<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="sliders"></i-feather> {{'List of Rlms in the same route' | translate}}
            </h4>
        </div>

        <!-- <div toolbar>
            <div class="filter-container">
                <div class="phrase-filter">
                    <input type='text' class="list-filter form-control" placeholder="{{'type to search' | translate}}" (keyup)='applyFiltering($event)'/>
                </div>
            </div>
        </div> -->
        <hr />
    </div>
    
    <div content>
        <div *ngIf="(list$ | async) as list">

            <ngx-datatable 
                id="apms" 
                class="bootstrap" 
                [rows]="list" 
                [columns]="columns" 
                [columnMode]="'flex'"
                [headerHeight]="50" 
                [footerHeight]="50" 
                [messages]="tableMessages" 
                [rowHeight]="'auto'" 
                [externalPaging]="false"
                [rowClass]="rowClass">
                <ng-container *ngFor="let col of columns">
                    <ngx-datatable-column [name]="col.prop" [flexGrow]="col.flexGrow" [prop]="col.prop" [headerClass]="col.headerClass" [sortable]="col.sortable">
                        <ng-template let-column="column" ngx-datatable-header-template class="header" let-sort="sortFn">
                            <b (click)="sort()">{{ col.name | translate }}</b>
                        </ng-template>
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>

                            <div [ngSwitch]="col.prop">
                                <div *ngSwitchCase="'apmAddress'">
                                    <span>{{row.apm.address | addressLabelPipe}}</span>
                                </div>
                                <div *ngSwitchCase="'apmName'">
                                    <span>{{row.apm.code}}</span>
                                </div>
                                <div *ngSwitchCase="'apmLcount'">
                                    <span 
                                        [containerClass]="'pretty-tooltip'" 
                                        placement="right" 
                                        [tooltip]="row.apm | rlmCapacityLabelPipe">{{row.apm | rlmCapacityLabelShortPipe}}</span>
                                </div>
                                <div *ngSwitchCase="'apmStatus'">
                                    <ng-container [ngSwitch]="row.apm.status">
                                        <span *ngSwitchCase="'NOT_AVAILABLE'" class="badge badge-danger">{{ 'RlmStatus.'+row.apm.status | translate }}</span>
                                        <span *ngSwitchCase="'AVAILABLE'" class="badge badge-success">{{ 'RlmStatus.'+row.apm.status | translate }}</span>
                                        <span *ngSwitchDefault class="badge badge-info">{{ 'RlmStatus.'+row.apm.status | translate }}</span>
                                    </ng-container>
                                </div>
                                <div *ngSwitchCase="'options'">
                                    <a 
                                        class="btn btn-outline-dark btn-xs btn-uppercase" 
                                        [routerLink]="['/apm', row.apm.id, 'availability', 'partner', servicePartner.id, 'delivery', apmDelivery.id, {sameDay: true}]">
                                            {{'Check availability' | translate }}
                                    </a>
                                </div>
                                <div *ngSwitchDefault>{{ value | translate }}</div>
                            </div>

                        </ng-template>
                    </ngx-datatable-column>
                </ng-container>
            </ngx-datatable>
        </div>

        <!-- <div *ngIf="loader" class="load-spinner text-center">
            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}
        </div> -->
    </div>

</layout-default>

<ng-template #confirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Move delivery' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p class="tx-12 tx-bold">{{'Do you confirm moving of delivery to another Apm' | translate }}?</p>
        <ul class="text-left solution-details-list">
            <li><strong>{{'Reference' | translate}}</strong>: {{apmDelivery.reservationNumber}}</li>
            <li><strong>{{'New apm' | translate}}</strong>: {{ apm.address | addressLabelPipe }}</li>
            <li><strong>{{'Date of delivery' | translate}}</strong>: {{ selectedTimeslot.date | amLocale: locale | amDateFormat: 'D MMMM YYYY' }}</li>
            <li><strong>{{'Time slot of pick up' | translate}}</strong>: {{selectedTimeslot.timeslot.timeslot | timeWindowPipe}}</li>
            <li><strong>{{'Pickup time window' | translate}}</strong>: {{selectedTimeslot.timeslot.pickupSlot | timeWindowPipe}}</li>
            <li><strong>{{'Put in time window' | translate}}</strong>: {{selectedTimeslot.putInTimeWindow | timeWindowPipe}}</li>
            <li><strong>{{'Customer time window' | translate}}</strong>: {{selectedTimeslot.customerTimeWindow | timeWindowPipe}}</li>
            <li><strong>{{'Purge time window' | translate}}</strong>: {{selectedTimeslot.purgeTimeWindow | timeWindowPipe}}</li>
        </ul>
        <button type="button" class="btn btn-brand-01" (click)="confirm()">
            {{'Yes, move' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>