<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="cast"></i-feather> {{'Apm availability' | translate}}
            </h4>
        </div>
        <div *ngIf="!apmDelivery" toolbar>
            <ng-container *ngIf="(otherApmsInRegions$ | async) as otherApmsInRegions">
                <select (change)="onChangeApm($event.target.value)" class="custom-select">
                    <option selected disabled>{{apm.name}}</option>
                    <option *ngFor="let el of otherApmsInRegions.content; let index=index" [value]="toStr(el)">
                        {{el.name}}</option>
                </select>
            </ng-container>
        </div>
        <hr />
    </div>

    <div content>

        <ng-container *ngIf="(columns$ | async) as columns">

            <div class="row">
                <div class="col">

                    <h3 class="mg-20"> {{'Choose time slot' | translate }} </h3>
                    <div class="ca2rd mg-20">
                        <div class="ca2rd-body">
                            <table class="table compartments-calendar">
                                <thead>
                                    <button *ngIf="!sameDay && !otherApm" class="btn compartments-calendar__day_nav_prev"
                                        (click)="prevWeek()">
                                        <svg id="Group_222" data-name="Group 222" xmlns="http://www.w3.org/2000/svg"
                                            width="43.034" height="43.034" viewBox="0 0 43.034 43.034">
                                            <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(0)" fill="none"
                                                stroke="#2c2c2c" stroke-width="3">
                                                <circle cx="21.517" cy="21.517" r="21.517" stroke="none" />
                                                <circle cx="21.517" cy="21.517" r="20.017" fill="none" />
                                            </g>
                                            <path id="Path_75" data-name="Path 75"
                                                d="M395.135,2440.519,386.8,2450.4l8.336,9.883"
                                                transform="translate(-370.031 -2428.657)" fill="none" stroke="#2c2c2c"
                                                stroke-width="3" />
                                        </svg>

                                    </button>
                                    <tr>
                                        <td class="text-center" *ngFor="let col of columns"
                                            [class.compartments-calendar__day_weekend]="col.isWeekend"
                                            [class.compartments-calendar__day_today]="col.isToday">
                                            <div>{{col.date | amLocale: locale | amDateFormat: 'dddd' }}</div>
                                            <div>{{col.date | amLocale: locale | amDateFormat: 'D MMM YYYY' }}</div>
                                        </td>
                                    </tr>

                                    <button *ngIf="!sameDay && !otherApm" class="btn compartments-calendar__day_nav_next"
                                        (click)="nextWeek()">
                                        <svg id="Group_222" data-name="Group 222" xmlns="http://www.w3.org/2000/svg"
                                            width="43.034" height="43.034" viewBox="0 0 43.034 43.034">
                                            <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(0)" fill="none"
                                                stroke="#2c2c2c" stroke-width="3">
                                                <circle cx="21.517" cy="21.517" r="21.517" stroke="none" />
                                                <circle cx="21.517" cy="21.517" r="20.017" fill="none" />
                                            </g>
                                            <path id="Path_75" data-name="Path 75"
                                                d="M395.135,2440.519,386.8,2450.4l8.336,9.883"
                                                transform="translate(-370.031 -2428.657)" fill="none" stroke="#2c2c2c"
                                                stroke-width="3" />
                                        </svg>
                                    </button>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="!loader else loaderRef;">


                                        <tr *ngFor="let i of rowsLength; let ind = index">
                                            <td *ngFor="let col of columns; let index = index" class="pd-0">

                                                <div class="compartments-calendar__cell"
                                                    *ngIf="columns[index].timeSlots[ind] as t"
                                                    [class.compartments-calendar__cell_disabled]="t.isDisabled"
                                                    [class.compartments-calendar__cell_selected]="t.timeslot && selectedTimeslot && t.timeslot.id == selectedTimeslot.timeslot.id && t.date === selectedTimeslot.date"
                                                    [containerClass]="'pretty-tooltip'" placement="right"
                                                    [tooltip]="t | rlmCapacityLabelPipe"
                                                    (click)="onSlotChange(col.date, t)">
                                                    <ng-container *ngIf="!apmDelivery">
                                                        <p *ngIf="t.pickupAvailability else ownLogistic">
                                                            {{'Inpost logistic' | translate}}</p>
                                                        <ng-template #ownLogistic>
                                                            <p>{{'Own logistic' | translate}}</p>
                                                        </ng-template>
                                                    </ng-container>
                                                    <strong>{{t.timeslot.timeslot.fromTime.slice(0,5)}} -
                                                        {{t.timeslot.timeslot.toTime.slice(0,5)}} </strong>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr class="no-timeslots" *ngIf="!rowsLength.length">{{'No timeslots for selected dates' | translate}}!</tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div id="apm-availability-delivery-info" *ngIf="apmDelivery" class="mg-20">
                        <label
                            class="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1">{{'Original delivery details' | translate}}:</label>

                        <p class="mg-b-2">{{'reservationNumber' | translate}}:
                            <strong>{{apmDelivery.reservationNumber}}</strong></p>
                        <p class="mg-b-2">{{'reservationReference' | translate}}:
                            <strong>{{apmDelivery.reservationReference}}</strong></p>
                        <p class="mg-b-2">{{'Capacity' | translate}}:
                            <strong [containerClass]="'pretty-tooltip'" placement="right"
                                [tooltip]="apmDelivery | rlmCapacityLabelPipe">{{apmDelivery | rlmCapacityLabelShortPipe}}</strong>
                        </p>
                        <p class="mg-b-2">{{'Status' | translate}}: <strong>{{apmDelivery.status | translate}}</strong></p>
                        <p class="mg-b-2">{{'Apm' | translate}}: <strong>{{apmDelivery.apm.name}}</strong></p>
                        <p class="mg-b-2">{{'Logistic type' | translate}}:
                            <strong [ngSwitch]="apmDelivery.logisticType">
                                <span
                                    *ngSwitchCase="'OWN'">{{ 'ApmDeliveryType.'+apmDelivery.logisticType | translate }}</span>
                                <span
                                    *ngSwitchCase="'INPOST'">{{ 'ApmDeliveryType.'+apmDelivery.logisticType | translate }}</span>
                                <span
                                    *ngSwitchCase="'MIXED'">{{ 'ApmDeliveryType.'+apmDelivery.logisticType | translate }}</span>
                                <span
                                    *ngSwitchDefault>{{ 'ApmDeliveryType.'+apmDelivery.logisticType | translate }}</span>
                            </strong>
                        </p>
                        <ng-container *ngFor="let window of ['deliveryWindow', 'reservationWindow', 'pickupWindow']">
                            <p *ngIf="apmDelivery[window.toString()] !== null" class="mg-b-2">
                                {{window.toString() | translate}}:
                                <strong>{{ apmDelivery[window.toString()]?.from | amLocale: locale | amDateFormat: 'D MMM, HH:mm' }}
                                    -
                                    {{ apmDelivery[window.toString()]?.to | amLocale: locale | amDateFormat: 'D MMM, HH:mm' }}</strong>
                            </p>
                        </ng-container>
                    </div>

                </div>
                <div *ngIf="apmDelivery" class="col-3">
                    <h3 class="mg-t-20 mg-b-20">{{'Booking summary' | translate}}</h3>
                    <div class="card booking-summary">
                        <div id="apm-availability-booking-summary" class="card-body">
                            <div class="row">
                                <div class="col-md-4 col-lg-12" *ngIf="apm.id">
                                    <h6 class="booking-summary__label">{{'Location' | translate}}</h6>
                                    <p class="booking-summary__value">{{ apm.address | addressLabelPipe }}
                                    </p>
                                </div>

                                <div class="col-md-4 col-lg-12" *ngIf="selectedTimeslot?.date">
                                    <h6 class="booking-summary__label">{{'Date of delivery' | translate}}</h6>
                                    <p class="booking-summary__value">
                                        {{ selectedTimeslot.date | amLocale: locale | amDateFormat: 'D MMMM YYYY' }}</p>
                                </div>


                                <div class="col-md-4 col-lg-12" *ngIf="selectedTimeslot?.timeslot">
                                    <h6 class="booking-summary__label" i18n>{{'Time slot of pick up' | translate}}</h6>
                                    <p class="booking-summary__value">
                                        {{ selectedTimeslot.timeslot.timeslot | timeWindowPipe }}</p>
                                </div>

                                <div class="col-md-4 col-lg-12" *ngIf="selectedTimeslot?.timeslot.pickupSlot">
                                    <h6 class="booking-summary__label" i18n>{{'Pickup time window' | translate}}</h6>
                                    <p class="booking-summary__value">
                                        {{ selectedTimeslot.timeslot.pickupSlot | timeWindowPipe }}</p>
                                </div>

                                <div class="col-md-4 col-lg-12" *ngIf="selectedTimeslot?.putInTimeWindow">
                                    <h6 class="booking-summary__label" i18n>{{'Put in time window' | translate}}</h6>
                                    <p class="booking-summary__value">
                                        {{ selectedTimeslot.putInTimeWindow | timeWindowPipe }}
                                    </p>
                                </div>

                                <div class="col-md-4 col-lg-12" *ngIf="selectedTimeslot?.customerTimeWindow">
                                    <h6 class="booking-summary__label" i18n>{{'Customer time window' | translate}}</h6>
                                    <p class="booking-summary__value">
                                        {{ selectedTimeslot.customerTimeWindow | timeWindowPipe }}</p>
                                </div>


                                <div class="col-md-4 col-lg-12" *ngIf="selectedTimeslot?.purgeTimeWindow">
                                    <h6 class="booking-summary__label" i18n>{{'Purge time window' | translate}}</h6>
                                    <p class="booking-summary__value">
                                        {{ selectedTimeslot.purgeTimeWindow | timeWindowPipe }}
                                    </p>
                                </div>

                                <div class="col-lg-12" *ngIf="apmDelivery.lockers.distribution.length">
                                    <h6 class="booking-summary__label" i18n>{{'Compartments' | translate}}</h6>
                                    <ul>
                                        <li *ngFor="let lockers of apmDelivery.lockers.distribution"
                                            class="booking-summary__value">
                                            <span *ngIf="lockers.subscriptionId !== null">{{lockers.subscriptionId}} -
                                            </span>
                                            <span *ngIf="lockers.subscriptionId === null">{{'Shared pool' | translate}}
                                                - </span>

                                            <span [containerClass]="'pretty-tooltip'" placement="right"
                                                [tooltip]="lockers.volume | rlmCapacityLabelPipe">{{lockers.volume | rlmCapacityLabelShortPipe}}</span>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button id="btn-apm-availability-move-delivery" [disabled]="!selectedTimeslot" (click)="moveDeliveryConfirmation()"
                        class="btn btn-warning btn-move">{{'Move delivery' | translate}}</button>
                </div>
            </div>
        </ng-container>


    </div>
</layout-default>

<ng-template #confirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Move delivery' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span
                aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p class="tx-12 tx-bold">{{'Do you confirm moving of delivery to another Apm' | translate }}?</p>
        <ul class="text-left solution-details-list">
            <li><strong>{{'Reference' | translate}}</strong>: {{apmDelivery.reservationNumber}}</li>
            <li><strong>{{'New apm' | translate}}</strong>: {{ apm.address | addressLabelPipe }}</li>
            <li><strong>{{'Date of delivery' | translate}}</strong>:
                {{ selectedTimeslot.date | amLocale: locale | amDateFormat: 'D MMMM YYYY' }}</li>
            <li><strong>{{'Time slot of pick up' | translate}}</strong>:
                {{selectedTimeslot.timeslot.timeslot | timeWindowPipe}}</li>
            <li><strong>{{'Pickup time window' | translate}}</strong>:
                {{selectedTimeslot.timeslot.pickupSlot | timeWindowPipe}}</li>
            <li><strong>{{'Put in time window' | translate}}</strong>:
                {{selectedTimeslot.putInTimeWindow | timeWindowPipe}}</li>
            <li><strong>{{'Customer time window' | translate}}</strong>:
                {{selectedTimeslot.customerTimeWindow | timeWindowPipe}}</li>
            <li><strong>{{'Purge time window' | translate}}</strong>:
                {{selectedTimeslot.purgeTimeWindow | timeWindowPipe}}</li>
        </ul>
        <button [disabled]="popupLoader" type="button" class="btn btn-brand-01" (click)="confirm()">
            <span *ngIf="!popupLoader">{{ "Yes, move" | translate }}</span>
            <ng-container *ngIf="popupLoader">
                <div class="load-spinner text-center"><i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                </div>
            </ng-container>
        </button>


        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>

<ng-template #loaderRef>
    <tr>
        <td colspan="7">
            <div class="load-spinner text-center pd-t-50">
                <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}
            </div>
        </td>
    </tr>
</ng-template>
