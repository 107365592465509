import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CrudListComponent } from '@shared/components/cruds/crud-list.component';
import { CronJobScheduler } from '../../interfaces/cron-job-scheduler.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CronJobSchedulerService } from '../../services/cronjob-scheduler.service';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { JobStatusType } from '@enums/enum';

@Component({
  selector: 'app-cronjob-scheduler',
  templateUrl: './cronjob-scheduler.component.html',
  styleUrls: ['./cronjob-scheduler.component.scss']
})
export class CronjobSchedulerComponent extends CrudListComponent<CronJobScheduler, string> implements OnInit {

  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  public columns = [
    { id: 'jobId', prop: 'jobId', name: this.translateService.instant('Code'), flexGrow: 1, sortable: true },
    { id: 'jobName', prop: 'jobName', name: this.translateService.instant('Name'), flexGrow: 3, sortable: true },
    { id: 'description', prop: 'description', name: this.translateService.instant('Description'), flexGrow: 4, sortable: true },
    { id: 'jobGroup', prop: 'jobGroup', name: this.translateService.instant('Group'), flexGrow: 2, sortable: true },
    { id: 'cronExpression', prop: 'cronExpression', name: this.translateService.instant('Cron expression'), flexGrow: 2, sortable: true },
    { id: 'jobStatus', prop: 'jobStatus', name: this.translateService.instant('Status'), flexGrow: 2, sortable: true },
    { id: 'cronJob', prop: 'cronJob', name: this.translateService.instant('CronJob'), flexGrow: 2, sortable: true },
    { id: 'modifiedAt', prop: 'modifiedAt', name: this.translateService.instant('Modified at'), flexGrow: 2, sortable: true },

    { prop: 'options', name: this.translateService.instant('Options'), flexGrow: 2, sortable: false},
  ];

  public data$: Observable<CronJobScheduler[]>;
  public jobStatusType = JobStatusType;
  public collection: CronJobScheduler[];

  constructor(
    protected readonly cronJobSchedulerService: CronJobSchedulerService,
    protected readonly translateService: TranslateService,
    protected readonly navigationRoute: ActivatedRoute,
    protected readonly modalService: BsModalService,
    protected readonly toastrProviderService: ToastrProviderService
  ) {
    super(cronJobSchedulerService, translateService, navigationRoute, modalService, toastrProviderService)
   }

   public ngOnInit() {
    this.loadData();
    this.modifiedRow = this.navigationRoute.snapshot.params.modifiedRow;
    this.loader = false;
  }

  public loadData() {
    this.data$ = this.cronJobSchedulerService.findAll().pipe(
      tap((list: CronJobScheduler[]) => this.rows = this.collection = list)
    );

    this.rowClass = row => {
      return { 'row-modified': row.jobId.toString() === this.modifiedRow };
    };
  }

  public runCronJob(jobId: string): void {
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.cronJobSchedulerService.runCronJob(jobId);
  }

  public resumeCronJob(jobId: string): void {
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.cronJobSchedulerService.resumeJob(jobId);
  }

  public pauseCronJob(jobId: string): void {
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.cronJobSchedulerService.pauseJob(jobId);
  }

  public removeCronJob(jobId: string): void {
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.cronJobSchedulerService.deleteCronJob(jobId);
  }

  public confirm(): void {
    this.confirmObservable.subscribe((cronJob: CronJobScheduler) => {
      if (cronJob){
        this.modifiedRow = cronJob.jobId.toString();
      }
      this.loadData();
      this.modalRef.hide();   
      this.toastrProviderService.showSuccess(this.translateService.instant('The distribution center has been removed!'));
      this.confirmObservable = null;
    });
  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }

  public applyFiltering(event) {
    const filterPhrase = event.target.value.toLowerCase();
    this.rows = this.collection.filter(a => a.jobName.toLowerCase().includes(filterPhrase))
  }

}
