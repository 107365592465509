<layout-default>
    <div header>
        <div toolbar>
            <h4 *ngIf="mode === 'CREATE'">
                <i-feather name="database"></i-feather> {{'Create user for partner' | translate}}
            </h4>
            <h4 *ngIf="mode === 'UPDATE'">
                <i-feather name="database"></i-feather> {{'Edit user' | translate}}: {{user.firstName}} {{user.lastName}}
            </h4>
        </div>

        <hr />
    </div>
    
    <div content>
        <div *ngIf="!loader" class="content bg-white">

            <div class="card-content col-8 offset-2">

                <form [formGroup]="form" autocomplete="off">

                    <div id="partner-user-edit-form-{{item}}" *ngFor="let item of regularFormItems()" class="form-group row">
                        <label class="col-2" for="code">{{item.toUpperCase() | translate}}:&nbsp;<span *ngIf="form.get(item) | isRequiredControlPipe">*</span></label>
                        <div class="col-10">
                            <input [formControlName]="item" class="form-control" />
                            <div *ngIf="form.get(item).hasError('required') && form.get(item).touched" class="error-color">
                                {{ "The field is required" | translate }}!
                            </div>
                            <div *ngIf="form.get(item).hasError('pattern') && form.get(item).touched" class="error-color">
                                {{ "Above email address is incorrect" | translate }}!
                            </div>
                        </div>
                    </div>
                   
                    <div id="partner-user-edit-form-code" class="form-group row">
                        <label class="col-2" for="code">{{ 'Password' | translate}}:&nbsp;<span *ngIf="form.get('password') | isRequiredControlPipe">*</span></label>
                        <div class="col-10">
                            <div class="input-group mb-2">
                                <div class="input-group-prepend" (touchstart)="showPassword = true"  (mousedown)="showPassword = true" (touchend)="showPassword = false" (mouseup)="showPassword = false">
                                    <div class="input-group-text">
                                        <i *ngIf="showPassword" class="far fa-eye"></i>
                                        <i *ngIf="!showPassword" class="far fa-eye-slash"></i>
                                    </div>
                                </div>
                                <input [type]="(showPassword) ? 'text' : 'password'" formControlName="password" class="form-control form-password-control" autocomplete="new-password"/>
                                
                            </div>
                            <div *ngIf="form.get('password')?.hasError('minlength') && form.get('password')?.touched" class="error-color">
                                {{ "The password should contain at least 8 characters" | translate }}!
                            </div>
                            <!-- <input type="password" formControlName="password" class="form-control" autocomplete="new-password"/>
                            <div *ngIf="form.get('password')?.hasError('minlength') && form.get('password')?.touched" class="error-color">
                                {{ "The password should contain at least 8 characters" | translate }}!
                            </div> -->
                        </div>
                    </div>

                    <div  class="form-group row">
                        <label class="col-2" for="roles">{{'Privileges' | translate}}:</label>
                        <div class="col-10 d-flex flex-wrap">
                            <ng-container formArrayName="roles">
                                <ng-container *ngFor="let p of rolesCtrl.controls; let i=index">
                               
                                        <div id="role-from-roles-{{i}}" formGroupName="{{i}}" class="custom-control custom-checkbox pd-b-10 w-50">
                                            <input formControlName="enabled" [checked]="p.value.enabled" type="checkbox" class="custom-control-input" id="customCheck{{i}}">
                                            <label class="custom-control-label justify-content-start" for="customCheck{{i}}">{{'Role_partneruser.'+p.value.name | translate}}</label>
                                        </div>
                             
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>

                    <div class="form-group row justify-content-end">
                        <div id="partner-user-form-btn-save" class="col-2">
                            <button (click)="onSubmit()" [disabled]="!form.valid || submitted"
                                class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01 w-100">

                                <span *ngIf="!submitted">{{ "Save" | translate }}</span>

                                <ng-container *ngIf="submitted">
                                    <div class="load-spinner text-center">
                                        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                    </div>
                                </ng-container>

                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</layout-default>


<!-- {{ 'FIRSTNAME' | translate}} -->
<!-- {{ 'LASTNAME' | translate}} -->
<!-- {{ 'Cannot manage non-admin partner user from admin panel' | translate}} -->
<!-- {{ 'Partner user with that email already exists' | translate}} -->
<!-- {{ 'Cannot create more users from Admin panel' | translate }} -->
<!-- {{'Role_partneruser.ROLE_WEBPANEL' | translate}} -->
<!-- {{'Role_partneruser.ROLE_IMPORTJOB' | translate}} -->
