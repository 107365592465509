import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
declare var google: any;

/**
 * Service responsible for Google Maps API interaction
 */
@Injectable()
export class GeocodingService {
    private TAG = '[Geocoding2Service]';
    private geocoder: any;

    constructor() {}

    /**
     * Geocode raw address
     * @param address The Raw Address
     * @return Observable<google.maps.GeocoderResult[]> Result of the coding
     */
    public geocode(address): Observable<google.maps.GeocoderResult[]> {
        this.geocoder = new google.maps.Geocoder();

        return new Observable((observer: Observer<google.maps.GeocoderResult[]>) => {
            // Invokes geocode method of Google Maps API geocoding.
            this.geocoder.geocode({ address }, (response, status) => {

                const result: google.maps.GeocoderResult[] = response;

                if (status === 'OK') {
                    observer.next(result);
                    observer.complete();
                } else {
                    console.log(this.TAG, 'geocode was not successful for the following reason:', result);
                    observer.error(status);
                }
            });
        });
    }
}
