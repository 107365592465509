import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ConfigValue } from '../../entities/config-value';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { ConfigValueService } from '../../services/config-value.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { NavService } from '@services/nav.sevice';
import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { Region } from '../../entities/region';
import { Partner } from '../../entities/partner';
import { ConfigKey } from '../../entities/config-key';
import { AppService } from '@services/app.service';
import { tooltipLabel } from '@shared/functions/tooltip-labels.function';
import { ConfigKeyTypes } from '../../enums/config-key.type';
import { CompartmentType } from '@enums/enum';
import { Permissions } from '@enums/permissions';
import { ConfigScopes } from '../../enums/config-scopes';
import { AuthenticationService } from 'src/app/authentication.service';

@Component({
  selector: 'app-config-value',
  templateUrl: './config-value.component.html',
  styleUrls: ['./config-value.component.scss']
})
export class ConfigValueComponent implements OnInit {

  public configValues: ConfigValue[];
  private configValuesCollection: ConfigValue[];

  public loader: boolean = true;

  public configKeys: ConfigKey[];
  public partners: Partner[];
  public regions: Region[];
  public configKeyTypes = ConfigKeyTypes;
  public compartmentType = CompartmentType;
  public Permissions = Permissions;

  public modifiedRow: string;

  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  public rowClass;

  public columns = [
    { prop: 'configKey.code', name: this.translateService.instant('Code'), flexGrow: 3 },
    // { prop: 'partnerId', name: this.translateService.instant('Partner'), flexGrow: 4 },
    { prop: 'scope', name: this.translateService.instant('Scopes'), flexGrow: 2 },
    { prop: 'value', name: this.translateService.instant('Value'), flexGrow: 2 },
    { prop: 'configKey.type', name: this.translateService.instant('Type'), flexGrow: 2 },
    { prop: 'options', name: this.translateService.instant('Options'), flexGrow: 2 },
  ];

  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  constructor(
    private configValueService: ConfigValueService,
    private navigationRoute: ActivatedRoute,
    private translateService: TranslateService,
    private modalService: BsModalService,
    private toastrProviderService: ToastrProviderService,
    private navService: NavService,
    private appService: AppService,
    private router: Router,
    private authService: AuthenticationService
  ) { }

  public ngOnInit() {
    this.configValues = this.configValuesCollection = plainToClass(ConfigValue, (this.navigationRoute.snapshot.data as {configValues: any}).configValues as ConfigValue[]) 
      .filter((c: ConfigValue) => (!this.authService.checkPrivileges([Permissions.ROLE_SYSADMIN])) ? [ConfigScopes.Global].includes(c.scope) : c);

    this.configKeys = plainToClass(ConfigKey, (this.navigationRoute.snapshot.data as {configKeys: any}).configKeys as ConfigKey[]);

    this.modifiedRow = this.navigationRoute.snapshot.params.modifiedRow;

    this.rowClass = row => {
      return { 'row-modified': row.id === this.modifiedRow };
    };

    console.log(this.configValues);

    this.loader = false;
  }

  public applyFiltering(event) {
    const filterPhrase = event.target.value.toLowerCase();
    const temp = _.filter(this.configValuesCollection, (config: ConfigValue) => {
      const searchIn: string = config.configKey.code;
      return searchIn.toLowerCase().indexOf(filterPhrase) !== -1 || !filterPhrase;
    });

    this.configValues = temp;
  }

  public fetch(): void {
    this.configValueService.getAllConfigValues().subscribe(
      (pApms) => {
        this.configValues = plainToClass(ConfigValue, pApms as ConfigValue[])
          .filter((c: ConfigValue) => [ConfigScopes.Global].includes(c.scope));
      })
  }

  public removeConfig(id: string): void {
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.configValueService.delete(id);
  }

  public confirm(): void {
    this.confirmObservable.subscribe((test) => {
      this.modalRef.hide();
      this.appService.getFeaturesConfiguration();
      this.fetch();      
      this.toastrProviderService.showSuccess(this.translateService.instant('The config key has been removed!'));
      this.confirmObservable = null;
    });
  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }

  public goToDetails(row: ConfigValue): void {
    this.router.navigate(['../edit', (row.id) ? row.id : row.configKey.code,  {scope: row.configKey.scopes, code: row.configKey.code, isDefault: row.isDefault.toString(), value: row.value}], {relativeTo: this.navigationRoute});
  }


  public tooltipLabel(property: string): string {
    return tooltipLabel(this.translateService, `tooltips.configkeys.labels.${property}`);
  }
}
