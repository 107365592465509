import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ApmDeliveryService } from '../services/apm-delivery.service';
import { ORDER } from '@enums/enum';

@Injectable()
export class AmpDeliveriesResolver implements Resolve<any> {
    constructor(private apmDeliveryService: ApmDeliveryService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        const params: any = {
            size: 1000,
            page: 0,
            order: ORDER.ASC,
            sort: 'createdAt',
            partnerId: route.params['partnerId']
        }
        return this.apmDeliveryService.findAllPageableResponse(params);
    }
}
