import { Injectable } from '@angular/core';
import { CrudService } from '@services/crud.service';
import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '@services/utils.service';
import { ConfigKey } from '../entities/config-key';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigKeyService extends CrudService<ConfigKey, string>{
  
  protected static BASE_API: string = `${environment.api.url}${environment.api.prefix}/config-key/v1`;  

  constructor(
    protected _http: HttpClient, 
    protected utilsService: UtilsService) 
    {
      super(_http, ConfigKeyService.BASE_API, utilsService);
    }

    public getConfigByCode(code: string): Observable<ConfigKey> {
      const endpoint = this.utilsService.interpolate(`${ConfigKeyService.BASE_API}/code/${code}`, {});
      return this._http.get<ConfigKey>(endpoint);
    }

}
