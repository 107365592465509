import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'reasonTranslatePipe',
    pure: true
})

export class ReasonTranslatePipe implements PipeTransform {
    constructor(private readonly translate: TranslateService){}
    public transform(reason: string): string {
        if (reason.includes("Booked to APM")) {
            return `${this.translate.instant('Booked to APM')}${reason.slice(13, reason.length)}`
        } else {
            return this.translate.instant(reason);
        }
    }
}
