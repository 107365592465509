import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

/**
 * A service that provides methods to display toast notifications using ngx-toastr.
 */
@Injectable({
  providedIn: 'root', // Makes this service available throughout the application
})
export class ToastrProviderService {
  constructor(private toastr: ToastrService) {}

  /**
   * Displays a success notification.
   * @param message The message to display in the notification.
   * @param title The title of the notification. Default is 'Success'.
   */
  showSuccess(message: string, title: string = 'Success'): void {
    this.toastr.success(message, title);
  }

  /**
   * Displays an error notification.
   * @param message The message to display in the notification.
   * @param title The title of the notification. Default is 'Error'.
   */
  showError(message: string, title: string = 'Error'): void {
    this.toastr.error(message, title);
  }

  /**
   * Displays a warning notification.
   * @param message The message to display in the notification.
   * @param title The title of the notification. Default is 'Warning'.
   */
  showWarning(message: string, title: string = 'Warning'): void {
    this.toastr.warning(message, title);
  }

  /**
   * Displays an informational notification.
   * @param message The message to display in the notification.
   * @param title The title of the notification. Default is 'Info'.
   */
  showInfo(message: string, title: string = 'Info'): void {
    this.toastr.info(message, title);
  }
}
