import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ConfigKeyService } from '../services/config-key.service';
import { ConfigValueService } from '../services/config-value.service';

@Injectable()
export class ConfigDefaultValuesResolver implements Resolve<any> {
    constructor(private configValueService: ConfigValueService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        return this.configValueService.getConfigDefaultValues();
    }
}
