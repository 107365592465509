import { Injectable, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Depot } from '@interfaces/depot.interface';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root' // lub można zdefiniować w module, jeśli klasa jest specyficzna dla określonego modułu
})
export abstract class DepotAssignHelper<T> {

  public list: T[];
  public assignedEntities: T[] = [];

  public depot: Depot;
  public idsControl: FormArray;

  public rawEntities: any[] = [];
  public rawEntitiesCollection: any[] = [];
  
  public loader: boolean = true;
  public submitted: boolean = false;

  public form: FormGroup;

  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  protected entityToRemoveId: number;
  protected entityToRemove: T;

  
  constructor(
    protected readonly navigationRoute: ActivatedRoute,
    protected readonly formBuilder: FormBuilder
  ) {
    this.initialize();
   }

  public initialize() {


    this.list = (this.navigationRoute.snapshot.data as {list: any}).list as T[];
    this.depot = (this.navigationRoute.snapshot.data as {depot: any}).depot as Depot;
    this.assignedEntities = (this.navigationRoute.snapshot.data as {assignedEntities: any}).assignedEntities as T[];

   
    this.initForm();
    // this.prepareApms();
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      ids: this.formBuilder.array([])
    })

    this.idsControl = <FormArray>this.form.controls['ids'];


    if (this.assignedEntities.length) {
      this.assignedEntities.forEach((item) => this.idsControl.push(this.patchValues(item)));
    }

    this.idsControl.push(this.patchValues());


  }

  private patchValues(item?: T): AbstractControl {

    if (!item) {
        return this.formBuilder.group({
            id: [{ value: null, disabled: false }],
            identifier: [{ value: null, disabled: false }],
            additionalInfo: [{ value: null, disabled: false }],
        });
    }

    if (item.hasOwnProperty('employeeId')) {
      return this.formBuilder.group({
        id: [{value: item['id'], disabled: true }],
        identifier: [{value: item['firstName'] ? `${item['firstName']} ${item['lastname']}` : item['employeeId'], disabled: true}],
        additionalInfo: [{value: item['employeeId'], disabled: true }],
      })
    }      
     
    if (item.hasOwnProperty('registration')) {
      return this.formBuilder.group({
        id: [{value: item['id'], disabled: true }],
        identifier: [{value: item['registration'], disabled: true}],
        additionalInfo: [{value: item['brand'], disabled: true }],
      })
    }

    if (item.hasOwnProperty('deviceNo')) {
      return this.formBuilder.group({
        id: [{value: item['id'], disabled: true }],
        identifier: [{value: item['deviceNo'], disabled: true}],
        additionalInfo: [{value: item['inventoryNo'], disabled: true }],
      })
    }
  }

  public isSetValue(index: number): boolean {
    return ((this.idsControl.controls[index] as FormControl).value['identifier']);
  }




  // public removeStep(i): void {
  //   this.removedUser = (<FormArray>this.form.controls['users']).controls[i].value;
  //   this.removedUserId = i;
  //   const userId: string = (<FormArray>this.form.controls['users']).controls[i].value['id'];
  //   this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
  //   this.confirmObservable = this.depotService.unassignUserFromDepot(this.depot.id, userId);
  // }

  // public confirm(): void {
  //   this.confirmObservable.subscribe((test) => {
  //     this.modalRef.hide();
  //     (<FormArray>this.form.controls['users']).removeAt(this.removedUserId);
  //     this.rawUsers.push({
  //       id: this.removedUser.id, 
  //       name: `${this.removedUser.firstName} ${this.removedUser.lastName}`,
  //       username: this.removedUser.username
  //     });
  //     this.toastrProviderService.showSuccess(this.translateService.instant('The user has been unassigned!'));
  //     this.appService.clearCache();
  //     this.confirmObservable = null;
  //   });
  // }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }

  public addStep(): void {
    this.idsControl.push(this.patchValues());
  }

  // public onSubmit() {

  //   const form = this.form.getRawValue();
  //   const body: string[] = [];

  //   _.forEach(_.values(form['users']), (user: PartnerUser) => user.id && this.assignedUsers.find(u => user.id === u.id) === undefined ? body.push(user.id) : null)
    
  //   this.submitted = true;
  //   this.depotService.assignUsersToDepot(this.depot.id, body)
  //     .pipe(
  //       catchError((error: HttpErrorResponse) => {
  //         this.submitted = false;
  //         throw (error);
  //       })
  //     )
  //     .subscribe(
  //       (region: any) => {
  //         console.log(region);
  //         this.toastrProviderService.showSuccess(this.translateService.instant('The user has been assigned to depot!'));
  //         this.appService.loadWarehouseLocationsDictionary();
  //         this.navService.goToPage(`/locations/depots/list`, {modifiedRow: this.depot.id});
  //         this.appService.clearCache();
  //         this.submitted = false;
  //       },
  //       error => {
  //         console.log(`Error occurred, please try again!`, error);
  //         this.submitted = false;
  //       },
  //       () => {
  //         this.submitted = false;
  //       }
  //     );

    
  // }

}
