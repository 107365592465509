import { Component, OnInit } from '@angular/core';
import { ApmDelivery } from '../../../entities/apm-delivery';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ApmDeliveryService } from '../../../services/apm-delivery.service';
import { PartnerCourierService } from '../../../services/partner-courier.service';
import { tap } from 'rxjs/operators';
import { PartnerCourier } from '../../../interfaces/partner-courier.interface';
import { Collection } from '@interfaces/collection.interface';

@Component({
  selector: 'app-apm-delivery-details',
  templateUrl: './apm-delivery-details.component.html',
  styleUrls: ['./apm-delivery-details.component.scss']
})
export class ApmDeliveryDetailsComponent implements OnInit {

  public apmDelivery: ApmDelivery;
  public newApmDelivery$: Observable<ApmDelivery>;
  public fields = ['id', 'date', 'customerEmail', 'customerPhone', 'status', 'logisticType', 'apm'];
  public timeFields = ['reservationWindow', 'pickupWindow', 'courierWindow', 'deliveryWindow', 'purgeWindow'];
  public isSuperseded = false;
  public couriers$: Observable<Collection<PartnerCourier>>;

  constructor(
    private readonly navigationRoute: ActivatedRoute,
    private readonly apmDeliveryService: ApmDeliveryService,
    private readonly partnerCouriersService: PartnerCourierService

  ) { }

  public ngOnInit() {
    this.apmDelivery = (this.navigationRoute.snapshot.data as {apmDelivery: any}).apmDelivery;

    if (this.apmDelivery.supersededBy) {
      this.isSuperseded = true;
      
      this.newApmDelivery$ = this.apmDeliveryService.getApmDelivery(this.apmDelivery.supersededBy).pipe(
        tap((apmDelivery: ApmDelivery) => {
          this.couriers$ = this.partnerCouriersService.getAllCouriersForRegion(apmDelivery.regionId);
        }));

    }

  }

  public print() {
    window.print();
}

}
