import { Injectable } from '@angular/core';
import { CrudService } from '@services/crud.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilsService } from '@services/utils.service';
import { Partner } from '../entities/partner';
import { Observable } from 'rxjs';
import { Timeslot } from '../interfaces/timeslot.interface';
import { shareReplay, map } from 'rxjs/operators';
import { environment } from '@environment';

@Injectable({
    providedIn: 'root'
})
export class TimeslotService extends CrudService<Partner, number>{

    protected static BASE_API: string = `${environment.api.url}${environment.api.prefix}/timeslot/v1`;

    protected BASE_API_2: string = `${environment.api.url}${environment.api.prefix}/timeslot/v1`;
    protected GET_TIMESLOTS_FOR_REGION: string = `${this.BASE_API_2}/` + '${partnerId}/region/${regionId}/list';
    protected GET_TIMESLOTS_FOR_PARTNER: string = `${this.BASE_API_2}/` + '${partnerId}/list';


    private cache$: Observable<Timeslot[]>;

    constructor(
        protected _http: HttpClient,
        protected utilsService: UtilsService) {
        super(_http, TimeslotService.BASE_API, utilsService);
    }

    public getAll(partnerId: string, regionId: string): Observable<Timeslot[]> {
        if (!this.cache$ && partnerId && regionId) {
            this.cache$ = this.getTimeSlotsForRegion(partnerId, regionId).pipe(shareReplay(1));
        }
        return this.cache$;
    }

    public getTimeSlotsForPartner(partnerId: string): Observable<Timeslot[]> {
        const endpoint = this.utilsService.interpolate(`${this.GET_TIMESLOTS_FOR_PARTNER}`, { partnerId });
        return this._http.get<Timeslot[]>(endpoint);
    }

    public getTimeSlotsForRegion(partnerId: string, regionId: string): Observable<Timeslot[]> {
        const endpoint = this.utilsService.interpolate(`${this.GET_TIMESLOTS_FOR_REGION}`, { partnerId, regionId });
        return this._http.get<Timeslot[]>(endpoint);
    }

}
