<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="cast"></i-feather> {{'Apm maintenance' | translate}}
            </h4>
        </div>

        <div toolbar>
            <button class="btn btn-sm btn-uppercase btn-white btn-apm-maint-add" (click)="addApmMaintenanceWindow()">
                <i-feather [style.height.px]="23" [style.width.px]="13" class="mg-r-10" name="plus"></i-feather> {{ 'Add RLM maintenance window' | translate }}
            </button>
            <date-range-picker class="flex-grow-1 pic-apm-maint-slot" [extendedOption]="false" (changedDateRange)="changedDateRange($event)"></date-range-picker>

            <hr />
        </div>
        
    </div>
    
    <div content>
        <div *ngIf="(apmMaintenance$ | async) as apmMaintenance">
            <ngx-datatable 
                id="apms" 
                class="bootstrap" 
                [rows]="apmMaintenance" 
                [columns]="columns" 
                [columnMode]="'flex'"
                [headerHeight]="50" 
                [footerHeight]="50" 
                [messages]="tableMessages"
                [sortType]="'single'"
                [rowHeight]="'auto'" 
                [externalPaging]="false"
                [rowClass]="rowClass"
                [scrollbarH]="scrollBarHorizontal">
                
                <ng-container *ngFor="let col of columns">
                    <ngx-datatable-column [flexGrow]="col.flexGrow" [prop]="col.prop">
                        <ng-template let-column="column" ngx-datatable-header-template class="header" let-sort="sortFn">
                            <b (click)="sort()">{{ col.name | translate }}</b>
                        </ng-template>
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>

                            <div [ngSwitch]="col.prop">
                                <div *ngSwitchCase="'slot.from'">
                                    {{ value | amLocale: locale | amDateFormat: 'D MMM YYYY, HH:mm'  }}
                                </div>
                                <div *ngSwitchCase="'slot.to'">
                                    {{ value | amLocale: locale | amDateFormat: 'D MMM YYYY, HH:mm'  }}
                                </div>
                                <div *ngSwitchCase="'type'">
                                    <ng-container [ngSwitch]="value">
                                        <span *ngSwitchCase="apmMaintenanceType.DEFECT" class="badge badge-warning">{{ 'ApmMaintenanceType.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmMaintenanceType.SCHEDULED_MAINTENANCE" class="badge badge-info">{{ 'ApmMaintenanceType.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmMaintenanceType.DIRTY" class="badge badge-success">{{ 'ApmMaintenanceType.'+value | translate }}</span>
                                        <span *ngSwitchDefault class="badge badge-info">{{ 'ApmMaintenanceType.'+value | translate }}</span>
                                    </ng-container>
                                </div>
                                <div *ngSwitchCase="'origin'">
                                    <ng-container [ngSwitch]="value">
                                        <span *ngSwitchCase="apmMaintenanceOrigin.AUTOMATIC" class="badge badge-warning">{{ 'ApmMaintenanceOrigin.'+value | translate }}</span>
                                        <span *ngSwitchDefault class="badge badge-dark">{{ 'ApmMaintenanceOrigin.'+value | translate }}</span>
                                    </ng-container>
                                </div>
                                <div *ngSwitchCase="'capacity'">
                                    <div >
                                        <span 
                                            [containerClass]="'pretty-tooltip'" 
                                            placement="right" 
                                            [tooltip]="row | rlmCapacityLabelPipe">{{row | rlmCapacityLabelShortPipe}}</span>
                                    </div>
                                </div>  
                                <div *ngSwitchCase="'options'">
                                    <div class="dropdown" dropdown>
                                        <button
                                            class="btn btn-outline-secondary btn-xs dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton">
                                            {{ 'Select' | translate }}
                                        </button>
    
                                        <div class="dropdown-menu dropdown-menu-right div-main-actions">

                                            <a 
                                                *ngIf="row.origin !== 'AUTOMATIC'"
                                                appCheckFeaturesConfig [featureName]="[Permissions.Apm_maintenance_edit]"
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-apm-main-edit" 
                                                [routerLink]="['./', 'edit', row.id]">
                                                    {{'Edit' | translate }}
                                            </a>

                                            <a 
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-apm-main-manage" 
                                                [routerLink]="['../', row.id, 'overbooking', 'list', {apmId: apm.id}]">
                                                    {{'Overbooked slots' | translate }}
                                            </a>

                                            <a 
                                                appCheckFeaturesConfig [featureName]="[Permissions.Apm_maintenance_edit]"
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-apm-main-remove" 
                                                (click)="removeTimeslot(row.id)">
                                                    {{'Remove' | translate }}
                                            </a>
    
                                        </div>
                                    </div>
                                </div>
                                <div *ngSwitchDefault>{{value}}</div>
                            </div>
                        
                           
                        </ng-template>
                    </ngx-datatable-column>
                </ng-container>
            </ngx-datatable>
        </div>

        <div *ngIf="loader" class="load-spinner text-center">
            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}
        </div>
    </div>

</layout-default>

<ng-template #confirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p>{{'Do you confirm removal of apm maintenance slot window' | translate }}?</p>
        <button type="button" class="btn btn-brand-01" (click)="confirm()">
            {{'Yes, remove' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>
