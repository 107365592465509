import { PipeTransform, Pipe } from '@angular/core';
import { TimePeriod } from '@analytics/enums/time-period.enum';
import { ChartModel } from '@analytics/interfaces/chart.interface';
import { CustomerType } from '@enums/enum';

@Pipe({
  name: 'removeTotalSerie',
  pure: true
})
export class RemoveTotalSeriePipe implements PipeTransform {
  public transform(charts: []): any {
    return charts.filter(o => o['name'] !== 'TOTAL');
  }
}
