import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { RouteExt } from '@entities/route-ext';
import { UtilsService } from '@services/utils.service';

import { PaymentForm } from '../entities/payment-form';
import { environment } from '@environment';
import { Licensing } from '../entities/licensing';
import { plainToClass } from 'class-transformer';
import { LicensingRequest } from '../entities/licensing-request';
import { groupBy, Dictionary } from 'lodash';
import { LicensingDeliveries } from '../entities/licensing-deliveries';
import { User } from '@entities/user';
import { Hardware } from '@hardware/entities/hardware';
import { ShiftPaymentSummary } from '@routes/interfaces/shift-payment-summary.interface';
import { UrlUtil } from '@shared/functions/UrlUtil';

@Injectable()
export class PaymentsService {
    constructor(private http: HttpClient, private translate: TranslateService, private toastrProviderService: ToastrProviderService, private utilsService: UtilsService) {
        this.notifier = toastrProviderService;
    }

    public static CLOSE: string = 'route/v2/${routeId}/close';
    public static LICENSING: string = 'licensing/v1/billing';
    public static LICENSING_USERS: string = 'licensing/v1/users';
    public static LICENSING_DELIVERIES: string = 'licensing/v1/deliveries';
    public static LICENSING_HARDWARE: string = 'licensing/v2/devices';
    public static LICENSING_APM_DELIVERIES: string = 'licensing/v1/apmdeliveries';

    private static PAYMENT_ROUTE_REPORT: string = 'report/v2/routePaymentSummary';
    private static PAYMENT_DELIVERY_REPORT: string = 'report/v2/deliveryPaymentSummary';

    private readonly host = environment.api.url;
    private readonly prefix = environment.api.prefix;

    private readonly notifier;

    public close(body: PaymentForm): Observable<any> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${PaymentsService.CLOSE}`, { routeId: body.routeId });

        return this.http
            .post(endpoint, body, this.utilsService.httpHeaders())
            .pipe(
                catchError(error => {
                    this.notifier.showError(this.translate.instant('Error occurred, please try again!'));
                    return observableThrowError(error);
                }),
                map((data: RouteExt) => {
                    return new RouteExt().deserialize(data);
                })
            );
    }

    public licensing(body: LicensingRequest) {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${PaymentsService.LICENSING}`, {});

        return this.http
            .post(endpoint, body, this.utilsService.httpHeaders())
            .pipe(
                catchError(error => {
                    this.notifier.showError(this.translate.instant('Error occurred, please try again!'));
                    return observableThrowError(error);
                }),
                map((licensing: any) => {
                    return plainToClass(Licensing, licensing as Licensing);
                })
            );
    }

    public licensingUsers(date: string, excludes: string, includes: string): Observable<User[]> {
        let endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${PaymentsService.LICENSING_USERS}?date=${date}`, {});

        if (excludes) {
            endpoint += `&excludes=${excludes.toString()}`;
        }

        if (includes) {
            endpoint += `&includes=${includes.toString()}`;
        }

        endpoint = encodeURI(endpoint);

        return this.http
            .get<User[]>(endpoint)
            .pipe(
                catchError(error => {
                    this.notifier.showError(this.translate.instant('Error occurred, please try again!'));
                    return observableThrowError(error);
                })
            );
    }

    
    public licensingHardware(dateStart: string, dateEnd: string): Observable<{[depotId: string]: Hardware[]}> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${PaymentsService.LICENSING_HARDWARE}?startDate=${dateStart}&endDate=${dateEnd}`, {});

        return this.http
            .get<{[depotId: string]: Hardware[]}>(endpoint)
            .pipe(
                catchError(error => {
                    this.notifier.showError(this.translate.instant('Error occurred, please try again!'));
                    return observableThrowError(error);
                })
            );
    }

    public licensingDeliveries(from: string, to: string, statusses: string[], partners: string[]): Observable<Dictionary<LicensingDeliveries[]>> {
        let endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${PaymentsService.LICENSING_DELIVERIES}?startDate=${from}&endDate=${to}&statusses=${statusses.join(',')}`, {});

        if (partners.length) {
            endpoint += `&partners=${partners}`;
        }

        return this.http
            .get(endpoint)
            .pipe(
                catchError(error => {
                    this.notifier.showError(this.translate.instant('Error occurred, please try again!'));
                    return observableThrowError(error);
                }),
                map((licensing: any) => groupBy(licensing, (l: LicensingDeliveries) => l.depotCode))
            );
    }

    public licensingApmDeliveries(from: string, to: string, statusses: string[], partners: string[]): Observable<Dictionary<LicensingDeliveries[]>> {
        let endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${PaymentsService.LICENSING_APM_DELIVERIES}?startDate=${from}&endDate=${to}&statusses=${statusses.join(',')}`, {});

        if (partners.length) {
            endpoint += `&partners=${partners}`;
        }

        return this.http
            .get(endpoint)
            .pipe(
                catchError(error => {
                    this.notifier.showError(this.translate.instant('Error occurred, please try again!'));
                    return observableThrowError(error);
                }),
                map((licensing: any) => groupBy(licensing, (l: LicensingDeliveries) => l.depotCode))
            );
    }

    public getRoutePaymentReport(params: Object): Observable<ShiftPaymentSummary[]> {
        let endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${PaymentsService.PAYMENT_ROUTE_REPORT}`, {});
        const paramsString = UrlUtil.objectToString(params);
        if (paramsString) {
            endpoint += '?' + paramsString;
          }
        return this.http.get<ShiftPaymentSummary[]>(endpoint).pipe(
            map((response: ShiftPaymentSummary[]) => {
                return response.map((i: ShiftPaymentSummary) => {
                    const [y, m, d] = i.date.split('-');
                    i.year = y;
                    i.month = m;
                    i.day = d;
                    return i
                })
            })
        );
    }


    public getDeliveryPaymentReport(params: Object): Observable<any[]> {
        let endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${PaymentsService.PAYMENT_DELIVERY_REPORT}`, {});
        const paramsString = UrlUtil.objectToString(params);
        if (paramsString) {
            endpoint += '?' + paramsString;
          }
        return this.http.get<any[]>(endpoint).pipe(
            map((response: ShiftPaymentSummary[]) => {
                return response.map((i: ShiftPaymentSummary) => {
                    const [y, m, d] = i.date.split('-');
                    i.year = y;
                    i.month = m;
                    i.day = d;
                    return i
                })
            })
        );
    }

}
