import { Component, OnInit } from '@angular/core';
import { DateRange, PaymentReportType, PaymentType, Client, MerchantTypeFrisco, MerchantTypeInpost } from '@enums/enum';
import { Depot } from '@interfaces/depot.interface';
import { ShiftPaymentSummary } from '@routes/interfaces/shift-payment-summary.interface';
import { Observable, Subscription } from 'rxjs';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { PaymentsService } from '../../services/payments.service';
import { DepotService } from 'src/app/locations/services/depot.service';
import { QueryParams } from '@interfaces/query-params.interface';
import { environment } from '@environment';

import * as XLSX from 'xlsx';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { AngularMultiSelectOptions } from '@shared/constants/multiselect-dropdown.constants';
type AOA = any[][];

@Component({
    selector: 'app-payment-report',
    templateUrl: './payment-report.component.html',
    styleUrls: ['./payment-report.component.scss'],
})
export class PaymentReportComponent implements OnInit {
    public depots$: Observable<Depot[]>;
    public list$: Observable<ShiftPaymentSummary[]>;

    public startDate: string;
    public endDate: string;
    public dateRange: DateRange = DateRange.LAST_7_DAYS;
    private data: AOA = [];
    private searchFieldSubscription: Subscription;

    public queryParams: {
        startDate: string;
        endDate: string;
        depots: string[];
        cutoffMissing: boolean;
        type: PaymentReportType;
        driverId?: string;
        customerId?: string;
        orderId?: string;
        merchants?: string;
        paymentMethods?: PaymentType[];
    };

    public dropdownList = [];
    public selectedItems = [];
    public dropdownListPaymentMethods = [];
    public selectedItemsPaymentMethods = [];

    public dropdownSettings = AngularMultiSelectOptions;

    public loading: boolean = false;
    public paymentReportType = Object.values(PaymentReportType);
    public paymentReport = PaymentReportType;

    public paymentType = Object.values(PaymentType);
    public merchantsType = environment.client === Client.INPOST ? Object.values(MerchantTypeInpost) : Object.values(MerchantTypeFrisco);

    public queryParamsConst: QueryParams = Object.assign({}, environment.pagination);

    public columnsRoute = [
        { prop: 'date', name: this.translate.instant('Date'), flexGrow: 1, sortable: true },
        { prop: 'routeNumber', name: this.translate.instant('Route number'), flexGrow: 1, sortable: true },
        { prop: 'depotCode', name: this.translate.instant('Depot'), flexGrow: 1, sortable: true },
        { prop: 'driver', name: this.translate.instant('Driver'), flexGrow: 2, sortable: true },
        { prop: 'merchant', name: this.translate.instant('Merchant'), flexGrow: 1, sortable: true },
        { prop: 'amountInvoiced', name: this.translate.instant('Requested offline'), flexGrow: 1, sortable: true },
        { prop: 'cardAmount', name: this.translate.instant('Card payment'), flexGrow: 1, sortable: true },
        { prop: 'wireAmount', name: this.translate.instant('Wiretransfer'), flexGrow: 1, sortable: true },
        { prop: 'couponAmount', name: this.translate.instant('Sodexo'), flexGrow: 1, sortable: true },
        { prop: 'complaintAmount', name: this.translate.instant('Complaints'), flexGrow: 1, sortable: true },
        { prop: 'totalAmount', name: this.translate.instant('Sum paid'), flexGrow: 1, sortable: true },
        { prop: 'terminalIds', name: this.translate.instant('Terminal'), flexGrow: 1, sortable: true },
        { prop: 'cardCount', name: this.translate.instant('No of card payments'), flexGrow: 1, sortable: true },
        { prop: 'cutoffCount', name: this.translate.instant('Payment report cutoff'), flexGrow: 1, sortable: true },
        { prop: 'cutoffNotDone', name: this.translate.instant('Payment report cutoff not done'), flexGrow: 1, sortable: true },
    ];

    public columnsDelivery = [
        { prop: 'date', name: this.translate.instant('Date'), flexGrow: 1, sortable: true },
        { prop: 'deliveryId', name: this.translate.instant('Delivery ID'), flexGrow: 2, sortable: true },
        { prop: 'orderId', name: this.translate.instant('Order Id'), flexGrow: 2, sortable: true },
        { prop: 'customerName', name: this.translate.instant('Customer'), flexGrow: 1, sortable: true },
        { prop: 'companyName', name: this.translate.instant('Company name'), flexGrow: 1, sortable: true },
        { prop: 'street', name: this.translate.instant('Street'), flexGrow: 2, sortable: true },
        { prop: 'houseNo', name: this.translate.instant('HOUSENO'), flexGrow: 1, sortable: true },
        { prop: 'flatNo', name: this.translate.instant('FLATNO'), flexGrow: 1, sortable: true },
        { prop: 'town', name: this.translate.instant('TOWN'), flexGrow: 1, sortable: true },
        { prop: 'zip', name: this.translate.instant('Zip'), flexGrow: 1, sortable: true },
        { prop: 'customerPhone', name: this.translate.instant('customerPhone'), flexGrow: 2, sortable: true },
        { prop: 'driverName', name: this.translate.instant('Driver Name'), flexGrow: 2, sortable: true },
        { prop: 'routeNumber', name: this.translate.instant('Route number'), flexGrow: 1, sortable: true },
        { prop: 'depotCode', name: this.translate.instant('Depot code'), flexGrow: 1, sortable: true },
        { prop: 'merchant', name: this.translate.instant('Merchant'), flexGrow: 1, sortable: true },
        { prop: 'shift', name: this.translate.instant('Shift'), flexGrow: 1, sortable: true },
        { prop: 'cardAmount', name: this.translate.instant('Card payment'), flexGrow: 1, sortable: true },
        { prop: 'complaintAmount', name: this.translate.instant('Complaints'), flexGrow: 1, sortable: true },
        { prop: 'couponAmount', name: this.translate.instant('Sodexo'), flexGrow: 1, sortable: true },
        { prop: 'posCutoffMissing', name: this.translate.instant('posCutoffMissing'), flexGrow: 1, sortable: true },
        { prop: 'wireAmount', name: this.translate.instant('Wiretransfer'), flexGrow: 1, sortable: true },
    ];

    constructor(private readonly navigationRoute: ActivatedRoute, private readonly paymentsService: PaymentsService, private readonly translate: TranslateService, private readonly depotService: DepotService, private toastrProviderService: ToastrProviderService) {}

    public ngOnInit() {
        this.loading = true;

        this.queryParams = {
            startDate: '',
            endDate: '',
            depots: [],
            cutoffMissing: false,
            merchants: '',
            type: PaymentReportType.Route,
        };

        this.depots$ = this.depotService.getDepotsForUser().pipe(tap((depots: Depot[]) => this.initDropdownListValues(depots)));
    }

    public loadData(): void {
        this.loading = true;
        this.data = [];

        if (this.selectedItems.length) {
            this.queryParams.depots = this.selectedItems.map((a) => a.id);
        }

        if (this.selectedItemsPaymentMethods.length) {
            this.queryParams.paymentMethods = this.selectedItemsPaymentMethods.map((a) => a.id);
        } else {
            delete this.queryParams.paymentMethods;
        }

        const days = moment(this.queryParams.endDate).diff(this.queryParams.startDate, 'days');
        if (days > 30) {
            this.toastrProviderService.showWarning(this.translate.instant('The date range selected is too large The maximum date range is 30 days'));
            return;
        }

        if (this.queryParams.type === PaymentReportType.Route) {
            this.loadRouteDate();
        } else if (this.queryParams.type === PaymentReportType.Delivery) {
            this.loadDeliveryDate();
        }
    }

    public loadRouteDate() {
        this.list$ = this.paymentsService.getRoutePaymentReport(this.queryParams).pipe(
            tap((routes: ShiftPaymentSummary[]) => {
                this.data.push([
                    this.translate.instant('Date'),
                    this.translate.instant('Route number'),
                    this.translate.instant('Depot'),
                    this.translate.instant('Driver'),
                    this.translate.instant('Merchant'),
                    this.translate.instant('Requested offline'),
                    this.translate.instant('Card payment'),
                    this.translate.instant('Wiretransfer'),
                    this.translate.instant('Sodexo'),
                    this.translate.instant('Complaints'),
                    this.translate.instant('Sum paid'),
                    this.translate.instant('Terminal'),
                    this.translate.instant('No of card payments'),
                    this.translate.instant('Payment report cutoff'),
                    this.translate.instant('Payment report cutoff not done'),
                ]);

                routes.forEach((r) => {
                    this.data.push([r.date, r.routeNumber, r.depotCode, r.driver, r.merchant, r.amountInvoiced, r.cardAmount, r.wireAmount, r.couponAmount, r.complaintAmount, r.totalAmount, r.terminalIds, r.cardCount, r.cutoffCount, r.cardCount - r.cutoffCount]);
                });

                this.loading = false;
            })
        );
    }

    public loadDeliveryDate() {
        this.list$ = this.paymentsService.getDeliveryPaymentReport(this.queryParams).pipe(
            tap((routes: ShiftPaymentSummary[]) => {
                this.data.push([
                    this.translate.instant('Date'),
                    this.translate.instant('Delivery ID'),
                    this.translate.instant('Order Id'),
                    this.translate.instant('Customer'),
                    this.translate.instant('Company name'),
                    this.translate.instant('Street'),
                    this.translate.instant('HOUSENO'),
                    this.translate.instant('FLATNO'),
                    this.translate.instant('TOWN'),
                    this.translate.instant('Zip'),
                    this.translate.instant('customerPhone'),
                    this.translate.instant('Driver Name'),
                    this.translate.instant('Route number'),
                    this.translate.instant('Depot code'),
                    this.translate.instant('Merchant'),
                    this.translate.instant('Shift'),
                    this.translate.instant('Card payment'),
                    this.translate.instant('Complaints'),
                    this.translate.instant('Sodexo'),
                    this.translate.instant('posCutoffMissing'),
                    this.translate.instant('Wiretransfer'),
                ]);

                routes.forEach((r) => {
                    this.data.push([r.date, r.deliveryId, r.orderId, r.customerName, r.companyName, r.street, r.houseNo, r.flatNo, r.town, r.zip, r.customerPhone, r.driverName, r.routeNumber, r.depotCode, r.merchant, r.shift, r.cardAmount, r.complaintAmount, r.couponAmount, r.posCutoffMissing, r.wireAmount]);
                });

                this.loading = false;
            })
        );
    }

    public changedDateRange(event) {
        this.startDate = this.queryParams.startDate = moment(event.dateStart).format('YYYY-MM-DD');
        this.endDate = this.queryParams.endDate = moment(event.dateEnd).format('YYYY-MM-DD');
        this.dateRange = event.dateRange;
        this.loadData();
    }

    public initDropdownListValues(depot: Depot[]): void {
        this.dropdownList = [];
        this.dropdownListPaymentMethods = [];

        depot.forEach((d: Depot) => {
            this.dropdownList.push({ id: d.id, itemName: d.code });
        });

        this.paymentType.forEach((d: string) => {
            this.dropdownListPaymentMethods.push({ id: d, itemName: this.translate.instant(d) });
        });

        this.selectedItems = [this.dropdownList[0]];
    }

    public export(): void {
        const filename: string = `Sheet-${this.queryParams.startDate}-${this.queryParams.endDate}`.substring(0, 20);
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, filename);
        XLSX.writeFile(wb, filename + '.xlsx');
    }
}
