import { Component, HostBinding, Input } from '@angular/core';

import { Roles, Client } from '@enums/enum';
import { Permissions } from '@enums/permissions';

@Component({
    selector: '[navigation]',
    templateUrl: 'navigation.component.html',
    styleUrls: ['navigation.component.scss'],
})
export class NavigationComponent {
    @Input() public navigation: string;

    public Permissions = Permissions;

    @HostBinding('class.nav')
    public get isDesktopNav(): boolean {
        return this.navigation === 'nav';
    }

    @HostBinding('class.mobile-nav')
    public get isMobileNav(): boolean {
        return this.navigation.includes('mobile-nav');
    }

    @HostBinding('class.mobile-nav--show')
    public get isMobileNavShowed(): boolean {
        return this.navigation.includes('mobile-nav--show');
    }

    public get className(): string {
        if (this.navigation === 'nav') {
            return 'nav';
        }
        return 'mobile-nav';
    }

    public sysadminRole: string = Roles.ROLE_SYSADMIN;
    public dispatcherRole: string = Roles.ROLE_DISPATCHER;
    public customerCareRole: string = Roles.ROLE_CUSTOMER_CARE;
    public warehouseRole: string = Roles.ROLE_WAREHOUSE;
    public inpostClient: string = Client.INPOST;
    public friscoClient: string = Client.FRISCO;
    public mamyitoClient: string = Client.MAMYITO;
}
