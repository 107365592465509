<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="sliders"></i-feather> {{'Affected Apm deliveries' | translate}}
            </h4>
        </div>

        <div toolbar>
            
            <button 
                id="btn-convert-to-replacement-vehicle"
                appCheckFeaturesConfig [featureName]="[Permissions.Apm_delivery_reschedule]"
                [disabled]="!selectedDeliveries.length" class="btn btn-sm btn-uppercase btn-warning"
                (click)="convertToReplacementVehicle()">
                <i-feather [style.height.px]="23" [style.width.px]="13" class="mg-r-10" name="map"></i-feather> {{ 'Convert apm deliveries into replacement vehicle' | translate }}
            </button>
            <div id="btn-search-bar" class="filter-container">
                <div class="phrase-filter">
                    <input type='text' class="list-filter form-control" placeholder="{{'type to search' | translate}}" (keyup)='applyFiltering($event)'/>
                </div>
            </div>
        </div>
        <hr />
    </div>
    
    <div content>
        <div *ngIf="(list$ | async) as list">

            <ngx-datatable 
                #table
                id="apm" 
                class="bootstrap expandable" 
                [rows]="rows" 
                [columns]="columns" 
                [columnMode]="'flex'"
                [headerHeight]="50" 
                [footerHeight]="50" 
                [messages]="tableMessages" 
                [rowHeight]="'auto'" 
                [externalPaging]="true"
                [count]="list.totalElements"
                [offset]="queryParams.page"
                [rowClass]="rowClass"
                [limit]="queryParams.size"
                [scrollbarH]="scrollBarHorizontal"
                (sort)="onSort($event)"
                (page)="setPage($event)">

                <!-- Row Detail Template -->
                <ngx-datatable-row-detail [rowHeight]="'auto'" #myDetailRow (toggle)="onDetailToggle($event)">
                    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                        <div class="d-flex align-items-top justify-content-between bd-highlight pd-20">
                            <div class="column p-2 mg-r-10 bd-highlight" [style.flex-grow]="1">
                                <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">{{'Delivery' | translate}}:</label>
                                <span class="d-block">{{'Customer email' | translate}}: <strong>{{row.apmDelivery.customerEmail}}</strong></span>
                                <span class="d-block">{{'Contact phone' | translate}}: <strong>{{row.apmDelivery.customerPhone}}</strong></span>
                                <span class="d-block">{{'Delivery window' | translate}}: <strong>{{row.apmDelivery.deliveryWindow | timeWindowPipe}}</strong></span>
                                <span class="d-block">{{'Courier window' | translate}}: <strong>{{row.apmDelivery.courierWindow | timeWindowPipe}}</strong></span>
                                <span class="d-block">{{'Pickup window' | translate}}: <strong>{{row.apmDelivery.pickupWindow | timeWindowPipe}}</strong></span>
                                <span class="d-block">{{'Purge window' | translate}}: <strong>{{row.apmDelivery.purgeWindow | timeWindowPipe}}</strong></span>
                                <span class="d-block">{{'Reservation window' | translate}}: <strong>{{row.apmDelivery.reservationWindow | timeWindowPipe}}</strong></span>
                                <span *ngIf="row.apmDelivery.reason" class="d-block">{{'Reson' | translate}}: <strong>{{row.apmDelivery.reason | translate}}</strong></span>
                                <span *ngIf="row.apmDelivery.supersededBy" class="d-block">{{'Superseded by' | translate}}: <strong>{{row.apmDelivery.supersededBy | translate}}</strong></span>
                            </div>
                            <div class="column p-2 mg-r-10 bd-highlight" [style.flex-grow]="1">
                                <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">{{'Partner' | translate}}:</label>
                                <span class="d-block">{{'Name' | translate}}: <strong>{{row.partner.name}}</strong></span>
                                <span class="d-block">{{'Code' | translate}}: <strong>{{row.partner.code}}</strong></span>
                                <span class="d-block">{{'Address' | translate}}: <strong>{{row.partner.address | addressLabelPipe}}</strong></span>
                                <span class="d-block">{{'Contact phone' | translate}}: <strong>{{row.partner.locationAdditional.contactPhoneNO}}</strong></span>
                                <span class="d-block">{{'E-mail' | translate}}: <strong>{{row.partner.locationAdditional.contactEmail}}</strong></span>

                            </div>
                            <div class="column p-2 flex-grow-1 flex-shrink-1 bd-highlight" [style.flex-grow]="1">
                                <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">{{'Region' | translate}}:</label>
                                <span class="d-block">{{'Name' | translate}}: <strong>{{row.region.name}}</strong></span>
                                <span class="d-block">{{'Code' | translate}}: <strong>{{row.region.code}}</strong></span>
                                <span class="d-block">{{'Address' | translate}}: <strong>{{row.region.address | addressLabelPipe}}</strong></span>
                                <span class="d-block">{{'Company name' | translate}}: <strong>{{row.region.locationAdditional.companyName}}</strong></span>
                                <span class="d-block">{{'Contact name' | translate}}: <strong>{{row.region.locationAdditional.contactName}}</strong></span>
                                <span class="d-block">{{'Contact phone' | translate}}: <strong>{{row.region.locationAdditional.contactPhoneNO}}</strong></span>
                                <span class="d-block">{{'E-mail' | translate}}: <strong>{{row.region.locationAdditional.contactEmail}}</strong></span>
                            </div>
                        </div>
                    </ng-template>
                </ngx-datatable-row-detail>

                <ng-container *ngFor="let col of columns">
                    
                    <ngx-datatable-column [name]="col.prop" [flexGrow]="col.flexGrow" [prop]="col.prop" [sortable]="col.sortable" [headerClass]="col.headerClass">
                        <ng-template let-column="column" ngx-datatable-header-template class="header" let-sort="sortFn">
                            <b (click)="sort()">{{ col.name | translate }}</b>
                        </ng-template>

                        <!-- <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                            
                          </ng-template> -->

                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>

                            <div [ngSwitch]="col.prop">
                                <div *ngSwitchCase="'extend'">
                                    <a
                                    href="javascript:void(0)"
                                    [class.datatable-icon-right]="!expanded"
                                    [class.datatable-icon-down]="expanded"
                                    title="Expand/Collapse Row"
                                    (click)="toggleExpandRow(row)"
                                  >
                                  </a>
                                </div>
                                <div *ngSwitchCase="'options'">
                                    <div *ngIf="![apmDeliveryStatus.Reserved, apmDeliveryStatus.Cancelled, apmDeliveryStatus.Superseded].includes(row.apmDelivery.status)" class="dropdown" dropdown>
                                        <button
                                            class="btn btn-outline-secondary btn-xs dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton">
                                            {{ 'Select' | translate }}
                                        </button>
    
                                        <div class="dropdown-menu dropdown-menu-right div-main-actions">

                                            <a 
                                                id="btn-apms-delivery-in-route"
                                                appCheckFeaturesConfig [featureName]="[Permissions.Apm_delivery_reschedule]"
                                                *ngIf="row.apmDelivery.logisticType !== 'OWN'" 
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                                [routerLink]="['/apm', row.apmDelivery.id, 'rlms-in-route', {apmId: apmId, slotTime: slotTime}]">
                                                    {{'Apms delivery in route' | translate }}
                                            </a>

                                            <a 
                                                id="btn-reschedule-to-another-apm"
                                                appCheckFeaturesConfig [featureName]="[Permissions.Apm_delivery_reschedule]"
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                                [routerLink]="['/partner', partnerService.id, 'apm-delivery', 'reschedule', row.apmDelivery.id, {apmId: apmId, slotTime: slotTime}]">
                                                    {{'Reschedule to another apm' | translate }}
                                            </a>

                                            <a 
                                                id="btn-convert-d2d"
                                                appCheckFeaturesConfig [featureName]="[Permissions.Apm_delivery_reschedule]"
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                                [routerLink]="['/partner', partnerService.id, 'apm-delivery', 'convert-d2d', row.apmDelivery.id, {apmId: apmId, slotTime: slotTime}]">
                                                    {{'Convert to d2d' | translate }}
                                            </a>

                                            <a 
                                                id="btn-audit-log"
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item"
                                                *ngIf="row.apmDelivery.status === apmDeliveryStatus.Superseded"
                                                (click)="showDeliveryAuditLog(row.apmDelivery.id)">
                                                    {{'Audit log' | translate }}
                                            </a>

                                        </div>
                                    </div>
                                </div>
                                <div *ngSwitchCase="'apmDelivery.reservationNumber'">
                                    {{ value }}<br />
                                    <span *ngIf="row.supersededBy"><strong>{{'Superseded by' | translate}}</strong>:<br />{{row.apmDelivery.supersededBy}}</span>
                                </div>
                                <div *ngSwitchCase="'select'">
                                    <ng-container *ngIf="![apmDeliveryStatus.Reserved, apmDeliveryStatus.Cancelled, apmDeliveryStatus.Superseded].includes(row.apmDelivery.status)">
                                        <input [checked]="selectedDeliveries.includes(row.apmDelivery.id)" type="checkbox" (click)="selectedRow(row.apmDelivery.id)" class="custom-control-input" id="customCheck{{row.apmDelivery.id}}">
                                        <label class="custom-control-label text-center justify-content-start mg-l-25" for="customCheck{{row.apmDelivery.id}}">&nbsp;</label>
                                    </ng-container>
                                </div>

                                <div *ngSwitchCase="'apmDelivery.apm'">{{ value.address | addressLabelPipe}}</div>
                                <div *ngSwitchCase="'apmDelivery.lcount'">
                                    <span 
                                        [containerClass]="'pretty-tooltip'" 
                                        placement="right" 
                                        [tooltip]="row.apmDelivery | rlmCapacityLabelPipe">{{row.apmDelivery | rlmCapacityLabelShortPipe}}</span>
                                </div>

                                <div *ngSwitchCase="'apmDelivery.status'">

                                    <ng-container [ngSwitch]="value">
                                        <span *ngSwitchCase="apmDeliveryStatus.Cancelled" class="badge badge-danger">{{ 'ApmDeliveryStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmDeliveryStatus.Completed" class="badge badge-success">{{ 'ApmDeliveryStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmDeliveryStatus.New" class="badge badge-warning">{{ 'ApmDeliveryStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmDeliveryStatus.Reserved"  
                                            [containerClass]="'pretty-tooltip'" 
                                            placement="left" 
                                            [tooltip]="'Please, contact with your partner to handle the delivery' | translate"
                                            class="badge badge-info">{{ 'ApmDeliveryStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmDeliveryStatus.Used" class="badge badge-secondary">{{ 'ApmDeliveryStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmDeliveryStatus.Not_used" class="badge badge-primary">{{ 'ApmDeliveryStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmDeliveryStatus.Expired" class="badge badge-dark">{{ 'ApmDeliveryStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmDeliveryStatus.Reminded" class="badge badge-light">{{ 'ApmDeliveryStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmDeliveryStatus.Stored" class="badge badge-secondary">{{ 'ApmDeliveryStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmDeliveryStatus.Cancelled_Post_Cutoff" class="badge badge-danger">{{ 'ApmDeliveryStatus.'+value | translate }}</span>
                                    
                                        <span *ngSwitchCase="apmDeliveryStatus.Superseded" 
                                            [containerClass]="'pretty-tooltip'" 
                                            placement="left" 
                                            [tooltip]="row.apmDelivery.reason | reasonTranslatePipe | translate"
                                            class="badge badge-success">{{ 'ApmDeliveryStatus.'+value | translate }}</span>

                                        <span *ngSwitchDefault class="badge badge-light">{{ 'ApmDeliveryStatus.'+value | translate }}</span>
                                    </ng-container>
                                </div>
                                <div *ngSwitchCase="'apmDelivery.reservationWindow'">
                                    {{ value.from | amLocale: locale | amDateFormat: 'D MMM, HH:mm' }} - {{ value.to | amLocale: locale | amDateFormat: 'D MMM, HH:mm' }}
                                </div>
                                <div *ngSwitchCase="'apmDelivery.logisticType'">
                                    <ng-container [ngSwitch]="value">
                                        <span *ngSwitchCase="'OWN'">{{ 'ApmDeliveryType.'+value | translate }}</span>
                                        <span *ngSwitchCase="'INPOST'">{{ 'ApmDeliveryType.'+value | translate }}</span>
                                        <span *ngSwitchCase="'MIXED'">{{ 'ApmDeliveryType.'+value | translate }}</span>
                                        <span *ngSwitchDefault>{{ 'ApmDeliveryType.'+value | translate }}</span>
                                    </ng-container>
                                </div>

                                <div *ngSwitchDefault>{{value | translate}}</div>
                            </div>

                        </ng-template>
                    </ngx-datatable-column>
                </ng-container>
            </ngx-datatable>
        </div>

        <div *ngIf="loader" class="load-spinner text-center">
            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}
        </div>
    </div>

</layout-default>

<!-- {{'Converted to D2D ' | translate }} -->
<!-- {{'Booked to replacement vehicle.' | translate }} -->