import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ApmDelivery } from '../../entities/apm-delivery';
import { DateRange, ORDER, LogisticType } from '@enums/enum';
import { PageableResponse } from '@entities/pagable-response';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, forkJoin, Subscription } from 'rxjs';
import { ApmDeliveryService } from '../../services/apm-delivery.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { NavService } from '@services/nav.sevice';
import { ToastrProviderService } from '@services/toastr-provider.service';
import * as _ from 'lodash';
import * as SubscriptionEntity from '../../entities/subscription';
import { Apm } from '../../entities/apm';
import { Partner } from '../../entities/partner';
import { environment } from '@environment';
import { QueryParams } from '@interfaces/query-params.interface';
import { tap, map } from 'rxjs/operators';
import { UtilsService } from '@services/utils.service';
import { ApmDeliveryStatus } from '../../enums/apm-delivery-status.enum';
import * as moment from 'moment';
import { Permissions } from '@enums/permissions';
import { AngularMultiSelectOptions } from '@shared/constants/multiselect-dropdown.constants';

@Component({
    selector: 'app-apm-delivery',
    templateUrl: './apm-delivery.component.html',
    styleUrls: ['./apm-delivery.component.scss'],
})
export class ApmDeliveryComponent implements OnInit {
    @ViewChild('confirmationDiscountedAction', {static: true}) public confirmationDiscountedAction: TemplateRef<any>;
    @ViewChild('rlmDeliveryDetails', {static: true}) public rlmDeliveryDetails: TemplateRef<any>;

    public apmDelivery: ApmDelivery[];
    public list$: Observable<PageableResponse<ApmDelivery>>;
    public subscriptions: SubscriptionEntity.Subscription[];
    public apms: Apm[];
    public partners: Partner[];
    public selectedApms;
    public dropdownList: {id: string, itemName: string}[] = [];

    public loader: boolean = true;
    private dateRange: DateRange;
    public Permissions = Permissions;
    public logisticType = LogisticType;

    private partnerId: string = null;
    public partner: Partner;
    public selectedRegion = null;
    public selectedStatus = null;
    public apmDeliveryStatus = ApmDeliveryStatus;
    public requests: Observable<any>[] = [];
    public alternateApmDelivery: ApmDelivery[];
    public forkJoinSubscription: Subscription;
    public discountedReason: string;
    private modalArgs: {values: {apmDelivery?: ApmDelivery, apmDeliveryId?: string}} = { values: {} };

    protected queryParams: QueryParams = Object.assign({}, environment.pagination);
    public rowClass;
    public modifiedRow: string;
    public locale = 'PL-pl';

    public tableMessages = {
        emptyMessage: this.translateService.instant('No data to display'),
    };

    public dropdownSettings = AngularMultiSelectOptions;

    public columns = [
        { prop: 'detail', name: '', flexGrow: 1, sortable: false },
        { prop: 'reservationReference', name: this.translateService.instant('Reservation ref'), flexGrow: 3, sortable: true },
        { prop: 'reservationNumber', name: this.translateService.instant('Reservation number'), flexGrow: 3, sortable: true },
        { prop: 'apm', name: this.translateService.instant('Apm'), flexGrow: 4, sortable: true },
        { prop: 'lcount', name: this.translateService.instant('Capacity'), flexGrow: 2, sortable: true },
        { prop: 'reservationWindow.from', name: this.translateService.instant('Reservation window'), flexGrow: 3, sortable: true },
        { prop: 'logisticType', name: this.translateService.instant('Logistic type'), flexGrow: 3, sortable: false },
        { prop: 'status', name: this.translateService.instant('Status'), flexGrow: 3, sortable: true },
        { prop: 'discounted', name: this.translateService.instant('Discounted'), flexGrow: 2, sortable: true },

        { prop: 'options', name: this.translateService.instant('Options'), flexGrow: 2, sortable: false },
    ];

    @ViewChild('confirmation', { static: true }) public confirmation: TemplateRef<any>;
    public modalRef: BsModalRef;
    public confirmObservable: Observable<any>;

    constructor(
        private apmDeliveryService: ApmDeliveryService,
        private navigationRoute: ActivatedRoute,
        private translateService: TranslateService,
        private modalService: BsModalService,
        private toastrProviderService: ToastrProviderService,
        private navService: NavService,
        private readonly utilsService: UtilsService
    ) {}

    public ngOnInit() {
        this.translateService.onLangChange.subscribe((lang: LangChangeEvent) => {
            this.locale = lang.lang;
        });

        this.navigationRoute.params.subscribe((params) => {
            this.queryParams.sort = 'createdAt';
            this.partnerId = this.utilsService.findUpRouteParams(this.navigationRoute.snapshot, 'partnerId');
            this.apmDeliveryService.fetchAll({ ...this.queryParams, partnerId: this.partnerId, regionId: this.selectedRegion });
        });

        this.partner = (this.navigationRoute.snapshot.data as {partner: any}).partner;
        this.apms = (this.navigationRoute.snapshot.data as {apms: any}).apms.content;

        console.log(this.partner);

        this.list$ = this.apmDeliveryService.list$.pipe(
            tap((list: PageableResponse<ApmDelivery>) => {
                this.apmDelivery = list.content;

                this.requests = [];
                this.alternateApmDelivery = [];

                const filtered = list.content.filter((r: ApmDelivery) => r.status === ApmDeliveryStatus.Superseded && r.supersededBy !== null);
                filtered.map((r) => this.requests.push(this.apmDeliveryService.getLastVersionOfApmDelivery(r.id)));
                if (this.forkJoinSubscription) {
                    this.forkJoinSubscription.unsubscribe();
                }
                this.getCurrentVersion();
            })
        );

        this.modifiedRow = this.navigationRoute.snapshot.params.modifiedRow;

        this.rowClass = (row) => {
            return { 'row-modified': row.id === this.modifiedRow };
        };

        this.apms.forEach((apm: Apm) => this.dropdownList.push({id: apm.id, itemName: apm.code}))

        this.loader = false;
    }

    public setPage(pageInfo?) {
        this.queryParams.page = pageInfo.offset;
        this.apmDeliveryService.fetchAll({ ...this.queryParams, partnerId: this.partnerId, regionId: this.selectedRegion });
    }

    public onSort(sortInfo) {
        this.queryParams.sort = sortInfo.sorts[0].prop;
        if (this.queryParams.sort === 'reservationWindow.from') {
            this.queryParams.sort = 'deliveryWindow.from';
        }
        this.queryParams.order = sortInfo.sorts[0].dir === 'asc' ? ORDER.ASC : ORDER.DESC;
        this.apmDeliveryService.fetchAll({ ...this.queryParams, partnerId: this.partnerId, regionId: this.selectedRegion });
    }

    public isOptionsColumn(status: string): boolean {
        return status === 'options';
    }

    public isColumnToTranslate(status: string): boolean {
        return status === 'logisticType';
    }

    public isColumnCapacity(status: string): boolean {
        return status === 'capacityLabel';
    }

    public goToDetails(id: string): void {
        this.navService.goToPage(`/apm-delivery/edit/${id}`);
    }

    private getCurrentVersion() {
        this.forkJoinSubscription = forkJoin(...this.requests)
            .pipe(
                map((responses) => {
                    this.alternateApmDelivery = responses;
                })
            )
            .subscribe();
    }

    public changedDateRange(event): void {
        this.queryParams.startDate = moment(event.dateStart).format('YYYY-MM-DD');
        this.queryParams.endDate = moment(event.dateEnd).format('YYYY-MM-DD');
        this.dateRange = event.dateRange;
        this.apmDeliveryService.fetchAll({ ...this.queryParams, partnerId: this.partnerId, regionId: this.selectedRegion });
    }

    public applyFiltering(event) {
        const filterPhrase = event.target.value.toLowerCase();
        this.queryParams.page = 0;
        this.queryParams.filter = filterPhrase;
        this.apmDeliveryService.fetchAll({ ...this.queryParams, partnerId: this.partnerId, regionId: this.selectedRegion });
    }

    public removeApmDelivery(id: string): void {
        this.modalRef = this.modalService.show(this.confirmation, { class: 'modal-sm' });
        this.confirmObservable = this.apmDeliveryService.delete(id);
    }

    public filterByRegion(event) {
        this.selectedRegion = event;
        this.queryParams.status = this.selectedStatus;
        this.apmDeliveryService.fetchAll({ ...this.queryParams, partnerId: this.partnerId, regionId: this.selectedRegion });
    }

    public filterByStatus(event) {
        this.selectedStatus = event;
        this.queryParams.status = this.selectedStatus;
        this.apmDeliveryService.fetchAll({ ...this.queryParams, partnerId: this.partnerId, regionId: this.selectedRegion });
    }

    public confirm(): void {
        this.confirmObservable.subscribe((test) => {
            this.modalRef.hide();
            this.queryParams.page = 0;
            this.apmDeliveryService.fetchAll({ ...this.queryParams, partnerId: this.partnerId });
            this.toastrProviderService.showSuccess(this.translateService.instant('The apm delivery has been removed!'));
            this.confirmObservable = null;
        });
    }

    public applyDiscount() {
        this.apmDeliveryService.discounted(this.modalArgs.values.apmDelivery.id, !this.modalArgs.values.apmDelivery.discounted, this.discountedReason).subscribe((test) => {
            this.modalRef.hide();
            this.queryParams.page = 0;
            this.apmDeliveryService.fetchAll({ ...this.queryParams, partnerId: this.partnerId });
            this.confirmObservable = null;
            this.discountedReason = undefined;
        });
    }

    public onItemSelect() {
       this.queryParams.apmIds = this.selectedApms.map(a => a.id);
       this.apmDeliveryService.fetchAll({ ...this.queryParams, partnerId: this.partnerId, regionId: this.selectedRegion });

    }

    public discountedChanged(event, apmDelivery: ApmDelivery) {
        event.preventDefault();
        this.modalArgs.values = { apmDeliveryId: apmDelivery.id, apmDelivery: apmDelivery };
        this.modalRef = this.modalService.show(this.confirmationDiscountedAction, { class: 'modal-sm' });
    }

    public showRlmDeliveryDetails(event, apmDelivery: ApmDelivery) {
        event.preventDefault();
        const path = event.path ? event.path : event.composedPath();
        const isInside = path.filter(item => !_.isUndefined(item.classList) && item.classList.value.includes('custom-control-input'));

        if (event.type === 'click' && !isInside.length) {
            this.modalArgs.values = { apmDelivery: apmDelivery };
            this.modalRef = this.modalService.show(this.rlmDeliveryDetails, { class: 'modal-lg' });
        }
    }

    public decline(): void {
        this.confirmObservable = null;
        this.modalRef.hide();
        this.discountedReason = undefined;
    }

    public isColumnApm(status: string): boolean {
        return status === 'apm';
    }

    public isColumnStatus(status: string): boolean {
        return status === 'status';
    }
}
