import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormGroup, FormArray, AbstractControl, FormControl } from '@angular/forms';
import { RegionService } from '../../services/region.service';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '@services/nav.sevice';
import { Driver } from '@drivers/entities/driver';
import { Region } from '../../entities/region';
import { Partner } from '../../entities/partner';
import { plainToClass } from 'class-transformer';
import { DriverStatus } from '@enums/enum';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { AssignDriverToRegion } from '../../entities/assign-driver-to-region';
import * as _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

export interface AssignedDriversInterface {driver: Driver, id: string, regionId: string, username: string}

@Component({
  selector: 'app-assign-driver',
  templateUrl: './assign-driver.component.html',
  styleUrls: ['./assign-driver.component.scss']
})
export class AssignDriverComponent implements OnInit {

  public partner: Partner;
  public region: Region;
  public drivers: Driver[];
  public assignDrivers: AssignedDriversInterface[];

  public driversForm: FormArray;

  public rawDrivers: any[] = [];

  public loader: boolean = true;
  public submitted: boolean = false;

  public form: FormGroup;

  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  private removedDriverId: number;

  constructor(
    private navigationRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private regionService: RegionService,
    private modalService: BsModalService,
    private toastrProviderService: ToastrProviderService,
    private translateService: TranslateService,
    private navService: NavService
  ) { }

  public ngOnInit() {

    this.drivers = ((this.navigationRoute.snapshot.data as {drivers: any}).drivers).filter((d: Driver) => d.status === DriverStatus.ACTIVE);
    this.partner = plainToClass(Partner, (this.navigationRoute.snapshot.data as {partner: any}).partner as Partner);
    this.region = plainToClass(Region, (this.navigationRoute.snapshot.data as {region: any}).region as Region);
    this.assignDrivers = (this.navigationRoute.snapshot.data as {assignDrivers: any}).assignDrivers;

    console.log(this.assignDrivers);

    this.initForm();
    this.prepareDrivers();
  }

  public prepareDrivers() {
    this.drivers.map((d: Driver) => {
      const isExist = this.assignDrivers.find((dr: AssignedDriversInterface) => dr.driver.id === d.id);
      if (d.status === DriverStatus.ACTIVE && isExist === undefined){
        this.rawDrivers.push({
          id: d.id, 
          username: d.fullName
        });
      }
    });

    this.loader = false;
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      drivers: this.formBuilder.array([])
    })

    this.driversForm = <FormArray>this.form.controls['drivers'];
    if (this.assignDrivers.length) {
      this.assignDrivers.forEach((item) => this.driversForm.push(this.patchValues(item)));
    }

    this.driversForm.push(this.patchValues());
  }

  private patchValues(item?: AssignedDriversInterface): AbstractControl {
    return this.formBuilder.group({
      id: [{value: item ? item.id : null, disabled: item ? true : false}],
      fullName: [{value: item ? item.driver.fullName : null, disabled: item ? true : false}],
      nationality: [{value: item ? this.translateService.instant(item.driver.nationality) : null, disabled: item ? true : false}],
      experience: [{value: item ? this.translateService.instant(item.driver.experience) : null, disabled: item ? true : false}],
    })
  }



  public selectEvent(item) {
    const driver: Driver = this.drivers.find((d: Driver) => d.id === item['id']);
    const num: number = this.driversForm.controls.length;

    this.driversForm.controls[num - 1].setValue({
      id: driver.id,
      fullName: driver.fullName,
      nationality: this.translateService.instant(driver.nationality),
      experience: this.translateService.instant(driver.experience)
    }, {onlySelf: true});

    _.remove(this.rawDrivers, (d: Driver) => d.id === driver.id);

    this.addStep();
  }

  public removeStep(i): void {
    console.log((<FormArray>this.form.controls['drivers']).controls[i].value);
    this.removedDriverId = i;
    const assigningId: string = (<FormArray>this.form.controls['drivers']).controls[i].value['id'];
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.regionService.unassignDriverFromRegion(this.partner.id, this.region.id, assigningId);
  }

  public confirm(): void {
    this.confirmObservable.subscribe((test) => {
      this.modalRef.hide();
      (<FormArray>this.form.controls['drivers']).removeAt(this.removedDriverId);
      this.toastrProviderService.showSuccess(this.translateService.instant('Driver assignment to region has been removed!'));
      this.confirmObservable = null;
    });
  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }

  public addStep(): void {
    this.driversForm.push(this.patchValues());
  }

  public isSetValue(index: number): boolean {
    return ((this.driversForm.controls[index] as FormControl).value['fullName']);
  }
 
  public onSubmit() {

    const form = this.form.getRawValue();
    const body: string[] = [];

    _.forEach(_.values(form['drivers']), (d: Driver) => d.id ? body.push(d.id) : null)
    this.submitted = true;
    this.regionService.assignDriverToRegion(this.partner.id, this.region.id, body)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (region: AssignDriverToRegion) => {
          console.log(region);
          this.toastrProviderService.showSuccess(this.translateService.instant('Drivers has been assigned to region!'));
          this.navService.goToPage(`/partner/${this.partner.id}/region/list`, {modifiedRow: this.region.id});
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );

    
  }

}
