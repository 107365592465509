import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TimeslotService } from '../services/timeslot.service';
import { UtilsService } from '@services/utils.service';

@Injectable()
export class TimeslotsResolver implements Resolve<any> {
    constructor(
        private readonly timeslotService: TimeslotService,
        private readonly utilsService: UtilsService
    ) { }

    public resolve(route: ActivatedRouteSnapshot) {

        const params = {
            partnerId: this.utilsService.findUpRouteParams(route, 'partnerId'),
            regionId: this.utilsService.findUpRouteParams(route, 'regionId')
        }

        return (params.regionId) 
            ? this.timeslotService.getTimeSlotsForRegion(params.partnerId, params.regionId)
            : this.timeslotService.getTimeSlotsForPartner(params.partnerId);
    }

}
