import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EntityValidationError } from '@shared/services/entity-validation-error.service';
import { FormService } from '@shared/services/form.service';
import { NavService } from '@shared/services/nav.sevice';
import { SharedModule } from '../shared.module';
import { AuthenticationComponent } from './components/authentication.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { PageNotFoundComponent } from './components/page-not-found.component';
import { FindProviderIdpUrlPipe } from './pipes/find-provider-idpUrl.pipe';
import { OauthRedirectComponent } from './components/oauth-redirect/oauth-redirect.component';

@NgModule({
    imports: [
        CommonModule, 
        SharedModule, 
        FormsModule
    ],
    declarations: [
        AuthenticationComponent, 
        PageNotFoundComponent, 
        FindProviderIdpUrlPipe, OauthRedirectComponent
    ],
    providers: [
        FormService, 
        NavService, 
        EntityValidationError, 
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ]
})
export class AuthenticationModule {}
