import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormArray } from '@angular/forms';
import { PartnerService } from '../../services/partner.service';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '@services/nav.sevice';
import { CRUD_MODE } from '@enums/enum';
import { Partner } from '../../entities/partner';
import { Address } from '@entities/address';
import * as _ from 'lodash';
import { Additional } from '@entities/additional';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { PartnerUser } from '../../interfaces/partneruser.interface';
import { PartnerUserService } from '../../services/partner-user.service';
import { UtilsService } from '@services/utils.service';
import { PartnerUserRoles } from '@enums/roles';

@Component({
  selector: 'app-edit-partner-user',
  templateUrl: './edit-partner-user.component.html',
  styleUrls: ['./edit-partner-user.component.scss']
})
export class EditPartnerUserComponent implements OnInit {

  public user: PartnerUser;
  public mode: CRUD_MODE;

  public form: FormGroup;
  public loader: boolean = true;
  public submitted: boolean = false;
  public partnerId = null;
  public rolesCtrl: FormArray;
  public roles = Object.values(PartnerUserRoles);

  constructor(
    private navigationRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private partnerUserService: PartnerUserService,
    private toastrProviderService: ToastrProviderService,
    private translateService: TranslateService,
    private navService: NavService,
    private activatedRoute: ActivatedRoute,
    private readonly utilsService: UtilsService
  ) { }

  public ngOnInit() {
    this.user = (this.navigationRoute.snapshot.data as {user: any}).user;

    this.activatedRoute.params.subscribe(params => {
      this.partnerId = this.utilsService.findUpRouteParams(this.navigationRoute.snapshot, 'partnerId');
      this.mode = (this.user) ? CRUD_MODE.UPDATE : CRUD_MODE.CREATE;
      this.initForm();
    });
  }

  private initForm() {
    this.form = this.formBuilder.group({
      email: [this.user ? this.user.email : null, [Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')]],
      firstName: [this.user ? this.user.firstName : null, Validators.required],
      id: [this.user ? this.user.id : null],
      lastName: [this.user ? this.user.lastName : null, Validators.required],
      password: [null, [Validators.minLength(8)]],
      canManageUsers: [this.user ? this.user.canManageUsers : true, Validators.required],
      partnerId: [this.user ? this.user.partnerId : this.partnerId, Validators.required],
      roles: this.formBuilder.array([]),
      version: [this.user ? this.user.version : null]
    });

    if (this.mode === CRUD_MODE.CREATE) {
      this.form.get('password').setValidators([Validators.required, Validators.minLength(8)]);
    }

    this.rolesCtrl = <FormArray>this.form.controls['roles'];
    if (this.roles.length) {
      this.roles.forEach((item) => this.rolesCtrl.push(this.patchValues(item)));
    }

    this.loader = false;
  }

  public regularFormItems(): string[] {
    return ['firstName', 'lastName', 'email']
  }

  private patchValues(name: string): AbstractControl {

    const enabled = (this.user) ? (this.user.roles.find(i => i === name) !== undefined) ? true : false : false;
    return this.formBuilder.group({
      name: [name],
      enabled: [enabled],
    })
  }

  public onSubmit() {

    const raw = this.form.getRawValue();
    raw.roles = raw.roles.map((r: {name: string, enabled: boolean}) => (r.enabled) ? r.name : false).filter(Boolean)

    console.log(raw);

    if (this.mode === CRUD_MODE.CREATE) {
      this.create(raw);
    } else {
      this.update(raw);
    }
  }

  private create(raw): void {
    this.submitted = true;
    this.partnerUserService.savePartnerUser(this.partnerId, raw)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (partner: PartnerUser) => {
          this.toastrProviderService.showSuccess(this.translateService.instant('New user partner has been created!'));
          this.navService.goToPage(`/partner/${this.partnerId}/users/list`, {modifiedRow: partner.id});
          this.submitted = false;
        },
        (error) => {
          console.log(`Error occurred, please try again!`, error);
          this.toastrProviderService.showError(this.translateService.instant(error.message));

          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }

  private update(raw): void {
    this.submitted = true;
    this.partnerUserService.updatePartnerUser(this.partnerId, raw)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (partner: PartnerUser) => {
         this.toastrProviderService.showSuccess(this.translateService.instant('The user partner has been updated!'));
          this.navService.goToPage(`/partner/${this.partnerId}/users/list`, {modifiedRow: partner.id});
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }

}
