<layout-default>
    <div header>
        <div toolbar>
            <h4 *ngIf="mode === 'CREATE'">
                <i-feather name="database"></i-feather> {{'Create courier for region' | translate}}
            </h4>
            <h4 *ngIf="mode === 'UPDATE'">
                <i-feather name="database"></i-feather> {{'Edit courier' | translate}}: {{partnerCourier.firstName}} {{partnerCourier.lastName}}
            </h4>
        </div>

        <hr />
    </div>
    
    <div content>
        <div *ngIf="!loader" class="content bg-white">

            <div class="card-content col-8 offset-2">

                <form [formGroup]="form" autocomplete="off">

                    <div id="partner-courier-edit-form-{{item}}" *ngFor="let item of regularFormControl()" class="form-group row">

                        <label [for]="item" class="col-2">
                            <span>{{item.toUpperCase() | translate}}:&nbsp;</span>
                            <span *ngIf="form.get(item) | isRequiredControlPipe">*</span>&nbsp;
                            <i-feather *ngIf="tooltipLabel(item)" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(item)"
                                [style.width.px]="13" name="info"></i-feather>
                        </label>

                        <div class="col-10">
                            <input type="text" [formControlName]="item" class="form-control" />
                            <div *ngIf="form.get(item).hasError('required') && form.get(item).touched" class="error-color">
                                {{ "The field is required" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div id="partner-courier-edit-form-contact-phone" class="form-group row">
                        <label class="col-2" for="contactPhone">{{'Contact phone' | translate}}:&nbsp;<span *ngIf="form.get('contactPhone') | isRequiredControlPipe">*</span></label>
                        <div class="col-10">
                            <input prefix="+48 " mask="000-000-000" formControlName="contactPhone" class="form-control" />
                            <div *ngIf="form.get('contactPhone')?.hasError('required') && form.get('contactPhone')?.touched"
                                class="error-color">
                                {{ "The field is required" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div id="partner-courier-edit-form-document-number" *ngIf="mode !== 'CREATE'" class="form-group row">
                        <label class="col-2" for="contactPhone">{{'Document number' | translate}}:&nbsp;<span *ngIf="form.get('documentNumber') | isRequiredControlPipe">*</span></label>
                        <div class="col-10">
                            <input formControlName="documentNumber" class="form-control" />
                        </div>
                    </div>

                    <div id="partner-courier-edit-form-password" class="form-group row">
                        <label class="col-2" for="password">{{'Password' | translate}}:&nbsp;<span *ngIf="form.get('password') | isRequiredControlPipe">*</span></label>
                        
                        <div class="col-10">

                            <div class="input-group mb-2">
                                <div class="input-group-prepend" (touchstart)="showPassword = true"  (mousedown)="showPassword = true" (touchend)="showPassword = false"  (mouseup)="showPassword = false">
                                    <div class="input-group-text">
                                        <i *ngIf="showPassword" class="far fa-eye"></i>
                                        <i *ngIf="!showPassword" class="far fa-eye-slash"></i>
                                </div>
                                </div>
                                <input [type]="(showPassword) ? 'text' : 'password'" formControlName="password" class="form-control form-password-control" autocomplete="new-password"/>
                            </div>

                            <div *ngIf="form.get('password')?.hasError('minlength') && form.get('password')?.touched"
                                class="error-color">
                                {{ "The password should contain at least 8 characters" | translate }}!
                            </div>
                        
                        </div>
                    </div>

                    <div id="partner-courier-edit-form-{{item}}" *ngFor="let item of selectFormControl()" class="form-group row">
                        <label class="col-2" [for]="item">{{item.toUpperCase() | translate}}:&nbsp;<span *ngIf="form.get(item) | isRequiredControlPipe">*</span></label>
                        <div class="col-10">
                            <select class="custom-select" [formControlName]="item">
                                <option [value]="true">{{'Yes' | translate}}</option>
                                <option [value]="false">{{'No' | translate}}</option>
                            </select>

                            <div *ngIf="form.get(item)?.hasError('required') && form.get(item)?.touched" class="error-color">
                                    {{ "Field is required" | translate }}!
                            </div>
                        </div>
                    </div>   
                       

                    <div *ngIf="formErrorsMessages && formErrorsMessages.length" class="form-group row">
                        <div class="col-10 offset-2">
                            <p class="error-color">{{ 'Something has wrong! Please correct form' | translate }}:</p>
                            <ul>
                                <li *ngFor="let error of formErrorsMessages" class="error-color">
                                    <span *ngIf="error.value">{{ 'Field' | translate }}</span>
                                    <strong>{{ error.field | translate }}</strong>
                                    <span>{{ error.value | translate }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="form-group row justify-content-end">
                        <div id="partner-courier-edit-form-btn-save" class="col-2">
                            <button (click)="onSubmit()" [disabled]="!form.valid || submitted"
                                class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01 w-100">

                                <span *ngIf="!submitted">{{ "Save" | translate }}</span>

                                <ng-container *ngIf="submitted">
                                    <div class="load-spinner text-center">
                                        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                    </div>
                                </ng-container>

                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</layout-default>

<!-- {{'USERNAME' | translate }} -->
<!-- {{'DOCUMENTNUMBER' | translate }} -->
<!-- {{'tooltips.partnercourier.labels.code' | translate -->
