
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ApmDeliveryService } from '../services/apm-delivery.service';
import { UtilsService } from '@services/utils.service';

@Injectable()
export class AmpDeliveryResolver implements Resolve<any> {
    
    constructor(
        private apmDeliveryService: ApmDeliveryService,
        private utilsService: UtilsService
        ) { }
    public resolve(route: ActivatedRouteSnapshot) {
        const params = {
            partnerId: this.utilsService.findUpRouteParams(route, 'partnerId'),
            id: this.utilsService.findUpRouteParams(route, 'id')
        }
        return this.apmDeliveryService.getApmDelivery(params.id);
    }
}
