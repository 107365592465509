import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { Notification } from '../../interfaces/notification';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environment';
import { QueryParams } from '@interfaces/query-params.interface';
import { tap } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { Permissions } from '@enums/permissions';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {

  public notifications$: Observable<Notification[]>;

  protected queryParams: QueryParams = Object.assign({}, environment.pagination);
  public loader: boolean = true;
  public Permissions = Permissions;
  public destroy$ = new Subject<boolean>();

  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  public columns = [
    // { prop: 'priority', name: this.translateService.instant('Priority'), flexGrow: 1, sortable: true },
    { prop: 'subject', name: this.translateService.instant('Subject'), flexGrow: 2, sortable: true },
    { prop: 'body', name: this.translateService.instant('Body'), flexGrow: 4, sortable: true },
    // { prop: 'from', name: this.translateService.instant('From'), flexGrow: 2, sortable: true },
    { prop: 'deepLinkType', name: this.translateService.instant('Type'), flexGrow: 2, sortable: true },
    { prop: 'deepLinkId', name: this.translateService.instant('Link Id'), flexGrow: 4, sortable: true },
    { prop: 'date', name: this.translateService.instant('Date'), flexGrow: 2, sortable: true },
    { prop: 'options', name: this.translateService.instant('Options'), flexGrow: 3, sortable: false},
  ];

  constructor(
    private readonly notificationService: NotificationService,
    private readonly navigationRoute: ActivatedRoute,
    private readonly translateService: TranslateService,
  ) { }

  public ngOnInit() {
   this.getNotifications();
  }

  public getNotifications(): void {
    this.notifications$ = this.notificationService.getNew().pipe(tap(() => this.loader = false));
  }

  public markAsRead(item: Notification) {
    this.notificationService.markAsRead(item.id).pipe(
      takeUntil(this.destroy$),
    ).subscribe(
      () => {
        this.loader = true;
        this.notifications$ = undefined;
        setTimeout(() => this.getNotifications(), 200)
      }
    )
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();  
  }
}
