import { Component, OnInit } from '@angular/core';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import enLocale from 'date-fns/locale/en-GB';
import plLocale from 'date-fns/locale/pl';
import * as _ from 'lodash';
import * as moment from 'moment';

import { datePickerOptions } from '@shared/constants/datepicker-options.constants';
import { ShiftType } from '@shared/enums/enum';

import { IRoutingEfficiencyResults } from '../../interfaces/routing-efficiency-results.interface';
import { IRoutingEfficiency } from '../../interfaces/routing-efficiency.interface';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
    selector: 'app-routing-efficiency',
    templateUrl: './routing-efficiency.component.html',
    styleUrls: ['./routing-efficiency.component.scss']
})
export class RoutingEfficiencyComponent implements OnInit {
    get shiftType() {
        return _.keys(ShiftType);
    }

    private timeFormat: string = 'YYYY-MM-DD';

    public startDate = moment()
        .add(-1, 'week')
        .toString();
    public endDate = moment().toString();
    public datePickerOptions: any = datePickerOptions;
    public routingEfficiencyResults: IRoutingEfficiencyResults[] = null;
    public periodOfTime: Array<IRoutingEfficiency> = [
        {
            label: this.translate.instant('Yesterday'),
            startDate: moment()
                .add(-1, 'day')
                .format(this.timeFormat),
            endDate: moment()
                .add(-1, 'day')
                .format(this.timeFormat)
        },
        {
            label: this.translate.instant('This week'),
            startDate: moment()
                .startOf('isoWeek')
                .format(this.timeFormat),
            endDate: moment()
                .endOf('isoWeek')
                .format(this.timeFormat)
        },
        {
            label: this.translate.instant('Previous week'),
            startDate: moment()
                .startOf('isoWeek')
                .add(-1, 'week')
                .format(this.timeFormat),
            endDate: moment()
                .endOf('isoWeek')
                .add(-1, 'week')
                .format(this.timeFormat)
        },
        {
            label: this.translate.instant('This month'),
            startDate: moment()
                .startOf('month')
                .format(this.timeFormat),
            endDate: moment()
                .endOf('month')
                .format(this.timeFormat)
        },
        {
            label: this.translate.instant('Previous month'),
            startDate: moment()
                .startOf('month')
                .add(-1, 'month')
                .format(this.timeFormat),
            endDate: moment()
                .endOf('month')
                .add(-1, 'month')
                .format(this.timeFormat)
        },
        {
            label: this.translate.instant('This quarter'),
            startDate: moment()
                .startOf('quarter')
                .format(this.timeFormat),
            endDate: moment()
                .endOf('quarter')
                .format(this.timeFormat)
        },
        {
            label: this.translate.instant('This year'),
            startDate: moment()
                .startOf('year')
                .format(this.timeFormat),
            endDate: moment()
                .endOf('year')
                .format(this.timeFormat)
        }
    ];

    public locale: any = null;
    public loading: boolean = false;

    constructor(private analyticsService: AnalyticsService, private translate: TranslateService) {
        this.locale = translate.currentLang === 'en' ? enLocale : plLocale;
    }

    public ngOnInit() {
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.locale = event.lang === 'en' ? enLocale : plLocale;
            this.datePickerOptions.locale = this.locale;
        });

        this.analyticsService.getRoutingEfficiency(this.periodOfTime).subscribe((result: IRoutingEfficiencyResults[]) => {
            this.routingEfficiencyResults = result;
        });
    }

    public findResult(startDate, endDate, shift): string {
        const res = this.routingEfficiencyResults.find((o: IRoutingEfficiencyResults) => o.label.includes(`${startDate} - ${endDate} ${shift}`));
        return res ? res.avgDeliveriesPerRoute.toFixed(2) : '0';
    }

    public addNewTimePeriod(): void {
        const start = moment(this.startDate);
        const end = moment(this.endDate);

        const body: IRoutingEfficiency[] = [
            {
                label: `${start.format('DD MMMM YYYY')} - ${end.format('DD MMMM YYYY')}`,
                startDate: start.format(this.timeFormat),
                endDate: end.format(this.timeFormat)
            }
        ];

        this.analyticsService.getRoutingEfficiency(body).subscribe((result: IRoutingEfficiencyResults[]) => {
            this.routingEfficiencyResults.push(...result);
            this.periodOfTime.push(...body);
        });
    }
}