import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment';
import { Apm } from '../entities/apm';

@Pipe({
    name: 'timeWindowPipe',
    pure: true
})
export class TimeWindowPipe implements PipeTransform {
    public transform(timeWindow: {fromTime: string, toTime: string, from: string, to: string}): any {
        if (!timeWindow) {
            return;
        } 
        const date: string = '2020-10-01';
        if (timeWindow.hasOwnProperty('fromTime')) {
            return `${moment(date + ' ' + timeWindow.fromTime).format('HH:mm')} - ${moment(date + ' ' + timeWindow.toTime).format('HH:mm')}`;
        } else if (timeWindow.hasOwnProperty('from')) {
            return `${moment(timeWindow.from).format('HH:mm')} - ${moment(timeWindow.to).format('HH:mm')}`;
        }
    }
}
