import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ApmService } from '../services/apm.service';
import { environment } from '@environment';
import * as _ from 'lodash';
import { QueryParams } from '@interfaces/query-params.interface';
import { ORDER } from '@enums/enum';

@Injectable()
export class AmpsResolver implements Resolve<any> {
    constructor(private apmService: ApmService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        const params: QueryParams = {
            size: 1000,
            page: 0,
            order: ORDER.ASC,
            sort: 'createdAt'
        }
        return this.apmService.findAllPageableResponse(params);
    }
}
