import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { DynamicRoles } from '../../entities/roles';
import { ActivatedRoute } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import * as _ from 'lodash';
import { NavService } from '@services/nav.sevice';
import { Subscription, Observable } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { RolesService } from '../../services/roles.service';
import { ToastrProviderService } from '@services/toastr-provider.service';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;

  public roles: DynamicRoles[];
  public rolesCollection: DynamicRoles[];

  private modifiedId = '';

  private routeQueryParams$: Subscription;
  public tableMessages = {
    'emptyMessage': this.translateService.instant('No data to display')
  };

  public columns = [
    { prop: 'id', name: 'Num', flexGrow: 1 },
    { prop: 'name', name: this.translateService.instant('Role name'), flexGrow: 4 },
    { prop: 'permissions', name: this.translateService.instant('Permissions'), flexGrow: 3 },
    { prop: 'createdAt', name: this.translateService.instant('Created At'), flexGrow: 3 },
    { prop: 'options', name: this.translateService.instant('Options'), flexGrow: 2 },
  ];

  public loader: boolean = false;
  public locale: string = '';

  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  constructor(
    private navigationRoute: ActivatedRoute, 
    private translateService: TranslateService,
    private modalService: BsModalService,
    private rolesService: RolesService,
    private toastrProviderService: ToastrProviderService,
    private navService: NavService) { }

  public ngOnInit() {
    this.roles = this.rolesCollection = (this.navigationRoute.snapshot.data as {roles: any} ).roles;
    this.routeQueryParams$ = this.navigationRoute.params.subscribe((params) => this.modifiedId = _.get(params, 'role', ''));
    this.translateService.onLangChange.subscribe((lang: LangChangeEvent) =>  this.locale = lang.lang);
  }

  public goToDetails(event): void {
    if (event.type === 'click') {
        const path = (event.event.path) ? event.event.path : event.event.composedPath();
        const isOutside = _.find(path, (n) => !_.isUndefined(n.classList) && n.classList.value.includes('dropdown'));
        if (_.isUndefined(isOutside)) {
            console.log(event);
            this.navService.goToPage(`/users/roles/${event.row.id}/edit`);
      }
    }
  }

  public applyFiltering(event) {
    const filterPhrase = event.target.value.toLowerCase();
    const temp = _.filter(this.rolesCollection, (role: DynamicRoles) => {
        const searchIn: string = role.name;
        return searchIn.toLowerCase().indexOf(filterPhrase) !== -1 || !filterPhrase;
    });

    this.roles = temp;
}

  public getRoles() {
    this.loader = true;
    this.rolesService.getRoles().subscribe(
        (roles: DynamicRoles[]) => {
            this.roles = roles;
            this.loader = false;
        },
        (error) => {
            console.log(`Error during receiving list of roles`, error);
            this.loader = false;
        });
}

  public delete(row: DynamicRoles) {
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.rolesService.delete(row.id);
  }

  public confirm(): void {
    this.confirmObservable.subscribe(() => {
        this.modalRef.hide();
        this.getRoles();
        this.toastrProviderService.showSuccess(this.translateService.instant('The role has been removed!'));
        this.confirmObservable = null;
    });
  }

  public decline(): void {
      this.confirmObservable = null;
      this.modalRef.hide();
  }

  public rowClass = (role: DynamicRoles) => {
    return {
      'row-modified': role.id === this.modifiedId
    };
  };

  public isColumnCreatedAt = (column: string) => column === 'createdAt';
  public isColumnOptions = (column: string) => column === 'options';
  public isColumnRoles = (column: string) => column === 'permissions';
  public isColumnId = (column: string) => column === 'id';

}
