


import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { UtilsService } from '@services/utils.service';
import { ApmService } from '../services/apm.service';

@Injectable()
export class AssignRegionsResolver implements Resolve<any> {

    constructor(
        private apmService: ApmService,
        private utilsService: UtilsService
    ) { }

    public resolve(route: ActivatedRouteSnapshot) {
        const params = { apmId: this.utilsService.findUpRouteParams(route, 'apmId')}
        return this.apmService.getAssignRegionsToApm(params.apmId);
    }
}
