<layout-default>
    <div header>
        <div toolbar>
            <h4 *ngIf="mode === 'CREATE'">
                <i-feather name="database"></i-feather> {{'Create distribution center for partner' | translate}}
            </h4>
            <h4 *ngIf="mode === 'UPDATE'">
                <i-feather name="database"></i-feather> {{'Edit distribution center' | translate}}: {{partnerdc.name}}
            </h4>
        </div>

        <hr />
    </div>
    
    <div content>
        <div *ngIf="!loader" class="content bg-white">

            <div class="card-content col-8 offset-2">

                <form [formGroup]="form">

                    <div id="partner-dc-edit-form-depot" *ngIf="(depots$ | async) as depots" class="form-group row">
                        <label class="col-2" for="bOO">{{'Depot' | translate}}:&nbsp;<span *ngIf="form.get('bOO') | isRequiredControlPipe">*</span></label>
                        <div class="col-10">
                            <select class="custom-select" formControlName="bOO">
                                <option *ngFor="let d of depots" [value]="d.id">{{d.code}}</option>
                            </select>

                            <div *ngIf="form.get('bOO')?.hasError('required') && form.get('bOO')?.touched" class="error-color">
                                    {{ 'The field is required' | translate }}!
                            </div>

                            <div *ngIf="form.get('bOO')?.hasError('maxlength') && form.get('bOO')?.touched" class="error-color">
                                {{ "The field value is too long" | translate }}!
                            </div>                       
                        </div>
                    </div>   

                    <div id="partner-dc-edit-form-name" class="form-group row">

                        <label for="name" class="col-2">
                            <span>{{'Name' | translate}}:&nbsp;</span>
                            <span *ngIf="form.get('name') | isRequiredControlPipe">*</span>&nbsp;
                            <i-feather *ngIf="tooltipLabel('name')" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('name')"
                                [style.width.px]="13" name="info"></i-feather>
                        </label>

                        <div class="col-10">
                            <input type="text" formControlName="name" class="form-control" />
                            <div *ngIf="form.get('name').hasError('required') && form.get('name').touched" class="error-color">
                                {{ "The field is required" | translate }}!
                            </div>
                            <div *ngIf="form.get('name')?.hasError('maxlength') && form.get('name')?.touched" class="error-color">
                                {{ "The field value is too long" | translate }}!
                            </div>
                        </div>
                    </div>


                    <div id="partner-dc-edit-form-{{item}}" *ngFor="let item of ['pickupBoxSeconds', 'pickupDeliverySeconds', 'pickupSeconds']" class="form-group row">
                        <label class="col-2" [for]="item">{{item | translate}} (s):&nbsp;<span *ngIf="form.get(item) | isRequiredControlPipe">*</span></label>
                        <div class="col-10">
                            <input type="number" [formControlName]="item" class="form-control" placeholder="{{'Please put the value in seconds' | translate}}"/>
                            <div *ngIf="form.get(item).hasError('required') && form.get(item).touched" class="error-color">
                                {{ "The field is required" | translate }}!
                            </div>
                            <div *ngIf="form.get(item).hasError('maxlength') && form.get(item).touched" class="error-color">
                                {{ "The field value is too long" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div id="partner-dc-edit-form-address" formGroupName="address">
                        <label class="d-block col-10 offset-2 tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-b-20">{{'Address' | translate}}</label>

                        <div *ngFor="let item of addressKeys" class="form-group row">
                            <label class="col-2" for="code">{{item.toUpperCase() | translate}}:&nbsp;<span *ngIf="form.get('address').get(item) | isRequiredControlPipe">*</span></label>
                            <div class="col-10">
                                <input *ngIf="item === 'zip'" [formControlName]="item" [dropSpecialCharacters]="false" mask="00-000" class="form-control" />
                                <input *ngIf="item !== 'zip'" [formControlName]="item" class="form-control" />
                                <div *ngIf="form.get('address').get(item)?.hasError('required') && form.get('address').get(item)?.touched" class="error-color">
                                    {{ "The field is required" | translate }}!
                                </div>
                                <div *ngIf="item === 'zip' && form.get('address').get('zip').hasError('pattern') && form.get('address').get('zip').touched" class="error-color">
                                    {{ "The zip code you entered is invalid" | translate }}!
                                </div>
                                <div *ngIf="form.get('address').get(item)?.hasError('maxlength') && form.get('address').get(item)?.touched" class="error-color">
                                    {{ "The field value is too long" | translate }}!
                                </div>
                            </div>
                        </div>

                    </div>

                    <div id="partner-dc-edit-form-locationAdditional" formGroupName="locationAdditional">
                        <label class="d-block col-10 offset-2 tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-b-20">{{'Address information' | translate}}</label>
     
                        <ng-container *ngFor="let item of additionalKeys">
                            <div *ngIf="form.get('locationAdditional').get(item)" class="form-group row">
                                <ng-container *ngIf="item != 'lift' && item != 'floor'">
                                    <label class="col-2" [for]="item">{{item.toUpperCase() | translate}}:&nbsp;<span *ngIf="form.get('locationAdditional').get(item) | isRequiredControlPipe">*</span></label>
                                    <div class="col-10">
                                        
                                        <input *ngIf="item === 'contactPhoneNO'" prefix="+48 " mask="000-000-000" [formControlName]="item" class="form-control" />
                                        <input *ngIf="item !== 'contactPhoneNO'" [formControlName]="item" class="form-control" />

                                        <div *ngIf="form.get('locationAdditional').get(item)?.hasError('required') && form.get('locationAdditional').get(item)?.touched" class="error-color">
                                            {{ "The field is required" | translate }}!
                                        </div>
                                        <div *ngIf="form.get('locationAdditional').get(item).hasError('pattern') && form.get('locationAdditional').get(item).touched" class="error-color">
                                            {{ "Above email address is incorrect" | translate }}!
                                        </div>
                                        <div *ngIf="item === 'contactPhoneNO' && form.get('locationAdditional').get('contactPhoneNO').hasError('Mask error') && form.get('locationAdditional').get('contactPhoneNO').touched" class="error-color">
                                            {{ "Above phone number you entered is invalid" | translate }}!
                                        </div>
                                        <div *ngIf="form.get('locationAdditional').get(item)?.hasError('maxlength') && form.get('locationAdditional').get(item)?.touched" class="error-color">
                                            {{ "The field value is too long" | translate }}!
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                        <div class="form-group row">
                            <label class="col-2" for="socialNumberType">{{'Lift' | translate}}:&nbsp;<span *ngIf="form.get('locationAdditional').get('lift') | isRequiredControlPipe">*</span></label>
                            <div class="col-10">
                                <select class="custom-select" formControlName="lift">
                                    <option [value]="true">{{'Yes' | translate}}</option>
                                    <option [value]="false">{{'No' | translate}}</option>
                                </select>

                                <div *ngIf="form.get('locationAdditional').get('lift')?.hasError('required') && form.get('locationAdditional').get('lift')?.touched" class="error-color">
                                        {{ "Lift field is required" | translate }}!
                                </div>
                            </div>
                        </div>   
                        <div class="form-group row">
                            <label class="col-2" for="floor">{{'Floor'.toUpperCase() | translate}}:&nbsp;<span *ngIf="form.get('locationAdditional').get('floor') | isRequiredControlPipe">*</span></label>
                            <div class="col-10">
                                <input formControlName="floor" type="number" class="form-control" />
                                <div *ngIf="form.get('locationAdditional').get('floor')?.hasError('required') && form.get('locationAdditional').get('floor')?.touched" class="error-color">
                                    {{ "The field is required" | translate }}!
                                </div>
                            </div>
                        </div>

                    </div>

                    <div id="partner-dc-edit-form-coordinates" formGroupName="coordinates">
                        <div class="row">
                            <div class="d-block col-10 offset-2">
                                <label class="d-block tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-r-20">{{'Coordinates' | translate}}</label>
                                <button class="btn btn-sm btn-white" (click)="getCoordinates()">{{'Geocode address' | translate}}</button>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-10 offset-2 mg-t-20 mg-b-20">
                                <gmap *ngIf="coordinates" (coords)="changeCoordinates($event)" [locations]="coordinates" [mapProperties]="mapProperties" [mapOption]="mapOption"></gmap>
                            </div>
                        </div>
                        <div *ngFor="let item of coordinatesKeys" class="form-group row">
                            <label class="col-2" for="code">{{item.toUpperCase() | translate}}:&nbsp;<span *ngIf="form.get('address').get(item) | isRequiredControlPipe">*</span></label>
                            <div class="col-10">
                                <input [formControlName]="item" class="form-control" />
                                <div *ngIf="form.get('address').get(item)?.hasError('required') && form.get('address').get(item)?.touched" class="error-color">
                                    {{ "The field is required" | translate }}!
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="formErrorsMessages && formErrorsMessages.length" class="form-group row">
                        <div class="col-10 offset-2">
                            <p class="error-color">{{ 'Something has wrong! Please correct form' | translate }}:</p>
                            <ul>
                                <li *ngFor="let error of formErrorsMessages" class="error-color">
                                    <span *ngIf="error.value">{{ 'Field' | translate }}</span>
                                    <strong>{{ error.field | translate }}</strong>
                                    <span>{{ error.value | translate }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div id="partner-dc-edit-form-btn-save" class="form-group row justify-content-end">
                        <div class="col-2">
                            <button (click)="onSubmit()" [disabled]="!form.valid || submitted"
                                class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01 w-100">

                                <span *ngIf="!submitted">{{ "Save" | translate }}</span>

                                <ng-container *ngIf="submitted">
                                    <div class="load-spinner text-center">
                                        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                    </div>
                                </ng-container>

                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</layout-default>

<!-- {{'pickupBoxSeconds' | translate }} -->
<!-- {{'pickupDeliverySeconds' | translate }} -->
<!-- {{'pickupSeconds' | translate }} -->
<!-- {{'DEPOT' | translate }} -->

<!-- {{'tooltips.partnerdc.labels.name' | translate -->
