import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { PartnerUserService } from '../services/partner-user.service';
import { UtilsService } from '@services/utils.service';

@Injectable()
export class PartnerUsersResolver implements Resolve<any> {
    constructor(private partnerUserService: PartnerUserService, private readonly utilsService: UtilsService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        const partnerId = this.utilsService.findUpRouteParams(route, 'partnerId');
        return this.partnerUserService.getAllPartnerUsers(partnerId);
    }
}
