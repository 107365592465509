<layout-default>
  <div header>
      <div toolbar>
          <h4>
              <i-feather name="cast"></i-feather> {{'Apm maintenance' | translate}}
          </h4>
      </div>
      <div toolbar>
          <date-range-picker class="flex-grow-1 pic-apm-maint-slot" [extendedOption]="false" (changedDateRange)="changedDateRange($event)"></date-range-picker>
          <select class="custom-select" [style.width.%]="15" [(ngModel)]="origin" (ngModelChange)="changedOrigin($event)" id="sel-apm-srv-origin-filter">
              <option [ngValue]="null" selected>{{'ApmMaintenance.origin.AllOrigins' | translate}}</option>
              <option [ngValue]="'AUTOMATIC'" selected>{{'ApmMaintenance.origin.AUTOMATIC' | translate}}</option>
              <option [ngValue]="'MANUAL'" selected>{{'ApmMaintenance.origin.MANUAL' | translate}}</option>
              <option [ngValue]="'HAACP'" selected>{{'ApmMaintenance.origin.HAACP' | translate}}</option>
          </select>
          <hr />
      </div>
  </div>
  
  <div content>
      <div *ngIf="list">
          <ngx-datatable 
            id="apms" 
            class="bootstrap" 
            [rows]="list.content" 
            [columns]="columns" 
            [columnMode]="'flex'"
            [headerHeight]="50" 
            [footerHeight]="50" 
            [messages]="tableMessages"
            [rowHeight]="'auto'" 
            [externalPaging]="true"
            [count]="list.totalElements"
            [offset]="queryParams.page"
            [rowClass]="rowClass"
            [limit]="queryParams.size"
            [scrollbarH]="scrollBarHorizontal"
            (sort)="onSort($event)"
            (page)="setPage($event)">
              
              <ng-container *ngFor="let col of columns">
                  <ngx-datatable-column [flexGrow]="col.flexGrow" [prop]="col.prop">
                      <ng-template let-column="column" ngx-datatable-header-template class="header" let-sort="sortFn">
                          <b (click)="sort()">{{ col.name | translate }}</b>
                      </ng-template>
                      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>

                        <div [ngSwitch]="col.prop">
                          <div *ngSwitchCase="'address'">
                                  <span>{{row.address | addressLabelPipe}}</span>
                              </div>
                          <div *ngSwitchCase="'lcount'">
                              <span 
                                  [containerClass]="'pretty-tooltip'" 
                                  placement="right" 
                                  [tooltip]="row | rlmCapacityLabelPipe"><strong>{{row | rlmCapacityLabelShortPipe}}</strong></span>

                              <!-- <span 
                                  *ngIf="row.availableVolume !== null"
                                  [containerClass]="'pretty-tooltip'" 
                                  placement="right" 
                                  [ngClass]="{'red': row | checkAvailabilityInRlmPipe}"
                                  [tooltip]="row | rlmCapacityLabelPipe"> - ({{row | rlmCapacityLabelShortPipe}})</span> -->
                          </div>
                          <div *ngSwitchCase="'status'">
                              <ng-container [ngSwitch]="value">
                                  <span *ngSwitchCase="'NOT_AVAILABLE'" class="badge badge-danger">{{ 'RlmStatus.'+value | translate }}</span>
                                  <span *ngSwitchCase="'AVAILABLE'" class="badge badge-success">{{ 'RlmStatus.'+value | translate }}</span>
                                  <span *ngSwitchDefault class="badge badge-info">{{ 'RlmStatus.'+value | translate }}</span>
                              </ng-container>
                          </div>
                          <div *ngSwitchCase="'options'">
                              <div class="dropdown" dropdown>
                                  <button
                                      class="btn btn-outline-secondary btn-xs dropdown-toggle"
                                      type="button"
                                      id="dropdownMenuButton">
                                      {{ 'Select' | translate }}
                                  </button>
                                  <div class="dropdown-menu dropdown-menu-right  div-main-actions">
                                      <a 
                                          class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-apm-go-details" 
                                          (click)="goToDetails(row.id)">
                                              {{'Rlm details' | translate }}
                                      </a>

                                      <a 
                                          appCheckFeaturesConfig [featureName]="[Permissions.Apm_maintenance_edit]"
                                          class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-apm-maintenance" 
                                          (click)="goToMaintenanceWindows(row.id)">
                                              {{'Maintenance windows' | translate }}
                                      </a>
                                  </div>
                              </div>
                          </div>
                          <div *ngSwitchDefault>{{ value | translate }}</div>
                      </div>
                      
                         
                      </ng-template>
                  </ngx-datatable-column>
              </ng-container>
          </ngx-datatable>
      </div>

      <div *ngIf="loader" class="load-spinner text-center">
          <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}
      </div>
  </div>

</layout-default>

