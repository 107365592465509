
<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="database"></i-feather> {{'Door to door - tests' | translate}}
            </h4>
        </div>
    </div>
    
    <div content>
       
       <div class="row pd-10" *ngIf="model">
            <div class="col-4">
                <label for="partner">Data:</label>
                <input [(ngModel)]="model.date" class="form-control" />
            </div>
           <div class="col-4">
               <label for="partner">Partner:</label>
                <select class="custom-select" [(ngModel)]="model.partner">
                    <option *ngFor="let partner of partnersList" [value]="partner.id">{{partner.code}} {{partner.name}}</option>
                </select>
           </div>
           <div class="col-4">
                <label for="partner">Region:</label>

                <select class="custom-select" [(ngModel)]="model.region">
                    <option *ngFor="let region of regionsList()" [value]="region.id">{{region.code}} {{region.name}}</option>
                </select>
           </div>
       </div> 

       <div class="row pd-10" *ngIf="model">
            <div class="col-4">
                <label for="partner">Informacje o zamówieniu:</label>
                
                <div class="row mg-b-20">
                    <div class="col-6">
                        <p class="d-block tx-sans tx-10 tx-uppercase tx-color-03 tx-spacing-1 text-left mg-b-0 pd-b-0">{{'firstName' | translate}}</p>
                        <input [(ngModel)]="model.firstName" type="text" class="form-control" />
                    </div>
                    <div class="col-6">
                        <p class="d-block tx-sans tx-10 tx-uppercase tx-color-03 tx-spacing-1 text-left mg-b-0 pd-b-0">{{'firstName' | translate}}</p>
                        <input [(ngModel)]="model.lastName" type="text" class="form-control" />
                    </div>
                </div>

                <div class="row mg-b-20">
                    <div *ngFor="let item of ['lcount', 'mcount', 'ncount', 'ocount']; let i = index;" class="item col-3">
                        <p class="d-block tx-sans tx-10 tx-uppercase tx-color-03 tx-spacing-1 text-left mg-b-0 pd-b-0">
                            <ng-container [ngSwitch]="item">
                                <span *ngSwitchCase="'lcount'">
                                    {{'CompartmentType.Cooler' | translate}}
                                </span>
                                <span *ngSwitchCase="'mcount'">
                                    {{'CompartmentType.Freezer' | translate}}
                                </span>
                                <span *ngSwitchCase="'ncount'">
                                    {{'CompartmentType.Ambient' | translate}}
                                </span>
                                <span *ngSwitchCase="'ocount'">
                                    {{'CompartmentType.Other' | translate}}
                                </span>
                            </ng-container></p>
                        <input [(ngModel)]="model[item]" type="number" class="form-control" />
                    </div>
                </div>

                <div class="row mg-b-20">
                    <div class="col-6">
                        <p class="d-block tx-sans tx-10 tx-uppercase tx-color-03 tx-spacing-1 text-left mg-b-0 pd-b-0">Odbiór od:</p>
                        <input [(ngModel)]="model.pickupFrom" type="text" class="form-control" />
                    </div>
                    <div class="col-6">
                        <p class="d-block tx-sans tx-10 tx-uppercase tx-color-03 tx-spacing-1 text-left mg-b-0 pd-b-0">Odbiór do:</p>
                        <input [(ngModel)]="model.pickupTo" type="text" class="form-control" />
                    </div>
                </div>
            </div>
            
            <div class="col-4">
                <label for="partner">Lokalizacja:</label>
                <gmap *ngIf="coordinatesRaw" (coords)="changeCoordinates($event)" [locations]="coordinatesRaw" [mapProperties]="mapProperties" [mapOption]="mapOption"></gmap>
            </div> 

            <div class="col-4">
                <label for="partner">&nbsp;</label>
                <textarea rows="4" [(ngModel)]="model.data" class="form-control mg-b-20"></textarea>
                <button class="btn btn-success" (click)="checkAv()">Sprawdź dostępność</button>
            </div>
        </div> 

        <div class="row pd-10" *ngIf="availabilityList">
            <div class="col-12">
                <p>Lista dostępnych slotów:</p>
                <ul>
                    <li *ngFor="let item of availabilityList">
                        <span>Klient: {{item.customer.ref}} {{item.customer.firstName}} {{item.customer.lastName}}</span>,&nbsp;
                    
                        <span 
                            [containerClass]="'pretty-tooltip'" 
                            placement="right" 
                            [tooltip]="item | rlmCapacityLabelPipe">Dostępność: {{item | rlmCapacityLabelShortPipe}}</span>,&nbsp;
                    
                        <span>Pickup: {{ item.pickup.window.from | amLocale: locale | amDateFormat: 'D MMM, HH:mm' }} - {{ item.pickup.window.to | amLocale: locale | amDateFormat: 'D MMM, HH:mm' }}</span>,&nbsp;
                        <span>Slot: {{ item.slot.deliveryWindow.from | amLocale: locale | amDateFormat: 'D MMM, HH:mm' }} - {{ item.slot.deliveryWindow.to | amLocale: locale | amDateFormat: 'D MMM, HH:mm' }}</span>
                        <button class="btn btn-default d-inline" (click)="reserve(item)">Rezerwuj</button>
                    </li>
                </ul>
            </div> 
        </div> 

        <hr />

        <div class="row pd-10" *ngIf="model">
            <div class="col-6">
                <label for="partner">Data:</label>
                <input [(ngModel)]="reservationId" class="form-control" />
            </div>
        
            <div class="col-6">
                <label class="d-block" for="partner">Akcje:</label>
                <button class="btn btn-warning d-inline mg-r-10" (click)="cancel()">Anuluj rezerwację</button>
                <button class="btn btn-warning d-inline mg-r-10" (click)="extend()">Przedłuż rezerwację</button>
                <button class="btn btn-success d-inline" (click)="confirm()">Potwierdź rezerwację</button>
            </div>
        </div> 


    </div>
</layout-default>
