import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ApmService } from '../services/apm.service';

@Injectable()
export class AmpsByDepotResolver implements Resolve<any> {
    constructor(private apmService: ApmService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        return this.apmService.getByDepot();
    }
}
