import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationComponent } from './authentication/components/authentication.component';
import { InpostRoutingModule } from 'projects/inpost/src/app/app-routing.module';
import { AccessDeniedComponent } from '@shared/components/access-denied.component';
import { MainComponent } from '@shared/components/main.component';
import { PageNotFoundComponent } from './authentication/components/page-not-found.component';
import { environment } from '@environment';
import { Client } from '@enums/enum';
import { OauthRedirectComponent } from './authentication/components/oauth-redirect/oauth-redirect.component';

const appRoutes: Routes = [

    {path: '', component: AuthenticationComponent},
    {path: 'login', component: AuthenticationComponent},
    {path: 'login/oauth/v2.0', component: OauthRedirectComponent},
    {path: 'page-not-found', component: PageNotFoundComponent},

    { 
        path: '',
        component: MainComponent,
        children: [
            { 
                path: 'access-denied', component: AccessDeniedComponent,
            },
            {
                path: 'calendar',
                loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule),
                data: {
                    breadcrumb: 'Breadcrumbs.Calendar'
                }
            },
        
            {
                path: 'analytics',
                loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule),
                data: {
                    breadcrumb: 'Breadcrumbs.Analytics'
                }
            },
            {
                path: 'drivers',
                loadChildren: () => import('./drivers/drivers.module').then(m => m.DriversModule),
                data: {
                    breadcrumb: (environment.client === Client.FRISCO) ? 'Breadcrumbs.Drivers' : 'Breadcrumbs.Couriers'
                }
            },
            {
                path: 'fleet',
                loadChildren: () => import('./hardware/hardware.module').then(m => m.HardwareModule),
                data: {
                    breadcrumb: 'Breadcrumbs.FleetAndHardware'
                }
            },
            {
                path: 'payments',
                loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsModule),
                data: {
                    breadcrumb: 'Breadcrumbs.Payments'
                }
            },
            {
                path: 'deliveries',
                loadChildren: () => import('./deliveries/deliveries.module').then(m => m.DeliveriesModule),
                data: {
                    breadcrumb: 'Breadcrumbs.Deliveries'
                }
            },
            {
                path: 'locations',
                loadChildren: () => import('./locations/locations.module').then(m => m.LocationsModule),
                data: {
                    breadcrumb: 'Breadcrumbs.Locations'
                }
            },
            {
                path: 'users',
                loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
                data: {
                    breadcrumb: 'Breadcrumbs.Users'
                }
            },
            {
                path: 'dashboard',
                loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
                data: {
                    breadcrumb: 'Breadcrumbs.Dashboard'
                }
            },
            {
                path: 'routes',
                loadChildren: () => import('./routes/routes.module').then(m => m.RoutesModule),
                data: {
                    breadcrumb: 'Breadcrumbs.RoutesList'
                }
            },
            {
                path: 'salary',
                loadChildren: () => import('./../../projects/frisco/src/app/salary/salary.module').then(m => m.SalaryModule),
                data: {
                    breadcrumb: 'Breadcrumbs.Salary'
                }
            },
            {
                path: 'visualiser',
                loadChildren: () => import('./visualiser/visualiser.module').then(m => m.VisualiserModule),
                data: {
                    breadcrumb: 'Breadcrumbs.Visualiser'
                }
            },
            {
                path: '',
                loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
            },
            {
                path: '',
                loadChildren: () => import('./system/system.module').then(m => m.SystemModule),
            },
            {
                path: '',
                loadChildren: () => import('./../../projects/inpost/src/app/operations/operations.module').then(m => m.OperationsModule),
            },
            {
                path: '',
                loadChildren: () => import('./../../projects/inpost/src/app/app.module').then(m => m.InpostSharedModule),
            },
        ]
    },

    {path: '**', redirectTo: 'page-not-found'}
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes), 
        InpostRoutingModule
    ],
    exports: [RouterModule]
})

export class Routing {}
