import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { NavService } from '@services/nav.sevice';
import * as _ from 'lodash';
import { ApmMaintenance, ApmMaintenanceType, ApmMaintenanceOrigin } from '../../interfaces/apm-maintenance.interface';
import { CRUD_MODE } from '@enums/enum';
import * as moment from 'moment';
import { ApmService } from '../../services/apm.service';
import { catchError, distinctUntilChanged, merge, combineAll, tap, filter } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Apm } from '../../entities/apm';
import { sumBy } from 'lodash';
import { datePickerOptions } from '@shared/constants/datepicker-options.constants';

@Component({
  selector: 'app-apm-maintenance-create',
  templateUrl: './apm-maintenance-create.component.html',
  styleUrls: ['./apm-maintenance-create.component.scss']
})
export class ApmMaintenanceCreateComponent implements OnInit {

  public apmId: string;
  private queryParamsSubscription: Subscription;

  public apmMaintenanceWindow: ApmMaintenance;
  public apm: Apm;
  public apms: Apm[];
  public selectedApms: string[] = [];
  public loader: boolean = true;
  public mode: CRUD_MODE;

  public form: FormGroup;

  public from: AbstractControl;
  public to: AbstractControl;

  public fromDate: AbstractControl;
  public toDate: AbstractControl;

  public fromTime: AbstractControl;
  public toTime: AbstractControl;

  public errorMsg = '';

  public capacityControls: string[] = ['lcount', 'mcount', 'ncount', 'ocount'];
  
  public apmMaintenanceType = Object.values(ApmMaintenanceType);
  public apmMaintenanceOrigin = Object.values(ApmMaintenanceOrigin);

  public submitted: boolean = false;
  private readonly format = "YYYY-MM-DD'T'HH:mm:ss.SSSZ";
  private requests = [];

  public options: any = datePickerOptions;

  constructor(
    private readonly navigationRoute: ActivatedRoute,
    private readonly navService: NavService,
    private toastrProviderService: ToastrProviderService,
    private readonly translateService: TranslateService,
    private readonly formBuilder: FormBuilder,
    private readonly apmMaintenanceService: ApmService
  ) { }

  public ngOnInit() {
    
    this.apmMaintenanceWindow = (this.navigationRoute.snapshot.data as {apmMaintenanceWindow: any}).apmMaintenanceWindow;
    this.apm = (this.navigationRoute.snapshot.data as {apm: any}).apm;
    this.mode = (this.apmMaintenanceWindow) ? CRUD_MODE.UPDATE : CRUD_MODE.CREATE;

    if (this.mode === CRUD_MODE.CREATE) {
      this.apms = (this.navigationRoute.snapshot.data as {apms: any}).apms.content;
      this.selectedApms =  _.get(this.navigationRoute.snapshot.queryParams, 'selectedApms', []);
    }

    this.queryParamsSubscription = this.navigationRoute.params.subscribe(params => {
      this.apmId = _.get(params, 'apmId', '');
      this.initForm();
    });
  }

  public initForm() {
    this.form = this.formBuilder.group({
      id: [],
      apmId: [this.apmId],
      slot: this.formBuilder.group({
        fromDate: [this.apmMaintenanceWindow ? this.apmMaintenanceWindow.slot.from : moment().format('YYYY-MM-DD'), [Validators.required]],
        fromTime: [this.apmMaintenanceWindow ? moment(this.apmMaintenanceWindow.slot.from).format('HH:mm') : '', [Validators.required]],
        from: [this.apmMaintenanceWindow ? this.apmMaintenanceWindow.slot.from : new Date()],
        
        toDate: [this.apmMaintenanceWindow ? this.apmMaintenanceWindow.slot.to :  moment().format('YYYY-MM-DD'), [Validators.required]],
        toTime: [this.apmMaintenanceWindow ? moment(this.apmMaintenanceWindow.slot.to).format('HH:mm') : '', [Validators.required]],
        to: [this.apmMaintenanceWindow ? this.apmMaintenanceWindow.slot.to :  new Date()],
      }),
      type: [ApmMaintenanceType.SCHEDULED_MAINTENANCE, [Validators.required]],
      origin: [ApmMaintenanceOrigin.MANUAL, [Validators.required]],
      lcount: [0, [Validators.required, Validators.min(0), Validators.maxLength(255)]],
      mcount: [0, [Validators.required, Validators.min(0), Validators.maxLength(255)]],
      ncount: [0, [Validators.required, Validators.min(0), Validators.maxLength(255)]],
      ocount: [0, [Validators.required, Validators.min(0), Validators.maxLength(255)]],

    });


    this.from = (this.form.controls[ 'slot' ] as FormGroup).controls[ 'from' ];
    this.to = (this.form.controls[ 'slot' ] as FormGroup).controls[ 'to' ];
    this.fromDate = (this.form.controls[ 'slot' ] as FormGroup).controls[ 'fromDate' ];
    this.toDate = (this.form.controls[ 'slot' ] as FormGroup).controls[ 'toDate' ];
    this.fromTime = (this.form.controls[ 'slot' ] as FormGroup).controls[ 'fromTime' ];
    this.toTime = (this.form.controls[ 'slot' ] as FormGroup).controls[ 'toTime' ];

    this.loader = false;

   
    this.fromTime.valueChanges.pipe(
      distinctUntilChanged(),
      filter(a => a.length === 5)
    ).subscribe(() => this.changedFromTime())

    this.fromDate.valueChanges.pipe(
      distinctUntilChanged(),
      filter(a => a.length === 5)
    ).subscribe(() => this.changedFromTime())

    this.toTime.valueChanges.pipe(
      distinctUntilChanged(),
      filter(a => a.length === 5)
    ) .subscribe(() => this.changedToTime())

    this.toDate.valueChanges.pipe(
      distinctUntilChanged(),
      filter(a => a.length === 5)
    ).subscribe(() => this.changedToTime())

    // merge([
    //   this.fromTime.valueChanges.pipe(distinctUntilChanged()), 
    //   this.fromDate.valueChanges.pipe(distinctUntilChanged())
    // ]).subscribe((d) => this.changedFromTime())

    this.form.patchValue(this.apmMaintenanceWindow);

    if (moment(this.from.value).isBefore(new Date())) {
      console.log('aaa');
      this.fromDate.disable();
      this.fromTime.disable();
    }
  }

  public changedFromTime() {
    const fromTime = moment(`${moment(this.fromDate.value).format('YYYY-MM-DD')} ${this.fromTime.value}`);

    // if ((!this.fromTime.touched || !this.fromDate.touched) && this.from.value !== null) {
    //   return;
    // }

    if (!fromTime.isValid) {
      this.fromTime.setErrors({ 'invalidTime': true });
      return;
    }
    
    if (fromTime.isBefore(new Date())) {
      this.fromTime.setValue(moment(this.from.value).format('HH:mm'));
      this.fromDate.setValue(moment(this.from.value).format('YYYY-MM-DD'));
      this.fromTime.setErrors({ 'pastDate': true });
      return;
    }

    this.from.setValue(fromTime.toISOString());
    this.fromTime.setErrors(null);
  }

  public changedToTime() {
    const toTime = moment(`${moment(this.toDate.value).format('YYYY-MM-DD')} ${this.toTime.value}`);

    if (toTime.isSame(this.to.value)) {
      return;
    }

    if (!toTime.isValid) {
      this.toTime.setErrors({ 'invalidTime': true });
      return;
    }

    if (toTime.isBefore(moment(this.from.value))) {
      this.toTime.setValue(moment(this.to.value).format('HH:mm'));
      this.toDate.setValue(moment(this.to.value).format('YYYY-MM-DD'));
      this.toTime.setErrors({ 'fromAfterTo': true });
      return;
    }

    this.to.setValue(toTime.toISOString());
    this.toTime.setErrors(null);
  }

  public onItemSelect(item) {
    console.log(this.selectedApms);
    // this.queryParams.apmIds = this.selectedApms.map(a => a.item_id);
    // this.apmDeliveryService.fetchAll({ ...this.queryParams, partnerId: this.partnerId, regionId: this.selectedRegion });
 }

  public onSubmit() {
    //this.changedFromTime();
    this.changedToTime();
    this.errorMsg = '';

    const from = moment(this.from.value);
    const to = moment(this.to.value);

    if (from.isAfter(to)) {
      this.errorMsg = this.translateService.instant('Date from needs to be before or equal date to');
      return;
    } 

    const raw: ApmMaintenance = this.form.getRawValue();


    if (raw.lcount + raw.mcount + raw.ncount + raw.ocount < 1) {
      this.errorMsg = this.translateService.instant('Lockers count have to be more than 0');
      return;
    } 

    const apmMaintenanceDto: ApmMaintenance = {
      id: raw.id,
      apmId: raw.apmId,
      slot: {
        from: raw.slot.from,
        to: raw.slot.to
      },
      type: raw.type,
      origin: raw.origin,
      lcount: raw.lcount,
      mcount: raw.mcount,
      ncount: raw.ncount,
      ocount: raw.ocount,
    }

    if (this.mode === CRUD_MODE.CREATE) {
      delete apmMaintenanceDto.id;
      this.selectedApms.map(apm => {
        const data = {
          ...apmMaintenanceDto,
          apmId: apm
        }
        this.create(data);
        console.log(data);
      })

      this.run();

    } else {
      this.update(apmMaintenanceDto);
    }
  }
  
  run() {
      forkJoin(...this.requests)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            this.submitted = false;
            throw (error);
          })
        )
        .subscribe(
          (apmMaintenance: ApmMaintenance) => {
            this.toastrProviderService.showSuccess(this.translateService.instant('New apm maintenance window has been created!'));
            if ([0, 1].includes(this.selectedApms.length)) {
              this.navService.goToPage(`/apm/maintenance/${this.apmId}`, {modifiedRow: apmMaintenance.id});
            } else {
              this.navService.goToPage(`/apm/list`);
            }
            this.submitted = false;
          },
          (error: HttpErrorResponse) => {
            console.log(`Error occurred, please try again!`, error);
            if (error.status === 409) {
              this.errorMsg = error.message;
            }
            this.submitted = false;
          },
          () => {
            this.submitted = false;
          }
        );
  }

  private create(raw): void {
    console.log(raw);
    this.requests.push(this.apmMaintenanceService.createApmMaintenanceWindow(raw));
  }

  private update(raw): void {

    this.apmMaintenanceService.updateApmMaintenanceWindow(raw)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (apmMaintenance: ApmMaintenance) => {
          this.toastrProviderService.showSuccess(this.translateService.instant('The apm maintenance window has been updated!'));
          this.navService.goToPage(`/apm/maintenance/${this.apmId}`, {modifiedRow: apmMaintenance.id});
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }

}
