import { Component, Inject, ViewEncapsulation } from '@angular/core';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { AppLang } from '@enums/enum';

@Component({
    selector: 'printable',
    template: `
        <router-outlet name="content"></router-outlet>
        <router-outlet></router-outlet>
    `,
    styles: [`
        :host-context(app-navbar) {
            display: none !important;
        }
    `]
})
export class PrintableComponent {
    private readonly tag = '[PrintableComponent]';

    constructor(private translateService: TranslateService) {
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => moment.locale(event.lang));
        const language = localStorage.getItem('lang') || AppLang.PL;
        this.translateService.setDefaultLang(language);
        this.translateService.use(language);
    }
}
