import { Component, OnInit } from '@angular/core';
import { datePickerOptions } from '@shared/constants/datepicker-options.constants';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { PartnerReportParams } from '../../interfaces/partner-report-params.interface';
import { PartnerReportModeType } from '../../enums/partner-report.params.enum';
import { ActivatedRoute } from '@angular/router';
import { ApmService } from '../../services/apm.service';
import { PartnerReport } from '../../interfaces/partner-report.interface';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import enLocale from 'date-fns/locale/en-GB';
import plLocale from 'date-fns/locale/pl';
import { ApmCapacity } from '../../interfaces/apm-capacity.interface';
import { ApmDeliveryStatus } from '../../enums/apm-delivery-status.enum';

@Component({
  selector: 'app-partner-reports',
  templateUrl: './partner-reports.component.html',
  styleUrls: ['./partner-reports.component.scss']
})
export class PartnerReportsComponent implements OnInit {

  private datePickerOptions = Object.assign({}, datePickerOptions);
  public locale = null;
  public loading = false;
  public apmDeliveryStatus = ApmDeliveryStatus;

  public params: PartnerReportParams = {
    from: moment().startOf('month').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    mode: PartnerReportModeType.SharedOnly,
    unitPrice: 0,
    partnerId: ''
  }

  public partnerReportModeType = Object.values(PartnerReportModeType);

  public report$: Observable<PartnerReport>;

  public columns = [
    { prop: 'deliveryDate', name: this.translateService.instant('Date'), flexGrow: 2, sortable: true },
    { prop: 'apmCode', name: this.translateService.instant('Apm'), flexGrow: 2, sortable: true },
    { prop: 'reservationNumber', name: this.translateService.instant('reservationNumber'), flexGrow: 3, sortable: true },
    { prop: 'available', name: this.translateService.instant('Available') + '\n(L/M/N/O)', flexGrow: 2, sortable: true },
    { prop: 'reserved', name: this.translateService.instant('Reserved') + '\n(L/M/N/O)', flexGrow: 2, sortable: true },
    { prop: 'used', name: this.translateService.instant('Used') + '\n(L/M/N/O)', flexGrow: 2, sortable: false },
    { prop: 'total', name: this.translateService.instant('Total') + '\n(L/M/N/O)', flexGrow: 2, sortable: false },
    { prop: 'unitPrice', name: this.translateService.instant('Unit price'), flexGrow: 2, sortable: false },
    { prop: 'discountApplied', name: this.translateService.instant('Discount'), flexGrow: 2, sortable: true },
    { prop: 'totalPrice', name: this.translateService.instant('Total price'), flexGrow: 2, sortable: false },
    { prop: 'averageUnitPrice', name: this.translateService.instant('Average price'), flexGrow: 2, sortable: true },
    { prop: 'status', name: this.translateService.instant('Status'), flexGrow: 2, sortable: true },
    { prop: 'subscriptionCode', name: this.translateService.instant('Subscription'), flexGrow: 2, sortable: true },
  ];



  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private readonly apmService: ApmService,
    private readonly translateService: TranslateService
  ) { }

  public ngOnInit() {
    this.locale = this.translateService.currentLang;
    this.datePickerOptions.locale = this.translateService.currentLang === 'en' ? enLocale : plLocale;

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.locale = event.lang;
      this.datePickerOptions.locale = this.locale === 'en' ? enLocale : plLocale;
    });

    this.params.partnerId = (this.activatedRoute.snapshot.data as {partner: any}).partner.id;
  }

  public generate() {

    this.report$ = undefined;
    this.loading = true;

    this.params.from = moment(this.params.from).format('YYYY-MM-DD');
    this.params.to = moment(this.params.to).format('YYYY-MM-DD')

    this.report$ = this.apmService.generateReport(this.params).pipe(
      tap(() => this.loading = false)
    );
  }

  public exportToXls(reportData: PartnerReport) {
    const data = this.getDataToExport(reportData);
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

    const workbook: XLSX.WorkBook = { Sheets: { 'Report Details': worksheet }, SheetNames: ['Report Details']};
    const date = moment().format('YYYY-MM-DD');
    XLSX.writeFile(workbook, `${this.params.mode}-${(this.activatedRoute.snapshot.data as {partner: any}).partner.name}-${date}.xls`, { bookType: 'xls', type: 'buffer' });
  }

  private getDataToExport(reportData: PartnerReport) {
    const routeData = [];

    for (const item of reportData.reportDetails) {
      routeData.push({
        'Partner': (this.activatedRoute.snapshot.data as {partner: any}).partner.name,
        'Data rezerwacji': item.deliveryDate,
        'Lodówkomat': item.apmCode,
        'Rezerwacja': item.reservationNumber,
        'Dostępne skrytki L': item.available.lcount,
        'Dostępne skrytki M': item.available.mcount,
        'Dostępne skrytki N': item.available.ncount,
        'Dostępne skrytki O': item.available.ocount,
        'Zadeklarowane skrytki L': item.reserved.lcount,
        'Zadeklarowane skrytki M': item.reserved.mcount,
        'Zadeklarowane skrytki N': item.reserved.ncount,
        'Zadeklarowane skrytki O': item.reserved.ocount,
        'Użyte skrytki L': item.used.lcount,
        'Użyte skrytki M': item.used.mcount,
        'Użyte skrytki N': item.used.ncount,
        'Użyte skrytki O': item.used.ocount,
        'Ilość L': item.total.lcount,
        'Ilość M': item.total.mcount,
        'Ilość N': item.total.ncount,
        'Ilość O': item.total.ocount,
        'Koszt jednostkowy': item.unitPrice,
        'Rabat': item.discountApplied,
        'Koszt finalny': item.totalPrice,
        'Cena za skrytkę': item.averageUnitPrice,
        'Status': item.status,
        'Subskrypcja': item.subscriptionCode,
      })
    }

    routeData.push({});

    routeData.push({
      'Partner': 'PODSUMOWANIE',
      'Data rezerwacji': '',
      'Lodówkomat': '',
      'Rezerwacja': '',
      'Dostępne skrytki L': '',
      'Dostępne skrytki M': '',
      'Dostępne skrytki N': '',
      'Dostępne skrytki O': '',
      'Zadeklarowane skrytki L': reportData.totalReserved.lcount,
      'Zadeklarowane skrytki M': reportData.totalReserved.mcount,
      'Zadeklarowane skrytki N': reportData.totalReserved.ncount,
      'Zadeklarowane skrytki O': reportData.totalReserved.ocount,
      'Użyte skrytki L': reportData.totalUsed.lcount,
      'Użyte skrytki M': reportData.totalUsed.mcount,
      'Użyte skrytki N': reportData.totalUsed.ncount,
      'Użyte skrytki O': reportData.totalUsed.ocount,
      'Ilość L': reportData.total.lcount,
      'Ilość M': reportData.total.mcount,
      'Ilość N': reportData.total.ncount,
      'Ilość O': reportData.total.ocount,
      'Koszt jednostkowy': '',
      'Rabat': '',
      'Koszt finalny': reportData.totalPrice,
      'Cena za skrytkę': reportData.averageUnitPrice,
      'Subskrypcja': '',
    })

    return routeData;
  }
}
