import { Injectable } from '@angular/core';
import { CrudService } from '@services/crud.service';
import { environment } from '@environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilsService } from '@services/utils.service';
import { Region } from '../entities/region';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AssignDriverToRegion } from '../entities/assign-driver-to-region';
import { Driver } from '@drivers/entities/driver';
import { plainToClass } from 'class-transformer';
import { Apm } from '../entities/apm';
import { AssignedDriversInterface } from '../components/region/assign-driver.component';

@Injectable({
  providedIn: 'root'
})
export class RegionService extends CrudService<Region, number>{
  
    protected static BASE_API: string = `${environment.api.url}${environment.api.prefix}/partner/v1/` + '${partnerId}/region';
    private static ASSIGN_DRIVER_TO_REGION = `${environment.api.url}${environment.api.prefix}/partner/v2/` + '${partnerId}/region/${regionId}/assign-driver/';
    private static GET_ASSIGN_DRIVERS_TO_REGION = `${environment.api.url}${environment.api.prefix}/partner/v1/` + '${partnerId}/region/${regionId}/drivers/';
    private static UNASSIGN_DRIVER_TO_REGION = `${environment.api.url}${environment.api.prefix}/partner/v1/` + '${partnerId}/region/${regionId}/unassign-driver/${apmAuthorizationId}';

    private static ASSIGN_APMS_TO_REGION = `${environment.api.url}${environment.api.prefix}/partner/v1/` + '${partnerId}/region/${regionId}/apm';
    private static GET_ASSIGN_APMS_TO_REGION = `${environment.api.url}${environment.api.prefix}/partner/v1/` + '${partnerId}/region/${regionId}/apm?size=${size}&order=${order}&sort=${sort}&page=${page}';
    private static UNASSIGN_APM_TO_REGION = `${environment.api.url}${environment.api.prefix}/partner/v1/` + '${partnerId}/region/${regionId}/apm';

    constructor(
        protected _http: HttpClient,
        protected utilsService: UtilsService
    ) {
        super(_http, RegionService.BASE_API, utilsService);
    }

    public assignDriverToRegion(partnerId: string, regionId: string, drivers: string[]): Observable<AssignDriverToRegion> {
        const endpoint = this.utilsService.interpolate(`${RegionService.ASSIGN_DRIVER_TO_REGION}`, {partnerId, regionId});
        return this._http.post(endpoint, drivers).pipe(
            map((response: AssignDriverToRegion) => {
                return plainToClass(AssignDriverToRegion, response as AssignDriverToRegion);
            })
        );
       
    }

    public assignApmsToRegion(partnerId: string, regionId: string, apms: string[]): Observable<Apm[]> {
        const endpoint = this.utilsService.interpolate(`${RegionService.ASSIGN_APMS_TO_REGION}`, {partnerId, regionId});
        return this._http.post(endpoint, apms).pipe(
            map((response: Apm[]) => {
                return response;
            })
        );
    }

    public getAssignDriverToRegion(partnerId: string, regionId: string): Observable<AssignDriverToRegion[]> {
        const endpoint = this.utilsService.interpolate(`${RegionService.GET_ASSIGN_DRIVERS_TO_REGION}`, {partnerId, regionId});
        return this._http.get(endpoint).pipe(
            map((response: any) => {
                return plainToClass(AssignDriverToRegion, response as AssignDriverToRegion[]);
            })
        );
    }

    public getAssignApmsToRegion(partnerId: string, regionId: string): Observable<Driver[]> {
        const endpoint = this.utilsService.interpolate(`${RegionService.GET_ASSIGN_APMS_TO_REGION}`, {
            partnerId, 
            regionId,
            size: 1000,
            order: environment.pagination.order,
            sort: environment.pagination.sort,
            page: environment.pagination.page
        });

        return this._http.get(endpoint).pipe(
            map((response: any) => {
                return plainToClass(Driver, response as Apm[]);
            })
        );
    }

    public unassignDriverFromRegion(partnerId: string, regionId: string, apmAuthorizationId: string): Observable<Driver[]> {
        const endpoint = this.utilsService.interpolate(`${RegionService.UNASSIGN_DRIVER_TO_REGION}`, {partnerId, regionId, apmAuthorizationId});
        return this._http.delete(endpoint).pipe(
            map((response: any) => {
                return plainToClass(Driver, response as Driver[]);
            })
        );
    }

    public unassignApmFromRegion(partnerId: string, regionId: string, apmId: string): Observable<Apm[]> {
        const endpoint = this.utilsService.interpolate(`${RegionService.UNASSIGN_APM_TO_REGION}`, {partnerId, regionId});

        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: [apmId],
        };
          
        return this._http.delete(endpoint, options).pipe(
            map((response: any) => {
                return plainToClass(Apm, response as Apm[]);
            })
        );
    }

}
