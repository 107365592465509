import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { PartnerCourier } from '../../interfaces/partner-courier.interface';
import { Region } from '../../entities/region';
import { ORDER } from '@enums/enum';
import { Collection } from '@interfaces/collection.interface';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { PartnerCourierService } from '../../services/partner-courier.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { NavService } from '@services/nav.sevice';
import * as _ from 'lodash';
import { UtilsService } from '@services/utils.service';
import { environment } from '@environment';
import { QueryParams } from '@interfaces/query-params.interface';
import { PageableResponse } from '@entities/pagable-response';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-partner-courier',
  templateUrl: './partner-courier.component.html',
  styleUrls: ['./partner-courier.component.scss']
})
export class PartnerCourierComponent implements OnInit {

  public partnerCouriers: PartnerCourier[];
  private partnerCouriersCollection: PartnerCourier[];

  public region: Region;

  public loader: boolean = true;

  // protected page: number = 0;
  // protected size = 10;
  // protected order: ORDER = ORDER.DESC;
  // protected sortBy: string = 'createdAt';
  protected queryParams: QueryParams = Object.assign({}, environment.pagination);


  // protected pagable: Collection<PartnerCourier>;
  public list$: Observable<PageableResponse<PartnerCourier>>;

  public pageInfo;
  public modifiedRow: string;
  public rowClass;

  public partnerId: string;
  public regionId: string;

  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  public columns = [
    { prop: 'firstName', name: this.translateService.instant('Firstname'), flexGrow: 2, align: 'left' },
    { prop: 'lastName', name: this.translateService.instant('Lastname'), flexGrow: 2, align: 'left' },
    { prop: 'documentNumber', name: this.translateService.instant('Document number'), flexGrow: 2, align: 'left' },
    { prop: 'active', name: this.translateService.instant('Active'), flexGrow: 1, align: 'center' },
    { prop: 'options', name: this.translateService.instant('Options'), flexGrow: 1, align: 'center' },
  ];

  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  constructor(
    private partnerCourierService: PartnerCourierService,
    private navigationRoute: ActivatedRoute,
    private translateService: TranslateService,
    private modalService: BsModalService,
    private toastrProviderService: ToastrProviderService,
    private navService: NavService,
    private readonly utilsService: UtilsService

  ) { }

  public ngOnInit() {
    // this.pagable = (this.navigationRoute.snapshot.data as {courier: any}).couriers;
    // this.partnerCouriers = this.partnerCouriersCollection = this.pagable.content as PartnerCourier[];
    this.region = (this.navigationRoute.snapshot.data as {region: any}).region;
    this.queryParams.sort = 'lastName';

    this.list$ = this.partnerCourierService.list$.pipe(
      tap((list: PageableResponse<PartnerCourier>) => {
          this.partnerCouriers = this.partnerCouriersCollection = list.content;
        })
      );

    this.partnerCourierService.fetchAll({...this.queryParams, regionId: this.region.id});


    this.modifiedRow = this.navigationRoute.snapshot.params.modifiedRow;

    this.partnerId = this.utilsService.findUpRouteParams(this.navigationRoute.snapshot, 'partnerId');
    this.regionId = this.utilsService.findUpRouteParams(this.navigationRoute.snapshot, 'regionId');

    this.rowClass = row => {
      return { 'row-modified': row.id === this.modifiedRow };
    };
    this.loader = false;
  }

  public setPage(pageInfo?) {
    this.queryParams.page = pageInfo.offset;
    this.pageInfo = pageInfo;
    this.partnerCourierService.fetchAll({...this.queryParams, regionId: this.region.id});
  }

  public onSort(sortInfo) {
    this.queryParams.sort = sortInfo.sorts[0].prop
    this.queryParams.order = (sortInfo.sorts[0].dir === 'asc') ? ORDER.ASC : ORDER.DESC;
    this.partnerCourierService.fetchAll({...this.queryParams, regionId: this.region.id});
  }

  public isOptionsColumn(status: string): boolean {
    return status === 'options';
  }

  public isColumnActive(status: string): boolean {
    return status === 'active';
  }

  public isColumnToTranslate(status: string): boolean {
    return false;
  }

  public isColumnTConvertTime(status: string): boolean {
    return status === 'pickupSeconds' || status === 'pickupBoxSeconds' || status === 'pickupDeliverySeconds';
  }

  public goToDetails(id: string): void {
    this.navService.goToPage(`/partner/${this.partnerId}/region/${this.regionId}/couriers/${id}/edit`);
  }

  public createCourier(): void {
    this.navService.goToPage(`/partner/${this.partnerId}/region/${this.regionId}/couriers/create`);
  }

  public applyFiltering(event) {
    const filterPhrase = event.target.value.toLowerCase();
    this.queryParams.page = 0;
    this.queryParams.filter = filterPhrase;
    this.partnerCourierService.fetchAll({...this.queryParams, regionId: this.regionId});
  }

  public remove(id: string): void {
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.partnerCourierService.delete(id);
  }

  public confirm(): void {
    this.confirmObservable.subscribe((test) => {
      this.modalRef.hide();
      this.pageInfo = null;
      this.partnerCourierService.fetchAll({...this.queryParams, regionId: this.regionId});
      this.toastrProviderService.showSuccess(this.translateService.instant('The courier has been removed!'));
      this.confirmObservable = null;
    });
  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }

}
