<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="file-text"></i-feather> {{'Partner reports' | translate}}
            </h4>
        </div>

        <div toolbar>

            <div id="partner-reports-btn-from" class="flex-grow-1">
                <label
                    class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">{{'rangeFrom' | translate}}
                    *:</label>
                <ng-datepicker *ngIf="locale" class="menu__select menu__select--date mg-r-10 flex-grow-1 w-100"
                    [(ngModel)]="params.from" [options]="datePickerOptions"></ng-datepicker>
            </div>

            <div id="partner-reports-btn-to" class="flex-grow-1">
                <label
                    class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">{{'rangeTo' | translate}}
                    *:</label>
                <ng-datepicker *ngIf="locale" class="menu__select menu__select--date mg-r-10 flex-grow-1 w-100"
                    [(ngModel)]="params.to" [options]="datePickerOptions"></ng-datepicker>
            </div>

            <div id="partner-reports-btn-mode" class="flex-grow-1">
                <label
                    class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">{{'mode' | translate}}
                    *:</label>
                <select class="menu__select custom-select mg-r-10 flex-grow-1 w-100" [(ngModel)]="params.mode">
                    <option *ngFor="let type of partnerReportModeType" [value]="type">{{type | translate}}</option>
                </select>
            </div>

            <div id="partner-reports-btn-unit-price" class="flex-grow-1">
                <label
                    class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">{{'Unit price' | translate}}
                    ({{'PLN' | translate}}) *:</label>
                <input type="number" [(ngModel)]="params.unitPrice" class="form-control" />
            </div>

            <div id="partner-reports-btn-reservation-number" class="flex-grow-1">
                <label
                    class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">{{'reservationNumber' | translate}}:</label>
                <input type="text" [(ngModel)]="params.reservationNumber" class="form-control" />
            </div>

            <div id="partner-reports-btn-generate-report" class="flex-grow-1">
                <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">&nbsp;</label>
                <button
                    class="menu__button button btn btn-white btn-sm btn-uppercase flex-fill mg-r-10 flex-grow-1 w-100"
                    (click)="generate()">{{'Generate report' | translate}}</button>
            </div>


        </div>
        <hr />
    </div>

    <div content>
        <ng-container *ngIf="(report$ | async) as report; else loader">

            <table id="partner-reports-table" class="table">
                <thead class="thead-light">
                    <tr>
                        <th>{{ 'Total reserved' | translate }}</th>
                        <th>{{ 'Total used' | translate }}</th>
                        <th>{{ 'Total' | translate }}</th>
                        <th class="pd-7">{{ 'Total price' | translate }}</th>
                        <th>{{ 'Average price' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td id="partner-reports-table-col-total-reserved">{{ report.totalReserved  | rlmCapacityLabelPipe}}</td>
                        <td id="partner-reports-table-col-total-used">{{ report.totalUsed  | rlmCapacityLabelPipe}}</td>
                        <td id="partner-reports-table-col-total">{{ report.total  | rlmCapacityLabelPipe}}</td>
                        <td id="partner-reports-table-col-total-price">{{ report.totalPrice }} {{'PLN' | translate}}</td>
                        <td id="partner-reports-table-col-average-unit-price">{{ report.averageUnitPrice }} {{'PLN' | translate}}</td>
                    </tr>
                </tbody>
            </table>

            <div class="d-flex justify-content-between align-items-center">
                <p>&nbsp;</p>
                
                <p class="tx-12 text-center tx-spacing-1 tx-bold tx-color-01 tx-uppercase tx-sans mg-l-15 mg-t-40 mg-b-30">
                    {{'Report details' | translate}}</p>

                <button id="partner-reports-btn-export" class="menu__button button btn btn-white btn-sm btn-uppercase mg-r-10" (click)="exportToXls(report)">{{'Export' | translate}}</button>
            </div>
           

            <ngx-datatable class="bootstrap" [rows]="report.reportDetails" [columns]="columns" [columnMode]="'flex'"
                [headerHeight]="50" [footerHeight]="50" [messages]="tableMessages" [rowHeight]="'auto'"
                [externalPaging]="false">
                <ng-container *ngFor="let col of columns">
                    <ngx-datatable-column [flexGrow]="col.flexGrow" [prop]="col.prop" [headerClass]="'text-center'">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <b (click)="sort()">{{ col.name | translate }}</b>
                        </ng-template>
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>

                            <div class="text-center" [ngSwitch]="col.prop">

                                <div id="partner-reports-table-col-{{col.prop}}" *ngSwitchCase="'available'">
                                    <ng-container *ngTemplateOutlet="capacity; context: { value: value }">
                                    </ng-container>
                                </div>

                                <div id="partner-reports-table-col-{{col.prop}}" *ngSwitchCase="'reserved'">
                                    <ng-container *ngTemplateOutlet="capacity; context: { value: value }">
                                    </ng-container>
                                </div>

                                <div id="partner-reports-table-col-{{col.prop}}" *ngSwitchCase="'used'">
                                    <ng-container *ngTemplateOutlet="capacity; context: { value: value }">
                                    </ng-container>
                                </div>

                                <div id="partner-reports-table-col-{{col.prop}}" *ngSwitchCase="'total'">
                                    <ng-container *ngTemplateOutlet="capacity; context: { value: value }">
                                    </ng-container>
                                </div>

                                <div id="partner-reports-table-col-{{col.prop}}" *ngSwitchCase="'averageUnitPrice'">
                                    <ng-container *ngTemplateOutlet="price; context: { value: value }"></ng-container>
                                </div>

                                <div id="partner-reports-table-col-{{col.prop}}" *ngSwitchCase="'totalPrice'">
                                    <ng-container *ngTemplateOutlet="price; context: { value: value }"></ng-container>
                                </div>

                                <div id="partner-reports-table-col-{{col.prop}}" *ngSwitchCase="'unitPrice'">
                                    <ng-container *ngTemplateOutlet="price; context: { value: value }"></ng-container>
                                </div>

                                <div id="partner-reports-table-col-{{col.prop}}" *ngSwitchCase="'status'">
                                    <ng-container *ngTemplateOutlet="status; context: { value: value }"></ng-container>
                                </div>

                                <div id="partner-reports-table-col-{{col.prop}}" *ngSwitchDefault>{{value}}</div>

                                <ng-template let-value="value" #capacity>
                                    <span [containerClass]="'pretty-tooltip'" placement="right"
                                        [tooltip]="value | rlmCapacityLabelPipe">{{value | rlmCapacityLabelShortPipe}}</span>
                                </ng-template>

                                <ng-template let-value="value" #price>
                                    {{value}} {{'PLN' | translate}}
                                </ng-template>

                                <ng-template let-value="value" #status>
                                    <ng-container [ngSwitch]="value">
                                        <span *ngSwitchCase="apmDeliveryStatus.Cancelled" class="badge badge-danger">{{ 'ApmDeliveryStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmDeliveryStatus.Completed" class="badge badge-success">{{ 'ApmDeliveryStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmDeliveryStatus.New" class="badge badge-warning">{{ 'ApmDeliveryStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmDeliveryStatus.Reserved"  
                                            [containerClass]="'pretty-tooltip'" 
                                            placement="left" 
                                            [tooltip]="'Please, contact with your partner to handle the delivery' | translate"
                                            class="badge badge-info">{{ 'ApmDeliveryStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmDeliveryStatus.Used" class="badge badge-secondary">{{ 'ApmDeliveryStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmDeliveryStatus.Not_used" class="badge badge-primary">{{ 'ApmDeliveryStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmDeliveryStatus.Expired" class="badge badge-dark">{{ 'ApmDeliveryStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmDeliveryStatus.Reminded" class="badge badge-light">{{ 'ApmDeliveryStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmDeliveryStatus.Stored" class="badge badge-secondary">{{ 'ApmDeliveryStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmDeliveryStatus.Cancelled_Post_Cutoff" class="badge badge-danger">{{ 'ApmDeliveryStatus.'+value | translate }}</span>
                                    
                                        <span *ngSwitchCase="apmDeliveryStatus.Superseded" 
                                            [containerClass]="'pretty-tooltip'" 
                                            placement="left" 
                                            [tooltip]="row.reason | reasonTranslatePipe | translate"
                                            class="badge badge-success">{{ 'ApmDeliveryStatus.'+value | translate }}</span>

                                        <span *ngSwitchDefault class="badge badge-light">{{ 'ApmDeliveryStatus.'+value | translate }}</span>
                                    </ng-container>
                                </ng-template>

                            </div>


                        </ng-template>
                    </ngx-datatable-column>
                </ng-container>
            </ngx-datatable>
        </ng-container>

        <ng-template #loader>
            <ng-container [ngSwitch]="loading">
                <div *ngSwitchCase="true" [style.padding-top.px]="100">
                    <div class="load-spinner text-center">
                        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}
                    </div>
                </div>
                <div [style.padding-top.px]="100" *ngSwitchCase="false">
                    <div class="text-center" >
                        {{ 'No results to show' | translate }}.
                    </div>
                </div>

            </ng-container>
        </ng-template>
        

    </div>

</layout-default>


<!-- {{'ALL' | translate }} -->
<!-- {{'SHARED_ONLY' | translate }} -->
<!-- {{'SUBSCRIPTION_ONLY' | translate }} -->