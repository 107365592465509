import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { ShiftsService } from '@services/shifts.service';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormArray } from '@angular/forms';
import { ShiftSettings } from '@entities/shift-settings';
import { slideInOut } from '@shared/animations/animations';
import { Subscription, Observable } from 'rxjs';
import { Planning } from '@entities/planning';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { ShiftConfig } from '@calendar/entities/shiftConfig';
import { VanLimitInterface } from '@calendar/interafces/vanLimit.interface';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { ValidateVanLimit } from '@calendar/validators/van-limit-validator';
import { tap, map, combineLatest } from 'rxjs/operators';
import { ShiftPlanning } from '@calendar/interafces/shift-planning';
import { ShiftplanType } from '@enums/enum';
import { ShiftFleet } from '@calendar/interafces/shift-fleet.interfae';
import { VehicleTypesService } from '@hardware/services/vehicle-types.service';
import { VehicleTypes } from '@hardware/interfaces/vehicle-types.interface';
import { PageableResponse } from '@entities/pagable-response';

@Component({
  selector: 'app-shift-management',
  templateUrl: './shift-management.component.html',
  styleUrls: ['./shift-management.component.scss'],
  animations: [slideInOut]
})
export class ShiftManagementComponent implements OnInit, OnDestroy {

  constructor(
    private shiftService: ShiftsService,
    private toastrProviderService: ToastrProviderService,
    private translate: TranslateService,
    private modalService: BsModalService,
    private vehicleTypesService: VehicleTypesService,
    private formBuilder: FormBuilder) { 
      this.routeStrategySubscription = this.shiftService.routeStrategy.subscribe(strategies => this.routeStrategy = strategies);
    }

  @Output() public closeShiftManagement: EventEmitter<boolean> = new EventEmitter();
  @Output() public updatedData: EventEmitter<ShiftPlanning> = new EventEmitter();

  @Input() private shiftId: string;
  @Input() private shift: ShiftConfig;
  @Input() public mode: string;

  @ViewChild('vanLimitCard', { static: true }) private vanLimitCard: TemplateRef<any>;

  public shiftplanTypeEnum = Object.values(ShiftplanType);
  public vehicleTypes$: Observable<VehicleTypes[]>;
  public vehicleTypes: VehicleTypes[];

  public deliveryTimeControls: string[] = [ 'boxGroupSize', 'boxGroupTime', 'initialTime', 'parkingTime', 'parkingTimeThreshold']

  public form: FormGroup;
  public loading: boolean = true;
  public submitted: boolean = false;
  public routeStrategy: string[] = [];
  private vanLimits: FormArray;
  private slotLimits: FormArray;
  private fleet: FormArray;
  public maxProductLinesCheckbox: boolean = true;

  public hours: string[];
  private modalRef: BsModalRef;

  get planningSequence(): Observable<Planning[]> {
    return this.shiftService.planningSequence;
  }

  private routeStrategySubscription: Subscription;

  public ngOnInit() {
    this.vehicleTypesService.fetchAll();
    this.vehicleTypes$ = this.vehicleTypesService.list$.pipe(map((pageable: PageableResponse<VehicleTypes>) => {
      this.vehicleTypes = pageable.content;
      return this.vehicleTypes;
    }));

    this.initForm();
  }

  private initForm() {
    
    this.form = this.formBuilder.group({
      autoFulfill: [ this.shift.autoFulfill, Validators.required],
      automaticCutoff: [ this.shift.automaticCutoff, Validators.required],
      bookingWindowDayOffset: [ this.shift.bookingWindowDayOffset, Validators.required],
      bookingWindowRestricted: [ this.shift.bookingWindowRestricted, Validators.required],
      bookingWindowTimeOfDay: [ this.shift.bookingWindowTimeOfDay, Validators.required],
      createdAt: [ this.shift.createdAt, Validators.required],
      date: [ this.shift.date, Validators.required],
      departureWaveInitialSize: [ this.shift.departureWaveInitialSize, Validators.required],
      departureWaveInterval: [ this.shift.departureWaveInterval, Validators.required],
      departureWaveSize: [ this.shift.departureWaveSize, Validators.required],
      endTime: [ this.shift.endTime, [Validators.required, Validators.pattern('[0-9]{2}:[0-9]{2}:[0-9]{2}')]],
      expensiveDeliveryThreshold: [ this.shift.expensiveDeliveryThreshold, Validators.required],
      expensiveDeliveryThresholdTime: [ this.shift.expensiveDeliveryThresholdTime, Validators.required],
      id: [ this.shift.id, Validators.required],
      includeReturnInPlanning: [ this.shift.includeReturnInPlanning, Validators.required],
      label: [ this.shift.label, Validators.required],
      maxCostFactor: [ this.shift.maxCostFactor, Validators.required],
      maximumDeliveries: [ this.shift.maximumDeliveries, Validators.required],
      modifiedAt: [ this.shift.modifiedAt, Validators.required],
      offset: [ this.shift.offset, Validators.required],
      planningSequence: [ this.shift.planningSequence, Validators.required],
      reloadPercentage: [ this.shift.reloadPercentage, Validators.required],
      reloadTimeMinutes: [ this.shift.reloadTimeMinutes, Validators.required],
      reloads: [ this.shift.reloads, Validators.required],
      removePlanning: [ this.shift.removePlanning, Validators.required],
      routeMargin: [ this.shift.routeMargin, Validators.required],
      routeMaximumReloads: [ this.shift.routeMaximumReloads, Validators.required],
      routeNumberOffset: [ this.shift.routeNumberOffset, Validators.required],
      routeStrategy: [ this.shift.routeStrategy, Validators.required],
      segmentNumberOffset: [ this.shift.segmentNumberOffset, Validators.required],
      shiftEndMarginMinutes: [ this.shift.shiftEndMarginMinutes, Validators.required],
      shiftMaximumSegments: [ this.shift.shiftMaximumSegments, Validators.required],
      shiftStartMarginMinutes: [ this.shift.shiftStartMarginMinutes, Validators.required],
      slotDuration: [ this.shift.slotDuration, Validators.required],
      shiftplanType: [ this.shift.shiftplanType, Validators.required],
      slotEndMarginMinutes: [ this.shift.slotEndMarginMinutes, Validators.required],
      slotLimits: this.formBuilder.array([]),
      slotOverLapping: [ this.shift.slotOverLapping, Validators.required],
      slotStartMarginMinutes: [ this.shift.slotStartMarginMinutes, Validators.required],
      slots: [ this.shift.slots, Validators.required],
      startTime: [ this.shift.startTime, [Validators.required, Validators.pattern('[0-9]{2}:[0-9]{2}:[0-9]{2}')]],
      status: [ this.shift.status, Validators.required],
      time: [ this.shift.time, Validators.required],
      type: [ this.shift.type, Validators.required],
      unOptimizedDeliveries: [ this.shift.unOptimizedDeliveries, Validators.required],
      vanLimits: this.formBuilder.array([], [ValidateVanLimit]),
      version: [ this.shift.version, Validators.required],
      warehouse: [ this.shift.warehouse, Validators.required],
      maximumProductLines: [this.shift.maximumProductLines, Validators.required],
      fleet: this.formBuilder.array([], [ValidateVanLimit]),
      deliveryTime: this.formBuilder.group({
        boxGroupSize: [this.shift.deliveryTime.boxGroupSize, [Validators.required]],
        boxGroupTime: [this.shift.deliveryTime.boxGroupTime, [Validators.required]],
        initialTime: [this.shift.deliveryTime.initialTime, [Validators.required]],
        parkingTime: [this.shift.deliveryTime.parkingTime, [Validators.required]],
        parkingTimeThreshold: [this.shift.deliveryTime.parkingTimeThreshold, [Validators.required]],
      })
    });

    this.maxProductLinesCheckbox = (this.shift.maximumProductLines !== -1)

    this.vanLimits = <FormArray>this.form.controls['vanLimits'];
    if (this.shift) {
      this.shift.vanLimits.forEach((item) => this.vanLimits.push(this.patchValuesVanLimit(item)));
    }

    this.slotLimits = <FormArray>this.form.controls['slotLimits'];
    if (this.shift) {
      _.keys(this.shift.slotLimits).forEach((key) => this.slotLimits.push(this.patchValuesSlotLimit(key)));
    }

    this.fleet = <FormArray>this.form.controls['fleet'];
    if (this.shift) {
      this.shift.fleet.forEach((key: ShiftFleet) => this.fleet.push(this.patchFleet(key)));
    }

    this.loading = false;

    this.form.valueChanges.subscribe(
      (form: ShiftPlanning) => {
        this.updatedData.emit(this.updateSlotLimits(form));
      }
    )

    const shift = this.updateSlotLimits(this.form.getRawValue() as ShiftPlanning);
    this.updatedData.emit(shift);
    console.log(this.form);
  }

  private patchValuesVanLimit(item?: VanLimitInterface): AbstractControl {
    return this.formBuilder.group({
      usedPercentage: [item ? item.usedPercentage : 0, Validators.required],
      blockedSlots: [item ? item.blockedSlots : 0, Validators.required],
    })
  }

  private patchValuesSlotLimit(key?: string): AbstractControl {
    return this.formBuilder.group({
      key: [key ? key : 0, [Validators.required, Validators.pattern('[0-9]{2}:[0-9]{2}')]],
      value: [key ? this.shift.slotLimits[key] : 0, Validators.required],
    })
  }

  private patchFleet(fleet?: ShiftFleet): AbstractControl {
    return this.formBuilder.group({
      vehicleTypeId: [(fleet) ? fleet.vehicleType.id : null, Validators.required],
      max: [(fleet) ? fleet.max : null, Validators.required],
    });
  }

  private updateSlotLimits(shift: ShiftPlanning): ShiftPlanning {
    const slotsLimitObj = {};
    this.slotLimits.value.forEach(item => slotsLimitObj[item['key']] = item['value']);
    delete shift['slotLimits'];
    shift['slotLimits'] = {};
    Object.assign(shift['slotLimits'], slotsLimitObj);
    return shift;
  }

  public onSubmit() {

    const vanLimitObj = {};
    this.slotLimits.value.forEach(item => vanLimitObj[item['key']] = item['value']);
    const rawShift = this.form.getRawValue();
    delete rawShift['slots'];
    rawShift['slotLimits'] = {};
    this.submitted = true;

    Object.assign(rawShift['slotLimits'], vanLimitObj);

    if (this.mode === 'SAVE') {
      const body: ShiftConfig = new ShiftConfig().deserialize(rawShift);
      this.shiftService.updateShift(body).subscribe(
        (shift) => {
          this.submitted = false;
        }, (error) => {
          this.submitted = false;
        });
    } else {
      this.closeShiftManagement.emit();
      // this.shiftService.calculateTemporaryResults(rawShift).subscribe(
      //   (shift) => {
      //     this.toastrProviderService.showSuccess(this.translate.instant('New routes has been calculated!'));
      //     this.closeShiftManagement.emit(true);
      //     this.submitted = false;
      //   }, (error) => {
      //     console.log(error);
      //     this.submitted = false;
      //   });
    }
  }

  public addVanLimit(): void {
    if (this.vanLimits.length) {
      this.vanLimits.push(this.patchValuesVanLimit());
    } else {
      const item: VanLimitInterface = { blockedSlots: 0, usedPercentage: 0 }
      this.vanLimits.push(this.patchValuesVanLimit(item));

      const item2: VanLimitInterface = { blockedSlots: 0, usedPercentage: 100 }
      this.vanLimits.push(this.patchValuesVanLimit(item2));
    }
  }

  public addFleet(): void {
    this.fleet.push(this.patchFleet());
  }

  public removeVanLimit(i): void {
    (<FormArray>this.form.controls['vanLimits']).removeAt(i);
  }

  public addSlotLimit(): void {
    this.slotLimits.push(this.patchValuesSlotLimit());
  }

  public removeSlotLimit(i): void {
    (<FormArray>this.form.controls['slotLimits']).removeAt(i);
  }

  public openVanLimitCard() {
    this.modalRef = this.modalService.show(this.vanLimitCard, { class: 'modal-lg' });
  }

  public resetForm(): void {
    this.initForm();
  }

  public close(): void {
    this.closeShiftManagement.emit(true);
  }

  public maxProductLinesCheckboxFun(): void {
    this.maxProductLinesCheckbox = !this.maxProductLinesCheckbox;
    if (!this.maxProductLinesCheckbox) {
      this.form.get('maximumProductLines').setValue(-1);
    }
  }

  public ngOnDestroy() {
    if (this.routeStrategySubscription) {
      this.routeStrategySubscription.unsubscribe();
    }
  }
}
