export enum ApmDeliveryStatus {
    Reserved = 'RESERVED',
    New = 'NEW',
    Completed = 'COMPLETED',
    Cancelled = 'CANCELLED',
    Stored = 'STORED',
    Reminded = 'REMINDED',
    Expired = 'EXPIRED',
    Not_used = 'NOT_USED',
    Used = 'USED',
    Returned = 'RETURNED',
    Cancelled_Post_Cutoff = 'CANCELLED_POST_CUTOFF',
    Superseded = 'SUPERSEDED'
}
