import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrProviderService } from '@services/toastr-provider.service';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Address } from '@entities/address';
import { Location } from '@entities/location';
import { NavService } from '@services/nav.sevice';
import { RouteId } from '@shared/entities/routeId';
import { AddressComponentType } from '../entites/addressComponentType';
import { GeocodingService } from '../services/geocoding.service';
import { LocationsService } from '../services/locations.service';
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;
import { Client, mapTypes } from '@enums/enum';

@Component({
    selector: 'edit-location',
    template: `
        <layout-default>
            <div header>
                <div toolbar>
                    <h4>
                        <i-feather name="map-pin"></i-feather>
                        {{ 'Location Edit' | translate }}
                    </h4>
                </div>
                <hr />
            </div>

            <div content>
                <ng-container *ngIf="location">
                    <div class="d-flex flex-row">
                        <div class="card" style="flex-basis: 60%">
                            <div class="card-header">
                                <div class="card-header__row row">
                                    <div class="col-5">{{ 'Original address' | translate }}</div>
                                    <div class="col-4">{{ 'Initial result' | translate }}</div>
                                    <div class="col-3">
                                        <button class="btn btn-xs pd-x-15 btn-uppercase mg-l-5 btn-white w-100" (click)="revertToOriginal()">{{ 'Reset changes' | translate }}</button>
                                    </div>
                                </div>
                            </div>

                            <div class="card-body mb-4 row">
                                <div class="card-text col-5">
                                    <button class="btn btn-link btn-sm" (click)="useOriginal()">
                                        {{ originalLocation.getOriginalAddressAsRaw() }}
                                    </button>
                                </div>
                                <div class="card-text col-5">
                                    <button class="btn btn-link btn-sm" (click)="useInitial()">
                                        {{ originalLocation.getAddressAsRaw() }}
                                        <span class="badge badge-warning">{{ location.geocodingStatus | translate }}</span>
                                    </button>
                                </div>
                            </div>

                            <div>
                                <div class="card-header">
                                    <div class="card-header__row row">
                                        <div class="col-10">{{ 'Address form' | translate }}</div>
                                        <div class="col-2">
                                            <button class="btn btn-xs pd-x-15 btn-uppercase mg-l-5 btn-brand-01 w-100" type="button" (click)="geocodeAddress()">{{ 'Geocode' | translate }}</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <form [formGroup]="locationForm">
                                        <div formGroupName="address">
                                            <div class="row">
                                                <div class="form-group col-4">
                                                    <label for="street">{{ 'Street' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="street" formControlName="street" />
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="houseNO">{{ 'House NO' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="houseNO" formControlName="houseNO" />
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="town">{{ 'Town' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="town" formControlName="town" />
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="zip">{{ 'Zip' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="zip" formControlName="zip" />
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="district">{{ 'District' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="district" formControlName="district" />
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="voivoidship">{{ 'Voivodeship' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="voivoidship" formControlName="voivoidship" />
                                                </div>
                                            </div>
                                        </div>
                                        <div formGroupName="additional">
                                            <div class="row">
                                                <div class="form-group col-4">
                                                    <label for="companyName">{{ 'Company Name' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="companyName" formControlName="companyName" />
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="flatNO">{{ 'Flat NO' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="flatNO" formControlName="flatNO" />
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="lift">{{ 'Lift' | translate }}</label>
                                                    <select class="form-control form-control-sm" id="lift" formControlName="lift">
                                                        <option [value]=""></option>
                                                        <option [value]="true">{{ 'Yes' | translate }}</option>
                                                        <option [value]="false">{{ 'No' | translate }}</option>
                                                    </select>
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="staircaseNO">{{ 'Staircase NO' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="staircaseNO" formControlName="staircaseNO" />
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="floor">{{ 'Floor' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="floor" formControlName="floor" />
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="contactName">{{ 'Contact Name' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="contactName" formControlName="contactName" />
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="contactPhoneNO">{{ 'Contact Phone Number' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="contactPhoneNO" formControlName="contactPhoneNO" />
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="domofonCode">{{ 'Domofon Code' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="domofonCode" formControlName="domofonCode" />
                                                </div>
                                            </div>
                                        </div>

                                        <div formGroupName="coordinates">
                                            <div class="row">
                                                <div class="form-group col-6">
                                                    <label for="lat">{{ 'Latitude' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="lat" formControlName="lat" />
                                                </div>
                                                <div class="form-group col-6">
                                                    <label for="lng">{{ 'Longitude' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="lng" formControlName="lng" />
                                                </div>
                                            </div>
                                        </div>

                                        <div appCheckClient [client]="inpostClient" formGroupName="additional" class="form-group">
                                            <label for="accessKey">{{ 'Access key' | translate }}</label>
                                            <input class="form-control form-control-sm" type="text" id="lng" formControlName="accessKey" />
                                        </div>

                                        <div class="form-group">
                                            <label for="domofonCode">{{ 'Remarks' | translate }}</label>
                                            <textarea class="form-control form-control-sm" type="text" formControlName="remarks"></textarea>
                                        </div>

                                        <div class="form-group-inline d-flex">
                                            <!--FROM ROUTE-->
                                            <ng-container *ngIf="mode == 1">
                                                <a (click)="goToRoute()" class="btn btn-sm btn-uppercase btn-white w-100">{{ 'Cancel' | translate }}</a>
                                            </ng-container>

                                            <!--FROM Locations-->
                                            <ng-container *ngIf="mode == 2">
                                                <a class="btn btn-sm btn-uppercase btn-white w-100" [routerLink]="['locations']">
                                                    {{ 'Cancel' | translate }}
                                                </a>
                                            </ng-container>
                                            <button class="btn btn-sm btn-uppercase mg-l-10 btn-brand-01 w-100" (click)="onSubmit(true)">{{ 'Update address globally' | translate }}</button>
                                            <button class="btn btn-sm btn-uppercase mg-l-10 btn-brand-01 w-100" (click)="onSubmit()">{{ 'Update only delivery address' | translate }}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div class="card" style="flex-basis: 40%">
                            <div>
                                <div class="card-header">
                                    <h5 class="header-row card-header__heading">{{ 'Geocoding results' | translate }}</h5>
                                </div>
                                <div class="card-body">
                                    <ul style="list-style: none">
                                        <li *ngFor="let result of results">
                                            <button class="btn btn-link" (click)="useResult(result)">
                                                <ng-container *ngIf="result.geocoderResult && result.geocoderResult.partial_match">
                                                    <i-feather name="map-pin"></i-feather>
                                                    {{ result.address.raw }}
                                                    <span class="badge badge-warning">{{ 'PARTIAL_MATCH' | translate }}</span>
                                                </ng-container>

                                                <ng-container *ngIf="result.geocoderResult && !result.geocoderResult.partial_match">
                                                    <i-feather name="map-pin"></i-feather>
                                                    {{ result.address.raw }}
                                                    <span class="badge badge-success">{{ 'FULL_MATCH' | translate }}</span>
                                                </ng-container>
                                            </button>
                                        </li>
                                    </ul>

                                    <div class="map-container">
                                        <gmap *ngIf="mapOptions" [coordinates]="results[0].marker.latLng" [options]="mapOptions" (markerChanged)="markerDragEnd($event)" [options]="mapOptions"></gmap>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </layout-default>

        <!--{{"NEW"|translate}}-->
        <!--{{"ENCODED"|translate}}-->
        <!--{{"NOTFOUND"|translate}}-->
        <!--{{"PARTIAL"|translate}}-->
        <!--{{"MANUAL"|translate}}-->
        <!--{{"ERROR"|translate}}-->
    `,
    styleUrls: ['location-edit.component.scss'],
})
export class LocationEditComponent implements OnInit, OnDestroy {
    public static MODE_FROM_ROUTE = 1;
    public static MODE_FROM_LOCATIONS = 2;

    @ViewChild('map', { static: false }) public map: any;
    private routingSubscription: Subscription;

    protected TAG: string = '[LocationEditComponent]';
    public inpostClient: string = Client.INPOST;

    // todo move to config or fetch from backend
    protected friscoWarehouse = { lat: 52.2418105, lng: 21.0962407 };
    protected bounds: any;

    protected center = { lat: 52.237049, lng: 21.017532 };

    protected assetsPath = '/assets/img';
    protected markerPath = `${this.assetsPath}/markers`;
    protected zoom: number = 11;

    public location: Location;
    protected originalLocation: Location;
    protected address: Address;
    private deliveryId: string;

    public mapOptions = {
        polyline: false,
        type: 'SIMPLE',
    };

    public mapProperties = {
        zoom: 18,
        mapTypeId: mapTypes.ROADMAP,
    };

    private id: string;

    protected latRange = {
        min: 0,
        max: 0,
    };

    protected lngRange = {
        min: 0,
        max: 0,
    };

    get latCenter() {
        return (this.latRange.min + this.latRange.max) / 2 || 52.237049;
    }

    get lngCenter() {
        return (this.lngRange.min + this.lngRange.max) / 2 || 21.017532;
    }

    protected results: {
        geocoderResult: google.maps.GeocoderResult;
        address: Address;
        marker: Marker;
    }[] = [];

    protected locationForm = this.fb.group({
        remarks: [''],
        additional: this.fb.group({
            companyName: [''],
            flatNO: [''],
            lift: [''],
            staircaseNO: [''],
            floor: [''],
            contactName: [''],
            contactPhoneNO: [''],
            domofonCode: [''],
            accessKey: [''],
        }),
        address: this.fb.group({
            town: [''],
            zip: [''],
            district: [''],
            voivoidship: [''],
            country: ['Polska'],
            street: [''],
            houseNO: [''],
        }),
        coordinates: this.fb.group({
            lat: [''],
            lng: [''],
        }),
    });

    protected mode: number;
    public routeId: RouteId;

    constructor(private fb: FormBuilder, private locationService: LocationsService, private route: ActivatedRoute, private navService: NavService, private toastrProviderService: ToastrProviderService, private translate: TranslateService, private navigationRoute: ActivatedRoute, private geocodingService: GeocodingService, private changeDetector: ChangeDetectorRef) {}

    protected navRouteParams: any = null;

    public ngOnInit() {
        console.log(this.TAG, 'Route', this.route);

        this.routingSubscription = this.route.params.subscribe((params) => {
            this.navRouteParams = params;
            this.id = this.navRouteParams.id;
            this.deliveryId = this.navRouteParams.deliveryId;

            if (_.keys(params).length === 1) {
                this.mode = LocationEditComponent.MODE_FROM_LOCATIONS;
            } else {
                this.mode = LocationEditComponent.MODE_FROM_ROUTE;
            }

            if (this.mode === LocationEditComponent.MODE_FROM_ROUTE) {
                const routeId = RouteId.getRouteId3(this.navRouteParams.y, this.navRouteParams.m, this.navRouteParams.d, this.navRouteParams.routeNumber, this.navRouteParams.warehouse);
                this.routeId = RouteId.parse(routeId);
                this.routeId.shift = this.navRouteParams.shiftId;
            }

            if (this.navRouteParams.locationId !== null) {
                this.locationService
                    .getLocation(this.navRouteParams.locationId)
                    .pipe(
                        map((location: Location) => {
                            this.originalLocation = _.cloneDeep(location);
                            this.setLocation(location);
                            this.changeDetector.detectChanges();
                        })
                    )
                    .subscribe();
            }

            this.changeDetector.detectChanges();
        });
    }

    public ngOnDestroy(): void {
        this.routingSubscription.unsubscribe();
    }

    protected geocodeAddress() {
        console.log(this.TAG, 'geocodeAddress', this.locationForm);
        const address: Address = new Address().deserialize(this.locationForm.getRawValue()['address']);
        console.log(this.TAG, 'geocodeAddress', address);

        this.geocodingService
            .geocode(address.raw)
            .pipe(
                take(1),
                map((results: google.maps.GeocoderResult[]) => {
                    console.log(this.TAG, 'Geocoding result', this.results);

                    this.results = results.map((geocoderResult: google.maps.GeocoderResult) => {
                        const resultAddress = this.toAddress(geocoderResult);

                        let markerIcon;
                        if (geocoderResult.partial_match) {
                            markerIcon = `${this.markerPath}/location-partial.png`;
                        } else {
                            markerIcon = `${this.markerPath}/location-result.png`;
                        }

                        const location = geocoderResult.geometry.location.toJSON();

                        this.locationForm.get('coordinates').get('lat').setValue(location.lat.toString());
                        this.locationForm.get('coordinates').get('lng').setValue(location.lng.toString());

                        return {
                            geocoderResult: geocoderResult,
                            address: resultAddress,
                            marker: {
                                ...(location as any),
                                latLng: { lat: location.lat, lng: location.lng },
                            },
                        };
                    });

                    console.log(this.TAG, 'Geocoding result', this.results);

                    this.changeDetector.detectChanges();
                })
            )
            .subscribe();
    }

    public onSubmit(updateGloball = false) {
        const locationData = this.locationForm.getRawValue();
        this.location.patch(locationData);
        this.location.additional['accessKey'] = locationData.additional.accessKey;

        console.log(this.TAG, 'Updated location', this.location);

        if (!updateGloball) {
            this.locationService
                .updateDeliveryLocation(this.deliveryId, this.location)
                .pipe(
                    map((location: Location) => {
                        this.proceedSuccess(location);
                    })
                )
                .subscribe();
        } else {
            this.locationService
                .updateLocationGlobally(this.location.id, this.location)
                .pipe(
                    map((location: Location) => {
                        this.proceedSuccess(location);
                    })
                )
                .subscribe();
        }
    }

    private proceedSuccess(location: Location) {
        this.setLocation(location);

        this.toastrProviderService.showSuccess(this.translate.instant('Data has been updated'));
        switch (this.mode) {
            case LocationEditComponent.MODE_FROM_ROUTE:
                const arg = this.routeId.getRouteId('/');
                this.navService.goToPage(`routes/${arg}/route/${this.id}`);
                break;
            case LocationEditComponent.MODE_FROM_LOCATIONS:
                this.navService.goToPage('/locations');
                break;
        }
    }

    public revertToOriginal() {
        const location = _.cloneDeep(this.originalLocation);

        console.log(this.TAG, 'revertToOriginal', location);

        this.setLocation(location);
        this.changeDetector.detectChanges();
    }

    public useOriginal() {
        this.location.address = _.cloneDeep(this.originalLocation.originalAddress);

        console.log(this.TAG, 'useOriginal', this.location);

        this.setLocation(this.location);
        this.geocodeAddress();
        this.changeDetector.detectChanges();
    }

    public useInitial() {
        this.location.address = _.cloneDeep(this.originalLocation.address);

        console.log(this.TAG, 'useInitial', this.location);

        this.setLocation(this.location);
        this.geocodeAddress();
        this.changeDetector.detectChanges();
    }

    public useResult(result: { geocoderResult: google.maps.GeocoderResult; address: Address }) {
        this.location.address = result.address;
        this.location.coordinates.deserialize(result.geocoderResult.geometry.location.toJSON());
        console.log(this.TAG, 'User Result', this.location);

        this.setLocation(this.location);
        this.changeDetector.detectChanges();
    }

    public goToRoute() {
        this.navService.navigateBack();
    }

    private setLocation(location: Location) {
        this.location = location;

        console.log(this.TAG, 'setLocation', location.serialize());

        this.locationForm.patchValue(location as any);

        let markerIcon;
        if (location.hasEncodingIssue) {
            markerIcon = `${this.markerPath}/location-partial.png`;
        } else {
            markerIcon = `${this.markerPath}/location-result.png`;
        }

        this.results.push({
            geocoderResult: null,
            address: location.address,
            marker: Marker.of(
                {
                    lat: location.coordinates.lat,
                    lng: location.coordinates.lng,
                },
                location.rawAddress,
                false,
                markerIcon
            ),
        });

        const latValues = [];
        const lngValues = [];

        this.results.forEach((r) => {
            latValues.push(location.coordinates.lat);
            lngValues.push(location.coordinates.lng);
        });

        this.latRange = {
            min: _.min(latValues),
            max: _.max(latValues),
        };

        this.lngRange = {
            min: _.min(lngValues),
            max: _.max(lngValues),
        };

        this.changeDetector.detectChanges();

        return;
    }

    public markerDragEnd(coords: { lat: number; lng: number }) {
        this.locationForm.get('coordinates').get('lat').setValue(coords.lat.toString());
        this.locationForm.get('coordinates').get('lng').setValue(coords.lng.toString());
    }

    private getAddressComponent(components: GeocoderAddressComponent[], type) {
        const result = components.find((component: GeocoderAddressComponent) => {
            return component.types.includes(type);
        });

        if (!_.isUndefined(result)) {
            return result.long_name;
        }

        return null;
    }

    private toAddress(geocoderResult: google.maps.GeocoderResult): Address {
        const address = new Address();

        address.street = this.getAddressComponent(geocoderResult.address_components, AddressComponentType.ROUTE);
        address.houseNO = this.getAddressComponent(geocoderResult.address_components, AddressComponentType.STREET_NUMBER);
        address.town = this.getAddressComponent(geocoderResult.address_components, AddressComponentType.LOCALITY);
        address.zip = this.getAddressComponent(geocoderResult.address_components, AddressComponentType.POSTAL_CODE);
        address.country = this.getAddressComponent(geocoderResult.address_components, AddressComponentType.COUNTRY);
        address.voivoidship = this.getAddressComponent(geocoderResult.address_components, AddressComponentType.ADMINISTRATIVE_AREA_LEVEL_1);
        address.district = this.getAddressComponent(geocoderResult.address_components, AddressComponentType.SUBLOCALITY_LEVEL_1);

        return address;
    }
}

export class Marker {
    public latLng: { lat: number; lng: number };
    public label: string;
    public selected: boolean;
    public marker: string;

    public static of(latLng: { lat: number; lng: number }, label: string, selected: boolean, marker: string) {
        const m = new Marker();

        m.latLng = latLng;
        m.label = label;
        m.selected = selected;
        m.marker = marker;

        return m;
    }
}
