<layout-default>
    <div header>
        <div toolbar>
            <h4 *ngIf="mode === 'CREATE'">
                <i-feather name="cast"></i-feather> {{'Create apm maintenance window' | translate}}
            </h4>
            <h4 *ngIf="mode === 'UPDATE'">
                <i-feather name="cast"></i-feather> {{'Edit apm maintenance window' | translate}}: {{apm.name}}
            </h4>
        </div>

        <hr />
    </div>
    
    <div content>
        <div *ngIf="!loader" class="content bg-white">

            <div class="card-content col-8 offset-1">

                <div class="row pd-t-30">
                    <div class="col-9 offset-3">
                        <p *ngIf="[0, 1].includes(selectedApms.length)">{{'Name' | translate}}: <strong>{{apm?.name}}</strong>, {{'Code' | translate}}: <strong>{{apm?.code}}</strong> - 
                        
                            <span 
                            [containerClass]="'pretty-tooltip'" 
                            placement="right" 
                            [tooltip]="apm | rlmCapacityLabelPipe">({{apm | rlmCapacityLabelShortPipe}})</span>

                        </p>
                        <div class="apm-list" *ngIf="![0, 1].includes(selectedApms.length)"><strong>{{'Apms names list' | translate}}:</strong>
                            <ul>
                                <li *ngFor="let apm of selectedApms">{{apm}}</li>
                            </ul>
                        </div>
                    </div>
                </div>


                
                <form [formGroup]="form">

                    <ng-container formGroupName="slot">
                        <div class="form-group row dat-apm-maint-from">
                            <label class="col-3" for="from">{{ 'From' | translate }}:</label>
                            <div class="col-9">
                                <div class="row">
                                    <div class="col">
                                        <ng-datepicker class="datepicker-input form-group" formControlName="fromDate" id="to" [headless]="disabled" [options]="options"> </ng-datepicker>
                                        <div *ngIf="form.get('slot').get('from').hasError('required') && form.get('slot').get('from').touched" class="error-color">{{ 'Date is required' | translate }}!</div>
                                        <div *ngIf="form.get('slot').get('fromTime').hasError('pastDate')" class="error-color">{{ 'The date cannot be passed' | translate }}!</div>

                                    </div>
                                    <div class="col">
                                        <input typ="text" [dropSpecialCharacters]="false" [validation]="true" formControlName="fromTime" mask="Hh:m0" class="form-control" placeholder="Godzina" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row dat-apm-maint-to">
                            <label class="col-3" for="to">{{ 'To' | translate }}:</label>
                            <div class="col-9">
                                <div class="row">
                                    <div class="col">
                                        <ng-datepicker class="datepicker-input form-group" formControlName="toDate" id="to" [headless]="disabled" [options]="options"> </ng-datepicker>
                                        <div *ngIf="form.get('slot').get('to').hasError('required') && form.get('slot').get('to').touched" class="error-color">{{ 'Date is required' | translate }}!</div>
                                        <div *ngIf="form.get('slot').get('toTime').hasError('fromAfterTo')" class="error-color">{{ 'Date is before beginning of timeslot' | translate }}!</div>

                                    </div>
                                    <div class="col">
                                        <input typ="text" [dropSpecialCharacters]="false" [validation]="true" formControlName="toTime" mask="Hh:m0" class="form-control" placeholder="Godzina" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <div class="form-group row sel-apm-maint-type">
                        <label class="col-3" for="type">{{'Type' | translate}}:&nbsp;<span *ngIf="form.get('type') | isRequiredControlPipe">*</span></label>
                        <div class="col-9">
                            <select class="custom-select" formControlName="type">
                                <option *ngFor="let type of apmMaintenanceType" [value]="type">{{'ApmMaintenanceType.'+type | translate}}</option>
                            </select>

                            <div *ngIf="form.get('type').hasError('required') && form.get('type').touched" class="error-color">
                                {{ "Type field is required" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div class="form-group row sel-apm-maint-origin">
                        <label class="col-3" for="type">{{'Origin' | translate}}:&nbsp;<span *ngIf="form.get('origin') | isRequiredControlPipe">*</span></label>
                        <div class="col-9">
                            <select class="custom-select" formControlName="origin">
                                <option value="MANUAL">{{'ApmMaintenanceOrigin.MANUAL' | translate}}</option>
                            </select>

                            <div *ngIf="form.get('origin').hasError('required') && form.get('origin').touched" class="error-color">
                                {{ "Type field is required" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div class="form-group row inp-apm-maint-lockers">
                        <label class="col-3" for="code">{{'Lockers' | translate }}:</label>
                        <div class="col-9">
                            <div class="row">
                                <div *ngFor="let item of capacityControls; let i = index;" class="item col-3">
                                    <p class="d-block tx-sans tx-10 tx-uppercase tx-color-03 tx-spacing-1 text-left mg-b-0 pd-b-0">
                                        <ng-container [ngSwitch]="item">
                                            <span *ngSwitchCase="'lcount'">
                                                {{'CompartmentType.Cooler' | translate}}
                                            </span>
                                            <span *ngSwitchCase="'mcount'">
                                                {{'CompartmentType.Freezer' | translate}}
                                            </span>
                                            <span *ngSwitchCase="'ncount'">
                                                {{'CompartmentType.Ambient' | translate}}
                                            </span>
                                            <span *ngSwitchCase="'ocount'">
                                                {{'CompartmentType.Other' | translate}}
                                            </span>
                                        </ng-container>:&nbsp;<span *ngIf="form.get(item) | isRequiredControlPipe">*</span></p>
                                    <input [formControlName]="item" type="number" class="form-control" />
                                    <div *ngIf="form.get(item).hasError('required') && form.get(item).touched" class="error-color">{{ 'The field is required' | translate }}!</div>
                                    <div *ngIf="form.get(item).hasError('min') && form.get(item).touched" class="error-color">{{ 'Minimum value is 0' | translate }}!</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="errorMsg" class="form-group row">
                        <div class="col-9 offset-3">
                            <p class="error-color">{{ 'Something has wrong! Please correct form' | translate }}:</p>
                            <ul>
                                <li class="error-color">
                                    <span>{{ errorMsg | translate }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="form-group row justify-content-end">
                        <div class="col-2">
                            <button (click)="onSubmit()" [disabled]="!form.valid || submitted"
                                class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01 w-100">

                                <span *ngIf="!submitted">{{ "Save" | translate }}</span>

                                <ng-container *ngIf="submitted">
                                    <div class="load-spinner text-center">
                                        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                    </div>
                                </ng-container>

                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</layout-default>


<!-- {{'There is already maintenance registered for that timeslot' | translate}} -->
<!-- {{'ApmMaintenanceType.SCHEDULED_MAINTENANCE' | translate}} -->
<!-- {{'ApmMaintenanceType.DEFECT' | translate}} -->
<!-- {{'ApmMaintenanceType.DIRTY' | translate}} -->
<!-- {{'ApmMaintenanceType.OTHER' | translate}} -->
<!-- {{'ApmMaintenanceOrigin.AUTOMATIC' | translate}} -->
<!-- {{'ApmMaintenanceOrigin.MANUAL' | translate}} -->
<!-- {{'ApmMaintenanceOrigin.HAACP' | translate}} -->