import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { PartnerCourierService } from '../services/partner-courier.service';
import { PartnerCourier } from '../interfaces/partner-courier.interface';
import { Collection } from '@interfaces/collection.interface';
import { Observable } from 'rxjs';
import { UtilsService } from '@services/utils.service';

@Injectable()
export class PartnerCouriersResolver implements Resolve<Collection<PartnerCourier>> {
    constructor(private partnerCourierService: PartnerCourierService, private readonly utilsService: UtilsService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        const regionId = this.utilsService.findUpRouteParams(route, 'regionId');
        return this.partnerCourierService.getAllCouriersForRegion(regionId) as Observable<Collection<PartnerCourier>>;
    }
}
