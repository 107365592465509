import { NgModule, ModuleWithProviders } from '@angular/core';
import { FriscoAppRoutingModule } from './frisco-routing.module';
import { FriscoAppComponent } from './frisco-app.component';
import { AppModule } from 'src/app/app.module';
import { SalaryModule } from './salary/salary.module';

const providers = [];

@NgModule({
  declarations: [
    FriscoAppComponent,
  ],
  imports: [
    FriscoAppRoutingModule,
    SalaryModule
  ],
  providers: providers,
  bootstrap: [FriscoAppComponent]
})
export class FriscoAppModule { }

@NgModule({})
export class FriscoSharedModule{
  public static forRoot(): ModuleWithProviders<AppModule> {
    return {
      ngModule: FriscoAppModule,
      providers: providers
    }
  }
}
