import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { DateRange, DashboardHistoryPartitions } from '@enums/enum';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { DashboardHistory } from '../../interfaces/dashboard-history.interface';
import { DashboardService } from '../../services/dashboard.service';
import { DashboardHistoryQueryParams } from '../../interfaces/dashboard-history-queryparams.interface';
import { TranslateService } from '@ngx-translate/core';
import { Depot } from '@interfaces/depot.interface';
import { DepotService } from 'src/app/locations/services/depot.service';
import { tap } from 'rxjs/operators';
import { AngularMultiSelectOptions } from '@shared/constants/multiselect-dropdown.constants';

@Component({
    selector: 'app-dashboard-history',
    templateUrl: './dashboard-history.component.html',
    styleUrls: ['./dashboard-history.component.scss'],
})
export class DashboardHistoryComponent implements OnInit {
    public form: FormGroup;
    public startDate;
    public endDate;
    public dateRange: DateRange = DateRange.LAST_7_DAYS;

    public startDateCtrl: AbstractControl;
    public endDateCtrl: AbstractControl;

    public regularControls = ['startOffset', 'completeOffset', 'startsWithin', 'endsWithin'];

    public data$: Observable<DashboardHistory[]>;
    public depots$: Observable<Depot[]>;

    public dropdownList = [];
    public selectedItems = [];
    public dropdownSettings = AngularMultiSelectOptions;

    public dropdownAggregationList = ['ALL', 'DATE', 'DEPOT', 'DATE,DEPOT', 'SHIFT,DEPOT', 'DEPOT,SHIFT,DATE'];

    public selectedAggregationItems: string = 'DEPOT,SHIFT,DATE';
    public selectedPartitions = [];

    public advandeSearchWindow = false;
    public DashboardHistoryPartitions = DashboardHistoryPartitions;
    public loader = true;

    constructor(private formBuilder: FormBuilder, private readonly dashboardService: DashboardService, private readonly translate: TranslateService, private readonly depotService: DepotService) {}

    public ngOnInit() {
        this.form = this.formBuilder.group({
            depotIds: [null],
            endDate: [null],
            partitions: [null],
            startDate: [null],
        });

        this.startDateCtrl = this.form.controls['startDate'];
        this.endDateCtrl = this.form.controls['endDate'];

        this.depots$ = this.depotService.getDepotsForUser().pipe(tap((depots: Depot[]) => this.initDropdownListValues(depots)));

        this.loadData();
    }

    public changedDateRange(event) {
        this.startDate = moment(event.dateStart).format('YYYY-MM-DD');
        this.endDate = moment(event.dateEnd).format('YYYY-MM-DD');
        this.dateRange = event.dateRange;
        this.startDateCtrl.setValue(this.startDate);
        this.endDateCtrl.setValue(this.endDate);
        this.loadData();
    }

    public loadData() {
        this.loader = true;
        this.data$ = undefined;
        const params = this.form.getRawValue() as DashboardHistoryQueryParams;

        params.depotIds = this.selectedItems.map((a) => a.id);

        params.partitions = (this.selectedAggregationItems !== 'ALL') ? this.selectedAggregationItems : '';

        this.data$ = this.dashboardService.getHistoryPunctuality(params).pipe(tap(() => (this.loader = false)));
    }

    public initDropdownListValues(depot: Depot[]): void {
        this.dropdownList = [];

        depot.forEach((d: Depot) => {
            this.dropdownList.push({ id: d.id, itemName: d.code });
        });

        this.selectedItems = this.dropdownList;
    }

    public onItemSelect() {}

    public onSelectAll() {}

    public onDeSelect() {}
}
