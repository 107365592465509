import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PartnerUser } from '../../interfaces/partneruser.interface';
import { ORDER } from '@enums/enum';
import { PageableResponse } from '@entities/pagable-response';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { PartnerUserService } from '../../services/partner-user.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { NavService } from '@services/nav.sevice';
import * as _ from 'lodash';
import { Partner } from '../../entities/partner';

@Component({
  selector: 'app-partner-user',
  templateUrl: './partner-user.component.html',
  styleUrls: ['./partner-user.component.scss']
})
export class PartnerUserComponent implements OnInit {

  public users: PartnerUser[];
  private usersCollection: PartnerUser[];

  public partner: Partner;

  public loader: boolean = true;

  protected page: number = 0;
  protected size = 10;
  protected order: ORDER = ORDER.DESC;
  protected sortBy: string = 'createdAt';
  protected pagable: PageableResponse<PartnerUser> = new PageableResponse<PartnerUser>(null);
  public pageInfo;
  public modifiedRow: string;
  public rowClass;

  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  public columns = [
    { prop: 'firstName', name: this.translateService.instant('Firstname'), flexGrow: 2 },
    { prop: 'lastName', name: this.translateService.instant('Lastname'), flexGrow: 2 },
    { prop: 'email', name: this.translateService.instant('Email address'), flexGrow: 4 },
    { prop: 'documentNumber', name: this.translateService.instant('Document number'), flexGrow: 2 },
    { prop: 'canManageUsers', name: this.translateService.instant('Can manage users'), flexGrow: 2 },
    { prop: 'options', name: this.translateService.instant('Options'), flexGrow: 1 },
  ];


  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  constructor(
    private partnerUserService: PartnerUserService,
    private navigationRoute: ActivatedRoute,
    private translateService: TranslateService,
    private modalService: BsModalService,
    private toastrProviderService: ToastrProviderService,
    private navService: NavService
  ) { }

  public ngOnInit() {
    this.pagable = (this.navigationRoute.snapshot.data as {users: any}).users;
    this.users = this.usersCollection = this.pagable.content as PartnerUser[];
    this.modifiedRow = this.navigationRoute.snapshot.params.modifiedRow;
    this.partner = (this.navigationRoute.snapshot.data as {partner: any}).partner;


    this.rowClass = row => {
      return { 'row-modified': row.id === this.modifiedRow };
    };
    this.loader = false;
  }

  public setPage(pageInfo?) {
    this.page = pageInfo.offset;
    this.pageInfo = pageInfo;
  }

  public isOptionsColumn(status: string): boolean {
    return status === 'options';
  }

  public isColumnToTranslate(status: string): boolean {
    return status === 'type' || status === 'status';
  }

  public isColumnCanManageUsers(status: string): boolean {
    return status === 'canManageUsers';
  }

  public goToDetails(id: string): void {
    this.navService.goToPage(`/partner/${this.partner.id}/users/edit/${id}`);
  }

  public createPartnerUser(): void {
    this.navService.goToPage(`/partner/${this.partner.id}/users/create`);
  }

  public applyFiltering(event) {
    const filterPhrase = event.target.value.toLowerCase();
    const temp = _.filter(this.usersCollection, (user: PartnerUser) => {
      const searchIn: string = `${user.firstName} ${user.lastName} ${user.email}`;
      return searchIn.toLowerCase().indexOf(filterPhrase) !== -1 || !filterPhrase;
    });

    this.users = temp;
  }

  public fetchPartners(): void {
    this.loader = true;
    this.partnerUserService.getAllPartnerUsers(this.partner.id).subscribe(
      (users: any) => {
        this.pagable = users;
        this.users = this.usersCollection = this.pagable.content as PartnerUser[];
        this.loader = false;
      }, 
      () => {
        this.loader = false;
      })
  }

  public removeUser(id: string): void {
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.partnerUserService.delete(id);
  }

  public confirm(): void {
    this.confirmObservable.subscribe((test) => {
      this.modalRef.hide();
      this.pageInfo = null;
      this.fetchPartners();      
      this.toastrProviderService.showSuccess(this.translateService.instant('The user has been removed!'));
      this.confirmObservable = null;
    });
  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }

}
