<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="grid"></i-feather> {{'Region for partner' | translate}}: {{partner.name}}
            </h4>
        </div>

        <div toolbar>
            <button id="region-btn-create" class="btn btn-sm btn-uppercase btn-white btn-region-create"
                (click)="addRegion()">
                <i-feather [style.height.px]="23" [style.width.px]="13" class="mg-r-10" name="plus"></i-feather> {{ 'Add region' | translate }}
            </button>
            <div id="region-btn-search" class="filter-container">
                <div class="phrase-filter">
                    <input type='text' class="list-filter form-control" placeholder="{{'type to search' | translate}}" (keyup)='applyFiltering($event)'/>
                </div>
            </div>
        </div>
        <hr />
    </div>
    
    <div content>
        <div *ngIf="(list$ | async) as list">
            <ngx-datatable 
                id="regions-table" 
                class="bootstrap" 
                [rows]="regions" 
                [columns]="columns" 
                [columnMode]="'flex'"
                [headerHeight]="50" 
                [footerHeight]="50" 
                [messages]="tableMessages" 
                [rowHeight]="'auto'" 
                [externalPaging]="true"
                [count]="list.totalElements"
                [offset]="queryParams.page"
                [rowClass]="rowClass"
                [limit]="queryParams.size"
                [scrollbarH]="scrollBarHorizontal"
                (sort)="onSort($event)"
                (page)="setPage($event)">
                <ng-container *ngFor="let col of columns">
                    <ngx-datatable-column [flexGrow]="col.flexGrow" [prop]="col.prop">
                        <ng-template let-column="column" ngx-datatable-header-template class="header" let-sort="sortFn">
                            <b (click)="sort()">{{ col.name | translate }}</b>
                        </ng-template>
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>

                            <div [ngSwitch]="col.prop">
                                <div *ngSwitchCase="'options'">
                                    <div class="dropdown" dropdown>
                                        <button
                                            appCheckFeaturesConfig [featureName]="[Permissions.Partner_edit]"
                                            class="btn btn-outline-secondary btn-xs dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton">
                                            {{ 'Select' | translate }}
                                        </button>
    
                                        <div class="dropdown-menu dropdown-menu-right div-main-actions">
    
                                            <a 
                                                id="region-btn-edit"
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-region-edit" 
                                                (click)="goToDetails(row.id)">
                                                    {{'Edit' | translate }}
                                            </a>
    
                                            <!-- <a 
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                                (click)="goToDrivers(row.id)">
                                                    {{'Drivers' | translate }}
                                            </a> -->
    
                                            <a 
                                                id="region-btn-apms"
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-assign-apms" 
                                                (click)="goToApms(row.id)">
                                                    {{'Apms' | translate }}
                                            </a>
    
                                            <a 
                                                id="region-btn-drivers"
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-region-drivers" 
                                                (click)="goToCouriers(row.id)">
                                                    {{'Drivers' | translate }}
                                            </a>
    
                                            <a 
                                                id="region-btn-configurations"
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-region-configuration" 
                                                (click)="goToConfig(row.id)">
                                                    {{'Show configuration' | translate }}
                                            </a>
    
                                            <a 
                                                id="region-btn-timeslots"
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-region-timeslots" 
                                                (click)="goToTimeslots(row.id)">
                                                    {{'Timeslots' | translate }}
                                            </a>
    
                                            <a 
                                                id="region-btn-remove"
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-region-remove" 
                                                (click)="removeRegion(row.id)">
                                                    {{'Remove' | translate }}
                                            </a>
    
                                        </div>
                                    </div>
                                </div>
                                <div id="partner-table-col-status" *ngSwitchCase="'status'">
                                    <ng-container [ngSwitch]="value">
                                        <span *ngSwitchCase="'ACTIVE'" class="badge badge-success">{{ 'RegionStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="'ARCHIVED'" class="badge badge-danger">{{ 'RegionStatus.'+value | translate }}</span>
                                        <span *ngSwitchDefault class="badge badge-info">{{ 'RegionStatus.'+value | translate }}</span>
                                    </ng-container>
                                </div>
                                <div id="partner-table-col-address" *ngSwitchCase="'address'">{{value | addressLabelPipe}}</div>
                                <div id="partner-table-col-{{col.prop}}" *ngSwitchDefault>{{value}}</div>
                            </div>
                      
                            
                        </ng-template>
                    </ngx-datatable-column>
                </ng-container>
            </ngx-datatable>
        </div>

        <div *ngIf="loader" class="load-spinner text-center">
            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}
        </div>

    </div>

</layout-default>

<ng-template #confirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p>{{'Do you confirm removal of region' | translate }}?</p>
        <button type="button" class="btn btn-brand-01" (click)="confirm()">
            {{'Yes, remove' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>

<!-- {{'RegionStatus.ACTIVE' | translate}} -->
<!-- {{'RegionStatus.ARCHIVED' | translate}} -->