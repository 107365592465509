import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { QueryParams } from '@interfaces/query-params.interface';
import { environment } from '@environment';
import { SmsTemplate } from '../../intefaces/sms-templates.interface';
import { SmsTemplatesService } from '../../services/sms-templates.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Permissions } from '@enums/permissions';
import {filter} from 'lodash';

@Component({
  selector: 'app-sms-templates',
  templateUrl: './sms-templates.component.html',
  styleUrls: ['./sms-templates.component.scss']
})
export class SmsTemplatesComponent implements OnInit, OnDestroy {

  public smsTemplates: SmsTemplate[];
  public smsTemplatesCollection: SmsTemplate[];


  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  protected queryParams: QueryParams = Object.assign({}, environment.pagination);
  public modifiedRow: string;
  public rowClass;
  public loader: boolean = true;
  public Permissions = Permissions;

  public destroy$ = new Subject<boolean>();

  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  public columns = [
    { prop: 'id', name: this.translateService.instant('Lp'), flexGrow: 1, sortable: true },
    { prop: 'title', name: this.translateService.instant('Title'), flexGrow: 6, sortable: true },
    { prop: 'parameters', name: this.translateService.instant('Parameters'), flexGrow: 3, sortable: true },
    { prop: 'type', name: this.translateService.instant('Type'), flexGrow: 2, sortable: true },
    { prop: 'options', name: this.translateService.instant('Options'), flexGrow: 2, sortable: false},
  ];

  constructor(
    private readonly navigationRoute: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly modalService: BsModalService,
    private toastrProviderService: ToastrProviderService,
    private readonly smsTemplatesService: SmsTemplatesService
  ) { }

  public ngOnInit() {
    this.smsTemplates = this.smsTemplatesCollection = (this.navigationRoute.snapshot.data as {smsTemplates: any}).smsTemplates;
    this.modifiedRow = this.navigationRoute.snapshot.params.modifiedRow;

    this.rowClass = row => {
      return { 'row-modified': row.id.toString() === this.modifiedRow };
    };

    this.loader = false;
  }

  public applyFiltering(event) {
    const filterPhrase = event.target.value.toLowerCase();
    const temp = filter(this.smsTemplatesCollection, (row: SmsTemplate) => {
      const searchIn: string = row.title;
      return searchIn.toLowerCase().indexOf(filterPhrase) !== -1 || !filterPhrase;
    });

    this.smsTemplates = temp;
  }

  public remove(id: string): void {
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.smsTemplatesService.delete(id);
  }

  public confirm(): void {
    this.confirmObservable
      .pipe(takeUntil(this.destroy$))
      .subscribe((test) => {
        this.modalRef.hide();
        this.queryParams = environment.pagination;
        this.smsTemplatesService.findAll()
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (templates: SmsTemplate[]) => {
              this.smsTemplates = this.smsTemplatesCollection = templates;
            }
          );  
        this.toastrProviderService.showSuccess(this.translateService.instant('The messege template has been removed!'));
        this.confirmObservable = null;
      });
  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();  
  }
}
