import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '@services/utils.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class D2dService {

  protected static CHECK_AVAILABILITY: string = `${environment.api.url}${environment.api.prefix}/dtddelivery/v1/available`;  
  protected static RESERVE: string = `${environment.api.url}${environment.api.prefix}/dtddelivery/v1/reserve`;  
  protected static CONFIRM: string = `${environment.api.url}${environment.api.prefix}/dtddelivery/v1/confirm`;  
  protected static CANCEL: string = `${environment.api.url}${environment.api.prefix}/dtddelivery/v1/cancel`;  
  protected static EXTEND: string = `${environment.api.url}${environment.api.prefix}/dtddelivery/v1/extend`;  
  protected static GET_DELIVERY: string = `${environment.api.url}${environment.api.prefix}/dtddelivery/v1/id`;  
//dtddelivery/v1/id/delivery:abcd:6a6e9509-1c0b-46d5-9ffa-d4c6e2b80698/partner/p1
  constructor(
    protected _http: HttpClient, 
    protected utilsService: UtilsService
  ) {}

  public checkAvailability(data: Object): Observable<any> {
    const endpoint = this.utilsService.interpolate(`${D2dService.CHECK_AVAILABILITY}`, {});
    return this._http.post<any>(endpoint, data);
  }

  public reserve(data: Object): Observable<any> {
    const endpoint = this.utilsService.interpolate(`${D2dService.RESERVE}`, {});
    return this._http.post<any>(endpoint, data, this.utilsService.httpHeadersPlainText());
  }

  public confirm(partnerId: string, id: string): Observable<any> {
    const endpoint = this.utilsService.interpolate(`${D2dService.CONFIRM}/${partnerId}/${id}`, {});
    return this._http.post<any>(endpoint, this.utilsService.httpHeadersPlainText());
  }

  public cancel(partnerId: string, id: string): Observable<any> {
    const endpoint = this.utilsService.interpolate(`${D2dService.CANCEL}/${partnerId}/${id}`, {});
    return this._http.delete<any>(endpoint, this.utilsService.httpHeadersPlainText());
  }

  public extend(partnerId: string, id: string): Observable<any> {
    const endpoint = this.utilsService.interpolate(`${D2dService.EXTEND}/${partnerId}/${id}`, {});
    return this._http.post<any>(endpoint, this.utilsService.httpHeaders());
  }

  public getDelivery(partnerId: string, id: string): Observable<any> {
    const endpoint = this.utilsService.interpolate(`${D2dService.GET_DELIVERY}/${id}/partner/${partnerId}`, {});
    return this._http.get<any>(endpoint, this.utilsService.httpHeaders());
  }

    // public savePartnerDC(partnerDc: PartnerDC, partnerId: string): Observable<PartnerDC> {
    //   const endpoint = this.utilsService.interpolate(`${DcService.BASE_API}/${partnerId}`, {});
    //   return this._http.post<PartnerDC>(endpoint, partnerDc);
    // }

    // public updatePartnerDC(partnerDc: PartnerDC, partnerId: string): Observable<PartnerDC> {
    //   const endpoint = this.utilsService.interpolate(`${DcService.BASE_API}/${partnerId}`, {});
    //   return this._http.put<PartnerDC>(endpoint, partnerDc);
    // }

    // public getAllDcForpartner(partnerId: string): Observable<PageableResponse<PartnerDC[]>> {
    //     const endpoint = this.utilsService.interpolate(`${DcService.BASE_API}/list?partnerId=${partnerId}&sort=id&size=1000`, {});
    //     return this._http.get<PageableResponse<PartnerDC[]>>(endpoint);
    // }

    
}
