import { Injectable } from '@angular/core';
import { CrudService } from '@services/crud.service';
import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '@services/utils.service';
import { Subscription } from '../entities/subscription';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService extends CrudService<Subscription, number>{
  
  protected static BASE_API: string = `${environment.api.url}${environment.api.prefix}/subscription/v1`;  
 
  constructor(
    protected _http: HttpClient, 
    protected utilsService: UtilsService) 
    {
      super(_http, SubscriptionService.BASE_API, utilsService);
    }
}
