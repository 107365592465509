import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import enLocale from 'date-fns/locale/en-GB';
import plLocale from 'date-fns/locale/pl';
import * as moment from 'moment';

import { DateRange, DateRangeOptions, mapOptions, ShiftType } from '@enums/enum';
import { DictionaryService } from '@services/dictionary.service';
import { ActivatedRoute } from '@angular/router';
import { datePickerOptions } from '@shared/constants/datepicker-options.constants';
import { bsConfig } from '@shared/constants/bs-config.constants';
import { AngularMultiSelectOptions } from '@shared/constants/multiselect-dropdown.constants';

@Component({
    selector: 'date-range-picker',
    template: `
        <div class="picker" *ngIf="dropdownList.length">
            <select id="data-range-select-range" class="custom-select" (change)="change()" [(ngModel)]="selectedRange">
                <option *ngFor="let range of dateRangeOptions" [value]="range">{{ range | translate }}</option>
            </select>

            <div>
                <label>{{ 'From' | translate }}:</label>
            </div>
            <input type="text" *ngIf="locale" [bsConfig]="bsConfig" bsDatepicker class="custom-select datepicker-input datepicker-from" [(ngModel)]="startDate" />
            <div>
                <label>{{ 'To' | translate }}:</label>
            </div>
            <input type="text" *ngIf="locale" [bsConfig]="bsConfig" bsDatepicker class="custom-select datepicker-input datepicker-to" [(ngModel)]="endDate" />

            <ng-container *ngIf="extendedOption">
                <angular2-multiselect 
                    id="data-range-select-days-of-week"
                    class="form-control"
                    [style.width.%]="49"
                    [data]="dropdownList"
                    [(ngModel)]="selectedItems"
                    [settings]="dropdownSettings"
                    (onSelect)="onItemSelect()"
                    (onSelectAll)="onSelectAll()"
                    (onDeSelect)="onDeSelect()">
                </angular2-multiselect>

                <div>
                    <label>{{ 'Shift' | translate }}:</label>
                </div>
                <select id="data-range-select-shift" class="custom-select" (change)="change()" [(ngModel)]="selectedShift">
                    <option *ngFor="let shift of ShiftTypes" [value]="shift">{{ shift | translate }}</option>
                    <option [value]="spreadShiftTypes">{{ 'All Shifts' | translate }}</option>
                </select>

            </ng-container>
        </div>
    `,
    styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {

    @Input() public defaultDateRange = undefined;

    public bsConfig = bsConfig;

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private translate: TranslateService, 
        private dictionaryService: DictionaryService) {
        this.locale = this.translate.currentLang === 'en' ? enLocale : plLocale;
        this.datePickerOptions.locale = this.locale;
    }

    public dropdownList = [];
    public selectedItems = [];
    public dropdownSettings = AngularMultiSelectOptions;
    public locale = null;

    get dateRangeOptions() {
        return DateRangeOptions;
    }

    get ShiftTypes() {
        return [...this.dictionaryService.shiftTypesDictionary];
    }


    private now = moment().toString();

    public _startDate = moment(this.now).toDate();
    public _endDate: any = moment(this.now).add(2, 'week').toDate();
    public _selectedShift = null;
    public _selectedMapType = mapOptions.HEATMAP;

    set startDate(date) {
        this._startDate = moment(date).toDate();
        this.selectedRange = DateRange.USER_VALUE;
        this.change();
    }

    get startDate() {
        return this._startDate;
    }

    set endDate(date) {
        this._endDate = moment(date).toDate();
        this.selectedRange = DateRange.USER_VALUE;
        this.change();
    }

    get endDate() {
        return this._endDate;
    }

    set selectedShift(shift) {
        this._selectedShift = shift;
        this.changeShiftType();
    }

    get selectedShift() {
        return this._selectedShift ? this._selectedShift : this.spreadShiftTypes;
    }

    get spreadShiftTypes() {
        return this.ShiftTypes.join(',');
    }

    public datePickerOptions: any = datePickerOptions;

    public selectedRange = DateRange.TWO_WEEKS;

    public selectedDateRange = {
        dateStart: moment(this.now)
            .toString(),
        dateEnd: moment(this.now)
            .add(2, 'week')
            .toString(),
        dateRange: DateRange.TWO_WEEKS
    };

    @Input() public extendedOption;
    @Output() public changedDateRange: EventEmitter<any> = new EventEmitter();
    @Output() public changeDow: EventEmitter<any> = new EventEmitter();
    @Output() public mapType: EventEmitter<any> = new EventEmitter();
    @Output() public changedShiftType: EventEmitter<any> = new EventEmitter();

    public ngOnInit() {

        if (this.defaultDateRange) {
            setTimeout(() => {
                this.selectedRange = this.defaultDateRange;
                this.change();
            })
           
        } else {
            setTimeout(() => {
                this.changedDateRange.emit(this.selectedDateRange);
            })
        }

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.locale = event.lang === 'en' ? enLocale : plLocale;
            this.datePickerOptions.locale = this.locale;
            this.initDropdownListValues();
        });

        this.initDropdownListValues();
    }

    public initDropdownListValues(): void {
        this.dropdownList = [
            { id: 'MONDAY', itemName: this.translate.instant('Monday') },
            { id: 'TUESDAY', itemName: this.translate.instant('Tuesday') },
            { id: 'WEDNESDAY', itemName: this.translate.instant('Wednesday') },
            { id: 'THURSDAY', itemName: this.translate.instant('Thursday') },
            { id: 'FRIDAY', itemName: this.translate.instant('Friday') },
            { id: 'SATURDAY', itemName: this.translate.instant('Saturday') },
            { id: 'SUNDAY', itemName: this.translate.instant('Sunday') }
        ];

        this.selectedItems = this.dropdownList;
    }

    public onItemSelect() {
        this.changeDowEvent();
    }

    public onSelectAll() {
        this.changeDowEvent();
    }

    public onDeSelect() {
        this.changeDowEvent();
    }

    public changeDowEvent(): void {
        const dow = [];
        this.selectedItems.map(d => dow.push(d.id));
        this.changeDow.emit(dow);
    }

    public changeShiftType() {
        this.changedShiftType.emit(this.selectedShift);
    }

    public change() {
        this.selectedDateRange.dateRange = this.selectedRange;
        let startDate;
        let endDate;

        switch (this.selectedRange) {
            case DateRange.YESTERDAY:
                startDate = moment(this.now)
                    .add(-1, 'days')
                    .toString();
                break;
            case DateRange.LAST_7_DAYS:
                startDate = moment(this.now)
                    .add(-1, 'week')
                    .toString();
                this.endDate = moment(this.now)
                    .toString();
                break;
            case DateRange.TWO_WEEKS:
                startDate = moment(this.now)
                    .toString();
                this.endDate = moment(this.now)
                    .add(2, 'week')
                    .toString();
                break;
            case DateRange.LAST_30_DAYS:
                startDate = moment(this.now)
                    .add(-1, 'month')
                    .toString();
                this.endDate = moment(this.now)
                    .toString();
                break;
            case DateRange.LAST_90_DAYS:
                startDate = moment(this.now)
                    .add(-90, 'days')
                    .toString();
                this.endDate = moment(this.now)
                    .toString();
                break;
            case DateRange.THIS_MONTH:
                startDate = moment()
                    .startOf('month')
                    .toString();
                this.endDate = moment()
                    .endOf('month')
                    .toString();
                break;
            case DateRange.THIS_WEEK:
                startDate = moment()
                    .startOf('week')
                    .toString();
                this.endDate = moment()
                    .endOf('week')
                    .toString();
                break;
            case DateRange.THIS_QUARTER:
                startDate = moment(this.now)
                    .startOf('quarter')
                    .toString();
                this.endDate = moment(this.now)
                    .endOf('quarter')
                    .toString();
                break;
            default:
                startDate = this._startDate.toString();
                break;
        }

        if (this.activatedRoute.snapshot.queryParams.startDate) {
           startDate = moment(this.now).toDate();
        }

        if (this.activatedRoute.snapshot.queryParams.endDate) {
            endDate = moment(this.activatedRoute.snapshot.params.endDate).toString();
        }

        this._startDate = moment(startDate).toDate();
        this.selectedDateRange.dateStart = startDate;
        this.selectedDateRange.dateEnd = this.endDate;
        this.changedDateRange.emit(this.selectedDateRange);
    }
}
