import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'boxId'
})
export class BoxIdPipe implements PipeTransform {
    public transform(base: string): string {

        if (base.split(':').length === 3) {
            const [id, type, no] = base.split(':');
            return `${id.slice(id.length - 3, id.length)}:${type.slice(0, 1)}:${no}`;
        } else if (base.split(':').length === 4) {
            const [partnerCode, id, type, no] = base.split(':');
            return `${id.slice(id.length - 3, id.length)}:${type.slice(0, 1)}:${no}`;
        }
        const firstLetters = base.toString().substring(0, 2);
        return (firstLetters === '90') ? base.toString().substring(2, base.length) : base;
    }
}
