<layout-default>
    <div header>
        <div toolbar>
            <h4 *ngIf="mode === 'CREATE'">
                <i-feather name="database"></i-feather> {{'Create apm delivery' | translate}}
            </h4>
            <h4 *ngIf="mode === 'UPDATE'">
                <i-feather name="database"></i-feather> {{'Edit apm delivery' | translate}}
            </h4>
        </div>

        <hr />
    </div>
    
    <div content>
        <div *ngIf="!loader" class="content bg-white">

            <div class="card-content col-8 offset-2">

                <form [formGroup]="form">

                    <div class="form-group row">
                        <label class="col-2" for="apmId">{{'Apm Id' | translate}}:</label>
                        <div class="col-10">
                            <select class="custom-select" formControlName="apmId">
                                <option *ngFor="let a of apms" [value]="a.id">{{a.name}} - {{a.code}}</option>
                            </select>

                            <div *ngIf="form.get('apmId').hasError('required') && form.get('apmId').touched" class="error-color">
                                {{ "Selecting apm is required" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-2" for="partnerId">{{'Partner' | translate}}:</label>
                        <div class="col-10">
                            <select class="custom-select" formControlName="partnerId">
                                <option *ngFor="let p of partners" [value]="p.id">{{p.name}} - {{p.code}}</option>
                            </select>

                            <div *ngIf="form.get('partnerId').hasError('required') && form.get('partnerId').touched" class="error-color">
                                {{ "Selecting partner is required" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-2" for="subscriptionId">{{'Subscription' | translate}}:</label>
                        <div class="col-10">
                            <select class="custom-select" formControlName="subscriptionId">
                                <option *ngFor="let s of subscriptions" [value]="s.id">{{s.name}} - {{s.code}}</option>
                            </select>

                            <div *ngIf="form.get('subscriptionId').hasError('required') && form.get('subscriptionId').touched" class="error-color">
                                {{ "Selecting partner is required" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div *ngFor="let item of regularFormItems()" class="form-group row">
                        <label class="col-2" for="code">{{item | translate}}:</label>
                        <div class="col-10">

                            <input *ngIf="item === 'customerPhone'" prefix="+48 " mask="000-000-000" [formControlName]="item" class="form-control" />
                            <input *ngIf="item !== 'customerPhone'" [formControlName]="item" class="form-control" />

                            <div *ngIf="form.get(item).hasError('required') && form.get(item).touched" class="error-color">
                                {{ "The field is required" | translate }}!
                            </div>
                            <div *ngIf="form.get(item).hasError('pattern') && form.get(item).touched" class="error-color">
                                {{ "Above email address is incorrect" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-2" for="onDeliveryAmount">{{'On delivery amount'.toUpperCase() | translate}}:</label>
                        <div class="col-10">
                            <input [formControlName]="'onDeliveryAmount'" class="form-control" currencyMask />
                            <div *ngIf="form.get('onDeliveryAmount').hasError('required') && form.get('onDeliveryAmount').touched" class="error-color">
                                {{ 'On delivery amount field is required' | translate }}!
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-2" for="code">{{'Lockers' | translate }}:</label>
                        <div class="col-10">
                            <div class="row">
                                <div *ngFor="let item of ['lcount', 'mcount', 'ncount', 'ocount']; let i = index;" class="item col-3">
                                    <p class="d-block tx-sans tx-10 tx-uppercase tx-color-03 tx-spacing-1 text-left mg-b-0 pd-b-0">
                                        <ng-container [ngSwitch]="item">
                                            <span *ngSwitchCase="'lcount'">
                                                {{'CompartmentType.Cooler' | translate}}
                                            </span>
                                            <span *ngSwitchCase="'mcount'">
                                                {{'CompartmentType.Freezer' | translate}}
                                            </span>
                                            <span *ngSwitchCase="'ncount'">
                                                {{'CompartmentType.Ambient' | translate}}
                                            </span>
                                            <span *ngSwitchCase="'ocount'">
                                                {{'CompartmentType.Other' | translate}}
                                            </span>
                                        </ng-container>:</p>
                                    <input [formControlName]="item" type="number" class="form-control" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-2" for="status">{{'Status' | translate}}:</label>
                        <div class="col-10">
                            <select class="custom-select" formControlName="status">
                                <option *ngFor="let s of apmDeliveryStatus" [value]="s">{{s | translate}}</option>
                            </select>

                            <div *ngIf="form.get('status').hasError('required') && form.get('status').touched" class="error-color">
                                {{ "Selecting status is required" | translate }}!
                            </div>
                        </div>
                    </div>


                    <div class="form-group row">
                        <label class="col-2" for="logisticType">{{'Logistic Type' | translate}}:</label>
                        <div class="col-10">
                            <select class="custom-select" formControlName="logisticType">
                                <option *ngFor="let type of apmDeliveryLogisticType" [value]="type">{{type | translate}}</option>
                            </select>

                            <div *ngIf="form.get('logisticType').hasError('required') && form.get('logisticType').touched" class="error-color">
                                {{ "Selecting logistic type is required" | translate }}!
                            </div>
                        </div>
                    </div>

                    <ng-container *ngFor="let name of ['courierWindow', 'purgeWindow', 'reservationWindow']">
                        <div class="row">
                            <p class="col-10 offset-2 d-block text-center tx-sans tx-10 tx-uppercase tx-color-03 tx-spacing-1 text-left mg-b-0 pd-b-20">{{ name | translate }}:</p>
                        </div>

                        <ng-container [formGroupName]="name">
                            <div *ngFor="let i of ['from', 'to']" class="form-group row">
                                <label class="col-2" [for]="i">{{ i | translate }}:</label>
                                <div class="col-10">
                                    <ng-datepicker class="datepicker-input form-group" [formControlName]="i" [id]="i" [headless]="disabled" [options]="options"> </ng-datepicker>
                                    <input *ngIf="disabled" type="text" class="form-control" [disabled]="disabled" [value]="form.get(i).value | date: 'dd.MM.yyyy'" />
                                    <div *ngIf="form.get(name).get(i).hasError('required') && form.get(name).get(i).touched" class="error-color">{{ 'Joined date is required' | translate }}!</div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>


                    <!-- <ng-container formGroupName="address">
                        <label class="d-block col-10 offset-2 tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-b-20">{{'Address' | translate}}</label>

                        <div *ngFor="let item of addressKeys" class="form-group row">
                            <label class="col-2" for="code">{{item.toUpperCase() | translate}}:</label>
                            <div class="col-10">
                                <input [formControlName]="item" class="form-control" />
                                <div *ngIf="form.get('address').get(item)?.hasError('required') && form.get('address').get(item)?.touched" class="error-color">
                                    {{ item+" is required" | translate }}!
                                </div>
                            </div>
                        </div>

                    </ng-container>

                    <ng-container formGroupName="locationAdditional">
                        <label class="d-block col-10 offset-2 tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-b-20">{{'Address information' | translate}}</label>

                        <div *ngFor="let item of additionalKeys" class="form-group row">
                            <ng-container *ngIf="item != 'lift' && item != 'floor'">
                                <label class="col-2" [for]="item">{{item.toUpperCase() | translate}}:</label>
                                <div class="col-10">
                                    <input [formControlName]="item" class="form-control" />
                                    <div *ngIf="form.get('locationAdditional').get(item)?.hasError('required') && form.get('locationAdditional').get(item)?.touched" class="error-color">
                                        {{ item+" is required" | translate }}!
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div class="form-group row">
                            <label class="col-2" for="socialNumberType">{{'Lift' | translate}}:</label>
                            <div class="col-10">
                                <select class="custom-select" formControlName="lift">
                                    <option [value]="true">{{'Yes' | translate}}</option>
                                    <option [value]="false">{{'No' | translate}}</option>
                                </select>

                                <div *ngIf="form.get('locationAdditional').get('lift')?.hasError('required') && form.get('locationAdditional').get('lift')?.touched" class="error-color">
                                        {{ "Lift field is required" | translate }}!
                                </div>
                            </div>
                        </div>   
                        <div class="form-group row">
                            <label class="col-2" for="floor">{{'Floor'.toUpperCase() | translate}}:</label>
                            <div class="col-10">
                                <input formControlName="floor" type="number" class="form-control" />
                                <div *ngIf="form.get('locationAdditional').get('floor')?.hasError('required') && form.get('locationAdditional').get('floor')?.touched" class="error-color">
                                        {{ item+" is required" | translate }}!
                                </div>
                            </div>
                        </div>

                    </ng-container> -->

                    <div class="form-group row justify-content-end">
                        <div class="col-2">
                            <button (click)="onSubmit()" [disabled]="!form.valid || submitted"
                                class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01 w-100">

                                <span *ngIf="!submitted">{{ "Save" | translate }}</span>

                                <ng-container *ngIf="submitted">
                                    <div class="load-spinner text-center">
                                        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                    </div>
                                </ng-container>

                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</layout-default>

<!-- {{'apmId' | translate }} -->
<!-- {{'customerEmail' | translate }} -->
<!-- {{'customerPhone' | translate }} -->
<!-- {{'lcount' | translate }} -->
<!-- {{'logisticType' | translate }} -->
<!-- {{'mcount' | translate }} -->
<!-- {{'ncount' | translate }} -->
<!-- {{'onDeliveryAmount' | translate }} -->
<!-- {{'partnerId' | translate }} -->
<!-- {{'reservationReference' | translate }} -->
<!-- {{'reservationNumber' | translate }} -->
<!-- {{'status' | translate }} -->
<!-- {{'subscriptionId' | translate }} -->

<!-- {{'NEW' | translate }} -->
<!-- {{'STORED' | translate }} -->
<!-- {{'REMINDED' | translate }} -->
<!-- {{'EXPIRED' | translate }} -->
<!-- {{'NOT_USED' | translate }} -->
<!-- {{'USED' | translate }} -->
<!-- {{'RETURNED' | translate }} -->
