import { PipeTransform, Pipe } from '@angular/core';
import { TimePeriod } from '@analytics/enums/time-period.enum';
import { ChartModel } from '@analytics/interfaces/chart.interface';
import { CustomerType } from '@enums/enum';

@Pipe({
  name: 'findByTimePeriod',
  pure: true
})
export class FindByTimePeriodPipe implements PipeTransform {
  public transform(charts: ChartModel[], type: TimePeriod, segment?: CustomerType): any {
    return (!segment) 
      ? [charts.find((o: ChartModel) => o.name === type)] 
      : [charts.find((o: ChartModel) => o.name === type && o.customerSegment === segment)];
  }
}
