<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="sliders"></i-feather> {{'List of Rlms not assigned to any service regions' | translate}}
            </h4>
        </div>

        <div toolbar>
            <div class="filter-container">
                <div class="phrase-filter">
                    <input type='text' class="list-filter form-control" placeholder="{{'type to search' | translate}}" (keyup)='applyFiltering($event)'/>
                </div>
            </div>
        </div>
        <hr />
    </div>
    
    <div content>
        <div *ngIf="(list$ | async) as list">

            <ngx-datatable 
                id="spm" 
                class="bootstrap" 
                [rows]="rows" 
                [columns]="columns" 
                [columnMode]="'flex'"
                [headerHeight]="50" 
                [footerHeight]="50" 
                [messages]="tableMessages" 
                [rowHeight]="'auto'" 
                [externalPaging]="true"
                [count]="list.totalElements"
                [offset]="queryParams.page"
                [rowClass]="rowClass"
                [limit]="queryParams.size"
                [scrollbarH]="scrollBarHorizontal"
                (sort)="onSort($event)"
                (page)="setPage($event)">
                <ng-container *ngFor="let col of columns">
                    <ngx-datatable-column [name]="col.prop" [flexGrow]="col.flexGrow" [prop]="col.prop" [sortable]="col.sortable">
                        <ng-template let-column="column" ngx-datatable-header-template class="header" let-sort="sortFn">
                            <b (click)="sort()">{{ col.name | translate }}</b>
                        </ng-template>
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>

                            <div [ngSwitch]="col.prop">
                                <div *ngSwitchCase="'address'">
                                        <span>{{row.address | addressLabelPipe}}</span>
                                    </div>
                                <div *ngSwitchCase="'lcount'">
                                    <span 
                                        [containerClass]="'pretty-tooltip'" 
                                        placement="right" 
                                        [tooltip]="row | rlmCapacityLabelPipe">{{row | rlmCapacityLabelShortPipe}}</span>
                                </div>
                                <div *ngSwitchCase="'status'">
                                    <ng-container [ngSwitch]="value">
                                        <span *ngSwitchCase="'NOT_AVAILABLE'" class="badge badge-danger">{{ 'RlmStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="'AVAILABLE'" class="badge badge-success">{{ 'RlmStatus.'+value | translate }}</span>
                                        <span *ngSwitchDefault class="badge badge-info">{{ 'RlmStatus.'+value | translate }}</span>
                                    </ng-container>
                                </div>
                                <div *ngSwitchCase="'options'">
                                    <div class="dropdown" dropdown>
                                        <button
                                            class="btn btn-outline-secondary btn-xs dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton">
                                            {{ 'Select' | translate }}
                                        </button>

                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a 
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                                (click)="assignApmToRegionConfirmation(row)">
                                                    {{'Assign Apm to region' | translate }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div *ngSwitchDefault>{{ value | translate }}</div>
                            </div>

                        </ng-template>
                    </ngx-datatable-column>
                </ng-container>
            </ngx-datatable>
        </div>

        <div *ngIf="loader" class="load-spinner text-center">
            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}
        </div>
    </div>

</layout-default>


<ng-template #confirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p>
            <strong>{{ 'Do you confirm to assign Apm to selected region' | translate }}?</strong>
        </p>

        <div class="d-flex justify-content-center align-items-center">
            <div class="mg-r-20">{{ 'Please select a region' | translate }}:</div>
            <select class="form-control" [(ngModel)]="selectedRegion">
                <option *ngFor="let region of servicePartner.regionList" [value]="region.id">{{ region.name }} - {{region.code}}</option>
            </select>
        </div>
       
        <br />
        <button type="button" [disabled]="!selectedRegion" class="btn btn-brand-01" (click)="assignApmToRegion(selectedRegion)">
            {{'Yes, assign' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>