import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DateRange, DriverNationalityOptions } from '@enums/enum';
import { DriverReport } from '../../entities/driver-report';
import { ChartModel } from '../../interfaces/chart.interface';
import { AnalyticsService } from '../../services/analytics.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import * as Papa from 'papaparse';

@Component({
    selector: 'app-driver-punctuality',
    templateUrl: './driver-punctuality.component.html',
    styleUrls: ['./driver-punctuality.component.scss']
})
export class DriverPunctualityComponent {
    public loader: boolean = true;
    public loaderDeliveries: boolean = true;

    public chartData: ChartModel[];
    public chartDataAllDrivers: ChartModel[];
    public chartDataDeliveries: ChartModel[];
    public filteredChartData: ChartModel[];
    public filteredDriverReportData: ChartModel[];
    public chartFilterByDriverData: any;
    public driverName: string;
    public nationality: string;

    public view: any[] = [, 300];
    public showXAxis = true;
    public showYAxis = true;
    public gradient = false;
    public showLegend = false;
    public showXAxisLabel = false;
    public showYAxisLabel = false;

    public startDate;
    public endDate;
    public dateRange: DateRange = DateRange.LAST_30_DAYS;

    public driversPunctualityStats$: Observable<ChartModel[]>;
    public deliveriesPunctualityStats$: Observable<ChartModel[]>;


    get nationalityOptions() {
        return DriverNationalityOptions;
    }

    public colorScheme = {
        domain: ['#32a63b', '#1976d2', 'red', '#3f536e', '#CCCCCC']
    };

    constructor(
        private readonly analyticsService: AnalyticsService, 
        private readonly translateService: TranslateService
    ) {}

    public loadData(dateStart, dateEnd) {

        this.deliveriesPunctualityStats$ =  this.analyticsService.getDeliveriesPunctualityStats(dateStart, dateEnd, 'FOREVER').pipe(
            tap((data) => {
                this.chartDataDeliveries = data;
                this.loaderDeliveries = false
            })
        );

        this.driversPunctualityStats$ =  this.analyticsService.getDriversPunctualityStats(this.startDate, this.endDate).pipe(
            tap((stats) => {
                this.chartDataAllDrivers = stats;
                this.chartData = _.cloneDeep(this.chartDataAllDrivers).slice(0, 20);
                this.filteredChartData = this.chartData;
                this.filteredDriverReportData = _.cloneDeep(this.chartDataAllDrivers).slice(0, 9);
                this.chartFilterByDriverData = _.cloneDeep(this.chartData);
                this.loader = false;
            })
        );
    }

    public changedDateRange(event) {
        this.startDate = moment(event.dateStart).format('YYYY-MM-DD');
        this.endDate = moment(event.dateEnd).format('YYYY-MM-DD');
        this.dateRange = event.dateRange;
        this.loader = true;
        this.loaderDeliveries = true;
        this.loadData(this.startDate, this.endDate);
    }

    public filterByName(): void {
        const chartDataSource: ChartModel[] = _.cloneDeep(this.chartDataAllDrivers);
        this.filteredChartData = chartDataSource.filter((driver: ChartModel) => driver.name.toLocaleLowerCase().includes(this.driverName.toLocaleLowerCase())).slice(0, 20);
        this.filteredDriverReportData = chartDataSource.filter((driver: ChartModel) => driver.name.toLocaleLowerCase().includes(this.driverName.toLocaleLowerCase())).slice(0, 9);
    }

    public filterByNationality(): void {
        const chartDataSource: ChartModel[] = _.cloneDeep(this.chartDataAllDrivers);
        this.filteredChartData = chartDataSource.filter((driver: ChartModel) => driver.nationality.includes(this.nationality)).slice(0, 20);
        this.filteredDriverReportData = chartDataSource.filter((driver: ChartModel) => driver.nationality.includes(this.nationality)).slice(0, 9);
    }

    public print() {
        let data;

        const options = {
            fieldSeparator: ';',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true,
            showTitle: false,
            headers: [
                this.translateService.instant('Driver Name'),
                this.translateService.instant('Nationality'),
                this.translateService.instant('Total delivered'),
                this.translateService.instant('Successful delivered'),
                this.translateService.instant('Successful delivered') + '%',
                this.translateService.instant('Early delivered'),
                this.translateService.instant('Early delivered') + '%',
                this.translateService.instant('Late delivered'),
                this.translateService.instant('Late delivered') + '%',
                this.translateService.instant('Failure delivered')
            ],
            nullToEmptyString: true
        };

        const chartDataSource: ChartModel[] = _.cloneDeep(this.chartDataAllDrivers);
        data = DriverReport.generateDataToExport(chartDataSource);

        try {
            const csv = Papa.unparse(data);
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.setAttribute('href', url);
            link.setAttribute('download', `Drivers_report_${this.startDate}-${this.endDate}.csv`);
            link.click();
        } catch (e) {
            throw e;
        }
    }
}
