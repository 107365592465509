import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { slideFadeinRight } from '@shared/animations/router.animation';
@Component({
    template: `
        <div [@slideFadeinRight]="currentPath">
            <router-outlet #outlet="outlet"></router-outlet>
        </div>
    `,
    animations: [slideFadeinRight],
})
export class ProxyComponent {
    currentPath: string | null = null;

    public onActivate(outlet: RouterOutlet): void {
        this.currentPath = outlet.activatedRoute?.routeConfig?.path || null;
    }
}
