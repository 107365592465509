import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DriversMode, ShiftType } from '@enums/enum';
import { DriverPlanningMode } from '@enums/enum';
import { UtilsService } from '@services/utils.service';

import { AvailabilityRange } from '../entities/availability-range';
import { Driver } from '../entities/driver';
import { environment } from '@environment';
import { AppService } from '@services/app.service';
import { LocalStorageService } from '@services/local-storage.service';

@Injectable()
export class DriversService {
    public static CREATE_DRIVER: string = 'driver/v1';
    public static DRIVERS: string = 'driver/v1/depot/${depotId}';
    public static DRIVER: string = 'driver/v1/${id}';
    public static GET_ALL_DRIVERS: string = 'driver/v1';
    public static GET_DRIVER_AVAILABILITY_RANGE: string = 'driver/v2/calendar/${warehouse}?startdate=${startDate}&enddate=${endDate}';
    public static DRIVER_PLANNING_V2: string = 'driver/v2/driver-planning/${planningId}';
    public static DRIVER_PLANNING_V1: string = 'driver/v1/driver-planning/${planningId}';
    public static DRIVERS_ASSIGNED_TO_SHIFT: string = 'driver/v2/shift-planning/${shiftId}/${mode}';
    public static DRIVER_SEARCH: string = 'driver/v1/search';

    public driversSource: BehaviorSubject<Driver[]> = new BehaviorSubject<Driver[]>([]);
    public drivers: Observable<Driver[]> = this.driversSource.asObservable();

    private readonly host = environment.api.url;
    private readonly prefix = environment.api.prefix;

    constructor(
        private http: HttpClient, 
        private utilsService: UtilsService, 
        private appService: AppService,
        private localStorageService: LocalStorageService
    ) {
        this.loadDrivers();
    }

    public loadDrivers(depotId?: string) {
        const depot = depotId ? depotId : this.localStorageService.getDepot();

        this.getDrivers(depot)
            .pipe(map((drivers: Driver[]) => this.driversSource.next(drivers)))
            .subscribe();
    }

    public getDrivers(depotId?: string) {
        const depot = depotId ? depotId : this.localStorageService.getDepot();
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${DriversService.DRIVERS}`, {depotId: depot});

        return this.http.get(endpoint, this.utilsService.httpHeaders()).pipe(
            map((response: any) => {
                return response
                    .map((rawDriver: any) => {
                        return new Driver().deserialize(rawDriver);
                    })
                    .sort((a: Driver, b: Driver) => {
                        return `${a.lastname} ${a.firstName}`.localeCompare(`${b.lastname} ${b.firstName}`);
                    });
            })
        );
    }

    public getAllDrivers() {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${DriversService.GET_ALL_DRIVERS}`, {});

        return this.http.get(endpoint, this.utilsService.httpHeaders()).pipe(
            map((response: any) => {
                return response
                    .map((rawDriver: any) => {
                        return new Driver().deserialize(rawDriver);
                    })
                    .sort((a: Driver, b: Driver) => {
                        return `${a.lastname} ${a.firstName}`.localeCompare(`${b.lastname} ${b.firstName}`);
                    });
            })
        );
    }

    public getDriversAssignedToShift(shiftId: string, mode: DriversMode): Observable<Driver[]> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${DriversService.DRIVERS_ASSIGNED_TO_SHIFT}`, {shiftId, mode});
        return this.http.get<Driver[]>(endpoint).pipe(
            map((drivers: any[]) => {
                return drivers.map((rawDriver: any) => new Driver().deserialize(rawDriver));
            })
        )
    }

    public getDriver(id: string): Observable<Driver> {
        if (!id) {
            throw new Error('Empty DriverId');
        }

        const endpoint = this.interpolate(`${this.host}${this.prefix}/${DriversService.DRIVER}`, { id: id });

        return this.http.get(endpoint).pipe(
            map(response => {
                return new Driver().deserialize(response);
            })
        );
    }

    public createDriver(driver: Driver): Observable<Driver> {
        const endpoint = `${this.host}${this.prefix}/${DriversService.CREATE_DRIVER}`;

        const driverData = driver.serialize();
        delete driverData.id;

        return this.http.post(endpoint, driverData).pipe(
            map(response => {
                this.loadDrivers();
                return new Driver().deserialize(response);
            })
        );
    }

    public updateDriver(driver: Driver): Observable<any> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${DriversService.DRIVER}`, { id: driver.id });

        const driverData = driver.serialize();
        // delete vanData.id;

        return this.http.put(endpoint, driverData).pipe(
            map(response => {
                this.loadDrivers();

                return new Driver().deserialize(response);
            })
        );
    }

    public getDriverAvailabilityRange(startDate: string, endDate: string, warehouse): Observable<AvailabilityRange> {
        if (_.isEmpty(warehouse) || _.isEmpty(startDate) || _.isEmpty(endDate)) {
            throw new Error('Empty date range or warehouse');
        }

        warehouse = this.appService.findWarehouseRef(warehouse);

        const endpoint = this.interpolate(`${this.host}${this.prefix}/${DriversService.GET_DRIVER_AVAILABILITY_RANGE}`, { warehouse, startDate, endDate });

        return this.http
            .get(endpoint)
            .pipe(
                map(res => plainToClass(AvailabilityRange, res as AvailabilityRange)),
                // map(availabilityRange => {
                //     _.forEach(availabilityRange.shiftSummaryList, (shiftSummary: ShiftSummaryList) => {
                //         shiftSummary.shifts = shiftSummary.shifts.filter((shiftWithStatussess: ShiftConfigWithStatuses) => shiftWithStatussess);
                //     });
                //     return availabilityRange;
                // })
            );
    }

    public saveDriverPlanning(planningId: string, mode: DriverPlanningMode = DriverPlanningMode.REGULAR): Observable<any> {
        if (!planningId) {
            throw new Error('Empty id');
        }

        const endpoint = this.interpolate(`${this.host}${this.prefix}/${DriversService.DRIVER_PLANNING_V2}`, { planningId });

        const body = JSON.stringify(mode);

        return this.http.post(endpoint, body, this.utilsService.httpHeaders()).pipe(
            map(response => {
                return response;
            })
        );
    }

    public saveDriverPlanningMorningShifts(driverId: string, shiftType: ShiftType, warehouse: string, startDate): Observable<any> {
        if (_.isEmpty(driverId) || _.isEmpty(shiftType) || _.isEmpty(shiftType)) {
            throw new Error('Empty driverId or shiftType or shiftType');
        }

        const endpoints = [];

        for (let index = 0; index < 7; index++) {
            const date = moment(startDate)
                .add(index, 'days')
                .format('YYYY-MM-DD');
            const planningId = `${date}:${warehouse}:${shiftType}:${driverId}`;
            const enpoint = this.interpolate(`${this.host}${this.prefix}/${DriversService.DRIVER_PLANNING_V1}`, { planningId });
            endpoints.push(this.http.post(enpoint, null).pipe(map(response => response)));
        }

        return forkJoin(...endpoints).pipe(
            map(responses => {
                return responses;
            })
        );
    }

    public deleteDriverPlanning(planningId: string): Observable<any> {
        if (!planningId) {
            throw new Error('Empty id');
        }

        const endpoint = this.interpolate(`${this.host}${this.prefix}/${DriversService.DRIVER_PLANNING_V1}`, { planningId });

        return this.http.delete(endpoint).pipe(
            map(response => {
                return response;
            })
        );
    }

    public searchDriver(phrase: string): Observable<Driver[]> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${DriversService.DRIVER_SEARCH}?q=${phrase}`, { });
        return this.http.get<Driver[]>(endpoint).pipe(
            map((drivers: any[]) => {
                return drivers.map((rawDriver: any) => new Driver().deserialize(rawDriver));
            })
        );
    }
    
    private interpolate(template: string, params: {}) {
        const names = Object.keys(params);
        const vals = Object.values(params);
        return new Function(...names, `return \`${template}\`;`)(...vals);
    }
}
