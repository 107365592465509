<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="sliders"></i-feather> {{'Overbooked slots' | translate}}
            </h4>
        </div>
        <hr />
    </div>
    
    <div content>
        <div *ngIf="(list$ | async) as list">

            <ngx-datatable 
                id="apms" 
                class="bootstrap" 
                [rows]="list" 
                [columns]="columns" 
                [columnMode]="'flex'"
                [headerHeight]="50" 
                [footerHeight]="50" 
                [messages]="tableMessages" 
                [rowHeight]="'auto'" 
                [externalPaging]="false"
                [rowClass]="rowClass">
                <ng-container *ngFor="let col of columns">
                    <ngx-datatable-column [name]="col.prop" [flexGrow]="col.flexGrow" [prop]="col.prop" [sortable]="col.sortable" [headerClass]="col.headerClass">
                        <ng-template let-column="column" ngx-datatable-header-template class="header" let-sort="sortFn">
                            <b (click)="sort()">{{ col.name | translate }}</b>
                        </ng-template>
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>

                            <div [ngSwitch]="col.prop">
                                <div *ngSwitchCase="'slotTime'">
                                        <span>{{value | timeFormatterPipe:'D.MM.YYYY HH:mm'}}</span>
                                    </div>
                                <div *ngSwitchCase="'overboking'">
                                    <ul class="pd-l-20 mg-0">
                                        <li *ngFor="let el of value">
                                            <span *ngIf="el.subscription !== null">
                                                {{subscription}}
                                            </span>
                                            <span 
                                            [containerClass]="'pretty-tooltip'" 
                                            placement="right" 
                                            [tooltip]="el.overbooking | rlmCapacityLabelPipe">{{el.overbooking | rlmCapacityLabelShortPipe}}</span>
                                        </li>
                                    </ul>
                                   
                                </div>
                                <div *ngSwitchCase="'options'">
                                    <div class="dropdown" dropdown>
                                        <button
                                            class="btn btn-outline-secondary btn-xs dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton">
                                            {{ 'Select' | translate }}
                                        </button>

                                        <div class="dropdown-menu dropdown-menu-right div-main-actions">
                                            <a 
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-affected-deliveries" 
                                                [routerLink]="['/apm', apmId, 'apm-deliveries', 'list', {slotTime: row.slotTime}]">
                                                    {{'Affected Apm deliveries' | translate }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div *ngSwitchDefault>{{ value | translate }}</div>
                            </div>

                        </ng-template>
                    </ngx-datatable-column>
                </ng-container>
            </ngx-datatable>
        </div>

        <!-- <div *ngIf="loader" class="load-spinner text-center">
            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}
        </div> -->
    </div>

</layout-default>
