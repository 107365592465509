<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="sliders"></i-feather> {{'Convert Apm delivery to door to door' | translate}}
            </h4>
        </div>
        <hr />
    </div>

    <div content>

        <div *ngIf="!loader" class="content bg-white">

            <div class="card-content col-8 offset-2">

                <div id="dtd-convert-delivery-info"  *ngIf="(partner$ | async) as partner" class="row mg-t-20">
                    <div class="offset-2 col">
                        <label class="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1">{{'Delivery details' | translate}}:</label>

                        <p class="mg-b-2">Partner: <strong>{{partner.name}}</strong></p>
                        <p class="mg-b-2">Kod: <strong>{{partner.code}}</strong></p>
                        <p class="mg-b-2">Status: <strong>{{entity.status | translate}}</strong></p>

                        <p class="mg-b-2">{{'Capacity' | translate}}: 
                            <strong 
                                [containerClass]="'pretty-tooltip'" 
                                placement="right" 
                                [tooltip]="entity | rlmCapacityLabelPipe">{{entity | rlmCapacityLabelShortPipe}}</strong></p>
                        <p class="mg-b-2">{{'Apm' | translate}}: <strong>{{entity.apm.name}}</strong></p>
                        <p>{{'Logistic type' | translate}}: 
                            <strong [ngSwitch]="entity.logisticType">
                                <span *ngSwitchCase="'OWN'">{{ 'ApmDeliveryType.'+entity.logisticType | translate }}</span>
                                <span *ngSwitchCase="'INPOST'">{{ 'ApmDeliveryType.'+entity.logisticType | translate }}</span>
                                <span *ngSwitchCase="'MIXED'">{{ 'ApmDeliveryType.'+entity.logisticType | translate }}</span>
                                <span *ngSwitchDefault>{{ 'ApmDeliveryType.'+entity.logisticType | translate }}</span>
                            </strong>
                        </p>
                    </div>
                    <div class="col">
                        <label class="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1">&nbsp;</label>
                        <p class="mg-b-2">{{'reservationNumber' | translate}}: <strong>{{entity.reservationNumber}}</strong></p>
                        <p class="mg-b-2">{{'reservationReference' | translate}}: <strong>{{entity.reservationReference}}</strong></p>

                        <ng-container *ngFor="let window of ['deliveryWindow', 'reservationWindow', 'pickupWindow']">
                            <p *ngIf="entity[window.toString()] !== null" class="mg-b-2">
                                {{window.toString() | translate}}: <strong>{{ entity[window.toString()]?.from | amLocale: locale | amDateFormat: 'D MMM, HH:mm' }} - {{ entity[window.toString()]?.to | amLocale: locale | amDateFormat: 'D MMM, HH:mm' }}</strong>
                            </p>
                        </ng-container>
                        
                        

                    </div>
                </div>

                <form [formGroup]="form">



                    <ng-container formGroupName="customer">
                        <label
                            class="d-block col-10 offset-2 tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-b-20">{{'Customer information' | translate}}</label>
                        <div *ngFor="let item of customerKeys" [id]="'dtd-convert-form-input-' + item" class="form-group row">
                            <!-- <label class="col-2" for="code">{{item.toUpperCase() | translate}}:&nbsp;<span
                                    *ngIf="form.get('customer').get(item) | isRequiredControlPipe">*</span></label> -->

                                    <label [for]="item" class="col-2">
                                        <span>{{item.toUpperCase() | translate}}:&nbsp;</span>
                                        <span *ngIf="form.get('customer').get(item) | isRequiredControlPipe">*</span>&nbsp;
                                        <i-feather *ngIf="tooltipLabel(item)" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(item)"
                                            [style.width.px]="13" name="info"></i-feather>
                                    </label>

                                    
                            <div class="col-10">

                                <ng-container [ngSwitch]="item">
                                    <div *ngSwitchCase="'phone2Nr'">
                                        <input prefix="+48 " mask="000-000-000" [formControlName]="item"
                                            class="form-control" />
                                    </div>
                                    <div *ngSwitchCase="'phoneNr'">
                                        <input prefix="+48 " mask="000-000-000" [formControlName]="item"
                                            class="form-control" />
                                    </div>
                                    <div *ngSwitchDefault>
                                        <input [formControlName]="item" class="form-control" />
                                    </div>
                                </ng-container>

                                <div *ngIf="item === 'phoneNr' && form.get('customer').get('phoneNr').hasError('Mask error') && form.get('customer').get('phoneNr').touched"
                                    class="error-color">
                                    {{ "Above phone number you entered is invalid" | translate }}!
                                </div>
                                <div *ngIf="item === 'phone2Nr' && form.get('customer').get('phone2Nr').hasError('Mask error') && form.get('customer').get('phone2Nr').touched"
                                    class="error-color">
                                    {{ "Above phone number you entered is invalid" | translate }}!
                                </div>
                                <div *ngIf="form.get('customer').get(item)?.hasError('required') && form.get('customer').get(item)?.touched"
                                    class="error-color">
                                    {{ "The field is required" | translate }}!
                                </div>
                                <div *ngIf="form.get('customer').get(item)?.hasError('maxlength') && form.get('customer').get(item)?.touched"
                                    class="error-color">
                                    {{ "The field value is too long" | translate }}!
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <div *ngFor="let item of regularKeys" [id]="'dtd-convert-form-input-' + item" class="form-group row">
                        <!-- <label class="col-2" for="code">{{item.toUpperCase() | translate}}:&nbsp;<span
                                *ngIf="form.get(item) | isRequiredControlPipe">*</span></label> -->

                        <label [for]="item" class="col-2">
                            <span>{{item.toUpperCase() | translate}}:&nbsp;</span>
                            <span *ngIf="form.get(item) | isRequiredControlPipe">*</span>&nbsp;
                            <i-feather *ngIf="tooltipLabel(item)" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(item)"
                                [style.width.px]="13" name="info"></i-feather>
                        </label>
                        <div class="col-10">
                            <ng-container [ngSwitch]="item">
                                <div *ngSwitchCase="'pickupDuration'">
                                    <input type="number" [formControlName]="item" class="form-control" />
                                </div>
                                <div *ngSwitchDefault>
                                    <input [formControlName]="item" class="form-control" />
                                </div>
                            </ng-container>
                            <div *ngIf="item === 'slot' && form.get(item).hasError('Mask error') && form.get(item).touched"
                                class="error-color">
                                {{ "Above phone number you entered is invalid" | translate }}!
                            </div>
                            <div *ngIf="form.get(item)?.hasError('required') && form.get(item)?.touched"
                                class="error-color">
                                {{ "The field is required" | translate }}!
                            </div>
                            <div *ngIf="form.get(item)?.hasError('maxlength') && form.get(item)?.touched"
                                class="error-color">
                                {{ "The field value is too long" | translate }}!
                            </div>
                        </div>
                    </div>

                    <ng-container formGroupName="pickupWindow">
                        <label
                            class="d-block col-10 offset-2 tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-t-30 mg-b-20">{{'Pickup window' | translate}}</label>

                        <div class="form-group row">
                            <label class="col-2" for="from">&nbsp;</label>
                            <div class="col-10">
                                <div class="row">
                                    <div id="dtd-convert-form-input-pickupWindow" class="col">
                                        <span class="d-block col-2 tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans pd-l-0" for="from">{{ 'Date' | translate }}:</span>
                                        <ng-datepicker class="datepicker-input form-group" formControlName="fromDate"
                                            id="to" [headless]="disabled" [options]="options"> </ng-datepicker>
                                        <div *ngIf="form.get('pickupWindow').get('from').hasError('required') && form.get('pickupWindow').get('from').touched"
                                            class="error-color">{{ 'Joined date is required' | translate }}!</div>
                                    </div>
                                    <div id="dtd-convert-form-input-fromTime" class="col">
                                        <span class="d-block col-2 tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans pd-l-0" for="from">{{ 'From' | translate }}:</span>
                                        <input typ="text" [dropSpecialCharacters]="false" formControlName="fromTime"
                                            mask="Hh:m0" class="form-control" placeholder="Godzina (HH:mm)" />
                                    </div>
                                    <div id="dtd-convert-form-input-toTime" class="col">
                                        <span class="d-block col-2 tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans pd-l-0" for="from">{{ 'To' | translate }}:</span>
                                        <input typ="text" [dropSpecialCharacters]="false" formControlName="toTime"
                                            mask="Hh:m0" class="form-control" placeholder="Godzina (HH:mm)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <label class="d-block col-10 offset-2 tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-b-20">{{'Customer slot' | translate}}</label>
                    <div class="form-group row">
                        <label class="col-2" for="from">&nbsp;</label>
                        <div class="col-10">
                            <div class="row">
                                <div id="dtd-convert-form-input-date" class="col">
                                    <span class="d-block col-2 tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans pd-l-0" for="from">{{ 'Date' | translate }}:</span>
                                    <ng-datepicker class="datepicker-input form-group" formControlName="date"
                                        id="date" [headless]="disabled" [options]="options"> </ng-datepicker>

                                    <input *ngIf="disabled" type="text" class="form-control" [disabled]="disabled"
                                        [value]="form.get('date').value | date: 'dd.MM.yyyy'" />

                                    <div *ngIf="form.get('date').hasError('required') && form.get('date').touched"
                                        class="error-color">{{ 'The field is required' | translate }}!</div>

                                </div>
                                <div id="dtd-convert-form-input-slotStart" class="col">
                                    <span class="d-block col-2 tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans pd-l-0" for="from">{{ 'From' | translate }}:</span>
                                    <input mask="00:00" [dropSpecialCharacters]="false" formControlName="slotStart"
                                        class="form-control" placeholder="Format (HH:mm-HH:mm)" />

                                    <div *ngIf="form.get('slotStart')?.hasError('required') && form.get('slotStart')?.touched"
                                        class="error-color"> {{ "The field is required" | translate }}!
                                    </div>
                                </div>
                                <div id="dtd-convert-form-input-slotEnd" class="col">
                                    <span class="d-block col-2 tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans pd-l-0" for="from">{{ 'To' | translate }}:</span>
                                    <input mask="00:00" [dropSpecialCharacters]="false" formControlName="slotEnd"
                                        class="form-control" placeholder="Format (HH:mm-HH:mm)" />
                                    <div *ngIf="form.get('slotEnd')?.hasError('required') && form.get('slotEnd')?.touched"
                                        class="error-color"> {{ "The field is required" | translate }}!
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>

                    <ng-container formGroupName="locationAddress">
                        <label
                            class="d-block col-10 offset-2 tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-b-20">{{'Address' | translate}}</label>

                        <div *ngFor="let item of addressKeys" [id]="'dtd-convert-form-input-' + item" class="form-group row">
                            <label class="col-2" for="code">{{item.toUpperCase() | translate}}:&nbsp;<span
                                    *ngIf="form.get('locationAddress').get(item) | isRequiredControlPipe">*</span></label>
                            <div class="col-10">
                                <input *ngIf="item === 'zip'" [formControlName]="item" [dropSpecialCharacters]="false"
                                    mask="00-000" class="form-control" />
                                <input *ngIf="item !== 'zip'" [formControlName]="item" class="form-control" />
                                <div *ngIf="form.get('locationAddress').get(item)?.hasError('required') && form.get('locationAddress').get(item)?.touched"
                                    class="error-color">
                                    {{ "The field is required" | translate }}!
                                </div>
                                <div *ngIf="item === 'zip' && form.get('locationAddress').get('zip').hasError('pattern') && form.get('locationAddress').get('zip').touched"
                                    class="error-color">
                                    {{ "The zip code you entered is invalid" | translate }}!
                                </div>
                                <div *ngIf="form.get('locationAddress').get(item)?.hasError('maxlength') && form.get('locationAddress').get(item)?.touched"
                                    class="error-color">
                                    {{ "The field value is too long" | translate }}!
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container formGroupName="locationAdditional">
                        <label
                            class="d-block col-10 offset-2 tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-b-20">{{'Address information' | translate}}</label>

                        <ng-container *ngFor="let item of additionalKeys">
                            <div *ngIf="form.get('locationAdditional').get(item)" [id]="'dtd-convert-form-input-' + item" class="form-group row">
                                <ng-container *ngIf="item != 'lift' && item != 'floor'">
                                    <label class="col-2" [for]="item">{{item.toUpperCase() | translate}}:&nbsp;<span
                                            *ngIf="form.get('locationAdditional').get(item) | isRequiredControlPipe">*</span></label>
                                    <div class="col-10">

                                        <input *ngIf="item === 'contactPhoneNO'" prefix="+48 " mask="000-000-000"
                                            [formControlName]="item" class="form-control" />
                                        <input *ngIf="item !== 'contactPhoneNO'" [formControlName]="item"
                                            class="form-control" />

                                        <div *ngIf="form.get('locationAdditional').get(item)?.hasError('required') && form.get('locationAdditional').get(item)?.touched"
                                            class="error-color">
                                            {{ "The field is required" | translate }}!
                                        </div>
                                        <div *ngIf="form.get('locationAdditional').get(item).hasError('pattern') && form.get('locationAdditional').get(item).touched"
                                            class="error-color">
                                            {{ "Above email address is incorrect" | translate }}!
                                        </div>
                                        <div *ngIf="item === 'contactPhoneNO' && form.get('locationAdditional').get('contactPhoneNO').hasError('Mask error') && form.get('locationAdditional').get('contactPhoneNO').touched"
                                            class="error-color">
                                            {{ "Above phone number you entered is invalid" | translate }}!
                                        </div>
                                        <div *ngIf="form.get('locationAdditional').get(item)?.hasError('maxlength') && form.get('locationAdditional').get(item)?.touched"
                                            class="error-color">
                                            {{ "The field value is too long" | translate }}!
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                        <div id="dtd-convert-form-input-lift" class="form-group row">
                            <label class="col-2" for="socialNumberType">{{'Lift' | translate}}:&nbsp;<span
                                    *ngIf="form.get('locationAdditional').get('lift') | isRequiredControlPipe">*</span></label>
                            <div class="col-10">
                                <select class="custom-select" formControlName="lift">
                                    <option [value]="true">{{'Yes' | translate}}</option>
                                    <option [value]="false">{{'No' | translate}}</option>
                                </select>

                                <div *ngIf="form.get('locationAdditional').get('lift')?.hasError('required') && form.get('locationAdditional').get('lift')?.touched"
                                    class="error-color">
                                    {{ "Lift field is required" | translate }}!
                                </div>
                            </div>
                        </div>
                        <div id="dtd-convert-form-input-floor" class="form-group row">
                            <label class="col-2" for="floor">{{'Floor'.toUpperCase() | translate}}:&nbsp;<span
                                    *ngIf="form.get('locationAdditional').get('floor') | isRequiredControlPipe">*</span></label>
                            <div class="col-10">
                                <input formControlName="floor" type="number" class="form-control" />
                                <div *ngIf="form.get('locationAdditional').get('floor')?.hasError('required') && form.get('locationAdditional').get('floor')?.touched"
                                    class="error-color">
                                    {{ "The field is required" | translate }}!
                                </div>
                            </div>
                        </div>

                    </ng-container>

                    <ng-container formGroupName="locationCoordinates">
                        <div class="row">
                            <div class="d-block col-10 offset-2">
                                <label
                                    class="d-block tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-r-20">{{'Coordinates' | translate}}</label>
                                <button class="btn btn-sm btn-white"
                                    (click)="getCoordinates()">{{'Geocode address' | translate}}</button>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-10 offset-2 mg-t-20 mg-b-20">
                                <gmap *ngIf="coordinates" (coords)="changeCoordinates($event)" [locations]="coordinates"
                                    [mapProperties]="mapProperties" [mapOption]="mapOption"></gmap>
                            </div>
                        </div>
                        <div *ngFor="let item of coordinatesKeys" [id]="'dtd-convert-form-input-' + item" class="form-group row">
                            <label class="col-2" for="code">{{item.toUpperCase() | translate}}:</label>
                            <div class="col-10">
                                <input [formControlName]="item" class="form-control" />
                                <div *ngIf="form.get('locationCoordinates').get(item)?.hasError('required') && form.get('locationCoordinates').get(item)?.touched"
                                    class="error-color">
                                    {{ "The field is required" | translate }}!
                                </div>
                            </div>
                        </div>
                    </ng-container>


                    <div *ngIf="errorMsg" class="form-group row">
                        <div class="col-9 offset-3">
                            <p class="error-color">{{ 'Something has wrong! Please correct form' | translate }}:</p>
                            <ul>
                                <li class="error-color">
                                    <span>{{ errorMsg | translate }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div *ngIf="formErrorsMessages && formErrorsMessages.length" class="form-group row">
                        <div class="col-10 offset-2">
                            <p class="error-color">{{ 'Something has wrong! Please correct form' | translate }}:</p>
                            <ul>
                                <li *ngFor="let error of formErrorsMessages" class="error-color">
                                    <span
                                        *ngIf="error.value && (error.field !== 'update.updateData' && error.field !== 'create.createData' && error.field !== 'update.updateData.timeWindow')">{{ 'Field' | translate }}:&nbsp;</span>
                                    <strong
                                        *ngIf="error.value && (error.field !== 'update.updateData' && error.field !== 'create.createData' && error.field !== 'update.updateData.timeWindow')">{{ error.field | translate }}&nbsp;-&nbsp;</strong>

                                    <span
                                        *ngIf="error.value && error.value.includes('specified date range')">{{ 'SUBSCRIPTION_VOLUME_ERROR' | translate | subscriptionVolumeErrorPipe:error.value }}</span>
                                    <span
                                        *ngIf="error.value && !error.value.includes('specified date range')">{{ error.value | translate }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="form-group row justify-content-end">
                        <div class="col-2">
                            <button (click)="onSubmit()" [disabled]="!form.valid || submitted"
                                class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01 w-100">

                                <span *ngIf="!submitted">{{ "Save" | translate }}</span>

                                <ng-container *ngIf="submitted">
                                    <div class="load-spinner text-center">
                                        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                    </div>
                                </ng-container>

                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </div>

    </div>
</layout-default>


<!-- {{'PHONENR' | translate}} -->
<!-- {{'PHONE2NR' | translate}} -->
<!-- {{'PICKUPDURATION' | translate}} -->
<!-- {{'CUSTOMERNOTES' | translate}} -->
<!-- {{'DISPATCHERNOTES' | translate}} -->
<!-- {{'NOTES' | translate}} -->
<!-- {{ 'SLOTSTART' | translate }} -->
<!-- {{ 'SLOTEND' | translate }} -->


<!-- {{ 'deliveryWindow' | translate }} -->
<!-- {{ 'reservationWindow' | translate }} -->
<!-- {{ 'purgeWindow' | translate }} -->
<!-- {{ 'pickupWindow' | translate }} -->




<!-- {{'tooltips.d2d.labels.slotStart' | translate -->
<!-- {{'tooltips.d2d.labels.slotEnd' | translate -->
<!-- {{'tooltips.d2d.labels.pickupDuration' | translate -->