import { Pipe, PipeTransform } from '@angular/core';
import { Apm } from '../entities/apm';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'rlmCapacityLabelPipe',
  pure: true
})
export class RlmCapacityLabelPipe implements PipeTransform {

  constructor(private readonly translateService: TranslateService){}

  transform(rlm: Apm): any {
    return  `${this.translateService.instant('CompartmentType.Cooler')}: ${rlm.lcount} \n` + 
            `${this.translateService.instant('CompartmentType.Freezer')}: ${rlm.mcount} \n` + 
            `${this.translateService.instant('CompartmentType.Ambient')}: ${rlm.ncount} \n` + 
            `${this.translateService.instant('CompartmentType.Other')}: ${rlm.ocount}`;
  }
}
