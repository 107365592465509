import { Component, OnInit } from '@angular/core';
import { CRUD_MODE, SmsTemplatesType } from '@enums/enum';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { SmsTemplate, SmsTemplatesParameter } from '../../intefaces/sms-templates.interface';
import { tooltipLabel } from '@shared/functions/tooltip-labels.function';
import { SmsTemplatesService } from '../../services/sms-templates.service';

@Component({
  selector: 'app-sms-templates-edit',
  templateUrl: './sms-templates-edit.component.html',
  styleUrls: ['./sms-templates-edit.component.scss']
})
export class SmsTemplatesEditComponent implements OnInit {

  public entity: SmsTemplate;

  public mode: CRUD_MODE;
  public formErrorsMessages;
  public form: FormGroup;
  public loader: boolean = true;
  public submitted: boolean = false;

  public parameters: FormArray;

  public smsTemplatesTypeOptions = SmsTemplatesType;
  public smsTemplatesType = Object.keys(SmsTemplatesType);
  public staticParametersSms = ['curierFirstName', 'customerFirstName', 'customerFullName', 'pickupCode'];
  public staticParametersInternal = ['customerId', 'customerFullName'];
  public staticParametersMail = ['customerList', 'customerListHtml'];

  constructor(
    private readonly navigationRoute: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private toastrProviderService: ToastrProviderService,
    private readonly translateService: TranslateService,
    private readonly smsTemplatesService: SmsTemplatesService,
    private readonly router: Router
  ) { }

  public ngOnInit() {
    this.mode = (this.navigationRoute.snapshot.params.id) ? CRUD_MODE.UPDATE : CRUD_MODE.CREATE;
    this.entity = (this.navigationRoute.snapshot.data as {smsTemplate: any}).smsTemplate;
    this.initForm(this.entity);
  }

  public initForm(entity: undefined | SmsTemplate) {
    this.form = this.formBuilder.group({
      id: [null],
      title: [null, Validators.required],
      parameters: this.formBuilder.array([]),
      body: [''],
      htmlBody: [''],
      type: [null, Validators.required],
    });

    this.parameters = <FormArray>this.form.controls[ 'parameters' ];

    if (entity) {
      this.form.patchValue(entity);

      if (entity.parameters && entity.parameters.length) {
        entity.parameters.forEach((item) => this.parameters.push(this.patchValues(item)));
      } 
    }

    this.loader = false;

  }

  private patchValues(item?: SmsTemplatesParameter): AbstractControl {
    return this.formBuilder.group({
      id: [item ? item.id : null],
      description: [item ? item.description : null, [Validators.required, Validators.maxLength(255)]],
      name: [item ? item.name : null, Validators.required],
    });
  }

  public addNewParam() {
    this.parameters.push(this.patchValues(undefined));
  }

  public removeParam(i: number) {
    this.parameters.removeAt(i);
  }
  
  public tooltipLabel(property: string): string {
    return tooltipLabel(this.translateService, `tooltips.smstemplate.labels.${property}`);
  }

  public pasteParam(name): void {
    const oldValue = this.form.controls.body.value;
    this.form.controls.body.setValue(oldValue + '${' + name + '}');
  }

  public onSubmit() {
    this.submitted = true;
    const rawData: SmsTemplate = this.form.getRawValue();

    if (this.mode === CRUD_MODE.UPDATE){
      this.smsTemplatesService.update(rawData.id, rawData)
        .subscribe((response: SmsTemplate) => {
          this.submitted = false;
          this.toastrProviderService.showSuccess(this.translateService.instant('Message template has been updated'));
          this.router.navigate(['../../list', {modifiedRow: response.id}], {relativeTo: this.navigationRoute})
        }, (error) => {
          this.submitted = false;
        });
    } else {
      delete rawData.id;
      this.smsTemplatesService.save(rawData)
        .subscribe((response: SmsTemplate) => {
          this.submitted = false;
          this.toastrProviderService.showSuccess(this.translateService.instant('Message template has been created'));
          this.router.navigate(['../list', {modifiedRow: response.id}], {relativeTo: this.navigationRoute})

        }, (error) => {
          this.submitted = false;
        });
    }
  }

}
