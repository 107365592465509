import { Component} from '@angular/core';
import { CrudService } from '@services/crud.service';
import { CRUD_MODE } from '@enums/enum';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  template: '',
})
export class CrudEditComponent<T, ID>  {
    
    public submitted: boolean = false;
    public loader: boolean = true;
    public mode: CRUD_MODE;

    constructor(
        protected readonly crudService: CrudService<T, string>,
        protected readonly translateService: TranslateService,
        protected readonly toastrProviderService: ToastrProviderService,
        protected readonly router: Router,
        private readonly activatedRoute: ActivatedRoute
    ) {}

    protected create(rawData): any {
        return this.crudService.save(rawData)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    this.submitted = false;
                    throw (error);
                })
            )
            .subscribe(
                (data: T) => {
                    this.toastrProviderService.showSuccess(this.translateService.instant('Create process has been done successfully!'));
                    this.router.navigate(['../list', { modifiedRow: data['id'] }], { relativeTo: this.activatedRoute });
                    this.submitted = false;
                },
                error => {
                    console.log(`Error occurred, please try again!`, error);
                    this.submitted = false;
                },
                () => {
                    this.submitted = false;
                }
            );
    }
    
    protected update(rawData): void {
        this.crudService.update(rawData['id'], rawData)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    this.submitted = false;
                    throw (error);
                })
            )
            .subscribe(
                (data: T) => {
                    this.toastrProviderService.showSuccess(this.translateService.instant('Update process has been done successfully!'));
                    this.router.navigate(['../list', { modifiedRow: data['id'] }], { relativeTo: this.activatedRoute });
                    this.submitted = false;
                },
                error => {
                    console.log(`Error occurred, please try again!`, error);
                    this.submitted = false;
                },
                () => {
                    this.submitted = false;
                }
            );
    }
}
