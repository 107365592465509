import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import enLocale from 'date-fns/locale/en-GB';
import plLocale from 'date-fns/locale/pl';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, mergeMap, tap } from 'rxjs/operators';
import { DeliveriesService } from '@deliveries/services/deliveries.services';
import { RoutesService } from '@routes/services/routes.service';
import { NavService } from '@services/nav.sevice';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Component({
    selector: 'search-delivery',
    templateUrl: 'search-delivery.component.html',
    styleUrls: ['search-delivery.component.scss'],
})
export class SearchDeliveryComponent {
    @Input() private year: string;
    @Input() private month: string;
    @Input() private day: string;

    private locale: any = plLocale;
    private searchedPhrase: string = '';

    public asyncSelected: string;
    public typeaheadLoading: boolean;
    public dataSource: Observable<any>;
    public datePickerOptions = {
        minYear: 1970,
        maxYear: 2030,
        displayFormat: 'D MMMM YYYY',
        barTitleFormat: 'MMMM YYYY',
        dayNamesFormat: 'dd',
        firstCalendarDay: 1,
        locale: this.locale
    };

    constructor(
        private readonly routesService: RoutesService, 
        private readonly navService: NavService, 
        private readonly translate: TranslateService, 
        private readonly deliveriesService: DeliveriesService,
        private readonly toastrProviderService: ToastrProviderService
    ) {
        this.locale = this.translate.currentLang === 'en' ? enLocale : plLocale;
        this.datePickerOptions.locale = this.locale;
        const depot = localStorage.getItem('depot');
        this.dataSource = new Observable((observer: any) => {
            observer.next(this.asyncSelected);
        }).pipe(
            filter((searchedPhrase: string) => {
                return searchedPhrase.length >= 3;
            }),
            debounceTime(500),
            distinctUntilChanged(),
            mergeMap((searchedPhrase: string) => {
                this.searchedPhrase = searchedPhrase;
                return this.routesService.searchDeliveryInRoute(this.year, this.month, this.day, searchedPhrase);
            }),
            tap((results: any[]) => {
                if (results && results.length === 0) {
                    this.toastrProviderService.showWarning(this.translate.instant('Customer not found!'));
                    return [];
                }
            })
        );
    }
    
    public changeTypeaheadLoading(e: boolean): void {
        this.typeaheadLoading = e;
    }

    public typeaheadOnSelect(event: TypeaheadMatch): void {
        if (event.item.routeId) {
            const date = event.item.routeId.getDate('/');
            this.navService.goToPage(`routes/${date}/${event.item.routeId.shift}/${event.item.routeId.warehouseId}/route/${event.item.rId}`, { deliveryId: event.item.deliveryId });
        }
    }
}
