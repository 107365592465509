import { Pipe, PipeTransform } from '@angular/core';
import { Apm } from '../entities/apm';

@Pipe({
  name: 'rlmCapacityLabelShortPipe',
  pure: true
})
export class RlmCapacityLabelShortPipe implements PipeTransform {
  transform(rlm: Apm): string {
    return `${rlm.lcount}/${rlm.mcount}/${rlm.ncount}/${rlm.ocount}`;
  }
}
