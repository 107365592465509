import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CronJobScheduler } from '../../interfaces/cron-job-scheduler.interface';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { CronJobSchedulerService } from '../../services/cronjob-scheduler.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { CrudEditComponent } from '@shared/components/cruds/crud-edit.component';
import { CRUD_MODE, TimePeriodReportPaymentSummaryJob, PaymentReportType } from '@enums/enum';
import { tooltipLabel } from '@shared/functions/tooltip-labels.function';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { CronJobAvailableParams } from '../../interfaces/cron-job-available-params.interface';

@Component({
  selector: 'app-cronjob-scheduler-edit',
  templateUrl: './cronjob-scheduler-edit.component.html',
  styleUrls: ['./cronjob-scheduler-edit.component.scss']
})
export class CronjobSchedulerEditComponent extends CrudEditComponent<CronJobScheduler, string> implements OnInit, OnDestroy {

  private entity: CronJobScheduler;
  public form: FormGroup;
  public cronExpression = '2 0/2 * 1/1 * ? *';
  public isCronDisabled = false;
  public availableParams$: Observable<CronJobAvailableParams[]>;
  private availableParamsColection: CronJobAvailableParams[];
  public availableValidators;
  public loader = true;
  public destroy$ = new Subject<boolean>();

  public regularFormItems = ['jobName', 'description', 'jobGroup'];
  public booleanFormmItems = ['cronJob'];
  public formErrorsMessages;
  public jobDataCtrl: AbstractControl;
  public jobClassOptions: string[] = [];
  public timePeriodReportPaymentSummaryJob = Object.values(TimePeriodReportPaymentSummaryJob);
  public paymentReportType = Object.values(PaymentReportType);
  public cronConfig: any = {
    multiple: true,
    quartz: true,
    bootstrap: true,
  };

  constructor(
    protected readonly navigationRoute: ActivatedRoute,
    protected readonly formBuilder: FormBuilder,
    protected readonly cronJobSchedulerService: CronJobSchedulerService,
    protected readonly toastrProviderService: ToastrProviderService,
    protected readonly translateService: TranslateService,
    protected readonly router: Router
  ) { 
    super(cronJobSchedulerService, translateService, toastrProviderService, router, navigationRoute)
  }

  public ngOnInit() {
    this.entity = (this.navigationRoute.snapshot.data as {vehicleType: any} ).vehicleType;
    this.mode = (this.entity) ? CRUD_MODE.UPDATE : CRUD_MODE.CREATE;

    this.availableParams$ = this.cronJobSchedulerService.available().pipe(
      tap((list: any[]) => {
        this.availableParamsColection = list;
        this.availableValidators = (this.entity) ? list.find(a => a.clazz === this.entity.jobClass) : undefined;
        this.jobClassOptions = list.map(a => a.clazz);
        this.initForm();
      })
    );
  }

  private initForm() {

    this.form = this.formBuilder.group({
      cronExpression: [null],
      cronJob: [true],
      description: [null, Validators.required],
      jobName: [null, Validators.required],
      jobGroup: [null, Validators.required],
      jobId: [null],
      jobData: this.formBuilder.group({}),
      repeatTime: [0],
      jobClass: ['com.or.routing.*Job', Validators.required],
      version: [null],
    });

    const controls: FormControl[] = [];

    if (this.availableValidators && this.availableValidators.parameters) {
      this.availableValidators.parameters.forEach((control: {name: string, required: boolean}) => {
        this.addDynamicForm(control);
      })
    }

    if (this.mode === CRUD_MODE.UPDATE) {
      this.form.patchValue(this.entity);
      this.cronExpression = this.entity.cronExpression;
      this.form.get('jobName').disable();
      this.form.get('jobGroup').disable();
      this.form.get('jobClass').disable();
    }

    this.form.get('jobClass').valueChanges.pipe(
      takeUntil(this.destroy$),
    ).subscribe(
      (jobClass: string) => {
        this.loader = true;
        this.availableValidators = this.availableParamsColection.find(a => a.clazz === jobClass);
        if (this.availableValidators && this.availableValidators.parameters) {
          this.availableValidators.parameters.forEach((control: {name: string, required: boolean}) => {
            this.addDynamicForm(control);
          })
        }

        this.loader = false;
      }
    )
    
    this.form.get('cronJob').valueChanges.pipe(
      takeUntil(this.destroy$),
    ).subscribe(
      (value) => this.cronExpression = (!value) ? null : '0 15 10 1/1 * ? *'
    );

    this.jobDataCtrl = this.form.get('jobData') as AbstractControl;
    this.loader = false;
  }

  public addDynamicForm(control: {name: string, required: boolean}) {
    const dCtrl = new FormControl();
    const jobDataCtrl =  this.form.get('jobData') as FormGroup;
    jobDataCtrl.addControl(control.name, dCtrl); 

    if (control.required) {
      dCtrl.setValidators(Validators.required);
    }

    dCtrl.updateValueAndValidity();
  }

  public onSubmit() {
    const cronJobScheduler = this.form.getRawValue() as CronJobScheduler;
    cronJobScheduler.cronExpression = this.cronExpression;

    if (cronJobScheduler.cronJob) {
      delete cronJobScheduler.repeatTime;
    } else {
      delete cronJobScheduler.cronExpression;
    }

    if (this.mode === CRUD_MODE.CREATE) {
      this.createCronJob(cronJobScheduler)
    } else {
      delete cronJobScheduler.jobName;
      delete cronJobScheduler.jobGroup;
      delete cronJobScheduler.jobClass;

      this.updateCronJob(cronJobScheduler);
    }
  }

  public tooltipLabel(property: string): string {
    return tooltipLabel(this.translateService, `tooltips.cronjobscheduler.labels.${property}`);
  }

  public cronExpressionChanged(value) {
    console.log(value)
  }

  public updateCronJob(rawData): void {
    this.cronJobSchedulerService.updateCronJob(rawData)
        .pipe(
            catchError((error: HttpErrorResponse) => {
                this.submitted = false;
                throw (error);
            })
        )
        .subscribe(
            (data: CronJobScheduler) => {
                this.toastrProviderService.showSuccess(this.translateService.instant('Update process has been done successfully!'));
                this.router.navigate(['../list', { modifiedRow: data['jobId'] }], { relativeTo: this.navigationRoute });
                this.submitted = false;
            },
            error => {
                console.log(`Error occurred, please try again!`, error);
                this.submitted = false;
            }
        );
  }

  public createCronJob(rawData): void {
    this.cronJobSchedulerService.createCronJob(rawData)
        .pipe(
            catchError((error: HttpErrorResponse) => {
                this.submitted = false;
                throw (error);
            })
        )
        .subscribe(
            (data: CronJobScheduler) => {
                this.toastrProviderService.showSuccess(this.translateService.instant('Update process has been done successfully!'));
                this.router.navigate(['../list', { modifiedRow: data['jobId'] }], { relativeTo: this.navigationRoute });
                this.submitted = false;
            },
            error => {
                console.log(`Error occurred, please try again!`, error);
                this.formErrorsMessages = error;

                this.submitted = false;
            }
        );
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
