import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CRUD_MODE } from '@enums/enum';
import { TranslateService } from '@ngx-translate/core';
import { CrudEditComponent } from '@shared/components/cruds/crud-edit.component';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { ProfanityDict } from '../../interfaces/profanity-dict.interface';
import { ProfanityFilterService } from '../../services/profanity-filter.service';

@Component({
    selector: 'app-profanity-filter-edit',
    templateUrl: './profanity-filter-edit.component.html',
    styleUrls: ['./profanity-filter-edit.component.scss'],
})
export class ProfanityFilterEditComponent extends CrudEditComponent<ProfanityDict, string> implements OnInit {
    private entity: ProfanityDict;
    public form: FormGroup;

    public regularFormItems = ['word'];

    constructor(
        protected readonly navigationRoute: ActivatedRoute,
        protected readonly formBuilder: FormBuilder,
        protected readonly profanityFilterService: ProfanityFilterService,
        protected readonly toastrProviderService: ToastrProviderService,
        protected readonly translateService: TranslateService,
        protected readonly router: Router
    ) {
        super(profanityFilterService, translateService, toastrProviderService, router, navigationRoute);
    }

    public ngOnInit() {
        this.entity = (this.navigationRoute.snapshot.data as {vehicleType: any}).vehicleType;
        this.mode = this.entity ? CRUD_MODE.UPDATE : CRUD_MODE.CREATE;
        this.initForm();
    }

    private initForm() {
        this.form = this.formBuilder.group({
            id: [null],
            word: ['', [Validators.required]],
            version: [null],
        });

        if (this.mode === CRUD_MODE.UPDATE) {
            this.form.patchValue(this.entity);
        }

        this.loader = false;
    }

    public onSubmit() {
      const profanity = this.form.getRawValue() as ProfanityDict;
  
      if (this.mode === CRUD_MODE.CREATE) {
        this.create(profanity);
      } else {
        this.update(profanity);
      }
    }
}
