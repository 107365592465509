<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="git-merge"></i-feather> {{'Routing efficiency by distance' | translate}}
            </h4>
        </div>
        <div toolbar>
            <date-range-picker class="flex-grow-1" [extendedOption]="false" [defaultDateRange]="defaultDateRange"
                (changedDateRange)="changedDateRange($event)"></date-range-picker>

        </div>
        <hr />
    </div>

    <div content>

        <div *ngIf="(entity$ | async) as entity">

            <div class="row d-flex align-items-center mg-t-20 mg-r-20">
                <div class="offset-6 col-2 text-right">{{'Aggregation' | translate}}:&nbsp;</div>
                <div class="col-4">
                    <select class="custom-select" [(ngModel)]="aggregation"
                        (ngModelChange)="changedAggregation($event)">
                        <option [value]="'DAYS'">{{'DAILY' | translate}}</option>
                        <option [value]="'WEEKS'">{{'WEEKLY' | translate}}</option>
                        <option [value]="'MONTHS'">{{'MONTHLY' | translate}}</option>
                    </select>
                </div>
            </div>

            <div class="chart-container" [style.width.%]="97" [style.height.px]="350" #ContainerRef>
                <!-- TODO: COMPOCHART -->
                <ng-container *ngIf="entity.length; else noResults">

                    <ngx-charts-bar-vertical [view]="[ContainerRef.offsetWidth, 350]" [results]="barChart"
                        [scheme]="comboBarScheme" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis"
                        [legend]="showLegend" [legendTitle]="legendTitle" [legendPosition]="legendPosition"
                        [showGridLines]="showGridLines" [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
                        [autoScale]="true"></ngx-charts-bar-vertical>


                    <ngx-charts-line-chart [view]="[ContainerRef.offsetWidth - 80, 270]" [results]="lineChartSeries"
                        [scheme]="lineChartScheme" [xAxis]="false" [yAxis]="false" [legend]="false" [autoScale]="true"
                        class="overlay-chart"></ngx-charts-line-chart>
                </ng-container>
                <!-- <combo-chart-component *ngIf="entity.length; else noResults" [view]="[ContainerRef.offsetWidth, 300]"
                    [scheme]="comboBarScheme" [colorSchemeLine]="lineChartScheme" [results]="barChart"
                    [animations]="animations" [lineChart]="lineChartSeries" [yAxisTickFormatting]="yLeftTickFormat"
                    [yLeftAxisScaleFactor]="yLeftAxisScale" [yRightAxisScaleFactor]="yRightAxisScale"
                    [yRightAxisTickFormatting]="yRightTickFormat" [gradient]="gradient" [xAxis]="showXAxis"
                    [yAxis]="showYAxis" [legend]="showLegend" [legendTitle]="legendTitle"
                    [legendPosition]="legendPosition" [showGridLines]="showGridLines" [showXAxisLabel]="showXAxisLabel"
                    [showYAxisLabel]="showYAxisLabel" [showRightYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
                    [yAxisLabel]="yAxisLabel" [yAxisLabelRight]="yAxisLabelRight">
                </combo-chart-component> -->
            </div>

            <ngx-datatable *ngIf="entity.length" id="distances" class="bootstrap" [rows]="entity" [columns]="columns"
                [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="50" [messages]="tableMessages"
                [sortType]="'single'" [rowHeight]="'auto'" [limit]="20" [externalPaging]="false" [rowClass]="rowClass"
                [scrollbarH]="scrollBarHorizontal">

                <ng-container *ngFor="let col of columns">
                    <ngx-datatable-column [flexGrow]="col.flexGrow" [prop]="col.prop">
                        <ng-template let-column="column" ngx-datatable-header-template class="header" let-sort="sortFn">
                            <b (click)="sort()">{{ col.name | translate }}</b>
                        </ng-template>
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            <div [ngSwitch]="col.prop">
                                <div *ngSwitchCase="'date'">
                                    {{value}}
                                </div>
                                <div *ngSwitchCase="'distance'">
                                    {{(value / 1000).toFixed(2) | number}}km
                                </div>
                                <div *ngSwitchCase="'averageDistance'">
                                    {{(value / 1000).toFixed(2) | number}}km
                                </div>
                                <div *ngSwitchCase="'averageDryBoxCount'">{{(value).toFixed(2) | number}}</div>
                                <div *ngSwitchCase="'averageChilledBoxCount'">{{(value).toFixed(2) | number}}</div>
                                <div *ngSwitchCase="'averageFrozenBoxCount'">{{(value).toFixed(2) | number}}</div>
                                <div *ngSwitchDefault>{{value | number}}</div>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                </ng-container>
            </ngx-datatable>


        </div>

        <div *ngIf="loader" class="load-spinner text-center">
            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>&nbsp;{{ 'Please, wait!' | translate }}
        </div>

        <ng-template #noResults>
            <div class="load-spinner text-center">
                <i-feather [style.width.px]="20" [style.height.px]="18" name="alert-triangle"></i-feather>&nbsp;{{ 'No
                results to show' | translate }}!
            </div>
        </ng-template>



    </div>
</layout-default>