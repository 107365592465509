import { Injectable } from '@angular/core';
import { ShiftIdInterface } from '@interfaces/shiftId.interface';
import { ShiftType } from '@enums/enum';
import * as moment from 'moment';
// import { AppService } from './app.service';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {

  private static DEPOT = 'depot';
  private static PREFFERED_SHIFT_ID = 'preferredShiftId';
  private static PREFFERED_CUTOFF = 'preferredCutoff';

  constructor(
    //private readonly appService: AppService
  ) { }


  /*
  *   Fetching saved preferred shift id from local storage. 
  *   Preferred shifts User can define when changed the shifts in shift pickers in application.
  */
  public getPreferredShift(): ShiftIdInterface {
    const preferredShiftId = localStorage.getItem(LocalStorageService.PREFFERED_SHIFT_ID);
    const [y, m, d, sh] = preferredShiftId ? preferredShiftId.split('/') : [undefined, undefined, undefined, undefined];
    const depot = localStorage.getItem(LocalStorageService.DEPOT);
    const date = moment();

    return {
      year: y ? y : date.format('YYYY'),
      month: m ? m : date.format('MM'),
      day: d ? d : date.format('DD'),
      shiftType: sh ? sh as ShiftType : undefined,
      depot: depot
    } as ShiftIdInterface;
  }

  /*
  *   Save preferred shift identifier in local storage
  */
    public setPreferredshift(shift: string): void {
      localStorage.setItem(LocalStorageService.PREFFERED_SHIFT_ID, shift);
    }

  /*
  *   Fetching depot identifier from local storage. 
  */
  public getDepot(): string {
    const depot = localStorage.getItem(LocalStorageService.DEPOT);
    return depot ? depot : undefined;
  }

  /*
  *   Save depot identifier in local storage
  */
  public setDepot(depot: string): void {
    localStorage.setItem(LocalStorageService.DEPOT, depot);
  }

  /*
  *   Fetching prefered cutoff from local storage. 
  */
  public getPreferedCutoff(): string {
    const cutoff = localStorage.getItem(LocalStorageService.PREFFERED_CUTOFF);
    return cutoff ? cutoff : undefined;
  }

  /*
  *   Save prefered cutoff identifier in local storage
  */
  public setPreferedCutoff(cutoff: string): void {
    if (!cutoff) {
      localStorage.removeItem(LocalStorageService.PREFFERED_CUTOFF);
    } else {
      localStorage.setItem(LocalStorageService.PREFFERED_CUTOFF, cutoff);
    }
  }
}
