import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ApmService } from '../../services/apm.service';
import { Observable } from 'rxjs';
import { ApmMaintenance, ApmMaintenanceType, ApmMaintenanceOrigin } from '../../interfaces/apm-maintenance.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { Apm } from '../../entities/apm';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DateRange } from '@enums/enum';
import * as moment from 'moment';
import { NavService } from '@services/nav.sevice';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { Permissions } from '@enums/permissions';

@Component({
  selector: 'app-apm-maintenance',
  templateUrl: './apm-maintenance.component.html',
  styleUrls: ['./apm-maintenance.component.scss']
})
export class ApmMaintenanceComponent implements OnInit {

  private apm: Apm;
  public apmMaintenance$: Observable<ApmMaintenance>;

  public columns = [
    { prop: 'slot.from', name: this.translateService.instant('From'), flexGrow: 2 },
    { prop: 'slot.to', name: this.translateService.instant('To'), flexGrow: 2 },
    { prop: 'type', name: this.translateService.instant('Type'), flexGrow: 2 },
    { prop: 'origin', name: this.translateService.instant('Origin'), flexGrow: 2 },
    { prop: 'capacity', name: this.translateService.instant('Capacity'), flexGrow: 2 },
    { prop: 'options', name: this.translateService.instant('Options'), flexGrow: 1 },
  ];

  public modifiedRow: string;
  public rowClass;
  public locale = 'pl';

  public loader: boolean = true;
  public apmMaintenanceType = ApmMaintenanceType;
  public apmMaintenanceOrigin = ApmMaintenanceOrigin;

  public startDate;
  public endDate;
  public dateRange: DateRange;
  public Permissions = Permissions;


  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  constructor(
    private readonly apmService: ApmService,
    private readonly navigationRoute: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly navService: NavService,
    private readonly modalService: BsModalService,
    private toastrProviderService: ToastrProviderService,
    private readonly router: Router
  ) { }

  public ngOnInit() {
    this.translateService.onLangChange.subscribe((lang: LangChangeEvent) => {
      this.locale = lang.lang;
    });
    
    this.apm = (this.navigationRoute.snapshot.data as {apm: any}).apm;
    this.modifiedRow = this.navigationRoute.snapshot.params.modifiedRow;

    this.rowClass = row => {
      return { 'row-modified': row.id === this.modifiedRow };
    };

  }

  private checkApmMaintenanceWindow() {
    if (this.startDate && this.endDate) {
      this.apmMaintenance$ = this.apmService.getApmMaintenanceWindow(this.apm.id, this.startDate, this.endDate);
      this.loader = false;
    }
  }

  public changedDateRange(event) {
    this.loader = true;
    this.startDate = moment(event.dateStart).format('YYYY-MM-DD');
    this.endDate = moment(event.dateEnd).format('YYYY-MM-DD');
    this.dateRange = event.dateRange;
    this.checkApmMaintenanceWindow();
  }

  public addApmMaintenanceWindow() {
    this.router.navigate(['/apm', 'maintenance', this.apm.id, 'create'], {queryParams: {selectedApms: [this.apm.id]}});
  }

  public goToDetails() {
    this.navService.goToPage(`/apm/maintenance/${this.apm.id}/edit`);
  }

  public isOptionsColumn(status: string): boolean {
    return status === 'options';
  }

  public removeTimeslot(id: string): void {
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.apmService.deleteMaintenanceWindow(id);
  }

  public confirm(): void {
    this.confirmObservable.subscribe((test) => {
      this.modalRef.hide();
      this.checkApmMaintenanceWindow();      
      this.toastrProviderService.showSuccess(this.translateService.instant('The RLM maintenance slot window has been removed!'));
      this.confirmObservable = null;
    });
  }

}
