import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { tap, take, switchMap, map, delay } from 'rxjs/operators';
import { RouteExt } from '@entities/route-ext';
import { Client, mapOptions, mapTypes } from '@enums/enum';
import { environment } from '@environment';
import { Depot } from '@interfaces/depot.interface';
import { LocalStorageService } from '@services/local-storage.service';
import { DepotService } from '../../../locations/services/depot.service';
import { DashboardMapVehicle } from '../../interfaces/dashboard-map-vehicle';
import { DashboardDepotPunctuality } from '../../interfaces/DashboardPunctuality';
import { DashboardService } from '../../services/dashboard.service';
import { dashboardMapStyles, dashboardSettingsInitial } from './dashboardConstants';
import { AppService } from '@services/app.service';
import { UserExt } from '@interfaces/user-ext.interface';
import { DashboardSettings } from '../../interfaces/dashboard-settings';
import { TranslateService } from '@ngx-translate/core';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { GoogleMapsLoaderService } from '@services/maps/googleMapsLoader.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements AfterViewInit, OnDestroy {
    @ViewChild('mapComponent') mapComponent!: GoogleMap; // Reference to the GoogleMap component
    @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow; // Reference to the MapInfoWindow component
    @ViewChild(MapMarker) marker!: MapMarker;

    public vehicles: DashboardMapVehicle[] = [];

    private animationIntervals = [];

    public mapOption = mapOptions.REGULAR;
    public mapProperties = {
        zoom: 10,
        mapTypeId: mapTypes.ROADMAP,
    };

    polylineOptions: google.maps.PolylineOptions = {
        strokeColor: '#000',
        strokeOpacity: 0.7,
        strokeWeight: 1,
    };

    public mapStyles = dashboardMapStyles;

    public selectedVehicle?: DashboardMapVehicle;

    public loader = true;

    private polling;
    private depotCyclingInterval;

    public punctuality$ = new Subject<DashboardDepotPunctuality[]>();

    public selectedDepotId: string;
    public displayedDepotId: string;
    public depot$ = new Subject<Depot>();
    private depots$: Observable<Depot[]>;

    public userAppSettings$: Observable<DashboardSettings | undefined>;

    private depotList: Depot[];

    public client = environment.client.toLowerCase();
    public inpostClient: string = Client.INPOST.toLowerCase();
    public friscoClient: string = Client.FRISCO.toLowerCase();
    private assetsPath: string = '/assets/img';
    public markerPath: string = `${this.assetsPath}/markers`;

    public settings: DashboardSettings;
    public settingsOpen = false;
    public settingsForm: FormGroup;

    public historyOpen = true;
    private isPollingActive = false;

    constructor(private readonly dashboardService: DashboardService, private readonly localStorage: LocalStorageService, private readonly router: Router, private readonly depotService: DepotService, private readonly route: ActivatedRoute, private readonly fb: FormBuilder, private toastrProviderService: ToastrProviderService, private readonly translate: TranslateService, private readonly appService: AppService, private mapsLoader: GoogleMapsLoaderService) {}

    public async ngAfterViewInit() {
        const apiKey = environment.googleMapApiKey;
        await this.mapsLoader
            .loadApi(apiKey)
            .then(() => {
                // this.fitMapToMarkers();
                // this.isMapLoaded = true; // Ustaw flagę, aby wyświetlić mapę
            })
            .catch((error) => {
                console.error('Błąd ładowania Google Maps:', error);
            });

        // Ensure `infoWindow` is initialized in `AfterViewInit` lifecycle hook
        if (!this.infoWindow) {
            console.warn('InfoWindow not initialized');
        }

        this.selectedDepotId = this.localStorage.getDepot();
        this.depots$ = this.depotService.getDepotsForUser();

        this.depots$
            .pipe(
                tap((depots) => {
                    this.depotList = depots;
                    this.depot$.next(depots.find((d) => d.id + '' === this.selectedDepotId));
                    this.displayedDepotId = this.selectedDepotId;
                })
            )
            .subscribe();

        this.dashboardService
            .getVehiclesPosition(this.selectedDepotId, this.settings)
            .pipe(
                tap((vehicles: DashboardMapVehicle[]) => (this.vehicles = vehicles)),
                tap(() => (this.loader = false)),
                delay(500),
                tap(() => this.fitMapToMarkers())
            )
            .subscribe();

        this.userAppSettings$ = this.appService.userAppSettings.pipe(
            map((userExt: UserExt) => {
                this.settings = userExt.adminAppSettings && userExt.adminAppSettings.hasOwnProperty('dashboardSettings') ? userExt.adminAppSettings.dashboardSettings : dashboardSettingsInitial;
                return this.settings;
            }),
            tap(() => this.init())
        );

        this.checkIfUserIsActive();
    }

    public init() {
        Object.keys(this.settings).forEach((key) => {
            if (['startsWithin', 'endsWithin'].includes(key)) {
                const settingValue = Number(this.settings[key]);
                this.settings[key] = settingValue ? settingValue : '';
            }
        });

        Object.keys(this.settings).forEach((key) => {
            if (['pollingTimeSeconds', 'depotCyclingTimeSeconds', 'animationTimeSeconds', 'animationFps', 'vehicleScale'].includes(key)) {
                this.settings[key] = Number(this.settings[key]);
            }
        });
        Object.keys(this.settings).forEach((key) => {
            if (['showPartials', 'showTotalsDelivery', 'showTotalsFulfillment', 'showTotalsLoading', 'hideVehiclesWithoutLastStop', 'hideVehiclesWithoutNextStop'].includes(key)) {
                this.settings[key] = this.settings[key] === 'true' || this.settings[key] === true;
            }
        });

        this.settingsForm = this.fb.group({
            mode: [''],
            pollingTimeSeconds: [''],
            depotCyclingTimeSeconds: [''],
            animationTimeSeconds: [''],
            animationFps: [''],
            vehicleScale: [''],
            earlyVehicleColor: [''],
            okVehicleColor: [''],
            lateVehicleColor: [''],
            unknownVehicleColor: [''],
            showPartials: [''],
            showTotalsDelivery: [''],
            showTotalsFulfillment: [''],
            showTotalsLoading: [''],
            hideVehiclesWithoutLastStop: [''],
            hideVehiclesWithoutNextStop: [''],
            startsWithin: [''],
            endsWithin: [''],
        });

        this.settingsForm.patchValue(this.settings);
        this.reloadCharts();
        this.startPolling();
    }

    private clearAnimationIntervals() {
        this.animationIntervals.forEach((i) => {
            clearInterval(i);
        });
    }

    /**
     * Starts the polling process for reloading vehicles and charts at a regular interval.
     * This method checks if polling is already active, and if not, it initializes the polling process.
     *
     * The polling interval is defined by `this.settings.pollingTimeSeconds`. If the application is in
     * 'presentation' mode, an additional cycling interval is started to automatically switch between
     * depots at a defined interval (`this.settings.depotCyclingTimeSeconds`).
     *
     * This method does the following:
     * - Starts the main polling interval if not already active.
     * - In 'presentation' mode, starts a secondary interval for cycling through depots and reloading vehicles for each depot.
     * - Sets `isPollingActive` to `true` to indicate that polling is active.
     *
     * @private
     */
    private startPolling(): void {
        if (!this.isPollingActive) {
            if (this.settings.pollingTimeSeconds < 240) {
                this.settings.pollingTimeSeconds = 240;
            }
            // Start the main polling interval
            this.polling = setInterval(() => {
                this.reloadVehicles(this.displayedDepotId);
                this.reloadCharts();
            }, this.settings.pollingTimeSeconds * 1000);

            if (this.settings.depotCyclingTimeSeconds < 240) {
                this.settings.depotCyclingTimeSeconds = 240;
            }
            // If in presentation mode, start the depot cycling interval
            if (this.settings.mode === 'presentation') {
                this.depotCyclingInterval = setInterval(() => {
                    const currentIndex = this.depotList.findIndex((d) => d.id + '' === this.displayedDepotId);
                    const nextIndex = (currentIndex + 1) % this.depotList.length;
                    this.displayedDepotId = this.depotList[nextIndex].id + '';
                    this.depot$.next(this.depotList[nextIndex]);
                    this.reloadVehicles(this.displayedDepotId, true);
                }, this.settings.depotCyclingTimeSeconds * 1000);
            }

            // Mark polling as active
            this.isPollingActive = true;
        }
    }

    /**
     * Stops the polling process that was previously started by `startPolling()`.
     *
     * This method performs the following actions:
     *
     * - Checks if polling is currently active by evaluating the `isPollingActive` flag.
     * - If polling is active:
     *   - Clears the main polling interval to stop further reloads of vehicle data and charts.
     *   - Clears the depot cycling interval (if it was set) to stop automatically switching depots in 'presentation' mode.
     *   - Calls `clearAnimationIntervals()` to clear any additional animation-related intervals that might be active.
     *   - Resets the `isPollingActive` flag to `false`, indicating that polling is no longer active.
     *
     * This method ensures that no further polling actions occur until `startPolling()` is called again.
     *
     * @private
     */
    private stopPolling(): void {
        if (this.isPollingActive) {
            // Clear the main polling interval
            clearInterval(this.polling);
            // Clear the depot cycling interval, if active
            clearInterval(this.depotCyclingInterval);
            // Clear any additional animation intervals
            this.clearAnimationIntervals();
            // Mark polling as inactive
            this.isPollingActive = false;
        }
    }

    /**
     * Adjusts the map's bounds to include all markers in the positions array.
     * Uses LatLngBounds to determine the bounding box and fitBounds on the map to set
     * the optimal zoom and center to show all points.
     */
    private fitMapToMarkers(markersPositions?: google.maps.LatLngLiteral[]): void {
        const bounds = new google.maps.LatLngBounds();
        // Extend bounds for each position in the array
        if (markersPositions) {
            markersPositions.forEach((point) => bounds.extend(point));
        } else {
            this.vehicles.forEach((point) => bounds.extend(point.position));
        }

        // Use fitBounds to adjust the map's view
        if (this.vehicles.length > 1 && this.mapComponent && this.mapComponent.googleMap) {
            this.mapComponent.googleMap.fitBounds(bounds);
        }
    }

    /**
     * Monitors the user's activity by detecting when the user switches between tabs or minimizes the browser window.
     *
     * This method listens for the `visibilitychange` event on the document. When this event is triggered, the method
     * determines whether the page is currently visible or hidden:
     *
     * - If the page becomes visible (`document.visibilityState === 'visible'`):
     *   - Logs a message indicating that the user is active on the page.
     *   - Calls `startPolling()` to resume any previously stopped polling processes.
     *
     * - If the page is hidden (`document.visibilityState === 'hidden'`):
     *   - Logs a message indicating that the user has switched to another tab or minimized the window.
     *   - Calls `stopPolling()` to halt ongoing polling processes while the user is inactive.
     *
     * This method ensures that resource-intensive operations like polling only occur when the user is actively viewing the page.
     *
     * @private
     */
    private checkIfUserIsActive(): void {
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                console.log('User is active on the page');
                this.startPolling();
            } else {
                console.log('User switched to another tab or minimized the window');
                this.stopPolling();
            }
        });
    }

    public reloadVehicles(depotId: string, forceReload?: boolean) {
        this.dashboardService
            .getVehiclesPosition(depotId, this.settings)
            .pipe(
                tap((updatedVehicles: DashboardMapVehicle[]) => {
                    this.clearAnimationIntervals();

                    const anyVehicleNotPresentAfterUpdate = this.vehicles.some((v) => !updatedVehicles.some((v2) => v2.vehicleId === v.vehicleId && v2.shiftId === v.shiftId));
                    const anyVehicleNotPresentBeforeUpdate = updatedVehicles.some((v) => !this.vehicles.some((v2) => v.vehicleId === v2.vehicleId && v.shiftId === v2.shiftId));

                    if (forceReload || anyVehicleNotPresentAfterUpdate || anyVehicleNotPresentBeforeUpdate) {
                        this.unselectVehicles();
                        this.vehicles = updatedVehicles;
                    } else {
                        this.vehicles.forEach((marker) => {
                            const updated = updatedVehicles.find((uv) => uv.vehicleId === marker.vehicleId && uv.shiftId === marker.shiftId && uv.routeId === marker.routeId && uv.route === marker.route);
                            if (updated) {
                                this.animateMarkerTo(marker, updated.position);
                                marker.lastStop = updated.lastStop;
                                marker.nextStop = updated.nextStop;
                            }
                        });
                    }
                }),
                tap(() => (this.loader = false))
            )
            .subscribe();
    }

    public reloadCharts() {
        const depotId = this.settings.mode === 'presentation' ? null : this.selectedDepotId;

        this.dashboardService.getPunctuality(depotId, this.settings).subscribe((result) => {
            this.punctuality$.next(result);
        });
    }

    private animateMarkerTo(marker: DashboardMapVehicle, newPosition: { lat: number; lng: number }) {
        if (!newPosition) {
            marker.position = undefined;
        }

        if (!marker.position) {
            marker.position = newPosition;
        } else {
            const easingFunction = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

            const originalLat = marker.position.lat;
            const originalLng = marker.position.lng;

            const diffLat = newPosition.lat - originalLat;
            const diffLng = newPosition.lng - originalLng;

            const animationMillis = 1000 * this.settings.animationTimeSeconds;
            const animationFrames = this.settings.animationTimeSeconds * this.settings.animationFps;

            const millisPerFrame = animationMillis / animationFrames;
            const lastFrame = animationFrames - 1;

            let frame = 0;
            const interval = setInterval(() => {
                const newLat = originalLat + diffLat * easingFunction((frame + 1) / animationFrames);
                const newLng = originalLng + diffLng * easingFunction((frame + 1) / animationFrames);
                marker.position = {
                    lat: newLat,
                    lng: newLng,
                };
                if (frame === lastFrame) {
                    clearInterval(interval);
                }
                frame++;
            }, millisPerFrame);

            this.animationIntervals.push(interval);
        }
    }

    /**
     * Opens the info window with the specified content.
     * Sets the position of the info window to match the clicked marker's position.
     * @param point The point containing the info to display
     */
    selectVehicle(marker: DashboardMapVehicle) {
        this.selectedVehicle = marker;

        // Set the position of the info window and open it
        if (this.infoWindow) {
            this.infoWindow.options = { position: marker.position }; // Set info window position
            this.infoWindow.open();
            const markers = [this.selectedVehicle.position, this.selectedVehicle.nextStop];
            this.fitMapToMarkers(markers);

            this.infoWindow.infoWindow?.addListener('closeclick', () => {
                this.unselectVehicles();
            });
        } else {
            console.warn('InfoWindow is not available');
        }
    }

    public random() {
        return 1 + Math.floor((10 - 1) * Math.random());
    }

    public unselectVehicles() {
        this.selectedVehicle = undefined;
        this.fitMapToMarkers();
        if (this.infoWindow) {
            this.infoWindow.close();
        }
    }

    public ngOnDestroy(): void {
        this.stopPolling();
    }

    public getProgressBarWidth(num: number, vehicle: DashboardMapVehicle): string {
        return `${(num * 100) / (vehicle.early + vehicle.OK + vehicle.late)}%`;
    }

    public driverUrl(route: RouteExt) {
        return ['/routes', ...route.date.split('-'), route.shift, route.warehouseId, 'route', route.id];
    }

    public driverUrlFromDriver(vehicle: DashboardMapVehicle, depotId: string) {
        const date = vehicle.routeId.substr(0, 10);
        return ['/routes', ...date.split('-'), vehicle.shiftId, depotId, 'route', vehicle.route];
    }

    public displayDepotName(depotId: string) {
        const depot = this.depotList.find((d) => d.id + '' === depotId);
        if (!depot) {
            return depotId;
        }
        return depot.name;
    }

    public submitSettingsForm(defaultSettings?) {
        const dashboardSettings = !defaultSettings ? this.settingsForm.getRawValue() : defaultSettings;
        this.appService.userAppSettings
            .pipe(
                take(1),
                switchMap((userExt: UserExt) => {
                    if (userExt.adminAppSettings === null) {
                        Object.assign(userExt, { adminAppSettings: {} });
                    }
                    Object.assign(userExt.adminAppSettings, { dashboardSettings });
                    return this.appService.saveUserAppSettings(userExt.adminAppSettings);
                })
            )
            .subscribe(
                () => {
                    window.location.reload();
                },
                () => {
                    this.toastrProviderService.showError(this.translate.instant('There was an error, please try again later'));
                }
            );
    }

    public getCurrentlyDisplayedDepot() {
        return this.depotList.find((d) => d.id + '' === this.displayedDepotId);
    }

    public resetSettings() {
        this.submitSettingsForm(dashboardSettingsInitial);
    }
}
