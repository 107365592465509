import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../authentication.service';
import { Permissions } from '@enums/permissions';

@Directive({
    selector: '[appCheckPrivileges]',
})
export class CheckPrivilegesDirective implements OnInit {

    @Input() public permissions: Permissions[];

    constructor(private elementRef: ElementRef, private authService: AuthenticationService) {}

    public ngOnInit() {
        if (!this.authService.checkPrivileges(this.permissions)) {
            this.elementRef.nativeElement.remove();
        }
    }
}
