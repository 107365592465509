import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthenticationModule } from '../authentication/authentication.module';
import { SharedModule } from '../shared.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DashboardRouting } from './dashboard.routing';
import { DashboardComponent } from './components/dashboard/dashboard.component';
// import { AgmCoreModule } from '@agm/core';
import { DashboardChartsComponent } from './components/dashboard-charts/dashboard-charts.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DashboardHistoryComponent } from './components/dashboard-history/dashboard-history.component';
import { DashboardDeliverySalesComponent } from './components/dashboard-delivery-sales/dashboard-delivery-sales.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DashboardVehicleDistanceComponent } from './components/dashboard-vehicle-distance/dashboard-vehicle-distance.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { environment } from '@environment';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        TooltipModule.forRoot(),
        RouterModule,
        DashboardRouting,
        AuthenticationModule,
        // AgmCoreModule.forRoot({
        //     apiKey: environment.googleMapApiKey,
        // }),
        ReactiveFormsModule,
        NgxChartsModule,
        NgxDatatableModule,
    ],
    declarations: [DashboardComponent, DashboardChartsComponent, DashboardHistoryComponent, DashboardDeliverySalesComponent, DashboardVehicleDistanceComponent],
    providers: [],
})
export class DashboardModule {}
