import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment';
import { Window } from 'projects/inpost/src/app/operations/entities/apm-delivery';

@Pipe({
    name: 'checkReservationTimeBufforPipe',
    pure: true
})
export class CheckReservationTimeBufforPipe implements PipeTransform {
    public transform(window: Window): boolean {
        const now = moment();
        const reservationTime = moment(`${window.from}`);
        return reservationTime.diff(now) / 1000 / 60 / 60 > -24;
    }
}
