import locale from 'date-fns/locale/pl';

export let datePickerOptions: any = {
    minYear: 1970,
    maxYear: 2030,
    format: 'D MMMM YYYY',
    formatTitle: 'MMMM YYYY',
    formatDays: 'dd',
    firstCalendarDay: 1,
    locale: locale
};