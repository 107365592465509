import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PartnerService } from '../../services/partner.service';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '@services/nav.sevice';
import { CRUD_MODE } from '@enums/enum';
import { Partner } from '../../entities/partner';
import { Address } from '@entities/address';
import * as _ from 'lodash';
import { Additional } from '@entities/additional';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { tooltipLabel } from '@shared/functions/tooltip-labels.function';
import { version } from 'punycode';

@Component({
  selector: 'app-edit-partner',
  templateUrl: './edit-partner.component.html',
  styleUrls: ['./edit-partner.component.scss']
})
export class EditPartnerComponent implements OnInit {

  public readonly codeFieldPrefix = 'RLMC';
  public partner: Partner;
  public mode: CRUD_MODE;

  public form: FormGroup;
  public loader: boolean = true;
  public submitted: boolean = false;


  get addressKeys() {
    const address = new Address();
    return _.keys(address);
  }

  get additionalKeys() {
    const additional = new Additional();
    return _.keys(additional);
  }

  constructor(
    private navigationRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private partnerService: PartnerService,
    private toastrProviderService: ToastrProviderService,
    private translateService: TranslateService,
    private navService: NavService
  ) { }

  public ngOnInit() {
    this.partner = (this.navigationRoute.snapshot.data as {apm: any}).apm;
    this.mode = (this.partner) ? CRUD_MODE.UPDATE : CRUD_MODE.CREATE;
    this.initForm();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      address: this.formBuilder.group({
        country: [this.partner ? this.partner.address.country : 'Polska', [Validators.required, Validators.maxLength(255)]],
        district: [this.partner ? this.partner.address.district : null, [Validators.required, Validators.maxLength(255)]],
        houseNO: [this.partner ? this.partner.address.houseNO : null, [Validators.required, Validators.maxLength(255)]],
        street: [this.partner ? this.partner.address.street : null, [Validators.required, Validators.maxLength(255)]],
        town: [this.partner ? this.partner.address.town : null, [Validators.required, Validators.maxLength(255)]],
        voivoidship: [this.partner ? this.partner.address.voivoidship : null, [Validators.required, Validators.maxLength(255)]],
        zip: [this.partner ? this.partner.address.zip : null, [Validators.required, Validators.pattern('[0-9]{2}\-[0-9]{3}')]]
      }),
      locationAdditional: this.formBuilder.group({
        companyName: [null, Validators.maxLength(255)],
        flatNO: [null, Validators.maxLength(255)],
        lift: [null, Validators.maxLength(255)],
        staircaseNO: [null, Validators.maxLength(255)],
        floor: [null, Validators.maxLength(255)],
        contactName: [null, Validators.maxLength(255)],
        contactPhoneNO: [null, [Validators.required, Validators.maxLength(255)]],
        domofonCode: [null, Validators.maxLength(255)],
        instructions: [null, Validators.maxLength(255)],
        contactEmail: [null, [Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')]]
      }),
      doWFilter: this.formBuilder.group({
        mon: [this.partner ? this.partner.doWFilter.mon : false, Validators.required],
        tue: [this.partner ? this.partner.doWFilter.tue : false, Validators.required],
        wed: [this.partner ? this.partner.doWFilter.wed : false, Validators.required],
        thu: [this.partner ? this.partner.doWFilter.thu : false, Validators.required],
        fri: [this.partner ? this.partner.doWFilter.fri : false, Validators.required],
        sat: [this.partner ? this.partner.doWFilter.sat : false, Validators.required],
        sun: [this.partner ? this.partner.doWFilter.sun : false, Validators.required],
      }),
      code: [this.partner ? this.partner.code : 'RLMC', [Validators.required, Validators.pattern('\R\L\M\C[0-9]{4}'), Validators.maxLength(32)]],
      id: [this.partner ? this.partner.id : null],
      name: [this.partner ? this.partner.name : null, [Validators.required, Validators.maxLength(64)]],
      shortName: [this.partner ? this.partner.shortName : null, [Validators.required, Validators.maxLength(16)]],
      senderEmail: [this.partner ? this.partner.senderEmail : null, [Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')]],
      version: [this.partner ? this.partner.version : null, Validators.maxLength(255)]
    });

    const locationAdditional = this.form.controls[ 'locationAdditional' ];

    if (this.partner) {
      locationAdditional.patchValue((this.partner.locationAdditional !== null) ? this.partner.locationAdditional : {});
    }

    this.loader = false;
  }

  public regularFormItems(): string[] {
    return ['name', 'shortName', 'code', 'senderEmail']
  }

  public weekDaysControls(): string[] {
    return ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
  }

  public tooltipLabel(property: string): string {
    return tooltipLabel(this.translateService, `tooltips.partner.labels.${property}`);
  }


  public onSubmit() {

    const raw = this.form.getRawValue();
    raw['code'] = raw['code'].trim();

    if (this.mode === CRUD_MODE.CREATE) {
      this.create(raw);
    } else {
      this.update(raw);
    }
  }

  private create(raw): void {
    this.submitted = true;
    delete raw['version'];
    delete raw['id'];
    this.partnerService.save(raw)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (partner: Partner) => {
          console.log(partner);
          this.toastrProviderService.showSuccess(this.translateService.instant('New partner has been created!'));
          this.navService.goToPage('/partner/list', {modifiedRow: partner.id});
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }

  private update(raw): void {
    this.submitted = true;
    this.partnerService.update(raw['id'], raw)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (partner: Partner) => {
          console.log(partner);
          this.toastrProviderService.showSuccess(this.translateService.instant('The partner has been updated!'));
          this.navService.goToPage('/partner/list', {modifiedRow: partner.id});
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }

}
