<div class="shift-settings" [ngClass]="{'test-mode': mode === 'TEST'}">

    <div *ngIf="mode === 'TEST'" class="d-flex justify-content-between align-items-center mg-t-20">
        <div>
            <h6 *ngIf="mode !== 'SAVE'" class="tx-11 text-center tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-b-0">{{'Shift settings' | translate}} {{shift?.id}} - {{'That operation wont store your settings in database only will show new calculated routes' | translate}}.</h6>
            <h6 *ngIf="mode === 'SAVE'" class="tx-11 text-center tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-b-0">{{'Shift settings' | translate}} {{shift?.id}} - {{'That operation will store your settings in database' | translate}}.</h6>
        </div>
        <div class="c-pointer" (click)="close()">
            <i-feather name="X"></i-feather>
        </div>
    </div>

    <form *ngIf="!loading" [formGroup]="form">
    
        <!-- VEHICLES -->
        <label class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-b-10">{{'VEHICLES' | translate}}:</label>
        <div class="d-flex justify-content-start">
            <div 
                *ngFor="let item of ['routeMargin', 'departureWaveInitialSize', 'departureWaveInterval', 'departureWaveSize']" 
                class="item mg-r-10">
                    <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{item | translate}}:</label>
                    <input type="number" [formControlName]="item" class="form-control w-100" />
            </div>
        </div>

        <label class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-b-10">{{'DELIVERY TIME' | translate}}:</label>
        <div class="d-flex justify-content-start flex-wrap">
            <ng-container formArrayName="deliveryTime">
                <div *ngFor="let item of deliveryTimeControls" class="item mg-r-10">
                    <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0" [for]="item">{{ item | translate }}:</label>
                    <input type="number" [formControlName]="item" class="form-control w-100" />
                </div>
            </ng-container>
        </div>
        <!-- VEHICLES -->
        <label class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-b-10">{{'SHIFT PLANNING' | translate}}:</label>
        
        <div class="d-flex justify-content-start flex-wrap">

            <div *ngFor="let item of ['autoFulfill', 'includeReturnInPlanning']" class="item mg-r-10">
                <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0" [for]="item">{{ item | translate }}:</label>
                <select [id]="item" class="custom-select" [formControlName]="item">
                    <option value="true">{{ 'True' | translate }}</option>
                    <option value="false">{{ 'False' | translate }}</option>
                </select>
            </div>

            <div 
                *ngFor="let item of ['expensiveDeliveryThreshold', 'expensiveDeliveryThresholdTime', 'reloadPercentage', 'reloadTimeMinutes', 'routeMaximumReloads', 'routeNumberOffset', 'segmentNumberOffset', 'shiftEndMarginMinutes', 'shiftMaximumSegments', 'shiftStartMarginMinutes', 'slotEndMarginMinutes', 'slotStartMarginMinutes', 'maxCostFactor']" 
                class="item mg-r-10">
                    <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{item | translate}}:</label>
                    <input type="number" [formControlName]="item" class="form-control w-100" />
            </div>

            <div class="item mg-r-10">
                <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0" for="shiftplanType">{{ 'shiftplanType' | translate }}:</label>
                <select id="shiftplanType" class="custom-select" formControlName="shiftplanType">
                    <option *ngFor="let type of shiftplanTypeEnum" [value]="type">{{ type }}</option>
                </select>
            </div>

            <!-- <div class="item mg-r-10">
                <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0" for="routeStrategy">{{ 'routeStrategy' | translate }}:</label>
                <select id="routeStrategy" class="custom-select" formControlName="routeStrategy">
                    <option *ngFor="let strategy of routeStrategy" [value]="strategy">{{ strategy }}</option>
                </select>
            </div> -->

            <div class="item mg-r-10">
                <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0" for="planningSequence">{{ 'planningSequence' | translate }}:</label>
                <select id="planningSequence" class="custom-select" formControlName="planningSequence">
                    <option *ngFor="let planning of (planningSequence | async); let i = index" [value]="planning.id">{{ planning.name | translate }}</option>
                </select>
            </div>

            <div 
                class="item mg-r-10">

                    <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{'maximumProductLines' | translate}}:</label>

                    <div class="d-flex align-items-center justify-content-between" [style.height.px]="36">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" [checked]="maxProductLinesCheckbox" id="customSwitch1" (change)="maxProductLinesCheckboxFun()">
                            <label class="custom-control-label" for="customSwitch1">&nbsp;</label>
                        </div>
                        <div *ngIf="maxProductLinesCheckbox">
                            <input type="number" formControlName="maximumProductLines" class="form-control w-100" />
                        </div>
                    </div>
                    
            </div>
        </div>

        <!-- BOOKING -->
        <label class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-b-10">{{'BOOKING & CUTOFF' | translate}}:</label>

        <div class="d-flex justify-content-start flex-wrap">
                    
            <div *ngFor="let item of ['bookingWindowRestricted']" class="item mg-r-10">
                <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0" [for]="item">{{ item | translate }}:</label>
                <select [id]="item" class="custom-select" [formControlName]="item">
                    <option value="true">{{ 'True' | translate }}</option>
                    <option value="false">{{ 'False' | translate }}</option>
                </select>
            </div>

            <div 
                *ngFor="let item of ['bookingWindowDayOffset', 'bookingWindowTimeOfDay', 'offset', 'time']" 
                class="item mg-r-10">
                    <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{item | translate}}:</label>
                    <input type="number" [formControlName]="item" class="form-control w-100" />
            </div>

            <div *ngFor="let item of ['startTime', 'endTime']" class="item mg-r-10">
                <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0" [for]="item">{{ item | translate }}:</label>
                <input type="text" [formControlName]="item" class="form-control w-100" />
                <div *ngIf="form.get(item).hasError('pattern') && form.get(item).touched" class="error-color">{{ 'Proper format: [HH:mm:ss]' | translate }}</div>
            </div>



        </div>

        <!-- BOXES -->
        <div class="d-flex justify-content-start flex-fill" *ngIf="(vehicleTypes$ | async) as vehicleTypes">
            <div class="w-100">
                <!-- VAN LIMITS -->
                <ng-container formArrayName="fleet">
                    <label class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-b-10 mg-r-10">{{'Fleet' | translate}}:</label>
                    <button (click)="addFleet()" class="d-inline btn btn-sm btn-outline-info pd-0 mg-0" [style.height.px]="20" [style.width.px]="20">
                        <i-feather [style.width.px]="18" [style.height.px]="18" name="plus"></i-feather>
                    </button>
                    <div class="d-flex justify-content-start align-items-center flex-wrap">
                        <ng-container *ngFor="let step of form.controls.fleet.controls; let i=index">
                            <ng-container formGroupName="{{i}}">
                                <div 
                                    class="item-md mg-r-10">
                                        <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{'Vehicle type' | translate}}:</label>
                                        <select [id]="item" class="custom-select" formControlName="vehicleTypeId">

                                            <option *ngFor="let vehicle of vehicleTypes" [value]="vehicle.id">{{ vehicle.code }}</option>
                                        </select>
                                </div>
                                <div 
                                    class="item-md mg-r-10">
                                        <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{'Max' | translate}}:</label>
                                        <input type="number" formControlName="max" class="form-control w-100" />
                                </div>
                                <button (click)="removeVanLimit(i)" class="btn btn-xs btn-outline-warning remove-van-limit align-self-end"><i-feather [style.height.px]="16" name="trash-2"></i-feather></button>
                            </ng-container>
                        </ng-container>

                    </div>
                </ng-container>
            </div>
        </div>

        <div class="d-flex justify-content-start flex-fill">
            <div class="w-50">
                <!-- VAN LIMITS -->
                <ng-container formArrayName="vanLimits">
                    <label class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-b-10 mg-r-10">{{'VAN LIMITS' | translate}}:</label>
                    <button (click)="addVanLimit()" class="d-inline btn btn-sm btn-outline-info pd-0 mg-0" [style.height.px]="20" [style.width.px]="20">
                        <i-feather [style.width.px]="18" [style.height.px]="18" name="plus"></i-feather>
                    </button>
                    <div class="d-flex justify-content-start align-items-center flex-wrap">
                        <ng-container *ngFor="let step of form.controls.vanLimits.controls | sortBy:'desc':'usedPercentage'; let i=index">
                            <ng-container formGroupName="{{i}}">
                                <div 
                                    class="item-sm mg-r-10">
                                        <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{'usedPercentage' | translate}}:</label>
                                        <input type="number" formControlName="usedPercentage" class="form-control w-100" />
                                </div>
                                <div 
                                    class="item-sm mg-r-10">
                                        <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{'blockedSlots' | translate}}:</label>
                                        <input type="number" formControlName="blockedSlots" class="form-control w-100" />
                                </div>
                                <button (click)="removeVanLimit(i)" class="btn btn-xs btn-outline-warning remove-van-limit align-self-end"><i-feather [style.height.px]="16" name="trash-2"></i-feather></button>
                            </ng-container>

                        </ng-container>

                    </div>
                    <div class="error" *ngIf="form.get('vanLimits').errors && form.get('vanLimits').dirty && form.get('vanLimits').errors.validVanLimitMin">
                        {{'Not found van limit used percentage equal 0' | translate}}
                    </div>
                    <div class="error" *ngIf="form.get('vanLimits').errors && form.get('vanLimits').dirty && form.get('vanLimits').errors.validVanLimitMax">
                        {{'Not found van limit used percentage equal 100' | translate}}
                    </div>
                </ng-container>
            </div>
            <div class="w-50">
                <!-- SLOTS LIMITS  -->
                <ng-container formArrayName="slotLimits">
                    <label class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-b-10 mg-r-10">{{'SLOT LIMITS' | translate}}:</label>
                    <button (click)="addSlotLimit()" class="d-inline btn btn-sm btn-outline-info pd-0 mg-0" [style.height.px]="20" [style.width.px]="20">
                        <i-feather [style.width.px]="18" [style.height.px]="18" name="plus"></i-feather>
                    </button>
                    <div class="d-flex justify-content-start align-items-center flex-wrap">
                        <ng-container *ngFor="let step of form.controls.slotLimits.controls; let i=index">
                            <ng-container formGroupName="{{i}}">
                                <div 
                                    class="item-sm mg-r-10">
                                        <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{'slot' | translate}}:</label>
                                        <input type="text" formControlName="key" class="form-control w-100" />
                                </div>

                                <div 
                                    class="item-sm mg-r-10">
                                        <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{'limit' | translate}}:</label>
                                        <input type="number" formControlName="value" class="form-control w-100" />
                                </div>
                                <button (click)="removeSlotLimit(i)" class="btn btn-xs btn-outline-warning remove-van-limit align-self-end"><i-feather [style.height.px]="16" name="trash-2"></i-feather></button>
    
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>

        <div *ngIf="mode === 'TEST'" class="form-group d-flex justify-content-end mg-t-40">

            <button (click)="resetForm()" [style.width.%]="20" class="btn btn-sm btn-uppercase btn-white mg-r-10">Reset custom settings</button>
            
            <button (click)="close()" [style.width.%]="20" class="btn btn-sm btn-outline-info btn-uppercase mg-r-10">{{'Close' | translate}}</button>

            <button (click)="onSubmit()" type="submit" [disabled]="submitted" class="btn btn-sm btn-uppercase btn-brand-01 flex-grow-1">
                <span *ngIf="!submitted && mode === 'TEST'">{{ 'Test shift settings parameters' | translate }}</span>
                <span *ngIf="!submitted && mode === 'SAVE'">{{ 'Save shift settings' | translate }}</span>
                <ng-container *ngIf="submitted">
                    <div class="load-spinner text-center">
                        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                    </div>
                </ng-container>
            </button>


        </div>

    </form>
</div>
