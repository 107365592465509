import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { BehaviorSubject, merge, Observable, Subject, Subscription, of, throwError } from 'rxjs';
import { catchError, map, switchMap, filter, tap } from 'rxjs/operators';
import { Driver } from '@drivers/entities/driver';
import { DriversService } from '@drivers/services/drivers.service';
import { BoxBagSearchResults } from '@entities/boxBagSearchResult';
import { Delivery } from '@entities/delivery';
import { DeliverySearchResult } from '@entities/deliverySearchResult';
import { RouteExt, RouteSummary } from '@entities/route-ext';
import { RouteId } from '@entities/routeId';
import { DriverStatus, VanStatus, RouteStatus, DriversMode } from '@enums/enum';
import { Van } from '@hardware/entities/van';
import { HardwareService } from '@hardware/services/hardware.service';
import { UtilsService } from '@services/utils.service';
import * as envProd from 'projects/frisco/src/environments/environment.prod';
import { environment } from '@environment';
import { FormGroup } from '@angular/forms';
import { ShiftPunctuality } from '@analytics/entities/shift-punctuality';
import { ReportSettings } from '@analytics/entities/report-settings';
import { AppService } from '@services/app.service';
import { LocalStorageService } from '@services/local-storage.service';
import { ShiftPaymentSummary } from '@routes/interfaces/shift-payment-summary.interface';
import { UrlUtil } from '@shared/functions/UrlUtil';
import { PunctualityChart } from 'src/app/dashboard/interfaces/DashboardPunctuality';

@Injectable({
    providedIn: 'root',
})
export class RoutesService {
    private static ROUTES: string = 'shifts/v2/${shiftId}/routes';
    private static ROUTES_SUMMARY: string = 'shifts/v2/${shiftId}/routes/summary?segmented=false';
    private static ROUTE: string = 'route/v3/${routeId}?segmented=true';
    private static GET_PENNDING_DELIVERIES: string = 'delivery/v2/status/PENDING';
    private static MOVE_TO_UNASSIGNED: string = 'route/v3/${routeId}/delivery/${deliveryId}';
    private static REASSIGN_DELIVERY_TO_ROUTE: string = 'shifts/v1/${year}/${month}/${day}/${shift}/routes/${routeId}/delivery/${deliveryId}';
    private static ASSIGN_DELIVERY_TO_ROUTE: string = 'route/v3/${routeId}/delivery';
    private static RESET_DELIVERY: string = 'delivery/v1/${deliveryId}/reset';
    private static ASSIGNMENT: string = 'route/v2/${routeId}/${objectType}';
    private static CREATE_NEW_ROUTE: string = 'shifts/v2/${shiftId}/routes/new';
    private static DELIVERY_SEARCH: string = 'shifts/v1/${year}/${month}/${day}/delivery/search';
    private static ROUTE_SEARCH_BY_BOX: string = 'shifts/v1/${warehouse}/boxbag?box=${boxId}';
    private static RESCHEDULE_DELIVERY_V2: string = 'shifts/v3/${routeId}/delivery/${deliveryId}/reschedule';
    private static MOVE_DELIVERY_TO_ARCHIVED_AND_UNASSIGNED: string = 'shifts/v3/${routeId}/delivery/${deliveryId}/archive';
    private static AUTO_ASSIGN_DRIVERS_TO_ROUTES: string = 'shifts/v1/autoassign-drivers/${shiftId}';
    private static RESET_AUTO_ASSIGN_DRIVERS_TO_ROUTES: string = 'shifts/v1/unaassign-drivers/${shiftId}';
    private static SEND_MESSAGE_TO_DRIVER: string = 'shifts/v2/${routeId}/send-text-message';
    private static FORE_SYNCHRONIZATION: string = 'shifts/v3/${routeId}/force-synchronize';
    private static CANCELLATION_REASONS: string = 'dictionary/v1/CancellationReason';
    private static CANCEL_DELIVERY: string = 'route/v2/${routeId}/delivery/${deliveryId}/cancel/${cancellationReason}';
    private static COMPLETE_DELIVERY: string = 'route/v1/${routeId}/delivery/${deliveryId}/complete';
    private static PUNCTUALITY: string = 'stats/v1/punctuality/${routeId}';
    private static PUNCTUALITY_SETTINGS: string = 'settings/v1/punctuality';
    private static IMPORT_DELIVERIES: string = 'shifts/v1/${shiftId}/import-file?overwrite=${overwrite}&finalizeShift=${finalizeShift}&append=${append}&preset=${preset}';
    private static SHIFT_PUNCTUALITY: string = 'events/route-stat/v1/punctuality/route-by-shift/${shiftId}?slotStartMargin=${slotStartMargin}&slotEndMargin=${slotEndMargin}';
    private static CHANGE_ROUTE_STATUS: string = 'shifts/v3/${routeId}/status/${status}';
    private static ALLOW_LOADING: string = 'route/v1/allowLoading/${routeId}';
    private static ALLOW_BOX_MERGING: string = 'route/v3/${routeId}/allowBoxMerging/${value}';
    private static ALLOW_JIT_MERGING: string = 'route/v3/${routeId}/allowJITMerging/${value}';
    private static LOCK_DELIVERY_ORDER: string = 'route/v3/${routeId}/lockDeliveryOrder/${value}';
    private static ENABLED_LOCATION_EVENTS: string = 'route/v3/${routeId}/enableLocationEvents/${value}';
    private static PAYMENTS_SHIFT_SUMMARY: string = 'report/v1/paymentSummary';
    private static ROUTE_PUNCTUALITY: string = 'route/v3/${routeId}/punctuality';
    private static SCAN_BEFORE_DEPARTURE: string = 'route/v1/${routeId}/scanBeforeDeparture/${enabled}';
    private static SCAN_AFTER_RETURN: string = 'route/v1/${routeId}/scanAfterReturn/${enabled}';

    
    private static SEND_PAYMENTS_SHIFT_SUMMARY: string = 'report/v1/${email}/${date}';

    private selectedShiftIdSource: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private selectedShiftId: Observable<string> = this.selectedShiftIdSource.asObservable();

    private selectedRoutesSource: BehaviorSubject<RouteExt[]> = new BehaviorSubject<RouteExt[]>([]);
    public selectedRoutes: Observable<RouteExt[]> = this.selectedRoutesSource.asObservable();

    private selectedRoutesSummarySource: BehaviorSubject<RouteSummary[]> = new BehaviorSubject<RouteSummary[]>([]);
    public selectedRoutesSummary: Observable<RouteSummary[]> = this.selectedRoutesSummarySource.asObservable();

    private selectedRoutesSummaryLoaderSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public selectedRoutesSummaryLoader: Observable<boolean> = this.selectedRoutesSummaryLoaderSource.asObservable();

    private selectedRoutesSummaryErrorSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public selectedRoutesSummaryError: Observable<boolean> = this.selectedRoutesSummaryErrorSource.asObservable();

    private selectedRoutesLoaderSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public selectedRoutesLoader: Observable<boolean> = this.selectedRoutesLoaderSource.asObservable();

    private selectedRoutesErrorSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public selectedRoutesError: Observable<boolean> = this.selectedRoutesErrorSource.asObservable();

    private selectedDeliveriesSource: Subject<RouteExt> = new Subject<RouteExt>();
    public selectedDeliveries: Observable<RouteExt> = this.selectedDeliveriesSource.asObservable();

    private selectedRouteSource: BehaviorSubject<RouteExt> = new BehaviorSubject<RouteExt>(null);

    private unassignedDeliverySource: BehaviorSubject<Delivery[]> = new BehaviorSubject<Delivery[]>(null);
    public unassignedDelivery: Observable<Delivery[]> = this.unassignedDeliverySource.asObservable();

    private updateDeliveriesSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    private readonly host = environment.api.url;
    private readonly prefix = environment.api.prefix;
    private readonly notifier: ToastrProviderService;
    private readonly tag: string = '[RoutesService]';

    private shiftId: string;
    private routeId;

    private getRoutesSummarySubscription: Subscription;

    private cancellationReasonsDictionary: string[];

    constructor(
        private readonly http: HttpClient,
        private readonly hardwareService: HardwareService,
        private readonly driversService: DriversService,
        private readonly toastrProviderService: ToastrProviderService,
        private readonly utilsService: UtilsService,
        private readonly translate: TranslateService,
        private readonly appService: AppService,
        private readonly localStorageService: LocalStorageService
    ) {
        this.notifier = toastrProviderService;

        this.selectedShiftId.subscribe((shiftId: string) => {
            this.selectedRoutesSummaryLoaderSource.next(true);
            this.selectedRoutesErrorSource.next(false);

            if (shiftId === null) {
                return;
            }

            if (shiftId === '') {
                this.selectedRoutesSource.next([]);
                return;
            }

            const [y, m, d, sh] = shiftId.split('/');
            const routeId = `${y}-${m}-${d}:${localStorage.getItem('depot')}:${sh}`;

            if (this.getRoutesSummarySubscription) {
                this.getRoutesSummarySubscription.unsubscribe();
            }

            this.getRoutesSummarySubscription = this.getRoutesSummary(routeId)
                .pipe(
                    map((routes: RouteSummary[]) => {
                        this.selectedRoutesSummarySource.next(routes);
                        this.selectedRoutesSummaryLoaderSource.next(false);
                        this.selectedRoutesSummaryErrorSource.next(false);
                    })
                )
                .subscribe();
        });
    }

    private httpHeaders(): Object {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
    }

    private interpolate(template: string, params: {}) {
        const names = Object.keys(params);
        const vals = Object.values(params);
        return new Function(...names, `return \`${template}\`;`)(...vals);
    }

    private assign(route: RouteSummary, object: object, objectType: string): Observable<void> {
        const routeId = RouteId.getRouteId3(route.year, route.month, route.day, route.routeNumber);
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${RoutesService.ASSIGNMENT}`, { routeId, objectType });

        return this.http.post(endpoint, object, this.httpHeaders()).pipe(
            catchError((error) => {
                this.notifier.showError(this.translate.instant('Error occurred, please try again!'));
                return throwError(error);
            }),
            map(() => {
                console.log(this.tag, `[Response] ${objectType} unassinged`);
                this.notifier.showSuccess(this.translate.instant('Data has been updated'));
                this.setSelectedShiftId(this.selectedShiftIdSource.getValue(), true);
            })
        );
    }

    private unassign(route: RouteExt, objectType: string): Observable<void> {
        const routeId = RouteId.getRouteId3(route.year, route.month, route.day, route.routeNumber);
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${RoutesService.ASSIGNMENT}`, { routeId, objectType });
        const headers = this.httpHeaders();
        headers['body'] = objectType === 'van' ? route.van : route.driver;

        return this.http.delete(endpoint, headers).pipe(
            catchError((error) => {
                this.notifier.showError(this.translate.instant('Error occurred, please try again!'));
                return throwError(error);
            }),
            map(() => {
                this.notifier.showSuccess(this.translate.instant('Data has been updated'));
                console.log(this.tag, `[Response] ${objectType} unassinged`);
                this.setSelectedShiftId(this.selectedShiftIdSource.getValue(), true);
            })
        );
    }

    public getCancellationReasonsDictionary() {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${RoutesService.CANCELLATION_REASONS}`, {});

        return this.http.get(endpoint).pipe(
            map((cancellationReasonsDictionary: string[]) => {
                this.cancellationReasonsDictionary = cancellationReasonsDictionary;
                return cancellationReasonsDictionary;
            })
        );
    }

    public setSelectedShiftId(shiftId: string, forceReload = false) {
        this.shiftId = shiftId;
        this.selectedShiftIdSource.next(shiftId);
    }

    public unassignedVansFilter() {
        this.hardwareService.loadVans();

        return merge(this.hardwareService.vans, this.selectedRoutesSummary).pipe(
            map(() => {
                const vans = this.hardwareService.vansSource.getValue();
                const routes = this.selectedRoutesSummarySource.getValue();
                const usedVans = [];

                _.forEach(routes, (route: RouteSummary) => {
                    if (route.van.id) {
                        usedVans.push(route.van.id);
                    }
                });

                return _.filter(vans, (van: Van) => _.indexOf(usedVans, van.id) === -1 && (van.status === VanStatus.AVAILABLE || van.status === VanStatus.REPLACEMENT) && van instanceof Van);
            })
        );
    }

    public getPunctualityData(routeId: string): Observable<any> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${RoutesService.PUNCTUALITY}`, { routeId });
        return this.http.get(endpoint, this.httpHeaders()).pipe(
            map((punctuality: any) => {
                return punctuality.segmentPunctualityList[0].deliveryPunctualityList;
            }),
            catchError((err: HttpErrorResponse) => {
                return throwError(err);
            })
        );
    }

    // public getPunctualitySettings(): Observable<SettingsInterface> {
    //     const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${RoutesService.PUNCTUALITY_SETTINGS}`, {});

    //     return this.http.get(endpoint, this.httpHeaders())
    //         .pipe(
    //             map((settings: SettingsInterface) => {
    //                 return settings;
    //             }),
    //             catchError((err: HttpErrorResponse) => {
    //                 return throwError(err)
    //             })
    //         );
    // }

    public unassignedDriversFilter() {
        this.driversService.loadDrivers();

        return merge(this.driversService.drivers, this.selectedRoutesSummary).pipe(
            map(() => {
                const drivers = this.driversService.driversSource.getValue();
                const routes = this.selectedRoutesSummarySource.getValue();
                const assignedDrivers = [];

                _.forEach(routes, (route: RouteSummary) => {
                    if (route.driver.id) {
                        assignedDrivers.push(route.driver.id);
                    }
                });

                return _.filter(drivers, (driver: Driver) => _.indexOf(assignedDrivers, driver.id) === -1 && driver.status === DriverStatus.ACTIVE);
            })
        );
    }

    public getDriversAssignedToShift(mode: DriversMode): Observable<Driver[]> {
        return merge(this.selectedRoutesSummarySource).pipe(
            switchMap(() => this.selectedShiftIdSource),
            filter(Boolean),
            map((shift: string) => {
                const [y, m, d, s] = (shift as string).split('/');
                const w = this.localStorageService.getDepot();
                return RouteId.getShiftId3(y, m, d, s, w);
            }),
            switchMap((shiftId) => this.driversService.getDriversAssignedToShift(shiftId, mode)),
            map((drivers: Driver[]) => {
                const routes = this.selectedRoutesSummarySource.getValue();
                const assignedDrivers = [];

                routes.map((route: RouteSummary) => {
                    if (route.driver.id) {
                        assignedDrivers.push(route.driver.id);
                    }
                });

                return _.filter(drivers, (driver: Driver) => _.indexOf(assignedDrivers, driver.id) === -1 && driver.status === DriverStatus.ACTIVE);
            })
        );
    }

    public getRoutesSummary(shiftId: string): Observable<RouteSummary[]> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${RoutesService.ROUTES_SUMMARY}`, { shiftId });

        this.selectedRoutesSummaryLoaderSource.next(true);

        return this.http.get(endpoint, this.httpHeaders()).pipe(
            catchError((err: HttpErrorResponse) => {
                this.selectedRoutesErrorSource.next(true);
                this.selectedRoutesLoaderSource.next(false);

                return throwError(err);
            }),
            map((response) => {
                this.selectedRoutesLoaderSource.next(true);
                this.selectedRoutesSummaryLoaderSource.next(true);

                let routesSummary = _.map(response, (routesSummaryData: any) => {
                    return new RouteSummary().deserialize(routesSummaryData);
                });

                routesSummary = _.sortBy(routesSummary, (r: RouteSummary) => r.routeNumber);
                return routesSummary;
            })
        );
    }

    public getRoutes(year: string, month: string, day: string, shift: string, expanded: string = ''): Observable<RouteExt[]> {
        const shiftId = RouteId.getShiftId3(year, month, day, shift);
        let endpoint = this.interpolate(`${this.host}${this.prefix}/${RoutesService.ROUTES}`, { shiftId: shiftId });

        if (expanded) {
            endpoint += `?expand=${expanded}`;
        }

        return this.http.get(endpoint, this.httpHeaders()).pipe(
            catchError((err: HttpErrorResponse) => {
                this.selectedRoutesErrorSource.next(true);
                this.selectedRoutesLoaderSource.next(false);
                return throwError(err);
            }),
            map((routesData) => {
                let routes = _.map(routesData, (routeData: any) => {
                    return new RouteExt().deserialize(routeData);
                });

                routes = _.sortBy(routes, (r: RouteExt) => r.routeNumber);

                console.log(this.tag, '[Response] getRoutes', routes);
                this.selectedRoutesSource.next(routes);
                return routes;
            })
        );
    }

    public getRoute(id: number) {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${RoutesService.ROUTE}`, { routeId: id });

        return this.http.get(endpoint, this.httpHeaders()).pipe(
            catchError((err: HttpErrorResponse) => {
                return throwError(err);
            }),
            map((response) => {
                const route = new RouteExt().deserialize(response);
                this.selectedRouteSource.next(route);

                this.selectedDeliveriesSource.next(route);

                return route;
            })
        );
    }

    public getRouteById(routeId: string) {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${RoutesService.ROUTE}`, { routeId });

        return this.http.get(endpoint, this.httpHeaders()).pipe(
            catchError((err: HttpErrorResponse) => {
                return throwError(err);
            }),
            map((response) => {
                const route = new RouteExt().deserialize(response);
                this.selectedRouteSource.next(route);

                this.selectedDeliveriesSource.next(route);

                return route;
            })
        );
    }

    public getUnassignedDelivery(filterDeliveries?: boolean): Observable<any> {
        let endpoint = this.interpolate(`${this.host}${this.prefix}/${RoutesService.GET_PENNDING_DELIVERIES}`, {});

        const depotId = this.localStorageService.getDepot();

        if (filterDeliveries) {
            const [y, m, d, s] = localStorage.getItem('preferredShiftId').split('/');
            endpoint += `?date=${y}-${m}-${d}&shift=${s}&depot=${depotId}`;
        } else {
            endpoint += `?depot=${depotId}`;
        }

        return this.http.get(endpoint, this.httpHeaders()).pipe(
            map((response) => {
                let deliveries: Delivery[] = _.map(response, (deliveryData: any) => {
                    return new Delivery().deserialize(deliveryData);
                });

                deliveries = _.reverse(_.sortBy(deliveries, (d: Delivery) => [d.generatedBy, d.modifiedAt]));

                this.unassignedDeliverySource.next(deliveries);
                return deliveries;
            })
        );
    }

    public unassignDelivery(routeId: any, delivery: Delivery): Observable<Delivery> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${RoutesService.MOVE_TO_UNASSIGNED}`, { routeId: routeId, deliveryId: delivery.id });

        return this.http.delete(endpoint, this.httpHeaders()).pipe(
            map((deliveryData: Delivery) => {
                const newDelivery = new Delivery().deserialize(deliveryData);
                this.updateDeliveriesSource.next(true);
                this.notifier.showSuccess(this.translate.instant('Data has been updated'));
                return newDelivery;
            })
        );
    }

    public reassignDelivery(route: RouteExt, segmentId, position: number, delivery: DeliverySearchResult): Observable<string> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${RoutesService.REASSIGN_DELIVERY_TO_ROUTE}`, {
            year: delivery.routeId.year,
            month: delivery.routeId.month,
            day: delivery.routeId.day,
            shift: delivery.routeId.shift,
            routeId: delivery.routeId.routeNumber,
            deliveryId: delivery.deliveryId,
        });
        const deliveryId = delivery.deliveryId;
        const deliveryData = {
            segmentId: segmentId,
            position: position,
            date: route.date,
            routeNumber: route.routeNumber,
        };

        return this.http.put(endpoint, deliveryData, this.httpHeaders()).pipe(
            map(() => {
                this.updateDeliveriesSource.next(true);
                this.notifier.showSuccess(this.translate.instant('Data has been updated'));
                console.log(this.tag, '[Response] assigned delivery action - success');
                return deliveryId;
            })
        );
    }

    public getRoutePunctuality(id: string): Observable<PunctualityChart> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${RoutesService.ROUTE_PUNCTUALITY}`, { routeId: id });
        return this.http.get<PunctualityChart>(endpoint);
    }

    public getShiftPunctuality(shiftId: string, settings: ReportSettings): Observable<ShiftPunctuality[]> {
        if (!environment.production) {
            return of([]);
        }

        const endpoint = this.interpolate(`${envProd.environment.api.url}/${RoutesService.SHIFT_PUNCTUALITY}`, {
            shiftId: shiftId,
            slotStartMargin: settings.slotStartMargin,
            slotEndMargin: settings.slotEndMargin,
        });
        return this.http.get(endpoint).pipe(map((response) => plainToClass(ShiftPunctuality, response as ShiftPunctuality[])));
    }

    public changeRouteStatus(routeId: string, status: RouteStatus): Observable<RouteExt> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${RoutesService.CHANGE_ROUTE_STATUS}`, { routeId, status });
        return this.http.post<RouteExt>(endpoint, null).pipe(tap(() => this.setSelectedShiftId(this.selectedShiftIdSource.getValue(), true)));
    }

    public allowLoadingRoute(routeId: string): Observable<RouteExt> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${RoutesService.ALLOW_LOADING}`, { routeId });
        return this.http.post<any>(endpoint, null).pipe(tap(() => this.setSelectedShiftId(this.selectedShiftIdSource.getValue(), true)));
    }

    public assignDelivery(route: RouteExt, segmentId: number, position: number, delivery: Delivery, adjustDelivery = false): Observable<string> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${RoutesService.ASSIGN_DELIVERY_TO_ROUTE}`, { routeId: route.id });

        delivery['deliveryNumber'] = position;

        const deliveryId = delivery.id;
        const deliveryData = {
            segmentId: segmentId,
            position: position,
            deliveryId: delivery.id,
            adjustDeliverySlotToRoutePosition: adjustDelivery,
        };

        return this.http.post(endpoint, deliveryData, this.httpHeaders()).pipe(
            map(() => {
                this.updateDeliveriesSource.next(true);
                this.notifier.showSuccess(this.translate.instant('Data has been updated'));
                console.log(this.tag, '[Response] assigned delivery action - success');
                return deliveryId;
            })
        );
    }

    public assignVan(route: RouteSummary, object: object) {
        return this.assign(route, object, 'van');
    }

    public assignDriver(route: RouteSummary, object: object) {
        return this.assign(route, object, 'driver');
    }

    public unassignVan(route: RouteExt) {
        return this.unassign(route, 'van');
    }

    public unassignDriver(route: RouteExt) {
        return this.unassign(route, 'driver');
    }

    public allowBoxMerging(routeId: string, value: boolean) {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${RoutesService.ALLOW_BOX_MERGING}`, { routeId, value });

        return this.http.post(endpoint, null, this.utilsService.httpHeaders()).pipe(
            map((route: RouteExt) => {
                this.toastrProviderService.showSuccess(this.translate.instant('The action has executed successfully!'));
                return route;
            })
        );
    }

    public allowJITMerging(routeId: string, value: boolean) {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${RoutesService.ALLOW_JIT_MERGING}`, { routeId, value });

        return this.http.post(endpoint, null, this.utilsService.httpHeaders()).pipe(
            map((route: RouteExt) => {
                this.toastrProviderService.showSuccess(this.translate.instant('The action has executed successfully!'));
                return route;
            })
        );
    }

    public lockDeliveryOrder(routeId: string, value: boolean) {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${RoutesService.LOCK_DELIVERY_ORDER}`, { routeId, value });

        return this.http.post(endpoint, null, this.utilsService.httpHeaders()).pipe(
            map((route: RouteExt) => {
                this.toastrProviderService.showSuccess(this.translate.instant('The action has executed successfully!'));
                return route;
            })
        );
    }

    public enabledLocationEvents(routeId: string, value: boolean) {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${RoutesService.ENABLED_LOCATION_EVENTS}`, { routeId, value });

        return this.http.post(endpoint, null, this.utilsService.httpHeaders()).pipe(
            map((route: RouteExt) => {
                this.toastrProviderService.showSuccess(this.translate.instant('The action has executed successfully!'));
                return route;
            })
        );
    }

    public scanBeforeDeparture(routeId: string, enabled: boolean) {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${RoutesService.SCAN_BEFORE_DEPARTURE}`, { routeId, enabled });

        return this.http.post(endpoint, null, this.utilsService.httpHeaders()).pipe(
            map((route: RouteExt) => {
                this.toastrProviderService.showSuccess(this.translate.instant('The action has executed successfully!'));
                return route;
            })
        );
    }

    public scanAfterReturn(routeId: string, enabled: boolean) {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${RoutesService.SCAN_AFTER_RETURN}`, { routeId, enabled });

        return this.http.post(endpoint, null, this.utilsService.httpHeaders()).pipe(
            map((route: RouteExt) => {
                this.toastrProviderService.showSuccess(this.translate.instant('The action has executed successfully!'));
                return route;
            })
        );
    }

    public rescheduleDelivery(routeId: string, deliveryId: string): Observable<Delivery> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${RoutesService.RESCHEDULE_DELIVERY_V2}`, {
            routeId,
            deliveryId,
        });

        return this.http.post(endpoint, null, this.utilsService.httpHeaders()).pipe(
            map((deliveryData: Delivery) => {
                const delivery = new Delivery().deserialize(deliveryData);
                this.toastrProviderService.showSuccess(this.translate.instant('Data has been updated'));
                console.log(this.tag, `[Response] Moved delivery to PENDING status and unassigned from route - success`);
                return delivery;
            })
        );
    }

    public getShiftPaymentSummary(params: Object): Observable<ShiftPaymentSummary[]> {
        let endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${RoutesService.PAYMENTS_SHIFT_SUMMARY}`, {});
        const paramsString = UrlUtil.objectToString(params);
        if (paramsString) {
            endpoint += '?' + paramsString;
        }
        return this.http.get<ShiftPaymentSummary[]>(endpoint);
    }

    public getDeliveryPaymentSummary(params: Object): Observable<ShiftPaymentSummary[]> {
        let endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${RoutesService.PAYMENTS_SHIFT_SUMMARY}`, {});
        const paramsString = UrlUtil.objectToString(params);
        if (paramsString) {
            endpoint += '?' + paramsString;
        }
        return this.http.get<ShiftPaymentSummary[]>(endpoint);
    }

    public sendPaymentReportViaEmail(date: string, email: string): Observable<any> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${RoutesService.SEND_PAYMENTS_SHIFT_SUMMARY}`, { date, email });
        return this.http.post<any>(endpoint, this.utilsService.httpHeaders());
    }

    public importDeliveries(form: FormGroup, file?: Blob): Observable<any> {
        const shiftId = `${form.get('date').value}:${form.get('warehouse').value}:${form.get('shift').value}`;
        let endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${RoutesService.IMPORT_DELIVERIES}`, {
            shiftId: shiftId,
            overwrite: form.get('overwrite').value,
            finalizeShift: form.get('finalizeShift').value,
            append: form.get('append').value,
            preset: form.get('preset').value,
        });
        const body = file ? file : form.get('file').value;
        const input = new FormData();
        input.append('file', body, form.get('file').value.name);

        return this.http.post<any>(endpoint, input).pipe(
            tap(() => {
                this.toastrProviderService.showSuccess(this.translate.instant('Data has been imported'));
            })
        );
    }

    public moveDeliveryToArchiveFromRoute(routeId: string, deliveryId: string): Observable<Delivery> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${RoutesService.MOVE_DELIVERY_TO_ARCHIVED_AND_UNASSIGNED}`, {
            routeId,
            deliveryId,
        });

        return this.http.post(endpoint, null, this.utilsService.httpHeaders()).pipe(
            map((deliveryData: Delivery) => {
                const delivery = new Delivery().deserialize(deliveryData);
                this.toastrProviderService.showSuccess(this.translate.instant('Data has been updated'));
                console.log(this.tag, `[Response] Moved delivery to ARCHIVED status and unassigned from route - success`);
                return delivery;
            })
        );
    }

    public resetDelivery(delivery: Delivery) {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${RoutesService.RESET_DELIVERY}`, { deliveryId: delivery.id });

        return this.http.post(endpoint, null, this.utilsService.httpHeaders()).pipe(
            map((deliveryData: Delivery) => {
                const newDelivery = new Delivery().deserialize(deliveryData);
                this.toastrProviderService.showSuccess(this.translate.instant('Data has been updated'));
                console.log(this.tag, `[Response] Reset delivery - success`);
                return newDelivery;
            })
        );
    }

    public createNewRoute(year: string, month: string, day: string, shift: string): Observable<RouteExt> {
        const shiftId = RouteId.getShiftId3(year, month, day, shift);
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${RoutesService.CREATE_NEW_ROUTE}`, { shiftId: shiftId });

        return this.http.post(endpoint, null, this.httpHeaders()).pipe(
            catchError((error) => {
                return throwError(error);
            }),
            map((routeData) => {
                const route = new RouteExt().deserialize(routeData);

                this.notifier.showSuccess(this.translate.instant('Data has been updated'));
                this.setSelectedShiftId(this.selectedShiftIdSource.getValue(), true);
                return route;
            })
        );
    }

    public cancelDelivery(routeId: string, deliveryId: string, cancellationReason: string): Observable<any> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${RoutesService.CANCEL_DELIVERY}`, {
            routeId,
            deliveryId,
            cancellationReason,
        });
        return this.http.post(endpoint, null, this.httpHeaders()).pipe(map(() => true));
    }

    public completeDelivery(routeId: string, deliveryId: string, completeTime?: string): Observable<any> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${RoutesService.COMPLETE_DELIVERY}`, {
            routeId,
            deliveryId,
        });
        return this.http.post(endpoint, completeTime ? `"${completeTime}"` : null, this.utilsService.httpHeadersPlainText()).pipe(map(() => true));
    }

    public autoassignDrivers(year: string, month: string, day: string, shift: string): Observable<RouteExt> {
        const shiftId = RouteId.getShiftId3(year, month, day, shift);
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${RoutesService.AUTO_ASSIGN_DRIVERS_TO_ROUTES}`, { shiftId });

        return this.http.post(endpoint, null, this.httpHeaders()).pipe(
            catchError((error) => {
                this.notifier.showError(this.translate.instant('Error occurred, please try again!'));
                return throwError(error);
            }),
            map((routeData) => {
                let route;
                try {
                    route = new RouteExt().deserialize(routeData);
                } catch (err) {
                    console.log(err);
                }

                this.notifier.showSuccess(this.translate.instant('Data has been updated'));
                this.setSelectedShiftId(this.selectedShiftIdSource.getValue());
                return route;
            })
        );
    }

    public resetAutoassignDrivers(year: string, month: string, day: string, shift: string): Observable<boolean> {
        const shiftId = RouteId.getShiftId3(year, month, day, shift);
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${RoutesService.RESET_AUTO_ASSIGN_DRIVERS_TO_ROUTES}`, { shiftId });

        return this.http.post(endpoint, null, this.httpHeaders()).pipe(
            catchError((error) => {
                this.notifier.showError(this.translate.instant('Error occurred, please try again!'));
                return throwError(error);
            }),
            map((routeData) => {
                this.notifier.showSuccess(this.translate.instant('Data has been updated'));
                this.setSelectedShiftId(this.selectedShiftIdSource.getValue());
                return true;
            })
        );
    }

    public sendMessageToDriver(routeId, driverNotification) {
        const body = JSON.stringify(driverNotification);
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${RoutesService.SEND_MESSAGE_TO_DRIVER}`, { routeId });

        return this.http.post(endpoint, body, this.httpHeaders()).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 404) {
                    this.notifier.showError(this.translate.instant('Driver has no connected devices!'));
                } else {
                    this.notifier.showError(this.translate.instant('Error occurred, please try again!'));
                }
                return throwError(error);
            }),
            map((response) => {
                this.notifier.showSuccess(this.translate.instant('Message has been sent!'));
                return response;
            })
        );
    }

    public sendSynchronizationMessage(year, month, day, routeNumber) {
        const routeId = RouteId.getRouteId3(year, month, day, routeNumber);
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${RoutesService.FORE_SYNCHRONIZATION}`, { routeId });

        return this.http.post(endpoint, null, this.httpHeaders()).pipe(
            catchError((error) => {
                if (error.status === 404) {
                    this.notifier.showError(this.translate.instant('Driver has no connected devices!'));
                } else {
                    this.notifier.showError(this.translate.instant('Error occurred, please try again!'));
                }
                return throwError(error);
            }),
            map((response) => {
                this.notifier.showSuccess(this.translate.instant('Message has been sent!'));
                return response;
            })
        );
    }

    /**
     * Perform search for route and delivery matching customer name or client id or delivery Id.
     *
     * @param year The year
     * @param month The month
     * @param day The day
     * @param phrase Searched phrase
     * @return Observable<DeliverySearchResult>
     */
    public searchDeliveryInRoute(year: string, month: string, day: string, phrase: string): Observable<DeliverySearchResult[]> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${RoutesService.DELIVERY_SEARCH}`, { year, month, day });

        return this.http.post(endpoint, phrase, this.httpHeaders()).pipe(
            catchError((error) => {
                this.notifier.showError(this.translate.instant('Error occurred, please try again!'));
                return throwError(error);
            }),
            map((searchResults: any[]) => {
                return _.map(searchResults, (searchResult: any) => {
                    return new DeliverySearchResult().deserialize(searchResult);
                });
            })
        );
    }

    /**
     *  Search route by boxId
     *
     * @return Observable<DeliverySearchResult>
     * @param boxId
     */
    public searchRouteByBoxId(boxId: string): Observable<BoxBagSearchResults[]> {
        const ref = this.appService.findWarehouseRef(this.localStorageService.getDepot());
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${RoutesService.ROUTE_SEARCH_BY_BOX}`, { warehouse: ref, boxId });

        return this.http.get(endpoint, this.httpHeaders()).pipe(
            map((response) => plainToClass(BoxBagSearchResults, response as BoxBagSearchResults)),
            map((boxBagSearchResults: BoxBagSearchResults) => [boxBagSearchResults])
        );
    }
}
