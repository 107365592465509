import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'convertToLocalTime',
    pure: true
})
export class ConvertToLocalTimePipe implements PipeTransform {
    public transform(time: string): any {
        return moment(moment.utc(time).toDate());
    }
}
