import { Injectable } from '@angular/core';
import { CrudService } from '@services/crud.service';
import { Apm } from '../entities/apm';
import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '@services/utils.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { Coordinates } from '@entities/coordinates';
import { ApmMaintenance } from '../interfaces/apm-maintenance.interface';
import { PartnerReportParams } from '../interfaces/partner-report-params.interface';
import { PartnerReport } from '../interfaces/partner-report.interface';
import { ApmTimes } from '../interfaces/apm-times.interface';
import { ApmOverbooking } from '../interfaces/apm-overbokking.interface';
import { PageableResponse } from '@entities/pagable-response';
import { Region } from '../entities/region';
import { Collection } from '@interfaces/collection.interface';
import { LocalStorageService } from '@services/local-storage.service';
import { UrlUtil } from '@shared/functions/UrlUtil';

@Injectable({
  providedIn: 'root'
})
export class ApmService extends CrudService<Apm, number>{
  
  protected static BASE_API: string = `${environment.api.url}${environment.api.prefix}/apm/v1`;  
  protected static GOOGLE_API: string = 'https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}';
  protected static REPORT: string = `${environment.api.url}${environment.api.prefix}` + '/apm-report/v1/deliveryReport/${mode}';  
  protected static APMS_IN_REGION: string = `${environment.api.url}${environment.api.prefix}` + '/partner/v1/${partnerId}/region/${regionId}/apm';  
  protected static REGIONS_IN_APM: string = `${environment.api.url}${environment.api.prefix}` + '/apm/v1/${apmId}/regions/list';  


  constructor(
    protected _http: HttpClient,
    private localstorageService: LocalStorageService,
    protected utilsService: UtilsService) 
    {
      super(_http, ApmService.BASE_API, utilsService);
    }

  public getCoordinatesForLocation(address: string): Observable<any> {
    const endpoint = this.utilsService.interpolate(ApmService.GOOGLE_API, {address: address, apiKey: environment.googleMapApiKey});
    return this._http.get(endpoint).pipe(
      map(response => plainToClass(Coordinates, response['results'][0]['geometry']['location']))
    )
  }

  public getApmMaintenanceWindow(apmId: string, from: string, to: string): Observable<ApmMaintenance> {
    const endpoint = this.utilsService.interpolate(`${environment.api.url}${environment.api.prefix}/apm-maintenance/v1/` + '${apmId}/${from}/${to}', {apmId, from, to});
    return this._http.get<ApmMaintenance>(endpoint);
  }

  public getTimeslot(timeslotId: string): Observable<ApmMaintenance> {
    const endpoint = `${environment.api.url}${environment.api.prefix}/apm-maintenance/v1/${timeslotId}`;
    return this._http.get<ApmMaintenance>(endpoint);
  }

  public createApmMaintenanceWindow(apmWindow: ApmMaintenance): Observable<ApmMaintenance> {
    const endpoint = `${environment.api.url}${environment.api.prefix}/apm-maintenance/v1/`;
    return this._http.post<ApmMaintenance>(endpoint, apmWindow);
  }

  public updateApmMaintenanceWindow(apmWindow: ApmMaintenance): Observable<ApmMaintenance> {
    const endpoint = `${environment.api.url}${environment.api.prefix}/apm-maintenance/v1/${apmWindow.id}`;
    return this._http.put<ApmMaintenance>(endpoint, apmWindow);
  }

  public deleteMaintenanceWindow(id: string): Observable<ApmMaintenance> {
    const endpoint = `${environment.api.url}${environment.api.prefix}/apm-maintenance/v1/${id}`;
    return this._http.delete<ApmMaintenance>(endpoint);
  }

  public synchronizeApms(apmId: string): Observable<any> {
    const endpoint = `${environment.api.url}${environment.api.prefix}/apm/v1/${apmId}/sync`;
    return this._http.put<any>(endpoint, null, this.utilsService.httpHeaders());
  }

  public generateReport(params: PartnerReportParams): Observable<PartnerReport> {
    let endpoint = this.utilsService.interpolate(ApmService.REPORT, {mode: params.mode});
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    endpoint += '?' + queryString;

    return this._http.get<PartnerReport>(endpoint);
  }

  public getApmsInDeliveryRoute(apmDeliveryId: string): Observable<ApmTimes[]> {
    const endpoint = `${environment.api.url}${environment.api.prefix}/apmdelivery/v1/${apmDeliveryId}/apmsInDeliveryRoute/list`;
    return this._http.get<ApmTimes[]>(endpoint);
  }

  public getApmMaintenanceWindowListByApm(params: Object = environment.pagination): Observable<Collection<Apm>> {
    params['depot'] = this.localstorageService.getDepot();
    const paramsString = UrlUtil.objectToString(params);

    let endpoint = `${environment.api.url}${environment.api.prefix}/apm-maintenance/v1/apm/list`;
    if (paramsString) {
      endpoint += '?' + paramsString;
    }
    return this._http.get<Collection<Apm>>(endpoint);
  }

  public getApmMaintenanceWindowList(params: Object = environment.pagination): Observable<Collection<ApmMaintenance>> {
    params['depot'] = this.localstorageService.getDepot();
    const paramsString = UrlUtil.objectToString(params);

    let endpoint = `${environment.api.url}${environment.api.prefix}/apm-maintenance/v1/list`;
    if (paramsString) {
      endpoint += '?' + paramsString;
    }
    return this._http.get<Collection<ApmMaintenance>>(endpoint);
  }

  public getApmsOverbooking(apmId: string): Observable<ApmOverbooking[]> {
    const endpoint = `${environment.api.url}${environment.api.prefix}/apm-maintenance/v1/${apmId}/overbooking/list`;
    return this._http.get<ApmOverbooking[]>(endpoint);
  }

  public findAllApmByRegionPageableResponse(params: Object = environment.pagination, partnerId: string, regionId: string): Observable<PageableResponse<Apm>> {
    const endpoint: string = this.utilsService.interpolate(ApmService.APMS_IN_REGION + '?size=${size}&order=${order}&sort=${sort}&page=${page}', {...params, regionId, partnerId});
    return this._http.get<PageableResponse<Apm>>(endpoint);
  }

  public getAssignRegionsToApm(apmId: string): Observable<Region[]> {
    const endpoint = `${environment.api.url}${environment.api.prefix}/apm/v1/${apmId}/regions/list`;
    return this._http.get<Region[]>(endpoint);
  }

  public getAvailableRegions(apmId: string, params = environment.pagination): Observable<Collection<Region>> {
    const endpoint = `${environment.api.url}${environment.api.prefix}/apm/v1/${apmId}/regions/available?size=1000&order=${params.order}&sort=${params.sort}&page=${params.page}`;
    return this._http.get<Collection<Region>>(endpoint);
  }

  public getByDepot(params = environment.pagination, selectedDepotId?: number): Observable<Collection<Apm>> {
    const depotId = (selectedDepotId) ? selectedDepotId : this.localstorageService.getDepot();
    const endpoint = `${environment.api.url}${environment.api.prefix}/apm/v1/depot/${depotId}/list?size=1000&order=${params.order}&sort=${params.sort}&page=${params.page}`;
    return this._http.get<Collection<Apm>>(endpoint);
  }

  

  
}
