import { Injectable } from '@angular/core';
import { CrudService } from '@services/crud.service';
import { Apm } from '../entities/apm';
import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '@services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class ApmMissingPartnerService extends CrudService<Apm, number>{
  
  protected static BASE_API: string = `${environment.api.url}${environment.api.prefix}/apm/v1/missingServicePartner`;  

  constructor(
    protected _http: HttpClient, 
    protected utilsService: UtilsService) 
    {
      super(_http, ApmMissingPartnerService.BASE_API, utilsService);
    }
}
