import { serializeType } from '@shared/functions/serializeType.function';
import { Type } from 'class-transformer/decorators';

export class Unit {
    public dateBased:         boolean;
    public durationEstimated: boolean;
    public timeBased:         boolean;
}

export class Duration {
    public nano:     number;
    public negative: boolean;
    public seconds:  number;
    public zero:     boolean;

    @Type(serializeType(Unit)) 
    public units:    Unit[];
}

export class LocalTime {
    public hour:   number;
    public minute: number;
    public nano:   number;
    public second: number;
}

export class TimeWindow {
    @Type(serializeType(Duration)) 
    public duration:      Duration;
    
    @Type(serializeType(Duration)) 
    public fromLocalTime: LocalTime;
    
    @Type(serializeType(Duration)) 
    public toLocalTime:   LocalTime;
    
    public to:            string;
    public from:          string;
}

