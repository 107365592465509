<div class="chartsWrapper" #chartsWrapper>

    <button (click)="toggleFullscreen()" class="btn btn-default fullscreen-button">
        <span class="material-icons">{{fullscreen ? 'fullscreen_exit' : 'fullscreen'}}</span>
    </button>

    <div *ngFor="let depot of punctuality; let i = index" class="depot card box">

        <div *ngIf="i === 0" class="shift-charts headers">
            <div>{{'Depot' | translate }}</div>
            <div>{{'Shift' | translate }}</div>
            <span>{{'Delivery' | translate}}</span>
            <span>{{'Fulfillment' | translate}}</span>
            <span>{{'Loading' | translate}}</span>
        </div>

        <div *ngFor="let shift of depot.shifts; let index = index;" class="shift-charts">

            <div class="depotName"><span *ngIf="index === 0">{{depot.name}}</span></div>

            <div class="shiftName">{{shift.name | translate}}</div>

            <app-dashboard-chart [quantityName]="'deliveries'" [chart]="shift.deliveryPunctuality"
                [showTotals]="settings.showTotalsDelivery" [showPartials]="settings.showPartials">
            </app-dashboard-chart>
            <app-dashboard-chart [chart]="shift.fulfillmentPunctuality" [quantityName]="'routes'"
                [showTotals]="settings.showTotalsFulfillment" [showPartials]="settings.showPartials">
            </app-dashboard-chart>
            <app-dashboard-chart [chart]="shift.loadingPunctuality" [quantityName]="'routes'"
                [showTotals]="settings.showTotalsLoading" [showPartials]="settings.showPartials">
            </app-dashboard-chart>



        </div>

    </div>

    <div *ngIf="fullscreen" class="powered">
        <div>Proudly Powered by&nbsp;<a title="Open Routing" href="http://open-routing.com"> Open Routing</a></div>
    </div>

</div>