import {Component} from "@angular/core";

@Component({
    selector: 'layout-with-sidebar',
    template: `
        <div class="wrapper">
            <div class="header">
                <ng-content select="[header]"></ng-content>
            </div>
            <div class="content">
                <ng-content select="[content]"></ng-content>
            </div>
        </div>
        <div class="sidebar">
            <ng-content select="[sidebar]"></ng-content>
        </div>
    `,
    styleUrls: ['./layout-with-sidebar.component.scss']
})

export class LayoutWithSidebarComponent {}
