import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, zip } from 'rxjs';
import { User } from '@entities/user';
import { AppLang } from '@enums/enum';
import { AppService } from '@services/app.service';
import { AuthenticationService } from '../../authentication.service';
import { Depot } from '@interfaces/depot.interface';
import { LocalStorageService } from '@services/local-storage.service';
import { NotificationService } from 'src/app/system/services/notification.service';
import { Notification } from 'src/app/system/interfaces/notification';
import { tap } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { DepotService } from 'src/app/locations/services/depot.service';
import { environment } from '@environment';
import { switchMap, take } from 'rxjs/operators';
import { UserExt } from '@interfaces/user-ext.interface';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { Permissions } from '@enums/permissions';

@Component({
    selector: 'app-navbar',
    templateUrl: 'navbar.component.html',
    styleUrls: ['navbar.component.scss']
})

export class AppNavbarComponent implements OnInit, OnDestroy {

    private static intervalTimer = 100 * 60 * 10;
    
    public user$: Observable<any> ;
    public depots$: Observable<Depot[]> = null;
    public notifications$: Observable<Notification[]>
    public notifications: Notification[];
    private notificationInterval;
    public appClient = environment.client;

    public selectedDepot: string;
    public _opened: boolean = false;
    public destroy$ = new Subject<boolean>();

    public lang: AppLang;
    public startPage: string;
    public Permissions = Permissions;

    get isWarehousePage(): boolean {
        return (this.router.url.includes('/calendar') ||
            this.router.url.includes('/routes') ||
            this.router.url.includes('/fleet') ||
            this.router.url.includes('/drivers') ||
            this.router.url.includes('/deliveries') ||
            (this.router.url.includes('/visualiser') && !this.router.url.includes('/visualiser/history')) ||
            this.router.url.includes('/drivers/availability')
        );
    }

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly translate: TranslateService,
        private readonly router: Router,
        private readonly localStorageService: LocalStorageService,
        private readonly notificationService: NotificationService,
        private readonly depotService: DepotService,
        private toastrProviderService: ToastrProviderService,
        private readonly appService: AppService) {
    }

    public ngOnInit() {
        this.selectedDepot = this.localStorageService.getDepot();
        
        this.user$ = zip(this.authenticationService.currentUser, this.appService.userAppSettings).pipe(
            tap((data: [User, UserExt]) => {
                this.lang = (data[1].adminAppSettings && data[1].adminAppSettings.hasOwnProperty('appLanguage')) ? data[1].adminAppSettings.appLanguage : AppLang.PL;
                this.startPage = (data[1].adminAppSettings && data[1].adminAppSettings.hasOwnProperty('startPage')) ? data[1].adminAppSettings.startPage : '';
            })
        );

        this.depots$ = this.depotService.getDepotsForUser();
        this.getNotifications();
        this.checkIfUserIsActive();

        this.appService.selectedWarehouse.subscribe(
            (depot: string) =>  this.selectedDepot = depot
        ) 
    }

    private getNotifications = () => {
        this.notifications$ = this.notificationService.getNew().pipe(
            tap(notifications => {
                this.notifications = notifications.slice(0, 5);
            })
        );
    }

    /**
     * Monitors the user's activity by detecting when the user switches between tabs or minimizes the browser window.
     * 
     * This method listens for the `visibilitychange` event on the document. When this event is triggered, the method
     * determines whether the page is currently visible or hidden:
     * 
     * - If the page becomes visible (`document.visibilityState === 'visible'`):
     *   - Logs a message indicating that the user is active on the page.
     *   - Calls `startPolling()` to resume any previously stopped polling processes.
     * 
     * - If the page is hidden (`document.visibilityState === 'hidden'`):
     *   - Logs a message indicating that the user has switched to another tab or minimized the window.
     *   - Calls `stopPolling()` to halt ongoing polling processes while the user is inactive.
     * 
     * This method ensures that resource-intensive operations like polling only occur when the user is actively viewing the page.
     * 
     * @private
     */
    private checkIfUserIsActive(): void {
        this.getNotificationsInterval();
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                console.log('User is active on the page');
                this.getNotificationsInterval();
            } else {
                console.log('User switched to another tab or minimized the window');
                clearInterval(this.notificationInterval);
            }
        });
    }

    private getNotificationsInterval() {
        this.notificationInterval = setInterval(this.getNotifications, AppNavbarComponent.intervalTimer);
    }

    public goToDetails(notification: Notification) {
        if (notification.deepLinkType === 'APM') {
            this.router.navigate(['/apm/maintenance', notification.deepLinkId]);
        } else {
            this.router.navigate(['/deliveries/update', notification.deepLinkId]);

        }
    }

    public markAsRead(item: Notification, event: Event) {
        event.preventDefault()

        this.notificationService.markAsRead(item.id).pipe(
          takeUntil(this.destroy$),
        ).subscribe(
          () => {
            this.notifications$ = undefined;
            setTimeout(() => {
               this.getNotifications();
            }, 200)
          }
        )
      }

    public ngOnDestroy() { 
        this.destroy$.next();
        this.destroy$.complete();
        clearInterval(this.notificationInterval);
    }

    public changedDepot(): void {
        this.appService.__selectedWarehouseSource.next(this.selectedDepot);
        localStorage.setItem('depot', this.selectedDepot);
        localStorage.removeItem('preferredShiftId');
        localStorage.removeItem('preferredCutoff');

        if (!this.router.url.includes('/visualiser')) {
            window.location.reload();
        } else {
            window.location.replace(`${window.location.origin}/visualiser`);
        }
    }

    public changedLang(lang: string) {
        this.appService.userAppSettings.pipe(
            take(1),
            switchMap((userExt: UserExt) => {
                if (userExt.adminAppSettings === null) {
                    Object.assign(userExt, {adminAppSettings: {}})
                }
                Object.assign(userExt.adminAppSettings, {appLanguage: lang as AppLang})
                return this.appService.saveUserAppSettings(userExt.adminAppSettings)
            })
        ).subscribe(
            () => {
                this.translate.use(lang);
                this.translate.reloadLang(lang);
                this.translate.setDefaultLang(lang);
            },
            () => {}
        );
    }

    public changedStartPage(startPage: string) {
        this.appService.userAppSettings.pipe(
            take(1),
            switchMap((userExt: UserExt) => {
                if (userExt.adminAppSettings === null) {
                    Object.assign(userExt, {adminAppSettings: {}})
                }
                Object.assign(userExt.adminAppSettings, {startPage})
                return this.appService.saveUserAppSettings(userExt.adminAppSettings)
            })
        ).subscribe(
            () => {
                this.toastrProviderService.showSuccess(this.translate.instant('Settings has been saved successfully!'));
            },
            () => {
                this.toastrProviderService.showError(this.translate.instant('There was an error, please try again later'));
            }
        );
    }

    public signOut() {
        this.authenticationService.signOut(true);
    }

    public _toggleSidebar(): boolean {
        return this._opened = !this._opened;
    }
}
