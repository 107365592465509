import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Apm } from '../../entities/apm';
import { ActivatedRoute } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { mapTypes, mapOptions } from '@enums/enum';

@Component({
  selector: 'app-apm-map',
  template: `
  <layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="database"></i-feather> {{'Apms map' | translate}}
            </h4>
        </div>
        <hr />
    </div>
    <div content>
        <gmap [path]="apms" [options]="mapOption"></gmap>
    </div>
  </layout-default>
  `,
  styles: 
  [`
    :host ::ng-deep gmap #map {
      height: 100%;
    }
    
  `]
})
export class ApmMapComponent implements OnInit {

  public apms: Apm[];
 
  public mapOption = {
    polyline: false,
    type: 'MULTI'
  }
  
  constructor(
    private navigationRoute: ActivatedRoute
  ) { }

  public ngOnInit() {
    const pagable = (this.navigationRoute.snapshot.data as {apms: any}).apms;
    this.apms = plainToClass(Apm, pagable.content as Apm[]);

  }

}
