<layout-default>
    <div header>
        <div toolbar>
            <h4 *ngIf="mode === 'CREATE'">
                <i-feather name="database"></i-feather> {{'Create config key' | translate}}
            </h4>
            <h4 *ngIf="mode === 'UPDATE'">
                <i-feather name="database"></i-feather> {{'Edit config key' | translate}}: {{configKey.code}}
            </h4>
        </div>

        <hr />
    </div>

    <div content>
        <div *ngIf="!loader" class="content bg-white">

            <div class="card-content col-8 offset-2">

                <form [formGroup]="form">

                    <!-- <div class="form-group row">
                        <label class="col-2" for="scope">{{'Parent config key' | translate}}:</label>
                        <div class="col-10">
                            <div class="ng-autocomplete">
                                <ng-autocomplete 
                                    [data]="rawConfigKeys"
                                    [searchKeyword]="'code'"
                                    (selected)='selectedConfigKeyEvent($event)'
                                    [itemTemplate]="itemTemplate"
                                    [initialValue]="initialConfigKeyValue"
                                    
                                    [debounceTime]="600"
                                    placeHolder="{{'type to search' | translate}}"
                                    [notFoundTemplate]="notFoundTemplate">                                 
                                </ng-autocomplete>
                                
                                <ng-template #itemTemplate let-item>
                                    <a [innerHTML]="item.code"></a>
                                </ng-template>
                                
                                <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                </ng-template>
                            </div>
                        </div>
                    </div>  -->

                    <div class="form-group row">
                        <label class="col-2" for="code">{{'Code' | translate}}:</label>
                        <div class="col-10">
                            <input formControlName="code" class="form-control" />
                            <div *ngIf="form.get('code').hasError('required') && form.get('code').touched"
                                class="error-color">
                                {{ "Code is required" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-2" for="scopes">{{'Scopes' | translate}}:</label>
                        <div class="col-10">
                            <select class="custom-select" (change)="changedScope($event.target.value)">
                                <option appCheckPrivileges [permissions]="[Permissions.ROLE_SYSADMIN]"
                                    value="SCOPE_CONFIG" selected>{{'SCOPE.32' | translate}}</option>
                                <option value="SCOPE_GLOBAL" selected>{{'SCOPE.16' | translate}}</option>
                                <option appCheckPrivileges [permissions]="[Permissions.ROLE_SYSADMIN]"
                                    value="SCOPE_PARTNER" selected>{{'SCOPE.8' | translate}}</option>
                                <option appCheckPrivileges [permissions]="[Permissions.ROLE_SYSADMIN]"
                                    value="SCOPE_REGION" selected>{{'SCOPE.4' | translate}}</option>
                                <option appCheckPrivileges [permissions]="[Permissions.ROLE_SYSADMIN]"
                                    value="SCOPE_TIMESLOT" selected>{{'SCOPE.2' | translate}}</option>
                                <option appCheckPrivileges [permissions]="[Permissions.ROLE_SYSADMIN]"
                                    value="SCOPE_RESERVATION" selected>{{'SCOPE.1' | translate}}</option>
                                <!-- <option value="OTHER">{{'OTHER' | translate}}</option> -->
                            </select>

                            <ng-container *ngIf="scopesOptions === 'OTHER'">
                                <div class="scopes d-flex justify-content-between mg-t-15">
                                    <div class="custom-control custom-checkbox">
                                        <input (change)="setScope($event.target.value)" type="checkbox"
                                            class="custom-control-input" value="SCOPE_GLOBAL" id="SCOPE_GLOBAL">
                                        <label class="custom-control-label" for="SCOPE_GLOBAL">{{'SCOPE_GLOBAL' |
                                            translate}}</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input (change)="setScope($event.target.value)" type="checkbox"
                                            class="custom-control-input" value="SCOPE_PARTNER" id="SCOPE_PARTNER">
                                        <label class="custom-control-label" for="SCOPE_PARTNER">{{'SCOPE_PARTNER' |
                                            translate}}</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input (change)="setScope($event.target.value)" type="checkbox"
                                            class="custom-control-input" value="SCOPE_REGION" id="SCOPE_REGION">
                                        <label class="custom-control-label" for="SCOPE_REGION">{{'SCOPE_REGION' |
                                            translate}}</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input (change)="setScope($event.target.value)" type="checkbox"
                                            class="custom-control-input" value="SCOPE_TIMESLOT" id="SCOPE_TIMESLOT">
                                        <label class="custom-control-label" for="SCOPE_TIMESLOT">{{'SCOPE_TIMESLOT' |
                                            translate}}</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input (change)="setScope($event.target.value)" type="checkbox"
                                            class="custom-control-input" value="SCOPE_RESERVATION"
                                            id="SCOPE_RESERVATION">
                                        <label class="custom-control-label"
                                            for="SCOPE_RESERVATION">{{'SCOPE_RESERVATION' | translate}}</label>
                                    </div>
                                </div>
                            </ng-container>

                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-2" for="type">{{'Type' | translate}}:</label>
                        <div class="col-10">
                            <select class="custom-select" formControlName="type">
                                <option *ngFor="let type of configKeyType" [value]="type">{{type | translate}}</option>
                            </select>

                            <div *ngIf="form.get('type').hasError('required') && form.get('type').touched"
                                class="error-color">
                                {{ "Selecting type is required" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div class="form-group row justify-content-end">
                        <div class="col-2">
                            <button (click)="onSubmit()" [disabled]="!form.valid || submitted"
                                class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01 w-100">

                                <span *ngIf="!submitted">{{ "Save" | translate }}</span>

                                <ng-container *ngIf="submitted">
                                    <div class="load-spinner text-center">
                                        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                    </div>
                                </ng-container>

                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</layout-default>

<!-- {{'INCLUSIVE' | translate}} -->
<!-- {{'EXCLUSIVE' | translate}} -->
<!-- {{'from' | translate}} -->
<!-- {{'to' | translate}} -->