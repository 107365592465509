import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { environment } from '@environment';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { Loader } from '@googlemaps/js-api-loader';
import { RoutePath } from '@entities/route-path';
import * as _ from 'lodash';
import { Apm } from 'projects/inpost/src/app/operations/entities/apm';

@Component({
    selector: 'app-gmap-cluster',
    template: `
        <div #mapContainer style="width: 100%; height: 500px;"></div>
    `,
    styles: [
        `
            #map {
                width: 100%;
                height: 100%;
            }
        `,
    ],
})
export class GmapClusterComponent implements AfterViewInit {
    @ViewChild('mapContainer', { static: false }) mapContainer!: ElementRef;
    @Input() locations: RoutePath[] | Apm[] | [{lat: number, lng: number}] = [];

    map!: google.maps.Map;
    markerCluster!: MarkerClusterer;
    center: google.maps.LatLngLiteral = { lat: 52.2297, lng: 21.0122 }; // Centrum mapy (np. Warszawa)
    zoom = 6;
    markersOnMap = []

    ngAfterViewInit(): void {
        this.loadGoogleMaps();
    }

    loadGoogleMaps(): void {
        const loader = new Loader({
            apiKey: environment.googleMapApiKey,
            version: 'weekly',
        });

        loader
            .load()
            .then(() => {
                this.initializeMap();
                this.addMarkersAndClusters();
            })
            .catch((e) => {
                console.error('Błąd ładowania Google Maps API:', e);
            });
    }

    initializeMap(): void {
        this.map = new google.maps.Map(this.mapContainer.nativeElement, {
            center: this.center,
            zoom: this.zoom,
        });
    }

    addMarkersAndClusters(): void {

        if (this.locations.length) {
            if (this.locations[0] instanceof RoutePath) {
                _.forEach(this.locations, (routePath: RoutePath) => {
                    _.forEach(routePath.points, (point) => this.markersOnMap.push({ ...point, label: `${point.description}` }));
                });
            } else if (this.locations[0] instanceof Apm) {
                _.forEach(this.locations, (apm: Apm) => {
                    this.markersOnMap.push({ ...apm.coordinates, label: `Name: ${apm.name}, Code: ${apm.code}` });
                });
            } else if (this.locations.every(loc => 'lat' in loc && 'lng' in loc)) {
                _.forEach(this.locations, (cords: {lat: number, lng: number}) => {
                    this.markersOnMap.push(cords);
                });            }else if ('locationCoordinates' in this.locations[0]) {
                _.forEach(this.locations, (apm) => {
                    this.markersOnMap.push({ ...apm.locationCoordinates, label: `Name: ${apm.name}, Code: ${apm.code}` });
                });
            }
        }

        const markers = this.markersOnMap.map((location) => {
            const marker = new google.maps.Marker({
                position: location,
                title: '',
            });

            const loc = new google.maps.LatLng(location.lat, location.lng);

            marker.addListener('click', () => {
                infowindow.setContent(location.label);
                infowindow.open(marker.get('map'), marker);
            });

            return marker;
        });

        const infowindow = new google.maps.InfoWindow();

        // Upewnij się, że `MarkerClusterer` otrzymuje właściwą instancję mapy
        this.markerCluster = new MarkerClusterer({
            map: this.map,
            markers: markers,
        });

        this.fitMapToMarkers();
    }

    /**
     * Adjusts the map's bounds to include all markers in the positions array.
     * Uses LatLngBounds to determine the bounding box and fitBounds on the map to set
     * the optimal zoom and center to show all points.
     */
    private fitMapToMarkers(): void {
        const bounds = new google.maps.LatLngBounds();

        // Extend bounds for each position in the array
        this.markersOnMap.forEach((point) => bounds.extend(point));

        // Use fitBounds to adjust the map's view
        if (this.markersOnMap.length > 1 && this.map) {
            this.map.fitBounds(bounds);
        }
    }
}
