import { Component, OnInit } from '@angular/core';
import { ApmOverbooking } from '../../../interfaces/apm-overbokking.interface';
import { ApmService } from '../../../services/apm.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-apm-overbooking',
  templateUrl: './apm-overbooking.component.html',
  styleUrls: ['./apm-overbooking.component.scss']
})
export class ApmOverbookingComponent implements OnInit {

  public list$: Observable<ApmOverbooking[]>;
  public modifiedRow: string;
  public rowClass;
  public loader: boolean = true;
  public apmId;
  public partnerId;

  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  public columns = [
    { headerClass: 'slotTime', prop: 'slotTime', name: this.translateService.instant('Slot time'), flexGrow: 4, sortable: true },
    { headerClass: 'overboking', prop: 'overboking', name: this.translateService.instant('Overbooked slots'), flexGrow: 4, sortable: true },
    { headerClass: 'options', prop: 'options', name: this.translateService.instant('Options'), flexGrow: 2, sortable: false},
  ];

  constructor(
    protected readonly apmService: ApmService,
    protected readonly translateService: TranslateService,
    protected readonly navigationRoute: ActivatedRoute,
    protected readonly modalService: BsModalService,
    protected readonly toastrProviderService: ToastrProviderService) {
  }

  public ngOnInit() {
    const params = this.navigationRoute.snapshot.params;
    this.apmId = params.apmId;

    this.list$ = this.apmService.getApmsOverbooking(params.maintenanceId).pipe(
      map((list: ApmOverbooking[]) => list.filter((el: ApmOverbooking) => el.overboking.length)),
      tap((list: ApmOverbooking[]) => console.log(list)),
    );

  }

}
