import { Injectable } from '@angular/core';
import { CrudService } from '@services/crud.service';
import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '@services/utils.service';
import { ConfigValue } from '../entities/config-value';
import { Observable, BehaviorSubject } from 'rxjs';
import { Collection } from '@interfaces/collection.interface';
import { ConfigValuesParams } from '../interfaces/config-values-params.interface';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigValueService extends CrudService<ConfigValue, number>{
  
  protected static BASE_API: string = `${environment.api.url}${environment.api.prefix}/config-value/v1`;  

  public configList$: BehaviorSubject<ConfigValue[]> = new BehaviorSubject<ConfigValue[]>(null);

  constructor(
    protected _http: HttpClient, 
    protected utilsService: UtilsService) 
    {
      super(_http, ConfigValueService.BASE_API, utilsService);
    }

  public getAllConfigValues(): Observable<ConfigValue[]> {
    const endpoint = `${environment.api.url}${environment.api.prefix}/config-value/v1/list`;
    return this._http.get<ConfigValue[]>(endpoint).pipe(
      tap((configs) => this.configList$.next(configs))
    );
  }

  public getConfigValuesByScope(params: ConfigValuesParams): Observable<ConfigValue[]> {
    let endpoint = `${environment.api.url}${environment.api.prefix}/config-value/v2/${params.partnerId}/list?scope=${params.scope ? params.scope : 15}`;  
    endpoint += (params.regionId && params.regionId !== 'null') ? `&regionId=${params.regionId}` : ''
    endpoint += (params.timeslotId) ? `&timeslotId=${params.timeslotId}` : ''

    return this._http.get<ConfigValue[]>(endpoint);
  }

  public getConfigDefaultValues(): Observable<Collection<ConfigValue>> {
    const endpoint = this.utilsService.interpolate(`${ConfigValueService.BASE_API}/default-list`, {});
    return this._http.get<Collection<ConfigValue>>(endpoint);
  }

  public getConfigByCodeAndScope(code: string, scope: number): Observable<ConfigValue> {
    const endpoint = this.utilsService.interpolate(`${ConfigValueService.BASE_API}/code/${code}/${scope}`, {});
    return this._http.get<ConfigValue>(endpoint);
  }
}
