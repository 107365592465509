import { Component } from '@angular/core';

@Component({
    selector: 'layout-default',
    template: `
        <div class="wrapper">
            <div class="header">
                <ng-content select="[header]"></ng-content>
            </div>
            <div id="content" class="content">
                <ng-content select="[content]"></ng-content>
            </div>
        </div>
    `,
    styleUrls: ['layout-default.component.scss']
})

export class LayoutDefaultComponent {}
