import { Injectable } from '@angular/core';
import { CrudService } from '@services/crud.service';
import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '@services/utils.service';
import { Observable } from 'rxjs';
import { PageableResponse } from '@entities/pagable-response';
import PartnerDC from '../interfaces/partnerdc.interface';

@Injectable({
  providedIn: 'root'
})
export class DcService extends CrudService<PartnerDC, number>{
  
  protected static BASE_API: string = `${environment.api.url}${environment.api.prefix}/partnerdc/v1`;  

  constructor(
    protected _http: HttpClient, 
    protected utilsService: UtilsService) 
    {
      super(_http, DcService.BASE_API, utilsService);
    }

    public savePartnerDC(partnerDc: PartnerDC, partnerId: string): Observable<PartnerDC> {
      const endpoint = this.utilsService.interpolate(`${DcService.BASE_API}/${partnerId}`, {});
      return this._http.post<PartnerDC>(endpoint, partnerDc);
    }

    public updatePartnerDC(partnerDc: PartnerDC, partnerId: string): Observable<PartnerDC> {
      const endpoint = this.utilsService.interpolate(`${DcService.BASE_API}/${partnerId}`, {});
      return this._http.put<PartnerDC>(endpoint, partnerDc);
    }

    public getAllDcForpartner(partnerId: string): Observable<PageableResponse<PartnerDC[]>> {
        const endpoint = this.utilsService.interpolate(`${DcService.BASE_API}/list?partnerId=${partnerId}&sort=id&size=1000`, {});
        return this._http.get<PageableResponse<PartnerDC[]>>(endpoint);
    }
}
