import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigKey } from '../../entities/config-key';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CompartmentType } from '@enums/enum';
import scopesConstants from '../config-key/constants/scopes.constants';
import { ConfigValueService } from '../../services/config-value.service';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfigValue } from '../../entities/config-value';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '@services/nav.sevice';
import { distinctUntilChanged } from 'rxjs/operators';
import { ConfigKeyTypes } from '../../enums/config-key.type';

@Component({
  selector: 'app-create-config-value',
  templateUrl: './create-config-value.component.html',
  styleUrls: ['./create-config-value.component.scss']
})
export class CreateConfigValueComponent implements OnInit {

  public configKeys: ConfigKey[];
  public form: FormGroup;
  public loader: boolean = true;
  public submitted: boolean = false;
  public compartmentType = CompartmentType;
  public configKeyTypes = ConfigKeyTypes;
  public selectedConfigKey: ConfigKey;
  public formErrorsMessages;

  private configKe;
  

  constructor(
    private readonly navigationRoute: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly configValueService: ConfigValueService,
    private toastrProviderService: ToastrProviderService,
    private translateService: TranslateService,
    private navService: NavService,

  ) { }

  ngOnInit() {
    this.configKeys = ((this.navigationRoute.snapshot.data as {configKeys: any}).configKeys as ConfigKey[])
      .filter((c: ConfigKey) => c.scopes === scopesConstants.SCOPE_GLOBAL);
      
    console.log(this.configKeys);

    this.initForm();
  }

  private initForm() {

    this.form = this.formBuilder.group({
      partnerId: [null],
      timeslotId: [null],
      regionId: [null],
      configKeyId: [null, Validators.required],
      scope: [16, Validators.required],
      value: [null, [Validators.required, Validators.min(0)]],
    });

    this.form.get('configKeyId').valueChanges.pipe(distinctUntilChanged()).subscribe(key => this.changedKey(key))

    this.loader = false;
  }

  public changedKey(keyId: string) {
    this.selectedConfigKey = this.configKeys.find(c => c.id === keyId);
    console.log(this.selectedConfigKey);
  }

  private onSubmit(): void {

    const raw = this.form.getRawValue();

    this.configValueService.save(raw)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (configValue: ConfigValue) => {
          this.toastrProviderService.showSuccess(this.translateService.instant('New config value has been created!'));
          this.navService.navigateBack();
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.formErrorsMessages = error;
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }
}
