import { AfterContentInit, ContentChild, Directive, ElementRef, HostBinding, HostListener } from '@angular/core';
import { DropdownMenuDirective } from './dropdown-menu.directive';
import { DropdownToggleDirective } from './dropdown-toggle.directive';

@Directive({
    selector: '[dropdown]'
})
export class DropdownDirective implements AfterContentInit {

    constructor(private elem: ElementRef) {}

    @ContentChild(DropdownToggleDirective, { read: DropdownToggleDirective, static: false })
    public toggleElem: DropdownToggleDirective;

    @ContentChild(DropdownMenuDirective, { read: DropdownMenuDirective, static: false })
    public menu: DropdownMenuDirective;

    @HostBinding('class.show')
    public opened = false;

    @HostListener('keyup.escape')
    @HostListener('document:click', ['$event'])
    public exitDropdown(event: Event) {
        const path = (event as any).path || (event.composedPath && event.composedPath());
        const isOutside = path && path.indexOf(this.elem.nativeElement) === -1;
        if (isOutside) {
            this.close();
        }
    }

    public open(): void {
        this.opened = true;
        if (this.menu) {
            this.menu.opened = this.opened;
        }
    }

    public close(): void {
        this.opened = false;
        if (this.menu) {
            this.menu.opened = this.opened;
        }
    }

    public toggle(): void {
        this.opened ? this.close() : this.open();
    }

    public ngAfterContentInit() {
        if (!this.menu) {
            console.warn('Dropdown menu element is not found.');
            return;
        }

        this.close();

        if (this.toggleElem) {
            this.toggleElem.onToggle.subscribe(() => {
                this.toggle();
            });
        }
    }
}
