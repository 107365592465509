import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
 
import { HardwareStatus } from '@enums/enum';
import { HardwareType, HardwareTypeOptions } from '@shared/enums/enum';
import { NavService } from '@shared/services/nav.sevice';

import { Hardware } from '../entities/hardware';
import { Van } from '../entities/van';
import { HardwareService } from '../services/hardware.service';
import { AppService } from '@services/app.service';

@Component({
    selector: 'drivers',
    templateUrl: 'vans.component.html',
    styleUrls: ['vans.component.scss']
})
export class VansComponent implements OnInit, OnDestroy {
    private readonly TAG: string = '[VansComponent]';
    private filterPhrase: string = '';
    private rows: Array<Van | Hardware> = [];
    private routeParams$: Subscription;
    private success: boolean = false;

    public columns;
    public hardwareStatus: HardwareStatus = HardwareStatus.AVAILABLE;
    public hardwareType: HardwareType | 'ALL' = 'ALL';
    public loader: boolean = true;
    public mode: string = 'list';
    public modifiedId = '';
    public rowClass;
    public rowsDuplicated: Array<Van | Hardware> = [];
    public selectedVanId: string = '';
    public tableMessages = {
        emptyMessage: this.translateService.instant('No data to display')
    };
    public vans: Array<Van>;

    get hardwareTypeOptions() {
        return HardwareTypeOptions;
    }

    get hardwareStatuses() {
        return _.keys(HardwareStatus);
    }

    constructor(
        private readonly hardwareService: HardwareService, 
        private readonly translateService: TranslateService, 
        private readonly navService: NavService,
        private readonly appService: AppService,
        private readonly navigationRoute: ActivatedRoute) {}

    public ngOnInit(): void {
        this.subscribeOnVansData();

        // data from route
        // todo refactor to separate components
        this.routeParams$ = this.navigationRoute.params.subscribe(params => {
            const length = _.keys(params).length;

            if (length === 0) {
                this.mode = 'list';
            } else if (length === 1 && params.vanId === 'create') {
                this.mode = 'create';
                this.selectedVanId = '';
            } else if (length === 1) {
                this.mode = 'show';
                this.selectedVanId = params.vanId;
            } else if (length === 2) {
                this.selectedVanId = params.vanId;
                this.mode = params.action;
            } else {
                this.mode = 'list';
            }

            this.modifiedId = _.get(params, 'vanId', '');
        });

        this.rowClass = van => {
            return { 'row-modified': van.id === this.modifiedId };
        };

        this.appService.selectedWarehouse.subscribe(depot => {
            this.loader = true;
            this.hardwareService.loadVans(depot);
        })
    }

    public ngOnDestroy() {
        console.log(`${this.TAG}, ngOnDestroy`);

        if (this.routeParams$) {
            this.routeParams$.unsubscribe();
        }
    }

    private subscribeOnVansData(): void {
        
        this.hardwareService.vans.subscribe((vans: Van[]) => {
            this.rows = vans;
            console.log(this.TAG, 'Subscribe received hardware: ', this.rows);

            this.columns = [
                { prop: 'num', name: '', flexGrow: 1, sortable: false },
                { prop: 'registration', name: this.translateService.instant('Registration or device number'), flexGrow: 6, sortable: true },
                { prop: 'owner', name: this.translateService.instant('Owner'), flexGrow: 3, sortable: true },
                { prop: 'brand', name: this.translateService.instant('Brand'), flexGrow: 3, sortable: true },
                { prop: 'status', name: this.translateService.instant('Status'), flexGrow: 3, sortable: true },
                // { prop: 'bodyType', name: this.translateService.instant('Hardware type'), flexGrow: 3, sortable: true }
            ];

            this.applyFiltering();
            this.loader = false;
        });
    }

    public isNum(status: string): boolean {
        return status === 'num';
    }

    public isColumnToTranslate(status: string): boolean {
        return status === 'owner' || status === 'status';
    }

    public isColumnRegistration(status: string): boolean {
        return status === 'registration';
    }

    public columnRegistrationValue(row: Van & Hardware): string {
        return row.registration === undefined ? row.inventoryNo : row.registration;
    }

    public isColumnBodyType(status: string): boolean {
        return status === 'bodyType';
    }

    public order(item: Van | Hardware): number {
        return _.findIndex(this.rowsDuplicated, (i: Van | Hardware) => i.id === item.id) + 1;
    }

    public applyFiltering(event?) {
        this.filterPhrase = event ? event.target.value.toLowerCase() : this.filterPhrase;

        if (this.hardwareType === 'ALL') {
            this.rowsDuplicated = this.rows.filter((row: Van & Hardware) =>
                (row.label.toLowerCase().indexOf(this.filterPhrase) !== -1 || !this.filterPhrase) &&
                row.status === this.hardwareStatus
            );
        } else if (this.hardwareType === HardwareType.VAN) {
            this.rowsDuplicated = this.rows.filter((row: Van & Hardware) =>
                (row.label.toLowerCase().indexOf(this.filterPhrase) !== -1 || !this.filterPhrase) &&
                row.status === this.hardwareStatus &&
                row.vehicleType
            );
        }
        else {
            this.rowsDuplicated = this.rows.filter((row: Van & Hardware) =>
                (row.label.toLowerCase().indexOf(this.filterPhrase) !== -1 || !this.filterPhrase) &&
                row.status === this.hardwareStatus &&
                row.hardwareType === this.hardwareType
            );
        }

        return;
    }

    public goToVanDetails(event: any): void {
        if (event.type === 'click') {
            if (event.row.id.includes('hardware:')) {
                this.navService.goToPage(`fleet/edit/${event.row.id}`);
                this.success = false;
            } else {
                this.navService.goToPage(`fleet/${event.row.id}`);
                this.success = false;
            }
        }
    }

    public goToHardwareUsages() {
        this.navService.goToPage(`/fleet/hardware-usages/${moment().format('YYYY/MM/DD')}`);
    }

    private __translate() {
        this.translateService.instant('MAINTENANCE');
        this.translateService.instant('AVAILABLE');
        this.translateService.instant('RETIRED');
        this.translateService.instant('FRISCO');
        this.translateService.instant('BASIC');
        this.translateService.instant('registration');
        this.translateService.instant('brand');
        this.translateService.instant('owner');
        this.translateService.instant('status');
        this.translateService.instant('bodyType');
        this.translateService.instant('PETIT_FORESTIER');
        this.translateService.instant('FRAIKIN');
        this.translateService.instant('REPLACEMENT');
    }
}
