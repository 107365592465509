

import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'sortSeries',
    pure: true
})
export class SortSeriesPipe implements PipeTransform {
    public transform(series: any): any {

        const sortOrder = ['EARLIER', 'FASTER', 'ON_TIME', 'ACCEPTABLE', 'DELAYED', 'SLOWER', 'TOTAL'];

        series.sort((a, b) => {
            if (a.name === b.name) {
                return a.name.localeCompare(b.name);
            } else {
                return sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name);
            }
        });
        return series;
    }
}
