import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '@services/nav.sevice';
import { CRUD_MODE, mapOptions, mapTypes } from '@enums/enum';
import * as _ from 'lodash';
import { Additional } from '@entities/additional';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import PartnerDC from '../../interfaces/partnerdc.interface';
import { DcService } from '../../services/dc.service';
import { ApmService } from '../../services/apm.service';
import { plainToClass } from 'class-transformer';
import { Coordinates } from '@entities/coordinates';
import { Address } from '@entities/address';
import { AppService } from '@services/app.service';
import { Observable } from 'rxjs';
import { Depot } from '@interfaces/depot.interface';
import { UtilsService } from '@services/utils.service';
import { tooltipLabel } from '@shared/functions/tooltip-labels.function';

@Component({
  selector: 'app-dc-edit',
  templateUrl: './dc-edit.component.html',
  styleUrls: ['./dc-edit.component.scss']
})
export class DcEditComponent implements OnInit {

  public partnerdc: PartnerDC;
  public mode: CRUD_MODE;

  public form: FormGroup;
  public loader: boolean = true;
  public submitted: boolean = false;
  public partnerId = null;
  public coordinates: Coordinates = null;

  public depots$: Observable<Depot[]>;

  public mapOption = mapOptions.REGULAR;
  public mapProperties = {
    zoom: 18,
    mapTypeId: mapTypes.ROADMAP
  };

  get addressKeys() {
    const address = new Address();
    return _.keys(address);
  }

  get additionalKeys() {
    const additional = new Additional();
    return _.keys(additional);
  }

  get coordinatesKeys() {
    const cords = new Coordinates();
    return _.keys(cords);
  }

  constructor(
    private navigationRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private dcService: DcService,
    private toastrProviderService: ToastrProviderService,
    private translateService: TranslateService,
    private navService: NavService,
    private activatedRoute: ActivatedRoute,
    private apmService: ApmService,
    private readonly appService: AppService,
    private readonly utilsService: UtilsService
  ) { }

  public ngOnInit() {
    this.partnerdc = (this.navigationRoute.snapshot.data as {dc: any}).dc;
    this.mode = (this.partnerdc) ? CRUD_MODE.UPDATE : CRUD_MODE.CREATE;

    this.depots$ = this.appService.getWarehouses();

    this.activatedRoute.params.subscribe(params => {
      this.partnerId = this.utilsService.findUpRouteParams(this.navigationRoute.snapshot, 'partnerId');
      
      this.initForm();
    })

    
  }

  private initForm() {
    this.form = this.formBuilder.group({
      address: this.formBuilder.group({
        country: [this.partnerdc ? this.partnerdc.address.country : 'Polska', [Validators.required, Validators.maxLength(255)]],
        district: [this.partnerdc ? this.partnerdc.address.district : null, [Validators.required, Validators.maxLength(255)]],
        houseNO: [this.partnerdc ? this.partnerdc.address.houseNO : null, [Validators.required, Validators.maxLength(255)]],
        street: [this.partnerdc ? this.partnerdc.address.street : null, [Validators.required, Validators.maxLength(255)]],
        town: [this.partnerdc ? this.partnerdc.address.town : null, [Validators.required, Validators.maxLength(255)]],
        voivoidship: [this.partnerdc ? this.partnerdc.address.voivoidship : null, [Validators.required, Validators.maxLength(255)]],
        zip: [this.partnerdc ? this.partnerdc.address.zip : null, [Validators.required, Validators.pattern('[0-9]{2}\-[0-9]{3}')]]
      }),
      coordinates: this.formBuilder.group({
        lat: [this.partnerdc ? this.partnerdc.coordinates.lat : 0, Validators.required],
        lng: [this.partnerdc ? this.partnerdc.coordinates.lng : 0, Validators.required]
      }),
      locationAdditional: this.formBuilder.group({
        companyName: [null, Validators.maxLength(255)],
        flatNO: [null, Validators.maxLength(255)],
        lift: [null, Validators.maxLength(255)],
        staircaseNO: [null, Validators.maxLength(255)],
        floor: [null, Validators.maxLength(255)],
        contactName: [null, Validators.maxLength(255)],
        contactPhoneNO: [null, Validators.maxLength(255)],
        domofonCode: [null, Validators.maxLength(255)],
        instructions: [null, Validators.maxLength(255)],
        contactEmail: [null, [Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')]]
      }),
      id: [(this.partnerdc) ? this.partnerdc.id : null],
      name: [(this.partnerdc) ? this.partnerdc.name : null, [Validators.required, Validators.maxLength(255)]],
      partnerId: [this.partnerId, [Validators.maxLength(255)]],
      pickupBoxSeconds: [(this.partnerdc) ? this.partnerdc.pickupBoxSeconds : null, [Validators.required, Validators.maxLength(255)]],
      pickupDeliverySeconds: [(this.partnerdc) ? this.partnerdc.pickupDeliverySeconds : null, [Validators.required, Validators.maxLength(255)]],
      pickupSeconds: [(this.partnerdc) ? this.partnerdc.pickupSeconds : null, [Validators.required, Validators.maxLength(255)]],
      boo: [this.partnerdc ? this.partnerdc.bOO : null, [Validators.required, Validators.maxLength(255)]],
      bOO: [this.partnerdc ? this.partnerdc.bOO : null, [Validators.required, Validators.maxLength(255)]],
      version: [this.partnerdc ? this.partnerdc.version : null]

    });

    this.form.get('bOO').valueChanges.subscribe((boo) => this.form.get('boo').setValue(boo));

    const locationAdditional = this.form.controls[ 'locationAdditional' ];

    if (this.partnerdc) {
      locationAdditional.patchValue((this.partnerdc.locationAdditional !== null) ? this.partnerdc.locationAdditional : {});
    }

    this.loader = false;
  }

  public onSubmit() {
    const raw: PartnerDC = this.form.getRawValue() as PartnerDC;
    
    if (this.mode === CRUD_MODE.CREATE) {
      this.create(raw);
    } else {
      this.update(raw);
    }
  }

  private create(raw): void {
    this.submitted = true;
    this.dcService.savePartnerDC(raw, this.partnerId)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (dc: PartnerDC) => {
          this.toastrProviderService.showSuccess(this.translateService.instant('New distribution center has been created!'));
          this.navService.goToPage(`/partner/${this.partnerId}/dc/list`, {modifiedRow: dc.id});
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }

  private update(raw): void {
    this.submitted = true;
    this.dcService.updatePartnerDC(raw, this.partnerId)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (dc: PartnerDC) => {
          this.toastrProviderService.showSuccess(this.translateService.instant('The distribution center has been updated!'));
          this.navService.goToPage(`/partner/${this.partnerId}/dc/list`, {modifiedRow: dc.id});
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }

  public getCoordinates(): void {
    const address = plainToClass(Address, this.form.getRawValue()['address']);
    this.apmService.getCoordinatesForLocation(address.raw).subscribe(
      (coords: Coordinates) => {
        this.coordinates = coords;
        
        this.form.get('coordinates').get('lat').setValue(this.coordinates.lat);
        this.form.get('coordinates').get('lng').setValue(this.coordinates.lng);
      }
    );
  }

  public changeCoordinates(coordinates: Coordinates): void {
    this.form.get('coordinates').get('lat').setValue(coordinates.lat);
    this.form.get('coordinates').get('lng').setValue(coordinates.lng);
  }

  public tooltipLabel(property: string): string {
    return tooltipLabel(this.translateService, `tooltips.partnerdc.labels.${property}`);
  }
}
