<layout-default>
        <div header>
            <div toolbar>
                <h4 *ngIf="mode === 'CREATE'">
                    <i-feather name="users"></i-feather> {{'Create partner' | translate}}
                </h4>
                <h4 *ngIf="mode === 'UPDATE'">
                    <i-feather name="users"></i-feather> {{'Edit partner' | translate}}: {{partner.name}}
                </h4>
            </div>

            <hr />
        </div>
        
        <div content>
            <div *ngIf="!loader" class="content bg-white">

                <div class="card-content col-8 offset-2">

                    <form [formGroup]="form">

                        <div id="partner-edit-form-{{item}}" *ngFor="let item of regularFormItems()" class="form-group row">

                            <label [for]="item" class="col-2">
                                <span>{{item.toUpperCase() | translate}}:&nbsp;</span>
                                <span *ngIf="form.get(item) | isRequiredControlPipe">*</span>&nbsp;
                                <i-feather *ngIf="tooltipLabel(item)" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(item)"
                                    [style.width.px]="13" name="info"></i-feather>
                            </label>
                            
                            <div class="col-10">

                                   
                                <input *ngIf="item === 'code'" [formControlName]="item" class="form-control" placeholder="RLMCxxxx"/>
                                <input *ngIf="item !== 'code'" [formControlName]="item" class="form-control" />

                                <div *ngIf="form.get(item).hasError('required') && form.get(item).touched" class="error-color">
                                    {{ "The field is required" | translate }}!
                                </div>
                                <div *ngIf="item === 'senderEmail' && form.get(item).hasError('pattern') && form.get(item).touched" class="error-color">
                                    {{ "Above email address is incorrect" | translate }}!
                                </div>
                                <div *ngIf="item === 'code' && form.get('code').hasError('pattern') && form.get('code').touched" class="error-color">
                                    {{ "The field code you entered is invalid" | translate }} ([RLMCxxxx])!
                                </div>
                                <div *ngIf="form.get(item)?.hasError('maxlength') && form.get(item)?.touched" class="error-color">
                                    {{ "The field value is too long" | translate }}!
                                </div>
                            </div>
                        </div>

                        <div id="partner-edit-form-address" formGroupName="address">
                            <label class="d-block col-10 offset-2 tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-b-20">{{'Address' | translate}}</label>

                            <div *ngFor="let item of addressKeys" class="form-group row">
                                <label class="col-2" for="code">{{item.toUpperCase() | translate}}: <span *ngIf="form.get('address').get(item) | isRequiredControlPipe">*</span></label>
                                <div class="col-10">
                                    
                                    <input *ngIf="item === 'zip'" [formControlName]="item" [dropSpecialCharacters]="false" mask="00-000" class="form-control" />
                                    <input *ngIf="item !== 'zip'" [formControlName]="item" class="form-control" />

                                    <div *ngIf="form.get('address').get(item)?.hasError('required') && form.get('address').get(item)?.touched" class="error-color">
                                        {{ "The field is required" | translate }}!
                                    </div>
                                    <div *ngIf="item === 'zip' && form.get('address').get('zip').hasError('pattern') && form.get('address').get('zip').touched" class="error-color">
                                        {{ "The zip code you entered is invalid" | translate }}!
                                    </div>
                                    <div *ngIf="form.get('address').get(item)?.hasError('maxlength') && form.get('address').get(item)?.touched" class="error-color">
                                        {{ "The field value is too long" | translate }}!
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div id="partner-edit-form-locationAdditional" formGroupName="locationAdditional">
                            <label class="d-block col-10 offset-2 tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-b-20">{{'Address information' | translate}}</label>

                            <ng-container *ngFor="let item of additionalKeys">
                                <div *ngIf="form.get('locationAdditional').get(item)" class="form-group row">
                                    <ng-container *ngIf="item != 'lift' && item != 'floor'">
                                        <label class="col-2" [for]="item">{{item.toUpperCase() | translate}}: <span *ngIf="form.get('locationAdditional').get(item) | isRequiredControlPipe">*</span></label>
                                        <div class="col-10">

                                            <input *ngIf="item === 'contactPhoneNO'" prefix="+48 " mask="000-000-000" [formControlName]="item" class="form-control" />
                                            <input *ngIf="item !== 'contactPhoneNO'" [formControlName]="item" class="form-control" />

                                            <div *ngIf="form.get('locationAdditional').get(item)?.hasError('required') && form.get('locationAdditional').get(item)?.touched" class="error-color">
                                                {{ "The field is required" | translate }}!
                                            </div>
                                            <div *ngIf="form.get('locationAdditional').get(item).hasError('pattern') && form.get('locationAdditional').get(item).touched" class="error-color">
                                                {{ "Above email address is incorrect" | translate }}!
                                            </div>
                                            <div *ngIf="item === 'contactPhoneNO' && form.get('locationAdditional').get('contactPhoneNO').hasError('Mask error') && form.get('locationAdditional').get('contactPhoneNO').touched" class="error-color">
                                                {{ "Above phone number you entered is invalid" | translate }}!
                                            </div>
                                            <div *ngIf="form.get('locationAdditional').get(item)?.hasError('maxlength') && form.get('locationAdditional').get(item)?.touched" class="error-color">
                                                {{ "The field value is too long" | translate }}!
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <div class="form-group row">
                                <label class="col-2" for="lift">{{'Lift' | translate}}:</label>
                                <div class="col-10">
                                    <select class="custom-select" formControlName="lift">
                                        <option [value]="true">{{'Yes' | translate}}</option>
                                        <option [value]="false">{{'No' | translate}}</option>
                                    </select>
    
                                    <div *ngIf="form.get('locationAdditional').get('lift')?.hasError('required') && form.get('locationAdditional').get('lift')?.touched" class="error-color">
                                            {{ "Lift field is required" | translate }}!
                                    </div>
                                </div>
                            </div>   
                            <div class="form-group row">
                                <label class="col-2" for="floor">{{'Floor'.toUpperCase() | translate}}:</label>
                                <div class="col-10">
                                    <input formControlName="floor" type="number" class="form-control" />
                                    <div *ngIf="form.get('locationAdditional').get('floor')?.hasError('required') && form.get('locationAdditional').get('floor')?.touched" class="error-color">
                                        {{ "The field is required" | translate }}!
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div id="partner-edit-form-doWFilter" formGroupName="doWFilter">
                            <label class="d-block col-10 offset-2 tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-b-20">{{'On what weekdays orders are delivered?' | translate}}</label>
                            <div class="offset-2 col-10 d-flex flex-wrap justify-content-between">
                                <div *ngFor="let day of weekDaysControls()">
                                    <div class="custom-control custom-checkbox pd-20">
                                        <input [formControlName]="day" [checked]="form.get('doWFilter').get(day).value" type="checkbox" class="custom-control-input" id="customCheck{{day}}">
                                        <label class="custom-control-label justify-content-start" for="customCheck{{day}}">{{'Weekdays.'+day | translate}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="partner-edit-form-btn-save" class="form-group row justify-content-end">
                            <div class="col-2">
                                <button (click)="onSubmit()" [disabled]="!form.valid || submitted"
                                    class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01 w-100 btn-submit">

                                    <span *ngIf="!submitted">{{ "Save" | translate }}</span>

                                    <ng-container *ngIf="submitted">
                                        <div class="load-spinner text-center">
                                            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                        </div>
                                    </ng-container>

                                </button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
</layout-default>

<!-- {{'senderEmail' | translate }} -->
<!-- {{'COMPANYNAME' | translate }} -->
<!-- {{'FLATNO' | translate }} -->
<!-- {{'STAIRCASENO' | translate }} -->
<!-- {{'CONTACTNAME' | translate }} -->
<!-- {{'CONTACTPHONENO' | translate }} -->
<!-- {{'DOMOFONCODE' | translate }} -->
<!-- {{'INSTRUCTIONS' | translate }} -->
<!-- {{'CONTACTEMAIL' | translate }} -->
<!-- {{'FLOOR' | translate }} -->
<!-- {{'SENDEREMAIL' | translate }} -->
<!-- {{'Weekdays.mon' | translate }} -->
<!-- {{'Weekdays.tue' | translate }} -->
<!-- {{'Weekdays.wed' | translate }} -->
<!-- {{'Weekdays.thu' | translate }} -->
<!-- {{'Weekdays.fri' | translate }} -->
<!-- {{'Weekdays.sat' | translate }} -->
<!-- {{'Weekdays.sun' | translate }} -->
<!-- {{'VOIVOIDSHIP' | translate}} -->
<!-- {{'SHORTNAME' | translate}} -->


<!-- {{'tooltips.partner.labels.code' | translate -->
<!-- {{'tooltips.partner.labels.name' | translate -->
<!-- {{'tooltips.partner.labels.shortName' | translate -->
<!-- {{'tooltips.partner.labels.senderEmail' | translate -->
