import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment';
import { Apm } from '../entities/apm';
import { Partner } from '../entities/partner';

@Pipe({
    name: 'findPartnerPipe',
    pure: true
})
export class FindPartnerPipe implements PipeTransform {
    public transform(id: string, partners: Partner[]): any {
        const partner: Partner = partners.find((a: Partner) => a.id === id);
        return (partner) ? `${partner.code} - ${partner.name}` : null;
    }
}
