import { Component, OnDestroy, OnInit } from '@angular/core';

import * as _ from 'lodash';
import { Subscription } from 'rxjs';

import { Driver } from '../entities/driver';
import { DriversService } from '../services/drivers.service';

@Component({
    selector: 'drivers-barcodes',
    template: `
        <layout-default>
            <div header>
                <div toolbar>
                    <h1 class="title">{{ 'Drivers' | translate }}</h1>
                    <button (click)="showDriverBarcode()" class="btn btn-default btn-white"><i class="fas fa-print" style="font-size:1rem"></i> {{ 'Print' | translate }}</button>
                </div>
            </div>
            
            <div content>
                <div class="card-body">
                    <div *ngFor="let driver of drivers; let index = index">
                        <div *ngIf="index % 6 == 5" style="display:block;page-break-before:always;"></div>
                        <div style="padding: 20px; display:flex;">
                            <div style="padding: 5px 10px;margin-top:10px;">
                                <ng-container>
                                    <ngx-barcode6 [bc-value]="driver.employeeId" [bc-display-value]="true"></ngx-barcode6>
                                </ng-container>
                            </div>
                            <div style="margin: 10px;">
                                <p>
                                    <strong>{{ driver.lastname.toUpperCase() }} {{ driver.firstName }}</strong>
                                </p>
                                <p>{{ driver.status }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </layout-default>
    `,
    styleUrls: ['drivers.component.scss']
})
export class DriversBarcodesComponent implements OnInit, OnDestroy {
    public TAG: string = '[DriversComponent]';
    public drivers: Driver[] = [];
    private drivers$: Subscription;

    constructor(private driversService: DriversService) {}

    public ngOnInit(): void {
        this.drivers$ = this.driversService.drivers.subscribe((drivers: Driver[]) => {
            this.drivers = _.sortBy(drivers, item => item.lastname);
        });
    }

    public ngOnDestroy() {
        console.log(`${this.TAG}, ngOnDestroy`);
        this.drivers$.unsubscribe();
    }

    public showDriverBarcode() {
        const printContents = document.getElementById('driver-profile').innerHTML;
        const popupWin = window.open('', '_blank', 'width=700,height=700');
        popupWin.document.open();
        popupWin.document.write(`<html><body onload="window.print()">${printContents}</body></html>`);
        popupWin.document.close();
    }
}
