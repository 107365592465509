import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';

import { Driver } from '@drivers/entities/driver';
import { FormArray, FormGroup, FormBuilder, AbstractControl, FormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '@services/nav.sevice';
import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { User } from '@entities/user';
import { PartnerUser } from 'projects/inpost/src/app/operations/interfaces/partneruser.interface';
import { Depot } from '@interfaces/depot.interface';
import { DepotService } from '../../services/depot.service';
import { AppService } from '@services/app.service';

@Component({
  selector: 'app-depot-assign-users',
  templateUrl: './depot-assign-users.component.html',
  styleUrls: ['./depot-assign-users.component.scss']
})
export class DepotAssignUsersComponent implements OnInit {

  public users: PartnerUser[];
  public assignedUsers: PartnerUser[] = [];

  public depot: Depot;

  public usersForm: FormArray;

  public rawUsers: any[] = [];

  public loader: boolean = true;
  public submitted: boolean = false;

  public form: FormGroup;

  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  private removedUserId: number;
  private removedUser: PartnerUser;

  
  constructor(
    private readonly navigationRoute: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly modalService: BsModalService,
    private toastrProviderService: ToastrProviderService,
    private readonly translateService: TranslateService,
    private readonly navService: NavService,
    private readonly appService: AppService,
    private readonly depotService: DepotService
  ) { }

  public ngOnInit() {
    console.log(this.navigationRoute.snapshot.data);
    this.users = (this.navigationRoute.snapshot.data as {users: any}).users as PartnerUser[];
    this.depot = (this.navigationRoute.snapshot.data as {depot: any}).depot as Depot;
    // this.partner = plainToClass(Partner, (this.navigationRoute.snapshot.data as {partner: any}).partner as Partner);
    // this.region = plainToClass(Region, (this.navigationRoute.snapshot.data as {region: any}).region as Region);
    this.assignedUsers = (this.navigationRoute.snapshot.data as {assignedUsers: any}).assignedUsers as PartnerUser[];
    console.log(this.users);
    this.initForm();
    this.prepareApms();
  }

  public prepareApms() {
    this.users.map((user: PartnerUser) => {
      const isExist = this.assignedUsers.find((u: PartnerUser) => u.id === user.id);
     
      if (isExist === undefined){
        this.rawUsers.push({
          id: user.id, 
          name: `${user.firstName} ${user.lastName}`,
          username: user.username
        });
      }

    });

    this.loader = false;
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      users: this.formBuilder.array([])
    })

    this.usersForm = <FormArray>this.form.controls['users'];
    if (this.assignedUsers.length) {
      this.assignedUsers.forEach((item) => this.usersForm.push(this.patchValues(item)));
    }

    this.usersForm.push(this.patchValues());
  }

  private patchValues(item?: PartnerUser): AbstractControl {
    return this.formBuilder.group({
      id: [{value: item ? item.id : null, disabled: item ? true : false}],
      name: [{value: item ? item.firstName ? `${item.firstName} ${item.lastName}` : item.username : null, disabled: item ? true : false}],
      username: [{value: item ? item.username : null, disabled: item ? true : false}],
    })
  }


  public selectEvent(item) {
    const user: PartnerUser = this.users.find((u: PartnerUser) => u.id === item['id']);
    const num: number = this.usersForm.controls.length;

    this.usersForm.controls[num - 1].setValue({
      id: user.id,
      name: user && user.firstName ? `${user.firstName} ${user.lastName}` : user.username,
      username: user.username,
    }, {onlySelf: true});

    _.remove(this.rawUsers, (u: PartnerUser) => u.id === user.id);

    this.addStep();
  }

  public removeStep(i): void {
    this.removedUser = (<FormArray>this.form.controls['users']).controls[i].value;
    this.removedUserId = i;
    const userId: string = (<FormArray>this.form.controls['users']).controls[i].value['id'];
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.depotService.unassignUserFromDepot(this.depot.id, userId);
  }

  public confirm(): void {
    this.confirmObservable.subscribe((test) => {
      this.modalRef.hide();
      (<FormArray>this.form.controls['users']).removeAt(this.removedUserId);
      this.rawUsers.push({
        id: this.removedUser.id, 
        name: `${this.removedUser.firstName} ${this.removedUser.lastName}`,
        username: this.removedUser.username
      });
      this.toastrProviderService.showSuccess(this.translateService.instant('The user has been unassigned!'));
      this.appService.clearCache();
      this.confirmObservable = null;
    });
  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }

  public addStep(): void {
    this.usersForm.push(this.patchValues());
  }

  public isSetValue(index: number): boolean {
    return ((this.usersForm.controls[index] as FormControl).value['name']);
  }
 
  public onSubmit() {

    const form = this.form.getRawValue();
    const body: string[] = [];

    _.forEach(_.values(form['users']), (user: PartnerUser) => user.id && this.assignedUsers.find(u => user.id === u.id) === undefined ? body.push(user.id) : null)
    
    this.submitted = true;
    this.depotService.assignUsersToDepot(this.depot.id, body)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (region: any) => {
          console.log(region);
          this.toastrProviderService.showSuccess(this.translateService.instant('The user has been assigned to depot!'));
          this.appService.loadWarehouseLocationsDictionary();
          this.navService.goToPage(`/locations/depots/list`, {modifiedRow: this.depot.id});
          this.appService.clearCache();
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );

    
  }

}
