import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Partner } from '../../entities/partner';
import { Region } from '../../entities/region';
import { Driver } from '@drivers/entities/driver';
import { FormArray, FormGroup, FormBuilder, AbstractControl, FormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { RegionService } from '../../services/region.service';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '@services/nav.sevice';
import { Apm, ApmStatus } from '../../entities/apm';
import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ApmService } from '../../services/apm.service';
import { environment } from '@environment';
import { Collection } from '@interfaces/collection.interface';

@Component({
  selector: 'app-assign-apm',
  templateUrl: './assign-apm.component.html',
  styleUrls: ['./assign-apm.component.scss']
})
export class AssignApmComponent implements OnInit {

  public partner: Partner;
  public region: Region;

  public apms: Apm[];
  public apms$: Observable<boolean>;

  public assignApms: Apm[] = [];

  public apmsForm: FormArray;

  public rawApms: any[] = [];

  public loader: boolean = true;
  public submitted: boolean = false;

  public form: FormGroup;

  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  private removedApmId: number;
  private removedApm: Apm;
  
  constructor(
    private navigationRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private regionService: RegionService,
    private modalService: BsModalService,
    private toastrProviderService: ToastrProviderService,
    private translateService: TranslateService,
    private navService: NavService,
    private apmService: ApmService
  ) { }

  public ngOnInit() {

    this.partner = plainToClass(Partner, (this.navigationRoute.snapshot.data as {partner: any}).partner as Partner);
    this.region = plainToClass(Region, (this.navigationRoute.snapshot.data as {region: any}).region as Region);
    this.assignApms = plainToClass(Apm, (this.navigationRoute.snapshot.data as {assignApms: any}).assignApms.content as Apm[]);
    this.initForm();


    this.apms$ = this.apmService.getByDepot(environment.pagination, this.region.depotId).pipe(
      tap((apm) => this.apms = apm.content),
      tap(() =>  this.prepareApms()),
      map(() => true)
    );

    // plainToClass(Apm, (this.navigationRoute.snapshot.data as {apm: any}).apms.content as Apm[]);

 
   
  }

  public prepareApms() {
    this.apms.map((a: Apm) => {
      const selectedApm: Apm | undefined = this.assignApms.find((apm: Apm) => a.id === apm.id);
      
      if (selectedApm === undefined && a.status === ApmStatus.AVAILABLE){
        this.rawApms.push({
          id: a.id, 
          name: a.name,
          capacityLabel: a.capacityLabel
        });
      }

    });

    this.loader = false;
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      apms: this.formBuilder.array([])
    })

    this.apmsForm = <FormArray>this.form.controls['apms'];
    if (this.assignApms.length) {
      this.assignApms.forEach((item) => this.apmsForm.push(this.patchValues(item)));
    }

    this.apmsForm.push(this.patchValues());
  }

  private patchValues(item?: Apm): AbstractControl {
    return this.formBuilder.group({
      id: [{value: item ? item.id : null, disabled: true }],
      name: [{value: item ? item.name : null, disabled: true }],
      code: [{value: item ? item.code : null, disabled: true }],
      status: [{value: item ? this.translateService.instant(`RlmStatus.${item.status}`) : null, disabled: true }],
    })
  }


  public selectEvent(item) {
    const apm: Apm = this.apms.find((a: Apm) => a.id === item['id']);
    const num: number = this.apmsForm.controls.length;

    this.apmsForm.controls[num - 1].setValue({
      id: apm.id,
      name: apm.name,
      code: apm.code,
      status: this.translateService.instant(`RlmStatus.${apm.status}`)
    }, {onlySelf: true});

    _.remove(this.rawApms, (a: Apm) => a.id === apm.id);

    this.addStep();
  }

  public removeStep(i): void {
    this.removedApm = (<FormArray>this.form.controls['apms']).controls[i].value;
    this.removedApmId = i;
    const ampId: string = (<FormArray>this.form.controls['apms']).controls[i].value['id'];
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.regionService.unassignApmFromRegion(this.partner.id, this.region.id, ampId);
  }

  public confirm(): void {
    this.confirmObservable.subscribe((test) => {
      this.modalRef.hide();
      (<FormArray>this.form.controls['apms']).removeAt(this.removedApmId);
      this.rawApms.push({
        id: this.removedApm.id, 
        name: this.removedApm.name,
        capacityLabel: this.removedApm.capacityLabel
      });
      this.toastrProviderService.showSuccess(this.translateService.instant('The apm has been unassigned!'));
      this.confirmObservable = null;
    });
  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }

  public addStep(): void {
    this.apmsForm.push(this.patchValues());
  }

  public isSetValue(index: number): boolean {
    return ((this.apmsForm.controls[index] as FormControl).value['name']);
  }
 
  public onSubmit() {

    const form = this.form.getRawValue();
    const body: string[] = [];

    _.forEach(_.values(form['apms']), (a: Apm) => a.id ? body.push(a.id) : null)
    this.submitted = true;
    this.regionService.assignApmsToRegion(this.partner.id, this.region.id, body)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (region: any) => {
          console.log(region);
          this.toastrProviderService.showSuccess(this.translateService.instant('The Rlm has been assigned to region!'));
          this.navService.goToPage(`/partner/${this.partner.id}/region/list`, {modifiedRow: this.region.id});
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );

    
  }

}
