import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { RegionService } from '../services/region.service';

@Injectable()
export class AssignDriversResolver implements Resolve<any> {
    constructor(private regionService: RegionService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        const params = {
            partnerId: route.params['partnerId'],
            regionId: route.params['regionId']
        }
        return this.regionService.getAssignDriverToRegion(params['partnerId'], params['regionId']);
    }
}
