import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Timeslot } from '../../interfaces/timeslot.interface';
import { ORDER } from '@enums/enum';
import { PageableResponse } from '@entities/pagable-response';
import { TimeslotService } from '../../services/timeslot.service';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { NavService } from '@services/nav.sevice';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ConfigScopes } from '../../enums/config-scopes';
import { UtilsService } from '@services/utils.service';

@Component({
  selector: 'app-timeslot',
  templateUrl: './timeslot.component.html',
  styleUrls: ['./timeslot.component.scss']
})
export class TimeslotComponent implements OnInit {

  public timeslots: Timeslot[];
  private timeslotsCollection: Timeslot[];

  public partnerId: string;
  public regionId: string;

  protected page: number = 0;
  protected size = 10;
  protected order: ORDER = ORDER.DESC;
  protected sortBy: string = 'createdAt';
  protected pagable: PageableResponse<Timeslot>;
  
  public pageInfo;
  public modifiedRow: string;
  public rowClass;

  public loader: boolean = true;

  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  public columns = [
    { prop: 'name', name: this.translateService.instant('Name'), flexGrow: 2 },
    { prop: 'code', name: this.translateService.instant('Code'), flexGrow: 2 },
    { prop: 'timeslot', name: this.translateService.instant('Timeslot'), flexGrow: 2 },
    { prop: 'pickupSlot', name: this.translateService.instant('Courier pickup time'), flexGrow: 3 },
    { prop: 'putInTimeWindow', name: this.translateService.instant('Put in time window'), flexGrow: 2 },
    { prop: 'customerTimeWindow', name: this.translateService.instant('Customer time window'), flexGrow: 3 },
    { prop: 'purgeTimeWindow', name: this.translateService.instant('Purge time window'), flexGrow: 3 },
    { prop: 'ownLogisticCancelTime', name: this.translateService.instant('Own logistic cancel time'), flexGrow: 3 },
    { prop: 'ownLogisticCutoff', name: this.translateService.instant('Own logistic cutoff'), flexGrow: 3 },
    { prop: 'active', name: this.translateService.instant('Active'), flexGrow: 2 },
    { prop: 'options', name: this.translateService.instant('Options'), flexGrow: 2 },
  ];

  constructor(
    private timeslotService: TimeslotService,
    private navigationRoute: ActivatedRoute,
    private translateService: TranslateService,
    private modalService: BsModalService,
    private toastrProviderService: ToastrProviderService,
    private readonly utilsService: UtilsService,
    private navService: NavService
  ) { }

  public ngOnInit() {
    this.timeslots = this.timeslotsCollection = _.sortBy((this.navigationRoute.snapshot.data as {timeslots}).timeslots as Timeslot[], ['timeslot.fromTime']);
    this.modifiedRow = this.navigationRoute.snapshot.params.modifiedRow;
    this.rowClass = row => {
      return { 'row-modified': row.id === this.modifiedRow };
    };

    this.partnerId = this.utilsService.findUpRouteParams(this.navigationRoute.snapshot, 'partnerId');
    this.regionId = this.utilsService.findUpRouteParams(this.navigationRoute.snapshot, 'regionId');
    this.loader = false;
  }

  public goToDetails(id: string): void {
    this.navService.goToPage(`/partner/${this.partnerId}/region/${this.regionId}/timeslots/edit/${id}`);
  }

  public addTimeslot(): void {
    this.navService.goToPage(`/partner/${this.partnerId}/region/${this.regionId}/timeslots/create`);
  }

  public goToConfig(timeslot: Timeslot): void {
    this.regionId 
      ? this.navService.goToPage(`/partner/${timeslot.partnerId}/region/${this.regionId}/timeslots/config-value/${timeslot.id}/${ConfigScopes.Timeslot}/list`)
      : this.navService.goToPage(`/partner/${timeslot.partnerId}/timeslots/config-value/${timeslot.id}/${ConfigScopes.Timeslot}/list`)
  }

  public fetchTimeslotsForRegion(): void {
    this.timeslotService.getTimeSlotsForRegion(this.partnerId, this.regionId).subscribe(
      (timeslots: any) => {
        this.pagable = timeslots;
        this.timeslots = this.timeslotsCollection = _.sortBy(timeslots as Timeslot[], ['timeslot.fromTime']);
        this.loader = false;
      },
      () => {
        this.loader = false;
      })
  }

  public fetchTimeslotsForPartner(): void {
    this.timeslotService.getTimeSlotsForPartner(this.partnerId).subscribe(
      (timeslots: any) => {
        this.pagable = timeslots;
        this.timeslots = this.timeslotsCollection = _.sortBy(timeslots as Timeslot[], ['timeslot.fromTime']);
        this.loader = false;
      },
      () => {
        this.loader = false;
      })
  }

  public applyFiltering(event) {
    const filterPhrase = event.target.value.toLowerCase();
    const temp = _.filter(this.timeslotsCollection, (t: Timeslot) => {
      const searchIn: string = t.name;
      return searchIn.toLowerCase().indexOf(filterPhrase) !== -1 || !filterPhrase;
    });

    this.timeslots = temp;
  }

  public removeTimeslot(id: string): void {
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.timeslotService.delete(id);
  }

  public confirm(): void {
    this.confirmObservable.subscribe((test) => {
      this.modalRef.hide();
      this.pageInfo = null;
      this.modifiedRow = null;
      this.regionId ? this.fetchTimeslotsForRegion() : this.fetchTimeslotsForPartner();      
      this.toastrProviderService.showSuccess(this.translateService.instant('The timeslot has been removed!'));
      this.confirmObservable = null;
    });
  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }


}
