import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { PartnerService } from '../services/partner.service';

@Injectable()
export class PartnerServiceResolver implements Resolve<any> {
    constructor(private partnerService: PartnerService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        return this.partnerService.getPartnerService();
    }
}
