import { Pipe, PipeTransform } from '@angular/core';
import { ApmExtended } from 'projects/inpost/src/app/operations/interfaces/apm-extended.interface';

@Pipe({
    name: 'checkAvailabilityInRlmPipe',
    pure: true
})
export class CheckAvailabilityInRlmPipe implements PipeTransform {

    public transform(apmExt: ApmExtended): boolean {
        if (apmExt.apm.lcount > apmExt.availableVolume.lcount || 
            apmExt.apm.mcount > apmExt.availableVolume.mcount ||
            apmExt.apm.ncount > apmExt.availableVolume.ncount ||
            apmExt.apm.ocount > apmExt.availableVolume.ocount ) {
            return true;
        } else {
            return false;
        }
    }
}
