import { TimeWindow } from '@entities/time-window';
import { Type } from 'class-transformer';
import { serializeType } from '@shared/functions/serializeType.function';

export class Subscription {
    public apmId:     string;
    public code:      string;

    @Type(serializeType(TimeWindow))
    public timeWindow:      TimeWindow;

    public id:        string;
    public lcount:    number;
    public mcount:    number;
    public ocount:    number;
    public name:      string;
    public ncount:    number;
    public partnerId: string;
    public to:        string;
    public version:   number;

    get capacityLabel(): string {
        return `${this.lcount}/${this.mcount}/${this.ncount}/${this.ocount}`;
    }
}
