<layout-default>
        <div header>
            <div toolbar>
                <h4>
                    <i-feather name="users"></i-feather> {{'Partners' | translate}}
                </h4>
            </div>
    
            <div toolbar>
                <button id="partner-btn-create" class="btn btn-sm btn-uppercase btn-white btn-create-partner"
                    [routerLink]="['/partner/create']">
                    <i-feather [style.height.px]="23" [style.width.px]="13" class="mg-r-10" name="plus"></i-feather> {{ 'Add partner' | translate }}
                </button>
                <div class="filter-container">
                    <div id="partner-btn-search" class="phrase-filter">
                        <input type='text' class="list-filter form-control" placeholder="{{'type to search' | translate}}" (keyup)='applyFiltering($event)'/>
                    </div>
                </div>
            </div>
            <hr />
        </div>
        
        <div content>
            <div *ngIf="(list$ | async) as list">
                <ngx-datatable 
                    id="apms" 
                    class="bootstrap" 
                    [rows]="partners" 
                    [columns]="columns" 
                    [columnMode]="'flex'"
                    [headerHeight]="50" 
                    [footerHeight]="50" 
                    [messages]="tableMessages" 
                    [rowHeight]="'auto'" 
                    [externalPaging]="true"
                    [count]="list.totalElements"
                    [offset]="queryParams.page"
                    [rowClass]="rowClass"
                    [limit]="queryParams.size"
                    [scrollbarH]="scrollBarHorizontal"
                    (sort)="onSort($event)"
                    (page)="setPage($event)">
                    <ng-container *ngFor="let col of columns">
                        <ngx-datatable-column [flexGrow]="col.flexGrow" [prop]="col.prop">
                            <ng-template let-column="column" ngx-datatable-header-template class="header" let-sort="sortFn">
                                <b (click)="sort()">{{ col.name | translate }}</b>
                            </ng-template>
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>

                                <div [ngSwitch]="col.prop">
                                    <div *ngSwitchCase="'options'">
                                        <div *ngIf="row.status === 'ACTIVE'" class="dropdown" dropdown>
                                            <button
                                                class="btn btn-outline-secondary btn-xs dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton">
                                                {{ 'Select' | translate }}
                                            </button>
        
                                            <div class="dropdown-menu dropdown-menu-right div-main-actions">

                                                <a 
                                                    id="partner-btn-show-distribution-center"
                                                    appCheckFeaturesConfig [featureName]="[Permissions.Partner_edit]"
                                                    class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-show-dc" 
                                                    (click)="goToDc(row.id)">
                                                        {{'Show distribution center' | translate }}
                                                </a>

                                                <a 
                                                    id="partner-btn-regions"
                                                    class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-show-regions" 
                                                    (click)="goToRegions(row.id)">
                                                        {{'Regions' | translate }}
                                                </a>

                                                <a 
                                                    id="partner-btn-timeslots"
                                                    appCheckFeaturesConfig [featureName]="[Permissions.Partner_edit]"
                                                    class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-show-timeslots" 
                                                    [routerLink]="['/partner/', row.id, 'timeslots', 'list']">
                                                        {{'Timeslots' | translate }}
                                                </a>
    
                                                <a 
                                                    id="partner-btn-users"
                                                    appCheckFeaturesConfig [featureName]="[Permissions.Partner_edit]"
                                                    class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-show-users" 
                                                    (click)="goToPartnerUsers(row.id)">
                                                        {{'Show users' | translate }}
                                                </a>
    
                                                <a 
                                                    id="partner-btn-configurations"
                                                    appCheckFeaturesConfig [featureName]="[Permissions.Partner_edit]"
                                                    class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-show-configuration" 
                                                    (click)="goToConfig(row.id)">
                                                        {{'Show configuration' | translate }}
                                                </a>
    
                                                <a 
                                                    id="partner-btn-apm-deliveries"
                                                    class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-show-apm-deliveries" 
                                                    (click)="goToApmDeliveries(row.id)">
                                                        {{'Show apm deliveries' | translate }}
                                                </a>

                                                <a 
                                                    id="partner-btn-reports"
                                                    class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-show-timeslots" 
                                                    [routerLink]="['/partner/', row.id, 'reports', 'generate']">
                                                        {{'Reports' | translate }}
                                                </a>
    
                                                <a 
                                                    id="partner-btn-edit-partner"
                                                    appCheckFeaturesConfig [featureName]="[Permissions.Partner_edit]"
                                                    class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-edit" 
                                                    (click)="goToDetails(row.id)">
                                                        {{'Edit' | translate }}
                                                </a>
        
                                                <a 
                                                    id="partner-btn-remove-partner"
                                                    appCheckFeaturesConfig [featureName]="[Permissions.Partner_edit]"
                                                    class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                                    (click)="removePartner(row.id)">
                                                        {{'Remove' | translate }}
                                                </a>
        
                                            </div>
                                        </div>
                                    </div>

                                    <div id="partner-table-col-status" *ngSwitchCase="'status'">
                                        <ng-container [ngSwitch]="value">
                                            <span *ngSwitchCase="'ACTIVE'" class="badge badge-success">{{ 'PartnerStatus.'+value | translate }}</span>
                                            <span *ngSwitchCase="'ARCHIVED'" class="badge badge-danger">{{ 'PartnerStatus.'+value | translate }}</span>
                                            <span *ngSwitchDefault class="badge badge-info">{{ 'PartnerStatus.'+value | translate }}</span>
                                        </ng-container>
                                    </div>

                                    <div id="partner-table-col-address" *ngSwitchCase="'address'">{{value | addressLabelPipe}}</div>

                                    <div id="partner-table-col-{{col.prop}}" *ngSwitchDefault>{{value}}</div>
                                </div>


                                    
                               
                            </ng-template>
                        </ngx-datatable-column>
                    </ng-container>
                </ngx-datatable>
            </div>
    
            <div *ngIf="loader" class="load-spinner text-center">
                <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}
            </div>
    
        </div>
    
    </layout-default>
    
    <ng-template #confirmation>
        <div class="modal-header">
            <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span aria-hidden="true">&times;</span></button>
        </div>
    
        <div class="modal-body text-center">
            <p>{{'Do you confirm remove the Partner' | translate }}?</p>
            <button type="button" class="btn btn-brand-01" (click)="confirm()">
                {{'Yes, remove' | translate }}
            </button>
            <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
        </div>
    </ng-template>
    
    <!-- {{'PartnerStatus.ACTIVE' | translate}} -->
    <!-- {{'PartnerStatus.ARCHIVED' | translate}} -->


    <!-- 
function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

var r = [];

document.querySelectorAll('*[id]').forEach(a => {r.push(a.id)});

r = r.filter(onlyUnique)

r.forEach(a => console.log(a));

const p =  r.forEach(a => a + '\n');

console.table(r);
     -->