import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'subscriptionVolumeErrorPipe',
    pure: true
})
export class SubscriptionVolumeErrorPipe implements PipeTransform {
    public transform(message: string, errorMessage: string): any {

        console.log(message);
        console.log(errorMessage);
        const volume = errorMessage.match(/(\d*, \d*, \d*, \d*)/g);
        const date = moment(errorMessage.match(/\d{4}-\d{2}-\d{2}/g)).format('D-MM-YYYY');

        if (volume) {
            message = message.replace('{{userVolume}}', volume[0]);
            message = message.replace('{{apmVolume}}', volume[1]);
            message = message.replace('{{date}}', date);
        } 
        return message;
    }
    
}
