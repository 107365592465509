<layout-default>
  <div class="d-print-none hidden-print" header>
      <div toolbar>
          <h4>
              <i-feather name="cast"></i-feather> {{'Apm delivery details' | translate}}
          </h4>
      </div>
      <div toolbar>
        <button id="drivers-availability-btn-print" class="btn btn-sm btn-uppercase btn-brand-01" (click)="print()">
            <i-feather name="printer"></i-feather> {{'Print' | translate}}
        </button>
      </div>
      <hr />
  </div>

  <div content>

    <div class="row visible-print-block">

      <ng-container *ngIf="(newApmDelivery$ | async) as newApmDelivery">
        <div class="col-6 card-content">
          <h4 class="mg-b-15">{{'New apm delivery' | translate}}:</h4>
          <ng-container *ngTemplateOutlet="details; context: { apmDelivery: newApmDelivery}"></ng-container>
        </div>
      </ng-container>


      <div class="col-6 card-content">
        <h4 class="mg-b-15" *ngIf="isSuperseded">{{'Old apm delivery' | translate}}:</h4>
        <ng-container *ngTemplateOutlet="details; context: { apmDelivery: apmDelivery}"></ng-container>
      </div>

      <div *ngIf="(couriers$ | async) as couriers" class="col-12 card-content">
        <h4 class="mg-b-15">{{'List of partner couriers' | translate}}:</h4>
        <ul>
          <li *ngFor="let courier of couriers.content">
            {{courier.firstName}} {{courier.lastName}} {{courier.contactPhone}}
          </li>
        </ul>
      </div>
    </div>
      

  </div>
</layout-default>


<ng-template #details let-apmDelivery="apmDelivery">

  <ng-container *ngFor="let field of fields">

    <ng-container [ngSwitch]="field">
      <ng-container *ngSwitchCase="'logisticType'">
        <p class="mg-b-2" *ngIf="apmDelivery[field] as value">{{field | translate}}: <strong>{{'ApmDeliveryType.' + value | translate}}</strong></p>
      </ng-container>
      <ng-container *ngSwitchCase="'status'">
        <p class="mg-b-2" *ngIf="apmDelivery[field] as value">{{'Status' | translate}}: <strong>{{'ApmDeliveryStatus.' + value | translate}}</strong></p>
      </ng-container>
      <ng-container *ngSwitchCase="'apm'">
        <p class="mg-b-2" *ngIf="apmDelivery[field] as value">{{'Location' | translate}}: <strong>[{{value.code}}] {{value.address | addressLabelPipe}}</strong></p>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <p class="mg-b-2" *ngIf="apmDelivery[field] as value">{{field | translate}}: <strong>{{value | translate}}</strong></p>
      </ng-container>
    </ng-container>

  </ng-container>



  <ng-container *ngFor="let field of timeFields">
    <p class="mg-b-2" *ngIf="apmDelivery[field] as value">{{field | translate}}: <strong>{{value | timeWindowPipe}}</strong></p>
  </ng-container>

  <div *ngIf="apmDelivery.lockers.distribution.length">
    <h6 class="booking-summary__label" i18n>{{'Compartments' | translate}}</h6>
    <ul>
        <li *ngFor="let lockers of apmDelivery.lockers.distribution"
            class="booking-summary__value">
            <span *ngIf="lockers.subscriptionId !== null">{{lockers.subscriptionId}} -
            </span>
            <span *ngIf="lockers.subscriptionId === null">{{'Shared pool' | translate}}
                - </span>

            <span [containerClass]="'pretty-tooltip'" placement="right"
                [tooltip]="lockers.volume | rlmCapacityLabelPipe">{{lockers.volume | rlmCapacityLabelShortPipe}}</span>
        </li>

    </ul>
</div>
</ng-template>

<!-- {{'logisticType' | translate}} -->