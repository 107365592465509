import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment';
import { Apm } from '../entities/apm';

@Pipe({
    name: 'findApmPipe',
    pure: true
})
export class FindApmPipe implements PipeTransform {
    public transform(id: string, apms: Apm[]): any {
        const apm: Apm = apms.find((a: Apm) => a.id === id);
        return `${apm.code} - ${apm.name}`;
    }
}
