import { Additional } from '@entities/additional';
import { serializeType } from '@shared/functions/serializeType.function';
import { Type } from 'class-transformer/decorators';
import { Address } from '@entities/address';
import { Region } from './region';
import { WeekDays } from '../interfaces/weekdays.interface';

export class Partner {

    @Type(serializeType(Address))
    public address: Address;

    @Type(serializeType(Additional))
    public locationAdditional: Additional;

    @Type(serializeType(Region))
    public regionList: Region[];

    public id: string;
    public code: string;
    public shortName: string;
    public name: string;
    public senderEmail: string;
    public version: string;
    public status: string

    public doWFilter: WeekDays;


    get label(): string {
        return `${this.name} - ${this.code}`;
    }
}
