import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Apm } from '../../../entities/apm';
import { ParcelLockersService } from '../../../services/parcel-lockers.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Timeslot } from '../../../interfaces/timeslot.interface';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { CompartmentType, LogisticTypeEnum } from '@enums/enum';
import { ApmDelivery } from '../../../entities/apm-delivery';
import { ApmDeliveryMove } from '../../../interfaces/apm-delivery-move.interface';
import { ApmDeliveryService } from '../../../services/apm-delivery.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, ReplaySubject } from 'rxjs';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { ApmCapacity } from '../../../interfaces/apm-capacity.interface';
import { Partner } from '../../../entities/partner';
import { ApmForPartnerService } from '../../../services/apm-for-partner.service';
import { PageableResponse } from '@entities/pagable-response';
import { QueryParams } from '@interfaces/query-params.interface';
import { environment } from '@environment';
import { HttpErrorResponse } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';

interface CalendarColumn {
  isToday: boolean;
  isWeekend: boolean;
  date: string;
  timeSlots: Timeslot[];
}

@Component({
  selector: 'app-apm-availability',
  templateUrl: './apm-availability.component.html',
  styleUrls: ['./apm-availability.component.scss']
})
export class ApmAvailabilityComponent implements OnInit {

  public apm: Apm;
  public apmDelivery: ApmDelivery;

  public compartments = { lcount: 0, mcount: 0, ncount: 0, ocount: 0 };
  public rowsLength: number[] = [];

  public columns$;
  public rowsCount: number = 0;

  public locale = 'pl_PL'

  public startDate: string;
  public endDate: string;
  public currentWeekChange = 0;
  public partner: Partner;
  public otherApmsInRegions$: Observable<PageableResponse<Apm>>;
  protected queryParams: QueryParams = Object.assign({}, environment.pagination);

  public selectedTimeslot: Timeslot;
  public toStr = JSON.stringify;
  public loader = true;
  public sameDay = false;
  public otherApm = false;
  public apmId: string;
  public slotTime: string;
  public popupLoader = false;

  private readonly weekChangeSubject: ReplaySubject<number> = new ReplaySubject<number>();

  @ViewChild('confirmation', { static: true }) private confirmation: TemplateRef<any>;
  private confirmObservable: Observable<any>;
  private modalRef: BsModalRef;


  constructor(
    private readonly navigationRoute: ActivatedRoute,
    private readonly parcelLockersService: ParcelLockersService,
    private readonly translateService: TranslateService,
    private readonly apmDeliveryService: ApmDeliveryService,
    private readonly modalService: BsModalService,
    private toastrProviderService: ToastrProviderService,
    private readonly apmForPartnerService: ApmForPartnerService,
    private readonly router: Router
  ) { }

  public ngOnInit() {
    this.translateService.onLangChange.subscribe((lang: LangChangeEvent) => this.locale = lang.lang);
    this.partner = (this.navigationRoute.snapshot.data as {partner: any}).partner;

    this.weekChangeSubject.next(this.currentWeekChange);
    const weekChangeObservable = this.weekChangeSubject.asObservable();

    this.apm = (this.navigationRoute.snapshot.data as {apm: any}).apm;
    this.apmDelivery = (this.navigationRoute.snapshot.data as {apmDelivery: any}).apmDelivery;
    
    this.sameDay = this.navigationRoute.snapshot.params.sameDay;
    this.otherApm = this.navigationRoute.snapshot.params.otherApm;

    this.apmId = this.navigationRoute.snapshot.params.apmId2;
    this.slotTime = this.navigationRoute.snapshot.params.slotTime;

    this.otherApmsInRegions$ = this.apmForPartnerService.list$;
    Object.assign(this.queryParams, {apmId: this.apm.id, partnerId: this.partner.id})
    this.apmForPartnerService.fetchAll(this.queryParams);
    
    if(this.apmDelivery) {
      this.apmDelivery['lockers'].distribution.map((v: { volume: ApmCapacity}) => {
        this.compartments.lcount += v.volume.lcount;
        this.compartments.mcount += v.volume.mcount;
        this.compartments.ncount += v.volume.ncount;
        this.compartments.ocount += v.volume.ocount;
      })
    }

    this.columns$ = weekChangeObservable
      .pipe(
        switchMap((weekChange) => {
        this.loader = true;
        if (this.sameDay) {
          this.startDate = moment(this.apmDelivery.reservationWindow.from.toString().slice(0, 10)).format('YYYY-MM-DD');
          this.endDate = this.startDate;
        } else if (this.otherApm) {
          this.startDate = moment(this.apmDelivery.reservationWindow.from.toString().slice(0, 10)).format('YYYY-MM-DD');
          this.endDate = moment(this.startDate).add(1, 'days').format('YYYY-MM-DD');
        } else {
          this.startDate = moment().startOf('isoWeek').add(weekChange, 'week').format('YYYY-MM-DD');
          this.endDate = moment(this.startDate).add(6, 'days').format('YYYY-MM-DD');
        }
        
        return this.parcelLockersService.getAvailableCompartments(this.apm.id, this.partner.id, this.startDate, this.endDate, false)
      }),
        map(result => this.buildCalendarColumns(result)));
  }


  public buildCalendarColumns(apmAvailability: any[]): any {

    let minDate = moment(this.startDate);
    const maxDate = moment(this.endDate);

    const groupedByDate = _.chain(apmAvailability).groupBy('date').sortBy().value();
    const results: CalendarColumn[] = [];

    _.values(groupedByDate).forEach((dayAvailability: any[]) => {
      const timeslots: Timeslot[] = [];
      const date = moment(`${dayAvailability[0].date}`);

      dayAvailability.forEach((availabilityItem: any) => {
        const timeslotStartTime = moment(`${availabilityItem.date} ${availabilityItem.reservedTimeWindow.fromTime}`);
        const timeslot: any = {
          ...availabilityItem,
          isDisabled: timeslotStartTime.isBefore(moment().format('YYYY-MM-DD HH:mm:ss')) || !this.checkAvailableLockersCapacity(availabilityItem) || availabilityItem.maintenanceWindow || this.checkPickupAvailability(availabilityItem.pickupAvailability),
          compartments: [{
            count: availabilityItem.lcount,
            size: CompartmentType.Cooler
          }, {
            count: availabilityItem.mcount,
            size: CompartmentType.Freezer
          }, {
            count: availabilityItem.ncount,
            size: CompartmentType.Ambient
          }, {
            count: availabilityItem.ocount,
            size: CompartmentType.Other
          }]
        };
        timeslots.push(timeslot);
      });


      results.push({
        isToday: date.isSame(moment(), 'day'),
        isWeekend: [6, 7].includes(date.isoWeekday()),
        date: date.format('YYYY-MM-DD'),
        timeSlots: _.sortBy(timeslots, (t: Timeslot) => t.timeslot['timeslot'].fromTime)
      } as CalendarColumn);


    });

    while (minDate.isBefore(maxDate)) {
      const dayExist = results.find((day: CalendarColumn) => day.date === minDate.format('YYYY-MM-DD'));

      if (!dayExist) {
        results.push({
          isToday: minDate.isSame(moment(), 'day'),
          isWeekend: [6, 7].includes(minDate.isoWeekday()),
          date: minDate.format('YYYY-MM-DD'),
          timeSlots: []
        } as CalendarColumn);
      }

      minDate.add(1, 'day');
    }

    const res = _.sortBy(results, 'date');

    let rowsCount = 0;

    res.map((row: CalendarColumn) => {
      rowsCount = (rowsCount < row.timeSlots.length) ? row.timeSlots.length : rowsCount
    });

    this.rowsLength = new Array(rowsCount);

    this.loader = false;

    return _.sortBy(results, 'date');
  }

  public checkAvailableLockersCapacity(item): boolean {
    return item.lcount + item.mcount + item.ncount + item.ocount > 0 && 
          this.compartments.lcount <= item.lcount &&
          this.compartments.mcount <= item.mcount &&
          this.compartments.ncount <= item.ncount &&
          this.compartments.ocount <= item.ocount;
  }

  public checkPickupAvailability(pickup: boolean): boolean {
    return this.apmDelivery ? !pickup : false;
  }

  public prevWeek() {
    this.currentWeekChange += -1;
    this.weekChangeSubject.next(this.currentWeekChange);
  }

  public nextWeek() {
    this.currentWeekChange += 1;
    this.weekChangeSubject.next(this.currentWeekChange);
  }

  public onSlotChange(date, timeslot) {
    this.selectedTimeslot = timeslot;
  }

  public onChangeApm(apm: string) {
    this.apm = JSON.parse(apm);
    this.weekChangeSubject.next(this.currentWeekChange);
  }

  public moveDeliveryConfirmation(): void {

    const body: ApmDeliveryMove = {
      apmDeliveryId: this.apmDelivery.id,
      apmId: this.apm.id,
      logisticType: LogisticTypeEnum.INPOST,
      partnerId: this.partner.id,
      reservationDate: this.selectedTimeslot['date'],
      timeslotId: this.selectedTimeslot.timeslot['id']
    }

    this.modalRef = this.modalService.show(this.confirmation, { class: 'modal-md' });
    this.confirmObservable = this.apmDeliveryService.rescheduleDelivery(this.apmDelivery.id, body)
  }

  public confirm(): void {
    this.popupLoader = true;
    this.confirmObservable.subscribe(
      () => {
        this.popupLoader = false;
        this.modalRef.hide();
        this.confirmObservable = null;
        this.toastrProviderService.showSuccess(this.translateService.instant('The delivery has been moved'));
        this.router.navigate(['/apm', this.apmId, 'apm-deliveries', 'list', {slotTime: this.slotTime}]);
      },
      (err: HttpErrorResponse) => {
        switch (err.status) {
          case 404:
            this.toastrProviderService.showError(this.translateService.instant('The delivery not found'));
            break;
          default : 
            this.toastrProviderService.showError(this.translateService.instant(err.error.message));
            break;
        }
        this.modalRef.hide();
        this.confirmObservable = null;
      });
  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }
}
