import { Component, Inject } from '@angular/core';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { AppLang } from '@enums/enum';

@Component({
    template: ``,
    styleUrls: ['../shared/components/main.component.scss']
})
export class AnalyticsComponent {
    public tag = '[AppComponent]';

    constructor(private translateService: TranslateService) {
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => moment.locale(event.lang));
        const language = localStorage.getItem('lang') || AppLang.PL;
        this.translateService.setDefaultLang(language);
        this.translateService.use(language);
    }
}
