
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { SubscriptionService } from '../services/subscription.service';

@Injectable()
export class SubscriptionResolver implements Resolve<any> {
    constructor(private subscriptionService: SubscriptionService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        const params = route.params;
        return this.subscriptionService.findOne(params['subscriptionId']);
    }
}
