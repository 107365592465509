<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="sliders"></i-feather> {{'Reschedule apm delivery' | translate}}
            </h4>
        </div>
        <div toolbar>
            <div id="reschedule-searchbar" class="filter-container">
                <div class="phrase-filter">
                    <input type='text' class="list-filter form-control" placeholder="{{'type to search' | translate}}" (keyup)='applyFiltering($event)'/>
                </div>
            </div>
        </div>
        <hr />
    </div>
    
    <div content>

        <div class="row">
            <div id="reschedule-delivery-info" class="col bg">
                <label class="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1 pd-l-10 pd-t-20">{{'Delivery' | translate}}</label>
                <p class="pd-l-10">{{'Reference' | translate}}: <strong>{{apmDelivery?.reservationNumber}}</strong>, 
                    {{'Apm' | translate}}: <strong>{{apmDelivery?.apm.name}}</strong>,
                    {{'Slot' | translate}}: <strong>{{apmDelivery?.reservationWindow.from | amLocale: locale | amDateFormat: 'D MMM YYYY, HH:mm'}} - {{apmDelivery?.reservationWindow.to | amLocale: locale | amDateFormat: 'D MMM YYYY, HH:mm'}}</strong>  
                </p>
            </div>
        </div>

           <div class=""> 
                <div *ngIf="(list$ | async) as list">

                    <ngx-datatable 
                        id="apms" 
                        class="bootstrap" 
                        [rows]="list.content" 
                        [columns]="columns" 
                        [columnMode]="'flex'"
                        [headerHeight]="50" 
                        [footerHeight]="50" 
                        [messages]="tableMessages" 
                        [rowHeight]="'auto'" 
                        [externalPaging]="true"
                        [count]="list.totalElements"
                        [offset]="queryParams.page"
                        [rowClass]="rowClass"
                        [limit]="queryParams.size"
                        [scrollbarH]="scrollBarHorizontal"
                        (sort)="onSort($event)"
                        (page)="setPage($event)">
                        <ng-container *ngFor="let col of columns">
                            <ngx-datatable-column [name]="col.prop" [flexGrow]="col.flexGrow" [prop]="col.prop" [headerClass]="col.headerClass" [sortable]="col.sortable">
                                <ng-template let-column="column" ngx-datatable-header-template class="header" let-sort="sortFn">
                                    <b (click)="sort()">{{ col.name | translate }}</b>
                                </ng-template>
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        
                                    <div [ngSwitch]="col.prop">
                                        <div *ngSwitchCase="'address'">
                                                <span>{{row.address | addressLabelPipe}}</span>
                                            </div>
                                        <div *ngSwitchCase="'lcount'">
                                            <span 
                                                [containerClass]="'pretty-tooltip'" 
                                                placement="right" 
                                                [tooltip]="row | rlmCapacityLabelPipe">{{row | rlmCapacityLabelShortPipe}}</span>
                                        </div>
                                        <div *ngSwitchCase="'status'">
                                            <ng-container [ngSwitch]="value">
                                                <span *ngSwitchCase="'NOT_AVAILABLE'" class="badge badge-danger">{{ 'RlmStatus.'+value | translate }}</span>
                                                <span *ngSwitchCase="'AVAILABLE'" class="badge badge-success">{{ 'RlmStatus.'+value | translate }}</span>
                                                <span *ngSwitchDefault class="badge badge-info">{{ 'RlmStatus.'+value | translate }}</span>
                                            </ng-container>
                                        </div>
                                        <div *ngSwitchCase="'options'">
                                          
                                            
                                            <a 
                                                id="btn-check-availability"
                                                class="btn btn-outline-dark btn-xs btn-uppercase" 
                                                [routerLink]="['/apm', row.id, 'availability', 'partner', partnerService.id, 'delivery', apmDelivery.id, {apmId2: apmId, slotTime: slotTime, otherApm: true}]">
                                                    {{'Check availability' | translate }}
                                            </a>
            
                                        </div>
                                        <div *ngSwitchDefault>{{ value | translate }}</div>
                                    </div>
        
                                </ng-template>
                            </ngx-datatable-column>
                        </ng-container>
                    </ngx-datatable>
                </div>
        
                <div *ngIf="loader" class="load-spinner text-center">
                    <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}
                </div>
            </div>

     
    </div>

</layout-default>
