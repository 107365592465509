import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '@services/utils.service';
import { CrudService } from '@services/crud.service';
import { VehicleTypes } from '@hardware/interfaces/vehicle-types.interface';
import { D2DDelivery } from '../interfaces/d2d-delivery.interface';

@Injectable({
  providedIn: 'root'
})
export class ApmDeliveryD2DService extends CrudService<D2DDelivery, number>{

  protected static BASE_API: string = `${environment.api.url}${environment.api.prefix}/apmdelivery/v1/{apmDeliveryId}/convertToDtD`;

  constructor(
    protected _http: HttpClient,
    protected utilsService: UtilsService) {
    super(_http, ApmDeliveryD2DService.BASE_API, utilsService);
  }
}
