import { PipeTransform, Pipe } from '@angular/core';
import { ChartModel } from '@analytics/interfaces/chart.interface';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'chartTooltipPipe',
    pure: true
})
export class ChartTooltipPipe implements PipeTransform {

    constructor(private translate: TranslateService) {}

    public transform(chart: ChartModel): string {
        let tooltip = (chart.category) ? `${this.translate.instant(chart.category)}\n\n` : '';
        chart.series.forEach(serie => tooltip += `${this.translate.instant(serie.name)}: ${serie.value} - (${Number(serie.percent).toFixed(2)}%)\n`);
        return tooltip;
    }
}
