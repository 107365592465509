export interface ApmMaintenance {
    apmId: string;
    id?: string;
    lcount: number,
    mcount: number,
    ncount: number,
    ocount: number,
    slot: {
        from: string,
        to: string
    };
    type: ApmMaintenanceType;
    origin: ApmMaintenanceOrigin;
}


export enum ApmMaintenanceType {
    SCHEDULED_MAINTENANCE = 'SCHEDULED_MAINTENANCE', 
    DEFECT = 'DEFECT', 
    DIRTY = 'DIRTY', 
    OTHER = 'OTHER'
}

export enum ApmMaintenanceOrigin {
    AUTOMATIC = 'AUTOMATIC', 
    MANUAL = 'MANUAL'
}