import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, throwError as observableThrowError, merge, Subject, Observable } from 'rxjs';
import { catchError, switchMap, takeUntil, take } from 'rxjs/operators';
import { AuthenticationService } from '../../authentication.service';
import { Login } from '../entities/authentication';
import { environment } from '@environment';
import { Client, LoginProviderType } from '@enums/enum';
import { AppService } from '@services/app.service';
import { User } from '@entities/user';
import { LoginProvider } from '@interfaces/login-provider.interface';

@Component({
    selector: 'authentication',
    templateUrl: './authentication.component.html',
    styleUrls: ['./authentication.components.scss']
})
export class AuthenticationComponent implements OnInit, OnDestroy {
    public tag = '[AuthenticationComponent]';
    public model: Login = { username: '', password: '' };
    public loginSubscription: Subscription;
    public loading: boolean = false;
    public error: boolean = false;
    public destroy$ = new Subject<boolean>();

    public loginProviders$: Observable<LoginProvider[]>;
    public loginProviderType = LoginProviderType;

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly appService: AppService
    ) {}

    get isClientFrisco() {
        return environment.client === Client.FRISCO;
    }

    get isClientMamyito() {
        return environment.client === Client.MAMYITO;
    }

    get isClientInpost() {
        return environment.client === Client.INPOST;
    }

    public ngOnInit() {
        console.log(environment.client)
        merge(this.authenticationService.currentUser, this.appService.userAppSettings)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (user: any) => {
                if (user instanceof User) {
                    this.appService.getUserAppSettings().pipe(take(1), takeUntil(this.destroy$)).subscribe();
                }

                if (user && user.hasOwnProperty('adminAppSettings')) {
                    const startPage = (user.adminAppSettings && user.adminAppSettings.startPage) ? user.adminAppSettings.startPage : undefined;
                    this.authenticationService.redirectAfterLogin(startPage);
                }
            });

        this.loginProviders$ = this.authenticationService.externalProviders();
    }

    public submit() {
        this.loading = true;

        this.loginSubscription = this.authenticationService
            .login(this.model)
            .pipe(
                takeUntil(this.destroy$),
                catchError(error => {
                    this.error = true;
                    this.loading = false;
                    return observableThrowError(error);
                }),
                switchMap(() => this.appService.getUserAppSettings())
            )
            .subscribe(
                result => {
                    if (result) {
                        this.loading = false;
                    }
                },
                err => {
                    this.error = true;
                },
                () => {
                    this.loading = false;
                }
            );
    }

    public ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
}
