import {
    transition,
    trigger,
    query,
    style,
    animate,
    group,
    animateChild,
} from '@angular/animations'

export const slideFadeinUp = trigger('slideFadeinUp', [
    transition(':enter', []),
    transition('* => *', [
        style({ position: 'relative' }),
        query(':enter', [style({ display: 'block', opacity: '0', transform: 'translateY(20px)' })]),
        query(':leave', [
            style({ display: 'block', position: 'absolute', width: '100%', top: 0, left: 0 }),
        ]),
        query(':leave', animateChild()),
        group([
            query(':leave', [
                animate('300ms ease-in-out', style({ opacity: '0', transform: 'translateY(-20px)' })),
            ]),
            query(
                ':enter',
                [animate('300ms ease-in-out', style({ opacity: '1', transform: 'translateY(0px)' }))],
                { delay: 400 },
            ),
        ]),
        query(':enter', animateChild()),
    ]),
])

export const slideFadeinRight = trigger('slideFadeinRight', [
    transition(':enter', []),
    transition('* => *', [
      style({ position: 'relative' }),
      
      // Użyj `{ optional: true }` w `query` dla `:enter` i `:leave`
      query(':enter', [style({ display: 'block', opacity: '0', transform: 'translateX(-20px)' })], { optional: true }),
      query(':leave', [
        style({ display: 'block', position: 'absolute', width: '100%', top: 0, left: 0 })
      ], { optional: true }),
  
      query(':leave', animateChild(), { optional: true }),
      
      group([
        query(':leave', [
          animate('300ms ease-in-out', style({ opacity: '0', transform: 'translateX(20px)' }))
        ], { optional: true }),
        query(
          ':enter',
          [animate('300ms ease-in-out', style({ opacity: '1', transform: 'translateX(0px)' }))],
          { delay: 400, optional: true }
        )
      ]),
      
      query(':enter', animateChild(), { optional: true })
    ]),
  ]);

export const zoomFadein = trigger('zoomFadein', [
    transition(':enter', []),
    transition('* => *', [
        style({ position: 'relative' }),
        query(':enter', [style({ display: 'block', opacity: '0', transform: 'scale(0.99)' })]),
        query(':leave', [
            style({ display: 'block', position: 'absolute', width: '100%', top: 0, left: 0 }),
        ]),
        query(':leave', animateChild()),
        group([
            query(':leave', [
                animate('300ms ease-in-out', style({ opacity: '0', transform: 'scale(1.01' })),
            ]),
            query(
                ':enter',
                [animate('300ms ease-in-out', style({ opacity: '1', transform: 'scale(1)' }))],
                { delay: 400 },
            ),
        ]),
        query(':enter', animateChild()),
    ]),
])

export const fadein = trigger('fadein', [
    transition(':enter', []),
    transition('* => *', [
        style({ position: 'relative' }),
        query(':enter', [style({ display: 'block', opacity: '0' })]),
        query(':leave', [
            style({ display: 'block', position: 'absolute', width: '100%', top: 0, left: 0 }),
        ]),
        query(':leave', animateChild()),
        group([
            query(':leave', [animate('300ms ease-in-out', style({ opacity: '0' }))]),
            query(':enter', [animate('300ms ease-in-out', style({ opacity: '1' }))], { delay: 400 }),
        ]),
        query(':enter', animateChild()),
    ]),
])


export const routeAnimationsLicensing =
    trigger('routeAnimationsLicensing', [
        transition('* => LicensingDeliveriesPage', [
            style({ position: 'relative' }),
            query(':enter, :leave', [
                style({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%'
                })
            ]),
            query(':enter', [
                style({ left: '-100%' })
            ]),
            query(':leave', animateChild()),
            group([
                query(':leave', [
                    animate('300ms ease-out', style({ left: '100%' }))
                ]),
                query(':enter', [
                    animate('300ms ease-out', style({ left: '0%' }))
                ])
            ]),
            query(':enter', animateChild()),
        ]),
        transition('LicensingUsersPage => LicensingHardwarePage', [
            style({ position: 'relative' }),
            query(':enter, :leave', [
                style({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%'
                })
            ]),
            query(':enter', [
                style({ left: '-100%' })
            ]),
            query(':leave', animateChild()),
            group([
                query(':leave', [
                    animate('300ms ease-out', style({ left: '100%' }))
                ]),
                query(':enter', [
                    animate('300ms ease-out', style({ left: '0%' }))
                ])
            ]),
            query(':enter', animateChild()),
        ]),
        transition('LicensingHardwarePage => LicensingApmDeliveriesPage', [
            style({ position: 'relative' }),
            query(':enter, :leave', [
                style({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%'
                })
            ]),
            query(':enter', [
                style({ left: '-100%' })
            ]),
            query(':leave', animateChild()),
            group([
                query(':leave', [
                    animate('300ms ease-out', style({ left: '100%' }))
                ]),
                query(':enter', [
                    animate('300ms ease-out', style({ left: '0%' }))
                ])
            ]),
            query(':enter', animateChild()),
        ]),
        transition('LicensingDeliveriesPage => *', [
            style({ position: 'relative' }),
            query(':enter, :leave', [
                style({
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: '100%'
                })
            ]),
            query(':enter', [
                style({ right: '-100%' })
            ]),
            query(':leave', animateChild()),
            group([
                query(':leave', [
                    animate('200ms ease-out', style({ right: '100%' }))
                ]),
                query(':enter', [
                    animate('300ms ease-out', style({ right: '0%' }))
                ])
            ]),
            query(':enter', animateChild()),
        ]),
        transition('LicensingApmDeliveriesPage => LicensingHardwarePage', [
            style({ position: 'relative' }),
            query(':enter, :leave', [
                style({
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: '100%'
                })
            ]),
            query(':enter', [
                style({ right: '-100%' })
            ]),
            query(':leave', animateChild()),
            group([
                query(':leave', [
                    animate('200ms ease-out', style({ right: '100%' }))
                ]),
                query(':enter', [
                    animate('300ms ease-out', style({ right: '0%' }))
                ])
            ]),
            query(':enter', animateChild()),
        ]),
        transition('* => LicensingUsersPage', [
            style({ position: 'relative' }),
            query(':enter, :leave', [
                style({
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: '100%'
                })
            ]),
            query(':enter', [
                style({ right: '-100%' })
            ]),
            query(':leave', animateChild()),
            group([
                query(':leave', [
                    animate('200ms ease-out', style({ right: '100%' }))
                ]),
                query(':enter', [
                    animate('300ms ease-out', style({ right: '0%' }))
                ])
            ]),
            query(':enter', animateChild()),
        ])
    ]);



