<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="database"></i-feather> {{'Create config value' | translate}}
            </h4>
        </div>
        <hr />
    </div>
    
    <div content>

        <div *ngIf="!loader" class="content bg-white">

            <div class="card-content col-8 offset-2">

                <form [formGroup]="form">

                    <div class="form-group row">
                        <label class="col-2" for="value">{{'Config key' | translate}}:&nbsp;<span *ngIf="form.get('configKeyId') | isRequiredControlPipe">*</span></label>
                        <div class="col-10">
                            <select class="custom-select" formControlName="configKeyId">
                                <option *ngFor="let key of configKeys" [ngValue]="key.id">{{'ConfigKey.'+key.code | translate}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-2" for="value">{{'Value' | translate}}:&nbsp;<span *ngIf="form.get('value') | isRequiredControlPipe">*</span></label>
                        <div class="col-10">
                            
                            <div [ngSwitch]="selectedConfigKey">
                                <div *ngSwitchCase="configKeyTypes.Boolean">
                                    <select class="custom-select" formControlName="value">
                                        <option *ngFor="let option of [true, false]" [ngValue]="option.toString()">{{option.toString() | translate}}</option>
                                    </select>
                                </div>
                                <div *ngSwitchCase="configKeyTypes.Integer">
                                    <input type="number" formControlName="value" [min]="0" class="form-control" />
                                </div>
                                <div *ngSwitchCase="configKeyTypes.Duration">
                                    <input type="number" formControlName="value" [min]="0" class="form-control" />
                                </div>
                                <div *ngSwitchDefault>
                                    <input type="text" formControlName="value" class="form-control" />
                                </div>
                            </div>

                            <div *ngIf="form.get('value').hasError('required') && form.get('value').touched" class="error-color">
                                {{ "Field is required" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div *ngIf="formErrorsMessages && formErrorsMessages.length" class="form-group row">
                        <div class="col-10 offset-2">
                            <p class="error-color">{{ 'Something has wrong! Please correct form' | translate }}:</p>
                            <ul>
                                <li *ngFor="let error of formErrorsMessages" class="error-color">
                                    <span>{{ error.value | translate }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="form-group row justify-content-end">
                        <div class="col-2">
                            <button (click)="onSubmit()" [disabled]="!form.valid || submitted"
                                class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01 w-100">

                                <span *ngIf="!submitted">{{ "Save" | translate }}</span>

                                <ng-container *ngIf="submitted">
                                    <div class="load-spinner text-center">
                                        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                    </div>
                                </ng-container>

                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</layout-default>

<!-- {{'Config key already exist' | translate}} -->