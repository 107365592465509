
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { PartnerUserService } from '../services/partner-user.service';

@Injectable()
export class PartnerUserResolver implements Resolve<any> {
    constructor(private readonly partnerUserService: PartnerUserService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        const params = route.params;
        return this.partnerUserService.findOne(params['partneruserId']);
    }
}
