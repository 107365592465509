import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrProviderService } from '@services/toastr-provider.service';
import * as moment from 'moment';
import { Observable, throwError as observableThrowError, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Token } from '@entities/token';
import { Roles } from '@enums/enum';
import { EntityValidationError } from '@shared/services/entity-validation-error.service';
import { AuthenticationService } from '../../authentication.service';
import { v } from '../../version';
import { environment } from '@environment';
import { Permissions } from '@enums/permissions';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private tag: string = '[AuthInterceptor]';
    private refreshTokenInProgress: boolean = false;

    constructor(
        public authService: AuthenticationService,
        private toastrProviderService: ToastrProviderService,
        private translate: TranslateService,
        private entityValidationService: EntityValidationError
    ) {}

    public processRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // todo: retring re quest for specyfic status code (unavaialable) .retry(5)

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                

                switch (error.status) {
                    case 500:
                        this.authService.checkPrivileges([Permissions.ROLE_SYSADMIN])
                            ? this.toastrProviderService.showError(this.translate.instant('errors.' + error.error.message))
                            : this.toastrProviderService.showError(this.translate.instant('General application error, please try again or contact with our support'));

                        //this.bugsnagService.notify(new Error(`500 - Internal Server Error`), options);
                        return observableThrowError(error);

                    case 401:
                        console.log(error);
                        this.toastrProviderService.showError(this.translate.instant('You are unauthorized, Please login again'));
                        this.authService.unauthorized();
                        return observableThrowError(error);

                    case 403:

                        //this.toastrProviderService.showWarning(this.translate.instant('errors.' + error.error.message));
                        console.error('Access denied', error);
                        //this.authService.redirectTo403Page();
                        return observableThrowError(error);

                    case 409:

                        // if (!error.error.errors) {
                        
                        //     return observableThrowError(error.error);
                        // }

                        const parsedError = error.error.messageCode ? JSON.parse(error.error) : error.error;

                        const errorMessage = (parsedError.messageCode) ? 'errors.' + parsedError.messageCode : 'errors.' + parsedError.message;
                        let message = this.translate.instant(errorMessage);

                        if (error.headers.has('x-traceid')) {
                            const traceId = error.headers.get('x-traceid');

                            if (this.authService.checkPrivileges([Permissions.ROLE_SYSADMIN])) {
                                message += ', ' + this.translate.instant('Error code', { traceId });
                            }
                        }

                        this.toastrProviderService.showError(message);
                        //this.bugsnagService.notify(new Error(`409 - Conflict`), options);
                        return observableThrowError(this.entityValidationService.parse(error));
                    case 422:
                        this.toastrProviderService.showError(this.translate.instant('Something has wrong! Please correct form'));
                        //this.bugsnagService.notify(new Error(`422 - Unprocessable Entity`), options);
                        return observableThrowError(this.entityValidationService.parse(error));
                    default:
                        return observableThrowError(error);
                }
            })
        );
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.url.match('osrm')) {
            return this.processRequest(request, next);
        }

        if (request.url.match('maps.googleapis.com')) {
            return this.processRequest(request, next);
        }
        
        if (request.url.match('auth/v2/authenticate')) {
            return next.handle(request);
        }

        try {
            const token: Token = this.authService.getToken();

            request = request.clone({
                setHeaders: {
                    'x-app-version': v.version,
                    Authorization: `Bearer ${token.getJwt()}`,
                    'x-froot': environment.client,
                    'X-ID': new Date().valueOf().toString()
                }
            });
            
            const expiry = token.getExpiration().subtract(45, 'minute');

            if (!moment().isBefore(expiry) && !this.refreshTokenInProgress) {
                this.refreshTokenInProgress = true;
                this.authService.renew().subscribe(() => this.refreshTokenInProgress = false);
                return this.processRequest(request, next)
            } else {
                return this.processRequest(request, next);
            }
        } catch (e) {
            return this.processRequest(request, next);
        }
    }
}
