import { Component, OnInit, ElementRef, ViewChild, TemplateRef } from '@angular/core';
import { Partner } from '../../entities/partner';
import { CRUD_MODE } from '@enums/enum';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PartnerService } from '../../services/partner.service';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '@services/nav.sevice';
import { Region } from '../../entities/region';
import { Address } from '@entities/address';
import { Additional } from '@entities/additional';
import * as _ from 'lodash';
import { RegionService } from '../../services/region.service';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import PartnerDC from '../../interfaces/partnerdc.interface';
import { tooltipLabel } from '@shared/functions/tooltip-labels.function';

@Component({
  selector: 'app-edit-region',
  templateUrl: './edit-region.component.html',
  styleUrls: ['./edit-region.component.scss']
})
export class EditRegionComponent implements OnInit {

  public partner: Partner;
  public region: Region;
  public dcs: PartnerDC;
  public mode: CRUD_MODE;
  public formErrorsMessages;
  public form: FormGroup;
  public loader: boolean = true;
  public submitted: boolean = false;
  public showPassword: boolean = false;

  get addressKeys() {
    const address = new Address();
    return _.keys(address);
  }

  get additionalKeys() {
    const additional = new Additional();
    return _.keys(additional);
  }

  
  constructor(
    private navigationRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private regionService: RegionService,
    private toastrProviderService: ToastrProviderService,
    private translateService: TranslateService,
    private navService: NavService
  ) { }

  public ngOnInit() {
    this.partner = (this.navigationRoute.snapshot.data as {partner: any}).partner;
    this.region = (this.navigationRoute.snapshot.data as {region: any}).region;
    this.dcs = (this.navigationRoute.snapshot.data as {dcs: any}).dcs.content;

    this.mode = (this.region) ? CRUD_MODE.UPDATE : CRUD_MODE.CREATE;
    this.initForm();
  }

  private initForm() {

    this.form = this.formBuilder.group({
      address: this.formBuilder.group({
        country: [this.region ? this.region.address.country : 'Polska', [Validators.required, Validators.maxLength(255)]],
        district: [this.region ? this.region.address.district : null, [Validators.required, Validators.maxLength(255)]],
        houseNO: [this.region ? this.region.address.houseNO : null, [Validators.required, Validators.maxLength(255)]],
        street: [this.region ? this.region.address.street : null, [Validators.required, Validators.maxLength(255)]],
        town: [this.region ? this.region.address.town : null, [Validators.required, Validators.maxLength(255)]],
        voivoidship: [this.region ? this.region.address.voivoidship : null, [Validators.required, Validators.maxLength(255)]],
        zip: [this.region ? this.region.address.zip : null, [Validators.required, Validators.pattern('[0-9]{2}\-[0-9]{3}')]]
      }),
      locationAdditional: this.formBuilder.group({
        companyName: [null, Validators.maxLength(255)],
        flatNO: [null, Validators.maxLength(255)],
        lift: [null, Validators.maxLength(255)],
        staircaseNO: [null, Validators.maxLength(255)],
        floor: [null, Validators.maxLength(255)],
        contactName: [null, Validators.maxLength(255)],
        contactPhoneNO: [null, Validators.maxLength(255)],
        domofonCode: [null, Validators.maxLength(255)],
        instructions: [null, Validators.maxLength(255)],
        contactEmail: [null, [Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')]]
      }),
      code: [this.region ? this.region.code : null, [Validators.required, Validators.maxLength(32)]],
      defaultApmPassword: [this.region ? this.region.defaultApmPassword : null, [Validators.required, Validators.minLength(8), Validators.maxLength(255)]],
      defaultApmUsername: [{value: this.region ? this.region.defaultApmUsername : null, disabled: true}, [Validators.required, Validators.maxLength(255)]],
      id: [this.region ? this.region.id : null],
      name: [this.region ? this.region.name : null, [Validators.required, Validators.maxLength(64)]],
      description: [this.region ? this.region.description : null, [Validators.required, Validators.maxLength(255)]],
      openingHours: [this.region ? this.region.openingHours : null, [Validators.required, Validators.maxLength(255)]],
      version: [this.region ? this.region.version : null, [Validators.maxLength(255)]],
      partnerId: [this.partner ? this.partner.id : null, [Validators.maxLength(255)]],
      partnerDcId: [this.region ? this.region.partnerDcId : null, [Validators.required, Validators.maxLength(255)]],

    });

    const locationAdditional = this.form.controls[ 'locationAdditional' ];

    if (this.region) {
      locationAdditional.patchValue((this.region.locationAdditional !== null) ? this.region.locationAdditional : {});
    }

    this.loader = false;
  }

  public regularFormItems(): string[] {
    return ['name', 'code', 'description', 'openingHours'];
  }

  public onSubmit() {
    const raw = this.form.getRawValue();
    raw['code'] = raw['code'].trim();
    
    if (this.mode === CRUD_MODE.CREATE) {
      this.create(raw);
    } else {
      this.update(raw);
    }
  }


  private create(raw): void {
    this.submitted = true;
    this.regionService.save(raw, {partnerId: this.partner.id})
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (region: Region) => {
          console.log(region);
          this.toastrProviderService.showSuccess(this.translateService.instant('New region has been created!'));
          this.navService.goToPage(`/partner/${this.partner.id}/region/list`, {modifiedRow: region.id});
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.formErrorsMessages = error;
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }

  private update(raw): void {
    this.submitted = true;
    this.regionService.update(raw['id'], raw, {partnerId: this.partner.id})
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (region: Region) => {
          console.log(region);
          this.toastrProviderService.showSuccess(this.translateService.instant('The region has been updated!'));
          this.navService.goToPage(`/partner/${this.partner.id}/region/list`, {modifiedRow: region.id});
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.formErrorsMessages = error;
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }

  public tooltipLabel(property: string): string {
    return tooltipLabel(this.translateService, `tooltips.region.labels.${property}`);
  }
}
