import { Injectable } from '@angular/core';
import { CrudService } from '@services/crud.service';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '@services/utils.service';
import { ApmDelivery } from '../entities/apm-delivery';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class ApmDeliverySearchService extends CrudService<ApmDelivery, number>{

    protected static BASE_API: string = `${environment.api.url}${environment.api.prefix}/apmdelivery/v1`;
    private static GET_APM_DELIVERIES: string = `${environment.api.url}${environment.api.prefix}/apmdelivery/v1/apm/` + '${apmId}/extended';
    constructor(
        protected _http: HttpClient,
        protected utilsService: UtilsService) {
        super(_http, ApmDeliverySearchService.GET_APM_DELIVERIES, utilsService);
    }

}
