import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PaymentsService } from 'src/app/payments/services/payments.service';
import { ToastrProviderService } from '@services/toastr-provider.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CurrencyType } from '@enums/enum';
import { AppService } from '@services/app.service';
import { Hardware } from '@hardware/entities/hardware';
import { size } from 'lodash';

@Component({
  selector: 'app-licensing-hardware',
  templateUrl: './licensing-hardware.component.html',
  styleUrls: ['./licensing-hardware.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LicensingHardwareComponent implements OnInit {

  public readonly mobileAppFee: number = 79;
  private from: string;
  private to: string;
  public licensing$!: Observable<any>;
  public mobileAppCount: number = 0;
  public CurrencyType = CurrencyType;
  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  public excludes: string;
  public includes: string;


  public columns = [
    { prop: 'brand', name: this.translateService.instant('Brand'), flexGrow: 2 },
    { prop: 'model', name: this.translateService.instant('Model'), flexGrow: 2 },
    { prop: 'inventoryNo', name: this.translateService.instant('Inventory no'), flexGrow: 2 },
    { prop: 'phoneNumber', name: this.translateService.instant('Phone number'), flexGrow: 2 },
    // { prop: 'depotId', name: this.translateService.instant('Depot'), flexGrow: 2 },
  ];

  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private paymentService: PaymentsService,
    private appSerice: AppService,
    private changeDetect: ChangeDetectorRef,
    private toastrProviderService: ToastrProviderService) { }

  public ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        if (params['from'] && params['to']) {
          this.from = moment(params['from']).format('YYYY-MM-DD');
          this.to = moment(params['to']).format('YYYY-MM-DD');
          this.fetechLicensingUsers();
        }
      });

      this.fetechLicensingUsers();
  }

  private fetechLicensingUsers() {
    this.mobileAppCount = 0;
    this.licensing$ = this.paymentService.licensingHardware(this.from, this.to).pipe(
      tap((devices: {[depotId: string]: Hardware[]}) => Object.values(devices).forEach(a => this.mobileAppCount += a.length)),
      tap(() => this.changeDetect.detectChanges())
    );
  }

  public changeFilters(): void {
    this.fetechLicensingUsers();
  }

  public findDepotRef(depotId: string): string {
    return this.appSerice.findWarehouseRef(depotId);
  }
}
