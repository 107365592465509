import { Injectable } from '@angular/core';
import { CrudService } from '@services/crud.service';
import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '@services/utils.service';
import { Observable } from 'rxjs';
import { PageableResponse } from '@entities/pagable-response';
import PartnerDC from '../interfaces/partnerdc.interface';
import { PartnerCourier } from '../interfaces/partner-courier.interface';
import { Collection } from '@interfaces/collection.interface';

@Injectable({
  providedIn: 'root'
})
export class PartnerCourierService extends CrudService<PartnerCourier, number>{
  
  protected static BASE_API: string = `${environment.api.url}${environment.api.prefix}/partnercourier/v1`;  

  constructor(
    protected _http: HttpClient, 
    protected utilsService: UtilsService) 
    {
      super(_http, PartnerCourierService.BASE_API, utilsService);
    }

    public savePartnerCourier(partnerCourier: PartnerDC, regionId: string): Observable<PartnerCourier> {
      const endpoint = this.utilsService.interpolate(`${PartnerCourierService.BASE_API}/${regionId}`, {});
      return this._http.post<PartnerCourier>(endpoint, partnerCourier);
    }

    public updatePartnerCourier(partnerCourier: PartnerDC, regionId: string): Observable<PartnerCourier> {
      const endpoint = this.utilsService.interpolate(`${PartnerCourierService.BASE_API}/${regionId}`, {});
      return this._http.put<PartnerCourier>(endpoint, partnerCourier);
    }

    public getAllCouriersForRegion(regionId: string): Observable<Collection<PartnerCourier>> {
        const endpoint = this.utilsService.interpolate(`${PartnerCourierService.BASE_API}/list?regionId=${regionId}&sort=id`, {});
        return this._http.get<Collection<PartnerCourier>>(endpoint);
    }
}
