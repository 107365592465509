import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { NavService } from '@services/nav.sevice';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class ClientGuard implements CanActivate {
  constructor(
    private readonly navService: NavService,
  ) { }

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    if (environment.client !== (route.data as {client: any}).client) {
      this.navService.goToPage('/access-denied');
      return false;
    }
    return true;
  }

  public canActivateChild(): boolean {
    return true;
  }

}
