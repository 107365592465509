{{'errors.subscription.slot_in_past' | translate}}
{{'errors.subscription.create.duplicate_code' | translate}}
{{'errors.subscription.update.duplicate_code' | translate}}
{{'errors.subscription.extend_active_subscription_future' | translate}}
{{'errors.subscription.end_before_now' | translate}}
{{'errors.subscription.existing_deliveries' | translate}}
{{'errors.subscription.negative_volume' | translate}}
{{'errors.subscription.create.apm_not_assigned' | translate}}
{{'errors.subscription.update.apm_not_assigned' | translate}}
{{'errors.subscription.remove.existing_deliveries' | translate}}

{{'errors.partner.create.duplicate_code' | translate}}
{{'errors.partner.create.duplicate_sender' | translate}}
{{'errors.partner.update.duplicate_code' | translate}}
{{'errors.partner.update.duplicate_sender' | translate}}
{{'errors.partner.update.existing_regions' | translate}}

{{'errors.region.remove.active_deliveries' | translate}}

{{'errors.region.create.duplicate_code' | translate}}
{{'errors.region.update.duplicate_code' | translate}}
{{'errors.region.carrier_does_not_exists' | translate}}
{{'errors.timeslot.customer_slot_too_short' | translate}}

{{'errors.Salary plan with reports cannot be removed' | translate}}
{{'errors.Cannot assign APMs to region as they are already assigned to other partner regions' | translate}}
{{'errors.Cannot create more users from Admin panel' | translate}}
{{'errors.User cannot be removed, owns 3 deliveries' | translate}}

{{'errors.timeslot.inconsistent' | translate}}
{{'errors.timeslot.slotEnd_after_slotStart' | translate}}
{{'errors.timeslot.pickup_slot_not_correct' | translate}}
{{'errors.timeslot.pickupSlotEnd_after_pickupSlotStart' | translate}}
{{'errors.timeslot.slot_too_short' | translate}}
{{'errors.timeslot.pickupSlot_too_short' | translate}}
{{'errors.timeslot.difference_pickup_delivery_too_short' | translate}}
{{'errors.timeslot.customer_slot_incorrect' | translate}}
{{'errors.timeslot.remove.in_use' | translate}}


{{'errors.reservation.email.not_valid' | translate}}

{{'errors.Access is denied' | translate}}
{{'errors.Password is missing' | translate}}
{{'errors.user.username_exists' | translate}}
{{'errors.Routing may not be modified before cutoff is finalized.' | translate}}

{{'errors.The shift has been updated, reload and try again.' | translate}}
{{'errors.The planning has been updated, reload and try again.' | translate}}
{{'errors.Shift is in status BOOKING and can not be finalized.' | translate}}
{{'errors.There is already maintenance registered for that timeslot' | translate}}

{{'errors.This partner cannot book for given apm' | translate}}
{{'errors.There is no capacity in given timeslot!' | translate}}
{{'errors.No capacity available for pickup' | translate}}
{{'errors.Maintenance capacity can not be more than APM capacity' | translate}}
{{'Delivery in this status can not be moved to a different machine.' | translate}}

{{'errors.Shift names may only contain uppercase, numbers, underscore and dash characters.' | translate}}
{{'errors.Partner user with that email already exists' | translate}}

{{'errors.This combination of route number offset and segment offset overlaps with another shift.' | translate}}

{{'errors.Could not reserve a slot, it may have been taken.' | translate}}

{{'Shift names may only contain uppercase, numbers, underscore and dash characters.' | translate}}
{{'This combination of route number offset and segment offset overlaps with another shift.' | translate}}

{{'errors.Before cutoff the delivery status can be changed by the customer, not the dispatcher.' | translate}}
{{'errors.Delivery may not be completed.' | translate}}

{{'errors.Shift has already cutoff, finalizeShift must be slected.' | translate}}

{{'errors.Shift has deliveries.' | translate}}

{{'errors.Only routes in status fulfillment can be moved to lkoading.' | translate}}
{{'errors.Not all deliveries are complete.' | translate}}
{{'errors.Only the last cutoff may be unlimited.' | translate}}
{{'errors.No value present' | translate}}
{{'errors.Shift is in status CONFIGURING and can not be finalized.' | translate}}

{{'errors.New cutoffs may not be in the past' | translate}}