import { Component, OnInit } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Apm } from '../../../entities/apm';
import { ActivatedRoute } from '@angular/router';
import { mapTypes, mapOptions, LogisticTypeEnum } from '@enums/enum';
import { ApmService } from '../../../services/apm.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { PageableResponse } from '@entities/pagable-response';
import { Observable } from 'rxjs';
import { QueryParams } from '@interfaces/query-params.interface';
import { environment } from '@environment';
import { ApmDelivery } from '../../../entities/apm-delivery';
import { ApmDeliveryMove } from '../../../interfaces/apm-delivery-move.interface';
import { ApmDeliveryService } from '../../../services/apm-delivery.service';
import { ApmForPartnerService } from '../../../services/apm-for-partner.service';
import { Partner } from '../../../entities/partner';

@Component({
  selector: 'app-apm-delivery-reschedule',
  templateUrl: './apm-delivery-reschedule.component.html',
  styleUrls: ['./apm-delivery-reschedule.component.scss']
})
export class ApmDeliveryRescheduleComponent implements OnInit {

  public apms: Apm[];
  public apmDelivery: ApmDelivery;
  
  private assetsPath = '/assets/img';
  private markerPath = `${this.assetsPath}/markers`;
  public zoom: number = 13;

  private apmsCollection: Apm[];
  public list$: Observable<PageableResponse<Apm>>;
  private partnerService: Partner;

  public apmId: string;
  public slotTime: string;

  protected queryParams: QueryParams = Object.assign({}, environment.pagination);

  public modifiedRow: string;
  public rowClass;
  public loader: boolean = true;
  public locale = 'pl';

  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  public columns = [
    { headerClass: 'name', prop: 'name', name: this.translateService.instant('Name'), flexGrow: 4, sortable: true },
    { headerClass: 'address', prop: 'address', name: this.translateService.instant('Adress'), flexGrow: 4, sortable: true },
    { headerClass: 'code', prop: 'code', name: this.translateService.instant('Code'), flexGrow: 3, sortable: true },
    { headerClass: 'type', prop: 'type', name: this.translateService.instant('Type'), flexGrow: 3, sortable: true },
    { headerClass: 'lcount', prop: 'lcount', name: this.translateService.instant('Capacity'), flexGrow: 2, sortable: true},
    { headerClass: 'status', prop: 'status', name: this.translateService.instant('Status'), flexGrow: 2, sortable: true },
    { headerClass: 'options', prop: 'options', name: this.translateService.instant('Options'), flexGrow: 3, sortable: false},

  ];

  constructor(
    private navigationRoute: ActivatedRoute,
    private apmForPartnerService: ApmForPartnerService,
    private readonly apmDeliveryService: ApmDeliveryService,
    private translateService: TranslateService,
  ) { }

  public ngOnInit() {

    this.translateService.onLangChange.subscribe((lang: LangChangeEvent) => this.locale = lang.lang);
    
    const pagable = (this.navigationRoute.snapshot.data as {apms: any}).apms;
    this.apmDelivery = (this.navigationRoute.snapshot.data as {apmDelivery: any}).apmDelivery;
    this.partnerService = (this.navigationRoute.snapshot.data as {partnerService: any}).partnerService;

    this.apms = plainToClass(Apm, pagable.content as Apm[]);

    this.apmId = this.navigationRoute.snapshot.params.apmId;
    this.slotTime = this.navigationRoute.snapshot.params.slotTime;

    this.list$ = this.apmForPartnerService.list$;
    Object.assign(this.queryParams, {apmId: this.apmDelivery.apm.id, partnerId: this.partnerService.id})
    this.queryParams.sort = 'createdAt';
    this.apmForPartnerService.fetchAll(this.queryParams);

    this.modifiedRow = this.navigationRoute.snapshot.params.modifiedRow;

    this.rowClass = row => {
      return { 'row-modified': row.id === this.modifiedRow };
    };

    this.loader = false;
  }

  public applyFiltering(event) {
    const filterPhrase = event.target.value.toLowerCase();
    this.queryParams.page = 0;
    this.queryParams.filter = filterPhrase;
    this.apmForPartnerService.fetchAll(this.queryParams);
  }

  public reschedule(apm: Apm) {
    const body: ApmDeliveryMove = {
      apmDeliveryId: this.apmDelivery.id,
      apmId: apm.id,
      logisticType: LogisticTypeEnum.INPOST,
      partnerId: this.apmDelivery.partnerId,
      reservationDate: this.apmDelivery.reservationWindow.from.toString().slice(0, 10),
      timeslotId: ''
    }

    this.apmDeliveryService.rescheduleDelivery(this.apmDelivery.id, body).subscribe(
      (a) => console.log(a)
    );
  }
}
