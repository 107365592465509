<ng-container *ngIf="(logs$ | async) as logs; else loaderRef">



    <ngx-datatable class="bootstrap table" [rows]="logs" [columns]="columns" [columnMode]="'flex'" [headerHeight]="50"
        [footerHeight]="50" [rowHeight]="'auto'" [limit]="30" [reorderable]="true" [messages]="tableMessages">

        <ng-container *ngFor="let col of columns">
            <ngx-datatable-column [flexGrow]="col.flexGrow" [prop]="col.prop">
                <ng-template let-column="column" class="header" let-sort="sortFn" ngx-datatable-header-template>
                    <span class="tx-bold">{{col.name | translate}}</span>
                </ng-template>

                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>


                    <div [ngSwitch]="col.prop">
                        <div *ngSwitchCase="'options'">
                            <button (click)="generete(row.id, row.firstName, row.lastname, 'pdf')"
                                class="btn btn-xs btn-white pd-8">
                                <span *ngIf="!submitted[row.id] else loader">{{'Generate' | translate}}</span>
                                <ng-template #loader>
                                    <div class="load-spinner text-center">
                                        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                    </div>
                                </ng-template>
                            </button>
                        </div>

                        <div *ngSwitchCase="'type'">
                            {{ 'AuditLogType.'+value | translate }}
                        </div>

                        <div *ngSwitchCase="'data'">
                            <ng-container [ngSwitch]="row.type">

                                <span *ngSwitchCase="auditLogType.DELIVERY_STATUS_CHANGE">
                                    <span>{{value.previousStatus | translate}}</span>
                                    &nbsp;<i-feather [style.height.px]="15" [style.width.px]="15"
                                        name="arrow-right"></i-feather>&nbsp;
                                    <span>{{value.status | translate}}</span>
                                </span>

                                <span *ngSwitchCase="auditLogType.SEGMENT_STATUS_CHANGE">
                                    <span>{{value.previousStatus | translate}}</span>
                                    &nbsp;<i-feather [style.height.px]="15" [style.width.px]="15"
                                        name="arrow-right"></i-feather>&nbsp;
                                    <span>{{value.status | translate}}</span>
                                </span>

                                <span *ngSwitchCase="auditLogType.ROUTE_STATUS_CHANGE">
                                    <span>{{value.previousStatus | translate}}</span>
                                    &nbsp;<i-feather [style.height.px]="15" [style.width.px]="15"
                                        name="arrow-right"></i-feather>&nbsp;
                                    <span>{{value.status | translate}}</span>
                                </span>

                                <span *ngSwitchCase="auditLogType.SHIFT_STATUS_CHANGE">
                                    <span>{{value.previousStatus | translate}}</span>
                                    &nbsp;<i-feather [style.height.px]="15" [style.width.px]="15"
                                        name="arrow-right"></i-feather>&nbsp;
                                    <span>{{value.status | translate}}</span>
                                </span>

                                <span *ngSwitchCase="auditLogType.APMDELIVERY_CHANGE">
                                    <span>{{value.change}}</span>
                                    <ul class="pd-0 mg-0">
                                        <li *ngFor="let change of value.changes" class="pd-b-10">
                                            <div>{{ 'apmDeliveryProperty.'+change.property | translate }}:</div>
                                            <span>{{change.oldValue.toString() | translate}}</span>
                                            &nbsp;<i-feather [style.height.px]="15" [style.width.px]="15"
                                                name="arrow-right"></i-feather>&nbsp;
                                            <span>{{change.newValue.toString() | translate}}</span>
                                        </li>
                                    </ul>
                                </span>

                                <span *ngSwitchCase="auditLogType.ROUTE_CHANGE">
                                    <span>{{value.change}}</span>
                                    <ul class="pd-0 mg-0">
                                        <li *ngFor="let change of value.changes" class="pd-b-10">
                                            <div>{{ 'routeProperty.'+change.property | translate }}:</div>
                                            <span>{{change.oldValue.toString() | translate}}</span>
                                            &nbsp;<i-feather [style.height.px]="15" [style.width.px]="15"
                                                name="arrow-right"></i-feather>&nbsp;
                                            <span>{{change.newValue.toString() | translate}}</span>
                                        </li>
                                    </ul>
                                </span>

                                <span *ngSwitchCase="auditLogType.SHIFT_CHANGE">
                                    <span>{{value.change}}</span>
                                    <ul class="pd-0 mg-0">
                                        <li *ngFor="let change of value.changes" class="pd-b-10">
                                            <div>{{ 'shiftProperty.'+change.property | translate }}:</div>
                                            <span>{{change.oldValue?.toString() | translate}}</span>
                                            &nbsp;<i-feather [style.height.px]="15" [style.width.px]="15"
                                                name="arrow-right"></i-feather>&nbsp;
                                            <span>{{change.newValue?.toString() | translate}}</span>
                                        </li>
                                    </ul>
                                </span>

                                <span *ngSwitchCase="auditLogType.SHIFTPLANNING_CHANGE">
                                    <span>{{value.change}}</span>
                                    <ul class="pd-0 mg-0">
                                        <li *ngFor="let change of value.changes" class="pd-b-10">
                                            <div>{{ 'shiftProperty.'+change.property | translate }}:</div>
                                            <span>{{change.oldValue.toString() | translate}}</span>
                                            &nbsp;<i-feather [style.height.px]="15" [style.width.px]="15"
                                                name="arrow-right"></i-feather>&nbsp;
                                            <span>{{change.newValue.toString() | translate}}</span>
                                        </li>
                                    </ul>
                                </span>

                                <span *ngSwitchCase="auditLogType.DELIVERY_CHANGE">
                                    {{value.change | translate}}
                                </span>
                                <span *ngSwitchCase="auditLogType.DELIVERY_ASSIGN">
                                    {{'Delivery assigned from route' | translate}}: {{row.date}}, {{row.routeNumber}}
                                </span>
                                <span *ngSwitchCase="auditLogType.DELIVERY_UNASSIGN">
                                    {{'Delivery unassigned from route' | translate}}: {{row.date}}, {{row.routeNumber}}
                                </span>
                                <span *ngSwitchDefault>
                                    {{value.change}}
                                </span>
                            </ng-container>


                        </div>
                        <div *ngSwitchCase="'occurredAt'">{{value + '.000Z' | date:'d-MM-yyyy, HH:mm'}}</div>
                        <div *ngSwitchDefault>{{value}}</div>
                    </div>

                </ng-template>
            </ngx-datatable-column>
        </ng-container>

        <!-- <ng-container *ngFor="let col of columns">
            <ngx-datatable-column [flexGrow]="col.flexGrow" [name]="col.name" [prop]="col.prop"
                [sortable]="col.sortable">
                <div>{{ col.name | translate }}</div>
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <div [ngSwitch]="col.prop">
                        <div *ngSwitchCase="'options'">
                            <button (click)="generete(row.id, row.firstName, row.lastname, 'pdf')"
                                class="btn btn-xs btn-white pd-8">
                                <span *ngIf="!submitted[row.id] else loader">{{'Generate' | translate}}</span>
                                <ng-template #loader>
                                    <div class="load-spinner text-center">
                                        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                    </div>
                                </ng-template>
                            </button>
                        </div>

                        <div *ngSwitchCase="'type'">
                            {{ 'AuditLogType.'+value | translate }}
                            <pre> {{logs | json}} </pre>
                        </div>

                        <div *ngSwitchCase="'data'">
                            <ng-container [ngSwitch]="row.type">

                                <span *ngSwitchCase="auditLogType.DELIVERY_STATUS_CHANGE">
                                    <span>{{value.previousStatus | translate}}</span>
                                    &nbsp;<i-feather [style.height.px]="15" [style.width.px]="15"
                                        name="arrow-right"></i-feather>&nbsp;
                                    <span>{{value.status | translate}}</span>
                                </span>

                                <span *ngSwitchCase="auditLogType.SEGMENT_STATUS_CHANGE">
                                    <span>{{value.previousStatus | translate}}</span>
                                    &nbsp;<i-feather [style.height.px]="15" [style.width.px]="15"
                                        name="arrow-right"></i-feather>&nbsp;
                                    <span>{{value.status | translate}}</span>
                                </span>

                                <span *ngSwitchCase="auditLogType.ROUTE_STATUS_CHANGE">
                                    <span>{{value.previousStatus | translate}}</span>
                                    &nbsp;<i-feather [style.height.px]="15" [style.width.px]="15"
                                        name="arrow-right"></i-feather>&nbsp;
                                    <span>{{value.status | translate}}</span>
                                </span>

                                <span *ngSwitchCase="auditLogType.SHIFT_STATUS_CHANGE">
                                    <span>{{value.previousStatus | translate}}</span>
                                    &nbsp;<i-feather [style.height.px]="15" [style.width.px]="15"
                                        name="arrow-right"></i-feather>&nbsp;
                                    <span>{{value.status | translate}}</span>
                                </span>

                                <span *ngSwitchCase="auditLogType.APMDELIVERY_CHANGE">
                                    <span>{{value.change}}</span>
                                    <ul class="pd-0 mg-0">
                                        <li *ngFor="let change of value.changes" class="pd-b-10">
                                            <div>{{ 'apmDeliveryProperty.'+change.property | translate }}:</div>
                                            <span>{{change.oldValue.toString() | translate}}</span>
                                            &nbsp;<i-feather [style.height.px]="15" [style.width.px]="15"
                                                name="arrow-right"></i-feather>&nbsp;
                                            <span>{{change.newValue.toString() | translate}}</span>
                                        </li>
                                    </ul>
                                </span>

                                <span *ngSwitchCase="auditLogType.ROUTE_CHANGE">
                                    <span>{{value.change}}</span>
                                    <ul class="pd-0 mg-0">
                                        <li *ngFor="let change of value.changes" class="pd-b-10">
                                            <div>{{ 'routeProperty.'+change.property | translate }}:</div>
                                            <span>{{change.oldValue.toString() | translate}}</span>
                                            &nbsp;<i-feather [style.height.px]="15" [style.width.px]="15"
                                                name="arrow-right"></i-feather>&nbsp;
                                            <span>{{change.newValue.toString() | translate}}</span>
                                        </li>
                                    </ul>
                                </span>

                                <span *ngSwitchCase="auditLogType.SHIFT_CHANGE">
                                    <span>{{value.change}}</span>
                                    <ul class="pd-0 mg-0">
                                        <li *ngFor="let change of value.changes" class="pd-b-10">
                                            <div>{{ 'shiftProperty.'+change.property | translate }}:</div>
                                            <span>{{change.oldValue.toString() | translate}}</span>
                                            &nbsp;<i-feather [style.height.px]="15" [style.width.px]="15"
                                                name="arrow-right"></i-feather>&nbsp;
                                            <span>{{change.newValue.toString() | translate}}</span>
                                        </li>
                                    </ul>
                                </span>

                                <span *ngSwitchCase="auditLogType.SHIFTPLANNING_CHANGE">
                                    <span>{{value.change}}</span>
                                    <ul class="pd-0 mg-0">
                                        <li *ngFor="let change of value.changes" class="pd-b-10">
                                            <div>{{ 'shiftProperty.'+change.property | translate }}:</div>
                                            <span>{{change.oldValue.toString() | translate}}</span>
                                            &nbsp;<i-feather [style.height.px]="15" [style.width.px]="15"
                                                name="arrow-right"></i-feather>&nbsp;
                                            <span>{{change.newValue.toString() | translate}}</span>
                                        </li>
                                    </ul>
                                </span>

                                <span *ngSwitchCase="auditLogType.DELIVERY_CHANGE">
                                    {{value.change | translate}}
                                </span>
                                <span *ngSwitchCase="auditLogType.DELIVERY_ASSIGN">
                                    {{'Delivery assigned from route' | translate}}: {{row.date}}, {{row.routeNumber}}
                                </span>
                                <span *ngSwitchCase="auditLogType.DELIVERY_UNASSIGN">
                                    {{'Delivery unassigned from route' | translate}}: {{row.date}}, {{row.routeNumber}}
                                </span>
                                <span *ngSwitchDefault>
                                    {{value.change}}
                                </span>
                            </ng-container>


                        </div>
                        <div *ngSwitchCase="'occurredAt'">{{value + '.000Z' | date:'d-MM-yyyy, HH:mm'}}</div>
                        <div *ngSwitchDefault>{{value}}</div>
                    </div>
                </ng-template>
            </ngx-datatable-column>
        </ng-container> -->
    </ngx-datatable>

</ng-container>

<ng-template #loaderRef>
    <div class="load-spinner text-center">
        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{'Please, wait!' | translate}}
    </div>
</ng-template>