import { Injectable } from '@angular/core';
import { CrudService } from '@services/crud.service';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '@services/utils.service';
import { ApmDelivery } from '../entities/apm-delivery';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environment';
import { ApmDeliveryMove } from '../interfaces/apm-delivery-move.interface';
import { D2DDelivery } from '../interfaces/d2d-delivery.interface';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { ApmDeliveryRebook } from '../interfaces/apm-delivery-rebook.interface';

@Injectable({
  providedIn: 'root'
})
export class ApmDeliveryService extends CrudService<ApmDelivery, number>{

    protected static BASE_API: string = `${environment.api.url}${environment.api.prefix}/apmdelivery/v1`;
    private static GET_APM_DELIVERIES_FOR_PARTNER: string = `${environment.api.url}${environment.api.prefix}/apmdelivery/v1/` + '${partnerId}';

    constructor(
        protected _http: HttpClient,
        protected utilsService: UtilsService,
        private toastrProviderService: ToastrProviderService,
        private translate: TranslateService) {
        super(_http, ApmDeliveryService.GET_APM_DELIVERIES_FOR_PARTNER, utilsService);
    }

    // public getApmDeliveriesForPartner(partnerId: string, params: QueryParams): void {
    //     this.findAllPageableResponse(params, this.utilsService.interpolate(ApmDeliveryService.GET_APM_DELIVERIES_FOR_PARTNER, {partnerId}));
    // }

    public getApmDelivery(id: string): Observable<ApmDelivery> {
      const endpoint = this.utilsService.interpolate(`${ApmDeliveryService.BASE_API}/${id}`, {});
      return this._http.get<ApmDelivery>(endpoint);
    }

    public rescheduleDelivery(id: string, body: ApmDeliveryMove): Observable<ApmDelivery> {
      const endpoint = this.utilsService.interpolate(`${ApmDeliveryService.BASE_API}/${id}/move`, {});
      return this._http.put<ApmDelivery>(endpoint, body);
    }

    public rebookDelivery(id: string, body: ApmDeliveryRebook): Observable<ApmDelivery> {
      const endpoint = this.utilsService.interpolate(`${ApmDeliveryService.BASE_API}/${id}/rebook`, {});
      return this._http.put<ApmDelivery>(endpoint, body);
    }


    public convertToD2d(apmDeliveryId: string, body: D2DDelivery): Observable<D2DDelivery> {
      const endpoint = this.utilsService.interpolate(`${ApmDeliveryService.BASE_API}/${apmDeliveryId}/convertToDtD`, {});
      return this._http.put<D2DDelivery>(endpoint, body);
    }

    public convertToReplacementVehicle(deliveriesIds: string[], apmId: string): Observable<D2DDelivery> {
      const endpoint = this.utilsService.interpolate(`${ApmDeliveryService.BASE_API}/${apmId}/convertToReplacementVehicle`, {});
      return this._http.post<D2DDelivery>(endpoint, deliveriesIds);
    }

    public getLastVersionOfApmDelivery(id: string): Observable<ApmDelivery> {
      const endpoint = this.utilsService.interpolate(`${ApmDeliveryService.BASE_API}/${id}/currentVersion`, {});
      return this._http.get<ApmDelivery>(endpoint);
    }

    public discounted(apmDeliveryId: string, value: boolean, discountedReason: string): Observable<ApmDelivery> {
      const endpoint = this.utilsService.interpolate(`${ApmDeliveryService.BASE_API}/${apmDeliveryId}/discounted/${value}`, {});
        
        return this._http.put(endpoint, discountedReason ? discountedReason : " ", this.utilsService.httpHeaders()).pipe(
            map((resp: ApmDelivery) => {
                this.toastrProviderService.showSuccess(this.translate.instant('The action has executed successfully!'));
                return resp;
            })
        );
    }
}
