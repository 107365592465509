import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ModalModule } from 'ngx-bootstrap/modal';

import { AuthenticationModule } from '../authentication/authentication.module';
import { SharedModule } from '../shared.module';
import { HardwareEditComponent } from './components/hardware-edit.component';
import { HardwareSearchbarComponent } from './components/hardware-searchbar.component';
import { HardwareUsagesComponent } from './components/hardware-usages.component';
import { VanEditComponent } from './components/van-edit.component';
import { VansComponent } from './components/vans.component';
import { HardwareRouting } from './hardware.routing';
import { HardwareService } from './services/hardware.service';
import { VehicleTypeComponent } from './components/vehicle-type/vehicle-type.component';
import { VehicleTypeEditComponent } from './components/vehicle-type-edit/vehicle-type-edit.component';
import { VehicleTypeResolver } from './resolvers/vehicle-type.resolver';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [CommonModule, SharedModule, RouterModule, FormsModule, ReactiveFormsModule, NgxDatatableModule, HardwareRouting, AuthenticationModule, ModalModule.forRoot()],
    declarations: [VansComponent, VanEditComponent, HardwareEditComponent, HardwareUsagesComponent, HardwareSearchbarComponent, VehicleTypeComponent, VehicleTypeEditComponent],
    providers: [HardwareService, VehicleTypeResolver]
})
export class HardwareModule {}
