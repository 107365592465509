import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ConfigValueService } from '../../services/config-value.service';
import { Observable } from 'rxjs';
import { Collection } from '@interfaces/collection.interface';
import { ConfigValue } from '../../entities/config-value';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { switchMap, filter, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ConfigValuesParams } from '../../interfaces/config-values-params.interface';
import { NavService } from '@services/nav.sevice';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrProviderService } from '@services/toastr-provider.service';
import * as _ from 'lodash';
import { UtilsService } from '@services/utils.service';
import { ConfigKeyTypes } from '../../enums/config-key.type';
import { CompartmentType } from '@enums/enum';
import { tooltipLabel } from '@shared/functions/tooltip-labels.function';

@Component({
  selector: 'app-config-values-by-scope',
  templateUrl: './config-values-by-scope.component.html',
  styleUrls: ['./config-values-by-scope.component.scss']
})
export class ConfigValuesByScopeComponent implements OnInit {

  public configValues$: Observable<ConfigValue[]>;
  public configValuesCollection: ConfigValue[];
  private configValuesCopy: ConfigValue[];

  public modifiedRow: string;
  public queryParams: ConfigValuesParams;
  public scope;

  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  public loader = false;
  public configKeyTypes = ConfigKeyTypes;
  public compartmentType = CompartmentType;

  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  public rowClass;

  public columns = [
    { prop: 'configKey.code', name: this.translateService.instant('Code'), sortable: true, flexGrow: 3 },
    { prop: 'scope', name: this.translateService.instant('Scopes'), sortable: true, flexGrow: 2 },
    { prop: 'value', name: this.translateService.instant('Value'), sortable: true, flexGrow: 2 },
    { prop: 'configKey.type', name: this.translateService.instant('Type'), sortable: true, flexGrow: 2 },
    { prop: 'isDefault', name: this.translateService.instant('Default'), sortable: true, flexGrow: 2 },
    { prop: 'options', name: this.translateService.instant('Options'), sortable: false, flexGrow: 1 },
  ];

  constructor(
    private readonly configValueService: ConfigValueService,
    private readonly route: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly navService: NavService,
    private readonly modalService: BsModalService,
    private toastrProviderService: ToastrProviderService,
    private readonly utilsService: UtilsService,
    private readonly navigationRoute: ActivatedRoute,
    private readonly router: Router
  ) { }

  public ngOnInit() {
    this.configValues$ = this.route.queryParams.pipe(
      switchMap((params: ConfigValuesParams) => {
        this.scope = this.utilsService.findUpRouteParams(this.navigationRoute.snapshot, 'scopeId');
       
        const timeslotId = this.utilsService.findUpRouteParams(this.navigationRoute.snapshot, 'timeslotId');
        const regionId = this.utilsService.findUpRouteParams(this.navigationRoute.snapshot, 'regionId');

        this.queryParams = {
          partnerId: this.utilsService.findUpRouteParams(this.navigationRoute.snapshot, 'partnerId'),
          scope: this.utilsService.findUpRouteParams(this.navigationRoute.snapshot, 'scope'),
          timeslotId: timeslotId ? timeslotId : null,
          regionId: regionId ? regionId : null
        }
        return this.configValueService.getConfigValuesByScope(this.queryParams)
      }),
      tap(config => this.configValuesCollection = this.configValuesCopy = config)
    )
  }

  public goToDetails(row: ConfigValue): void {
    this.router.navigate(['../edit', (row.id) ? row.id : row.configKey.code,  {...this.queryParams, code: row.configKey.code, isDefault: row.isDefault.toString(), value: row.value}], {relativeTo: this.navigationRoute});
  }

  public isOptionsColumn(status: string): boolean {
    return status === 'options';
  }

  public isColumnToTranslate(status: string): boolean {
    return status === 'type' || status === 'status';
  }

  public isColumnScope(status: string): boolean {
    return status === 'scope';
  }

  public isColumnPartner(status: string): boolean {
    return status === 'partnerId';
  }

  public isColumnDefault(status: string): boolean {
    return status === 'isDefault';
  }

  public canManage(keyScope: number) {
    return (keyScope & this.queryParams.scope) == this.queryParams.scope;
  }

  public removeConfig(id: string): void {
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.configValueService.delete(id);
  }

  public applyFiltering(event) { 
    const filterPhrase = event.target.value.toLowerCase();
    const temp = _.filter(this.configValuesCopy, (cv: ConfigValue) => {
      const searchIn: string = cv.configKey.code;
      return searchIn.toLowerCase().indexOf(filterPhrase) !== -1 || !filterPhrase;
    });

    this.configValuesCollection = temp; 
  }

  public confirm(): void {
    this.confirmObservable.subscribe((test) => {
      this.modalRef.hide();
      this.configValues$.pipe();
      this.toastrProviderService.showSuccess(this.translateService.instant('The config value has been returned to default value!'));
      this.confirmObservable = null;
      this.loader = true;
      setTimeout(() => this.loader = false, 500);
    });
  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }

  public tooltipLabel(property: string): string {
    return tooltipLabel(this.translateService, `tooltips.configkeys.labels.${property}`);
  }
  
}
