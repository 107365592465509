<layout-default>
        <div header>
            <div toolbar>
                <h4>
                    <i-feather name="inbox"></i-feather> {{'Subscriptions' | translate}}
                </h4>
            </div>
    
            <div toolbar>
                <button id="btn-subscription-create" class="btn btn-sm btn-uppercase btn-white"
                    [routerLink]="['/subscription/create']">
                    <i-feather [style.height.px]="23" [style.width.px]="13" class="mg-r-10" name="plus"></i-feather> {{ 'Add subscription' | translate }}
                </button>
                <div id="input-subscription-filter" class="filter-container">
                    <div class="phrase-filter">
                        <input type='text' class="list-filter form-control" placeholder="{{'type to search' | translate}}" (keyup)='applyFiltering($event)'/>
                    </div>
                </div>
            </div>
            <hr />
        </div>
        
        <div content>
            <div *ngIf="(list$ | async) as list">
                <ngx-datatable 
                    id="subscriptions" 
                    class="bootstrap" 
                    [rows]="subscriptions" 
                    [columns]="columns" 
                    [columnMode]="'flex'"
                    [headerHeight]="50" 
                    [footerHeight]="50" 
                    [messages]="tableMessages" 
                    [rowHeight]="'auto'" 
                    [externalPaging]="true"
                    [count]="list.totalElements"
                    [offset]="queryParams.page"
                    [rowClass]="rowClass"
                    [limit]="queryParams.size"
                    [scrollbarH]="scrollBarHorizontal"
                    (sort)="onSort($event)"
                    (page)="setPage($event)">
                    <ng-container *ngFor="let col of columns">
                        <ngx-datatable-column [flexGrow]="col.flexGrow" [prop]="col.prop" [headerClass]="col.headerClass"
                        >
                            <ng-template let-column="column" ngx-datatable-header-template class="header" let-sort="sortFn">
                                <b (click)="sort()">{{ col.name | translate }}</b>
                            </ng-template>
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <!-- <span *ngIf="isCapacityColumn"></span> -->
                                <div *ngIf="isColumnToTranslate(col.prop)">{{ value | translate }}</div>
                                <div *ngIf="isColumnDate(col.prop)">{{ value | amLocale: locale | amDateFormat: 'D MMM YYYY'  }}</div>
                                <div *ngIf="isColumnApm(col.prop)">{{ value | findApmPipe:apms}}</div>
                                <div *ngIf="isColumnPartner(col.prop)">{{ value | findPartnerPipe:partners}}</div>
                                <div *ngIf="isColumnCapacity(col.prop)">
                                    <span 
                                        [containerClass]="'pretty-tooltip'" 
                                        placement="right" 
                                        [tooltip]="row | rlmCapacityLabelPipe">{{row | rlmCapacityLabelShortPipe}}</span>
                                </div>

                                <div *ngIf="!isOptionsColumn(col.prop) && !isColumnToTranslate(col.prop) && !isColumnDate(col.prop) && !isColumnApm(col.prop) && !isColumnPartner(col.prop) && !isColumnCapacity(col.prop)">{{ value }}</div>
                                <div *ngIf="isOptionsColumn(col.prop)">
                                    <div class="dropdown" dropdown>
                                        <button
                                            class="btn btn-outline-secondary btn-xs dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton">
                                            {{ 'Select' | translate }}
                                        </button>
    
                                        <div class="dropdown-menu dropdown-menu-right div-main-actions">
                                            <a 
                                                id="btn-subscription-edit"
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                                (click)="goToDetails(row.id)">
                                                    {{'Edit' | translate }}
                                            </a>
    
                                            <a 
                                                id="btn-subscription-remove"
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                                (click)="removeSubscription(row.id)">
                                                    {{'Remove' | translate }}
                                            </a>
    
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                    </ng-container>
                </ngx-datatable>
            </div>
    
            <div *ngIf="loader" class="load-spinner text-center">
                <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}
            </div>
    
        </div>
    
    </layout-default>
    
    <ng-template #confirmation>
        <div class="modal-header">
            <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span aria-hidden="true">&times;</span></button>
        </div>
    
        <div class="modal-body text-center">
            <p>{{'Do you confirm remove the Subscription' | translate }}?</p>
            <button type="button" class="btn btn-brand-01" (click)="confirm()">
                {{'Yes, remove' | translate }}
            </button>
            <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
        </div>
    </ng-template>
    