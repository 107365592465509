import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { RegionService } from '../services/region.service';
import { UtilsService } from '@services/utils.service';

@Injectable()
export class AssignApmsResolver implements Resolve<any> {
    constructor(private regionService: RegionService, private readonly utilsService: UtilsService) { }
    public resolve(route: ActivatedRouteSnapshot) {

        const params = {
            partnerId: this.utilsService.findUpRouteParams(route, 'partnerId'),
            regionId: this.utilsService.findUpRouteParams(route, 'regionId')
        }

        return this.regionService.getAssignApmsToRegion(params['partnerId'], params['regionId']);
    }
}
