import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { SubscriptionService } from '../services/subscription.service';

@Injectable()
export class SubscriptionsResolver implements Resolve<any> {
    constructor(private subscriptionService: SubscriptionService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        return this.subscriptionService.findAllPageableResponse();
    }
}
