<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="database"></i-feather> {{'Config key' | translate}}
            </h4>
        </div>

        <div toolbar>
            <button class="btn btn-sm btn-uppercase btn-white"
                [routerLink]="['/config-key/create']">
                <i-feather [style.height.px]="23" [style.width.px]="13" class="mg-r-10" name="plus"></i-feather> {{ 'Add config key' | translate }}
            </button>
            <div class="filter-container">
                <div class="phrase-filter">
                    <input type='text' class="list-filter form-control" placeholder="{{'type to search' | translate}}" (keyup)='applyFiltering($event)'/>
                </div>
            </div>
        </div>
        <hr />
    </div>
    
    <div content>
        <div *ngIf="!loader">
            <ngx-datatable 
                id="config-key" 
                class="bootstrap" 
                [rows]="configKeys" 
                [columns]="columns" 
                [columnMode]="'flex'"
                [headerHeight]="50" 
                [footerHeight]="50" 
                [rowClass]="rowClass"
                [messages]="tableMessages" 
                [rowHeight]="'auto'">
                <ng-container *ngFor="let col of columns">
                    <ngx-datatable-column [flexGrow]="col.flexGrow" [prop]="col.prop">
                        <ng-template let-column="column" ngx-datatable-header-template class="header" let-sort="sortFn">
                            <b>{{ col.name | translate }}</b>
                        </ng-template>
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>

                            <ng-container [ngSwitch]="col.prop">
                                <div *ngSwitchCase="'code'">
                                    <span>{{ 'ConfigKey.'+value | translate}} &nbsp;</span>
                                    <i-feather *ngIf="tooltipLabel(value)" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(value)"
                                        [style.width.px]="13" name="info"></i-feather>      
                                </div>
                                <div *ngSwitchCase="'type'">{{'ConfigKeyType.'+value | translate}}</div>
                                <div *ngSwitchCase="'scopes'">{{ 'SCOPE.'+value | translate }}</div>

                                <div *ngSwitchCase="'options'">
                                    <div class="dropdown" dropdown>
                                        <button
                                            class="btn btn-outline-secondary btn-xs dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton">
                                            {{ 'Select' | translate }}
                                        </button>
    
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a 
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                                (click)="goToDetails(row.id)">
                                                    {{'Edit' | translate }}
                                            </a>
    
                                            <a 
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                                (click)="removeConfig(row.id)">
                                                    {{'Remove' | translate }}
                                            </a>
    
                                        </div>
                                    </div>
                                </div>


                                <p *ngSwitchDefault>
                                    {{value}}
                                </p>
                            </ng-container>


                           
                        </ng-template>
                    </ngx-datatable-column>
                </ng-container>
            </ngx-datatable>
        </div>

        <div *ngIf="loader" class="load-spinner text-center">
            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}
        </div>

    </div>

</layout-default>

<ng-template #confirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p>{{'Do you confirm remove the config' | translate }}?</p>
        <button type="button" class="btn btn-brand-01" (click)="confirm()">
            {{'Yes, remove' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>
