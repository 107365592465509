import { Component, OnInit } from '@angular/core';
import { ApmDelivery } from '../../entities/apm-delivery';
import { CRUD_MODE, ApmDeliveryStatus, LogisticTypeEnum } from '@enums/enum';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '@services/nav.sevice';
import { Apm } from '../../entities/apm';
import { Partner } from '../../entities/partner';
import { Subscription } from '../../entities/subscription';
import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { datePickerOptions } from '@shared/constants/datepicker-options.constants';
import { ApmDeliveryService } from '../../services/apm-delivery.service';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-edit-apm-delivery',
  templateUrl: './edit-apm-delivery.component.html',
  styleUrls: ['./edit-apm-delivery.component.scss']
})
export class EditApmDeliveryComponent implements OnInit {

  public apmDelivery: ApmDelivery;
  public mode: CRUD_MODE;

  public apms: Apm[];
  public partners: Partner[];
  public subscriptions: Subscription[];

  public form: FormGroup;
  public loader: boolean = true;
  public submitted: boolean = false;

  get apmDeliveryStatus() {
    return _.values(ApmDeliveryStatus);
  }

  get apmDeliveryLogisticType() {
    return _.values(LogisticTypeEnum);
  }

  get options() {
    return datePickerOptions;
  }

  constructor(
    private navigationRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private apmDeliveryService: ApmDeliveryService,
    private toastrProviderService: ToastrProviderService,
    private translateService: TranslateService,
    private navService: NavService
  ) { }

  public ngOnInit() {
    this.apmDelivery = (this.navigationRoute.snapshot.data as {apmDelivery: any}).apmDelivery;

    this.subscriptions = plainToClass(Subscription, (this.navigationRoute.snapshot.data as {subscriptions: any}).subscriptions.content as Subscription[]);
    this.apms = plainToClass(Apm, (this.navigationRoute.snapshot.data as {apms: any}).apms.content as Apm[]);
    this.partners = plainToClass(Partner, (this.navigationRoute.snapshot.data as {partners: any}).partners.content as Partner[]);

    this.mode = (this.apmDelivery) ? CRUD_MODE.UPDATE : CRUD_MODE.CREATE;
    this.initForm();
  }

  private initForm() {
    const date = new Date();

    this.form = this.formBuilder.group({
      id: [this.apmDelivery ? this.apmDelivery.id : null],
      version: [this.apmDelivery ? this.apmDelivery.version : null],
      
      apmId: [this.apmDelivery ? this.apmDelivery.apmId : null, Validators.required],
      partnerId: [this.apmDelivery ? this.apmDelivery.partnerId : null, Validators.required],
      subscriptionId: [this.apmDelivery ? this.apmDelivery.subscriptionId : null, Validators.required],
      
      customerEmail: [this.apmDelivery ? this.apmDelivery.customerEmail : null, [Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')]],
      customerPhone: [this.apmDelivery ? this.apmDelivery.customerPhone : null, Validators.required],
      reservationReference: [this.apmDelivery ? this.apmDelivery.reservationReference : null, Validators.required],
      reservationNumber: [this.apmDelivery ? this.apmDelivery.reservationNumber : null, Validators.required],
      
      lcount: [this.apmDelivery ? this.apmDelivery.lcount : 0, Validators.required],
      mcount: [this.apmDelivery ? this.apmDelivery.mcount : 0, Validators.required],
      ncount: [this.apmDelivery ? this.apmDelivery.ncount : 0, Validators.required],
      ocount: [this.apmDelivery ? this.apmDelivery.ocount : 0, Validators.required],

      logisticType: [this.apmDelivery ? this.apmDelivery.logisticType : null, Validators.required],
      status: [this.apmDelivery ? this.apmDelivery.status : null, Validators.required],
      
      onDeliveryAmount: [this.apmDelivery ? this.apmDelivery.onDeliveryAmount : 0, [Validators.required]],
      
      courierWindow: this.formBuilder.group({
        from: [this.apmDelivery ? this.apmDelivery.courierWindow.from : date, Validators.required],
        to: [this.apmDelivery ? this.apmDelivery.courierWindow.to : date, Validators.required],
      }),

      purgeWindow: this.formBuilder.group({
        from: [this.apmDelivery ? this.apmDelivery.purgeWindow.from : date, Validators.required],
        to: [this.apmDelivery ? this.apmDelivery.purgeWindow.to : date, Validators.required],
      }),
      
      reservationWindow: this.formBuilder.group({
        from: [this.apmDelivery ? this.apmDelivery.purgeWindow.from : date, Validators.required],
        to: [this.apmDelivery ? this.apmDelivery.purgeWindow.to : date, Validators.required],
      }),
    });

    this.loader = false;

  }

  public regularFormItems(): string[] {
    return ['customerEmail', 'customerPhone', 'reservationReference', 'reservationNumber']
  }

  public onSubmit() {

    const raw = this.form.getRawValue();
    console.log(raw);

    if (this.mode === CRUD_MODE.CREATE) {
      delete raw['id'];
      this.create(raw);
    } else {
      this.update(raw);
    }
  }

  private create(rawApm): void {
    this.apmDeliveryService.save(rawApm)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (apmDelivery: ApmDelivery) => {
          console.log(apmDelivery);
          this.toastrProviderService.showSuccess(this.translateService.instant('New apm delivery has been created!'));
          this.navService.goToPage(`/apm-delivery/partner/${apmDelivery.partnerId}/list`, {modifiedRow: apmDelivery.id});
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }

  private update(rawApm): void {
    this.apmDeliveryService.update(rawApm['id'], rawApm)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (apmDelivery: ApmDelivery) => {
          console.log(apmDelivery);
          this.toastrProviderService.showSuccess(this.translateService.instant('New apm delivery has been updated!'));
          this.navService.goToPage(`/apm-delivery/partner/${apmDelivery.partnerId}/list`, {modifiedRow: apmDelivery.id});
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }
}
