import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ConfigKeyService } from '../services/config-key.service';

@Injectable()
export class ConfigKeysResolver implements Resolve<any> {
    constructor(private configKeyService: ConfigKeyService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        return this.configKeyService.findAll();
    }
}
