import { Component, OnInit } from '@angular/core';
import { Partner } from '../../entities/partner';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import * as ICoords from '@interfaces/coordinates.interface';
import { mapOptions, mapTypes } from '@enums/enum';
import { D2dService } from '../../services/d2d.service';
import * as _ from 'lodash';
import { switchMap, first } from 'rxjs/operators';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { AppService } from '@services/app.service';
import { LocalStorageService } from '@services/local-storage.service';

@Component({
  selector: 'app-d2d-tests',
  templateUrl: './d2d-tests.component.html',
  styleUrls: ['./d2d-tests.component.scss']
})
export class D2dTestsComponent implements OnInit {

  public model: {
    partner: string,
    region: string,
    date: string,
    lat?: number,
    lng?: number,
    data: string,
    mcount: number,
    ncount: number,
    ocount: number,
    lcount: number,
    firstName: string,
    lastName: string,
    pickupFrom: string,
    pickupTo: string
  }

  public coordinatesRaw: ICoords.Coordinates = undefined;
  public mapOption = mapOptions.REGULAR;
  public mapProperties = {
    zoom: 18,
    mapTypeId: mapTypes.ROADMAP
  };

  public availabilityList: any[];

  public tmpData: Object = {
    "lcount": 1,
    "mcount": 0,
    "ncount": 0,
    "ocount": 0,
    "location": {
        "address": {
            "town": "Warszawa",
            "zip": "02-548",
            "country": "Poland",
            "street": "Koszykowa",
            "houseNO": "12"
        },
        "coordinates": {
          "lat": 50.03989200945398,
          "lng": 19.96357283093565
        },
        "additional": {
          "companyName": null,
          "flatNO": null,
          "lift": null,
          "staircaseNO": null,
          "floor": null,
          "contactName": null,
          "contactPhoneNO": null,
          "contactEmail": null,
          "domofonCode": null,
          "instructions": null
      },
      "ref": '45678901'
    },
    "orderId": "",
    "customer": {
        "phoneNr": "+48 555 555 555",
        "ref": "freferfrew"
    },
}

  public partnersList: Partner[];
  public locale = 'PL-pl';
  public reservationId: string;

  constructor(
    private readonly navigationRoute: ActivatedRoute,
    private readonly d2dService: D2dService,
    private toastrProviderService: ToastrProviderService,
    private readonly appService: AppService,
    private readonly localStorageService: LocalStorageService
  ) { }

  public ngOnInit() {
    this.partnersList = (this.navigationRoute.snapshot.data as {partners: any}).partners.content;
    this.coordinatesRaw = this.appService.findWarehouseCoordinates(this.localStorageService.getDepot())

    this.model = {
      partner: this.partnersList[0].id,
      region: '',
      date: moment().format('YYYY-MM-DD'),
      data: JSON.stringify(this.tmpData),
      lcount: 1,
      ncount: 0,
      mcount: 0,
      ocount: 0,
      firstName: 'John',
      lastName: 'Doe',
      pickupFrom: '05:45:00',
      pickupTo: '12:15:00'
    }
  }

  public regionsList() {
    return (this.model) ? this.partnersList.find((p: Partner) => p.id === this.model.partner).regionList : [];
  }

  public checkAv() {
    Object.assign(this.tmpData, {partnerId: this.model.partner});
    Object.assign(this.tmpData, {date: this.model.date});
    Object.assign(this.tmpData, {regionId: (this.model.region) ? this.model.region : this.partnersList.find((p: Partner) => p.id === this.model.partner).regionList[0].id});
    Object.assign(this.tmpData, {partnerId: this.model.partner});
    Object.assign(this.tmpData, {lcount: this.model.lcount});
    Object.assign(this.tmpData, {ncount: this.model.ncount});
    Object.assign(this.tmpData, {mcount: this.model.mcount});
    Object.assign(this.tmpData, {ocount: this.model.ocount});

    Object.assign(this.tmpData, {pickupWindow: {
      from: `${this.model.date}T${this.model.pickupFrom}Z`,
      to: `${this.model.date}T${this.model.pickupTo}Z`
    }});

    Object.assign(this.tmpData, {customer: {
        firstName: this.model.firstName,
        lastName: this.model.lastName,
        phoneNr: "+48 555 555 555",
        ref: _.random(100, 99999)}
    });

    this.tmpData['location']['ref'] = _.random(100, 99999);
 
    console.log(this.tmpData);

    this.d2dService.checkAvailability(this.tmpData).pipe(first()).subscribe(
      (data) => {
        console.log(data);
        this.availabilityList = data;
      }
    )
  }

  public changeCoordinates(coordinates: ICoords.Coordinates): void {
   this.tmpData['location']['coordinates']['lat'] = coordinates.lat;
   this.tmpData['location']['coordinates']['lng'] = coordinates.lng;
  }

  public reserve(item) {
    const body = _.cloneDeep(this.tmpData);

    Object.assign(body, {cutOff: item['slot']['cutOff']});
    Object.assign(body, {deliveryWindow: item['slot']['deliveryWindow']});

    this.d2dService.reserve(body).pipe(first()).subscribe(
      (data) => {
        console.log(data);
        this.reservationId = data;
        this.toastrProviderService.showSuccess('Pomyślnie zarezerwowano!');
      },
      (err) => {
        //this.toastrProviderService.showError(`Błąd! ${err.message}`);
      }
    )
  }

  public confirm() {
    this.d2dService.confirm(this.model.partner, this.reservationId).pipe(first()).subscribe(
      (data) => {
        console.log(data);
        this.toastrProviderService.showSuccess('Rezerwacja została potwierdzona!');
      },
      (err) => {
        this.toastrProviderService.showError(`Błąd! ${err.message}`);
      }
    )
  }

  public cancel() {
    this.d2dService.cancel(this.model.partner, this.reservationId).pipe(first()).subscribe(
      (data) => {
        console.log(data);
        this.toastrProviderService.showSuccess('Rezerwacja została anulowana!');
      },
      (err) => {
        this.toastrProviderService.showError(`Błąd! ${err.message}`);
      }
    )
  }

  public extend() {
    this.d2dService.getDelivery(this.model.partner, this.reservationId).pipe(
      switchMap(a => {
        console.log(a);
        return this.d2dService.extend(this.model.partner, this.reservationId)
      }),
      first()
    ).subscribe(
      (data) => {
        console.log(data);
        this.toastrProviderService.showSuccess('Rezerwacja została przedłużona!');
      },
      (err) => {
        this.toastrProviderService.showError(`Błąd! ${err.message}`);
      }
    )
  }
}
