import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment';
import { Activity } from '@entities/activity';
import { ActivityType } from '@enums/enum';

@Pipe({
    name: 'findActivityByTypePipe',
    pure: true
})
export class FindActivityByTypePipe implements PipeTransform {
    public transform(activities: Activity[], type: ActivityType): any {
        return activities.filter((a: Activity) => a.type === type);
    }
}
