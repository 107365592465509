
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ConfigValueService } from '../services/config-value.service';
import { UtilsService } from '@services/utils.service';

@Injectable()
export class ConfigValueResolver implements Resolve<any> {
    constructor(private configValueService: ConfigValueService, private readonly utilsService: UtilsService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        const configId = this.utilsService.findUpRouteParams(route, 'configId')
        return this.configValueService.findOne(configId);
    }
}
