import { Injectable } from '@angular/core';
import { CrudService } from '@services/crud.service';
import { Apm } from '../entities/apm';
import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '@services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class ApmForPartnerService extends CrudService<Apm, number>{
  
  protected static BASE_API: string = `${environment.api.url}${environment.api.prefix}/` + 'partner/v1/${partnerId}/apm/${apmId}/otherApmsInRegion';  
  constructor(
    protected _http: HttpClient, 
    protected utilsService: UtilsService) 
    {
      super(_http, ApmForPartnerService.BASE_API, utilsService);
    }
}
