import { Pipe, PipeTransform } from '@angular/core';
import { ApmDelivery } from '../entities/apm-delivery';

@Pipe({
    name: 'findApmDeliveryByIdPipe',
    pure: true
})
export class FindApmDeliveryByIdPipe implements PipeTransform {

    public transform(apmDelivery: ApmDelivery[], id: string): ApmDelivery | undefined {
        
        if (apmDelivery) {
            return apmDelivery.find(r => r.reservationNumber === id);
        }
        return undefined;
    }
}
