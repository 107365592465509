import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Driver } from '@drivers/entities/driver';
import { DepotAssignHelper } from '../depot-assign-helper/depot-assign-helper.component';
import { remove } from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { DepotService } from '../../services/depot.service';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { forEach, values } from 'lodash';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { NavService } from '@services/nav.sevice';

@Component({
    selector: 'app-depot-assign-drivers',
    templateUrl: './depot-assign-drivers.component.html',
    styleUrls: ['./depot-assign-drivers.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DepotAssignDriversComponent extends DepotAssignHelper<Driver> implements OnInit {
    
    constructor(
      public readonly navigationRoute: ActivatedRoute, 
      protected readonly formBuilder: FormBuilder,
      private readonly modalService: BsModalService,
      private readonly translateService: TranslateService,
      private readonly depotService: DepotService,
      private toastrProviderService: ToastrProviderService,
      private readonly navService: NavService,
      private readonly cdr: ChangeDetectorRef,

    ) {
        super(navigationRoute, formBuilder);
    }

    public ngOnInit() {
      this.loader = false;
    }

    public prepareEntities() {
        this.list.map((driver: Driver) => {
            const isExist = this.assignedEntities.find((d: Driver) => d.id === driver.id);

            if (isExist === undefined) {
                this.rawEntities.push({
                    id: driver.id,
                    identifier: `${driver.firstName} ${driver.lastname}`,
                    additionalInfo: driver.employeeId,
                });
            }
        });

        this.rawEntitiesCollection = this.rawEntities;
        this.loader = false;
        this.cdr.detectChanges();
    }

    public selectEvent(item) {
      console.log('selectEvent');
      

        const driver: Driver = this.list.find((d: Driver) => d.id === item['id']);
        const num: number = this.idsControl.controls.length;

        this.idsControl.controls[num - 1].setValue(
            {
                id: driver.id,
                identifier: driver && driver.firstName ? `${driver.firstName} ${driver.lastname}` : driver.employeeId,
                additionalInfo: driver.employeeId,
            },
            { onlySelf: true }
        );

        remove(this.rawEntities, (d: Driver) => d.id === driver.id);

        this.addStep();
        this.cdr.detectChanges();
    }

    public onChangeSearch(phrase) {
      console.log('onChangeSearch');

      if (!this.rawEntitiesCollection.length) {
        this.prepareEntities();
      }

      this.rawEntities = this.rawEntitiesCollection;
      this.rawEntities = this.rawEntities.filter(a => a.identifier.toLowerCase().includes(phrase.toLowerCase()) || a.additionalInfo.toLowerCase().includes(phrase.toLowerCase()))
      this.cdr.detectChanges();
      return;
    }

    public remove(i): void {
      this.entityToRemove = this.idsControl.controls[i].value;
      this.entityToRemoveId = i;
      const id: string = (this.idsControl).controls[i].value['id'];
      this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
      this.confirmObservable = this.depotService.unassignDriverFromDepot(this.depot.id, id);
      this.cdr.detectChanges();
    }
  
    public confirm(): void {
      this.confirmObservable.subscribe((test) => {
        this.modalRef.hide();
        (this.idsControl).removeAt(this.entityToRemoveId);

        this.rawEntities.push({
          id: this.entityToRemove.id,
          identifier: this.entityToRemove.firstName ? `${this.entityToRemove.firstName} ${this.entityToRemove.lastname}` : this.entityToRemove.employeeId,
          additionalInfo: this.entityToRemove.employeeId,
        });

        this.toastrProviderService.showWarning(
            this.translateService.instant('The driver {{userName}} has been unassigned from {{depotName}}!', {
                userName: this.entityToRemove['identifier'],
                depotName: this.depot.name
            })
        );
        this.confirmObservable = null;
        this.navService.goToPage(`/locations/depots/list`, {modifiedRow: this.depot.id});
      });
    }

    public onSubmit() {

      const form = this.form.getRawValue();
      const body: string[] = [];

      forEach(values(form['ids']), (driver: Driver) =>  driver.id && this.assignedEntities.find(d => driver.id === d.id) === undefined ? body.push(driver.id) : null)
      
      this.submitted = true;
      this.depotService.assignDriversToDepot(this.depot.id, body)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            this.submitted = false;
            throw (error);
          })
        )
        .subscribe(
          (region: any) => {
            console.log(region);
            this.toastrProviderService.showSuccess(this.translateService.instant('The drivers list has been updated successfully!'));
            this.navService.goToPage(`/locations/depots/list`, {modifiedRow: this.depot.id});
            this.submitted = false;
          },
          error => {
            console.log(`Error occurred, please try again!`, error);
            this.submitted = false;
          },
          () => {
            this.submitted = false;
          }
        );
    }

}
