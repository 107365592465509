
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ConfigKeyService } from '../services/config-key.service';
import { UtilsService } from '@services/utils.service';

@Injectable()
export class ConfigKeyResolver implements Resolve<any> {
    constructor(private configKeyService: ConfigKeyService, private readonly utilsService: UtilsService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        const configKeyId = this.utilsService.findUpRouteParams(route, 'configKeyId')
        return this.configKeyService.findOne(configKeyId);
    }
}
