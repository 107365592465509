import { Component, OnInit } from '@angular/core';
import { ApmDelivery } from '../../../entities/apm-delivery';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudEditComponent } from '@shared/components/cruds/crud-edit.component';
import { D2DDelivery } from '../../../interfaces/d2d-delivery.interface';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { VehicleTypesService } from '@hardware/services/vehicle-types.service';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ApmDeliveryD2DService } from '../../../services/apm-delivery-d2d.service';
import { Address } from '@entities/address';
import { Additional } from '@entities/additional';
import { Coordinates } from '@entities/coordinates';
import { LocalStorageService } from '@services/local-storage.service';
import { plainToClass } from 'class-transformer';
import { ApmService } from '../../../services/apm.service';
import { mapOptions, mapTypes, DeliveryStatus, ApmDeliveryStatus } from '@enums/enum';
import { datePickerOptions } from '@shared/constants/datepicker-options.constants';
import * as moment from 'moment';
import { ApmDeliveryService } from '../../../services/apm-delivery.service';
import { Partner } from '../../../entities/partner';
import { PartnerService } from '../../../services/partner.service';
import { tooltipLabel } from '@shared/functions/tooltip-labels.function';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-apm-delivery-convert-d2d',
  templateUrl: './apm-delivery-convert-d2d.component.html',
  styleUrls: ['./apm-delivery-convert-d2d.component.scss']
})
export class ApmDeliveryConvertD2dComponent extends CrudEditComponent<D2DDelivery, string> implements OnInit {

  public apmDelivery: ApmDelivery;
  private entity: ApmDelivery;
  public partner$: Observable<Partner>;
  public form: FormGroup;
  public coordinates: Coordinates = null;

  public addressKeys = Object.keys(new Address());
  public additionalKeys = Object.keys(new Additional());
  public coordinatesKeys = Object.keys(new Coordinates());
  public options = datePickerOptions;
  public formErrorsMessages = [];
  
  public customerKeys: string[] = ['firstName', 'lastName', 'notes', 'phone2Nr', 'phoneNr'];
  public regularKeys: string[] = ['customerNotes', 'dispatcherNotes', 'pickupDuration'];
  public pickupWindowKeys: string[] = ['from', 'to'];

  public from: AbstractControl;
  public to: AbstractControl;

  public fromDate: AbstractControl;
  public toDate: AbstractControl;

  public fromTime: AbstractControl;
  public toTime: AbstractControl;

  public apmId: string;
  public slotTime: string;

  public locale = "pl_PL";

  public mapOption = mapOptions.REGULAR;
  public mapProperties = {
    zoom: 18,
    mapTypeId: mapTypes.ROADMAP
  };
  
  constructor(
    private readonly navigationRoute: ActivatedRoute,
    protected readonly formBuilder: FormBuilder,
    protected readonly apmDeliveryD2DService: ApmDeliveryD2DService,
    private readonly apmDeliveryService: ApmDeliveryService,
    protected readonly toastrProviderService: ToastrProviderService,
    protected readonly translateService: TranslateService,
    protected readonly localStorageService: LocalStorageService,
    protected readonly router: Router,
    private readonly partnerService: PartnerService,
    private readonly apmService: ApmService
  ) {
    super(apmDeliveryD2DService, translateService, toastrProviderService, router, navigationRoute)
  }

  public ngOnInit() {
    this.entity = (this.navigationRoute.snapshot.data as {entity: any}).entity as ApmDelivery;
    this.partner$ = this.partnerService.findOne(this.entity.partnerId);
    this.translateService.onLangChange.subscribe((lang: LangChangeEvent) => {
      this.locale = lang.lang;
    });

    this.apmId = this.navigationRoute.snapshot.params.apmId;
    this.slotTime = this.navigationRoute.snapshot.params.slotTime;

    this.initForm();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      customer: this.formBuilder.group({
        firstName: [null, [Validators.required, Validators.maxLength(255)]],
        lastName: [null, [Validators.required, Validators.maxLength(255)]],
        notes: [null, [Validators.maxLength(255)]],
        phone2Nr: [null, [Validators.maxLength(255)]],
        phoneNr: [this.entity.customerPhone, [Validators.maxLength(255)]],
      }),
      customerNotes: [null, [Validators.maxLength(255)]],
      date: [new Date(), [Validators.required, Validators.maxLength(255)]],
      dispatcherNotes: [null, [Validators.maxLength(255)]],
      locationAdditional: this.formBuilder.group({
        companyName: ['', Validators.maxLength(255)],
        flatNO: ['', Validators.maxLength(255)],
        lift: ['', Validators.maxLength(255)],
        staircaseNO: ['', Validators.maxLength(255)],
        floor: ['', Validators.maxLength(255)],
        contactName: ['', Validators.maxLength(255)],
        contactPhoneNO: [this.entity.customerPhone, [Validators.required, Validators.maxLength(255)]],
        domofonCode: ['', Validators.maxLength(255)],
        instructions: ['', Validators.maxLength(255)],
        contactEmail: [this.entity.customerEmail, [Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')]]
      }),
      locationAddress: this.formBuilder.group({
        country: [ 'Polska', [Validators.required, Validators.maxLength(255)]],
        district: [ '', [Validators.required, Validators.maxLength(255)]],
        houseNO: [ '', [Validators.required, Validators.maxLength(255)]],
        street: [ '', [Validators.required, Validators.maxLength(255)]],
        town: [ '', [Validators.required, Validators.maxLength(255)]],
        voivoidship: [ '', [Validators.required, Validators.maxLength(255)]],
        zip: [ '', [Validators.required, Validators.pattern('[0-9]{2}\-[0-9]{3}')]]
      }),
      locationCoordinates: this.formBuilder.group({
        lat: [0, Validators.required],
        lng: [0, Validators.required]
      }),
      pickupDuration: [[ApmDeliveryStatus.NEW, ApmDeliveryStatus.COMPLETED].includes(this.entity.status as ApmDeliveryStatus) ? 300 : 180, [Validators.required]],
      pickupWindow: this.formBuilder.group({
        fromDate: [moment().format('YYYY-MM-DD'), []],
        fromTime: [(this.entity['pickupWindow'] !== null) ? `${this.entity['pickupWindow'].from.toString().slice(11,16)}` : '', [Validators.required]],
        from: [null],
        toTime: [(this.entity['pickupWindow'] !== null) ? `${this.entity['pickupWindow'].to.toString().slice(11,16)}` : '', [Validators.required]],
        to: [null],
      }),
      slotStart: [null, [Validators.required, Validators.maxLength(255)]],
      slotEnd: [null, [Validators.required, Validators.maxLength(255)]],
      slot: [null],
      warehouse: [this.localStorageService.getDepot(), Validators.required]
    });

    this.from = (this.form.controls[ 'pickupWindow' ] as FormGroup).controls[ 'from' ];
    this.to = (this.form.controls[ 'pickupWindow' ] as FormGroup).controls[ 'to' ];
    this.fromDate = (this.form.controls[ 'pickupWindow' ] as FormGroup).controls[ 'fromDate' ];
    this.fromTime = (this.form.controls[ 'pickupWindow' ] as FormGroup).controls[ 'fromTime' ];
    this.toTime = (this.form.controls[ 'pickupWindow' ] as FormGroup).controls[ 'toTime' ];

    this.loader = false;
  }

  public regularFormItems(): string[] {
    return ['code']
  }

  public onSubmit() {
    this.changedFromTime();
    this.changedToTime();
    this.formErrorsMessages = [];

    const from = moment(this.from.value);
    const to = moment(this.to.value);

    if (from.isAfter(to)) {
      this.formErrorsMessages.push({field: "pickupDuration", value: this.translateService.instant('Date from needs to be before or equal date to')});
      return;
    }

    this.form.get('slot').setValue(`${this.form.get('slotStart').value}-${this.form.get('slotEnd').value}`);
    this.form.get('date').setValue(`${moment(this.form.get('date').value).format('YYYY-MM-DD')}`);
    const d2d: D2DDelivery = this.form.getRawValue() as D2DDelivery;

    d2d.pickupWindow = {
      from: d2d.pickupWindow.from,
      to: d2d.pickupWindow.to
    }

    this.apmDeliveryService.convertToD2d(this.entity.id, d2d).subscribe(
      (result) => {
        this.toastrProviderService.showSuccess(this.translateService.instant('The delivery has been superseded!'));
        this.router.navigate(['/apm', this.apmId, 'apm-deliveries', 'list', {slotTime: this.slotTime}]);
      },
      (error: any) => {
        console.log(`Error occurred, please try again!`, error);
          switch (error.status) {
            case 404:
              this.toastrProviderService.showError(this.translateService.instant('The delivery not found'));
              break;
            default : 
              this.toastrProviderService.showError(this.translateService.instant(error.error.message));
              break;
          }
        
        this.formErrorsMessages = error;
        this.submitted = false;
      },
    )
  }

  public getCoordinates(): void {
    const address: Address = plainToClass(Address, this.form.getRawValue()['locationAddress']);
    this.apmService.getCoordinatesForLocation(address.raw).subscribe(
      (coords: Coordinates) => {
        this.coordinates = coords;
        this.form.get('locationCoordinates').get('lat').setValue(this.coordinates.lat);
        this.form.get('locationCoordinates').get('lng').setValue(this.coordinates.lng);
      }
    );
  }

  public tooltipLabel(property: string): string {
    return tooltipLabel(this.translateService, `tooltips.d2d.labels.${property}`);
  }

  public changeCoordinates(coordinates: Coordinates): void {
    this.form.get('locationCoordinates').get('lat').setValue(coordinates.lat);
    this.form.get('locationCoordinates').get('lng').setValue(coordinates.lng);
  }

  public changedFromTime() {
    const fromTime = moment(`${moment(this.fromDate.value).format('YYYY-MM-DD')} ${this.fromTime.value}`);
    if (fromTime.isValid) {
      this.from.setValue(fromTime.toISOString());
    }
  }

  public changedToTime() {
    const toTime = moment(`${moment(this.fromDate.value).format('YYYY-MM-DD')} ${this.toTime.value}`);
    if (toTime.isValid) {
      this.to.setValue(toTime.toISOString());
    }
  }

}


// {
//   "customer": {
//     "firstName": "John",
//     "lastName": "Doe",
//     "notes": "Customer is fluent in English, Ukranian and Russian.",
//     "phone2Nr": "+485555555",
//     "phoneNr": "+485555555"
//   },
//   "customerNotes": "You can park on our driveway.",
//   "date": "2020-01-01",
//   "dispatcherNotes": "Please deliver into the kitchen instead of to the front door.",
//   "locationAdditional": {
//     "companyName": "string",
//     "contactEmail": "string",
//     "contactName": "string",
//     "contactPhoneNO": "string",
//     "domofonCode": "string",
//     "flatNO": "string",
//     "floor": 0,
//     "instructions": "string",
//     "lift": true,
//     "staircaseNO": "string"
//   },
//   "locationAddress": {
//     "country": "string",
//     "district": "string",
//     "houseNO": "string",
//     "street": "string",
//     "town": "string",
//     "voivoidship": "string",
//     "zip": "string"
//   },
//   "locationCoordinates": {
//     "lat": 0,
//     "lng": 0
//   },
//   "pickupDuration": 900,
//   "pickupWindow": {
//     "from": "2020-08-25T12:04:16.518Z",
//     "to": "2020-08-25T12:04:16.518Z"
//   },
//   "slot": "08:00-11:00",
//   "warehouse": 1
// }