import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '@services/nav.sevice';
import { CRUD_MODE } from '@enums/enum';
import * as _ from 'lodash';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import { PartnerCourier } from '../../interfaces/partner-courier.interface';
import { PartnerCourierService } from '../../services/partner-courier.service';
import { UtilsService } from '@services/utils.service';
import { tooltipLabel } from '@shared/functions/tooltip-labels.function';

@Component({
  selector: 'app-partner-courier-edit',
  templateUrl: './partner-courier-edit.component.html',
  styleUrls: ['./partner-courier-edit.component.scss']
})
export class PartnerCourierEditComponent implements OnInit {

  public partnerCourier: PartnerCourier;
  public mode: CRUD_MODE;

  public form: FormGroup;
  public loader: boolean = true;
  public submitted: boolean = false;
  public partnerId = null;
  public regionId = null;


  constructor(
    private navigationRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private partnerCourierService: PartnerCourierService,
    private toastrProviderService: ToastrProviderService,
    private translateService: TranslateService,
    private navService: NavService,
    private activatedRoute: ActivatedRoute,
    private readonly utilsService: UtilsService
  ) { }

  public ngOnInit() {
    this.partnerCourier = (this.navigationRoute.snapshot.data as {courier: any}).courier;
    this.mode = (this.partnerCourier) ? CRUD_MODE.UPDATE : CRUD_MODE.CREATE;

    this.activatedRoute.params.subscribe(params => {
      this.partnerId = this.utilsService.findUpRouteParams(this.navigationRoute.snapshot, 'partnerId');
      this.regionId = this.utilsService.findUpRouteParams(this.navigationRoute.snapshot, 'regionId');
      this.initForm();
    })


  }

  private initForm() {
    this.form = this.formBuilder.group({
      id: [this.partnerCourier ? this.partnerCourier.id : null],
      firstName: [this.partnerCourier ? this.partnerCourier.firstName : null, Validators.required],
      lastName: [this.partnerCourier ? this.partnerCourier.lastName : null, Validators.required],
      // username: [this.partnerCourier ? this.partnerCourier.username : null, Validators.required],
      documentNumber: [{value: this.partnerCourier ? this.partnerCourier.documentNumber : null, disabled: true}, Validators.required],
      
      isRegionDefault: [this.partnerCourier ? this.partnerCourier.isRegionDefault : false, Validators.required],
      active: [this.partnerCourier ? this.partnerCourier.active : null, Validators.required],
      regionDefault: [this.partnerCourier ? this.partnerCourier.regionDefault : false],
      
      contactPhone: [this.partnerCourier ? this.partnerCourier.contactPhone : null, Validators.required],
      password: [this.partnerCourier ? this.partnerCourier.password : null, [Validators.required, Validators.minLength(8)]],

      regionId: [this.regionId, Validators.required],
      version: [this.partnerCourier ? this.partnerCourier.version : null],
    });

    console.log(this.form);

    this.loader = false;
  }

  public regularFormControl(): string[]{
    return ['firstName', 'lastName'];
  }

  public selectFormControl(): string[]{
    return ['active'];
  }

  public onSubmit() {
    const raw: PartnerCourier = this.form.getRawValue() as PartnerCourier;

    console.log(raw);
    
    if (this.mode === CRUD_MODE.CREATE) {
      this.create(raw);
    } else {
      this.update(raw);
    }
  }

  private create(raw): void {
    this.submitted = true;
    this.partnerCourierService.savePartnerCourier(raw, this.regionId)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (courier: PartnerCourier) => {
          this.toastrProviderService.showSuccess(this.translateService.instant('New courier has been created!'));
          this.navService.goToPage(`/partner/${this.partnerId}/region/${this.regionId}/couriers/list`, {modifiedRow: courier.id});
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }

  private update(raw): void {
    this.submitted = true;
    this.partnerCourierService.updatePartnerCourier(raw, this.regionId)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (courier: PartnerCourier) => {
          this.toastrProviderService.showSuccess(this.translateService.instant('The courier has been updated!'));
          this.navService.goToPage(`/partner/${this.partnerId}/region/${this.regionId}/couriers/list`, {modifiedRow: courier.id});
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }

  public tooltipLabel(property: string): string {
    return tooltipLabel(this.translateService, `tooltips.partnercourier.labels.${property}`);
  }
}
