<div class="typehead-filter filter-container">
    <div class="row">
        <div class="typehead-filter__item col-md">
            <span id="basic-addon1" class="typehead-filter__loader">
                <i class="typehead-filter__icon fa fa-spinner fast-pulse" aria-hidden="true"
                    [class.typehead-filter__notvisible]="!typeaheadLoading"></i>
                <i class="typehead-filter__icon fa fa-search" aria-hidden="true"
                    [class.typehead-filter__notvisible]="typeaheadLoading"></i>
            </span>

            <input class="typehead-filter__input form-control"
                placeholder="Wyszukaj dostawę po numerze albo nazwie klienta" aria-label="Wyszukaj dostawę"
                aria-describedby="basic-addon1" [(ngModel)]="asyncSelected" [typeahead]="dataSource"
                (typeaheadLoading)="changeTypeaheadLoading($event)" (typeaheadOnSelect)="typeaheadOnSelect($event)"
                [typeaheadOptionsLimit]="7" typeaheadOptionField="customerLabel"
                [typeaheadItemTemplate]="customItemTemplate" typeaheadWaitMs="1000" [typeaheadMinLength]="3">
        </div>
    </div>
</div>

<ng-template #customItemTemplate class="items" let-model="item" let-index="index">
    <div>
        <span *ngIf="model.orderId" class="items__delivery-label">{{model.orderId}}, </span>
        <span *ngIf="!model.orderId" class="items__delivery-label">{{model.customerRef}}, </span>
        <span *ngIf="model.customerLabel" class="items__customer-label">{{model.customerLabel}}</span>
    </div>
    <div *ngIf="model.routeId" class="items__delivery-date">{{'Date' | translate}}
        : {{model.routeId.date | amDateFormat:'DD MMMM YYYY'}}, {{'Route' | translate}}
        : {{model.routeId.routeNumber}}</div>
    <div *ngIf="!model.routeId" class="items__delivery-status">{{'Status' | translate}}
        : {{model.deliveryStatus | translate}} - {{'not assigned' | translate}}</div>
    <div>{{model.addressLabel}}</div>
</ng-template>