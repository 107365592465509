import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { CrudListComponent } from '@shared/components/cruds/crud-list.component';
import { Apm } from '../../entities/apm';
import { ApmService } from '../../services/apm.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { tap } from 'rxjs/operators';
import { PageableResponse } from '@entities/pagable-response';
import { ApmMissingPartnerService } from '../../services/apm-missing-partner.service';
import { Partner } from '../../entities/partner';
import { Observable } from 'rxjs';
import { RegionService } from '../../services/region.service';

@Component({
  selector: 'app-apm-missing-partner',
  templateUrl: './apm-missing-partner.component.html',
  styleUrls: ['./apm-missing-partner.component.scss']
})

export class ApmMissingPartnerComponent extends CrudListComponent<Apm, string> implements OnInit {

  public columns = [
    { prop: 'name', name: this.translateService.instant('Name'), flexGrow: 4, sortable: true },
    { prop: 'address', name: this.translateService.instant('Adress'), flexGrow: 4, sortable: true },
    { prop: 'code', name: this.translateService.instant('Code'), flexGrow: 3, sortable: true },
    { prop: 'type', name: this.translateService.instant('Type'), flexGrow: 3, sortable: true },
    { prop: 'lcount', name: this.translateService.instant('Capacity'), flexGrow: 2, sortable: true},
    { prop: 'status', name: this.translateService.instant('Status'), flexGrow: 2, sortable: true },
    { prop: 'options', name: this.translateService.instant('Options'), flexGrow: 2, sortable: false},
  ];

  public servicePartner: Partner;
  public selectedApm: Apm;
  public selectedRegion: string;


  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  constructor(
    protected readonly apmMissingPartnerService: ApmMissingPartnerService,
    protected readonly translateService: TranslateService,
    protected readonly navigationRoute: ActivatedRoute,
    protected readonly modalService: BsModalService,
    protected readonly regionService: RegionService,
    protected readonly toastrProviderService: ToastrProviderService) {
    super(apmMissingPartnerService, translateService, navigationRoute, modalService, toastrProviderService)
  }



  public ngOnInit() {
    this.list$ = this.apmMissingPartnerService.list$.pipe(
      tap((list: PageableResponse<Apm>) => this.rows = list.content)
    );

    this.servicePartner = (this.navigationRoute.snapshot.data as {servicePartner: any}).servicePartner;
    console.log(this.servicePartner);

    this.apmMissingPartnerService.fetchAll(this.queryParams);
    this.modifiedRow = this.navigationRoute.snapshot.params.modifiedRow;

    this.rowClass = row => {
      return { 'row-modified': row.id === this.modifiedRow };
    };

    this.loader = false;
  }

  public assignApmToRegionConfirmation(apm: Apm) {
    this.selectedApm = apm;
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-md'});
  }

  public assignApmToRegion(regionId: string): void {
    this.regionService.assignApmsToRegion(this.servicePartner.id, regionId, [this.selectedApm.id]).subscribe(
      (test) => {
        this.modalRef.hide();
        this.queryParams.page = 0;
        this.apmMissingPartnerService.fetchAll(this.queryParams);
        this.toastrProviderService.showSuccess(this.translateService.instant('The Rlm has been assigned to region!'));
        this.confirmObservable = null;
      },
      error => {
        this.modalRef.hide();
        console.log(`Error occurred, please try again!`, error);
      });
  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }

}
