
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { tap } from 'rxjs/operators';

import { ApmTimes } from '../../../interfaces/apm-times.interface';
import { Observable } from 'rxjs';
import { ApmService } from '../../../services/apm.service';
import { ApmDeliveryMove } from '../../../interfaces/apm-delivery-move.interface';
import { Apm } from '../../../entities/apm';
import { LogisticTypeEnum } from '@enums/enum';
import { ApmDelivery } from '../../../entities/apm-delivery';
import { Partner } from '../../../entities/partner';
import { ApmDeliveryService } from '../../../services/apm-delivery.service';

@Component({
  selector: 'app-apms-in-route',
  templateUrl: './apms-in-route.component.html',
  styleUrls: ['./apms-in-route.component.scss']
})
export class ApmsInRouteComponent implements OnInit {

  public list$: Observable<ApmTimes[]>;
  public modifiedRow: string;
  public rowClass;
  public loader: boolean = true;
  public locale = 'pl_PL';

  public apmDelivery: ApmDelivery;
  public servicePartner: Partner;

  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  @ViewChild('confirmation', { static: true }) private confirmation: TemplateRef<any>;
  private confirmObservable: Observable<any>;
  private modalRef: BsModalRef;

  public columns = [
    { headerClass: 'code', prop: 'apm.code', name: this.translateService.instant('Code'), flexGrow: 3, sortable: true },
    { headerClass: 'apmAddress', prop: 'apmAddress', name: this.translateService.instant('Adress'), flexGrow: 4, sortable: true },
    { headerClass: 'apmLcount', prop: 'apmLcount', name: this.translateService.instant('Capacity'), flexGrow: 2, sortable: true },
    { headerClass: 'type', prop: 'apm.type', name: this.translateService.instant('Type'), flexGrow: 3, sortable: true },
    { headerClass: 'apmStatus', prop: 'apmStatus', name: this.translateService.instant('Status'), flexGrow: 2, sortable: true },
    { headerClass: 'options', prop: 'options', name: this.translateService.instant('Options'), flexGrow: 3, sortable: false},
  ];

  constructor(
    protected readonly apmService: ApmService,
    protected readonly translateService: TranslateService,
    protected readonly apmDeliveryService: ApmDeliveryService,
    protected readonly navigationRoute: ActivatedRoute,
    protected readonly modalService: BsModalService,
    protected readonly toastrProviderService: ToastrProviderService) {
  }

  public ngOnInit() {
    this.translateService.onLangChange.subscribe((lang: LangChangeEvent) => this.locale = lang.lang);

    this.servicePartner = (this.navigationRoute.snapshot.data as {servicePartner: any}).servicePartner;
    this.apmDelivery = (this.navigationRoute.snapshot.data as {apmDelivery: any}).apmDelivery;

    const params = this.navigationRoute.snapshot.params;
    this.list$ = this.apmService.getApmsInDeliveryRoute(params.id).pipe(
      tap((list: ApmTimes[]) => console.log(list))
    );

  }

  public moveDeliveryConfirmation(apm: Apm, timeslotId: string, date: string): void {

    const body: ApmDeliveryMove = {
      apmDeliveryId: this.apmDelivery.id,
      apmId: apm.id,
      logisticType: LogisticTypeEnum.INPOST,
      partnerId: this.servicePartner.id,
      reservationDate: '2020-02-02',
      timeslotId: timeslotId
    }

    this.modalRef = this.modalService.show(this.confirmation, { class: 'modal-md' });
    this.confirmObservable = this.apmDeliveryService.rescheduleDelivery(this.apmDelivery.id, body)
  }

  public confirm(): void {
    this.confirmObservable.subscribe(
      () => {
        this.modalRef.hide();
        this.confirmObservable = null;
        this.toastrProviderService.showSuccess(this.translateService.instant('The delivery has been moved'));
      },
      () => {
        this.modalRef.hide();
        this.confirmObservable = null;
      });
  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }

}
  
