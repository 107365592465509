import { Component, OnInit } from '@angular/core';
import { Timeslot } from '../../interfaces/timeslot.interface';
import { CRUD_MODE } from '@enums/enum';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TimeslotService } from '../../services/timeslot.service';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '@services/nav.sevice';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { UtilsService } from '@services/utils.service';
import { tooltipLabel } from '@shared/functions/tooltip-labels.function';

@Component({
  selector: 'app-create-timeslot',
  templateUrl: './create-timeslot.component.html',
  styleUrls: ['./create-timeslot.component.scss']
})
export class CreateTimeslotComponent implements OnInit {

  public timeslot: Timeslot;
  public mode: CRUD_MODE;
  public formErrorsMessages;
  public form: FormGroup;
  public loader: boolean = true;
  public submitted: boolean = false;

  public partnerId: string;
  public regionId: string;
  private pickupSlotControl: AbstractControl;

  constructor(
    private navigationRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private timeslotService: TimeslotService,
    private toastrProviderService: ToastrProviderService,
    private translateService: TranslateService,
    private navService: NavService,
    private readonly utilsService: UtilsService
  ) { }

  public ngOnInit() {
    this.timeslot = this.navigationRoute.snapshot.data.timeslot;
    this.mode = (this.timeslot) ? CRUD_MODE.UPDATE : CRUD_MODE.CREATE;

    this.navigationRoute.params
      .subscribe(params => {
   
        this.partnerId = this.utilsService.findUpRouteParams(this.navigationRoute.snapshot, 'partnerId');
        this.regionId = this.utilsService.findUpRouteParams(this.navigationRoute.snapshot, 'regionId');

        this.initForm();
      });
  }

  private initForm() {

    this.form = this.formBuilder.group({
      id: [this.timeslot ? this.timeslot.id : null],
      version: [this.timeslot ? this.timeslot.version : null],
      code: [this.timeslot ? this.timeslot.code : null, [Validators.required, Validators.maxLength(32)]],
      name: [this.timeslot ? this.timeslot.name : null, [Validators.required, Validators.maxLength(255)]],
      daysOffset: [this.timeslot ? this.timeslot.daysOffset : 0, [Validators.required, Validators.max(7)]],
      active: [this.timeslot ? this.timeslot.active : true, [Validators.required]],

      partnerId: [this.timeslot ? this.timeslot.partnerId : this.partnerId, Validators.required],
      regionId: [this.timeslot ? this.timeslot.regionId : this.regionId],

      doWFilter: this.formBuilder.group({
        mon: [this.timeslot ? this.timeslot.doWFilter.mon : false, Validators.required],
        tue: [this.timeslot ? this.timeslot.doWFilter.tue : false, Validators.required],
        wed: [this.timeslot ? this.timeslot.doWFilter.wed : false, Validators.required],
        thu: [this.timeslot ? this.timeslot.doWFilter.thu : false, Validators.required],
        fri: [this.timeslot ? this.timeslot.doWFilter.fri : false, Validators.required],
        sat: [this.timeslot ? this.timeslot.doWFilter.sat : false, Validators.required],
        sun: [this.timeslot ? this.timeslot.doWFilter.sun : false, Validators.required],
      }),

      pickupOffset: [this.timeslot ? this.timeslot.pickupOffset : 0],
      pickupSlot: this.formBuilder.group({
        fromTime: [this.timeslot && this.timeslot.pickupSlot !== null ? this.timeslot.pickupSlot.fromTime.slice(0, 5) : '', [Validators.minLength(5), Validators.maxLength(5), this.emailConditionallyRequiredValidator]],
        toTime: [this.timeslot && this.timeslot.pickupSlot !== null ? this.timeslot.pickupSlot.toTime.slice(0, 5)  : '', [Validators.minLength(5), Validators.maxLength(5), this.emailConditionallyRequiredValidator]]
      }),

      timeslot: this.formBuilder.group({
        fromTime: [this.timeslot ? this.timeslot.timeslot.fromTime.slice(0, 5) : '', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
        toTime: [this.timeslot ? this.timeslot.timeslot.toTime.slice(0, 5)  : '', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]]
      })
    });

    this.pickupSlotControl = this.form.controls[ 'pickupSlot' ];
  
    this.loader = false;
  }

  private emailConditionallyRequiredValidator(formControl: AbstractControl) {
    if (!formControl.value) {
      return null;
    } else {
      return Validators.required(formControl); 
    }
  }

  public regularFormItems(): string[] {
    return ['code', 'name']
  }

  public weekDaysControls(): string[] {
    return ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
  }

  public onSubmit() {

    const raw = this.form.getRawValue();
    raw['code'] = raw['code'].trim();
    if (!this.pickupSlotControl.get('fromTime').value){
      raw['pickupSlot'] = null;
    }

    if (this.mode === CRUD_MODE.CREATE) {
      this.create(raw);
    } else {
      this.update(raw);
    }
  }

  private create(raw): void {
 
    this.submitted = true;
    this.timeslotService.save(raw)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (timeslot) => {
          this.toastrProviderService.showSuccess(this.translateService.instant('New timeslot has been created!'));
          
          (this.regionId !== null) 
            ? this.navService.goToPage(`/partner/${this.partnerId}/region/${this.regionId}/timeslots/list`, {modifiedRow: timeslot.id})
            : this.navService.goToPage(`/partner/${this.partnerId}/timeslots/list`, {modifiedRow: timeslot.id})

          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.formErrorsMessages = error;
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }

  private update(raw): void {
    this.submitted = true;
    this.timeslotService.update(raw['id'], raw)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (timeslot) => {
          this.toastrProviderService.showSuccess(this.translateService.instant('The timeslot has been updated!'));
          
          (this.regionId !== null) 
            ? this.navService.goToPage(`/partner/${this.partnerId}/region/${this.regionId}/timeslots/list`, {modifiedRow: timeslot.id})
            : this.navService.goToPage(`/partner/${this.partnerId}/timeslots/list`, {modifiedRow: timeslot.id})

          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.formErrorsMessages = error;
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }

  public tooltipLabel(property: string): string {
    return tooltipLabel(this.translateService, `tooltips.timeslot.labels.${property}`);
  }

}
