export let AngularMultiSelectOptions: any = {
    singleSelection: false,
    text: 'Zaznacz',
    selectAllText: 'Zaznacz wszystkie',
    unSelectAllText: 'Odznacz wszystkie',
    enableSearchFilter: true,
    classes: 'myclass custom-class',
    badgeShowLimit: 3,
    itemsShowLimit: 5,
    noDataAvailablePlaceholderText: 'Brak dostępnych danych',
};
