import { Component, OnInit } from '@angular/core';
import { Subscription } from '../../entities/subscription';
import { CRUD_MODE, SubscriptionsType } from '@enums/enum';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '@services/nav.sevice';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { SubscriptionService } from '../../services/subscription.service';
import { Apm } from '../../entities/apm';
import { Partner } from '../../entities/partner';
import { plainToClass } from 'class-transformer';
import { datePickerOptions } from '@shared/constants/datepicker-options.constants';
import * as _ from 'lodash';
import { tooltipLabel } from '@shared/functions/tooltip-labels.function';

@Component({
  selector: 'app-edit-subscription',
  templateUrl: './edit-subscription.component.html',
  styleUrls: ['./edit-subscription.component.scss']
})
export class EditSubscriptionComponent implements OnInit {

  public subscription: Subscription;
  public mode: CRUD_MODE;

  public apms: Apm[];
  public partners: Partner[];

  public form: FormGroup;
  public loader: boolean = true;
  public submitted: boolean = false;

  public formErrorsMessages;

  get options() {
    return datePickerOptions;
  }

  get subscriptionsType() {
     return _.keys(SubscriptionsType);
  }

  constructor(
    private navigationRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private subscriptionService: SubscriptionService,
    private toastrProviderService: ToastrProviderService,
    private translateService: TranslateService,
    private navService: NavService
  ) { }

  public ngOnInit() {
    this.subscription = (this.navigationRoute.snapshot.data as {subscription: any}).subscription;
    this.apms = plainToClass(Apm, (this.navigationRoute.snapshot.data as {apms: any}).apms.content as Apm[]);
    this.partners = plainToClass(Partner, (this.navigationRoute.snapshot.data as {partners: any}).partners.content as Partner[]);

    this.mode = (this.subscription) ? CRUD_MODE.UPDATE : CRUD_MODE.CREATE;
    this.initForm();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      apmId: [this.subscription ? this.subscription.apmId : null, Validators.required],
      partnerId: [this.subscription ? this.subscription.partnerId : null, Validators.required],

      timeWindow: this.formBuilder.group({
        from: [this.subscription ? this.subscription.timeWindow.from : new Date(), Validators.required],
        to: [this.subscription ? this.subscription.timeWindow.to : new Date(), Validators.required],
      }),

      lcount: [this.subscription ? this.subscription.lcount : 0, [Validators.required, Validators.min(0), Validators.maxLength(255)]],
      mcount: [this.subscription ? this.subscription.mcount : 0, [Validators.required, Validators.min(0), Validators.maxLength(255)]],
      ncount: [this.subscription ? this.subscription.ncount : 0, [Validators.required, Validators.min(0), Validators.maxLength(255)]],
      ocount: [this.subscription ? this.subscription.ocount : 0, [Validators.required, Validators.min(0), Validators.maxLength(255)]],

      
      code: [this.subscription ? this.subscription.code : null, [Validators.maxLength(32), Validators.required]],
      name: [this.subscription ? this.subscription.name : null, [Validators.maxLength(255), Validators.required]],
          
      id: [this.subscription ? this.subscription.id : null],
      version: [this.subscription ? this.subscription.version : null]
    });

    this.loader = false;
  }

  public regularFormItems(): string[] {
    return ['name', 'code'];
  }

  public numberTypeFormItems(): string[] {
    return ['lcount', 'mcount', 'ncount'];
  }

  public onSubmit() {

    const raw = this.form.getRawValue();
    raw['code'] = raw['code'].trim();

    if (this.mode === CRUD_MODE.CREATE) {
      delete raw['id'];
      this.create(raw);
    } else {
      this.update(raw);
    }
  }

  private create(raw): void {
    this.submitted = true;
    this.subscriptionService.save(raw)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (subscription: Subscription) => {
          console.log(subscription);
          this.toastrProviderService.showSuccess(this.translateService.instant('New subscription has been created!'));
          this.navService.goToPage('/subscription/list', {modifiedRow: subscription.id});
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.formErrorsMessages = error;
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }

  private update(raw): void {
    this.submitted = true;
    this.subscriptionService.update(raw['id'], raw)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (subscription: Subscription) => {
          console.log(subscription);
          this.toastrProviderService.showSuccess(this.translateService.instant('The subscription has been updated!'));
          this.navService.goToPage('/subscription/list', {modifiedRow: subscription.id});
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.formErrorsMessages = error;
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }

  public tooltipLabel(property: string): string {
    return tooltipLabel(this.translateService, `tooltips.subscription.labels.${property}`);
  }


}
