import { Component, OnInit } from '@angular/core';
import { DepotVirtual } from '@interfaces/depot-virtual.interface';
import { CrudEditComponent } from '@shared/components/cruds/crud-edit.component';
import { VehicleTypes } from '@hardware/interfaces/vehicle-types.interface';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl } from '@angular/forms';
import { DepotVirtualService } from '../../services/depot-virtual.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { CRUD_MODE, mapOptions, mapTypes, FilterStrategyType } from '@enums/enum';
import { Depot } from '@interfaces/depot.interface';
import * as ICoords from '@interfaces/coordinates.interface';
import { tooltipLabel } from '@shared/functions/tooltip-labels.function';
import { Coordinates } from '@entities/coordinates';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-depot-virtual-edit',
  templateUrl: './depot-virtual-edit.component.html',
  styleUrls: ['./depot-virtual-edit.component.scss']
})
export class DepotVirtualEditComponent extends CrudEditComponent<DepotVirtual, string> implements OnInit {

  private entity: Depot;
  public form: FormGroup;
  private virtualDepotId;
  public virtualDepot: DepotVirtual;

  public locationAdditional: AbstractControl;
  public coordinates: AbstractControl;
  public testLocationsControl: FormArray;
  public allowedZipCodesControl: FormArray;
  public disallowedZipCodesControl: FormArray;

  public coordinatesRaw: ICoords.Coordinates = {lat: 52.22, lng: 21.016};

  get coordinatesKeys() {
    const cords = new Coordinates();
    return Object.keys(cords);
  }

  public mapOptions = {
    polyline: false,
    type: 'SIMPLE'
  };

  public mapProperties = {
    zoom: 18,
    mapTypeId: mapTypes.ROADMAP
  };

  public regularControls = ['code', 'name']
  public numberControls = ['drivingTime', 'latFrom', 'latTo', 'lngFrom', 'lngTo'];
  public deliveryTimeAdjustmentControls = ['initialTime', 'boxGroupTime', 'boxGroupSize', 'paymentTime'];

  public formErrorsMessages
  public filterStrategyType: string[] = Object.values(FilterStrategyType);

  constructor(
    protected readonly navigationRoute: ActivatedRoute,
    protected readonly formBuilder: FormBuilder,
    protected readonly depotVirtualService: DepotVirtualService,
    protected readonly translateService: TranslateService,
    protected readonly toastrProviderService: ToastrProviderService,
    protected readonly router: Router
  ) { 
    super(depotVirtualService, translateService, toastrProviderService, router, navigationRoute)
  }

  public ngOnInit() {
    this.entity = (this.navigationRoute.snapshot.data as {depot: any}).depot;
    this.virtualDepotId = this.navigationRoute.snapshot.params.virtualDepotId;
    this.mode = (this.virtualDepotId) ? CRUD_MODE.UPDATE : CRUD_MODE.CREATE;
    this.virtualDepot = (this.virtualDepotId) ? this.entity.virtualDepots.find(v => v.id.toString() === this.virtualDepotId) : undefined;

    this.initForm();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      id: [null],
      depotId: [null, [Validators.required]],
      code: [null, [Validators.required, Validators.maxLength(32)]],
      name: [null, [Validators.required]],
      allowedZipCodes: this.formBuilder.array([]),
      disallowedZipCodes: this.formBuilder.array([]),
      coordinates: this.formBuilder.group({
        lat: [null, Validators.required],
        lng: [null, Validators.required]
      }),
      testLocations: this.formBuilder.array([]),
      drivingTime: [null, [Validators.required]],
      latFrom: [null, [Validators.required]],
      latTo: [null, [Validators.required]],
      lngFrom: [null, [Validators.required]],
      lngTo: [null, [Validators.required]],
      active: [true, [Validators.required]],
      filterStrategy: [null, [Validators.required]],
      deliveryTimeAdjustment: this.formBuilder.group({
        parkingTime: [0, [Validators.required]],
        parkingTimeThreshold: [0, [Validators.required]],
        initialTime: [0, [Validators.required]],
        boxGroupTime: [0, [Validators.required]],
        boxGroupSize: [0, [Validators.required]],
        paymentTime:[0, [Validators.required]],
      }),
    });

    this.coordinates = this.form.controls[ 'coordinates' ];
    this.testLocationsControl = <FormArray>this.form.controls['testLocations'];
    this.allowedZipCodesControl = <FormArray>this.form.controls['allowedZipCodes'];
    this.disallowedZipCodesControl = <FormArray>this.form.controls['disallowedZipCodes'];
    this.form.get('depotId').setValue(this.entity.id);

    if (this.mode === CRUD_MODE.UPDATE && this.virtualDepot.testLocations && this.virtualDepot.testLocations.length) {
      this.virtualDepot.testLocations.forEach((item) => this.testLocationsControl.push(this.patchValues(item)));
    }

    if (this.mode === CRUD_MODE.UPDATE) {
      this.form.patchValue(this.virtualDepot);
      this.virtualDepot.allowedZipCodes.forEach((code) => this.allowedZipCodesControl.push(this.patchValuesZipCodes(code)));
      this.virtualDepot.disallowedZipCodes.forEach((code) => this.disallowedZipCodesControl.push(this.patchValuesZipCodes(code)));
    }

    if (this.virtualDepot && this.virtualDepot.coordinates) {
      this.coordinatesRaw = this.virtualDepot.coordinates;
      this.coordinates.patchValue((this.virtualDepot.coordinates !== null) ? this.virtualDepot.coordinates : {});
    } else {
      this.coordinatesRaw = this.entity.locationCoordinates;
      this.coordinates.patchValue(this.entity.locationCoordinates);
    }

    this.loader = false;
  }

  private patchValues(item?: (ICoords.Coordinates)): AbstractControl {
    return this.formBuilder.group({
      lat: [item ? item.lat : 52 ],
      lng: [item ? item.lng : 21 ],
    })
  }

  private patchValuesZipCodes(item?: string): AbstractControl {
    return this.formBuilder.control((item) ? item : '', Validators.required);
  }

  public removeAllowedZipCodes(index: number) {
    this.allowedZipCodesControl.removeAt(index);
  }

  public removeDisallowedZipCodes(index: number) {
    this.disallowedZipCodesControl.removeAt(index);
  }

  public addAllowedZipCodes() {
    this.allowedZipCodesControl.push(this.patchValuesZipCodes());
  }

  public addDisallowedZipCodes() {
    this.disallowedZipCodesControl.push(this.patchValuesZipCodes());
  }

  public placeMarker(event) {
    this.testLocationsControl.push(this.patchValues({lat: event.coords.lat, lng: event.coords.lng}));
  }

  public removeMarker(marker, index) {
    this.testLocationsControl.removeAt(index);
  }

  public changeCoordinates(coordinates: Coordinates): void {
    this.coordinates.patchValue(coordinates);
  }

  public tooltipLabel(property: string): string {
    return tooltipLabel(this.translateService, `tooltips.depot.labels.${property}`);
  }

  public onSubmit() {
    const formData: Depot = this.form.getRawValue();
    formData['code'] = formData['code'].trim();

    if (this.mode === CRUD_MODE.CREATE) {
      this.create(formData);
    } else {
      this.update(formData);
    }
  }

  public create(raw): void {
    this.depotVirtualService.save(raw)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (depotVirtual: DepotVirtual) => {
          this.toastrProviderService.showSuccess(this.translateService.instant('New depot has been created!'));
          this.router.navigate(['../list'], {relativeTo: this.navigationRoute, queryParams: { modifiedRow: this.virtualDepotId} });
          this.submitted = false;
        },
        error => {
          this.formErrorsMessages = error;
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }

  public update(raw): void {

    this.depotVirtualService.update(raw['id'], raw)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (depotVirtual: DepotVirtual) => {
          this.toastrProviderService.showSuccess(this.translateService.instant('The depot has been updated!'));
          this.router.navigate(['../../list'], { relativeTo: this.navigationRoute, queryParams: { modifiedRow: this.virtualDepotId } });
          this.submitted = false;
        },
        error => {
          this.formErrorsMessages = error;
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }
}
