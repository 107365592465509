import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ConfigKey } from '../../entities/config-key';
import { ActivatedRoute } from '@angular/router';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '@services/nav.sevice';
import { plainToClass } from 'class-transformer';
import { ConfigKeyService } from '../../services/config-key.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import scopesConstants from './constants/scopes.constants';
import { tooltipLabel } from '@shared/functions/tooltip-labels.function';
import { Permissions } from '@enums/permissions';
import { ConfigScopes } from '../../enums/config-scopes';
import { AuthenticationService } from 'src/app/authentication.service';

@Component({
  selector: 'app-config-key',
  templateUrl: './config-key.component.html',
  styleUrls: ['./config-key.component.scss']
})
export class ConfigKeyComponent implements OnInit {

  public configKeys: ConfigKey[];
  private configKeysCollection: ConfigKey[];

  public loader: boolean = true;
  public modifiedRow: string;
  public rowClass;

  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  public columns = [
    { prop: 'code', name: this.translateService.instant('Code'), flexGrow: 4 },
    { prop: 'scopes', name: this.translateService.instant('Scopes'), flexGrow: 2 },
    { prop: 'type', name: this.translateService.instant('Type'), flexGrow: 4 },
    { prop: 'options', name: this.translateService.instant('Options'), flexGrow: 2 },

  ];

  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  constructor(
    private configKeyService: ConfigKeyService,
    private navigationRoute: ActivatedRoute,
    private translateService: TranslateService,
    private modalService: BsModalService,
    private toastrProviderService: ToastrProviderService,
    private navService: NavService,
    public authService: AuthenticationService
  ) { }

  public ngOnInit() {
    this.configKeys = this.configKeysCollection = plainToClass(ConfigKey, (this.navigationRoute.snapshot.data as {configKeys: any}).configKeys as ConfigKey[])
      .filter((c: ConfigKey) => (!this.authService.checkPrivileges([Permissions.ROLE_SYSADMIN])) ? [ConfigScopes.Global].includes(c.scopes) : c); 
      
      //c.scopes === scopesConstants.SCOPE_GLOBAL);

    this.modifiedRow = this.navigationRoute.snapshot.params.modifiedRow;
    
    this.configKeys = _.orderBy(this.configKeys, (s: ConfigKey) => s.code);
    
    this.rowClass = row => {
      return { 'row-modified': row.id === this.modifiedRow };
    };
    
    this.loader = false;
  }

  public isOptionsColumn(status: string): boolean {
    return status === 'options';
  }

  public isColumnScope(status: string): boolean {
    return status === 'scopes';
  }

  public isColumnToTranslate(status: string): boolean {
    return status === 'type' || status === 'status' || status === 'code';
  }

  public goToDetails(id: string): void {
    this.navService.goToPage(`/config-key/edit/${id}`);
  }

  public applyFiltering(event) {
    const filterPhrase = event.target.value.toLowerCase();
    const temp = _.filter(this.configKeysCollection, (configKey: ConfigKey) => {
      const searchIn: string = configKey.code;
      return searchIn.toLowerCase().indexOf(filterPhrase) !== -1 || !filterPhrase;
    });

    this.configKeys = temp;
  }

  public fetchSubscriptions(): void {
    this.configKeyService.findAll().subscribe(
      (pApms) => {
        this.configKeys = this.configKeysCollection = plainToClass(ConfigKey, pApms as ConfigKey[]).filter((c: ConfigKey) => c.scopes === scopesConstants.SCOPE_GLOBAL);
      })
  }

  public removeConfig(id: string): void {
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.configKeyService.delete(id);
  }

  public confirm(): void {
    this.confirmObservable.subscribe((test) => {
      this.modalRef.hide();
      this.fetchSubscriptions();      
      this.toastrProviderService.showSuccess(this.translateService.instant('The config key has been removed!'));
      this.confirmObservable = null;
    });
  }

  public tooltipLabel(property: string): string {
    return tooltipLabel(this.translateService, `tooltips.configkeys.labels.${property}`);
  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }

}
