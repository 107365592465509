import { Component } from '@angular/core';
import { AppLang } from '@enums/enum';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-root',
    template: `
    <router-outlet></router-outlet>`
})
export class AppComponent {
    constructor(private translateService: TranslateService) {
        const lang = localStorage.getItem('lang') || AppLang.PL;
        this.translateService.setDefaultLang(lang);
        this.translateService.use(lang);
    }

    // public spinkit = Spinkit;
}
