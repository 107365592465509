import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Subscription } from '../../entities/subscription';
import { ORDER } from '@enums/enum';
import { PageableResponse } from '@entities/pagable-response';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { SubscriptionService } from '../../services/subscription.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { NavService } from '@services/nav.sevice';
import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { Apm } from '../../entities/apm';
import { Partner } from '../../entities/partner';
import { QueryParams } from '@interfaces/query-params.interface';
import { environment } from '@environment';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

  public list$: Observable<PageableResponse<Subscription>>;
  public subscriptions: Subscription[];
  private subscriptionsCollection: Subscription[];

  public apms: Apm[];
  public partners: Partner[];

  public loader: boolean = true;

  protected queryParams: QueryParams = Object.assign({}, environment.pagination);

  public locale = 'pl';
  public modifiedRow: string;
  public rowClass;

  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  public columns = [
    { prop: 'name', headerClass: 'name', name: this.translateService.instant('Name'), flexGrow: 2 },
    { prop: 'code', headerClass: 'code', name: this.translateService.instant('Code'), flexGrow: 2 },
    { prop: 'partnerId', headerClass: 'partnerId', name: this.translateService.instant('PartnerId'), flexGrow: 4 },
    { prop: 'apmId', headerClass: 'apmId', name: this.translateService.instant('ApmId'), flexGrow: 4 },
    { prop: 'lcount', headerClass: 'lcount', name: this.translateService.instant('Capacity'), flexGrow: 2 },
    { prop: 'from', headerClass: 'from', name: this.translateService.instant('From'), flexGrow: 2 },
    { prop: 'to', headerClass: 'to', name: this.translateService.instant('To'), flexGrow: 2 },
    { prop: 'options', headerClass: 'options', name: this.translateService.instant('Options'), flexGrow: 2 },

  ];

  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  constructor(
    private readonly subscriptionService: SubscriptionService,
    private readonly navigationRoute: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly modalService: BsModalService,
    private toastrProviderService: ToastrProviderService,
    private readonly navService: NavService
  ) { }

  public ngOnInit() {
    this.translateService.onLangChange.subscribe((lang: LangChangeEvent) => {
      this.locale = lang.lang;
    });

    this.list$ = this.subscriptionService.list$.pipe(
      tap((list: PageableResponse<Subscription>) => {
          this.subscriptions = this.subscriptionsCollection = list.content;
        })
      );
    
    this.subscriptionService.fetchAll(this.queryParams);

    this.apms = plainToClass(Apm, (this.navigationRoute.snapshot.data as {apms: any}).apms.content as Apm[]);
    this.partners = plainToClass(Partner, (this.navigationRoute.snapshot.data as {partners: any}).partners.content as Partner[]);

    this.modifiedRow = this.navigationRoute.snapshot.params.modifiedRow;

    this.rowClass = row => {
      return { 'row-modified': row.id === this.modifiedRow };
    };

    this.loader = false;
  }

  public setPage(pageInfo?) {
    this.queryParams.page = pageInfo.offset;
    this.subscriptionService.fetchAll(this.queryParams);
  }

  public onSort(sortInfo) {
    this.queryParams.sort = sortInfo.sorts[0].prop
    this.queryParams.order = (sortInfo.sorts[0].dir === 'asc') ? ORDER.ASC : ORDER.DESC;
    this.subscriptionService.fetchAll(this.queryParams);
  }

  public isOptionsColumn(status: string): boolean {
    return status === 'options';
  }

  public isColumnToTranslate(status: string): boolean {
    return status === 'type' || status === 'status';
  }

  public isColumnDate(status: string): boolean {
    return status === 'from' || status === 'to';
  }

  public isColumnApm(status: string): boolean {
    return status === 'apmId';
  }

  public isColumnCapacity(status: string): boolean {
    return status === 'lcount';
  }

  public isColumnPartner(status: string): boolean {
    return status === 'partnerId';
  }

  public goToDetails(id: string): void {
    this.navService.goToPage(`/subscription/edit/${id}`);
  }

  public applyFiltering(event) {
    const filterPhrase = event.target.value.toLowerCase();
    this.queryParams.page = 0;
    this.queryParams.filter = filterPhrase;
    this.subscriptionService.fetchAll(this.queryParams);
  }

  public removeSubscription(id: string): void {
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.subscriptionService.delete(id);
  }

  public confirm(): void {
    this.confirmObservable.subscribe((test) => {
      this.modalRef.hide();
      this.queryParams = environment.pagination;
      this.subscriptionService.fetchAll(this.queryParams);  
      this.toastrProviderService.showSuccess(this.translateService.instant('The subscription has been removed!'));
      this.confirmObservable = null;
    });
  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }

}
