import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, ReplaySubject, Subject } from 'rxjs';
import { map, tap, timeout, switchMap } from 'rxjs/operators';
import { environment } from '@environment';
import { AnalyticsService } from '@analytics/services/analytics.service';
import { plainToClass } from 'class-transformer';
import { ConfigValue } from 'projects/inpost/src/app/operations/entities/config-value';
import { Coordinates } from '@interfaces/coordinates.interface';
import { Collection } from '@interfaces/collection.interface';
import { Depot } from '@interfaces/depot.interface';
import * as _ from 'lodash';
import { UtilsService } from './utils.service';
import * as moment from 'moment';
import { UserAppSettings } from '@interfaces/user-app-settings.interface';
import { UserExt } from '@interfaces/user-ext.interface';

@Injectable({
    providedIn: 'root'
})
export class AppService implements OnDestroy {

    private static CACHE_SIZE = 1;
    private static readonly SEPARATOR: string = ".";

    private static WAREHOUSE_LOCATIONS: string = 'depot/v1/list';
    private static FEATURES_CONFIGURATION: string = 'config-value/v1/list';
    private static USER_APP_SETTINGS: string = 'user/v1/me';
    private static SAVE_USER_APP_SETTINGS: string = 'user/v1/me/adminAppSettings';

    private static LOG: string = 'mobile-app/v1/log/';

    private readonly host = environment.api.url;
    private readonly prefix = environment.api.prefix;
    private dictionarySubscription: Subscription;
    private featuresConfigSubscription: Subscription;


    private cache$: Observable<Depot[]>;

    private __warehouseDictionarySource: BehaviorSubject<Depot[]> = new BehaviorSubject<Depot[]>([]);
    public warehouseDictionary: Observable<Depot[]> = this.__warehouseDictionarySource.asObservable();

    public __selectedWarehouseSource: BehaviorSubject<string> = new BehaviorSubject<string>(localStorage.getItem('depot'));
    public selectedWarehouse: Observable<string> = this.__selectedWarehouseSource.asObservable();

    public __featuresConfigSource: ReplaySubject<ConfigValue[] | null> = new ReplaySubject<ConfigValue[]>();
    public featuresConfig: Observable<ConfigValue[]> = this.__featuresConfigSource.asObservable();

    public __userAppSettings: BehaviorSubject<UserExt | undefined> = new BehaviorSubject<UserExt | undefined>(undefined);
    public userAppSettings: Observable<UserExt> = this.__userAppSettings.asObservable();

    public __helpSource: Subject<string> = new Subject<string>();
    public help: Observable<string> = this.__helpSource.asObservable();

    public __featuresConfig: ConfigValue[] = [];

    private warehouses: Depot[] = [];

    constructor(
        private http: HttpClient, 
        private analyticsService: AnalyticsService,
        private readonly utilsService: UtilsService
    ) {}

    public ngOnDestroy() {
        if (this.dictionarySubscription) {
            this.dictionarySubscription.unsubscribe();
        }

        if (this.featuresConfigSubscription) {
            this.featuresConfigSubscription.unsubscribe();
        }
    }

    public getWarehouses(): Observable<Depot[]> {
        if (!this.cache$) {
            this.cache$ = this.loadWarehouseLocationsDictionary().pipe(
                map((response: Collection<Depot>) => response.content )
            );
        }
        return this.cache$;
    }

    public getUserAppSettings(): Observable<UserExt> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${AppService.USER_APP_SETTINGS}`, {});
        return this.http.get<UserExt>(endpoint).pipe(
            tap((user: UserExt) => this.__userAppSettings.next(user)
        ));
    }

    public saveUserAppSettings(userAppSettings: UserAppSettings): Observable<UserExt> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${AppService.SAVE_USER_APP_SETTINGS}`, {});
        return this.http.post<UserExt>(endpoint, userAppSettings).pipe(
            switchMap(() => this.getUserAppSettings() ),
            tap((user: UserExt) => this.__userAppSettings.next(user)
        ));
    }

    public clearCache() {
        this.cache$ = undefined;
        this.getWarehouses().subscribe();
    }

    public loadWarehouseLocationsDictionary(): Observable<Collection<Depot>> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${AppService.WAREHOUSE_LOCATIONS}?size=1000`, {});
        return this.http.get<Collection<Depot>>(endpoint).pipe(
            tap((response: Collection<Depot>) => {
                const depots: Depot[] = response.content;
                let warehouseSelected = parseInt(localStorage.getItem('depot'), 0);
        
                this.warehouses = depots;

                if (depots.find((depot: Depot) => depot.id === warehouseSelected) === undefined) {
                    warehouseSelected = null;
                }

                if (warehouseSelected === null) {
                    localStorage.setItem('depot', depots[0].id.toString());
                    this.__selectedWarehouseSource.next(depots[0].id.toString());
                }

                this.__warehouseDictionarySource.next(depots);
                return depots;
            })
        );
    }

    public getFeaturesConfiguration(): void {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${AppService.FEATURES_CONFIGURATION}`, {});
        
        this.featuresConfigSubscription = this.http.get(endpoint).pipe(
            map((response: any) => plainToClass(ConfigValue, response as ConfigValue[])),
            map((configValues: ConfigValue[]) => {
                this.__featuresConfigSource.next(configValues);
                this.__featuresConfig = configValues;
                return configValues;
            }))
            .subscribe((configValues: ConfigValue[]) => configValues);
    }

    public log(data, logLevel?, queryparams?) {
        const level = logLevel || 'INFO';
        let endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${AppService.LOG}${level}`, {level});

        if (queryparams) {
            endpoint += `?${queryparams}`;
        }
    
        const id = Math.random().toString(36).substr(2, 9);
        const httpOptions: any = {
          headers: new HttpHeaders({
            'X-Id': id,
            'X-Occurred-At': moment().format()
          })
        };
        httpOptions.responseType = 'text';

        return this.http.post(endpoint, data, httpOptions).pipe(timeout(9000))
      }

    public findWarehouseRef(id): string {
        id = parseInt(id, 0);
        return this.warehouses.find((d: Depot) => d.id === id).code;
    }

    public findWarehouseCoordinates(id): Coordinates {
        id = parseInt(id, 0);
        return this.warehouses.find((d: Depot) => d.id === id).locationCoordinates;
    }

    private interpolate(template: string, params: {}) {
        const names = Object.keys(params);
        const vals = Object.values(params);
        return new Function(...names, `return \`${template}\`;`)(...vals);
    }
}
