<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="cast"></i-feather> {{'Apm' | translate}}
            </h4>
        </div>

        <div toolbar>
            <button *ngIf="!createMaintenanceWindowMode" class="btn btn-sm btn-uppercase btn-white"
                (click)="createMaintenanceWindowMode = !createMaintenanceWindowMode"
                id="btn-apm-create-maintenance-window">
                <i-feather [style.height.px]="23" [style.width.px]="13" class="mg-r-10" name="settings"></i-feather> {{ 'Create maintenance window' | translate }}
            </button>
            <button *ngIf="createMaintenanceWindowMode" class="btn btn-sm btn-uppercase btn-brand-01"
                (click)="createMaintenanceWindowFn()"
                id="btn-apm-create-maintenance-window">
                <i-feather [style.height.px]="23" [style.width.px]="13" class="mg-r-10" name="settings"></i-feather> {{ 'Create maintenance window' | translate }}
            </button>

            <div class="dropdown" dropdown>
                <button id="dropdownMenuButton" class="btn btn-sm pd-x-15 btn-brand-01 btn-uppercase dropdown-toggle route-dropdown-options" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{'Select' | translate}}
                </button>
    
                <div class="dropdown-menu dropdown-menu-left div-main-actions-2" aria-labelledby="dropdownMenuButton">
                    <button class="btn btn-sm btn-uppercase btn-outline-secondary dropdown-item"
                    [routerLink]="['/apm/map']"
                    id="btn-apm-map">
                    <i-feather [style.height.px]="23" [style.width.px]="13" class="mg-r-10" name="map"></i-feather> {{ 'Apms map' | translate }}
                </button>
                <button appCheckFeaturesConfig [featureName]="[Permissions.Apm_maintenance_edit]" 
                    id="btn-maintenance-list"    
                    class="btn btn-sm btn-uppercase btn-outline-secondary dropdown-item"
                    [routerLink]="['/apm/maintenance/windows/list']">
                    <i-feather [style.height.px]="23" [style.width.px]="13" class="mg-r-10" name="list"></i-feather> {{ 'Apm maintenance list' | translate }}
                </button>
                <button appCheckFeaturesConfig [featureName]="[Permissions.Apm_maintenance_edit]" 
                    id="btn-apm-maintenance-list"    
                    class="btn btn-sm btn-uppercase btn-outline-secondary dropdown-item"
                    [routerLink]="['/apm/maintenance/list']">
                    <i-feather [style.height.px]="23" [style.width.px]="13" class="mg-r-10" name="list"></i-feather> {{ 'Apms list with maintenance' | translate }}
                </button>
                <button appCheckFeaturesConfig [featureName]="[Permissions.Partner_view]" 
                    id="btn-apm-list-no-region"    
                    class="btn btn-sm btn-uppercase btn-outline-secondary dropdown-item"
                    [routerLink]="['/apm/missing-service-partner/list']"
                    id="btn-apm-list-no-region">
                    <i-feather [style.height.px]="23" [style.width.px]="13" class="mg-r-10" name="list"></i-feather> {{ 'Apms not assigned to service region' | translate }}
                </button>
                </div>
            </div>
            <ng-container *ngIf="(servicePartnerEntity$ | async) as servicePartner">
                <select class="custom-select" [style.width.%]="15" [(ngModel)]="selectedRegion" (ngModelChange)="filterByRegion($event)" id="sel-apm-srv-reg-filter">
                    <option [ngValue]="null" selected>{{'All apms' | translate}}</option>
                    <option *ngFor="let p of servicePartner.regionList" [value]="p.id">{{p.name}} - {{p.code}}</option>
                </select>
            </ng-container>
            <div class="filter-container" >
                <div class="phrase-filter">
                    <input type='text' class="list-filter form-control" placeholder="{{'type to search' | translate}}" (keyup)='applyFiltering($event)' id="inp-apm-filter"/>
                </div>
            </div>
        </div>
        <hr />
    </div>
    
    <div content>
        <div *ngIf="(list$ | async) as list">

            <ngx-datatable 
                #myTable
                id="apms" 
                class="bootstrap" 
                [rows]="apms" 
                [columns]="columns" 
                [columnMode]="'flex'"
                [headerHeight]="50" 
                [footerHeight]="50" 
                [messages]="tableMessages" 
                [rowHeight]="'auto'" 
                [externalPaging]="true"
                [count]="list.totalElements"
                [offset]="queryParams.page"
                [rowClass]="rowClass"
                [limit]="queryParams.size"
                [scrollbarH]="scrollBarHorizontal"
                (sort)="onSort($event)"
                (page)="setPage($event)">
                <ng-container *ngFor="let col of columns">
                    <ngx-datatable-column [name]="col.prop" [flexGrow]="col.flexGrow" [prop]="col.prop" [sortable]="col.sortable">
                        <ng-template let-column="column" ngx-datatable-header-template class="header" let-sort="sortFn">
                            <b (click)="sort()">{{ col.name | translate }}</b>
                        </ng-template>
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>

                            <div [ngSwitch]="col.prop">

                                <div *ngSwitchCase="'name'">
                                    <div class="flex">
                                        <div *ngIf="createMaintenanceWindowMode" class="custom-control custom-checkbox">
                                            <input (change)="selectedApmsFn($event.target.value)" type="checkbox" class="custom-control-input" [value]="row.apm.id" [id]="row.apm.id">
                                            <label class="custom-control-label" [for]="row.apm.id"></label>
                                        </div>
                                        <div>{{row.apm.name}}</div>
                                    </div>
                                </div>
                                <div *ngSwitchCase="'address'">
                                    <span>{{row.apm.address | addressLabelPipe}}</span>
                                </div>
                                <div *ngSwitchCase="'lcount'">
                                    <span 
                                        [containerClass]="'pretty-tooltip'" 
                                        placement="right" 
                                        [tooltip]="row.apm | rlmCapacityLabelPipe"><strong>{{row.apm | rlmCapacityLabelShortPipe}}</strong></span>

                                    <span 
                                        *ngIf="row.availableVolume !== null"
                                        [containerClass]="'pretty-tooltip'" 
                                        placement="right" 
                                        [ngClass]="{'red': row | checkAvailabilityInRlmPipe}"
                                        [tooltip]="row.availableVolume | rlmCapacityLabelPipe"> - ({{row.availableVolume | rlmCapacityLabelShortPipe}})</span>
                                </div>
                                <div *ngSwitchCase="'apm.status'">
                                    <ng-container [ngSwitch]="value">
                                        <span *ngSwitchCase="'NOT_AVAILABLE'" class="badge badge-danger">{{ 'RlmStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="'AVAILABLE'" class="badge badge-success">{{ 'RlmStatus.'+value | translate }}</span>
                                        <span *ngSwitchDefault class="badge badge-info">{{ 'RlmStatus.'+value | translate }}</span>
                                    </ng-container>
                                </div>
                                <div *ngSwitchCase="'options'">
                                    <div class="dropdown" dropdown>
                                        <button
                                            class="btn btn-outline-secondary btn-xs dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton">
                                            {{ 'Select' | translate }}
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right div-main-actions">
                                            
                                            <a 
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-apm-go-details" 
                                                (click)="goToDetails(row.apm.id)">
                                                    {{'Show details' | translate }}
                                            </a>

                                            <a 
                                                appCheckFeaturesConfig [featureName]="[Permissions.Apm_maintenance_edit]"
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-apm-assign-regions" 
                                                [routerLink]="['/apm', row.apm.id, 'regions', 'list']">
                                                    {{'Assign regions' | translate }}
                                            </a>
    
                                            <a 
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-apm-sync" 
                                                (click)="synchronizeApms(row.apm.id)">
                                                    {{'Synchronize' | translate }}
                                            </a>

                                            <a 
                                                appCheckFeaturesConfig [featureName]="[Permissions.Apm_maintenance_edit]"
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-apm-maintenance" 
                                                (click)="goToMaintenanceWindows(row.apm.id)">
                                                    {{'Maintenance windows' | translate }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div *ngSwitchDefault>{{ value | translate }}</div>
                            </div>

                        </ng-template>
                    </ngx-datatable-column>
                </ng-container>
            </ngx-datatable>
        </div>

        <div *ngIf="loader" class="load-spinner text-center">
            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}
        </div>
    </div>

</layout-default>

<ng-template #confirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p>{{'Do you confirm remove the APM' | translate }}?</p>
        <button type="button" class="btn btn-brand-01" (click)="confirm()">
            {{'Yes, remove' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>

<!-- {{'RlmStatus.AVAILABLE' | translate}} -->
<!-- {{'RlmStatus.NOT_AVAILABLE' | translate}} -->