import { Component, Input } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Bag } from '@entities/bag';
import { Box } from '@entities/box';
import { BoxType, BoxTypeOptions, Client, BoxScanningType } from '@enums/enum';
import { environment } from '@environment';

@Component({
    selector: 'boxes-grid',
    template: `
        <div *ngIf="allBoxes" class="flex-area">
            <div class="column">
                <div *ngFor="let type of boxTypesOptions">
                    <div *ngIf="groupedBoxes[type]" class="boxes">
                        <label class="tx-10 d-block tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0 pd-t-10">{{ type | translate }}:</label>

                        <ng-container *ngFor="let item of groupedBoxes[type]; let i = index">

                            <div *ngIf="item.id && item.isMerged" class="box merged" [tooltip]="mergeBoxTooltipLabel(item)">
                                <span> <span *ngIf="checkFirstLetters(item.id)" class="prefix">90</span>{{ item.id | boxId }} </span>
                            </div>

                            <div
                                *ngIf="item.id && (item.isLoaded || item.isScanned) && !item.isMerged"
                                [ngClass]="{ loaded: item.isLoaded && !item.isScanned, scanned: item.isScanned }"
                                class="box {{item.status}}"
                                [tooltip]="tooltipLabel(item)"
                                [routerLink]="['/deliveries/auditlog', item.id, 'BOXBAG']"
                            >
                                <span> <span *ngIf="checkFirstLetters(item.id)" class="prefix">90</span>{{ item.id | boxId }} </span>
                            </div>

                            <div *ngIf="item.id && !item.isLoaded && !item.isScanned && !item.isMerged" class="box {{item.status}}" [tooltip]="tooltipLabel(item)">
                                <span> <span *ngIf="checkFirstLetters(item.id)" class="prefix">90</span>{{ item.id | boxId }} </span>
                            </div>

                            <ng-container *ngIf="(client === appClient.FRISCO || (client === appClient.INPOST && item.type === boxType.FROZEN))">
                                <ng-container *ngIf="item.bags.length && !isRouteParent">
                                    <div *ngFor="let bag of item.bags" [ngClass]="{ loaded: bag.loadedAt !== null && !bag.isScanned, scanned: bag.isScanned }" class="bag scanned" [tooltip]="bagTooltipLabel(bag)">
                                        <span>{{ bag | formatBagNumber }}</span>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>


       <!-- {{'BoxStatus.NEW' | translate }}
        {{'BoxStatus.FAKE' | translate }}
        {{'BoxStatus.LOADED' | translate }}
        {{'BoxStatus.UNLOADED' | translate }}
        {{'BoxStatus.REGISTERED' | translate }}
        {{'BoxStatus.READY_FOR_PICKUP' | translate }}
        {{'BoxStatus.PICKED_UP' | translate }}
        {{'BoxStatus.COMPLETE' | translate }}
        {{'BoxStatus.INCOMPLETE' | translate }}
        {{'BoxStatus.MERGED' | translate }} -->
    `,
    styleUrls: ['./boxes-grid.component.scss']
})
export class BoxesGridComponent {
    @Input() public allBoxes: Box[];
    @Input() public parent: string;

    public client = environment.client;
    public appClient = Client;
    public boxType = BoxType;
    public boxScanningStatus = BoxScanningType;

    get boxTypesOptions(): Array<BoxType> {
        return BoxTypeOptions;
    }

    get groupedBoxes() {
        return _.groupBy(this.allBoxes, (box: Box) => box.type);
    }

    get isRouteParent(): boolean {
        return this.parent === 'route';
    }

    constructor(private translateService: TranslateService) {}

    public tooltipLabel(box: Box): string {
        const id = box.id ? `${this.translateService.instant('Id')}: ${box.id}` : '';
        const status = box.status ? `${this.translateService.instant('Status')}: ${this.translateService.instant('BoxStatus.' + box.status)}` : '';
        const readyToPickupAt = box.readyForPickupAt ? `${this.translateService.instant('Ready for pickup at')}: ${moment(box.readyForPickupAt).format('HH:mm:ss')}` : '';
        const pickedupAt = box.pickupScanAt ? `${this.translateService.instant('Picked up at')}: ${moment(box.pickupScanAt).format('HH:mm:ss')}` : '';
        const loadedAt = box.isLoaded ? `${this.translateService.instant('Loaded at')}: ${moment(box.loadedAt).format('HH:mm:ss')}` : `${this.translateService.instant('Not loaded yet')}`;
        const scanedAt = box.isScanned ? `${this.translateService.instant('Scanned at')}: ${moment(box.deliveryScanAt).format('HH:mm:ss')} ` : '';

        return id + '\n' + status + '\n' + readyToPickupAt + '\n' + pickedupAt + '\n' + loadedAt + '\n' + scanedAt;
    }

    public bagTooltipLabel(bag: Bag): string {
        let label = `Ref: ${bag.ref} \n`;
        label += bag.loadedAt ? `${this.translateService.instant('Loaded at')}: ${moment(bag.loadedAt).format('HH:mm:ss')}\n` : `${this.translateService.instant('Not loaded yet')}\n`;
        label += bag.isScanned ? `${this.translateService.instant('Scanned at')}: ${moment(bag.deliveryScanAt).format('HH:mm:ss')} ` : `${this.translateService.instant('Not scanned yet')}`;
        return label;
    }

    public mergeBoxTooltipLabel(box: Box): string {
        return `${this.translateService.instant('Merged to box')}: ${box.mergedTo}
${this.translateService.instant('at')}: ${moment(box.modifiedAt).format('HH:mm:ss')}`;
    }

    public checkFirstLetters(boxId): boolean {
        return boxId.toString().substring(0, 2) === '90';
    }
}
