<layout-default>
  <div header>
    <div toolbar>
      <h4 *ngIf="mode === 'CREATE'">
        <i-feather name="sliders"></i-feather> {{'Create cron job' | translate}}
      </h4>
      <h4 *ngIf="mode === 'UPDATE'">
        <i-feather name="sliders"></i-feather> {{'Edit cron job' | translate}}
      </h4>
    </div>

    <hr />
  </div>

  <div content>
    <div *ngIf="(availableParams$ | async) as availableParams" class="content bg-white">

      <div *ngIf="!laoder" class="card-content col-8 offset-2">

        <form [formGroup]="form">


          <div *ngFor="let item of regularFormItems" class="form-group row">
            <label class="col-2" for="code">{{item | translate}}:</label>
            <div class="col-10">
              <input [formControlName]="item" class="form-control" />
              <div *ngIf="form.get(item).hasError('required') && form.get(item).touched" class="error-color">
                {{ "The field is required" | translate }}!
              </div>
            </div>
          </div>

          <div *ngFor="let control of booleanFormmItems; let index = index" class="form-group row">
            <label class="col-2" for="active">
              <span>{{ 'jobClass' | translate }}:</span>
              <sup *ngIf="control | tooltipLabelPipe:'cronJobScheulerEdit' as tooltip"
                [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                &nbsp;<i-feather [style.width.px]="10" name="info">{{ 'cronJobScheulerEdit.jobClass' | translate }}:
                </i-feather>
              </sup>
            </label>
            <div class="col-10">
              <select class="custom-select" id="jobClass" formControlName="jobClass">
                <option *ngFor="let option of jobClassOptions" [ngValue]="option">{{ option | translate }}</option>
              </select>
            </div>
          </div>

          <div *ngIf="form.get('cronJob').value" class="form-group row">
            <label class="col-2" for="active">{{'Cron expression' | translate}}:</label>
            <div class="col-10">
              <cron-jobs [(ngModel)]="cronExpression" [ngModelOptions]="{standalone: true}" class="mg-b-10"
                [config]="cronConfig"></cron-jobs>
              <input [disabled]="true" [value]="cronExpression" class="form-control" />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-2" for="active">{{'Repeat time' | translate}}:</label>
            <div class="col-10">
              <input type="number" formControlName="repeatTime" class="form-control" />
              <div *ngIf="form.get('repeatTime').hasError('required') && form.get('repeatTime').touched"
                class="error-color">
                {{ "The field is required" | translate }}!
              </div>
            </div>
          </div>

          <ng-container *ngIf="availableValidators && availableValidators.parameters" formGroupName="jobData">
            <div *ngFor="let control of availableValidators.parameters" class="form-group row">
              <label class="col-2" for="active">
                <span>{{ control.name | translate }}:</span>
                <sup *ngIf="control.description as tooltip" [containerClass]="'pretty-tooltip'"
                  [tooltip]="tooltip">&nbsp;<i-feather [style.width.px]="10" name="info"></i-feather>
                </sup>
              </label>

              <div class="col-10">

                <ng-container [ngSwitch]="control.name">

                  <!-- TIMEPERIOD:Control -->
                  <ng-container *ngSwitchCase="'timePeriod'">
                    <select class="custom-select" [id]="control" [formControlName]="control.name">
                      <option *ngFor="let opt of timePeriodReportPaymentSummaryJob" [value]="opt">{{ 'TimePeriod.' + opt
                        | translate }}</option>
                    </select>
                  </ng-container>

                  <!-- EXPECTION_ONLY:Control -->
                  <ng-container *ngSwitchCase="'exceptionOnly'">
                    <select class="custom-select" [id]="control" [formControlName]="control.name">
                      <option [ngValue]="true">{{ 'True' | translate }}</option>
                      <option [ngValue]="false">{{ 'False' | translate }}</option>
                    </select>
                  </ng-container>

                  <!-- TYPE:Control -->
                  <ng-container *ngSwitchCase="'type'">
                    <select class="custom-select" [id]="control" [formControlName]="control.name">
                      <option *ngFor="let opt of paymentReportType" [value]="opt">{{'DeepLinkType.' + opt | translate}}
                      </option>
                    </select>
                  </ng-container>


                  <ng-container *ngSwitchDefault>
                    <input type="string" [formControlName]="control.name" class="form-control" />
                  </ng-container>

                </ng-container>

                <div *ngIf="jobDataCtrl.get(control.name).hasError('required') && jobDataCtrl.get(control.name).touched"
                  class="error-color">
                  {{ "The field is required" | translate }}!
                </div>
              </div>
            </div>
          </ng-container>

          <div *ngFor="let control of booleanFormmItems; let index = index" class="form-group row">
            <label class="col-2" for="active">
              <span>{{ 'cronJob' | translate }}:</span>
              <sup *ngIf="control | tooltipLabelPipe:'cronJobScheulerEdit' as tooltip"
                [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                &nbsp;<i-feather [style.width.px]="10" name="info">{{ 'cronJobScheulerEdit.'+control | translate }}:
                </i-feather>
              </sup>
            </label>
            <div class="col-10">
              <select class="custom-select" [id]="control" [formControlName]="control">
                <option [ngValue]="true">{{ 'True' | translate }}</option>
                <option [ngValue]="false">{{ 'False' | translate }}</option>
              </select>
            </div>
          </div>

          <div class="form-group row justify-content-end">
            <div class="col-2">
              <button (click)="onSubmit()" [disabled]="!form.valid || submitted"
                class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01 w-100">

                <span *ngIf="!submitted">{{ "Save" | translate }}</span>

                <ng-container *ngIf="submitted">
                  <div class="load-spinner text-center">
                    <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                  </div>
                </ng-container>

              </button>
            </div>
          </div>

          <div *ngIf="formErrorsMessages && formErrorsMessages.length" class="form-group row">
            <div class="col-10 offset-2">
              <p class="error-color">{{ 'Something has wrong! Please correct form' | translate }}:</p>
              <ul>
                <li *ngFor="let error of formErrorsMessages" class="error-color">
                  <span>{{ error.value | translate }}</span>
                </li>
              </ul>
            </div>
          </div>

        </form>
      </div>

      <div *ngIf="loader" class="load-spinner text-center">
        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}
      </div>

    </div>
  </div>
</layout-default>

<!-- {{'jobName' | translate}} -->
<!-- {{'description' | translate}} -->
<!-- {{'jobClass' | translate}} -->
<!-- {{'jobGroup' | translate}} -->
<!-- {{'depotIds' | translate}} -->
<!-- {{'exceptionOnly' | translate}} -->
<!-- {{'timePeriod' | translate}} -->
<!-- {{'paymentMethods' | translate}} -->
<!-- {{'TimePeriod.THIS_DAY' | translate }} -->
<!-- {{'TimePeriod.THIS_WEEK' | translate }} -->
<!-- {{'TimePeriod.THIS_MONTH' | translate }} -->
<!-- {{'TimePeriod.THIS_YEAR' | translate }} -->
<!-- {{'TimePeriod.PREVIOUS_DAY' | translate }} -->
<!-- {{'TimePeriod.PREVIOUS_WEEK' | translate }} -->
<!-- {{'TimePeriod.PREVIOUS_MONTH' | translate }} -->
<!-- {{'TimePeriod.PREVIOUS_YEAR' | translate }} -->
<!-- {{'TimePeriod.NEXT_DAY' | translate }} -->
<!-- {{'TimePeriod.NEXT_WEEK' | translate }} -->
<!-- {{'TimePeriod.NEXT_MONTH' | translate }} -->
<!-- {{'TimePeriod.NEXT_YEAR' | translate }} -->