import { Component, OnInit } from '@angular/core';
import { RolesService } from '../../services/roles.service';
import { FormBuilder, Validators, FormGroup, FormArray, AbstractControl } from '@angular/forms';
import { DynamicRoles } from '../../entities/roles';
import { ActivatedRoute } from '@angular/router';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '@services/nav.sevice';

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.scss']
})
export class EditRoleComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private rolesService: RolesService,
    private navigationRoute: ActivatedRoute,
    private toastrProviderService: ToastrProviderService,
    private translateService: TranslateService,
    private navService: NavService
  ) { }

  public form: FormGroup;
  public role: DynamicRoles;
  public permissions: string[];
  public submitted: boolean = false;
  public permissionsCtrl: FormArray;
  public mode: string;
  public disabled: boolean = false;

  public ngOnInit() {

    this.role = (this.navigationRoute.snapshot.data as {role: any} ).role;
    this.permissions = (this.navigationRoute.snapshot.data as {permissions: any}).permissions;

    this.mode = (this.role === undefined) ? 'CREATE' : 'UPDATE';

    this.form = this.formBuilder.group({
      id: [this.role ? this.role.id : null],
      name: [this.role ? this.role.name : null, Validators.required],
      permissions: this.formBuilder.array([]),
      modifiedAt: [this.role ? this.role.modifiedAt : null],
      createdAt: [this.role ? this.role.createdAt : null],
      version: [this.role ? this.role.version : null]
    });
    
    this.permissionsCtrl = <FormArray>this.form.controls['permissions'];
    if (this.permissions) {
      this.permissions.forEach((item) => this.permissionsCtrl.push(this.patchValues(item)));
    }

    console.log(this.form);
  }

  private patchValues(name: string): AbstractControl {

    const enabled = (this.role) ? (this.role.permissions.find(i => i === name) !== undefined) ? true : false : false;
    return this.formBuilder.group({
      name: [name],
      enabled: [enabled],
    })
  }

  public onSubmit(): void {

    this.submitted = true;

    const permissionsRaw: string[] = [];
    const rawForm = this.form.getRawValue();
    this.form.getRawValue()['permissions'].forEach(o => o['enabled'] ? permissionsRaw.push(o.name) : null)
    rawForm['permissions'] = permissionsRaw;

    if (this.mode === 'CREATE') {
      this.rolesService.create(rawForm).subscribe(
        (roles: DynamicRoles) => {
          this.submitted = false;
          this.toastrProviderService.showSuccess(this.translateService.instant('New role has been created!'));
          this.navService.goToPage('/users/roles', {role: roles.id})
        }, (error) => {
          this.submitted = false;
        });
    } else {
      this.rolesService.update(rawForm).subscribe(
        (roles: DynamicRoles) => {
          this.submitted = false;
          this.toastrProviderService.showSuccess(this.translateService.instant('The role has been updated!'));
          this.navService.goToPage('/users/roles', {role: roles.id})
        }, (error) => {
          this.submitted = false;
        });
    }


  }
}
