import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { Injectable } from '@angular/core';
import { AvailableCompartments } from '../interfaces/available-compartments.interface';


@Injectable({
  providedIn: 'root'
})
export class ParcelLockersService {
    
  private static CACHE_SIZE = 1;
  protected static BASE_API: string = `${environment.api.url}${environment.api.prefix}/apm-availability/v3`;  
  protected static REBOOKING: string = `${environment.api.url}${environment.api.prefix}/apm-availability/v2`;  

  private cache$: Observable<any[]>;

  constructor(
    private readonly http: HttpClient
  ) {
  }

//   getParcelLockers(regionId: string): Observable<ParcelLocker[]> {
//     this.cache$ = this.requestParcelLockers(regionId).pipe(
//       shareReplay(ParcelLockersService.CACHE_SIZE)
//     );
//     return this.cache$;
//   }

  public getAvailableCompartments(apmId: string, partnerId: string, startDate: string, endDate: string, isRebook: boolean = false, lockers?: {lBoxes: number, mBoxes: number, nBoxes: number, oBoxes: number, })
    : Observable<AvailableCompartments[]> {
    
    const body = {
      "fri": true,
      "mon": true,
      "sat": true,
      "sun": true,
      "thu": true,
      "tue": true,
      "wed": true
    }

    if (!isRebook) {
      return  this.http.post<AvailableCompartments[]>(`${ParcelLockersService.BASE_API}/${partnerId}/${apmId}/pickup?from=${startDate}&to=${endDate}`, body);
    } else {
      
      return  this.http.post<AvailableCompartments[]>(`${ParcelLockersService.REBOOKING}/${partnerId}/${apmId}?from=${startDate}&to=${endDate}&overrideConstraints=true&lBoxes=${lockers.lBoxes}&mBoxes=${lockers.mBoxes}&nBoxes=${lockers.nBoxes}&oBoxes=${lockers.oBoxes}`, body);
    }

   
  }

//   getAvailableUpsize(apmDeliveryId: string): Observable<SubscriptionsAvailability[]> {
//     return this.http.get<SubscriptionsAvailability[]>(`${environment.apiUrl}/external/inpost/webpanel/reservation/v1/${apmDeliveryId}/modifyLockers`);
//   }

//   private requestParcelLockers(regionId: string): Observable<ParcelLocker[]> {
//     return this.http.get<ParcelLocker[]>(`${environment.apiUrl}/external/inpost/webpanel/apm/v1/region/${regionId}/apm`);
//   }

}
