import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Partner } from '../../entities/partner';
import { ORDER } from '@enums/enum';
import { PageableResponse } from '@entities/pagable-response';
import { PartnerService } from '../../services/partner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { NavService } from '@services/nav.sevice';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { ConfigScopes } from '../../enums/config-scopes';
import { QueryParams } from '@interfaces/query-params.interface';
import { environment } from '@environment';
import { tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { Permissions } from '@enums/permissions';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {

  public partners: Partner[];
  private partnersCollection: Partner[];
  public list$: Observable<PageableResponse<Partner>>;

  public loader: boolean = true;
  protected queryParams: QueryParams = Object.assign({}, environment.pagination);

  public pageInfo;
  public modifiedRow: string;
  public rowClass;
  public Permissions = Permissions;


  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  public columns = [
    { prop: 'name', name: this.translateService.instant('Name'), flexGrow: 3, sortable: true },
    { prop: 'code', name: this.translateService.instant('Code'), flexGrow: 2, sortable: true },
    { prop: 'senderEmail', name: this.translateService.instant('Sender email'), flexGrow: 3, sortable: true },
    { prop: 'address', name: this.translateService.instant('Address'), flexGrow: 5, sortable: false },
    { prop: 'status', name: this.translateService.instant('Status'), flexGrow: 2, sortable: true },
    { prop: 'options', name: this.translateService.instant('Options'), flexGrow: 2, sortable: false },
  ];

  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  constructor(
    private partnerService: PartnerService,
    private navigationRoute: ActivatedRoute,
    private translateService: TranslateService,
    private modalService: BsModalService,
    private toastrProviderService: ToastrProviderService,
    private navService: NavService,
    private readonly router: Router
  ) { }

  public ngOnInit() {
   
    this.list$ = this.partnerService.list$.pipe(
      map((list: PageableResponse<Partner>) => {
        list.content = _.sortBy(list.content, (p: Partner) => p.status);
        return list;
      }),
      tap((list: PageableResponse<Partner>) => {
          this.partners = this.partnersCollection = list.content;
        })
      );

    this.queryParams.sort = 'code';
    this.queryParams.order = ORDER.ASC;
    this.partnerService.fetchAll(this.queryParams);
    this.modifiedRow = this.navigationRoute.snapshot.params.modifiedRow;

    this.rowClass = row => {
      return { 'row-modified': row.id === this.modifiedRow };
    };

    this.loader = false;
  }

  public setPage(pageInfo?) {
    this.queryParams.page = pageInfo.offset;
    this.partnerService.fetchAll(this.queryParams);
  }

  public onSort(sortInfo) {
    this.queryParams.sort = sortInfo.sorts[0].prop
    this.queryParams.order = (sortInfo.sorts[0].dir === 'asc') ? ORDER.ASC : ORDER.DESC;
    this.partnerService.fetchAll(this.queryParams);
  }

  public isOptionsColumn(status: string): boolean {
    return status === 'options';
  }

  public isAddressColumn(status: string): boolean {
    return status === 'address';
  }

  public isColumnToTranslate(status: string): boolean {
    return status === 'type' || status === 'status';
  }

  public goToDetails(id: string): void {
    this.navService.goToPage(`/partner/edit/${id}`);
  }

  public applyFiltering(event) {
    const filterPhrase = event.target.value.toLowerCase();
    this.queryParams.page = 0;
    this.queryParams.filter = filterPhrase;
    this.partnerService.fetchAll(this.queryParams);
  }

  public removePartner(id: string): void {
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.partnerService.delete(id);
  }

  public confirm(): void {
    this.confirmObservable.subscribe((test) => {
      this.modalRef.hide();
      this.queryParams.page = 0;
      this.partnerService.fetchAll(this.queryParams);
      this.toastrProviderService.showSuccess(this.translateService.instant('The partner has been removed!'));
      this.confirmObservable = null;
    });
  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }

  public goToRegions(partnerId: string): void {
    this.navService.goToPage(`/partner/${partnerId}/region/list`);
  }

  public goToConfig(partnerId: string): void {
    this.router.navigate([`/partner/${partnerId}/config-value/${ConfigScopes.Partner}/list`] );
  }

  public goToApmDeliveries(partnerId: string): void {
    this.navService.goToPage(`/partner/${partnerId}/apm-delivery/list`);
  }

  public goToPartnerUsers(partnerId: string): void {
    this.navService.goToPage(`/partner/${partnerId}/users/list`);
  }

  public goToDc(partnerId: string): void {
    this.navService.goToPage(`/partner/${partnerId}/dc/list`);
  }
}
