export const ConfigValueOptions = {
    defaultLogistic: {
        Inpost: 'INPOST',
        Own: 'OWN',
        Mixed: 'MIXED'
    },
    isManifestRequired: {
        True: 'TRUE',
        False: 'FALSE'
    },
    allowedPools: {
        All: 'ALL',
        Subscription: 'SUBSCRIPTION'
    }
};
