import { Injectable } from '@angular/core';
import { CrudService } from '@services/crud.service';
import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '@services/utils.service';
import { Partner } from '../entities/partner';
import { PageableResponse } from '@entities/pagable-response';
import { PartnerUser } from '../interfaces/partneruser.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PartnerUserService extends CrudService<Partner, number>{

  protected static BASE_API: string = `${environment.api.url}${environment.api.prefix}/partneruser/v1`;

  constructor(
    protected _http: HttpClient,
    protected utilsService: UtilsService) {
    super(_http, PartnerUserService.BASE_API, utilsService);
  }

  public savePartnerUser(partnerId: string, userPartner: PartnerUser): Observable<PartnerUser> {
    const endpoint = this.utilsService.interpolate(`${PartnerUserService.BASE_API}/${partnerId}`, {});
    return this._http.post<PartnerUser>(endpoint, userPartner);
  }

  public updatePartnerUser(partnerId: string,  userPartner: PartnerUser): Observable<PartnerUser> {
    const endpoint = this.utilsService.interpolate(`${PartnerUserService.BASE_API}/${partnerId}`, {});
    return this._http.put<PartnerUser>(endpoint, userPartner);
  }

  public getAllPartnerUsers(partnerId: string): Observable<PageableResponse<PartnerUser[]>> {
    const endpoint = this.utilsService.interpolate(`${PartnerUserService.BASE_API}/list?partnerId=${partnerId}&sort=id&size=1000`, {});
    return this._http.get<PageableResponse<PartnerUser[]>>(endpoint);
  }

}
