import { Component, OnInit } from '@angular/core';
import { VehicleTypes } from '@hardware/interfaces/vehicle-types.interface';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CRUD_MODE } from '@enums/enum';
import { ActivatedRoute, Router } from '@angular/router';
import { VehicleTypesService } from '@hardware/services/vehicle-types.service';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { CrudEditComponent } from '@shared/components/cruds/crud-edit.component';

@Component({
  selector: 'app-vehicle-type-edit',
  templateUrl: './vehicle-type-edit.component.html',
  styleUrls: ['./vehicle-type-edit.component.scss']
})
export class VehicleTypeEditComponent extends CrudEditComponent<VehicleTypes, string> implements OnInit {

  private entity: VehicleTypes;
  public form: FormGroup;

  public capacityNumberControl = ['chilledBoxes', 'combinedBoxes', 'dryBoxes', 'frozenBoxes', 'weight'];

  constructor(
    protected readonly navigationRoute: ActivatedRoute,
    protected readonly formBuilder: FormBuilder,
    protected readonly vehicleTypesService: VehicleTypesService,
    protected readonly toastrProviderService: ToastrProviderService,
    protected readonly translateService: TranslateService,
    protected readonly router: Router) 
  {
    super(vehicleTypesService, translateService, toastrProviderService, router, navigationRoute)
   }

  public ngOnInit() {
    this.entity = (this.navigationRoute.snapshot.data as {vehicleType: any}).vehicleType;
    this.mode = (this.entity) ? CRUD_MODE.UPDATE : CRUD_MODE.CREATE;
    this.initForm();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      id: [null],
      allowMergeToShared: [true, [Validators.required]],
      code: [null, Validators.required],
      active: [null, [Validators.required]],
      version: [null],
      capacity: this.formBuilder.group({
        chilledBoxes: [null, [Validators.required]],
        combinedBoxes: [null, [Validators.required]],
        dryBoxes: [null, [Validators.required]],
        frozenBoxes: [null, [Validators.required]],
        weight: [null, [Validators.required]],
      }),
      softCapacity: this.formBuilder.group({
        chilledBoxes: [null, [Validators.required]],
        combinedBoxes: [null, [Validators.required]],
        dryBoxes: [null, [Validators.required]],
        frozenBoxes: [null, [Validators.required]],
        weight: [null, [Validators.required]],
      })
    });

    if (this.mode === CRUD_MODE.UPDATE) {
      this.form.patchValue(this.entity);
      const capacity = this.form.controls[ 'capacity' ];
      const softCapacity = this.form.controls[ 'softCapacity' ];

      capacity.patchValue(this.entity.capacity);
      softCapacity.patchValue(this.entity.softCapacity);

    }

    this.loader = false;
  }

  public regularFormItems(): string[] {
    return ['code']
  }

  public onSubmit() {
    const vehicleType = this.form.getRawValue() as VehicleTypes;
    vehicleType.code = vehicleType.code.trim();

    if (this.mode === CRUD_MODE.CREATE) {
      this.create(vehicleType);
    } else {
      this.update(vehicleType);
    }
  }
}
