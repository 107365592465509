import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { Subscription } from 'rxjs';

import { RouteExt } from '@entities/route-ext';
import { ReviewStatus, ReviewStatusOptions } from '@enums/enum';

import { PaymentForm } from '../entities/payment-form';
import { PaymentsService } from '../services/payments.service';

@Component({
    selector: 'payments-form',
    template: `
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <ng-container *ngIf="!submitted">
                <div class="form-group">
                    <select class="form-control form-control-xs text-center custom-select" formControlName="status" [ngClass]="form.value.status">
                        <option *ngFor="let status of reviewStatusOptions" [value]="status" class="{{ status }}">{{ status | translate }}</option>
                    </select>
                </div>

                <div *ngIf="hasDiscrepancies()" class="form-group">
                    <input currencyMask formControlName="collectedCashByDriver" placeholder="{{ 'Sum of cash collected by driver' | translate }}" class="form-control" />
                </div>

                <div *ngIf="hasDiscrepancies()" class="form-group">
                    <input currencyMask formControlName="collectedCouponsByDriver" placeholder="{{ 'Sum of coupons value collected by driver' | translate }}" class="form-control" />
                </div>

                <div *ngIf="isApprovedPayment()" class="form-group">
                    <textarea formControlName="comment" class="form-control" placeholder="{{ 'Comment' | translate }}"></textarea>
                </div>

                <div *ngIf="isApprovedPayment()" class="form-group">
                    <button [disabled]="loading" class="w-100 btn btn btn-secondary btn-sm">{{ 'Save' | translate }}</button>
                </div>
            </ng-container>

            <ng-container *ngIf="submitted">
                <div class="load-spinner text-center">
                    <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                </div>
            </ng-container>
        </form>
    `,
    styleUrls: ['./payments-form.component.scss']
})
export class PaymentFormComponent implements OnInit, OnDestroy {
    private TAG: string = '[PaymentFormComponent]';
    private paymentsServiceCloseSub: Subscription;
    public model: PaymentForm = new PaymentForm();
    public form: FormGroup;
    public submitted: boolean = false;

    @Input() public data;
    @Output() public commentChanged = new EventEmitter<PaymentForm>();

    constructor(private formBuilder: FormBuilder, private translate: TranslateService, private paymentsService: PaymentsService, private toastrProviderService: ToastrProviderService) {}

    public ngOnInit() {
        const [id, reviewStatus, cash, coupons, card, comment] = [...this.data];

        this.form = this.formBuilder.group({
            routeId: [id, Validators.required],
            status: [reviewStatus, Validators.required],
            comment: [comment],
            cash: [cash, Validators.required],
            coupons: [coupons, Validators.required],
            card: [card, Validators.required],
            collectedCashByDriver: [null],
            collectedCouponsByDriver: [null]
        });
    }

    public onSubmit(): void {
        this.submitted = true;

        if (this.form.invalid) {
            return;
        }

        const paymentFormBody = new PaymentForm().parse(this.form.value);
        console.log(`${this.TAG} onSubmit parsed form`, paymentFormBody);

        this.paymentsServiceCloseSub = this.paymentsService.close(paymentFormBody).subscribe(
            (route: RouteExt) => {
                console.log(`${this.TAG} close route - succesfully!`, route);
                this.toastrProviderService.showSuccess(this.translate.instant('Data has been updated'));
                this.form.value.comment = paymentFormBody.comment;
                this.commentChanged.emit(paymentFormBody);
                this.form.controls.status.markAsPristine();
            },
            error => {
                console.log(`${this.TAG} close route - failure!`, error);
                this.submitted = false;
            },
            () => {
                console.log(`${this.TAG} close route - completed!`);
                this.submitted = false;
            }
        );
    }

    get reviewStatusOptions() {
        return ReviewStatusOptions.filter(status => status !== ReviewStatus.CHOOSE_STATUS);
    }

    public isApprovedPayment(): boolean {
        return this.form.controls.status.dirty;
    }

    public hasDiscrepancies(): boolean {
        return this.isApprovedPayment() && (this.form.value.status === ReviewStatus.REVIEW_DISCREPANCIES || this.form.value.status === ReviewStatus.APPROVED_DISCREPANCIES);
    }

    private __translate() {
        this.translate.instant('NOT_REVIEWED');
        this.translate.instant('NO_DISCREPANCIES');
        this.translate.instant('REVIEW_DISCREPANCIES');
        this.translate.instant('APPROVED_DISCREPANCIES');
        this.translate.instant('CHOOSE_STATUS');
        this.translate.instant('DRY');
        this.translate.instant('CHILLED');
        this.translate.instant('BREAD');
        this.translate.instant('FROZEN');
        this.translate.instant('TRUE');
        this.translate.instant('FALSE');
        this.translate.instant('CASH');
        this.translate.instant('CARD');
        this.translate.instant('ACCOUNT');
        this.translate.instant('WIRETRANSFER');
        this.translate.instant('CARD_AND_CASH');
        this.translate.instant('SODEXO_AND_CASH');
        this.translate.instant('SODEXO_AND_CARD');
        this.translate.instant('SODEXO');
        this.translate.instant('DEFERRED');
        this.translate.instant('ONLINE');
        this.translate.instant('ONLINE_PENDING');
        this.translate.instant('NO_PAYMENT');
        this.translate.instant('PLN');
        this.translate.instant('EURO');
    }

    public ngOnDestroy(): void {
        if (this.paymentsServiceCloseSub) {
            this.paymentsServiceCloseSub.unsubscribe();
        }
    }
}
