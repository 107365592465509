import { NgModule } from '@angular/core';
import { ApmComponent } from './components/apm/apm.component';
import { ApmRoutingModule } from './operations.routing';
import { SharedModule } from 'src/app/shared.module';
import { ApmService } from './services/apm.service';
import { EditApmComponent } from './components/apm/edit-apm.component';
import { AmpsResolver } from './resolvers/apms.resolver';
import { AuthenticationModule } from 'src/app/authentication/authentication.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AmpResolver } from './resolvers/apm.resolver';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ApmMapComponent } from './components/apm/apm-map.component';
import { EditPartnerComponent } from './components/partner/edit-partner.component';
import { PartnerComponent } from './components/partner/partner.component';
import { PartnerService } from './services/partner.service';
import { PartnerResolver } from './resolvers/partner.resolver';
import { PartnersResolver } from './resolvers/partners.resolver';
import { RegionComponent } from './components/region/region.component';
import { EditRegionComponent } from './components/region/edit-region.component';
import { RegionService } from './services/region.service';
import { RegionResolver } from './resolvers/region.resolver';
import { RegionsResolver } from './resolvers/regions.resolver';
import { DriversResolver } from '@drivers/resolvers/drivers.resolvers';
import { AssignDriverComponent } from './components/region/assign-driver.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { EditSubscriptionComponent } from './components/subscription/edit-subscription.component';
import { SubscriptionResolver } from './resolvers/subscription.resolver';
import { SubscriptionsResolver } from './resolvers/subscriptions.resolver';
import { SubscriptionService } from './services/subscription.service';

import { FindApmPipe } from './pipes/find-apm.pipe';
import { FindPartnerPipe } from './pipes/find-partner.pipe';
import { ApmDeliveryComponent } from './components/apm-delivery/apm-delivery.component';
import { EditApmDeliveryComponent } from './components/apm-delivery/edit-apm-delivery.component';
import { ApmDeliveryService } from './services/apm-delivery.service';
import { AmpDeliveryResolver } from './resolvers/apm-delivery.resolver';
import { AmpDeliveriesResolver } from './resolvers/apm-deliveries.resolver';
import { CurrencyMaskConfig } from 'ng2-currency-mask';
import { FindSubscriptionPipe } from './pipes/find-subscription.pipe';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ConfigKeyComponent } from './components/config-key/config-key.component';
import { EditConfigKeyComponent } from './components/config-key/edit-config-key.component'
import { ConfigKeyResolver } from './resolvers/config-key.resolver';
import { ConfigKeysResolver } from './resolvers/config-keys.resolver';
import { ConfigKeyService } from './services/config-key.service';
import { ConfigValueService } from './services/config-value.service';
import { ConfigValueResolver } from './resolvers/config-value.resolver';
import { ConfigValuesResolver } from './resolvers/config-values.resolver';
import { ConfigValueComponent } from './components/config-value/config-value.component';
import { EditConfigValueComponent } from './components/config-value/edit-config-value.component';
import { AssignDriversResolver } from './resolvers/assign-drivers.resolver';
import { TranslateModule } from '@ngx-translate/core';
import { AssignApmsResolver } from './resolvers/assign-apms.resolver';
import { AssignApmComponent } from './components/region/assign-apm.component';
import { SubscriptionVolumeErrorPipe } from './pipes/subscription-volume-error.pipe';
import { TimeslotComponent } from './components/timeslot/timeslot.component';
import { TimeslotsResolver } from './resolvers/timeslots.resolver';
import { TimeslotService } from './services/timeslot.service';
import { CreateTimeslotComponent } from './components/timeslot/create-timeslot.component';
import { TimeslotResolver } from './resolvers/timeslot.resolver';
import { PartnerUserComponent } from './components/partner-user/partner-user.component';
import { PartnerUserService } from './services/partner-user.service';
import { PartnerUserResolver } from './resolvers/partner-user.resolver';
import { PartnerUsersResolver } from './resolvers/partner-users.resolver';
import { EditPartnerUserComponent } from './components/partner-user/edit-partner-user.component';
import { DcComponent } from './components/dc/dc.component';
import { DcEditComponent } from './components/dc/dc-edit.component';
import { DcService } from './services/dc.service';
import { DcsResolver } from './resolvers/dcs.resolver';
import { DcResolver } from './resolvers/dc.resolver';
import { PartnerCourierService } from './services/partner-courier.service';
import { PartnerCourierResolver } from './resolvers/partner-courier.resolver';
import { PartnerCourierComponent } from './components/partner-courier/partner-courier.component';
import { PartnerCourierEditComponent } from './components/partner-courier/partner-courier-edit.component';
import { PartnerCouriersResolver } from './resolvers/partner-couriers.resolver';
import { ApmMaintenanceComponent } from './components/apm/apm-maintenance.component';
import { ApmMaintenanceCreateComponent } from './components/apm/apm-maintenance-create.component';
import { ConfigValuesByScopeComponent } from './components/config-values-by-scope/config-values-by-scope.component';
import { ConfigDefaultValuesResolver } from './resolvers/config-default-values.resolver';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { RlmCapacityLabelPipe } from './pipes/rlm-capacity-label.pipe';
import { RlmCapacityLabelShortPipe } from './pipes/rlm-capacity-label-short.pipe';
import { PartnerReportsComponent } from './components/partner-reports/partner-reports.component';
import { ApmMissingPartnerComponent } from './components/apm-missing-partner/apm-missing-partner.component';
import { ApmMissingPartnerService } from './services/apm-missing-partner.service';
import { ApmsInRouteComponent } from './components/apm-delivery/apms-in-route/apms-in-route.component';
import { ApmOverbookingComponent } from './components/apm/apm-overbooking/apm-overbooking.component';
import { ApmDeliverySearchComponent } from './components/apm-delivery/apm-delivery-search/apm-delivery-search.component';
import { ApmDeliverySearchService } from './services/apm-delivery-search.service';
import { ApmDeliveryRescheduleComponent } from './components/apm-delivery/apm-delivery-reschedule/apm-delivery-reschedule.component';
import { ApmDeliveryConvertD2dComponent } from './components/apm-delivery/apm-delivery-convert-d2d/apm-delivery-convert-d2d.component';
import { ApmDeliveryD2DService } from './services/apm-delivery-d2d.service';
import { ApmAvailabilityComponent } from './components/apm/apm-availability/apm-availability.component';
import { ParcelLockersService } from './services/parcel-lockers.service';
import { PartnerServiceResolver } from './resolvers/partner-service.resolver';
import { ApmForPartnerService } from './services/apm-for-partner.service';
import { ReasonTranslatePipe } from './pipes/reason-translations.pipe';
import { ApmExtendedService } from './services/apm-extended.service';
import { CheckAvailabilityInRlmPipe } from 'src/app/visualiser/pipes/check-availability-rlm.pipe';
import { ApmMaintenanceResolver } from './resolvers/apm-maintenance-window.resolver';
import { CreateConfigValueComponent } from './components/config-value/create-config-value.component';
import { FindApmDeliveryByIdPipe } from './pipes/find-apm-delivery-by-id.pipe';
import { D2dTestsComponent } from './components/d2d-tests/d2d-tests.component';
import { D2dService } from './services/d2d.service';
import { ApmAssignedRegionsComponent } from './components/apm/apm-assigned-regions/apm-assigned-regions.component';
import { AssignRegionsResolver } from './resolvers/apm-assigned-regions.resolver';
import { AmpsByDepotResolver } from './resolvers/apms-by-depot.resolver';
import { ApmMaintenanceListComponent } from './components/apm/apm-maintenance-list/apm-maintenance-list.component';
import { ApmMaintananceWindowsListComponent } from './components/apm/apm-maintanance-windows-list/apm-maintanance-windows-list.component';
import { HardwareModule } from '@hardware/hardware.module';
import { OtherApmsInRegionComponent } from './components/apm-delivery/rebooking/other-apms-in-region/other-apms-in-region.component';
import { ApmDeliveryDetailsComponent } from './components/apm-delivery/apm-delivery-details/apm-delivery-details.component';
import { CheckReservationTimeBufforPipe } from '@shared/pipes/check-reservation-time-buffor.pipe';
import { CommonModule } from '@angular/common';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: "."
};

@NgModule({
  declarations: [
    ApmComponent, 
    RegionComponent, 
    EditRegionComponent, 
    AssignDriverComponent,
    EditApmComponent, 
    ApmMapComponent, 
    EditPartnerComponent, 
    PartnerComponent, 
    SubscriptionComponent, 
    EditSubscriptionComponent,
    FindApmPipe,
    FindPartnerPipe,
    FindSubscriptionPipe,
    ApmDeliveryComponent,
    EditApmDeliveryComponent,
    ConfigKeyComponent,
    EditConfigKeyComponent,
    ConfigValueComponent,
    EditConfigValueComponent,
    AssignApmComponent,
    SubscriptionVolumeErrorPipe,
    TimeslotComponent,
    CreateTimeslotComponent,
    PartnerUserComponent,
    EditPartnerUserComponent,
    DcComponent,
    DcEditComponent,
    PartnerCourierComponent,
    PartnerCourierEditComponent,
    ApmMaintenanceComponent,
    ApmMaintenanceCreateComponent,
    ConfigValuesByScopeComponent,
    RlmCapacityLabelPipe,
    RlmCapacityLabelShortPipe,
    PartnerReportsComponent,
    ApmMissingPartnerComponent,
    ApmsInRouteComponent,
    ApmOverbookingComponent,
    ApmDeliverySearchComponent,
    ApmDeliveryRescheduleComponent,
    ApmDeliveryConvertD2dComponent,
    ApmAvailabilityComponent,
    ReasonTranslatePipe,
    CheckAvailabilityInRlmPipe,
    CreateConfigValueComponent,
    FindApmDeliveryByIdPipe,
    D2dTestsComponent,
    ApmAssignedRegionsComponent,
    ApmMaintenanceListComponent,
    ApmMaintananceWindowsListComponent,
    OtherApmsInRegionComponent,
    ApmDeliveryDetailsComponent,
    CheckReservationTimeBufforPipe
  ],
  providers: [
    ApmService,
    AmpsResolver,
    AmpResolver,
    PartnerService,
    PartnerResolver,
    PartnersResolver,
    RegionService,
    RegionResolver,
    RegionsResolver,
    DriversResolver,
    SubscriptionService,
    SubscriptionResolver,
    SubscriptionsResolver,
    ApmDeliveryService,
    AmpDeliveryResolver,
    AmpDeliveriesResolver,
    ConfigKeyService,
    ConfigKeyResolver,
    ConfigKeysResolver,
    ConfigValueService,
    ConfigValueResolver,
    ConfigValuesResolver,
    AssignDriversResolver,
    AssignApmsResolver,
    TimeslotResolver,
    TimeslotsResolver,
    TimeslotService,
    PartnerUserService,
    PartnerUsersResolver,
    PartnerUserResolver,
    DcService,
    DcsResolver,
    DcResolver,
    PartnerCourierService,
    PartnerCourierResolver,
    PartnerCouriersResolver,
    ConfigDefaultValuesResolver,
    ApmMissingPartnerService,
    ApmDeliverySearchService,
    ApmDeliveryD2DService,
    ParcelLockersService,
    PartnerServiceResolver,
    ApmForPartnerService,
    ApmExtendedService,
    ApmMaintenanceResolver,
    D2dService,
    AssignRegionsResolver,
    AmpsByDepotResolver
  ],
  imports: [
    CommonModule,
    ApmRoutingModule,
    SharedModule,
    AuthenticationModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    HardwareModule,
    TooltipModule.forRoot(),
    NgxMaskModule.forRoot(),
    TranslateModule.forChild({}),
  ]
})
export class OperationsModule { }
