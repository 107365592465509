import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment';
import { Apm } from '../entities/apm';
import { Partner } from '../entities/partner';
import { Subscription } from '../entities/subscription';

@Pipe({
    name: 'findSubscriptionPipe',
    pure: true
})
export class FindSubscriptionPipe implements PipeTransform {
    public transform(id: string, subscriptions: Subscription[]): any {
        const subscription: Subscription = subscriptions.find((s: Subscription) => s.id === id);
        return `${subscription.code} - ${subscription.name}`;
    }
}
