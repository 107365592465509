import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormArray, AbstractControl, FormBuilder } from '@angular/forms';
import { Agency } from '../../interfaces/agency';
import { Multiplier } from '../../interfaces/multiplier';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '@services/nav.sevice';
import { MultiplierType } from '../../enums/multiplier-type.enum';
import * as _ from 'lodash';
import { AgencyService } from '../../services/agency.service';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { CRUD_MODE } from '@enums/enum';
import { tooltipLabel } from '@shared/functions/tooltip-labels.function';

@Component({
  selector: 'app-agency-edit',
  templateUrl: './agency-edit.component.html',
  styleUrls: ['./agency-edit.component.scss']
})
export class AgencyEditComponent implements OnInit {

  public agency: Agency;
  public multiplierForm: FormArray;
  public rawApms: any[] = [];
  public loader: boolean = true;
  public submitted: boolean = false;
  public form: FormGroup;
  public mode: CRUD_MODE;
  public multiplierTypes = _.values(MultiplierType);

  constructor(
    private readonly agencyService: AgencyService,
    private formBuilder: FormBuilder,
    private toastrProviderService: ToastrProviderService,
    private translateService: TranslateService,
    private navService: NavService,
    private readonly navigationRoute: ActivatedRoute
  ) { }

  public ngOnInit() {
    this.agency = (this.navigationRoute.snapshot.data as {agency: any}).agency as Agency;
    this.mode = (this.agency) ? CRUD_MODE.UPDATE : CRUD_MODE.CREATE;
    this.initForm();
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      id: [this.agency ? this.agency.id : null],
      name: [this.agency ? this.agency.name : null, Validators.required],
      baseDeliveryFeeGross: [this.agency ? this.agency.baseDeliveryFeeGross : null, Validators.required],
      baseDeliveryFeeNet: [this.agency ? this.agency.baseDeliveryFeeNet : null, Validators.required],
      baseMonthlySalaryGross: [this.agency ? this.agency.baseMonthlySalaryGross : null, Validators.required],
      baseMonthlySalaryNet: [this.agency ? this.agency.baseMonthlySalaryNet : null, Validators.required],
      multiplierList: this.formBuilder.array([]),
      version: [this.agency ? this.agency.version : null]
    })

    this.multiplierForm = <FormArray>this.form.controls['multiplierList'];
    if (this.agency && this.agency.multiplierList.length) {
      this.agency.multiplierList.forEach((item) => this.multiplierForm.push(this.patchValues(item)));
    } else {
      this.multiplierTypes.forEach(type => this.multiplierForm.push(this.patchValues({factor: null, rangeFrom: null, rangeTo: null, type: type} as Multiplier)));
    }

  
    this.loader = false;
  }

  private patchValues(item?: Multiplier): AbstractControl {
    return this.formBuilder.group({
      factor: [{value: item ? item.factor : null, disabled: item.factor !== null ? true : false}],
      rangeFrom: [{value: item ? item.rangeFrom : null, disabled: item.rangeFrom !== null ? true : false}],
      rangeTo: [{value: item ? item.rangeTo : null, disabled: item.rangeTo !== null ? true : false}],
      type: [{value: item ? item.type : null, disabled: false}],
    })
  }

  public addMultiplier(type: MultiplierType): void {
    this.multiplierForm.push(this.patchValues({factor: null, rangeFrom: null, rangeTo: null, type: type} as Multiplier));
  }

  public removeMultiplier(i: number): void {
    this.multiplierForm.removeAt(i);
  }
 
  public onSubmit() {
    if(this.form.invalid) {
      this.toastrProviderService.showWarning(this.translateService.instant('The form is invalid'));
      console.log(this.form);
      return;
    }

    if (this.mode === CRUD_MODE.CREATE) {
      this.create();
    } else {
      this.update();
    }
  }

  public tooltipLabel(property: string): string {
    return tooltipLabel(this.translateService, `tooltips.agency.labels.${property}`);
  }

  private create() {
    const form = this.form.getRawValue() as Agency;
    form['multiplierList'] = form.multiplierList.filter((multiplier: Multiplier) => multiplier.factor && multiplier.rangeFrom && multiplier.rangeTo && multiplier.type);
    const body: string[] = [];

    this.agencyService.createAgency(form)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (agency: Agency) => {
          this.toastrProviderService.showSuccess(this.translateService.instant('Agency has been created!'));
          this.navService.goToPage(`/salary/agency/list`, {modifiedRow: agency.id});
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }

  public update(){
    const form = this.form.getRawValue() as Agency;
    form['multiplierList'] = form.multiplierList.filter((multiplier: Multiplier) => multiplier.factor && multiplier.rangeFrom && multiplier.rangeTo && multiplier.type);
    const body: string[] = [];

    this.agencyService.updateAgency(form)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (agency: Agency) => {
          this.toastrProviderService.showSuccess(this.translateService.instant('Agency has been updated!'));
          this.navService.goToPage(`/salary/agency/list`, {modifiedRow: agency.id});
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  
  }

}
