import { Type } from 'class-transformer/decorators';
import { serializeType } from '@shared/functions/serializeType.function';
import { Additional } from '@entities/additional';
import { Address } from '@entities/address';

export class Region {
    @Type(serializeType(Address))
    public address:            Address;
    
    @Type(serializeType(Additional))
    public locationAdditional: Additional;

    public code:               string;
    public description:        string;
    public id:                 string;
    public name:               string;
    public openingHours:       string;
    public partnerId:          string;
    public version:            number;
    public defaultApmPassword: string;
    public defaultApmUsername: string;
    public partnerDcId:        string;
    public status:             string;
    public depotId:            number;


    get label(): string {
        return `${this.name} - ${this.code}`;
    }
}
