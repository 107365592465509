import { Component, OnInit } from '@angular/core';
import { ConfigValue } from '../../entities/config-value';
import { CRUD_MODE, CompartmentType } from '@enums/enum';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '@services/nav.sevice';
import { ConfigValueService } from '../../services/config-value.service';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfigKey } from '../../entities/config-key';
import { Partner } from '../../entities/partner';
import { Region } from '../../entities/region';
import * as _ from 'lodash';
import { AppService } from '@services/app.service';
import { Observable } from 'rxjs';
import { ConfigValuesParams } from '../../interfaces/config-values-params.interface';
import { ConfigKeyService } from '../../services/config-key.service';
import { ConfigValueUpdateDto } from '../../dto/config-value-update';
import { ConfigKeyTypes } from '../../enums/config-key.type';
import { ConfigValueOptions } from '../../enums/config-values-options';

@Component({
  selector: 'app-edit-config-value',
  templateUrl: './edit-config-value.component.html',
  styleUrls: ['./edit-config-value.component.scss']
})
export class EditConfigValueComponent implements OnInit {

  public configValue: ConfigValue;
  public mode: CRUD_MODE;

  public configKeys: ConfigKey[];
  public partner: Partner;
  public regions: Region[];

  public form: FormGroup;
  public loader: boolean = true;
  public submitted: boolean = false;

  public configKeyTypes = ConfigKeyTypes;

  public scopesOptions: string = 'GLOBAL';
  public selectedScopes: string[] = [];
  private parentScope;

  public configValue$: Observable<ConfigValue | ConfigKey>;
  private queryParams: ConfigValuesParams;

  public configValueOptions = ConfigValueOptions;
  public compartmentType = CompartmentType;
  // public allowedLockerTypesValue: string = "LMNO";


  constructor(
    private formBuilder: FormBuilder,
    private configValueService: ConfigValueService,
    private toastrProviderService: ToastrProviderService,
    private translateService: TranslateService,
    private navService: NavService,
    private appService: AppService,
    private route: ActivatedRoute,
    private readonly configKeyService: ConfigKeyService
  ) { }

  public ngOnInit() {



    this.configValue$ = this.route.params.pipe(
      tap((params: ConfigValuesParams) => this.queryParams = params),
      switchMap(() => {
        
        return (this.queryParams.isDefault.toString() === 'true')
          ? this.configKeyService.getConfigByCode(this.queryParams.code)
          : this.configValueService.findOne(this.queryParams.configId)
      }),
      tap((config: ConfigValue | ConfigKey) => this.initForm(config))
    )
  }

  private initForm(config: ConfigValue | ConfigKey) {

    if (config.hasOwnProperty('configKey')) {
      this.parentScope = (config as ConfigValue).scope;
    }

    this.form = this.formBuilder.group({
      id: [config.id],
      code: [{value: this.queryParams.code, disabled: true}, [Validators.maxLength(32)]],
      partnerId: [(this.queryParams.partnerId) ? this.queryParams.partnerId : null],
      timeslotId: [(this.queryParams.timeslotId !== 'null') ? this.queryParams.timeslotId : null],
      regionId: [this.queryParams.regionId !== 'null' ? this.queryParams.regionId : null],
      configKeyId: [config.hasOwnProperty('configKey') ? (config as ConfigValue).configKey.id : config.id, Validators.required],
      scope: [this.queryParams.scope, Validators.required],
      value: [ ((config as ConfigValue).value) ? (config as ConfigValue).value : this.queryParams.value, [Validators.required, Validators.min(0)]],
      version: [(config.version) ? config.version : null],
    });

    this.loader = false;
  }

  public onSubmit() {

    const formData = this.form.getRawValue();

    if (formData['regionId'] === 'null') {
      formData['regionId'] = null;
    } 

    if (this.queryParams.isDefault == true) {
      this.create(formData);
      return;
    }

    if (formData['code'] === 'allowedLockerTypes' && typeof formData['value'] !== 'string') {
      formData['value'] = formData['value'].join().replace(/,/g, '');
    }

    if (this.parentScope != formData['scope'] && this.queryParams.scope != 32) {
      this.create(formData);
      return;
    }

    this.update(formData);
    
  }

  private create(raw): void {
    this.configValueService.save(raw)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (configValue: ConfigValue) => {
          this.toastrProviderService.showSuccess(this.translateService.instant('New config value has been created!'));
          this.navService.navigateBack();
          this.appService.getFeaturesConfiguration();
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }

  private update(raw): void {

    this.configValueService.update(raw['id'], raw)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (configValue: ConfigValue) => {
          this.toastrProviderService.showSuccess(this.translateService.instant('The config value has been updated!'));
          this.navService.navigateBack();
          this.appService.getFeaturesConfiguration();
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }

  public allowedLockerTypesChanged(type: CompartmentType){
    let allowedLockers = this.form.get('value').value;
    const exist = allowedLockers.split('').find(a => a === type);

    if (exist) {
      allowedLockers = allowedLockers.split('').filter(a => a !== type).join('');
    } else {
      allowedLockers += type;
    }

    this.form.get('value').setValue(allowedLockers);
  }
}
