import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Apm } from '../../entities/apm';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import { Address } from '@entities/address';
import { Coordinates } from '@entities/coordinates';
import { ApmService } from '../../services/apm.service';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { CRUD_MODE, mapOptions, mapTypes } from '@enums/enum';
import { NavService } from '@services/nav.sevice';
import { plainToClass } from 'class-transformer';

@Component({
  selector: 'app-edit-apm',
  templateUrl: './edit-apm.component.html',
  styleUrls: ['./edit-apm.component.scss']
})
export class EditApmComponent implements OnInit {

  private queryParams: Subscription;
  private apm: Apm;
  public form: FormGroup;
  public submitted: boolean = false;
  public loader: boolean = true;
  public mode: CRUD_MODE;
  public coordinates: Coordinates = null;

  public mapOption = mapOptions.REGULAR;
  public mapProperties = {
    zoom: 18,
    mapTypeId: mapTypes.ROADMAP
  };

  constructor(
    private navigationRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private apmService: ApmService,
    private toastrProviderService: ToastrProviderService,
    private translateService: TranslateService,
    private navService: NavService
  ) { }

  get addressKeys() {
    const address = new Address();
    return _.keys(address);
  }

  get coordinatesKeys() {
    const cords = new Coordinates();
    return _.keys(cords);
  }

  public ngOnInit() {
    this.apm = (this.navigationRoute.snapshot.data as {apm: any}).apm;
    this.mode = (this.apm) ? CRUD_MODE.UPDATE : CRUD_MODE.CREATE;
    this.initForm();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      address: this.formBuilder.group({
        country: [this.apm ? this.apm.address.country : 'Polska', Validators.required],
        district: [this.apm ? this.apm.address.district : null, Validators.required],
        houseNO: [this.apm ? this.apm.address.houseNO : null, Validators.required],
        street: [this.apm ? this.apm.address.street : null, Validators.required],
        town: [this.apm ? this.apm.address.town : null, Validators.required],
        voivoidship: [this.apm ? this.apm.address.voivoidship : null, Validators.required],
        zip: [this.apm ? this.apm.address.zip : null, Validators.required]
      }),
      code: [this.apm ? this.apm.code : null],
      coordinates: this.formBuilder.group({
        lat: [this.apm ? this.apm.coordinates.lat : null, Validators.required],
        lng: [this.apm ? this.apm.coordinates.lng : null, Validators.required]
      }),
      id: [this.apm ? this.apm.id : null],
      name: [this.apm ? this.apm.name : null, Validators.required],
      lcount: [this.apm ? this.apm.lcount : null, Validators.required],
      mcount: [this.apm ? this.apm.mcount : null, Validators.required],
      ocount: [this.apm ? this.apm.ocount : null, Validators.required],
      ncount: [this.apm ? this.apm.ncount : null, Validators.required],
      operatingHours: [this.apm ? this.apm.operatingHours : null, Validators.required],
      status: [this.apm ? this.apm.status : null, Validators.required],
      type: [this.apm ? this.apm.type : null, Validators.required],
      version: [this.apm ? this.apm.version : null]
    });

    if (this.mode === CRUD_MODE.UPDATE) {
      this.getCoordinates();
      this.form.disable();
    } 
    


    this.loader = false;
  }

  public regularFormItems(): string[] {
    return ['name', 'code', 'operatingHours']
  }

  public onSubmit() {

    const rawApm = this.form.getRawValue();
    rawApm['code'] = rawApm['code'].trim();

    if(this.mode === CRUD_MODE.CREATE) {
      this.create(rawApm);
    } else {
      this.update(rawApm);
    }
  }

  private create(rawApm): void {
    this.apmService.save(rawApm)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (apm: Apm) => {
          console.log(apm);
          this.toastrProviderService.showSuccess(this.translateService.instant('New apm has been created!'));
          this.navService.goToPage('/apm/list', {modifiedRow: apm.id});
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }

  private update(rawApm): void {
    this.apmService.update(rawApm['id'], rawApm)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (apm: Apm) => {
          console.log(apm);
          this.toastrProviderService.showSuccess(this.translateService.instant('New apm has been updated!'));
          this.navService.goToPage('/apm/list', {modifiedRow: apm.id});
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }

  public getCoordinates(): void {
    const address: Address = plainToClass(Address, this.form.getRawValue()['address']);
    this.apmService.getCoordinatesForLocation(address.raw).subscribe(
      (coords: Coordinates) => {
        this.coordinates = coords;
        this.form.get('coordinates').get('lat').setValue(this.coordinates.lat);
        this.form.get('coordinates').get('lng').setValue(this.coordinates.lng);
      }
    );
  }

  public changeCoordinates(coordinates: Coordinates): void {
    this.form.get('coordinates').get('lat').setValue(coordinates.lat);
    this.form.get('coordinates').get('lng').setValue(coordinates.lng);
  }
}
