<layout-default>
    <div header>
        <div toolbar>
            <h4 *ngIf="mode === 'CREATE'">
                <i-feather name="database"></i-feather> {{'Create config value' | translate}}
            </h4>
            <h4 *ngIf="mode === 'UPDATE'">
                <i-feather name="database"></i-feather> {{'Edit config value' | translate}}
            </h4>
        </div>

        <hr />
    </div>
    
    <div content>

        <ng-container *ngIf="(configValue$ | async) as configValue">

            <div *ngIf="!loader" class="content bg-white">

                <div class="card-content col-8 offset-2">

                    <form [formGroup]="form">

                        <div class="form-group row">
                            <label class="col-2" for="code">{{'Code' | translate}}:&nbsp;<span *ngIf="form.get('code') | isRequiredControlPipe">*</span></label>
                            <div class="col-10">
                                <input formControlName="code" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-2" for="value">{{'Value' | translate}}:&nbsp;<span *ngIf="form.get('value') | isRequiredControlPipe">*</span></label>
                            <div class="col-10">
                                
                                <div [ngSwitch]="(configValue.type) ? configValue.type : configValue.configKey.type">
                                    <div *ngSwitchCase="configKeyTypes.Boolean">
                                        <select class="custom-select" formControlName="value">
                                            <option *ngFor="let option of [true, false]" [ngValue]="option.toString()">{{option.toString() | translate}}</option>
                                        </select>
                                    </div>
                                    <div *ngSwitchCase="configKeyTypes.Integer">
                                        <input type="number" formControlName="value" [min]="0" class="form-control" />
                                    </div>
                                    <div *ngSwitchCase="configKeyTypes.Duration">
                                        <input type="number" formControlName="value" [min]="0" class="form-control" />
                                    </div>
                                    <div *ngSwitchDefault>
                                        <ng-container *ngIf="configValueOptions[form.get('code').value] !== undefined; else inputText">
                                            <select class="custom-select" formControlName="value">
                                                <option *ngFor="let option of configValueOptions[form.get('code').value] | getObjectValuesPipe" [ngValue]="option">{{'ConfigValue.'+option | translate}}</option>
                                            </select>
                                        </ng-container>
                                        <ng-template #inputText>
                                            <input *ngIf="form.get('code').value !== 'allowedLockerTypes' else allowedLockerTypesControl" type="text" formControlName="value" class="form-control" />
                                            
                                            <ng-template #allowedLockerTypesControl>
                                                <div *ngFor="let lockerType of compartmentType | getObjectValuesPipe">
                                                    <div class="custom-control custom-checkbox pd-b-5">
                                                        <input [checked]="form.get('value').value.includes(lockerType)" (click)="allowedLockerTypesChanged(lockerType)" type="checkbox" class="custom-control-input" id="customCheck{{lockerType}}">
                                                        <label class="custom-control-label justify-content-start" for="customCheck{{lockerType}}">
                                                            <ng-container [ngSwitch]="lockerType.toUpperCase()">
                                                                <ng-container *ngSwitchCase="compartmentType.Freezer">{{'CompartmentType.Freezer' | translate }}</ng-container>
                                                                <ng-container *ngSwitchCase="compartmentType.Cooler">{{'CompartmentType.Cooler' | translate }}</ng-container>
                                                                <ng-container *ngSwitchCase="compartmentType.Ambient">{{'CompartmentType.Ambient' | translate}}</ng-container>
                                                                <ng-container *ngSwitchCase="compartmentType.Other">{{'CompartmentType.Other' | translate }}</ng-container>
                                                            </ng-container>
                                                        </label>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                </div>

                                <div *ngIf="form.get('value').hasError('required') && form.get('value').touched" class="error-color">
                                    {{ "Field is required" | translate }}!
                                </div>
                            </div>
                        </div>

                        <div class="form-group row justify-content-end">
                            <div class="col-2">
                                <button (click)="onSubmit()" [disabled]="!form.valid || submitted"
                                    class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01 w-100">

                                    <span *ngIf="!submitted">{{ "Save" | translate }}</span>

                                    <ng-container *ngIf="submitted">
                                        <div class="load-spinner text-center">
                                            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                        </div>
                                    </ng-container>

                                </button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </ng-container>
    </div>
</layout-default>

<!-- {{'False' | translate}} -->