import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DateRange, ORDER } from '@enums/enum';
import { ApmMaintenance, ApmMaintenanceType, ApmMaintenanceOrigin } from '../../../interfaces/apm-maintenance.interface';
import { NavService } from '@services/nav.sevice';
import { ApmService } from '../../../services/apm.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import * as moment from 'moment';
import { Apm } from '../../../entities/apm';
import { ActivatedRoute } from '@angular/router';
import { Permissions } from '@enums/permissions';
import { QueryParams } from '@interfaces/query-params.interface';
import { environment } from '@environment';
import { Collection } from '@interfaces/collection.interface';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-apm-maintanance-windows-list',
  templateUrl: './apm-maintanance-windows-list.component.html',
  styleUrls: ['./apm-maintanance-windows-list.component.scss']
})
export class ApmMaintananceWindowsListComponent implements OnInit {

  public apmMaintenance$: Observable<Collection<ApmMaintenance>>;
  public startDate;
  public endDate;
  public loader: boolean = true;
  public dateRange: DateRange;
  public list: Collection<ApmMaintenance>;
  public Permissions = Permissions;
  public origin = null;
  public apmMaintenanceType = ApmMaintenanceType;
  public apmMaintenanceOrigin = ApmMaintenanceOrigin;
  public locale = 'pl';
  public apms: Apm[];
  protected queryParams: QueryParams = Object.assign({}, environment.pagination);

  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  public columns = [
    { prop: 'apmId', name: this.translateService.instant('Apm'), flexGrow: 2, sortable: true },
    { prop: 'slot.from', name: this.translateService.instant('From'), flexGrow: 2, sortable: false },
    { prop: 'slot.to', name: this.translateService.instant('To'), flexGrow: 2, sortable: false },
    { prop: 'type', name: this.translateService.instant('Type'), flexGrow: 2, sortable: true },
    { prop: 'origin', name: this.translateService.instant('Origin'), flexGrow: 2, sortable: true },
    { prop: 'lcount', name: this.translateService.instant('Capacity'), flexGrow: 2, sortable: true },
    { prop: 'options', name: this.translateService.instant('Options'), flexGrow: 1, sortable: true },
  ];

  constructor(
    private readonly navService: NavService,
    private readonly apmService: ApmService,
    private readonly translateService: TranslateService,
    private readonly navigationRoute: ActivatedRoute
  ) { }

  public ngOnInit() {
    this.apms = this.navigationRoute.snapshot.params.modifiedRow;
    delete this.queryParams.sort;

    this.translateService.onLangChange.subscribe((lang: LangChangeEvent) => {
      this.locale = lang.lang;
    });
  }

  private checkApmMaintenanceWindow(): void {
      this.apmService.getApmMaintenanceWindowList(this.queryParams)
        .pipe(tap((response) => this.list = response)).subscribe();

      this.loader = false;
  }

  public changedDateRange(event): void {
    this.loader = true;
    this.queryParams.startDate = moment(event.dateStart).format('YYYY-MM-DD');
    this.queryParams.endDate = moment(event.dateEnd).format('YYYY-MM-DD');
    this.dateRange = event.dateRange;
    this.checkApmMaintenanceWindow();
  }

  public onSort(sortInfo) {
    console.log(sortInfo);
    const sort = sortInfo.sorts[0].prop.split('.');
    this.queryParams.sort = sort[sort.length - 1]
    this.queryParams.order = (sortInfo.sorts[0].dir === 'asc') ? ORDER.DESC : ORDER.ASC;
    this.checkApmMaintenanceWindow();
  }

  public setPage(pageInfo?) {
    this.queryParams.page = pageInfo.offset;
    this.checkApmMaintenanceWindow();
  }

  public changedOrigin(event): void {
    console.log(event);
    this.queryParams['origins'] = event
    this.checkApmMaintenanceWindow();
  }

  public goToMaintenanceWindows(id: string): void {
    this.navService.goToPage(`/apm/maintenance/${id}`);
  }

}
