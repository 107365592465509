
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { PartnerCourierService } from '../services/partner-courier.service';

@Injectable()
export class PartnerCourierResolver implements Resolve<any> {
    constructor(private partnerCourierService: PartnerCourierService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        const params = route.params;
        return this.partnerCourierService.findOne(params['partnerCourierId']);
    }
}
