<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="database"></i-feather> {{'Apm delivery' | translate}}
            </h4>
        </div>
        <div toolbar>
            <button id="apm-delivery-btn-create" class="btn btn-sm btn-uppercase btn-brand-01"
                [routerLink]="['/apm-delivery/create']">
                <i-feather [style.height.px]="23" [style.width.px]="13" class="mg-r-10" name="plus"></i-feather> {{ 'Add
                apm delivery' | translate }}
            </button>
            <select id="apm-delivery-btn-select-region" class="custom-select" [(ngModel)]="selectedRegion"
                (ngModelChange)="filterByRegion($event)">
                <option [ngValue]="null">{{'All regions' | translate}}</option>
                <option *ngFor="let p of partner.regionList" [value]="p.id">{{p.name}} - {{p.code}}</option>
            </select>
            <select id="apm-delivery-btn-select-status" class="custom-select" [(ngModel)]="selectedStatus"
                (ngModelChange)="filterByStatus($event)">
                <option [ngValue]="null">{{'All statuses' | translate}}</option>
                <option *ngFor="let status of apmDeliveryStatus" [value]="status">{{'ApmDeliveryStatus.'+status |
                    translate}}</option>
            </select>
            <div id="apm-delivery-btn-search" class="filter-container flex-grow-1">
                <div class="phrase-filter">
                    <input type='text' class="list-filter form-control" placeholder="{{'type to search' | translate}}"
                        (keyup)='applyFiltering($event)' />
                </div>
            </div>
        </div>
        <div toolbar>
            <div style="flex-grow: 2">
                <date-range-picker [extendedOption]="false"
                    (changedDateRange)="changedDateRange($event)"></date-range-picker>
            </div>
            <div>
                <label>{{ 'Apms' | translate }}:</label>
            </div>
            <div id="apm-filter" style="flex-grow: 1">
                <angular2-multiselect id="apm-delivery-btn-select-apms" class="form-control" [data]="dropdownList"
                    [(ngModel)]="selectedApms" [settings]="dropdownSettings" (onSelect)="onItemSelect()"
                    (onSelectAll)="onItemSelect()" (onDeSelect)="onItemSelect()">
                </angular2-multiselect>
            </div>
        </div>
        <hr />
    </div>

    <div content>
        <div *ngIf="(list$ | async) as list">
            <ngx-datatable id="apm-delivery-table" class="bootstrap" [rows]="apmDelivery" [columns]="columns"
                [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="50" [messages]="tableMessages"
                [rowHeight]="'auto'" [externalPaging]="true" [count]="list.totalElements" [offset]="queryParams.page"
                [rowClass]="rowClass" [limit]="queryParams.size" [scrollbarH]="scrollBarHorizontal"
                (sort)="onSort($event)" (page)="setPage($event)">
                <ng-container *ngFor="let col of columns">
                    <ngx-datatable-column [flexGrow]="col.flexGrow" [prop]="col.prop">
                        <ng-template let-column="column" ngx-datatable-header-template class="header" let-sort="sortFn">
                            <b (click)="sort()">{{ col.name | translate }}</b>
                        </ng-template>
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>

                            <div [ngSwitch]="col.prop">
                                <div *ngSwitchCase="'options'">
                                    <div class="dropdown" dropdown>
                                        <button class="btn btn-outline-secondary btn-xs dropdown-toggle" type="button"
                                            id="dropdownMenuButton">
                                            {{ 'Select' | translate }}
                                        </button>

                                        <div
                                            class="dropdown-menu dropdown-menu-right div-main-actions div-main-actions">
                                            <a *ngIf="([Permissions.Apm_delivery_rebook] | checkPermissionsPipe) && row.logisticType === logisticType.OWN && [apmDeliveryStatus.New, apmDeliveryStatus.Completed, apmDeliveryStatus.Not_used].includes(row.status) && row.reservationWindow | checkReservationTimeBufforPipe"
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item"
                                                [routerLink]="['/partner', row.partnerId, 'apm-delivery', 'rebooking', 'delivery', row.id, 'apm', row.apm.id]">
                                                {{'Rebook' | translate }}
                                            </a>

                                            <a class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item"
                                                [routerLink]="['/partner', row.partnerId, 'apm-delivery', 'details', row.id]">
                                                {{'Details' | translate }}
                                            </a>

                                            <!-- <a 
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                                (click)="removeApmDelivery(row.id)">
                                                    {{'Remove' | translate }}
                                            </a> -->

                                        </div>
                                    </div>
                                </div>
                                <div id="apm-delivery-table-col-{{col.prop}}"
                                    (click)="showRlmDeliveryDetails($event, row)" *ngSwitchCase="'detail'">
                                    <i-feather name="search" [style.width.px]="15"></i-feather>
                                </div>
                                <div id="apm-delivery-table-col-{{col.prop}}" *ngSwitchCase="'reservationNumber'">
                                    {{ value }}<br />
                                    <span *ngIf="row.supersededBy"><strong>{{'Superseded by' |
                                            translate}}</strong>:<br />{{row.supersededBy}}</span>

                                </div>
                                <div id="apm-delivery-table-col-{{col.prop}}" *ngSwitchCase="'apm'">[{{row.apm.code}}],
                                    {{ value.address | addressLabelPipe}}


                                    <span
                                        *ngIf="[apmDeliveryStatus.Superseded].includes(row.status) && row.supersededBy !== null">
                                        <ng-container
                                            *ngIf="(alternateApmDelivery | findApmDeliveryByIdPipe:row.supersededBy) as delivery else noAddress;">
                                            <span
                                                *ngIf="delivery.apm.id === row.apm.id else showNewAddress"><br /><strong>{{'The
                                                    same apm machine' | translate}}</strong></span>
                                            <ng-template #showNewAddress><br /><strong>{{'Superseded by' |
                                                    translate}}</strong>:<br />[{{delivery.apm.code}}],
                                                {{delivery.apm.address | addressLabelPipe}}</ng-template>
                                        </ng-container>

                                    </span>

                                </div>
                                <div id="apm-delivery-table-col-{{col.prop}}" *ngSwitchCase="'lcount'">
                                    <span [containerClass]="'pretty-tooltip'" placement="right"
                                        [tooltip]="row | rlmCapacityLabelPipe">{{row |
                                        rlmCapacityLabelShortPipe}}</span>
                                </div>

                                <div id="apm-delivery-table-col-{{col.prop}}" *ngSwitchCase="'status'">

                                    <ng-container [ngSwitch]="value">
                                        <span *ngSwitchCase="apmDeliveryStatus.Cancelled" class="badge badge-danger">{{
                                            'ApmDeliveryStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmDeliveryStatus.Completed" class="badge badge-success">{{
                                            'ApmDeliveryStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmDeliveryStatus.New" class="badge badge-warning">{{
                                            'ApmDeliveryStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmDeliveryStatus.Reserved"
                                            [containerClass]="'pretty-tooltip'" placement="left"
                                            [tooltip]="'Please, contact with your partner to handle the delivery' | translate"
                                            class="badge badge-info">{{ 'ApmDeliveryStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmDeliveryStatus.Used" class="badge badge-secondary">{{
                                            'ApmDeliveryStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmDeliveryStatus.Not_used" class="badge badge-primary">{{
                                            'ApmDeliveryStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmDeliveryStatus.Expired" class="badge badge-dark">{{
                                            'ApmDeliveryStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmDeliveryStatus.Reminded" class="badge badge-light">{{
                                            'ApmDeliveryStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmDeliveryStatus.Stored" class="badge badge-secondary">{{
                                            'ApmDeliveryStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="apmDeliveryStatus.Cancelled_Post_Cutoff"
                                            class="badge badge-danger">{{ 'ApmDeliveryStatus.'+value | translate
                                            }}</span>

                                        <span *ngSwitchCase="apmDeliveryStatus.Superseded"
                                            [containerClass]="'pretty-tooltip'" placement="left"
                                            [tooltip]="row.reason | reasonTranslatePipe | translate"
                                            class="badge badge-dark">{{ 'ApmDeliveryStatus.'+value | translate }}</span>

                                        <span *ngSwitchDefault class="badge badge-light">{{ 'ApmDeliveryStatus.'+value |
                                            translate }}</span>
                                    </ng-container>
                                </div>
                                <div id="apm-delivery-table-col-{{col.prop}}" *ngSwitchCase="'reservationWindow.from'">
                                    {{ row.reservationWindow.from | amLocale: locale | amDateFormat: 'D MMM, HH:mm' }} -
                                    {{ row.reservationWindow.to | amLocale: locale | amDateFormat: 'D MMM, HH:mm' }}
                                </div>
                                <div id="apm-delivery-table-col-{{col.prop}}" *ngSwitchCase="'logisticType'">
                                    <ng-container [ngSwitch]="value">
                                        <span *ngSwitchCase="'OWN'">{{ 'ApmDeliveryType.'+value | translate }}</span>
                                        <span *ngSwitchCase="'INPOST'">{{ 'ApmDeliveryType.'+value | translate }}</span>
                                        <span *ngSwitchCase="'MIXED'">{{ 'ApmDeliveryType.'+value | translate }}</span>
                                        <span *ngSwitchDefault>{{ 'ApmDeliveryType.'+value | translate }}</span>
                                    </ng-container>
                                </div>

                                <div id="apm-delivery-table-col-{{col.prop}}" *ngSwitchCase="'discounted'">
                                    <div class="custom-control custom-checkbox text-center">
                                        <input [checked]="value" (click)="discountedChanged($event, row)"
                                            type="checkbox" class="custom-control-input" id="discount-{{row.id}}">
                                        <label class="custom-control-label justify-content-start"
                                            for="discount-{{row.id}}">&nbsp;</label>
                                    </div>
                                </div>


                                <div id="apm-delivery-table-col-{{col.prop}}" *ngSwitchDefault>{{value | translate}}
                                </div>
                            </div>

                        </ng-template>
                    </ngx-datatable-column>
                </ng-container>
            </ngx-datatable>
        </div>

        <div *ngIf="loader" class="load-spinner text-center">
            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}
        </div>

    </div>

</layout-default>

<ng-template #confirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span
                aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p>{{'Do you confirm remove the Apm delivery' | translate }}?</p>
        <button type="button" class="btn btn-brand-01" (click)="confirm()">
            {{'Yes, remove' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>


<ng-template #confirmationDiscountedAction>
    <div class="modal-header">
        <h4 class="modal-title">{{ 'Confirm action' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="!modalArgs.values.apmDelivery.discounted else deleteDiscount">
            <p>{{ 'Do you want to apply the discount for that delivery: %apmDeliveryId%' | translate: modalArgs.values
                }}?</p>

            <div>
                <label for="partner">{{'Reason' | translate}}:</label>
                <textarea rows="4" [(ngModel)]="discountedReason" class="form-control mg-b-20"></textarea>
            </div>
        </ng-container>

        <ng-template #deleteDiscount>
            <p>{{ 'Do you want to delete the discount for that delivery: %apmDeliveryId%' | translate: modalArgs.values
                }}?</p>
        </ng-template>



        <div class="text-center">
            <button type="button" class="btn btn-brand-01 btn-confirm" (click)="applyDiscount()">
                {{ 'Yes' | translate }}
            </button>
            <button type="button" class="btn btn-default" (click)="decline()">
                {{ 'Cancel' | translate }}
            </button>
        </div>
    </div>
</ng-template>

<ng-template #rlmDeliveryDetails>
    <div class="modal-header">
        <h4 class="modal-title">{{ 'Delivery details' | translate }}: {{modalArgs.values.apmDelivery.id}}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body reservation-details">


        <div class="row">
            <div class="col-6">
                <p>{{'Delivery ID' | translate}}:</p>
                <h6>{{ modalArgs.values?.apmDelivery.id | translate}}</h6>

                <p>{{'reservationReference' | translate}}:</p>
                <h6>{{ modalArgs.values?.apmDelivery.reservationReference | translate}}</h6>

                <p>{{'Location' | translate}}:</p>
                <h6>[{{modalArgs.values?.apmDelivery.apm.code}}], {{ modalArgs.values?.apmDelivery.apm.address |
                    addressLabelPipe}}</h6>

                <p>{{'Status' | translate}}:</p>
                <h6>{{'ApmDeliveryStatus.' + modalArgs.values?.apmDelivery.status | translate}}</h6>

                <p>{{'Reservation window' | translate}}:</p>
                <h6>{{ modalArgs.values?.apmDelivery.reservationWindow.from | amLocale: locale | amDateFormat: 'D MMM,
                    HH:mm' }} - {{ modalArgs.values?.apmDelivery.reservationWindow.to | amLocale: locale | amDateFormat:
                    'D MMM, HH:mm' }}</h6>

                <p>{{'courierWindow' | translate}}:</p>
                <h6>{{ modalArgs.values?.apmDelivery.courierWindow.from | amLocale: locale | amDateFormat: 'D MMM,
                    HH:mm' }} - {{ modalArgs.values?.apmDelivery.courierWindow.to | amLocale: locale | amDateFormat: 'D
                    MMM, HH:mm' }}</h6>

                <p>{{'deliveryWindow' | translate}}:</p>
                <h6>{{ modalArgs.values?.apmDelivery.deliveryWindow.from | amLocale: locale | amDateFormat: 'D MMM,
                    HH:mm' }} - {{ modalArgs.values?.apmDelivery.deliveryWindow.to | amLocale: locale | amDateFormat: 'D
                    MMM, HH:mm' }}</h6>

                <p>{{'purgeWindow' | translate}}:</p>
                <h6>{{ modalArgs.values?.apmDelivery.purgeWindow.from | amLocale: locale | amDateFormat: 'D MMM, HH:mm'
                    }} - {{ modalArgs.values?.apmDelivery.purgeWindow.to | amLocale: locale | amDateFormat: 'D MMM,
                    HH:mm' }}</h6>

            </div>
            <div class="col-6">
                <p>{{'customerEmail' | translate}}:</p>
                <h6>{{ modalArgs.values?.apmDelivery.customerEmail | translate}}</h6>

                <p>{{'customerPhone' | translate}}:</p>
                <h6>{{ modalArgs.values?.apmDelivery.customerPhone | translate}}</h6>

                <p>{{'CompartmentType.Cooler' | translate}}:</p>
                <h6>{{ modalArgs.values?.apmDelivery.lcount}}</h6>

                <p>{{'CompartmentType.Freezer' | translate}}:</p>
                <h6>{{ modalArgs.values?.apmDelivery.mcount}}</h6>

                <p>{{'CompartmentType.Ambient' | translate}}:</p>
                <h6>{{ modalArgs.values?.apmDelivery.ncount}}</h6>

                <p>{{'CompartmentType.Other' | translate}}:</p>
                <h6>{{ modalArgs.values?.apmDelivery.ocount}}</h6>
            </div>
        </div>

        <div class="text-center">
            <button type="button" class="btn btn-default" (click)="decline()">
                {{ 'Close' | translate }}
            </button>
        </div>
    </div>
</ng-template>

<!-- {{'ApmDeliveryStatus.NEW' | translate}} -->
<!-- {{'ApmDeliveryStatus.COMPLETED' | translate}} -->
<!-- {{'ApmDeliveryStatus.CANCELLED' | translate}} -->
<!-- {{'ApmDeliveryStatus.RESERVED' | translate}} -->
<!-- {{'ApmDeliveryStatus.NOT_USED' | translate}} -->
<!-- {{'ApmDeliveryStatus.USED' | translate}} -->
<!-- {{'ApmDeliveryStatus.EXPIRED' | translate}} -->
<!-- {{'ApmDeliveryStatus.STORED' | translate}} -->
<!-- {{'ApmDeliveryStatus.REMINDED' | translate}} -->
<!-- {{'ApmDeliveryStatus.RETURNED' | translate}} -->
<!-- {{'ApmDeliveryStatus.CANCELLED_POST_CUTOFF' | translate}} -->
<!-- {{'ApmDeliveryStatus.SUPERSEDED' | translate}} -->

<!-- {{'ApmDeliveryType.OWN' | translate}} -->
<!-- {{'ApmDeliveryType.INPOST' | translate}} -->
<!-- {{'ApmDeliveryType.MIXED' | translate}} -->