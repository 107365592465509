import { Component, Input, ViewChild, TemplateRef } from '@angular/core';

import { Delivery } from '@entities/delivery';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DeliveriesService } from '@deliveries/services/deliveries.services';
import { DomSanitizer } from '@angular/platform-browser';
import { DeliveryFileType } from '@interfaces/files.interface';
import { Client } from '@enums/enum';
import { AppService } from '@services/app.service';
import { take, tap, switchMap } from 'rxjs/operators';
import { environment } from '@environment';

@Component({
    selector: 'delivery-details',
    template: `
        <div *ngIf="delivery" class="d-flex align-items-top justify-content-between bd-highlight" [ngClass]="{ 'bg-gray': num % 2 }">
            <div class="column p-2 mg-r-10 bd-highlight" [style.flex-grow]="6" [style.flex-basis]="0">
                <div *ngIf="location" class="mg-b-10">
                    <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">{{ 'Location' | translate }}:</label>
                    <span class="d-block" *ngIf="location.label"
                        ><strong>{{ location.label }}</strong></span
                    >
                    <span class="d-block" *ngIf="location.address.raw"
                        >{{ 'Address' | translate }}:<strong> {{ location.address.raw }}</strong>
                        <a class="map" href="{{ GOOGLE_MAPS_URL }} {{ location.address.raw }}" target="_blank"> <i class="fas fa-external-link-alt"></i></a>
                    </span>
                    <span class="d-block" *ngIf="location.remarks"
                        >{{ 'Note introduced by customer' | translate }}: <strong>{{ location.remarks }}</strong></span
                    >
                </div>

                <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">{{ 'Customer' | translate }}:</label>
                <span class="d-block" *ngIf="delivery.customer.label"
                    ><strong>{{ delivery.customer.label }}</strong></span
                >
                <span class="d-block" *ngIf="delivery.id.includes('INP')"
                    >{{ 'Id' | translate }}: <strong>{{ delivery.id }}</strong></span
                >
                <span class="d-block" *ngIf="delivery.customer.ref"
                    >{{ 'Customer number' | translate }}: <strong>{{ delivery.customer.ref }}</strong></span
                >

                <span class="d-block" *ngIf="delivery.location.additional.contactPhoneNO"
                    >{{ 'Phone number to delivery' | translate }}: <strong>{{ delivery.location.additional.contactPhoneNO }}</strong></span
                >
                <span class="d-block" *ngIf="delivery.customer.phoneNr && delivery.location.additional.contactPhoneNO !== delivery.customer.phoneNr"
                    >{{ 'Phone number to account owner' | translate }}: <strong>{{ delivery.customer.phoneNr }}</strong></span
                >
                <span class="d-block" *ngIf="delivery.locationLabel"
                    >{{ 'Address' | translate }}:

                    <strong
                        ><span class="city-label">{{ delivery.location.address.town }}, </span>
                        <span class="street">{{ delivery.location.address.street }}</span>
                        {{ delivery.location.address.houseNO }}<ng-container *ngIf="delivery.location.additional.flatNO as flatNO">/{{ flatNO }},</ng-container> {{ delivery.location.address.zip }}
                        {{ delivery.location.address.country }}
                    </strong>

                    <a class="map" href="{{ GOOGLE_MAPS_URL }} {{ delivery.location.rawAddress }}" target="_blank"> <i class="fas fa-external-link-alt"></i></a
                ></span>
                <span appCheckClient [client]="friscoClient" class="d-block" *ngIf="delivery.isAddressDifferent"
                    >{{ 'Frisco address' | translate }}: <strong>{{ delivery.friscoAddress }}</strong
                    ><a class="map" href="{{ GOOGLE_MAPS_URL }} {{ delivery.friscoAddress }}" target="_blank"> <i class="fas fa-external-link-alt"></i></a
                ></span>
                <br />
                <span class="d-block" *ngIf="delivery.dispatcherNotes"
                    >{{ 'Dispatcher notes to delivery' | translate }}: <strong>{{ delivery.dispatcherNotes }}</strong></span
                >
                <span class="d-block" *ngIf="delivery.customer.notes"
                    >{{ 'Notes to customer' | translate }}: <strong>{{ delivery.customer.notes }}</strong></span
                >
                <span class="d-block" *ngIf="delivery.order.notes"
                    >{{ 'Notes to delivery' | translate }}: <strong>{{ delivery.order.notes }}</strong></span
                >

                <span class="d-block" *ngIf="delivery.location.additional.instructions"
                    >{{ 'Note introduced by customer' | translate }} : <strong>{{ delivery.location.additional.instructions }}</strong></span
                >
                <span class="d-block" *ngIf="delivery.driverNotes"
                    >{{ 'Driver notes' | translate }}: <strong>{{ delivery.driverNotes }}</strong></span
                >

                <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0 pd-t-10">{{ 'Boxes & bags' | translate }}:</label>
                <boxes-grid [allBoxes]="delivery.order.boxes" [parent]="'delivery'"></boxes-grid>
                <div *ngIf="!delivery.order.boxesCount">
                    <strong>{{ 'Delivery without boxes' | translate }}</strong>
                </div>
            </div>
            <div class="column p-2 flex-grow-1 flex-shrink-1 bd-highlight" [style.flex-grow]="1" [style.flex-shrink]="1">
                <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">{{ 'Delivery' | translate }}:</label>
                <span class="d-block" *ngIf="delivery.status"
                    >{{ 'Status' | translate }}: <strong>{{ delivery.status | translate }}</strong></span
                >
                <span class="d-block" *ngIf="delivery.originalRouteId"
                    >{{ 'Original Route Id' | translate }}: <strong>{{ delivery.originalRouteId.getRouteId() }}</strong></span
                >
                <span class="d-block" *ngIf="delivery.originalRouteNumber"
                    >{{ 'Original Route Number' | translate }}: <strong>{{ delivery.originalRouteNumber }}</strong></span
                >
                <span class="d-block" *ngIf="delivery.altered"
                    >{{ 'Altered' | translate }}: <strong>{{ delivery.altered.toString() | translate }}</strong></span
                >
                <span class="d-block" *ngIf="delivery.shift"
                    >{{ 'Shift' | translate }}: <strong>{{ delivery.shift | translate }}</strong></span
                >
                <span class="d-block" *ngIf="delivery.generatedBy"
                    >{{ 'Generated by' | translate }}: <strong>{{ delivery.generatedBy | translate }}</strong></span
                >

                <span class="d-block" *ngIf="delivery.order.slot"
                    >{{ 'Slot selected by customer' | translate }}: <strong>{{ delivery.order.slot }}</strong></span
                >

                <span class="d-block" *ngIf="delivery.order.originalWindow"
                    >{{ 'Original slot' | translate }}: <strong>{{ delivery.order.originalWindow | timeWindowPipe }}</strong></span
                >

                <span class="d-block"
                    >{{ 'Early delivery permission' | translate }}: <strong>{{ delivery.earlyDeliveryPermission.toString() | translate }}</strong></span
                >
                <span *ngIf="delivery.properties && delivery.properties.pickupCode" class="d-block"
                    >{{ 'Pickup code' | translate }}: <strong>{{ delivery.properties.pickupCode | translate }}</strong></span
                >

                <ng-container *ngIf="delivery.files.length">
                    <span *ngFor="let file of delivery.files">
                        <span class="d-block" (click)="dispalySignature(file.id, file.identifier)"
                            >{{ 'FileType.' + file.identifier | translate }} {{ file.merchant }}: <strong>LINK <i class="fas fa-external-link-alt"></i></strong
                        ></span>
                    </span>
                </ng-container>

                <label *ngIf="delivery.order.isComplaint" class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans pd-t-10 mg-b-3">{{ 'Complaints images' | translate }}:</label>

                <ng-container *ngFor="let item of delivery.order.items">
                    <ng-container *ngFor="let complaint of item.complaints">
                        <span *ngFor="let file of complaint.filename">
                            <span class="d-block" (click)="dispalyCompaintImage(file)"
                                >{{ item.name.substr(0, 18) }} {{ complaint.type | translate }} - <strong>LINK <i class="fas fa-external-link-alt"></i></strong
                            ></span>
                        </span>
                    </ng-container>
                </ng-container>
            </div>
            <div class="column p-2 flex-grow-1 flex-shrink-1 bd-highlight" [style.flex-grow]="1" [style.flex-shrink]="1">
                <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">{{ 'Payments' | translate }}:</label>
                <ol class="pd-l-12">
                    <li class="mg-b-10" *ngFor="let payment of delivery.paymentInfos">
                        <span class="d-block" appCheckClient [client]="friscoClient" *ngIf="payment.merchant"
                            >{{ 'Merchant' | translate }}: <strong>{{ payment.merchant }}</strong></span
                        >
                        <span class="d-block" *ngIf="payment.id"
                            >{{ 'Id' | translate }}: <strong>{{ payment.id }}</strong></span
                        >
                        <span class="d-block" *ngIf="payment.amountDue">
                            {{ 'Amount Due' | translate }}: <strong>{{ payment.amountDue | number: '1.2-2' }} {{ payment.currency | translate }}</strong>
                        </span>
                        <span class="d-block" *ngIf="payment.amountPaid">
                            {{ 'Amount Paid' | translate }}: <strong>{{ payment.amountPaid | number: '1.2-2' }} {{ payment.currency | translate }}</strong>
                        </span>
                        <span class="d-block" *ngIf="payment.grandTotal"
                            >{{ 'Grand Total' | translate }}: <strong>{{ payment.grandTotal | number: '1.2-2' }}</strong></span
                        >
                        <span class="d-block" *ngIf="payment.requestedType && payment.amountDue"
                            >{{ 'Requested Type' | translate }}: <strong>{{ payment.requestedType | translate }}</strong></span
                        >
                        <span appCheckClient [client]="friscoClient" class="d-block"
                            >{{ 'e-Invoice' | translate }}: <strong *ngIf="delivery.electronicInvoiceOnly">{{ 'Yes' | translate }}</strong>
                            <strong *ngIf="!delivery.electronicInvoiceOnly">{{ 'false' | translate }}</strong></span
                        >
                        <span class="d-block" *ngIf="payment.methods?.length"
                            >{{ 'Method' | translate }}:
                            <ul>
                                <li *ngFor="let method of payment.methods; let index = index">
                                    <strong [containerClass]="'pretty-tooltip'" [tooltip]="'Paper version' | translate" *ngIf="method.type === 'WIRETRANSFER' && !method.online">[P]</strong>
                                    <strong [containerClass]="'pretty-tooltip'" [tooltip]="'Electronic version' | translate" *ngIf="method.type === 'WIRETRANSFER' && method.online"
                                        >[E] <button class="btn btn-xs btn-info d-inline" (click)="showCustomerPrivateData(method.id)">{{ 'Show' | translate }}</button>
                                    </strong>
                                    <ng-container *ngIf="method.type === 'CARD'">
                                        <ng-container [ngSwitch]="method.amount">
                                            <span *ngSwitchCase="0" class="badge badge-danger mg-b-3 mg-r-5">{{ 'PaymentResult.Failed' | translate }}</span>
                                        </ng-container> </ng-container
                                    >{{ method.type | translate }} - {{ method.amount | number: '1.2-2' }} {{ method.currency }}
                                </li>
                            </ul>
                        </span>
                        <span *ngIf="!payment.amountDue" class="d-block"
                            ><strong>{{ 'Delivery without payment' | translate }}</strong></span
                        >
                    </li>
                </ol>
            </div>
        </div>

        <ng-template #signatureRef>
            <div class="modal-header">
                <h4 class="modal-title">{{ 'Image for delivery' | translate }}: {{ delivery.orderId }}</h4>
                <button (click)="download(modalArgs.values.signature)" class="btn btn-white mg-l-5">
                    <span aria-hidden="true">{{ 'Download' | translate }}</span>
                </button>
                <button type="button" class="btn btn-white close" data-dismiss="modal" aria-label="Close" (click)="decline()">
                    <span aria-hidden="true"><i-feather name="X"></i-feather></span>
                </button>
            </div>
            <div class="modal-body text-center" [style.max-height.vh]="90" [style.overflow]="'scroll'">
                <div *ngIf="dispalySignatureLoader && !error" class="load-spinner spinner" [style.min-height.px]="300">
                    <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                    <p>{{ 'Please, wait!' | translate }}</p>
                </div>
                <div *ngIf="error" class="load-spinner" [style.min-height.px]="300">
                    <i-feather name="alert-circle"></i-feather>
                    <div class="tx-11 mg-l-5">{{ 'No data to display' | translate }}</div>
                </div>
                <img *ngIf="!dispalySignatureLoader" [src]="modalArgs.values.signature" class="img-fluid rounded" width="70%" />
            </div>
        </ng-template>

        <ng-template #cutomerPrivateDataRef>
            <div class="modal-header">
                <h4 class="modal-title">{{ 'Confirm action' | translate }}</h4>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <div *ngIf="!showPrivateDataToggle" class="row" style="padding-bottom: 40px">
                    <div class="col-9 form-horizontal">
                        <label class="text-left tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans">
                            {{ 'Password' | translate }}
                        </label>
                        <input [(ngModel)]="showPrivateDataPass" type="password" class="form-control" />
                    </div>
                    <div class="col-3 form-horizontal">
                        <label class="text-left tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans">&nbsp;</label>
                        <button (click)="showPrivateDataConfirmation()" class="btn btn-danger form-control" style="color: #FFF;">{{ 'Show' | translate }}</button>
                    </div>
                </div>

                <div *ngIf="showPrivateDataToggle" class="row" style="padding-bottom: 40px">
                    <div class="col-12">
                        <p *ngIf="paymentDetails.fullName as value">
                            {{ 'Firstname and lastname' | translate }}: <strong>{{ value }}</strong>
                        </p>
                        <p *ngIf="paymentDetails.idNumber as value">
                            {{ 'IDNumber' | translate }}: <strong>{{ value }}</strong>
                        </p>
                        <p *ngIf="paymentDetails.address as value">
                            {{ 'Address' | translate }}: <strong>{{ value }}</strong>
                        </p>
                    </div>
                </div>

                <button type="button" class="btn btn-default" (click)="decline()">
                    {{ 'Cancel' | translate: modalArgs.values }}
                </button>
            </div>
        </ng-template>

        <!-- {{'FileType.CUSTOMER_SIGNATURE' | translate }} -->
        <!-- {{'FileType.CUSTOMER_WIRETRANSFER_SIGNATURE' | translate }} -->
        <!-- {{'FileType.DRIVER_WIRETRANSFER_SIGNATURE' | translate }} -->
        <!-- {{'FileType.DROPOFF_PICTURE' | translate }} -->
        <!-- {{'DAMAGED' | translate }} -->
        <!-- {{'MISSING_PRODUCT' | translate }} -->
        <!-- {{'MISSING_BOX' | translate }} -->
        <!-- {{'BAD_QUALITY' | translate }} -->
        <!-- {{'UNDERAGE_PERSON' | translate }} -->
        <!-- {{'MISSING' | translate }} -->
    `,
    styleUrls: ['./delivery-details.component.scss'],
})
export class DeliveryDetailsComponent {
    @Input() public delivery: Delivery;
    @Input() public location?: Location;
    @ViewChild('signatureRef', { static: true }) private signatureRef: TemplateRef<any>;
    @ViewChild('cutomerPrivateDataRef', { static: true }) private cutomerPrivateDataRef: TemplateRef<any>;

    public inpostClient: string = Client.INPOST;
    public friscoClient: string = Client.FRISCO;

    private GOOGLE_MAPS_URL = 'https://www.google.pl/maps/place/';

    public dispalySignatureLoader: boolean = true;
    private modalRef: BsModalRef;
    private modalArgs = { values: {} };
    public error = false;
    public showPrivateDataPass = '';
    public showPrivateDataToggle = false;
    public paymentInfoId;
    public paymentDetails: any;

    constructor(
        private readonly modalService: BsModalService,
        private readonly deliveriesService: DeliveriesService,
        private readonly sanitizer: DomSanitizer,
        private readonly appService: AppService
    ) {}

    public dispalySignature(id: string, identifier: DeliveryFileType): void {
        this.dispalySignatureLoader = true;

        this.deliveriesService.fetchSignature(this.delivery.id, identifier).subscribe(
            (signature) => {
                const reader = new FileReader();
                let base64data;
                reader.readAsDataURL(signature);
                reader.onloadend = () => {
                    base64data = reader.result;
                    this.modalArgs.values = {
                        delivery: this.delivery,
                        signature: this.sanitizer.bypassSecurityTrustUrl(base64data),
                    };
                    this.dispalySignatureLoader = false;
                };
            },
            () => {
                this.error = true;
            }
        );

        this.modalRef = this.modalService.show(this.signatureRef, { class: 'modal-lg' });
    }

    public dispalyCompaintImage(file: string) {
        this.dispalySignatureLoader = true;
        this.modalArgs.values = {
            delivery: this.delivery,
            signature: this.sanitizer.bypassSecurityTrustUrl(`${environment.publicFilesBucketUrl}/uploads/p/${file}`),
        };

        this.dispalySignatureLoader = false;
        this.modalRef = this.modalService.show(this.signatureRef, { class: 'modal-lg' });
    }

    public showCustomerPrivateData(id: string) {
        this.paymentInfoId = id;
        this.modalRef = this.modalService.show(this.cutomerPrivateDataRef, { class: 'modal-xs' });
    }

    public showPrivateDataConfirmation() {
        if (this.showPrivateDataPass !== '928112') {
            this.decline();
            return;
        }

        this.deliveriesService
            .fetchPaymentDetails(this.paymentInfoId, this.delivery.id)
            .pipe(tap((paymentDetails: any) => (this.paymentDetails = paymentDetails)))
            .subscribe(() => {
                this.showPrivateDataToggle = true;
            });
    }

    public download(signature) {
        var saveImg = document.createElement('a');
        saveImg.href = signature.changingThisBreaksApplicationSecurity;
        saveImg.download = `signature_${this.delivery.orderId}.png`;
        document.body.appendChild(saveImg);
        saveImg.click();
        document.body.removeChild(saveImg);
    }

    private decline(): void {
        this.showPrivateDataToggle = false;
        this.showPrivateDataPass = '';
        this.modalRef.hide();
    }
}
