import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { RegionService } from '../services/region.service';
import { QueryParams } from '@interfaces/query-params.interface';
import { ORDER } from '@enums/enum';

@Injectable()
export class RegionsResolver implements Resolve<any> {
    constructor(private regionService: RegionService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        const params: any = {
            size: 1000,
            page: 0,
            order: ORDER.ASC,
            sort: 'createdAt',
            partnerId: route.params['partnerId']
        }
        return this.regionService.findAllPageableResponse(params);
    }
}
