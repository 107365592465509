import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { Apm } from '../../../entities/apm';
import { FormArray, FormGroup, FormBuilder, AbstractControl, FormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute } from '@angular/router';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { ApmService } from '../../../services/apm.service';
import { Region } from '../../../entities/region';
import { RegionService } from '../../../services/region.service';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { Collection } from '@interfaces/collection.interface';
import { remove } from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { NavService } from '@services/nav.sevice';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-apm-assigned-regions',
  templateUrl: './apm-assigned-regions.component.html',
  styleUrls: ['./apm-assigned-regions.component.scss']
})
export class ApmAssignedRegionsComponent implements OnInit, OnDestroy {

  public apm: Apm;
  public assigndRegions: Region[];


  public regionsForm: FormArray;

  public rawRegions: any[] = [];

  public loader: boolean = true;
  public submitted: boolean = false;

  public form: FormGroup;

  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  private removedRegionId: number;
  private removedRegion: Region;

  public destroy$ = new Subject<boolean>();
  
  constructor(
    private navigationRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private apmService: ApmService,
    private modalService: BsModalService,
    private toastrProviderService: ToastrProviderService,
    private translateService: TranslateService,
    private regionService: RegionService,
    private navService: NavService
  ) { }

  public ngOnInit() {
    this.apm = (this.navigationRoute.snapshot.data as {apm: any}).apm as Apm;
    this.assigndRegions = (this.navigationRoute.snapshot.data as {assigndRegions: any}).assigndRegions as Region[];

 
    this.initForm();
    this.prepareRegions();
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      regions: this.formBuilder.array([])
    })

    this.regionsForm = <FormArray>this.form.controls['regions'];
    if (this.assigndRegions.length) {
      this.assigndRegions.forEach((item) => this.regionsForm.push(this.patchValues(item)));
    }

    this.regionsForm.push(this.patchValues());
    
  }

  public prepareRegions() {
    this.apmService.getAvailableRegions(this.apm.id).pipe(
      takeUntil(this.destroy$),
      map((a: Collection<Region>) => a.content),
      map((regions: Region[]) => {
        regions.map(region => {
          
          const selectedARegions: Region | undefined = this.assigndRegions.find((r: Region) => region.id === r.id);

          if (selectedARegions === undefined){
            this.rawRegions.push({
              id: region.id, 
              name: region.name,
              code: region.code,
              status: region.status,
              partnerId: region.partnerId
            });
          }
        })

        return this.rawRegions;
      })
    ).subscribe(a => console.log(a));

    this.loader = false;
  }

  private patchValues(item?: Region): AbstractControl {
    return this.formBuilder.group({
      id: [{value: item ? item.id : null, disabled: true }],
      name: [{value: item ? item.name : null, disabled: true }],
      code: [{value: item ? item.code : null, disabled: true }],
      partnerId: [{value: item ? item.partnerId : null, disabled: true }],
      status: [{value: item ? this.translateService.instant(`RegionStatus.${item.status}`) : null, disabled: true }],
    })
  }

  public selectEvent(item) {
    const region: Region = this.rawRegions.find((r: Region) => r.id === item['id']);
    const num: number = this.regionsForm.controls.length;

    this.regionsForm.controls[num - 1].setValue({
      id: region.id,
      name: region.name,
      code: region.code,
      partnerId: region.partnerId,
      status: this.translateService.instant(`RegionStatus.${region.status}`)
    }, {onlySelf: true});

    remove(this.rawRegions, (r: Region) => r.id === region.id);

    this.save(region.partnerId, region.id, this.apm.id);

    this.addStep();
  }

  public save(partnerId: string, regionId: string, apmId: string) {
    this.regionService.assignApmsToRegion(partnerId, regionId, [apmId])
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.submitted = false;
        throw (error);
      })
    )
    .subscribe(
      (region: any) => {
        console.log(region);
        this.toastrProviderService.showSuccess(this.translateService.instant('The region has been assigned to apm!'));
        this.submitted = false;
      },
      error => {
        console.log(`Error occurred, please try again!`, error);
        this.submitted = false;
      },
      () => {
        this.submitted = false;
      }
    );

  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }

  public addStep(): void {
    this.regionsForm.push(this.patchValues());
  }

  public isSetValue(index: number): boolean {
    return ((this.regionsForm.controls[index] as FormControl).value['name']);
  }

  public removeStep(i): void {
    this.removedRegion = (<FormArray>this.form.controls['regions']).controls[i].value;
    this.removedRegionId = i;
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.regionService.unassignApmFromRegion(this.removedRegion.partnerId, this.removedRegion.id, this.apm.id);
  }
 
  public confirm(): void {
    this.confirmObservable.subscribe((test) => {
      this.modalRef.hide();
      (<FormArray>this.form.controls['regions']).removeAt(this.removedRegionId);
      this.prepareRegions();
      this.toastrProviderService.showSuccess(this.translateService.instant('The region has been unassigned!'));
      this.confirmObservable = null;
    });
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
