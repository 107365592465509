<layout-default>
    <div header>
        <div toolbar>
            <h4 *ngIf="mode === 'CREATE'">
                <i-feather name="clock"></i-feather> {{'Create timeslot' | translate}}
            </h4>
            <h4 *ngIf="mode === 'UPDATE'">
                <i-feather name="clock"></i-feather> {{'Edit timeslot' | translate}}: {{timeslot.name}}
            </h4>
        </div>

        <hr />
    </div>
    
    <div content>
        <div *ngIf="!loader" class="content bg-white">

            <div class="card-content col-8 offset-2">

                <form [formGroup]="form">

                    <div id="timeslot-edit-form-{{item}}" *ngFor="let item of regularFormItems()" class="form-group row">
                        
                        <label [for]="item" class="col-2">
                            <span>{{item.toUpperCase() | translate}}:&nbsp;</span>
                            <span *ngIf="form.get(item) | isRequiredControlPipe">*</span>&nbsp;
                            <i-feather *ngIf="tooltipLabel(item)" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(item)"
                                [style.width.px]="13" name="info"></i-feather>
                        </label>

                        <div class="col-10">
                            <input [formControlName]="item" class="form-control" />
                            <div *ngIf="form.get(item).hasError('required') && form.get(item).touched" class="error-color">
                                {{ "The field is required" | translate }}!
                            </div>
                            <div *ngIf="form.get(item).hasError('maxlength') && form.get(item).touched" class="error-color">
                                {{ "The field value is too long" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div id="timeslot-edit-form-active" class="form-group row">
                        
                        <label for="active" class="col-2">
                            <span>{{'active'.toUpperCase() | translate}}:&nbsp;</span>
                            <span *ngIf="form.get('active') | isRequiredControlPipe">*</span>&nbsp;
                            <i-feather *ngIf="tooltipLabel('active')" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('active')"
                                [style.width.px]="13" name="info"></i-feather>
                        </label>

                        <div class="col-10">
                            <select class="custom-select" formControlName="active">
                                <option [value]="true">{{'Yes' | translate}}</option>
                                <option [value]="false">{{'No' | translate}}</option>
                            </select>
                            <div *ngIf="form.get('active').hasError('required') && form.get('active').touched" class="error-color">
                                {{ "The field is required" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div id="timeslot-edit-form-timeslot" formGroupName="timeslot">
                        <label class="d-block col-10 offset-2 tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-b-20">{{groupName | translate}}</label>
                        <div *ngFor="let item of ['fromTime', 'toTime']" class="form-group row">
                            <label class="col-2" [for]="item">{{item | translate}}:&nbsp;<span *ngIf="form.get('timeslot').get(item) | isRequiredControlPipe">*</span></label>
                            <div class="col-10">
  
                                <input  [formControlName]="item" [dropSpecialCharacters]="false" [validation]="true" mask="Hh:m0" class="form-control" placeholder="{{'Please fill time in format' | translate}} (HH:mm)"/>
                                <div *ngIf="form.get('timeslot').get(item)?.hasError('required') && form.get('timeslot').get(item)?.touched" class="error-color">
                                    {{ "The field is required" | translate }}!
                                </div>
                                <div *ngIf="form.get('timeslot').get(item)?.errors?.minlength && form.get('timeslot').get(item)?.touched" class="error-color">
                                    {{ "Invalid value! Hour should have 4 numbers For example: 08:00"  | translate }}.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="timeslot-edit-form-dayOffset" class="form-group row">
                        <label class="col-2" for="dayOffset">{{'Day offset' | translate}}:&nbsp;<span *ngIf="form.get('daysOffset') | isRequiredControlPipe">*</span></label>
                        <div class="col-10">
                            <select class="custom-select" formControlName="daysOffset">
                                <option *ngFor="let i of [0,1,2,3,4,5,6,7]" [value]="i">{{i}}
                                    <span [ngSwitch]="i">
                                        <span *ngSwitchCase="0">{{'days' | translate}}</span>
                                        <span *ngSwitchCase="1">{{'day' | translate}}</span>
                                        <span *ngSwitchDefault>{{'days' | translate}}</span>
                                    </span>
                                </option>
                            </select>
                            <div *ngIf="form.get('daysOffset').hasError('required') && form.get('daysOffset').touched" class="error-color">
                                {{ "The field is required" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div id="timeslot-edit-form-pickupSlot" formGroupName="pickupSlot">
                        <label class="d-block col-10 offset-2 tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-b-20">{{'Pickup time information' | translate}}</label>

                        <label class="d-block col-10 offset-2 tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-b-20">{{groupName | translate}}</label>
                        <div *ngFor="let item of ['fromTime', 'toTime']" class="form-group row">
                            <label class="col-2" [for]="item">{{item | translate}}:&nbsp;<span *ngIf="form.get('pickupSlot').get(item) | isRequiredControlPipe">*</span></label>
                            <div class="col-10">
    
                                <input  [formControlName]="item" [dropSpecialCharacters]="false" [validation]="false" mask="Hh:m0" class="form-control" placeholder="{{'Please fill time in format' | translate}} (HH:mm)"/>
                                <div *ngIf="form.get('pickupSlot').get(item)?.hasError('required') && form.get('pickupSlot').get(item)?.touched" class="error-color">
                                    {{ "The field is required" | translate }}!
                                </div>
                                <div *ngIf="form.get('pickupSlot').get(item)?.errors?.minlength && form.get('pickupSlot').get(item)?.touched" class="error-color">
                                    {{ "Invalid value! Hour should have 4 numbers For example: 08:00"  | translate }}.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="timeslot-edit-form-dayOffset-pickup" class="form-group row">
                        <label class="col-2" for="dayOffset">{{'Day offset' | translate}}:&nbsp;<span *ngIf="form.get('pickupOffset').get(item) | isRequiredControlPipe">*</span></label>
                        <div class="col-10">
                            <select class="custom-select" formControlName="pickupOffset">
                                <option *ngFor="let i of [0,1,2,3,4,5,6,7]" [value]="i">{{i}}
                                    <span [ngSwitch]="i">
                                        <span *ngSwitchCase="0">{{'days' | translate}}</span>
                                        <span *ngSwitchCase="1">{{'day' | translate}}</span>
                                        <span *ngSwitchDefault>{{'days' | translate}}</span>
                                    </span>
                                </option>
                            </select>
                            <div *ngIf="form.get('pickupOffset').hasError('required') && form.get('pickupOffset').touched" class="error-color">
                                {{ "The field is required" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div id="timeslot-edit-form-doWFilter" formGroupName="doWFilter">
                        <label class="d-block col-10 offset-2 tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-t-20">{{'On what weekdays timeslots is available?' | translate}}</label>
                        <div class="offset-2 col-10 d-flex flex-wrap justify-content-between">
                            <div *ngFor="let day of weekDaysControls()">
                                <div class="custom-control custom-checkbox pd-20">
                                    <input [formControlName]="day" [checked]="form.get('doWFilter').get(day).value" type="checkbox" class="custom-control-input" id="customCheck{{day}}">
                                    <label class="custom-control-label justify-content-start" for="customCheck{{day}}">{{'Weekdays.'+day | translate}}</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="formErrorsMessages && formErrorsMessages.length" class="form-group row pd-t-20">
                        <div class="col-10 offset-2">
                            <p class="error-color">{{ 'Something has wrong! Please correct form' | translate }}:</p>
                            <ul>
                                <li *ngFor="let error of formErrorsMessages" class="error-color">
                                    <!-- <span *ngIf="error.value">{{ 'Field' | translate }}</span>
                                    <strong>{{ error.field | translate }}</strong> -->
                                    <span>{{ 'errors.'+error.value | translate }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                    <div class="form-group row justify-content-end">
                        <div class="col-2">
                            <button id="timeslot-edit-form-btn-save" (click)="onSubmit()" [disabled]="!form.valid || submitted"
                                class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01 w-100">

                                <span *ngIf="!submitted">{{ "Save" | translate }}</span>

                                <ng-container *ngIf="submitted">
                                    <div class="load-spinner text-center">
                                        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                    </div>
                                </ng-container>

                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</layout-default>

<!-- {{'fromTime' | translate }} -->
<!-- {{'toTime' | translate }} -->
<!-- {{'timeslot' | translate }} -->
<!-- {{'customerTimeWindow' | translate }} -->
<!-- {{'putInTimeWindow' | translate }} -->
<!-- {{'purgeTimeWindow' | translate }} -->

<!-- {{'tooltips.timeslot.labels.code' | translate -->
<!-- {{'tooltips.timeslot.labels.active' | translate -->
