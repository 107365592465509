import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ConfigValueService } from '../services/config-value.service';

@Injectable()
export class ConfigValuesResolver implements Resolve<any> {
    constructor(private configValueService: ConfigValueService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        return this.configValueService.findAll();
    }
}
