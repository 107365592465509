<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="database"></i-feather> {{'Config values' | translate}}
            </h4>
        </div>

        <div toolbar>
            <button id="btn-configvalue-create" class="btn btn-sm btn-uppercase btn-white"
                [routerLink]="['/config-value/create']">
                <i-feather [style.height.px]="23" [style.width.px]="13" class="mg-r-10" name="plus"></i-feather> {{ 'Add config value' | translate }}
            </button>
            <button id="btn-show-configkey" class="btn btn-sm btn-uppercase btn-white"
                [routerLink]="['/config-key/list']">
                <i-feather [style.height.px]="23" [style.width.px]="13" class="mg-r-10" name="plus"></i-feather> {{ 'Show config keys' | translate }}
            </button>
            <div id="configvalue-search" class="filter-container">
                <div class="phrase-filter">
                    <input type='text' class="list-filter form-control" placeholder="{{'type to search' | translate}}" (keyup)='applyFiltering($event)'/>
                </div>
            </div>
        </div>
        <hr />
    </div>
    
    <div content>
        <div *ngIf="!loader">
            <ngx-datatable 
                id="config-value" 
                class="bootstrap" 
                [rows]="configValues" 
                [columns]="columns" 
                [columnMode]="'flex'"
                [headerHeight]="50" 
                [footerHeight]="50" 
                [messages]="tableMessages" 
                [rowClass]="rowClass"
                [rowHeight]="'auto'">
                <ng-container *ngFor="let col of columns">
                    <ngx-datatable-column [flexGrow]="col.flexGrow" [prop]="col.prop">
                        <ng-template let-column="column" ngx-datatable-header-template class="header" let-sort="sortFn">
                            <b>{{ col.name | translate }}</b>
                        </ng-template>
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>

                            <div [ngSwitch]="col.prop">
                                <div *ngSwitchCase="'options'">
                                    <div appCheckFeaturesConfig [featureName]="[Permissions.Config_default_edit]"  class="dropdown" dropdown>
                                        <button
                                            class="btn btn-outline-secondary btn-xs dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton">
                                            {{ 'Select' | translate }}
                                        </button>
    
                                        <div class="dropdown-menu dropdown-menu-right div-main-actions">
                                            <a 
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-configvalue-edit" 
                                                (click)="goToDetails(row)">
                                                    {{'Edit' | translate }}
                                            </a>
    
                                            <!-- <a 
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                                (click)="removeConfig(row.id)">
                                                    {{'Remove' | translate }}
                                            </a> -->
    
                                        </div>
                                    </div>
                                </div>
                                <div id="scope" *ngSwitchCase="'scope'">{{ 'SCOPE.'+value | translate }}</div>
                                <div id="code" *ngSwitchCase="'configKey.code'">
                                    <span>{{ 'ConfigKey.'+value | translate}} &nbsp;</span>
                                    <i-feather *ngIf="tooltipLabel(value)" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(value)"
                                        [style.width.px]="13" name="info"></i-feather>      
                                </div>
                                <div id="value" *ngSwitchCase="'value'">
                                    <span [ngSwitch]="row.configKey.type">
                                        <span *ngSwitchCase="configKeyTypes.String">

                                            <ng-container [ngSwitch]="row.configKey.code">

                                                <span *ngSwitchCase="'allowedLockerTypes'">
                                                    <option *ngFor="let option of value.split('')">
                                                        <ng-container [ngSwitch]="option.toUpperCase()">
                                                            <ng-container *ngSwitchCase="compartmentType.Freezer">{{'CompartmentType.Freezer' | translate }}</ng-container>
                                                            <ng-container *ngSwitchCase="compartmentType.Cooler">{{'CompartmentType.Cooler' | translate }}</ng-container>
                                                            <ng-container *ngSwitchCase="compartmentType.Ambient">{{'CompartmentType.Ambient' | translate}}</ng-container>
                                                            <ng-container *ngSwitchCase="compartmentType.Other">{{'CompartmentType.Other' | translate }}</ng-container>
                                                        </ng-container>
                                                    </option>
                                                </span>

                                                <span *ngSwitchCase="'driverSupportTelephone'">
                                                    {{value}}
                                                </span>

                                                <span *ngSwitchDefault>
                                                    {{value | translate}}
                                                </span>
                                            
                                            </ng-container>

                                        </span>
                                        <span *ngSwitchCase="configKeyTypes.Integer">
                                            {{value | translate}}
                                        </span>
                                        <span *ngSwitchCase="configKeyTypes.Boolean">
                                            {{'ConfigValue.'+value | translate}}
                                        </span>
                                        <span *ngSwitchCase="configKeyTypes.Duration">
                                            {{value | translate}}
                                        </span>
                                        <span *ngSwitchDefault>
                                            {{value | translate}}
                                        </span>
                                    </span>
                                </div>
                                <div id="type" *ngSwitchCase="'configKey.type'">{{'ConfigKeyType.'+value | translate}}</div>

                            </div>

                        </ng-template>
                    </ngx-datatable-column>
                </ng-container>
            </ngx-datatable>
        </div>

        <div *ngIf="loader" class="load-spinner text-center">
            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}
        </div>

    </div>

</layout-default>

<ng-template #confirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p>{{'Do you confirm remove the config' | translate }}?</p>
        <button type="button" class="btn btn-brand-01" (click)="confirm()">
            {{'Yes, remove' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>

<!-- {{'SCOPE.32' | translate}} -->
<!-- {{'SCOPE.31' | translate}} -->
<!-- {{'SCOPE.24' | translate}} -->
<!-- {{'SCOPE.16' | translate}} -->
<!-- {{'SCOPE.15' | translate}} -->
<!-- {{'SCOPE.12' | translate}} -->
<!-- {{'SCOPE.8' | translate}} -->
<!-- {{'SCOPE.4' | translate}} -->
<!-- {{'SCOPE.3' | translate}} -->
<!-- {{'SCOPE.2' | translate}} -->
<!-- {{'SCOPE.1' | translate}} -->

<!-- {{'ConfigKey.alwaysAllowBoxMerging' | translate}} -->
<!-- {{'ConfigKey.allowBoxMergingExcessTotalBoxes' | translate}} -->
<!-- {{'ConfigKey.allowBoxMergingExcessChilledBoxes' | translate}} -->
<!-- {{'ConfigKey.driverSupportTelephone' | translate}} -->

<!-- {{'ConfigKey.importantCustomers.notificationTemplateId' | translate}} -->
<!-- {{'ConfigKey.importantCustomers.list' | translate}} -->
<!-- {{'ConfigKey.importantCustomers.emailsSending' | translate}} -->
<!-- {{'ConfigKey.importantCustomers.emailTemplateId' | translate}} -->

<!-- {{'tooltips.configkeys.labels.importantCustomers.notificationTemplateId' | translate}} -->
<!-- {{'tooltips.configkeys.labels.importantCustomers.list' | translate}} -->
<!-- {{'tooltips.configkeys.labels.importantCustomers.emailsSending' | translate}} -->
<!-- {{'tooltips.configkeys.labels.importantCustomers.emailTemplateId' | translate}} -->



