
<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="database"></i-feather> {{'Config values' | translate}}
            </h4>
        </div>

        <div toolbar>
            <div id="configvalue-search" class="filter-container">
                <div class="phrase-filter">
                    <input type='text' class="list-filter form-control" placeholder="{{'type to search' | translate}}" (keyup)='applyFiltering($event)'/>
                </div>
            </div>
        </div>
        <hr />
    </div>
    
    <div content>
        <ng-container *ngIf="!loader">
            <div *ngIf="(configValues$ | async) as configValues" >
                <ngx-datatable 
                    id="config-value" 
                    class="bootstrap" 
                    [rows]="configValuesCollection" 
                    [columns]="columns" 
                    [columnMode]="'flex'"
                    [headerHeight]="50" 
                    [footerHeight]="50" 
                    [messages]="tableMessages" 
                    [externalPaging]="false"
                    [rowClass]="rowClass"
                    [rowHeight]="'auto'">
                    <ng-container *ngFor="let col of columns">
                        <ngx-datatable-column [flexGrow]="col.flexGrow" [prop]="col.prop">
                            <ng-template let-column="column" ngx-datatable-header-template class="header" let-sort="sortFn">
                                <b (click)="sort()">{{ col.name | translate }}</b>
                            </ng-template>
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>


                                <div [ngSwitch]="col.prop">
                                    <div *ngSwitchCase="'options'">
                                        <div *ngIf="canManage(row.configKey.scopes)" class="dropdown" dropdown>
                                            <button
                                                class="btn btn-outline-secondary btn-xs dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton">
                                                {{ 'Select' | translate }}
                                            </button>
    
                                            <div class="dropdown-menu dropdown-menu-right div-main-actions">
                                                <a 
                                                    class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-configvalue-edit" 
                                                    (click)="goToDetails(row)">
                                                        {{'Edit' | translate }}
                                                </a>
    
                                                <a 
                                                    class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-configvalue-return-to-default" 
                                                    *ngIf="!row.isDefault && row.scope == queryParams.scope"
                                                    (click)="removeConfig(row.id)">
                                                        {{'Return to default' | translate }}
                                                </a>
    
                                            </div>
                                        </div>
                                    </div>
                                    <div id="code" *ngSwitchCase="'configKey.code'">
                                        <span>{{ 'ConfigKey.'+value | translate}}&nbsp;</span>
                                        <i-feather *ngIf="tooltipLabel(value)" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(value)"
                                            [style.width.px]="13" name="info"></i-feather>      
                                    </div>

                                    <div id="scope" *ngSwitchCase="'scope'">{{ 'SCOPE.'+value | translate }}</div>
                                    <div id="type" *ngSwitchCase="'configKey.type'">{{'ConfigKeyType.'+value | translate}}</div>
                                    <div id="value" *ngSwitchCase="'value'">
                                        <span [ngSwitch]="row.configKey.type">
                                            <span *ngSwitchCase="configKeyTypes.String">
                                                <span *ngIf="row.configKey.code === 'allowedLockerTypes'">
                                                    <option *ngFor="let option of value.split('')">
                                                        <ng-container [ngSwitch]="option.toUpperCase()">
                                                            <ng-container *ngSwitchCase="compartmentType.Freezer">{{'CompartmentType.Freezer' | translate }}</ng-container>
                                                            <ng-container *ngSwitchCase="compartmentType.Cooler">{{'CompartmentType.Cooler' | translate }}</ng-container>
                                                            <ng-container *ngSwitchCase="compartmentType.Ambient">{{'CompartmentType.Ambient' | translate}}</ng-container>
                                                            <ng-container *ngSwitchCase="compartmentType.Other">{{'CompartmentType.Other' | translate }}</ng-container>
                                                        </ng-container>
                                                    </option>
                                                </span>
                                                <span *ngIf="row.configKey.code !== 'allowedLockerTypes'">{{'ConfigValue.'+value | translate}}</span>
                                            </span>
                                            <span *ngSwitchCase="configKeyTypes.Integer">
                                                {{value | translate}}
                                            </span>
                                            <span *ngSwitchCase="configKeyTypes.Boolean">
                                                {{'ConfigValue.'+value | translate}}
                                            </span>
                                            <span *ngSwitchCase="configKeyTypes.Duration">
                                                {{value | translate}}
                                            </span>
                                            <span *ngSwitchDefault>
                                                {{value | translate}}
                                            </span>
                                        </span>
                                    </div>

                                    <div id="is-default" *ngSwitchCase="'isDefault'">
                                        <div class="custom-control custom-checkbox w-50 text-center">
                                            <input [checked]="value" type="checkbox" class="custom-control-input" id="active" disabled>
                                            <label class="custom-control-label justify-content-start" for="active">&nbsp;</label>
                                        </div>
                                    </div>
                                    <div *ngSwitchDefault>{{value | translate}}</div>
                                </div>

                            </ng-template>
                        </ngx-datatable-column>
                    </ng-container>
                </ngx-datatable>
            </div>
        </ng-container>

        <div *ngIf="loader" class="load-spinner text-center">
            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}
        </div>

    </div>

</layout-default>

<ng-template #confirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p>{{'Do you confirm remove the config' | translate }}?</p>
        <button type="button" class="btn btn-brand-01" (click)="confirm()">
            {{'Yes, remove' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>

<!-- {{'ConfigKeyType.STRING' | translate}} -->
<!-- {{'ConfigKeyType.BOOLEAN' | translate}} -->
<!-- {{'ConfigKeyType.DURATION' | translate}} -->
<!-- {{'ConfigKeyType.INTEGER' | translate}} -->

<!-- {{'ConfigValue.ALL' | translate}} -->
<!-- {{'ConfigValue.MIXED' | translate}} -->
<!-- {{'ConfigValue.OWN' | translate}} -->
<!-- {{'ConfigValue.INPOST' | translate}} -->
<!-- {{'ConfigValue.SUBSCRIPTION' | translate}} -->
<!-- {{'ConfigValue.false' | translate}} -->
<!-- {{'ConfigValue.true' | translate}} -->
<!-- {{'ConfigValue.ENABLED' | translate}} -->
<!-- {{'ConfigValue.DISABLED' | translate}} -->


<!-- {{'tooltips.configkeys.labels.defaultLogistic' | translate }} -->
<!-- {{'tooltips.configkeys.labels.canStartReserveBefore' | translate }} -->
<!-- {{'tooltips.configkeys.labels.canStartReserveTo' | translate }} -->
<!-- {{'tooltips.configkeys.labels.allowedPools' | translate }} -->
<!-- {{'tooltips.configkeys.labels.reservationCutoff' | translate }} -->
<!-- {{'tooltips.configkeys.labels.reservationCutoffSameDay' | translate }} -->
<!-- {{'tooltips.configkeys.labels.isManifestRequired' | translate }} -->
<!-- {{'tooltips.configkeys.labels.customerReservationExpiration' | translate }} -->
<!-- {{'tooltips.configkeys.labels.timeReminded' | translate }} -->
<!-- {{'tooltips.configkeys.labels.putinBeforeCustomerSlot' | translate }} -->
<!-- {{'tooltips.configkeys.labels.putinAfterCustomerSlot' | translate }} -->
<!-- {{'tooltips.configkeys.labels.purgeTime' | translate }} -->
<!-- {{'tooltips.configkeys.labels.courierSlackTime' | translate }} -->
<!-- {{'tooltips.configkeys.labels.incompleteDeliveryTTL' | translate }} -->
<!-- {{'tooltips.configkeys.labels.allowedLockerTypes' | translate }} -->
<!-- {{'tooltips.configkeys.labels.putinBeforeCustomerSlot ' | translate }} -->
<!-- {{'tooltips.configkeys.labels.putinAfterCustomerSlot ' | translate }} -->
<!-- {{'tooltips.configkeys.labels.putinAfterCustomerSlot ' | translate }} -->
<!-- {{'tooltips.configkeys.labels.alwaysAllowBoxMerging ' | translate }} -->
<!-- {{'tooltips.configkeys.labels.allowDtdDeliveries ' | translate }} -->


<!-- {{'ConfigKey.defaultLogistic' | translate}} -->
<!-- {{'ConfigKey.canStartReserveBefore' | translate}} -->
<!-- {{'ConfigKey.canStartReserveTo' | translate}} -->
<!-- {{'ConfigKey.allowedPools' | translate}} -->
<!-- {{'ConfigKey.reservationCutoff' | translate}} -->
<!-- {{'ConfigKey.reservationCutoffSameDay' | translate}} -->
<!-- {{'ConfigKey.isManifestRequired' | translate}} -->
<!-- {{'ConfigKey.customerReservationExpiration' | translate}} -->
<!-- {{'ConfigKey.timeReminded' | translate}} -->
<!-- {{'ConfigKey.putinBeforeCustomerSlot' | translate}} -->
<!-- {{'ConfigKey.putinAfterCustomerSlot' | translate}} -->
<!-- {{'ConfigKey.purgeTime' | translate}} -->
<!-- {{'ConfigKey.courierSlackTime' | translate}} -->
<!-- {{'ConfigKey.incompleteDeliveryTTL' | translate}} -->
<!-- {{'ConfigKey.allowedLockerTypes' | translate}} -->
<!-- {{'ConfigKey.putinBeforeCustomerSlot' | translate}} -->
<!-- {{'ConfigKey.putinAfterCustomerSlot' | translate}} -->
<!-- {{'ConfigKey.allowDtdDeliveries' | translate}} -->
<!-- {{'ConfigKey.allowRepeatedDeliveries' | translate}} -->