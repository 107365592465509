import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { CrudListComponent } from '@shared/components/cruds/crud-list.component';
import { ApmDelivery } from '../../../entities/apm-delivery';
import { ApmDeliveryService } from '../../../services/apm-delivery.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { PageableResponse } from '@entities/pagable-response';
import { tap } from 'rxjs/operators';
import { ApmDeliverySearchService } from '../../../services/apm-delivery-search.service';
import { ApmDeliveryStatus } from '../../../enums/apm-delivery-status.enum';
import { Subscription } from 'rxjs';
import { Partner } from '../../../entities/partner';
import { Permissions } from '@enums/permissions';

@Component({
  selector: 'app-apm-delivery-search',
  templateUrl: './apm-delivery-search.component.html',
  styleUrls: ['./apm-delivery-search.component.scss']
})
export class ApmDeliverySearchComponent extends CrudListComponent<ApmDelivery, string> implements OnInit, OnDestroy {

  // @ViewChild('mytable') public mytable: any;
  @ViewChild('table', {static: false}) public table: any;


  public columns = [
    { headerClass: 'extend', prop: 'extend', name: '', flexGrow: 1, sortable: false },
    { headerClass: 'select', prop: 'select', name: '', flexGrow: 1, sortable: true },
    { headerClass: 'reservationReference', prop: 'apmDelivery.reservationReference', name: this.translateService.instant('Reservation ref'), flexGrow: 3, sortable: true },
    { headerClass: 'reservationNumber', prop: 'apmDelivery.reservationNumber', name: this.translateService.instant('Reservation number'), flexGrow: 3, sortable: true },
    { headerClass: 'apm', prop: 'apmDelivery.apm', name: this.translateService.instant('Apm'), flexGrow: 4, sortable: true },
    { headerClass: 'lcount', prop: 'apmDelivery.lcount', name: this.translateService.instant('Capacity'), flexGrow: 2, sortable: true },
    { headerClass: 'reservationWindow', prop: 'apmDelivery.reservationWindow', name: this.translateService.instant('Reservation window'), flexGrow: 3, sortable: true },
    { headerClass: 'logisticType', prop: 'apmDelivery.logisticType', name: this.translateService.instant('Logistic type'), flexGrow: 3, sortable: false },
    { headerClass: 'status', prop: 'apmDelivery.status', name: this.translateService.instant('Status'), flexGrow: 3, sortable: true },
    { headerClass: 'options', prop: 'options', name: this.translateService.instant('Options'), flexGrow: 2, sortable: false },
  ];

  public apmDeliveryStatus = ApmDeliveryStatus;
  public locale = this.translateService.currentLang;
  private _convert: Subscription;
  private _getLastVersionOfApmDelivery: Subscription;
  public partnerService: Partner;
  public apmId: string;
  public slotTime: string;

  public selectedDeliveries: string[] = [];

  public Permissions = Permissions;

  constructor(
    protected readonly apmDeliverySearchService: ApmDeliverySearchService,
    protected readonly translateService: TranslateService,
    protected readonly navigationRoute: ActivatedRoute,
    protected readonly modalService: BsModalService,
    private readonly apmDeliveryService: ApmDeliveryService,
    protected readonly toastrProviderService: ToastrProviderService,
    private readonly router: Router
  ) {
    super(apmDeliverySearchService, translateService, navigationRoute, modalService, toastrProviderService)
  }

  public ngOnInit() {

    this.translateService.onLangChange.subscribe((lang: LangChangeEvent) => {
      this.locale = lang.lang;
    });
    
    this.list$ = this.apmDeliverySearchService.list$.pipe(
      tap((list: PageableResponse<ApmDelivery>) => this.rows = list.content)
    );

    const params = this.navigationRoute.snapshot.params;

    this.partnerService = (this.navigationRoute.snapshot.data as {partnerService: any}).partnerService;

    Object.assign(this.queryParams, {apmId: params.apmId, instant: params.slotTime});
    this.queryParams.sort = 'reservationNumber';

    this.apmId = this.navigationRoute.snapshot.params.apmId;
    this.slotTime = this.navigationRoute.snapshot.params.slotTime;

    this.apmDeliverySearchService.fetchAll(this.queryParams);
    this.modifiedRow = this.navigationRoute.snapshot.params.modifiedRow;

    this.rowClass = row => {
      return { 'row-modified': row.apmDelivery.id === this.modifiedRow };
    };

    this.loader = false;
  }

  public selectedRow(id: string) {
    const exist = this.selectedDeliveries.find(a => a === id);
    if (exist) {
      this.selectedDeliveries = this.selectedDeliveries.filter(a => a !== id);
    } else {
      this.selectedDeliveries.push(id);
    }

  }

  public convertToReplacementVehicle() {
    this.loader = true;
    this._convert = this.apmDeliveryService.convertToReplacementVehicle(this.selectedDeliveries, this.rows[0]['apmDelivery'].apm.id).subscribe(
      (result) => {
        this.toastrProviderService.showSuccess(this.translateService.instant('Deliveries has been moved to replacement van!'));
        this.apmDeliverySearchService.fetchAll(this.queryParams);
        this.selectedDeliveries = [];
      },
      () => {},
      () => this.loader = false
    )
  }

  public showDeliveryAuditLog(id: string): void {
    this._getLastVersionOfApmDelivery = this.apmDeliveryService.getLastVersionOfApmDelivery(id).pipe(
      tap((delivery: ApmDelivery) => {
        (delivery === null) 
          ? this.router.navigate(['/deliveries', 'auditlog', id, 'DELIVERY'])
          : this.router.navigate(['/deliveries', 'auditlog', delivery.id, 'DELIVERY']);
      })
    ).subscribe();
  }

  public toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    console.log(this.table);
    this.table.rowDetail.toggleExpandRow(row);
  }

  public onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  public ngOnDestroy() {
    if (this._convert) {
      this._convert.unsubscribe();
    }
    if (this._getLastVersionOfApmDelivery) {
      this._getLastVersionOfApmDelivery.unsubscribe();
    }
  }
}
