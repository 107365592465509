<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="database"></i-feather> {{'Asign apms to region' | translate}}
            </h4>
        </div>

        <hr />
    </div>

    <div content>

        <ng-container *ngIf="(apms$ | async)">
            <div *ngIf="!loader" class="content bg-white pd-40">

                <div class="row">
                    <div id="assign-apm-partner-info" class="col">
                        <label class="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1">{{'Partner' |
                            translate}}</label>
                        <p>{{'Name' | translate}}: <strong>{{partner?.name}}</strong>, {{'Code' | translate}}:
                            <strong>{{partner?.code}}</strong></p>
                    </div>
                    <div id="assign-apm-region-info" class="col">
                        <label class="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1">{{'Region' |
                            translate}}</label>
                        <p>{{'Name' | translate}}: <strong>{{region?.name}}</strong>, {{'Code' | translate}}:
                            <strong>{{region?.code}}</strong></p>
                    </div>
                </div>


                <form [formGroup]="form">

                    <ng-container formArrayName="apms">
                        <div *ngFor="let apm of form.controls.apms.controls; let index=index" class="form-horizontal">
                            <ng-container formGroupName="{{index}}">

                                <div id="assign-apm-show-availability"
                                    class="item mg-r-10 ng-tns-c28-0 ng-star-inserted">
                                    <label
                                        class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-6 d-block">&nbsp;</label>

                                    <button
                                        [routerLink]="['/partner', partner.id, 'region', 'apm-availability', apm.get('id').value]"
                                        [ngClass]="{'invisible': !isSetValue(index)}"
                                        class="btn btn-xs d-inline btn-white remove-driver pd-t-2 pd-b-2"><i-feather
                                            [style.height.px]="16" name="eye"></i-feather></button>

                                </div>

                                <div [ngClass]="{'invisible': !isSetValue(index)}" class="item mg-t-30 mg-r-10">
                                    {{index +1}}.
                                </div>

                                <div id="assign-apm-name" [style.flex-grow]="3"
                                    class="item mg-r-10 ng-tns-c28-0 ng-star-inserted">
                                    <label for="name"
                                        class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{'Name'
                                        | translate}}:</label>


                                    <ng-template [ngIf]="isSetValue(index)" [ngIfElse]="autocomplete" type="submit">
                                        <input formControlName="name" class="form-control" />
                                    </ng-template>

                                    <ng-template #autocomplete>
                                        <div class="ng-autocomplete">
                                            <ng-autocomplete [data]="rawApms" [searchKeyword]="'name'"
                                                (selected)='selectEvent($event)' (inputChanged)='onChangeSearch($event)'
                                                [itemTemplate]="itemTemplate" [debounceTime]="600"
                                                [placeHolder]="'Name' | translate"
                                                [notFoundTemplate]="notFoundTemplate">
                                            </ng-autocomplete>

                                            <ng-template #itemTemplate let-item>
                                                <a [innerHTML]="item.name"></a>
                                            </ng-template>

                                            <ng-template #notFoundTemplate let-notFound>
                                                <div [innerHTML]="notFound"></div>
                                            </ng-template>
                                        </div>
                                    </ng-template>
                                </div>

                                <div id="assign-apm-code" [style.flex-grow]="1"
                                    class="item mg-r-10 ng-tns-c28-0 ng-star-inserted">
                                    <label for="code"
                                        class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">Apm
                                        {{ 'code' | translate }}:</label>
                                    <input formControlName="code" class="form-control" />
                                </div>

                                <div id="assign-apm-status" [style.flex-grow]="1"
                                    class="item mg-r-10 ng-tns-c28-0 ng-star-inserted">
                                    <label for="status"
                                        class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{
                                        'Status' | translate }}:</label>
                                    <input formControlName="status" class="form-control" />
                                </div>

                                <div id="assign-apm-remove" class="item mg-r-10 ng-tns-c28-0 ng-star-inserted">
                                    <label
                                        class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-6 d-block">&nbsp;</label>
                                    <button [ngClass]="{'invisible': !isSetValue(index)}" (click)="removeStep(index)"
                                        class="btn btn-xs d-inline btn-white remove-driver pd-t-2 pd-b-2"><i-feather
                                            [style.height.px]="16" name="trash-2"></i-feather></button>
                                </div>



                            </ng-container>
                        </div>
                    </ng-container>



                    <div class="d-flex justify-content-end mg-t-20">
                        <div id="assign-btn-submit" class="col-2">
                            <button (click)="onSubmit()" [disabled]="submitted"
                                class="btn btn-sm btn-uppercase mg-l-5 btn-brand-01 w-100">

                                <span *ngIf="!submitted">{{ "Save" | translate }}</span>

                                <ng-container *ngIf="submitted">
                                    <div class="load-spinner text-center">
                                        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                    </div>
                                </ng-container>

                            </button>
                        </div>
                    </div>

                </form>

            </div>
        </ng-container>
    </div>
</layout-default>


<ng-template #confirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span
                aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p>{{'Do you confirm removal of the apm assignment' | translate }}?</p>
        <button type="button" class="btn btn-brand-01" (click)="confirm()">
            {{'Yes, remove' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>

<!-- {{'Cannot assign APMs to region as they are already assigned to other partner regions' | translate}} -->