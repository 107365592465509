import { Component } from '@angular/core';
import { Apm } from '../../../entities/apm';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';
import { DateRange, ORDER } from '@enums/enum';
import { ApmService } from '../../../services/apm.service';
import { Permissions } from '@enums/permissions';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '@services/nav.sevice';
import { QueryParams } from '@interfaces/query-params.interface';
import { environment } from '@environment';
import { Collection } from '@interfaces/collection.interface';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-apm-maintenance-list',
  templateUrl: './apm-maintenance-list.component.html',
  styleUrls: ['./apm-maintenance-list.component.scss']
})
export class ApmMaintenanceListComponent {
  
  public apmMaintenance$: Observable<Collection<Apm>>;
  public list: Collection<Apm>;
  public startDate;
  public endDate;
  public loader: boolean = true;
  public dateRange: DateRange;
  public Permissions = Permissions;
  public origin = null;
  protected queryParams: QueryParams = Object.assign({}, environment.pagination);

  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  public columns = [
    { prop: 'name', name: this.translateService.instant('Name'), flexGrow: 4, sortable: true },
    { prop: 'address', name: this.translateService.instant('Adress'), flexGrow: 4, sortable: true },
    { prop: 'code', name: this.translateService.instant('Code'), flexGrow: 3, sortable: true },
    { prop: 'type', name: this.translateService.instant('Type'), flexGrow: 3, sortable: true },
    { prop: 'lcount', name: this.translateService.instant('Capacity'), flexGrow: 2, sortable: true},
    { prop: 'status', name: this.translateService.instant('Status'), flexGrow: 2, sortable: true },
    { prop: 'options', name: this.translateService.instant('Options'), flexGrow: 2, sortable: false},

  ];

  constructor(
    private readonly navService: NavService,
    private readonly apmService: ApmService,
    private readonly translateService: TranslateService,
  ) { }

  private checkApmMaintenanceWindow(): void {

      this.apmService.getApmMaintenanceWindowListByApm(this.queryParams)
        .pipe(tap((response) => this.list = response)).subscribe();

      this.loader = false;

  }

  public changedDateRange(event): void {
    this.loader = true;
    this.queryParams.startDate = moment(event.dateStart).format('YYYY-MM-DD');
    this.queryParams.endDate = moment(event.dateEnd).format('YYYY-MM-DD');
    this.dateRange = event.dateRange;
    this.checkApmMaintenanceWindow();
  }

  public onSort(sortInfo) {
    console.log(sortInfo);
    const sort = sortInfo.sorts[0].prop.split('.');
    this.queryParams.sort = sort[sort.length - 1]
    this.queryParams.order = (sortInfo.sorts[0].dir === 'asc') ? ORDER.ASC : ORDER.DESC;
    this.checkApmMaintenanceWindow();
  }

  public setPage(pageInfo?) {
    this.queryParams.page = pageInfo.offset;
    this.checkApmMaintenanceWindow();
  }


  public changedOrigin(event): void {
    this.queryParams['origins'] = event;
    this.checkApmMaintenanceWindow();
  }

  public goToMaintenanceWindows(id: string): void {
    this.navService.goToPage(`/apm/maintenance/${id}`);
  }
}
