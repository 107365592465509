import { Driver } from '@drivers/entities/driver';
import { serializeType } from '@shared/functions/serializeType.function';
import { Type } from 'class-transformer';

export class AssignDriverToRegion {

    @Type(serializeType(Driver))
    public driver: Driver;

    public id: string;
    public regionId: string;
    public username: string;
    public version: number;
}
