import { Component, ViewChild, TemplateRef} from '@angular/core';
import { PageableResponse } from '@entities/pagable-response';
import { Observable } from 'rxjs';
import { QueryParams } from '@interfaces/query-params.interface';
import { environment } from '@environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { ORDER } from '@enums/enum';
import { CrudService } from '@services/crud.service';

@Component({
  template: '',
})
export class CrudListComponent<T, ID>  {

  public rows: T[];
  public list$: Observable<PageableResponse<T>>;
  public queryParams: QueryParams = Object.assign({}, environment.pagination);

  public modifiedRow: string;
  public rowClass;
  public loader: boolean = true;

  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  constructor(
    protected readonly crudService: CrudService<T, string>,
    protected readonly translateService: TranslateService,
    protected readonly navigationRoute: ActivatedRoute,
    protected readonly modalService: BsModalService,
    protected readonly toastrProviderService: ToastrProviderService,
    ) {}


  public setPage(pageInfo?) {
    this.queryParams.page = pageInfo.offset;
    this.crudService.fetchAll(this.queryParams);
  }

  public onSort(sortInfo) {
    this.queryParams.sort = sortInfo.sorts[0].prop
    this.queryParams.order = (sortInfo.sorts[0].dir === 'asc') ? ORDER.ASC : ORDER.DESC;
    this.crudService.fetchAll(this.queryParams);
  }

  public remove(id: string): void {
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.crudService.delete(id);
  }

  public confirm(): void {
    this.confirmObservable.subscribe((test) => {
      this.modalRef.hide();
      this.queryParams = environment.pagination;
      this.crudService.fetchAll(this.queryParams);      
      this.toastrProviderService.showSuccess(this.translateService.instant('Removal process has been done successfully'));
      this.confirmObservable = null;
    });
  }

  public applyFiltering(event) {
    const filterPhrase = event.target.value.toLowerCase();
    this.queryParams.page = 0;
    this.queryParams.filter = filterPhrase;
    this.crudService.fetchAll(this.queryParams);
  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }

}
