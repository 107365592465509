import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PaymentsService } from 'src/app/payments/services/payments.service';
import { ToastrProviderService } from '@services/toastr-provider.service';
import * as moment from 'moment';
import { LicensingDeliveries } from 'src/app/payments/entities/licensing-deliveries';
import { Observable } from 'rxjs';
import { Dictionary } from 'lodash';
import { CurrencyType, DeliveryStatus, Client } from '@enums/enum';
import { tap } from 'rxjs/operators';
import { sumBy } from 'lodash';
import { PartnerService } from 'projects/inpost/src/app/operations/services/partner.service';
import { map } from 'rxjs/operators';
import { environment } from '@environment';

@Component({
  selector: 'app-licensing-deliveries',
  templateUrl: './licensing-deliveries.component.html',
  styleUrls: ['./licensing-deliveries.component.scss']
})
export class LicensingDeliveriesComponent implements OnInit {

  public readonly deliveriesFee: number = 0.35;

  private from: string;
  private to: string;
  public deliveriesCount: number = 0;
  public deliveryStatus = Object.values(DeliveryStatus);
  public selectedStatusses = this.deliveryStatus;
  public $licencing: Observable<Dictionary<LicensingDeliveries[]>>;

  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  public columns = [
    { prop: 'status', name: this.translateService.instant('Status'), flexGrow: 2 },
    { prop: 'count', name: this.translateService.instant('Count'), flexGrow: 2 },
    { prop: 'depotCode', name: this.translateService.instant('Depot'), flexGrow: 2 },
    { prop: 'partnerCode', name: this.translateService.instant('Partner code'), flexGrow: 2 },
  ];

  public CurrencyType = CurrencyType;
  public partners$: Observable<any[]>;
  public selectedPartners = [];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly paymentService: PaymentsService,
    private readonly partnerService: PartnerService,
    private readonly toastrProviderService: ToastrProviderService) { }

  public ngOnInit() {
    this.partners$ = this.partnerService.findAll().pipe(map((response) => response['content']));

    if (environment.client === Client.INPOST) {
      this.selectedStatusses = this.selectedStatusses.filter((s) => s !== DeliveryStatus.FAILURE)
    }

    this.route.queryParams
      .subscribe(params => {
        if (params['from'] && params['to']) {
          this.from = moment(params['from']).format('YYYY-MM-DD');
          this.to = moment(params['to']).format('YYYY-MM-DD');
          this.fetechLicensingUsers();
        }
      });
  }

  private fetechLicensingUsers() {
    this.$licencing = this.paymentService.licensingDeliveries(this.from, this.to, this.selectedStatusses, this.selectedPartners).pipe(
      tap((l: Dictionary<LicensingDeliveries[]>) => {
        this.deliveriesCount = 0;
        Object.values(l).forEach((item: LicensingDeliveries[]) => this.deliveriesCount += sumBy(item, (e) => e.count));
      })
    );
  }

  public selectedStatus(status) {
    if (this.selectedStatusses.includes(status)) {
      this.selectedStatusses = this.selectedStatusses.filter(s => s !== status)
    } else {
      this.selectedStatusses.push(status)
    }

    this.fetechLicensingUsers();
  }

}
