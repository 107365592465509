import { Component, OnInit } from '@angular/core';
import { ConfigKey } from '../../entities/config-key';
import { CRUD_MODE, ConfigKeyType } from '@enums/enum';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '@services/nav.sevice';
import { plainToClass } from 'class-transformer';
import { ConfigKeyService } from '../../services/config-key.service';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';
import scopesConstants from './constants/scopes.constants';
import { Permissions } from '@enums/permissions';

@Component({
  selector: 'app-edit-config-key',
  templateUrl: './edit-config-key.component.html',
  styleUrls: ['./edit-config-key.component.scss']
})
export class EditConfigKeyComponent implements OnInit {

  public configKey: ConfigKey;
  public configKeys: ConfigKey[];
  public mode: CRUD_MODE;

  public form: FormGroup;
  public loader: boolean = true;
  public submitted: boolean = false;

  public scopesOptions: string = 'GLOBAL';
  public selectedScopes: string[] = [];

  public Permissions = Permissions;

  get configKeyType() {
    return _.keys(ConfigKeyType);
  }

  get rawConfigKeys() {
    if (this.configKeys) {
      return JSON.parse(JSON.stringify(this.configKeys));
    } else {
      return {};
    }
    
  }

  constructor(
    private navigationRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private configKeyService: ConfigKeyService,
    private toastrProviderService: ToastrProviderService,
    private translateService: TranslateService,
    private navService: NavService
  ) { }

  public ngOnInit() {

    const configTemp = (this.navigationRoute.snapshot.data as {configKey: any}).configKey;
    
    if (configTemp) {
      this.configKey = plainToClass(ConfigKey, (this.navigationRoute.snapshot.data as {configKey: any}).configKey as ConfigKey);
    }

    this.configKeys = plainToClass(ConfigKey, (this.navigationRoute.snapshot.data as {configKeys: any}).configKeys as ConfigKey[]);

    this.mode = (this.configKey) ? CRUD_MODE.UPDATE : CRUD_MODE.CREATE;
    this.initForm();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      parentId: [this.configKey ? this.configKey.parentId : null],
      code: [this.configKey ? this.configKey.code : null, Validators.required],
      id: [this.configKey ? this.configKey.id : null],
      scopes: [this.configKey ? this.configKey.scopes : scopesConstants.SCOPE_GLOBAL, Validators.required],
      type: [this.configKey ? this.configKey.type : null, Validators.required],
      version: [this.configKey ? this.configKey.version : null],
    });

    this.loader = false;
  }

  public selectedConfigKeyEvent(configKey: ConfigKey) {
    this.form.controls['parentId'].setValue(configKey.id);
  }

  public changedScope(value: string) {
    this.scopesOptions = value;
    if (this.scopesOptions === 'SCOPE_GLOBAL') {
      this.form.controls['scopes'].setValue(scopesConstants.SCOPE_GLOBAL);
    } else {
      this.form.controls['scopes'].setValue(0);
    }
  }

  public setScope(value: string) {
    
    if (this.selectedScopes.find(a => a === value) !== undefined) {
      this.selectedScopes = this.selectedScopes.filter(a => a !== value);
    } else {
      this.selectedScopes.push(value);
    }

    let scopeValue: number = 0;

    this.selectedScopes.forEach(a => {
      switch (a) {
        case 'SCOPE_GLOBAL' :
          scopeValue += scopesConstants.SCOPE_GLOBAL;
          break;
        case 'SCOPE_PARTNER' :
          scopeValue += scopesConstants.SCOPE_PARTNER;
          break;
        case 'SCOPE_REGION' :
          scopeValue += scopesConstants.SCOPE_REGION;
          break;
        case 'SCOPE_TIMESLOT' :
          scopeValue += scopesConstants.SCOPE_TIMESLOT;
          break;
        case 'SCOPE_RESERVATION' :
          scopeValue += scopesConstants.SCOPE_RESERVATION;
          break;
      }
    });
    this.form.controls['scopes'].setValue(scopeValue);
  }

  public onSubmit() {

    const raw = this.form.getRawValue();

    if (this.mode === CRUD_MODE.CREATE) {
      this.create(raw);
    } else {
      this.update(raw);
    }
  }

  private create(raw): void {
    this.configKeyService.save(raw)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (configKey: ConfigKey) => {
          console.log(configKey);
          this.toastrProviderService.showSuccess(this.translateService.instant('New config key has been created!'));
          this.navService.goToPage('/config-key/list', {modifiedRow: configKey.id});
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }

  private update(raw): void {
    this.configKeyService.update(raw['id'], raw)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (configKey: ConfigKey) => {
          console.log(configKey);
          this.toastrProviderService.showSuccess(this.translateService.instant('The config key has been updated!'));
          this.navService.goToPage('/config-key/list', {modifiedRow: configKey.id});
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }

}
