import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ToastrProviderService } from '@services/toastr-provider.service';
import * as _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

import { NavService } from '@services/nav.sevice';

import { UserPanel } from '../entities/user-panel';
import { UsersService } from '../services/users.service';
import { Roles } from '@enums/enum';
import { AuthenticationService } from 'src/app/authentication.service';
import { map } from 'rxjs/operators';
import { Permissions } from '@enums/permissions';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersComponent implements OnInit {
    @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
    @ViewChild('makeUserSysadmin', {static: true}) public makeUserSysadminModal: TemplateRef<any>;
    @ViewChild('removeRoleSysadminForUser', {static: true}) public removeRoleSysadminForUser: TemplateRef<any>;
    @ViewChild('confirmActionTmpl', {static: true}) public confirmActionTmpl: TemplateRef<any>;
    
    private readonly TAG = '[UsersComponent]';

    public columns = [
        {prop: 'id', name: 'Num', flexGrow: 1},
        {prop: 'username', name: this.translateService.instant('Username'), flexGrow: 4},
        {prop: 'firstName', name: this.translateService.instant('Firstname'), flexGrow: 2},
        {prop: 'lastName', name: this.translateService.instant('Lastname'), flexGrow: 2},
        {prop: 'dynamicRoles', name: this.translateService.instant('Dynamic roles'), flexGrow: 3},
        {prop: 'sysadmin', name: this.translateService.instant('Administrator'), flexGrow: 2},
        {prop: 'createdAt', name: this.translateService.instant('Created At'), flexGrow: 3},
        {prop: 'options', name: this.translateService.instant('Options'), flexGrow: 2},
    ];

    public users: UserPanel[] = [];
    public usersCollection: UserPanel[] = [];
    public locale: string = '';
    public loader: boolean = true;
    public modalRef: BsModalRef;
    public confirmObservable: Observable<any>;
    public Permissions = Permissions;

    constructor(
        private readonly userService: UsersService,
        private readonly translateService: TranslateService,
        private readonly navService: NavService,
        private readonly authService: AuthenticationService,
        private readonly modalService: BsModalService,
        private toastrProviderService: ToastrProviderService,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {
    }

    public ngOnInit() {
        this.translateService.onLangChange.subscribe((lang: LangChangeEvent) => {
            this.locale = lang.lang;
        });

        this.getUsers();
    }

    public getUsers() {
        this.loader = true;
        this.userService.getUsers().pipe(
            map((u: UserPanel[]) => {
                if (!this.authService.checkPrivileges([Permissions.ROLE_SYSADMIN])) {
                    this.columns = this.columns.filter(c => c.prop !== 'sysadmin');
                }
                return !this.authService.checkPrivileges([Permissions.ROLE_SYSADMIN]) 
                    ? u.filter((u: UserPanel) => !u.roles.includes(Roles.ROLE_SYSADMIN)) : u
            }),
        ).subscribe(
            (users: UserPanel[]) => {
                console.log(`${this.TAG} Received list of users`, users);
                this.users = users;
                this.usersCollection = users;
                this.loader = false;
                this.changeDetectorRef.markForCheck();
            },
            (error) => {
                console.log(`${this.TAG} Error during receiving list of users`, error);
                this.loader = false;
            });
    }

    public isColumnCreatedAt = (column: string) => column === 'createdAt';

    public isColumnOptions = (column: string) => column === 'options';

    public isColumnId = (column: string) => column === 'id';

    public isColumnDynamicRoles = (column: string) => column === 'dynamicRoles';

    public isColumnSysadmin = (column: string) => column === 'sysadmin';


    public goToDetails(event): void {
        if (event.type === 'click') {
            const path = (event.event.path) ? event.event.path : event.event.composedPath();
            const isOutside = _.find(path, (n) => !_.isUndefined(n.classList) && n.classList.value.includes('dropdown'));
            if (_.isUndefined(isOutside)) {
                this.navService.goToPage(`/users/${event.row.id}/edit`);
            }
        }
    }

    public applyFiltering(event) {
        const filterPhrase = event.target.value.toLowerCase();
        const temp = _.filter(this.usersCollection, (user: UserPanel) => {
            const searchIn: string = user.username;
            return searchIn.toLowerCase().indexOf(filterPhrase) !== -1 || !filterPhrase;
        });

        this.users = temp;
    }

    public delete(row: UserPanel) {
        this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
        this.confirmObservable = this.userService.delete(row.id);
    }

    public makeSysadmin(event: Event, user: UserPanel) {
        event.preventDefault();

        if (!this.authService.authorizedUser.isSysadminRole) {
            console.log('User have not right permissions to do that action.');
            return;
        }

        if (user.roles.find(i => i === Roles.ROLE_SYSADMIN) !== undefined) {
            this.modalRef = this.modalService.show(this.removeRoleSysadminForUser, {class: 'modal-sm'});
            this.confirmObservable = this.userService.removeRoleSysadminForUser(user.id);
        } else {
            this.modalRef = this.modalService.show(this.makeUserSysadminModal, {class: 'modal-sm'});
            this.confirmObservable = this.userService.makeUserSysadmin(user.id);
        }
    }

    sendTestSmsNotification(id: string) {
        this.modalRef = this.modalService.show(this.confirmActionTmpl, {class: 'modal-sm'});
        this.confirmObservable = this.userService.sendTestSmsNotification(id);
    }

    public confirm(action: string): void {
        let key: string;
        switch (action) {
            case 'removeUser':
                key = this.translateService.instant('The user has been removed');
                break;
            case 'addSysadminRole':
                key = this.translateService.instant('Sysadmin role has been added');
                break;
            case 'removeSysadminRole':
                key = this.translateService.instant('Sysadmin role has been removed');
                break;
            default : 
                key = this.translateService.instant('The action has been executed');
                break;
        }

        this.confirmObservable.subscribe(() => {
            this.modalRef.hide();
            this.getUsers();
            this.toastrProviderService.showSuccess(this.translateService.instant(key));
            this.confirmObservable = null;
        });
    }

    public decline(): void {
        this.confirmObservable = null;
        this.modalRef.hide();
    }
}
