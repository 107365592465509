<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="database"></i-feather> {{'Users for partner' | translate}}: {{partner.name}}
            </h4>
        </div>

        <div toolbar>
            <button id="partner-user-btn-create" class="btn btn-sm btn-uppercase btn-white" (click)="createPartnerUser()">
                <i-feather [style.height.px]="23" [style.width.px]="13" class="mg-r-10" name="plus"></i-feather> {{ 'Add user' | translate }}
            </button>
            <div id="partner-user-btn-search" class="filter-container">
                <div class="phrase-filter">
                    <input type='text' class="list-filter form-control" placeholder="{{'type to search' | translate}}" (keyup)='applyFiltering($event)'/>
                </div>
            </div>
        </div>
        <hr />
    </div>
    
    <div content>
        <div *ngIf="!loader">
            <ngx-datatable 
                id="partner-user-table" 
                class="bootstrap" 
                [rows]="users" 
                [columns]="columns" 
                [columnMode]="'flex'"
                [headerHeight]="50" 
                [footerHeight]="50" 
                [messages]="tableMessages" 
                [rowHeight]="'auto'" 
                [externalPaging]="false"
                [count]="pagable.totalElements" 
                [offset]="page" 
                [rowClass]="rowClass"
                [limit]="size" 
                [scrollbarH]="scrollBarHorizontal"
                (page)="setPage($event)">
                <ng-container *ngFor="let col of columns">
                    <ngx-datatable-column [flexGrow]="col.flexGrow" [prop]="col.prop">
                        <ng-template let-column="column" ngx-datatable-header-template class="header" let-sort="sortFn">
                            <b>{{ col.name | translate }}</b>
                        </ng-template>
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            <div id="partner-user-table-col-{{col.prop}}" *ngIf="isColumnToTranslate(col.prop)">{{ value | translate }}</div>
                            <div id="partner-user-table-col-{{col.prop}}" *ngIf="!isOptionsColumn(col.prop) && !isColumnToTranslate(col.prop) && !isColumnCanManageUsers(col.prop)">{{ value }}</div>

                            <div id="partner-user-table-col-{{col.prop}}" *ngIf="isColumnCanManageUsers(col.prop)">
                                <div class="custom-control custom-checkbox pd-b-10 w-50 text-center">
                                    <input [checked]="value" type="checkbox" class="custom-control-input" id="canManageUsers" disabled>
                                    <label class="custom-control-label justify-content-start" for="canManageUsers">&nbsp;</label>
                                </div>
                            </div>

                            <div *ngIf="isOptionsColumn(col.prop)">
                                <div *ngIf="row.canManageUsers" class="dropdown" dropdown>
                                    <button
                                        class="btn btn-outline-secondary btn-xs dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton">
                                        {{ 'Select' | translate }}
                                    </button>

                                    <div class="dropdown-menu dropdown-menu-right div-main-actions">
                                        <a 
                                            id="partner-user-btn-edit"
                                            *ngIf="row.canManageUsers"
                                            class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                            (click)="goToDetails(row.id)">
                                                {{'Edit' | translate }}
                                        </a>

                                        <!-- <a 
                                            *ngIf="!row.canManageUsers"
                                            class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                            (click)="removeUser(row.id)">
                                                {{'Remove' | translate }}
                                        </a> -->

                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                </ng-container>
            </ngx-datatable>
        </div>

        <div *ngIf="loader" class="load-spinner text-center">
            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}
        </div>

    </div>

</layout-default>

<ng-template #confirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p>{{'Do you confirm remove the user' | translate }}?</p>
        <button type="button" class="btn btn-brand-01" (click)="confirm()">
            {{'Yes, remove' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>
