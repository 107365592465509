import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getObjectValuesPipe',
  pure: true
})
export class GetObjectValuesPipe implements PipeTransform {
  public transform(object: Object): any {
    return Object.values(object);
  }
}
