import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Region } from '../../entities/region';
import { Partner } from '../../entities/partner';
import { ORDER } from '@enums/enum';
import { PageableResponse } from '@entities/pagable-response';
import { RegionService } from '../../services/region.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { NavService } from '@services/nav.sevice';
import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { ConfigScopes } from '../../enums/config-scopes';
import { QueryParams } from '@interfaces/query-params.interface';
import { environment } from '@environment';
import { tap } from 'rxjs/operators';
import { Permissions } from '@enums/permissions';

@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.scss']
})
export class RegionComponent implements OnInit {

  public regions: Region[];
  private regionsCollection: Region[];
  public list$: Observable<PageableResponse<Region>>;

  public partner: Partner;

  protected queryParams: QueryParams =Object.assign({}, environment.pagination);
  public pageInfo;
  public modifiedRow: string;
  public rowClass;
  public Permissions = Permissions;

  public loader: boolean = true;

  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  public columns = [
    { prop: 'name', name: this.translateService.instant('Name'), flexGrow: 3, sortable: true },
    { prop: 'code', name: this.translateService.instant('Code'), flexGrow: 2, sortable: true },
    { prop: 'locationAdditional.contactEmail', name: this.translateService.instant('Sender email'), flexGrow: 3, sortable: true },
    { prop: 'address', name: this.translateService.instant('Address'), flexGrow: 5, sortable: false },
    { prop: 'status', name: this.translateService.instant('Status'), flexGrow: 2, sortable: true },
    { prop: 'options', name: this.translateService.instant('Options'), flexGrow: 2, sortable: false },
  ];


  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  constructor(
    private regionService: RegionService,
    private navigationRoute: ActivatedRoute,
    private translateService: TranslateService,
    private modalService: BsModalService,
    private toastrProviderService: ToastrProviderService,
    private navService: NavService,
    private readonly router: Router
  ) { }

  public ngOnInit() {
    this.partner = plainToClass(Partner, (this.navigationRoute.snapshot.data as {partner: any}).partner as Partner);

    this.list$ = this.regionService.list$.pipe(
      tap((list: PageableResponse<Region>) => {
          this.regions = this.regionsCollection = list.content;
        })
      );

    this.regionService.fetchAll({...this.queryParams, partnerId: this.partner.id});

    this.modifiedRow = this.navigationRoute.snapshot.params.modifiedRow;

    this.rowClass = row => {
      return { 'row-modified': row.id === this.modifiedRow };
    };
    
    this.loader = false;
  }

  public goToDetails(id: string): void {
    this.navService.goToPage(`/partner/${this.partner.id}/region/edit/${id}`);
  }

  public goToRegions(id: string): void {
    this.navService.goToPage(`/partner/${id}/region/list`);
  }

  public goToDrivers(id: string): void {
    this.navService.goToPage(`/partner/${this.partner.id}/region/assign-driver/${id}`);
  }

  public goToTimeslots(id: string): void {
    this.navService.goToPage(`/partner/${this.partner.id}/region/${id}/timeslots/list`);
  }

  public goToCouriers(id: string): void {
    this.navService.goToPage(`/partner/${this.partner.id}/region/${id}/couriers/list`);
  }

  public goToApms(id: string): void {
    this.navService.goToPage(`/partner/${this.partner.id}/region/assign-apms/${id}`);
  }

  public addRegion(): void {
    this.navService.goToPage(`/partner/${this.partner.id}/region/create`);
  }

  public goToConfig(id: string): void {
    this.router.navigate([`/partner/${this.partner.id}/region/${id}/config-value/${ConfigScopes.Region}/list`]);
  }

  public removeRegion(id: string): void {
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.regionService.delete(id, {partnerId: this.partner.id});
  }

  public confirm(): void {
    this.confirmObservable.subscribe((test) => {
      this.modalRef.hide();
      this.queryParams.page = 0;
      this.regionService.fetchAll({...this.queryParams, partnerId: this.partner.id});
      this.toastrProviderService.showSuccess(this.translateService.instant('The region has been removed!'));
      this.confirmObservable = null;
    });
  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }

  public setPage(pageInfo?) {
    this.queryParams.page = pageInfo.offset;
    this.regionService.fetchAll({...this.queryParams, partnerId: this.partner.id});
  }

  public onSort(sortInfo) {
    this.queryParams.sort = sortInfo.sorts[0].prop
    this.queryParams.order = (sortInfo.sorts[0].dir === 'asc') ? ORDER.ASC : ORDER.DESC;
    this.regionService.fetchAll({...this.queryParams, partnerId: this.partner.id});
  }

  public applyFiltering(event) {
    const filterPhrase = event.target.value.toLowerCase();
    this.queryParams.page = 0;
    this.queryParams.filter = filterPhrase;
    this.regionService.fetchAll({...this.queryParams, partnerId: this.partner.id});
  }

}
