<layout-default>
  <div header>
    <div toolbar>
      <h4>
        <i-feather name="bar-chart-2"></i-feather> {{'Vehicle distance history' | translate}}
      </h4>
    </div>
    <div *ngIf="(depots$ | async)" toolbar>

      <date-range-picker class="flex-grow-1" [extendedOption]="false" (changedDateRange)="changedDateRange($event)"
        [defaultDateRange]="dateRange"></date-range-picker>

      <div class="label flex-grow-1">
        <label>{{ 'Depots' | translate }}:</label>
      </div>

      <div class="input-control" style="width:20%">
        <angular2-multiselect id="data-range-select-days-of-week" class="form-control" [data]="dropdownList"
          [(ngModel)]="selectedItems" [settings]="dropdownSettings">
        </angular2-multiselect>
      </div>

      <div class="label flex-grow-1">
        <label>{{ 'Route skip threhold' | translate }}:</label>
      </div>

      <div class="input-control">
        <select class="custom-select" [(ngModel)]="queryParams.shortRouteSkipThrehold">
          <option *ngFor="let a of [1,2,3,4,5,6,7,8,9,10]" [value]="a">{{a}}</option>
        </select>
      </div>


      <button type="button" class="btn btn-brand-01" (click)="loadData()">
        {{'Load data' | translate }}
      </button>

      <button class="btn btn-brand-01 mg-l-15" target="_blank" (click)="export();">
        <i class="fas fa-download"></i> {{'Export' | translate}}
      </button>

    </div>

    <hr />
  </div>

  <div content>
    <ng-container *ngIf="(data$ | async) as data">
      <ngx-datatable class="bootstrap table" [rows]="data" [columns]="columns" [columnMode]="'flex'" [headerHeight]="50"
        [footerHeight]="50" [rowHeight]="'auto'" [limit]="30" [reorderable]="true" [messages]="tableMessages">

        <ng-container *ngFor="let col of columns">
          <ngx-datatable-column [flexGrow]="col.flexGrow" [name]="col.name" [prop]="col.prop" [sortable]="col.sortable">
            <div>{{ col.name | translate }}</div>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <div [ngSwitch]="col.prop">
                <div *ngSwitchCase="'shift'">{{value | translate}}</div>
                <div *ngSwitchDefault>{{value}}</div>
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ng-container>
      </ngx-datatable>

    </ng-container>
  </div>
</layout-default>