
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TimeslotService } from '../services/timeslot.service';

@Injectable()
export class TimeslotResolver implements Resolve<any> {
    constructor(private timeslotService: TimeslotService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        const params = route.params;
        return this.timeslotService.findOne(params['timeslotId']);
    }
}
