import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild } from '@angular/router';
import { NavService } from '@shared/services/nav.sevice';
import { AuthenticationService } from 'src/app/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionGuardService implements CanActivate, CanActivateChild {

    constructor(
        private readonly navService: NavService, 
        private readonly authService: AuthenticationService
    ) {}

    public canActivate(route: ActivatedRouteSnapshot): boolean {
        const expectedRole = (route.data as {expectedPermission: any}).expectedPermission;
        if (this.authService.checkPrivileges(expectedRole)) {
            return true;
        }

        this.navService.goToPage('/access-denied', { permissions: expectedRole });
        return false;
    }

    public canActivateChild(): boolean {
        return true;
    }
}
