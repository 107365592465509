import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ORDER } from '@enums/enum';
import { PageableResponse } from '@entities/pagable-response';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { NavService } from '@services/nav.sevice';
import * as _ from 'lodash';
import { Partner } from '../../entities/partner';
import PartnerDC from '../../interfaces/partnerdc.interface';
import { DcService } from '../../services/dc.service';
import { Boo } from '@entities/boo';

@Component({
  selector: 'app-dc',
  templateUrl: './dc.component.html',
  styleUrls: ['./dc.component.scss']
})
export class DcComponent implements OnInit {

  public partnerdcs: PartnerDC[];
  private partnerdcsCollection: PartnerDC[];

  public partner: Partner;

  public loader: boolean = true;

  protected page: number = 0;
  protected size = 10;
  protected order: ORDER = ORDER.DESC;
  protected sortBy: string = 'createdAt';
  protected pagable: PageableResponse<PartnerDC> = new PageableResponse<PartnerDC>(null);
  public pageInfo;
  public modifiedRow: string;
  public rowClass;

  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  public columns = [
    { prop: 'name', name: this.translateService.instant('Name'), flexGrow: 2 },
    { prop: 'address', name: this.translateService.instant('Address'), flexGrow: 4 },
    { prop: 'pickupSeconds', name: this.translateService.instant('Pickup'), flexGrow: 2 },
    { prop: 'pickupBoxSeconds', name: this.translateService.instant('Pickup box'), flexGrow: 2 },
    { prop: 'pickupDeliverySeconds', name: this.translateService.instant('Pickup delivery'), flexGrow: 2 },
    { prop: 'options', name: this.translateService.instant('Options'), flexGrow: 1 },
  ];

  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  constructor(
    private dcService: DcService,
    private navigationRoute: ActivatedRoute,
    private translateService: TranslateService,
    private modalService: BsModalService,
    private toastrProviderService: ToastrProviderService,
    private navService: NavService
  ) { }

  public ngOnInit() {
    this.pagable = (this.navigationRoute.snapshot.data as {dcs: any}).dcs;
    this.partnerdcs = this.partnerdcs = this.pagable.content as PartnerDC[];
    this.modifiedRow = this.navigationRoute.snapshot.params.modifiedRow;
    this.partner = (this.navigationRoute.snapshot.data as {partner: any}).partner;

    this.rowClass = row => {
      return { 'row-modified': row.id === this.modifiedRow };
    };
    this.loader = false;
  }

  public setPage(pageInfo?) {
    this.page = pageInfo.offset;
    this.pageInfo = pageInfo;
  }

  public isOptionsColumn(status: string): boolean {
    return status === 'options';
  }

  public isColumnAddress(status: string): boolean {
    return status === 'address';
  }

  public isColumnTConvertTime(status: string): boolean {
    return status === 'pickupSeconds' || status === 'pickupBoxSeconds' || status === 'pickupDeliverySeconds';
  }

  public goToDetails(id: string): void {
    this.navService.goToPage(`/partner/${this.partner.id}/dc/${id}/edit`);
  }

  public createPartnerDc(): void {
    this.navService.goToPage(`/partner/${this.partner.id}/dc/create`);
  }

  public applyFiltering(event) {
    const filterPhrase = event.target.value.toLowerCase();
    const temp = _.filter(this.partnerdcsCollection, (dc: PartnerDC) => {
      const searchIn: string = dc.name;
      return searchIn.toLowerCase().indexOf(filterPhrase) !== -1 || !filterPhrase;
    });

    this.partnerdcs = temp;
  }

  public fetchPartnerDC(): void {
    this.loader = true;
    this.dcService.getAllDcForpartner(this.partner.id).subscribe(
      (dc: any) => {
        this.pagable = dc;
        this.partnerdcs = this.partnerdcsCollection = this.pagable.content as PartnerDC[];
        this.loader = false;
      }, 
      () => {
        this.loader = false;
      })
  }

  public removeDc(id: string): void {
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.dcService.delete(id);
  }

  public confirm(): void {
    this.confirmObservable.subscribe((test) => {
      this.modalRef.hide();
      this.pageInfo = null;
      this.fetchPartnerDC();      
      this.toastrProviderService.showSuccess(this.translateService.instant('The distribution center has been removed!'));
      this.confirmObservable = null;
    });
  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }

}
