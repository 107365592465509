import { Pipe, PipeTransform } from '@angular/core';
import { LoginProvider } from '@interfaces/login-provider.interface';
import { LoginProviderType } from '@enums/enum';

@Pipe({
    name: 'findProviderIdpUrlPipe',
    pure: true,
})
export class FindProviderIdpUrlPipe implements PipeTransform {
    public transform(providers: LoginProvider[], type: LoginProviderType): any {
        let provider: LoginProvider[] = providers.filter((p: LoginProvider) => p.type === type);

        provider.forEach((provider) => {
            if (provider && provider.idpUrl) {
                provider.idpUrl = provider.idpUrl.replace('https://frisco.open-routing.com/', 'https://november-2024-tmp.open-routing.com/');
            }
        });

        return provider ? provider : undefined;
    }
}
