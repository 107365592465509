import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as _ from 'lodash';
import { DriversModule } from '@drivers/drivers.module';
import { HardwareModule } from '@hardware/hardware.module';
import { AnalyticsModule } from './analytics/analytics.module';
import { AppComponent } from './app.component';
import { Routing } from './app.routing';
import { LocationsModule } from './locations/locations.module';
import { PaymentsModule } from './payments/payments.module';
import { SharedModule } from './shared.module';
import { UsersModule } from './users/users.module';
import { InpostSharedModule } from 'projects/inpost/src/app/app.module';
import { FriscoSharedModule } from 'projects/frisco/src/app/frisco-app.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DashboardModule } from './dashboard/dashboard.module';
import { SettingsModule } from './settings/settings.module';
import { SystemModule } from './system/system.module';

const defaultRuntimeConfiguration = {
    app: {
        localMenu: false
    },
    lang: {
        default: 'pl',
        current: localStorage.getItem('lang') || 'pl'
    }
};

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/');
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        Routing,
        FormsModule,
        HttpClientModule,
        HardwareModule,
        DriversModule,
        LocationsModule,
        PaymentsModule,
        DashboardModule,
        AnalyticsModule,
        UsersModule,
        SharedModule,
        FormsModule,
        SettingsModule,
        SystemModule,
        InpostSharedModule.forRoot(),
        FriscoSharedModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
            },
            isolate : false
          }),
    ],
    providers: [
        // { provide: ErrorHandler, useFactory: errorHandlerFactory }
        
    ],
    exports: [
        TranslateModule,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {}
}
