import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { DcService } from '../services/dc.service';
import { UtilsService } from '@services/utils.service';

@Injectable()
export class DcsResolver implements Resolve<any> {
    constructor(private dcService: DcService, private readonly utilsService: UtilsService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        const partnerId = this.utilsService.findUpRouteParams(route, 'partnerId');
        return this.dcService.getAllDcForpartner(partnerId);
    }
}
