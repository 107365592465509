<header class="header">
    <div class="header-container">
        <a class="logo-container" [routerLink]="['/']">
            <div class="logo"></div>
            <span class="powered">Powered by Open Routing</span>
        </a>

        <button class="toggle-button" (click)="_toggleSidebar()">
            <div class="toggle-button__icon" [ngClass]="{ 'toggle-button__icon--open': !_toggleSidebar() }"></div>
        </button>

        <nav [navigation]="'nav'"></nav>

        <nav [navigation]="_toggleSidebar() ? 'mobile-nav mobile-nav--show' : 'mobile-nav'"></nav>

        <div class="right-container">
            <!-- <select class="right-container__lang-select custom-select" [(ngModel)]="lang">
                <option value="pl">{{ 'Polish' | translate }}</option>
                <option value="en">{{ 'English' | translate }}</option>
            </select> -->

            <!-- <ng-container *ngIf="isWarehousePage"> -->
            <ng-container *ngIf="(depots$ | async) as depots">
                <select class="right-container__warehouse custom-select" [(ngModel)]="selectedDepot"
                    (change)="changedDepot()">
                    <ng-container *ngFor="let depot of depots">
                        <option [value]="depot.id">{{ depot.code | translate }}</option>
                    </ng-container>
                </select>
            </ng-container>
            <!-- </ng-container> -->

            <div *ngIf="(user$ | async) as user" class="right-container__profile-outer dropdown" dropdown>
                <div class="right-container__profile dropdown-toggle {{appClient}}" dropdown-toggle
                    data-toggle="dropdown" data-display="static">
                    <div class="right-container__avatar-container d-flex align-items-center justify-content-center">
                        <i-feather [style.width.px]="20" [style.height.px]="20" name="mail"></i-feather>
                    </div>
                    <ng-container *ngIf="(notifications$ | async) as notifications">
                        <span *ngIf="notifications.length"
                            class="badge badge-pill badge-success bounce-6">{{notifications.length}}</span>
                    </ng-container>
                </div>
                <div class="dropdown-menu dropdown-menu-right right-container__menu" dropdown-menu>
                    <div class="dropdown-header">{{'New notifications' | translate}}<span>{{ user[1].username }}</span>
                    </div>

                    <a *ngFor="let notification of notifications" href="#" onclick="return false" class="dropdown-item">
                        <div class="media">
                            <div class="media-body">
                                <strong>{{notification.subject}}</strong>
                                <p>{{notification.body | truncatePipe:10:'...' | translate}}</p>
                                <p *ngIf="notification.deepLinkId as deepLinkId"><span>{{deepLinkId}}</span></p>
                                <a (click)="goToDetails(notification)">{{'More details' | translate}}</a>
                                <a (click)="markAsRead(notification, $event)" class="mg-l-10" href="">{{'Mark as read' |
                                    translate}}</a>
                                <span class="d-block mg-t-4">
                                    <span class="notification-badge" [ngSwitch]="notification.deepLinkType">
                                        <span *ngSwitchCase="'CUSTOMER'" class="badge badge-secondary">{{
                                            'DeepLinkType.'+notification.deepLinkType | translate }}</span>
                                        <span *ngSwitchCase="'ROUTE'" class="badge badge-success">{{
                                            'DeepLinkType.'+notification.deepLinkType | translate }}</span>
                                        <span *ngSwitchCase="'DELIVERY'" class="badge badge-info">{{
                                            'DeepLinkType.'+notification.deepLinkType | translate }}</span>
                                        <span *ngSwitchCase="'APM'" class="badge badge-info">{{
                                            'DeepLinkType.'+notification.deepLinkType | translate }}</span>
                                    </span>
                                    <span class="mg-r-10">{{notification.date | date:'short'}}</span>
                                </span>
                            </div><!-- media-body -->
                        </div><!-- media -->
                    </a>

                    <p *ngIf="notifications && !notifications.length"
                        class="text-center pd-t-30 pd-b-15 tx-color-03 tx-12"><i-feather [style.width.px]="15"
                            name="activity"></i-feather> {{'There are no notifications to display' | translate}}.</p>

                    <div class="dropdown-footer d-flex justify-content-between">
                        <a [routerLink]="['/system/notifications/list']">{{'View all notifications' | translate}}</a>
                        <a href="#" (click)="signOut()">{{ 'Log out' | translate }} </a>

                    </div>
                </div>
            </div>


            <div *ngIf="(user$ | async) as user" class="right-container__profile-outer dropdown" dropdown>
                <div *ngIf="appClient" class="right-container__profile dropdown-toggle" data-toggle="dropdown"
                    data-display="static">
                    <div class="right-container__avatar-container">
                        <img class="right-container__avatar" src="/assets/img/avatar.png" alt="avatar" />
                    </div>
                </div>
                <div class="dropdown-menu dropdown-menu-right right-container__menu">

                    <div class="dropdown-header">{{'Settings' | translate}}<span>{{ user[1].username }}</span></div>

                    <div class="dropdown-content">
                        <div class="select-lang d-flex align-items-baseline justify-content-between">
                            <label class="d-block tx-12 tx-color-03" for="language">{{'Language' | translate}}:</label>
                            <select class="right-container__lang-select custom-select"
                                (ngModelChange)="changedLang($event)" [(ngModel)]="lang">
                                <option value="pl">{{ 'Polish' | translate }}</option>
                                <option value="en">{{ 'English' | translate }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="dropdown-content">
                        <div class="select-lang d-flex align-items-baseline justify-content-between">
                            <label class="d-block tx-12 tx-color-03" for="language">{{'Start page' |
                                translate}}:</label>
                            <select class="right-container__lang-select custom-select"
                                (ngModelChange)="changedStartPage($event)" [(ngModel)]="startPage">
                                <option value="calendar">{{ 'Calendar' | translate }}</option>
                                <option appCheckFeaturesConfig [featureName]="[Permissions.Route_view]" value="routes">
                                    {{ 'Breadcrumbs.RoutesList' | translate }}</option>
                                <option appCheckFeaturesConfig [featureName]="[Permissions.Dashboard_view]"
                                    value="dashboard">{{ 'Breadcrumbs.Dashboard' | translate }}</option>
                                <option appCheckFeaturesConfig [featureName]="[Permissions.Visualiser_view]"
                                    value="visualiser">{{ 'Breadcrumbs.Visualiser' | translate }}</option>
                                <option appCheckFeaturesConfig [featureName]="[Permissions.Partner_view]"
                                    value="partner">{{ 'Breadcrumbs.Partner' | translate }}</option>
                                <option appCheckFeaturesConfig [featureName]="[Permissions.Delivery_view]"
                                    value="deliveries">{{ 'Breadcrumbs.Deliveries' | translate }}</option>
                                <option appCheckFeaturesConfig [featureName]="[Permissions.Analytics_stats2_view]"
                                    value="analytics">{{ 'Breadcrumbs.Analytics' | translate }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="dropdown-footer d-flex justify-content-between">
                        <a href="#" (click)="signOut()">{{ 'Log out' | translate }} </a>
                    </div>
                </div>
            </div>



        </div>
    </div>
</header>