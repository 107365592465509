import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Agency } from '../../interfaces/agency';
import { QueryParams } from '@interfaces/query-params.interface';
import { AgencyService } from '../../services/agency.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { NavService } from '@services/nav.sevice';
import { ActivatedRoute } from '@angular/router';
import { PageableResponse } from '@entities/pagable-response';
import { ORDER } from '@enums/enum';
import { environment } from '@environment';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-agency-list',
  templateUrl: './agency-list.component.html',
  styleUrls: ['./agency-list.component.scss']
})
export class AgencyListComponent implements OnInit {

  public agencySubscriptions$: Observable<PageableResponse<Agency>>;
  public agencies: Agency[];
  private agenciesCollection: Agency[];

  public sortedColumn: string | undefined;
  public rowClass;

  public loader = false;

  public columns = [
    { prop: 'name', name: this.translateService.instant('Name'), flexGrow: 3, sortable: true },
    { prop: 'baseMonthlySalaryNet', name: this.translateService.instant('Monthly salary net'), flexGrow: 2, sortable: true },
    { prop: 'baseMonthlySalaryGross', name: this.translateService.instant('Monthly salary gross'), flexGrow: 2, sortable: true },
    { prop: 'baseDeliveryFeeNet', name: this.translateService.instant('Delivery fee net'), flexGrow: 2, sortable: true },
    { prop: 'baseDeliveryFeeGross', name: this.translateService.instant('Delivery fee gross'), flexGrow: 2, sortable: true },
    { prop: 'driverIdSet.length', name: this.translateService.instant('Drivers count'), flexGrow: 2, sortable: false },
    { prop: 'options', name: this.translateService.instant('Options'), flexGrow: 1, sortable: false },
  ];

  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  public queryParams: QueryParams = Object.assign({}, environment.pagination);

  constructor(
    private readonly agencyService: AgencyService,
    private readonly translateService: TranslateService,
    private readonly modalService: BsModalService,
    private toastrProviderService: ToastrProviderService,
    private readonly navService: NavService,
    private readonly navigationRoute: ActivatedRoute
  ) { }

  public ngOnInit() {
    this.agencySubscriptions$ = this.agencyService.list$.pipe(
      tap((agency: PageableResponse<Agency>) => {
        this.agencies = this.agenciesCollection = agency.content;
      })
    );
    this.agencyService.fetchAll(this.queryParams);

    const modifiedRow = this.navigationRoute.snapshot.params.modifiedRow;

    this.rowClass = row => {
      return { 'row-modified': row.id === modifiedRow };
    };
  }

  public setPage(pageInfo?) {
    this.queryParams.page = pageInfo.offset;
    this.agencyService.fetchAll(this.queryParams);
  }

  public onSort(event) {
    this.queryParams.sort = event.sorts[0].prop;
    this.queryParams.order = (event.sorts[0].dir === 'asc') ? ORDER.ASC : ORDER.DESC;
    this.agencyService.fetchAll(this.queryParams);
  }

  public isOptionsColumn(status: string): boolean {
    return status === 'options';
  }

  public isColumnToTranslate(status: string): boolean {
    return status === 'type' || status === 'status';
  }

  public goToDetails(id: string): void {
    this.navService.goToPage(`/salary/agency/${id}/edit`);
  }

  public removeAgency(id: string): void {
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.agencyService.delete(id);
  }

  public confirm(): void {
    this.confirmObservable.subscribe((test) => {
      this.modalRef.hide();
      this.toastrProviderService.showSuccess(this.translateService.instant('The agency has been removed!'));
      this.confirmObservable = null;
      this.queryParams.page = 0;
      this.agencyService.fetchAll(this.queryParams);
    });
  }

  public applyFiltering(event) {
    const filterPhrase = event.target.value.toLowerCase();
    const temp = _.filter(this.agenciesCollection, (agency: Agency) => {
      const searchIn: string = agency.name;
      return searchIn.toLowerCase().indexOf(filterPhrase) !== -1 || !filterPhrase;
    });

    this.agencies = temp;
  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }
}
