import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ApmService } from '../../services/apm.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Apm } from '../../entities/apm';
import { ORDER } from '@enums/enum';
import { PageableResponse } from '@entities/pagable-response';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '@services/nav.sevice';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { ToastrProviderService } from '@services/toastr-provider.service';
import * as _ from 'lodash';
import { QueryParams } from '@interfaces/query-params.interface';
import { environment } from '@environment';
import { tap, map } from 'rxjs/operators';
import { Partner } from '../../entities/partner';
import { ApmExtendedService } from '../../services/apm-extended.service';
import { ApmExtended } from '../../interfaces/apm-extended.interface';
import { Permissions } from '@enums/permissions';
import { PartnerService } from '../../services/partner.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-apm',
  templateUrl: './apm.component.html',
  styleUrls: ['./apm.component.scss']
})
export class ApmComponent implements OnInit {

  @ViewChild('table', { static: false }) table: DatatableComponent;

  public apms: ApmExtended[];
  private apmsCollection: ApmExtended[];
  public list$: Observable<PageableResponse<ApmExtended>>;

  protected queryParams: QueryParams = Object.assign({}, environment.pagination);

  public modifiedRow: string;
  public rowClass;
  public loader: boolean = true;

  public servicePartnerEntity$: Observable<Partner>;
  public servicePartnerId: string;

  public selectedRegion: string = null;

  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  public createMaintenanceWindowMode = false;
  public selectedApms = [];

  public columns = [
    { prop: 'name', name: this.translateService.instant('Name'), flexGrow: 4, sortable: true },
    { prop: 'address', name: this.translateService.instant('Adress'), flexGrow: 4, sortable: true },
    { prop: 'apm.code', name: this.translateService.instant('Code'), flexGrow: 3, sortable: true },
    { prop: 'apm.type', name: this.translateService.instant('Type'), flexGrow: 3, sortable: true },
    { prop: 'lcount', name: this.translateService.instant('Capacity'), flexGrow: 2, sortable: true},
    { prop: 'apm.status', name: this.translateService.instant('Status'), flexGrow: 2, sortable: true },
    { prop: 'options', name: this.translateService.instant('Options'), flexGrow: 2, sortable: false},

  ];

  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  public Permissions = Permissions;

  constructor(
    private apmService: ApmService,
    private apmExtended: ApmExtendedService,
    private navigationRoute: ActivatedRoute,
    private translateService: TranslateService,
    private modalService: BsModalService,
    private toastrProviderService: ToastrProviderService,
    private navService: NavService,
    private partnerService: PartnerService,
    private router: Router
  ) { }

  public ngOnInit() {

    this.list$ = this.apmExtended.list$.pipe(
      map((list: PageableResponse<ApmExtended>) => {
        list.content = _.sortBy(list.content, (p: ApmExtended) => p.apm.status);
        return list;
      }),
      tap((list: PageableResponse<ApmExtended>) => {
          this.apmsCollection = this.apms = list.content;
        })
      );
    
    this.apmExtended.fetchAll(this.queryParams);

    this.modifiedRow = this.navigationRoute.snapshot.params.modifiedRow;
    this.servicePartnerEntity$ = this.partnerService.getPartnerService().pipe(
      tap((partner: Partner) => this.servicePartnerId = partner.id)
    );


    this.rowClass = row => {
      return { 'row-modified': row.apm.id === this.modifiedRow };
    };

    this.loader = false;
  }

  public filterByRegion(event) {
    if (this.selectedRegion === null) {
      this.queryParams = environment.pagination;
      this.apmExtended.fetchAll(this.queryParams);
      return;
    }

    this.apmService.findAllApmByRegionPageableResponse(this.queryParams, this.servicePartnerId, this.selectedRegion).pipe(
      map((list: PageableResponse<Apm>) => {
        list.content = _.sortBy(list.content, (p: Apm) => p.status);
        return list;
      }),
      tap((list: PageableResponse<Apm>) => {
          this.apmsCollection = this.apms = list.content.map((a: Apm) => { 
            return {apm: a, availableVolume: null};
          })
      })
    ).subscribe()
  }

  public setPage(pageInfo?) {
    this.queryParams.page = pageInfo.offset;
    this.apmExtended.fetchAll(this.queryParams);
  }

  public onSort(sortInfo) {
    const sort = sortInfo.sorts[0].prop.split('.');
    this.queryParams.sort = sort[sort.length - 1]
    this.queryParams.order = (sortInfo.sorts[0].dir === 'asc') ? ORDER.DESC : ORDER.ASC;
    this.apmExtended.fetchAll(this.queryParams);
  }

  public goToDetails(id: string): void {
    this.navService.goToPage(`/apm/edit/${id}`);
  }

  public goToMaintenanceWindows(id: string): void {
    this.navService.goToPage(`/apm/maintenance/${id}`);
  }

  public synchronizeApms(apmId: string): void {
    this.apmService.synchronizeApms(apmId).subscribe(() => {
      this.toastrProviderService.showSuccess(this.translateService.instant('The rlm has been synchronized!'));
    });
  }

  public selectedApmsFn(id: string) {
    const exist = this.selectedApms.find(apm => id === apm);

    if (exist) {
      this.selectedApms = this.selectedApms.filter(apm => id !== apm);
    } else {
      this.selectedApms.push(id);
    }

    console.log(this.selectedApms);

  }

  public createMaintenanceWindowFn() {
    this.router.navigate(['/apm', 'maintenance', this.selectedApms[0], 'create'], {queryParams: {selectedApms: this.selectedApms}});
  }

  public removeApm(id: string): void {
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.apmService.delete(id);
  }

  public confirm(): void {
    this.confirmObservable.subscribe((test) => {
      this.modalRef.hide();
      this.queryParams = environment.pagination;
      this.apmService.fetchAll(this.queryParams);      
      this.toastrProviderService.showSuccess(this.translateService.instant('The apm has been removed!'));
      this.confirmObservable = null;
    });
  }

  public applyFiltering(event) {
    const filterPhrase = event.target.value.toLowerCase();
    this.queryParams.page = 0;
    this.queryParams.filter = filterPhrase;
    this.apmExtended.fetchAll(this.queryParams);
  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }
}
