import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsLoaderService {
  private isApiLoaded = false; // Flaga sprawdzająca, czy API zostało załadowane
  private loadPromise: Promise<void> | null = null;

  loadApi(apiKey: string): Promise<void> {
    // Sprawdzenie, czy API jest już załadowane
    if (this.isApiLoaded) {
      return Promise.resolve();
    }

    // Jeśli API nie jest jeszcze załadowane, inicjujemy ładowanie
    if (!this.loadPromise) {
      this.loadPromise = new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
        script.async = true;
        script.defer = true;
        script.onload = () => {
          this.isApiLoaded = true;
          resolve();
        };
        script.onerror = (error) => {
          this.loadPromise = null; // Resetujemy, aby można było spróbować ponownie
          reject(error);
        };
        document.head.appendChild(script);
      });
    }

    return this.loadPromise;
  }
}
