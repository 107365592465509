import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UsersRoles } from '@enums/roles';
import { ApmComponent } from './components/apm/apm.component';
import { EditApmComponent } from './components/apm/edit-apm.component';
import { OperationsMainComponent } from './components/operations.component';
import { AmpsResolver } from './resolvers/apms.resolver';
import { AmpResolver } from './resolvers/apm.resolver';
import { ApmMapComponent } from './components/apm/apm-map.component';
import { PartnerComponent } from './components/partner/partner.component';
import { PartnersResolver } from './resolvers/partners.resolver';
import { EditPartnerComponent } from './components/partner/edit-partner.component';
import { PartnerResolver } from './resolvers/partner.resolver';
import { RegionsResolver } from './resolvers/regions.resolver';
import { RegionComponent } from './components/region/region.component';
import { EditRegionComponent } from './components/region/edit-region.component';
import { RegionResolver } from './resolvers/region.resolver';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { SubscriptionsResolver } from './resolvers/subscriptions.resolver';
import { EditSubscriptionComponent } from './components/subscription/edit-subscription.component';
import { SubscriptionResolver } from './resolvers/subscription.resolver';
import { ApmDeliveryComponent } from './components/apm-delivery/apm-delivery.component';
import { EditApmDeliveryComponent } from './components/apm-delivery/edit-apm-delivery.component';
import { AmpDeliveryResolver } from './resolvers/apm-delivery.resolver';
import { AmpDeliveriesResolver } from './resolvers/apm-deliveries.resolver';
import { ConfigKeyComponent } from './components/config-key/config-key.component';
import { EditConfigKeyComponent } from './components/config-key/edit-config-key.component';
import { ConfigKeyResolver } from './resolvers/config-key.resolver';
import { ConfigKeysResolver } from './resolvers/config-keys.resolver';
import { ConfigValuesResolver } from './resolvers/config-values.resolver';
import { ConfigValueResolver } from './resolvers/config-value.resolver';
import { EditConfigValueComponent } from './components/config-value/edit-config-value.component';
import { ConfigValueComponent } from './components/config-value/config-value.component';
import { ProxyComponent } from '@shared/components/proxy.component';
import { AssignApmComponent } from './components/region/assign-apm.component';
import { AssignApmsResolver } from './resolvers/assign-apms.resolver';
import { TimeslotComponent } from './components/timeslot/timeslot.component';
import { TimeslotsResolver } from './resolvers/timeslots.resolver';
import { CreateTimeslotComponent } from './components/timeslot/create-timeslot.component';
import { TimeslotResolver } from './resolvers/timeslot.resolver';
import { PartnerUsersResolver } from './resolvers/partner-users.resolver';
import { PartnerUserComponent } from './components/partner-user/partner-user.component';
import { PartnerUserResolver } from './resolvers/partner-user.resolver';
import { EditPartnerUserComponent } from './components/partner-user/edit-partner-user.component';
import { DcsResolver } from './resolvers/dcs.resolver';
import { DcComponent } from './components/dc/dc.component';
import { DcEditComponent } from './components/dc/dc-edit.component';
import { DcResolver } from './resolvers/dc.resolver';
import { PartnerCourierComponent } from './components/partner-courier/partner-courier.component';
import { PartnerCouriersResolver } from './resolvers/partner-couriers.resolver';
import { PartnerCourierResolver } from './resolvers/partner-courier.resolver';
import { PartnerCourierEditComponent } from './components/partner-courier/partner-courier-edit.component';
import { ApmMaintenanceComponent } from './components/apm/apm-maintenance.component';
import { ApmMaintenanceCreateComponent } from './components/apm/apm-maintenance-create.component';
import { ConfigValuesByScopeComponent } from './components/config-values-by-scope/config-values-by-scope.component';
import { ConfigDefaultValuesResolver } from './resolvers/config-default-values.resolver';
import { PermissionGuardService } from '@guards/permission.guard';
import * as P from '@enums/permissions';
import { ClientGuard } from '@guards/client.guard';
import { Client } from '@enums/enum';
import { RouterOutletComponent } from '@shared/components/router-outlet.component';
import { PartnerReportsComponent } from './components/partner-reports/partner-reports.component';
import { ApmMissingPartnerComponent } from './components/apm-missing-partner/apm-missing-partner.component';
import { ApmsInRouteComponent } from './components/apm-delivery/apms-in-route/apms-in-route.component';
import { ApmOverbookingComponent } from './components/apm/apm-overbooking/apm-overbooking.component';
import { ApmDeliverySearchComponent } from './components/apm-delivery/apm-delivery-search/apm-delivery-search.component';
import { ApmDeliveryRescheduleComponent } from './components/apm-delivery/apm-delivery-reschedule/apm-delivery-reschedule.component';
import { ApmDeliveryConvertD2dComponent } from './components/apm-delivery/apm-delivery-convert-d2d/apm-delivery-convert-d2d.component';
import { ApmAvailabilityComponent } from './components/apm/apm-availability/apm-availability.component';
import { PartnerServiceResolver } from './resolvers/partner-service.resolver';
import { ApmMaintenanceResolver } from './resolvers/apm-maintenance-window.resolver';
import { CreateConfigValueComponent } from './components/config-value/create-config-value.component';
import { D2dTestsComponent } from './components/d2d-tests/d2d-tests.component';
import { RoleGuardService } from '@guards/role.guard';
import { ApmAssignedRegionsComponent } from './components/apm/apm-assigned-regions/apm-assigned-regions.component';
import { AssignRegionsResolver } from './resolvers/apm-assigned-regions.resolver';
import { ApmMaintenanceListComponent } from './components/apm/apm-maintenance-list/apm-maintenance-list.component';
import { ApmMaintananceWindowsListComponent } from './components/apm/apm-maintanance-windows-list/apm-maintanance-windows-list.component';
import { OtherApmsInRegionComponent } from './components/apm-delivery/rebooking/other-apms-in-region/other-apms-in-region.component';
import { ApmDeliveryDetailsComponent } from './components/apm-delivery/apm-delivery-details/apm-delivery-details.component';

const routes: Routes = [
    {
        path: '',
        component: ProxyComponent,
        children: [
            {
                path: 'apm', component: OperationsMainComponent,
                canActivate: [ClientGuard],
                data: {
                    client: Client.INPOST,
                    breadcrumb: 'Breadcrumbs.Apm'
                },
                children: [
                    {
                        path: 'list',
                        component: ApmComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Apm_view],
                            breadcrumb: 'Breadcrumbs.Apm'
                        },
                        resolve: {
                            apms: AmpsResolver,
                            //servicePartner: PartnerServiceResolver
                        }
                    },
                    {
                        path: 'missing-service-partner/list',
                        component: ApmMissingPartnerComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Apm_view],
                            breadcrumb: 'Breadcrumbs.Apm'
                        },
                        resolve: {
                            servicePartner: PartnerServiceResolver
                        }
                    },
                    { 
                        path: 'create', 
                        component: EditApmComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Apm_view, P.Permissions.Apm_edit],
                            breadcrumb: 'Breadcrumbs.ApmCreate'
                        }
                    },
                    { 
                        path: 'edit/:apmId', 
                        component: EditApmComponent,
                        canActivate: [PermissionGuardService],
                        resolve: {
                            apm: AmpResolver
                        },
                        data: {
                            expectedPermission: [P.Permissions.Apm_view, P.Permissions.Apm_edit],
                            breadcrumb: 'Breadcrumbs.ApmUpdate'
                        }
                    },
                    {
                        path: ':apmId/regions/list',
                        component: ApmAssignedRegionsComponent,
                        canActivate: [PermissionGuardService],
                        resolve: {
                            apm: AmpResolver,
                            assigndRegions: AssignRegionsResolver
                        },
                        data: {
                            expectedPermission: [P.Permissions.Apm_view, P.Permissions.Apm_edit],
                            breadcrumb: 'Breadcrumbs.ApmAssignRegions'
                        }
                    },
                    {
                        path: 'map',
                        component: ApmMapComponent,
                        canActivate: [PermissionGuardService],
                        resolve: {
                            apms: AmpsResolver
                        },
                        data: {
                            expectedPermission: [P.Permissions.Apm_view],
                            breadcrumb: 'Breadcrumbs.ApmMap'
                        }
                    },
                    {
                        path: ':apmId/availability/partner/:partnerId/delivery/:id',
                        component: ApmAvailabilityComponent,
                        canActivate: [PermissionGuardService],
                        resolve: {
                            apm: AmpResolver,
                            apmDelivery: AmpDeliveryResolver,
                            partner: PartnerServiceResolver
                        },
                        data: {
                            expectedPermission: [P.Permissions.Apm_view],
                            breadcrumb: 'Breadcrumbs.ApmAvailability'
                        }
                    },
                    {
                        path: 'maintenance/list',
                        component: ApmMaintenanceListComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Apm_maintenance_edit],
                            breadcrumb: 'Breadcrumbs.ApmMaintenanceList'
                        }
                    },
                    {
                        path: 'maintenance/windows/list',
                        component: ApmMaintananceWindowsListComponent,
                        canActivate: [PermissionGuardService],
                        resolve: {
                            apms: AmpsResolver
                        },
                        data: {
                            expectedPermission: [P.Permissions.Apm_maintenance_edit],
                            breadcrumb: 'Breadcrumbs.ApmMaintenanceWindowsList'
                        }
                    },
                    {
                        path: 'maintenance/:apmId',
                        component: ApmMaintenanceComponent,
                        canActivate: [PermissionGuardService],
                        resolve: {
                            apm: AmpResolver
                        },
                        data: {
                            expectedPermission: [P.Permissions.Apm_maintenance_edit],
                            breadcrumb: 'Breadcrumbs.ApmMaintenance'
                        }
                    },
                    {
                        path: 'maintenance/:maintenanceId/overbooking/list',
                        component: ApmOverbookingComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Apm_maintenance_edit],
                            breadcrumb: 'Breadcrumbs.ApmMaintenanceOverbooking'
                        }
                    },
                    {
                        path: ':apmId/apm-deliveries/list', 
                        component: ApmDeliverySearchComponent,
                        data: {
                            breadcrumb: 'Breadcrumbs.ApmDeliverySearch'
                        },
                        resolve: {
                            partnerService: PartnerServiceResolver
                        }
                    },
                    {
                        path: ':id/rlms-in-route', 
                        component: ApmsInRouteComponent,
                        data: {
                            breadcrumb: 'Breadcrumbs.ApmsDeliveryInRoute'
                        },
                        resolve: {
                            servicePartner: PartnerServiceResolver,
                            apmDelivery: AmpDeliveryResolver
                        }
                    },
                    {
                        path: 'maintenance/:apmId/create',
                        component: ApmMaintenanceCreateComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Apm_maintenance_edit],
                            breadcrumb: 'Breadcrumbs.ApmMaintenanceCreate'
                        },
                        resolve: {
                            apm: AmpResolver,
                            apms: AmpsResolver
                        }
                    },
                    {
                        path: 'maintenance/:apmId/edit/:timeslotId',
                        component: ApmMaintenanceCreateComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Apm_maintenance_edit],
                            breadcrumb: 'Breadcrumbs.ApmMaintenanceEdit'
                        },
                        resolve: {
                            apm: AmpResolver,
                            apmMaintenanceWindow: ApmMaintenanceResolver
                        }
                    },
                    { path: '**', redirectTo: 'list' },
                ]
            },
            {
                path: 'partner', component: OperationsMainComponent,
                canActivate: [ClientGuard],
                data: {
                    client: Client.INPOST,
                    breadcrumb: 'Breadcrumbs.Partner'
                },
                children: [
                    { path: '', pathMatch: 'full', redirectTo: 'list' },
                    {
                        path: 'list',
                        component: PartnerComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Partner_view],
                            breadcrumb: 'Breadcrumbs.Partner'
                        },
                    },
                    { 
                        path: 'create', 
                        component: EditPartnerComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Partner_view, P.Permissions.Partner_edit],
                            breadcrumb: 'Breadcrumbs.PartnerCreate'
                        }
                    },
                    { 
                        path: 'edit/:partnerId', 
                        component: EditPartnerComponent,
                        canActivate: [PermissionGuardService],
                        resolve: {
                            apm: PartnerResolver
                        },
                        data: {
                            expectedPermission: [P.Permissions.Partner_view, P.Permissions.Partner_edit],
                            breadcrumb: 'Breadcrumbs.PartnerUpdate'
                        }
                    },
                    {
                        path: ':partnerId',
                        component: PartnerComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Partner_view],
                            breadcrumb: 'Breadcrumbs.Partner'
                        },
                        children: [
                            { 
                                path: 'edit/:configId', 
                                component: EditConfigValueComponent,
                                canActivate: [PermissionGuardService],
                                resolve: {
                                    configValue: ConfigValueResolver,
                                    configKeys: ConfigKeysResolver,
                                    partner: PartnerResolver,
                                    regions: RegionsResolver
                                },
                                data: {
                                    expectedPermission: [P.Permissions.Partner_view, P.Permissions.Partner_edit],
                                    breadcrumb: 'Breadcrumbs.ConfigValueUpdate'
                                }
                            },
                            
                        ]
                    },
                    { 
                        path: '', 
                        component: RouterOutletComponent,
                        data: {
                            expectedPermission: [P.Permissions.Partner_view, P.Permissions.Partner_edit],
                        },
                        children: [
                            { 
                                path: '', 
                                component: RouterOutletComponent,
                                canActivate: [PermissionGuardService],
                                data: {
                                    expectedPermission: [P.Permissions.Partner_view],
                                    breadcrumb: 'Breadcrumbs.Partner',
                                },
                                children: [
                                    { path: '', pathMatch: 'full', redirectTo: 'region' },
                                    {
                                        path: ':partnerId/users', 
                                        component: RouterOutletComponent,
                                        data: {
                                            expectedPermission: [P.Permissions.Partner_view, P.Permissions.Partner_edit],
                                            breadcrumb: 'Breadcrumbs.PartnerUsers',
                                        },
                                        resolve: {
                                            partner: PartnerResolver
                                        },
                                        children: [
                                            { path: '', pathMatch: 'full', redirectTo: 'list' },
                                            { 
                                                path: 'list', 
                                                component: PartnerUserComponent,
                                                canActivate: [PermissionGuardService],
                                                resolve: {
                                                    users: PartnerUsersResolver,
                                                    partner: PartnerResolver
                                                },
                                                data: {
                                                    expectedPermission: [P.Permissions.Partner_view, P.Permissions.Partner_edit],
                                                    breadcrumb: 'Breadcrumbs.PartnerUsers'
                                                }
                                            },
                                            { 
                                                path: 'create', 
                                                component: EditPartnerUserComponent,
                                                canActivate: [PermissionGuardService],
                                                data: {
                                                    expectedPermission: [P.Permissions.Partner_view, P.Permissions.Partner_edit],
                                                    breadcrumb: 'Breadcrumbs.PartnerUserCreate'
                                                }
                                            },
                                            { 
                                                path: 'edit/:partneruserId', 
                                                component: EditPartnerUserComponent,
                                                canActivate: [PermissionGuardService],
                                                resolve: {
                                                    user: PartnerUserResolver
                                                },
                                                data: {
                                                    expectedPermission: [P.Permissions.Partner_view, P.Permissions.Partner_edit],
                                                    breadcrumb: 'Breadcrumbs.PartnerUserEdit'
                                                }
                                            },
                                        ]
                                    },
                                    {
                                        path: ':partnerId/timeslots', 
                                        component: RouterOutletComponent,
                                        data: {
                                            expectedPermission: [P.Permissions.Partner_view, P.Permissions.Partner_edit],
                                            breadcrumb: 'Breadcrumbs.Timeslots',
                                        },
                                        resolve: {
                                            partner: PartnerResolver
                                        },
                                        children: [
                                            { path: '', pathMatch: 'full', redirectTo: 'list' },
                                            { 
                                                path: 'list', 
                                                component: TimeslotComponent,
                                                canActivate: [PermissionGuardService],
                                                resolve: {
                                                    timeslots: TimeslotsResolver,
                                                },
                                                data: {
                                                    expectedPermission: [P.Permissions.Partner_view, P.Permissions.Partner_edit],
                                                    breadcrumb: 'Breadcrumbs.Timeslots'
                                                }
                                            },
                                            { 
                                                path: 'create', 
                                                component: CreateTimeslotComponent,
                                                canActivate: [PermissionGuardService],
                                                data: {
                                                    expectedPermission: [P.Permissions.Partner_view, P.Permissions.Partner_edit],
                                                    breadcrumb: 'Breadcrumbs.CreateTimeslots'
                                                }
                                            },
                                            { 
                                                path: 'edit/:timeslotId', 
                                                component: CreateTimeslotComponent,
                                                canActivate: [PermissionGuardService],
                                                resolve: {
                                                    timeslot: TimeslotResolver,
                                                },
                                                data: {
                                                    expectedPermission: [P.Permissions.Partner_view, P.Permissions.Partner_edit],
                                                    breadcrumb: 'Breadcrumbs.EditTimeslot'
                                                }
                                            },
                                            {
                                                path: 'config-value/:timeslotId/:scope', 
                                                component: RouterOutletComponent,
                                                canActivate: [PermissionGuardService],
                                                data: {
                                                    expectedPermission: [P.Permissions.Config_default_view, P.Permissions.Config_default_edit],
                                                    breadcrumb: 'Breadcrumbs.ConfigValue'
                                                },
                                                resolve: {
                                                    timeslot: TimeslotResolver
                                                },
                                                children: [
                                                    { path: '', pathMatch: 'full', redirectTo: 'list' },
                                                    {
                                                        path: 'list',
                                                        component: ConfigValuesByScopeComponent,
                                                        resolve: {
                                                            configValues: ConfigValuesResolver,
                                                            configKeys: ConfigKeysResolver,
                                                            partner: PartnerResolver,
                                                        },
                                                        data: {
                                                            breadcrumb: 'Breadcrumbs.ConfigValue'
                                                        }
                                                    },
                                                    { 
                                                        path: 'create', 
                                                        component: EditConfigValueComponent,
                                                        resolve: {
                                                            configKeys: ConfigKeysResolver,
                                                            partner: PartnerResolver,
                                                            regions: RegionsResolver,
                                                            configDefaultvalues: ConfigDefaultValuesResolver
                                                        },
                                                        data: {
                                                            breadcrumb: 'Breadcrumbs.ConfigValueCreate'
                                                        }
                                                    },
                                                    
                                                    { 
                                                        path: 'edit/:configId', 
                                                        component: EditConfigValueComponent,
                                                        data: {
                                                            breadcrumb: 'Breadcrumbs.ConfigValueUpdate'
                                                        }
                                                    },
                                                    { path: '**', redirectTo: 'list' },
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        path: ':partnerId/apm-delivery', 
                                        component: RouterOutletComponent,
                                        canActivate: [PermissionGuardService],
                                        data: {
                                            expectedPermission: [P.Permissions.Partner_view],
                                            breadcrumb: 'Breadcrumbs.ApmDelivery',
                                        },
                                        resolve: {
                                            partner: PartnerResolver
                                        },
                                        children: [
                                            { path: '', pathMatch: 'full', redirectTo: 'list' },
                                            {
                                                path: 'list',
                                                component: ApmDeliveryComponent,
                                                data: {
                                                    breadcrumb: 'Breadcrumbs.ApmDelivery'
                                                },
                                                resolve: {
                                                    partner: PartnerResolver,
                                                    apms: AmpsResolver,
                                                },
                                            },
                                            { 
                                                path: 'create', 
                                                component: EditApmDeliveryComponent,
                                                resolve: {
                                                    apms: AmpsResolver,
                                                    partners: PartnersResolver,
                                                    subscriptions: SubscriptionsResolver,
                                                },
                                                data: {
                                                    breadcrumb: 'Breadcrumbs.ApmDeliveryCreate'
                                                }
                                            },
                                            { 
                                                path: 'reschedule/:id', 
                                                component: ApmDeliveryRescheduleComponent,
                                                resolve: {
                                                    apms: AmpsResolver,
                                                    apmDelivery: AmpDeliveryResolver,
                                                    partnerService: PartnerServiceResolver
                                                },
                                                data: {
                                                    breadcrumb: 'Breadcrumbs.ApmDeliveryReschedule'
                                                }
                                            },
                                            { 
                                                path: 'convert-d2d/:id', 
                                                component: ApmDeliveryConvertD2dComponent,
                                                resolve: {
                                                    apms: AmpsResolver,
                                                    entity: AmpDeliveryResolver
                                                },
                                                data: {
                                                    breadcrumb: 'Breadcrumbs.ApmDeliveryConvertd2d'
                                                }
                                            },
                                            { 
                                                path: 'rebooking/delivery/:id/apm/:apmId', 
                                                component: OtherApmsInRegionComponent,
                                                resolve: {
                                                    apm: AmpResolver,
                                                    partner: PartnerResolver,
                                                    apmDelivery: AmpDeliveryResolver
                                                },
                                                data: {
                                                    breadcrumb: 'Breadcrumbs.RebookingOtherApmsInRegion'
                                                }
                                            },
                                            { 
                                                path: 'edit/:id', 
                                                component: EditApmDeliveryComponent,
                                                resolve: {
                                                    apmDelivery: AmpDeliveryResolver,
                                                    apms: AmpsResolver,
                                                    partners: PartnersResolver,
                                                    subscriptions: SubscriptionsResolver,
                                                },
                                                data: {
                                                    breadcrumb: 'Breadcrumbs.ApmDeliveryUpdate'
                                                }
                                            },
                                            { 
                                                path: 'details/:id', 
                                                component: ApmDeliveryDetailsComponent,
                                                resolve: {
                                                    apmDelivery: AmpDeliveryResolver,
                                                    partner: PartnerResolver,
                                                },
                                                data: {
                                                    breadcrumb: 'Breadcrumbs.ApmDeliveryDetails'
                                                }
                                            },
                                            { path: '**', redirectTo: 'list' },
                        
                                        ]
                                    },
                                    {
                                        path: ':partnerId/reports', 
                                        component: RouterOutletComponent,
                                        canActivate: [PermissionGuardService],
                                        data: {
                                            expectedPermission: [P.Permissions.Partner_view],
                                            breadcrumb: 'Breadcrumbs.PartnerReports',
                                        },
                                        resolve: {
                                            partner: PartnerResolver
                                        },
                                        children: [
                                            { path: '', pathMatch: 'full', redirectTo: 'list' },
                                            {
                                                path: 'generate',
                                                component: PartnerReportsComponent,
                                                data: {
                                                    breadcrumb: 'Breadcrumbs.PartnerReports'
                                                },
                                                resolve: {
                                                    partner: PartnerResolver
                                                },
                                            },
                                            { path: '**', redirectTo: 'list' },
                        
                                        ]
                                    },
                                    {
                                        path: ':partnerId/dc', 
                                        component: RouterOutletComponent,
                                        data: {
                                            expectedPermission: [P.Permissions.Partner_view, P.Permissions.Partner_edit],
                                            breadcrumb: 'Breadcrumbs.PartnerDc',
                                        },
                                        resolve: {
                                            partner: PartnerResolver
                                        },
                                        children: [
                                            { path: '', pathMatch: 'full', redirectTo: 'list' },
                                            {
                                                path: 'list',
                                                component: DcComponent,
                                                canActivate: [PermissionGuardService],
                                                resolve: {
                                                    dcs: DcsResolver,
                                                    partner: PartnerResolver
                                                },
                                                data: {
                                                    expectedPermission: [P.Permissions.Partner_view, P.Permissions.Partner_edit],
                                                    breadcrumb: 'Breadcrumbs.PartnerDc'
                                                }
                                            },
                                            {
                                                path: 'create',
                                                component: DcEditComponent,
                                                canActivate: [PermissionGuardService],
                                                data: {
                                                    expectedPermission: [P.Permissions.Partner_view, P.Permissions.Partner_edit],
                                                    breadcrumb: 'Breadcrumbs.DcCreate'
                                                }
                                            },
                                            {
                                                path: ':partnerDcId/edit',
                                                component: DcEditComponent,
                                                canActivate: [PermissionGuardService],
                                                resolve: {
                                                    dc: DcResolver
                                                },
                                                data: {
                                                    expectedPermission: [P.Permissions.Partner_view, P.Permissions.Partner_edit],
                                                    breadcrumb: 'Breadcrumbs.DcEdit'
                                                }
                                            },
                                        ]
                                    },
                                    {
                                        path: ':partnerId/config-value/:scope', 
                                        component: RouterOutletComponent,
                                        canActivate: [PermissionGuardService],
                                        data: {
                                            expectedPermission: [P.Permissions.Config_default_view, P.Permissions.Config_default_edit],
                                            breadcrumb: 'Breadcrumbs.ConfigValue'
                                        },
                                        resolve: {
                                            partner: PartnerResolver
                                        },
                                        children: [
                                            { path: '', pathMatch: 'full', redirectTo: 'list' },
                                            {
                                                path: 'list',
                                                component: ConfigValuesByScopeComponent,
                                                resolve: {
                                                    configValues: ConfigValuesResolver,
                                                    configKeys: ConfigKeysResolver,
                                                    partner: PartnerResolver,
                                                },
                                                data: {
                                                    breadcrumb: 'Breadcrumbs.ConfigValue'
                                                }
                                            },
                                            { 
                                                path: 'create', 
                                                component: EditConfigValueComponent,
                                                resolve: {
                                                    configKeys: ConfigKeysResolver,
                                                    partner: PartnerResolver,
                                                    regions: RegionsResolver,
                                                    configDefaultvalues: ConfigDefaultValuesResolver
                                                },
                                                data: {
                                                    breadcrumb: 'Breadcrumbs.ConfigValueCreate'
                                                }
                                            },
                                            
                                            { 
                                                path: 'edit/:configId', 
                                                component: EditConfigValueComponent,
                                                data: {
                                                    breadcrumb: 'Breadcrumbs.ConfigValueUpdate'
                                                }
                                            },
                                            { path: '**', redirectTo: 'list' },
                        
                                        ]
                                    },
                                    {
                                        path: ':partnerId/region', 
                                        component: RouterOutletComponent,
                                        data: {
                                            expectedPermission: [P.Permissions.Partner_view],
                                            breadcrumb: 'Breadcrumbs.Region',
                                        },
                                        resolve: {
                                            partner: PartnerResolver
                                        },
                                        children: [
                                            { path: '', pathMatch: 'full', redirectTo: 'list' },
                                            { 
                                                path: 'list', 
                                                component: RegionComponent,
                                                canActivate: [PermissionGuardService],
                                                resolve: {
                                                    partner: PartnerResolver
                                                },
                                                data: {
                                                    expectedPermission: [P.Permissions.Partner_view],
                                                    breadcrumb: 'Breadcrumbs.Region',
                                                }
                                            },
                                            { 
                                                path: 'create', 
                                                component: EditRegionComponent,
                                                canActivate: [PermissionGuardService],
                                                resolve: {
                                                    partner: PartnerResolver,
                                                    dcs: DcsResolver
                                                },
                                                data: {
                                                    expectedPermission: [P.Permissions.Partner_view, P.Permissions.Partner_edit],
                                                    breadcrumb: 'Breadcrumbs.RegionCreate'
                                                }
                                            },
                                            { 
                                                path: 'edit/:regionId', 
                                                component: EditRegionComponent,
                                                canActivate: [PermissionGuardService],
                                                resolve: {
                                                    partner: PartnerResolver,
                                                    region: RegionResolver,
                                                    dcs: DcsResolver
                                                },
                                                data: {
                                                    expectedPermission: [P.Permissions.Partner_view, P.Permissions.Partner_edit],
                                                    breadcrumb: 'Breadcrumbs.RegionUpdate'
                                                }
                                            },
                                            { 
                                                path: 'assign-apms/:regionId', 
                                                component: AssignApmComponent,
                                                canActivate: [PermissionGuardService],
                                                resolve: {
                                                    partner: PartnerResolver,
                                                    region: RegionResolver,
                                                    //apms: AmpsByDepotResolver,
                                                    assignApms: AssignApmsResolver
                                                },
                                                data: {
                                                    expectedPermission: [P.Permissions.Partner_view, P.Permissions.Partner_edit],
                                                    breadcrumb: 'Breadcrumbs.AssignApmToRegion'
                                                }
                                            },
                                            {
                                                path: 'apm-availability/:apmId',
                                                component: ApmAvailabilityComponent,
                                                canActivate: [PermissionGuardService],
                                                resolve: {
                                                    apm: AmpResolver,
                                                    partner: PartnerResolver
                                                },
                                                data: {
                                                    expectedPermission: [P.Permissions.Apm_view],
                                                    breadcrumb: 'Breadcrumbs.ApmAvailability'
                                                }
                                            },
                                            {
                                                path: ':regionId/timeslots', 
                                                component: RouterOutletComponent,
                                                data: {
                                                    expectedPermission: [P.Permissions.Partner_view, P.Permissions.Partner_edit],
                                                    breadcrumb: 'Breadcrumbs.Timeslots',
                                                },
                                                resolve: {
                                                    region: RegionResolver
                                                },
                                                children: [
                                                    { path: '', pathMatch: 'full', redirectTo: 'list' },
                                                    { 
                                                        path: 'list', 
                                                        component: TimeslotComponent,
                                                        resolve: {
                                                            timeslots: TimeslotsResolver,
                                                        },
                                                    },
                                                    { 
                                                        path: 'create', 
                                                        component: CreateTimeslotComponent,
                                                        canActivate: [PermissionGuardService],
                                                        data: {
                                                            expectedPermission: [P.Permissions.Partner_view, P.Permissions.Partner_edit],
                                                            breadcrumb: 'Breadcrumbs.CreateTimeslots'
                                                        }
                                                    },
                                                    { 
                                                        path: 'edit/:timeslotId', 
                                                        component: CreateTimeslotComponent,
                                                        canActivate: [PermissionGuardService],
                                                        resolve: {
                                                            timeslot: TimeslotResolver,
                                                        },
                                                        data: {
                                                            expectedPermission: [P.Permissions.Partner_view, P.Permissions.Partner_edit],
                                                            breadcrumb: 'Breadcrumbs.EditTimeslot'
                                                        }
                                                    },
                                                    { 
                                                        path: 'edit/:configId', 
                                                        component: EditConfigValueComponent,
                                                        data: {
                                                            breadcrumb: 'Breadcrumbs.ConfigValueUpdate'
                                                        }
                                                    },
                                                    {
                                                        path: 'config-value/:timeslotId/:scope', 
                                                        component: RouterOutletComponent,
                                                        canActivate: [PermissionGuardService],
                                                        data: {
                                                            expectedPermission: [P.Permissions.Config_default_view, P.Permissions.Config_default_edit],
                                                            breadcrumb: 'Breadcrumbs.ConfigValue'
                                                        },
                                                        resolve: {
                                                            timeslot: TimeslotResolver
                                                        },
                                                        children: [
                                                            { path: '', pathMatch: 'full', redirectTo: 'list' },
                                                            {
                                                                path: 'list',
                                                                component: ConfigValuesByScopeComponent,
                                                                resolve: {
                                                                    configValues: ConfigValuesResolver,
                                                                    configKeys: ConfigKeysResolver,
                                                                    partner: PartnerResolver,
                                                                },
                                                                data: {
                                                                    breadcrumb: 'Breadcrumbs.ConfigValue'
                                                                }
                                                            },
                                                            { 
                                                                path: 'create', 
                                                                component: EditConfigValueComponent,
                                                                resolve: {
                                                                    configKeys: ConfigKeysResolver,
                                                                    partner: PartnerResolver,
                                                                    regions: RegionsResolver,
                                                                    configDefaultvalues: ConfigDefaultValuesResolver
                                                                },
                                                                data: {
                                                                    breadcrumb: 'Breadcrumbs.ConfigValueCreate'
                                                                }
                                                            },
                                                            
                                                            { 
                                                                path: 'edit/:configId', 
                                                                component: EditConfigValueComponent,
                                                                data: {
                                                                    breadcrumb: 'Breadcrumbs.ConfigValueUpdate'
                                                                }
                                                            },
                                                            { path: '**', redirectTo: 'list' },
                                                        ]
                                                    }
                                                ]
                                            },
                                            {
                                                path: ':regionId/config-value/:scope', 
                                                component: RouterOutletComponent,
                                                canActivate: [PermissionGuardService],
                                                data: {
                                                    expectedPermission: [P.Permissions.Config_default_view, P.Permissions.Config_default_edit],
                                                    breadcrumb: 'Breadcrumbs.ConfigValue'
                                                },
                                                resolve: {
                                                    region: RegionResolver
                                                },
                                                children: [
                                                    { path: '', pathMatch: 'full', redirectTo: 'list' },
                                                    {
                                                        path: 'list',
                                                        component: ConfigValuesByScopeComponent,
                                                        resolve: {
                                                            configValues: ConfigValuesResolver,
                                                            configKeys: ConfigKeysResolver,
                                                            partner: PartnerResolver,
                                                        },
                                                        data: {
                                                            breadcrumb: 'Breadcrumbs.ConfigValue'
                                                        }
                                                    },
                                                    { 
                                                        path: 'create', 
                                                        component: EditConfigValueComponent,
                                                        resolve: {
                                                            configKeys: ConfigKeysResolver,
                                                            partner: PartnerResolver,
                                                            regions: RegionsResolver,
                                                            configDefaultvalues: ConfigDefaultValuesResolver
                                                        },
                                                        data: {
                                                            breadcrumb: 'Breadcrumbs.ConfigValueCreate'
                                                        }
                                                    },
                                                    
                                                    { 
                                                        path: 'edit/:configId', 
                                                        component: EditConfigValueComponent,
                                                        data: {
                                                            breadcrumb: 'Breadcrumbs.ConfigValueUpdate'
                                                        }
                                                    },
                                                    { path: '**', redirectTo: 'list' },
                                
                                                ]
                                            },
                                            {
                                                path: ':regionId/couriers', 
                                                component: RouterOutletComponent,
                                                data: {
                                                    expectedPermission: [P.Permissions.Partner_view, P.Permissions.Partner_edit],
                                                    breadcrumb: 'Breadcrumbs.PartnerCouriers',
                                                },
                                                resolve: {
                                                    region: RegionResolver
                                                },
                                                children: [
                                                    { path: '', pathMatch: 'full', redirectTo: 'list' },
                                                    {
                                                        path: 'list',
                                                        component: PartnerCourierComponent,
                                                        resolve: {
                                                            couriers: PartnerCouriersResolver,
                                                            region: RegionResolver
                                                        },
                                                    },
                                                    {
                                                        path: 'create',
                                                        component: PartnerCourierEditComponent,
                                                        canActivate: [PermissionGuardService],
                                                        data: {
                                                            expectedPermission: [P.Permissions.Partner_view, P.Permissions.Partner_edit],
                                                            breadcrumb: 'Breadcrumbs.PartnerCouriersCreate'
                                                        }
                                                    },
                                                    {
                                                        path: ':partnerCourierId/edit',
                                                        component: PartnerCourierEditComponent,
                                                        canActivate: [PermissionGuardService],
                                                        resolve: {
                                                            courier: PartnerCourierResolver
                                                        },
                                                        data: {
                                                            expectedPermission: [P.Permissions.Partner_view, P.Permissions.Partner_edit],
                                                            breadcrumb: 'Breadcrumbs.PartnerCouriersEdit'
                                                        }
                                                    },
                                                ],
                                            }
                                        ]
                                    },
                                ]
                            },
                        ]
                    },   
                    { path: '**', redirectTo: 'list' },
                ]
            },
            {
                path: 'd2d', component: OperationsMainComponent,
                canActivate: [RoleGuardService, ClientGuard],
                data: {
                    client: Client.INPOST,
                    breadcrumb: 'Breadcrumbs.D2DTest',
                    expectedRole: [UsersRoles.ROLE_SYSADMIN]
                },
                children: [
                    {
                        path: 'test',
                        component: D2dTestsComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Apm_view],
                            breadcrumb: 'Breadcrumbs.D2DTest'
                        },
                        resolve: {
                            partners: PartnersResolver
                        }
                    },
                    { path: '**', redirectTo: 'test' },
                ]
            },
            {
                path: 'subscription', component: OperationsMainComponent,
                canActivate: [PermissionGuardService, ClientGuard],
                
                data: {
                    expectedPermission: [P.Permissions.Partner_view, P.Permissions.Partner_edit],
                    breadcrumb: 'Breadcrumbs.Subscription',
                    client: Client.INPOST,
                },
                children: [
                    {
                        path: 'list',
                        component: SubscriptionComponent,
                        resolve: {
                            subscriptions: SubscriptionsResolver,
                            apms: AmpsResolver,
                            partners: PartnersResolver
                        },
                        data: {
                            breadcrumb: 'Breadcrumbs.Subscription'
                        }
                    },
                    { 
                        path: 'create', 
                        component: EditSubscriptionComponent,
                        resolve: {
                            apms: AmpsResolver,
                            partners: PartnersResolver
                        },
                        data: {
                            breadcrumb: 'Breadcrumbs.SubscriptionCreate'
                        }
                    },
                    { 
                        path: 'edit/:subscriptionId', 
                        component: EditSubscriptionComponent,
                        resolve: {
                            subscription: SubscriptionResolver,
                            apms: AmpsResolver,
                            partners: PartnersResolver
                        },
                        data: {
                            breadcrumb: 'Breadcrumbs.SubscriptionUpdate'
                        }
                    },
                    { path: '**', redirectTo: 'list' },

                ]
            },
            {
                path: 'config-key', component: OperationsMainComponent,
                canActivate: [PermissionGuardService],
                data: {
                    expectedPermission: [P.Permissions.Config_default_view],
                    breadcrumb: 'Breadcrumbs.ConfigKey'
                },
                children: [
                    {
                        path: 'list',
                        component: ConfigKeyComponent,
                        resolve: {
                            configKeys: ConfigKeysResolver
                        },
                        data: {
                            breadcrumb: 'Breadcrumbs.ConfigKey'
                        }
                    },
                    { 
                        path: 'create', 
                        component: EditConfigKeyComponent,
                        resolve: {
                            configKeys: ConfigKeysResolver
                        },
                        data: {
                            breadcrumb: 'Breadcrumbs.ConfigKeyCreate'
                        }
                    },
                    { 
                        path: 'edit/:configKeyId', 
                        component: EditConfigKeyComponent,
                        resolve: {
                            configKey: ConfigKeyResolver,
                        },
                        data: {
                            breadcrumb: 'Breadcrumbs.ConfigKeyUpdate'
                        }
                    },
                    { path: '**', redirectTo: 'list' },

                ]
            },
            {
                path: 'config-value', component: OperationsMainComponent,
                canActivate: [PermissionGuardService],
                data: {
                    expectedPermission: [P.Permissions.Config_default_view],
                    breadcrumb: 'Breadcrumbs.ConfigValue'
                },
                children: [
                    {
                        path: 'list',
                        component: ConfigValueComponent,
                        resolve: {
                            configValues: ConfigValuesResolver,
                            configKeys: ConfigKeysResolver,
                        },
                        data: {
                            breadcrumb: 'Breadcrumbs.ConfigValue'
                        }
                    },
                    {
                        path: 'partner/:partnerId/list',
                        component: ConfigValuesByScopeComponent,
                        resolve: {
                            configValues: ConfigValuesResolver,
                            configKeys: ConfigKeysResolver,
                            partner: PartnerResolver,
                        },
                        data: {
                            breadcrumb: 'Breadcrumbs.ConfigValue'
                        }
                    },
                    { 
                        path: 'partner/:partnerId/create', 
                        component: EditConfigValueComponent,
                        resolve: {
                            configKeys: ConfigKeysResolver,
                            partner: PartnerResolver,
                            regions: RegionsResolver,
                            configDefaultvalues: ConfigDefaultValuesResolver
                        },
                        data: {
                            breadcrumb: 'Breadcrumbs.ConfigValueCreate'
                        }
                    },
                    { 
                        path: 'create', 
                        component: CreateConfigValueComponent,
                        resolve: {
                            configKeys: ConfigKeysResolver
                        },
                        data: {
                            breadcrumb: 'Breadcrumbs.ConfigValueCreate'
                        }
                    },
                    { 
                        path: 'partner/:partnerId/edit/:configId', 
                        component: EditConfigValueComponent,
                        data: {
                            breadcrumb: 'Breadcrumbs.ConfigValueUpdate'
                        }
                    },
                    { 
                        path: 'edit/:configId', 
                        component: EditConfigValueComponent,
                        resolve: {
                            configValue: ConfigValueResolver,
                            configKeys: ConfigKeysResolver,
                        },
                        data: {
                            breadcrumb: 'Breadcrumbs.ConfigValueUpdate'
                        }
                    },
                    { path: '**', redirectTo: 'list' },

                ]
            },
            {
                path: '**', redirectTo: 'apm',
            }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ApmRoutingModule { }
