import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatBagNumber',
    pure: true
})
export class FormatBagNumberPipe implements PipeTransform {

    public transform(bag: any) {
        if (!bag) {
            return '';
        }

        if (typeof bag === 'string') {
            if (bag.length === 11) {
                return bag.slice(-2);
            } else if (bag.length === 12) {
                return bag.slice(-3);
            } else if (bag.length > 46 && bag.length < 55) {
                return `${bag.slice(15, 19)}${bag.slice(26, 27)}`;
            }
            else {
                // new warehouse bag
                return bag;
            }

        }
        if (bag.ref.length === 11) {
            return bag.ref.slice(-2);
        } else if (bag.ref.length === 12) {
            return bag.ref.slice(-3);
        } else if (bag.ref.length > 46 && bag.ref.length < 55) {
            return `${bag.ref.slice(15, 19)}${bag.ref.slice(26, 27)}`;
        } else {
            // new warehouse bag
            return bag.ref;
        }
    }
}
