<layout-default>
        <div header>
            <div toolbar>
                <h4 *ngIf="mode === 'CREATE'">
                    <i-feather name="cast"></i-feather> {{'Create apm' | translate}}
                </h4>
                <h4 *ngIf="mode === 'UPDATE'">
                    <i-feather name="cast"></i-feather> {{'Show apm data' | translate}}: {{apm.name}}
                </h4>
            </div>

            <hr />
        </div>
        
        <div content>
            <div *ngIf="!loader" class="content bg-white">

                <div class="card-content col-8 offset-2">

                    <form [formGroup]="form">


                        <div [id]="'input-apm-' + item" *ngFor="let item of regularFormItems()" class="form-group row">
                            <label class="col-2" for="code">{{item.toUpperCase() | translate}}:</label>
                            <div class="col-10">
                                <input [formControlName]="item" class="form-control" />
                                <div *ngIf="form.get(item).hasError('required') && form.get(item).touched" class="error-color">
                                    {{ "The field is required" | translate }}!
                                </div>
                            </div>
                        </div>

                        <div id="input-apm-lockers" class="form-group row">
                            <label class="col-2" for="code">{{'Lockers' | translate }}:</label>
                            <div class="col-10">
                                <div class="row div-apm-lockers">
                                    <div *ngFor="let item of ['lcount', 'mcount', 'ncount', 'ocount']; let i = index;" class="item col-3">
                                        <p class="d-block tx-sans tx-10 tx-uppercase tx-color-03 tx-spacing-1 text-left mg-b-0 pd-b-0">
                                            <ng-container [ngSwitch]="item">
                                                <span *ngSwitchCase="'lcount'">
                                                    {{'CompartmentType.Cooler' | translate}}
                                                </span>
                                                <span *ngSwitchCase="'mcount'">
                                                    {{'CompartmentType.Freezer' | translate}}
                                                </span>
                                                <span *ngSwitchCase="'ncount'">
                                                    {{'CompartmentType.Ambient' | translate}}
                                                </span>
                                                <span *ngSwitchCase="'ocount'">
                                                    {{'CompartmentType.Other' | translate}}
                                                </span>
                                            </ng-container>:</p>
                                        <input [formControlName]="item" type="number" class="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="input-apm-status" class="form-group row">
                            <label class="col-2" for="socialNumberType">{{'Status' | translate}}:</label>
                            <div class="col-10">
                                <select class="custom-select" formControlName="status">
                                    <option [value]="'AVAILABLE'">{{'AVAILABLE' | translate}}</option>
                                    <option [value]="'UNAVAILABLE'">{{'UNAVAILABLE' | translate}}</option>
                                </select>

                                <div *ngIf="form.get('status').hasError('required') && form.get('status').touched" class="error-color">
                                    {{ "Status field is required" | translate }}!
                                </div>
                            </div>
                        </div>

                        <div id="input-apm-type" class="form-group row">
                            <label class="col-2" for="socialNumberType">{{'Type' | translate}}:</label>
                            <div class="col-10">
                                <select class="custom-select" formControlName="type">
                                    <option [value]="'RLM'">{{'RLM' | translate}}</option>
                                    <option [value]="'UNAVAILABLE'">{{'UNAVAILABLE' | translate}}</option>
                                </select>

                                <div *ngIf="form.get('status').hasError('required') && form.get('status').touched" class="error-color">
                                    {{ "Type field is required" | translate }}!
                                </div>
                            </div>
                        </div>

                        <div id="input-apm-address" formGroupName="address">
                            <label class="d-block col-10 offset-2 tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-b-20">{{'Address information' | translate}}</label>

                            <div *ngFor="let item of addressKeys" class="form-group row">
                                <label class="col-2" for="code">{{item.toUpperCase() | translate}}:</label>
                                <div class="col-10">
                                    <input [formControlName]="item" class="form-control" />
                                    <div *ngIf="form.get('address').get(item)?.hasError('required') && form.get('address').get(item)?.touched" class="error-color">
                                        {{ "The field is required" | translate }}!
                                    </div>
                                </div>
                            </div>

                        </div>



                        <div id="input-apm-coordinates" formGroupName="coordinates">
                            <div class="row">
                                <div class="d-block col-10 offset-2">
                                    <label class="d-block tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-r-20">{{'Coordinates' | translate}}</label>
                                    <button class="btn btn-sm btn-white" (click)="getCoordinates()">{{'Geocode address' | translate}}</button>
                                </div>
                            </div>
                            
                            <div class="row div-apm-map">
                                <div class="col-10 offset-2 mg-t-20 mg-b-20">
                                    <gmap *ngIf="coordinates" (coords)="changeCoordinates($event)" [locations]="coordinates" [mapProperties]="mapProperties" [mapOption]="mapOption"></gmap>
                                </div>
                            </div>
                            <div *ngFor="let item of coordinatesKeys" class="form-group row">
                                <label class="col-2" for="code">{{item.toUpperCase() | translate}}:</label>
                                <div class="col-10">
                                    <input [formControlName]="item" class="form-control" />
                                    <div *ngIf="form.get('address').get(item)?.hasError('required') && form.get('address').get(item)?.touched" class="error-color">
                                        {{ "The field is required" | translate }}!
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="form-group row justify-content-end">
                            <div class="col-2">
                                <button (click)="onSubmit()" [disabled]="!form.valid || submitted"
                                    class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01 w-100">

                                    <span *ngIf="!submitted">{{ "Save" | translate }}</span>

                                    <ng-container *ngIf="submitted">
                                        <div class="load-spinner text-center">
                                            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                        </div>
                                    </ng-container>

                                </button>
                            </div>
                        </div> -->

                    </form>
                </div>
            </div>
        </div>
</layout-default>


<!-- {{'NAME' | translate }} -->
<!-- {{'CODE' | translate }} -->
<!-- {{'OPERATINGHOURS' | translate }} -->
<!-- {{'BOXES' | translate }} -->
<!-- {{'LCOUNT' | translate }} -->
<!-- {{'MCOUNT' | translate }} -->
<!-- {{'NCOUNT' | translate }} -->
<!-- {{'TOWN' | translate }} -->
<!-- {{'ZIP' | translate }} -->
<!-- {{'DISTRICT' | translate }} -->
<!-- {{'VOIVODESHIP' | translate }} -->
<!-- {{'COUNTRY' | translate }} -->
<!-- {{'STREET' | translate }} -->
<!-- {{'HOUSENO' | translate }} -->