import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-api-error-translations',
  templateUrl: './api-error-translations.component.html'
})
export class ApiErrorTranslationsComponent {


}
