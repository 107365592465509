<layout-default>
    <div header>
        <div toolbar>
            <h4 *ngIf="mode === 'CREATE'">
                <i-feather name="inbox"></i-feather> {{'Create subscription' | translate}}
            </h4>
            <h4 *ngIf="mode === 'UPDATE'">
                <i-feather name="inbox"></i-feather> {{'Edit subscription' | translate}}: {{subscription.code}}
            </h4>
        </div>

        <hr />
    </div>
    
    <div content>
        <div *ngIf="!loader" class="content bg-white">

            <div class="card-content col-8 offset-2">

                <form [formGroup]="form">


                    <div *ngFor="let item of regularFormItems()" class="form-group row input-group-{{item}}">

                        
                        <label [for]="item" class="col-2">
                            <span>{{item.toUpperCase() | translate}}:&nbsp;</span>
                            <span *ngIf="form.get(item) | isRequiredControlPipe">*</span>&nbsp;
                            <i-feather *ngIf="tooltipLabel(item)" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(item)"
                                [style.width.px]="13" name="info"></i-feather>
                        </label>

                        <div class="col-10">
                            <input [formControlName]="item" class="form-control" />
                            <div *ngIf="form.get(item).hasError('required') && form.get(item).touched" class="error-color">
                                {{ "The field is required" | translate }}!
                            </div>
                            <div *ngIf="form.get(item).hasError('maxlength') && form.get(item).touched" class="error-color">
                                {{ "The field value is too long" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div class="form-group row input-group-apmId">
                        <label class="col-2" for="socialNumberType">{{'Apm Id' | translate}}:&nbsp;<span *ngIf="form.get('apmId') | isRequiredControlPipe">*</span></label>
                        <div class="col-10">
                            <select class="custom-select" formControlName="apmId">
                                <option *ngFor="let a of apms" [value]="a.id">{{a.name}} - {{a.code}}</option>
                            </select>

                            <div *ngIf="form.get('apmId').hasError('required') && form.get('apmId').touched" class="error-color">
                                {{ "The field is required" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div class="form-group row input-group-partnerId">
                        <label class="col-2" for="partnerId">{{'Partner' | translate}}:&nbsp;<span *ngIf="form.get('partnerId') | isRequiredControlPipe">*</span></label>
                        <div class="col-10">
                            <select class="custom-select" formControlName="partnerId">
                                <option *ngFor="let p of partners" [value]="p.id">{{p.name}} - {{p.code}}</option>
                            </select>

                            <div *ngIf="form.get('partnerId').hasError('required') && form.get('partnerId').touched" class="error-color">
                                {{ "Selecting partner is required" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div class="form-group row input-group-lockers">
                        <label class="col-2" for="code">{{'Lockers' | translate }}:</label>
                        <div class="col-10">
                            <div class="row">
                                <div *ngFor="let item of ['lcount', 'mcount', 'ncount', 'ocount']; let i = index;" class="item col-3">
                                    <p class="d-block tx-sans tx-10 tx-uppercase tx-color-03 tx-spacing-1 text-left mg-b-0 pd-b-0">
                                        <ng-container [ngSwitch]="item">
                                            <span *ngSwitchCase="'lcount'">
                                                {{'CompartmentType.Cooler' | translate}}
                                            </span>
                                            <span *ngSwitchCase="'mcount'">
                                                {{'CompartmentType.Freezer' | translate}}
                                            </span>
                                            <span *ngSwitchCase="'ncount'">
                                                {{'CompartmentType.Ambient' | translate}}
                                            </span>
                                            <span *ngSwitchCase="'ocount'">
                                                {{'CompartmentType.Other' | translate}}
                                            </span>
                                        </ng-container>:&nbsp;<span *ngIf="form.get(item) | isRequiredControlPipe">*</span></p>
                                    <input [formControlName]="item" type="number" class="form-control" />
                                    <div *ngIf="form.get(item).hasError('required') && form.get(item).touched" class="error-color">{{ 'The field is required' | translate }}!</div>
                                    <div *ngIf="form.get(item).hasError('min') && form.get(item).touched" class="error-color">{{ 'Minimum value is 0' | translate }}!</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ng-container formGroupName="timeWindow">
                        <div *ngFor="let i of ['from', 'to']" class="form-group row input-group-{{i}}">
                            <label class="col-2" [for]="i">{{ i | translate }}:&nbsp;<span *ngIf="form.get('timeWindow').get(i) | isRequiredControlPipe">*</span></label>
                            <div class="col-10">
                                <ng-datepicker class="datepicker-input form-group" [formControlName]="i" [id]="i" [headless]="disabled" [options]="options"> </ng-datepicker>
                                <input *ngIf="disabled" type="text" class="form-control" [disabled]="disabled" [value]="form.get(i).value | date: 'dd.MM.yyyy'" />
                                <div *ngIf="form.get('timeWindow').get(i).hasError('required') && form.get('timeWindow').get(i).touched" class="error-color">{{ 'The field is required' | translate }}!</div>
                            </div>
                        </div>
                    </ng-container>

                    <!-- <div class="form-group row">
                        <label class="col-2" for="type">{{'Type' | translate}}:</label>
                        <div class="col-10">
                            <select class="custom-select" formControlName="type">
                                <option *ngFor="let type of subscriptionsType" [value]="type">{{type | translate}}</option>
                            </select>

                            <div *ngIf="form.get('type').hasError('required') && form.get('type').touched" class="error-color">
                                {{ "Selecting partner is required" | translate }}!
                            </div>
                        </div>
                    </div> -->

                    <div *ngIf="formErrorsMessages && formErrorsMessages.length" class="form-group row">
                        <div class="col-10 offset-2">
                            <p class="error-color">{{ 'Something has wrong! Please correct form' | translate }}:</p>
                            <ul>
                                <li *ngFor="let error of formErrorsMessages" class="error-color">
                                    <span *ngIf="error.value && (error.field !== 'update.updateData' && error.field !== 'create.createData' && error.field !== 'update.updateData.timeWindow')">{{ 'Field' | translate }}:&nbsp;</span>
                                    <strong *ngIf="error.value && (error.field !== 'update.updateData' && error.field !== 'create.createData' && error.field !== 'update.updateData.timeWindow')">{{ error.field | translate }}&nbsp;-&nbsp;</strong>
                                    
                                    <span *ngIf="error.value && error.value.includes('specified date range')">{{ 'SUBSCRIPTION_VOLUME_ERROR' | translate | subscriptionVolumeErrorPipe:error.value }}</span>
                                    <span *ngIf="error.value && !error.value.includes('specified date range')">{{ error.value | translate }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="form-group row justify-content-end">
                        <div class="col-2">
                            <button (click)="onSubmit()" [disabled]="!form.valid || submitted"
                                id="btn-create-subscription-submit"
                                class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01 w-100 b">

                                <span *ngIf="!submitted">{{ "Save" | translate }}</span>

                                <ng-container *ngIf="submitted">
                                    <div class="load-spinner text-center">
                                        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                    </div>
                                </ng-container>

                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</layout-default>

<!-- {{'INCLUSIVE' | translate}} -->
<!-- {{'EXCLUSIVE' | translate}} -->
<!-- {{'from' | translate}} -->
<!-- {{'to' | translate}} -->

<!-- {{'SUBSCRIPTION_VOLUME_ERROR' | translate}} -->
<!-- {{'Volume not avail for that subscription' | translate}} -->
<!-- {{'Slot start is after slot end' | translate}} -->

<!-- {{'tooltips.subscription.labels.code' | translate -->
