import { AbstractControl } from '@angular/forms';
import { VanLimitInterface } from '@calendar/interafces/vanLimit.interface';

export function ValidateVanLimit(control: AbstractControl) {
    
    if (!control.value.length) {
        return null;
    }

    if (control.value.find((i: VanLimitInterface) => i.usedPercentage === 0) === undefined) {
        return { validVanLimitMin: true };
    }

    if (control.value.find((i: VanLimitInterface) => i.usedPercentage === 100) === undefined) {
        return { validVanLimitMax: true };
    }
    
    return null;
}
