import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '@environment';
import { UtilsService } from '@services/utils.service';
import { DynamicRoles } from '../entities/roles';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class RolesService {
    public static ROLES: string = 'roles/v1/';
    public static GET_ROLE: string = 'roles/v1/${roleId}';
    public static GET_PERMISSIONS: string = 'dictionary/v1/Permission';
    public static UPDATE_ROLE: string = 'roles/v1/${roleId}';
    public static DELETE_ROLE: string = 'roles/v1/${roleId}';

    private readonly host = environment.api.url;
    private readonly prefix = environment.api.prefix;

    constructor(
        private http: HttpClient, 
        private utilsService: UtilsService,
        private toastrProviderService: ToastrProviderService,
        private translate: TranslateService
    ) {}

    public getRoles(): Observable<DynamicRoles[]> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${RolesService.ROLES}`, {});

        return this.http
            .get(endpoint)
            .pipe(
                map(response => plainToClass(DynamicRoles, response as DynamicRoles[]))
            )
    }

    public getPermissions(): Observable<string[]> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${RolesService.GET_PERMISSIONS}`, {});
        return this.http.get(endpoint).pipe(map((response: string[]) => response))
    }

    public getRole(roleId: string): Observable<DynamicRoles> {
        
        if (!roleId) {
            throw new Error('Empty RoleId');
        }

        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${RolesService.GET_ROLE}`, { roleId: roleId });

        return this.http.get(endpoint)
            .pipe(
                map(response => plainToClass(DynamicRoles, response))
            );
    }

    public create(role): Observable<DynamicRoles> {
        const endpoint = `${this.host}${this.prefix}/${RolesService.ROLES}`;
        return this.http.post(endpoint, role)
            .pipe(map(response => plainToClass(DynamicRoles, response)));
    }

    public update(role): Observable<DynamicRoles> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${RolesService.UPDATE_ROLE}`, { roleId: role['id'] });
        return this.http.put(endpoint, role) .pipe(map(response => plainToClass(DynamicRoles, response)))
    }

    public delete(roleId: string): Observable<boolean> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${RolesService.DELETE_ROLE}`, { roleId: roleId });
        return this.http.delete(endpoint).pipe(map(() => true));
    }

}
