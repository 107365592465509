import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, mergeMap, tap } from 'rxjs/operators';
import { DeliveriesService } from '@deliveries/services/deliveries.services';
import { RoutesService } from '@routes/services/routes.service';
import { NavService } from '@services/nav.sevice';
import { ToastrProviderService } from '@services/toastr-provider.service';
import { LocalStorageService } from '@services/local-storage.service';
import { UsersService } from 'src/app/users/services/users.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DriversService } from '@drivers/services/drivers.service';

@Component({
    selector: 'app-search-customer',
    templateUrl: './search-customer.component.html',
    styleUrls: ['./search-customer.component.scss']
})
export class SearchCustomerComponent {

    @Input() public type: string = 'CUSTOMER';
    @Input() public disabled: boolean = false;

    @Output() public result: EventEmitter<string> = new EventEmitter();

    private searchedPhrase: string = '';

    public asyncSelected: string;
    public typeaheadLoading: boolean;
    public dataSource: Observable<any>;

    constructor(
        private readonly routesService: RoutesService,
        private readonly navService: NavService,
        private readonly translate: TranslateService,
        private readonly deliveriesService: DeliveriesService,
        private toastrProviderService: ToastrProviderService,
        private readonly localStorageService: LocalStorageService,
        private readonly usersService: UsersService,
        private readonly driverService: DriversService,
        private readonly cdr: ChangeDetectorRef
    ) {


        this.dataSource = Observable.create((observer: any) => {
            observer.next(this.asyncSelected);
        })
            .pipe(filter((a: any) => a.length >= 3))
            .pipe(distinctUntilChanged())
            .pipe(
                mergeMap((token: string) => {
                    if (this.type === 'CUSTOMER') {
                        return this.usersService.searchCustomer(token);
                    } else if (this.type === 'DRIVER') {
                        return this.driverService.searchDriver(token);
                    }
                }),
                catchError((error: HttpErrorResponse) => {
                    this.changeTypeaheadLoading(false);
                    if (error.status === 404) {
                        this.toastrProviderService.showWarning(this.translate.instant('Box not found in this shift!'));
                        return [];
                    } else {
                        this.toastrProviderService.showError(this.translate.instant('Error occurred, please try again!'));
                        return throwError(error);
                    }
                })
            );
    }

    public changeTypeaheadLoading(e: boolean): void {
        this.typeaheadLoading = e;
    }

    public typeaheadOnSelect(event: TypeaheadMatch): void {
        this.result.emit(event.item.id);
    }

    public removeResults(): void {
        this.result.emit(undefined);
        this.asyncSelected = undefined
    }
    
}
