import { Injectable } from '@angular/core';
import { CrudService } from '@services/crud.service';
import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '@services/utils.service';
import { ApmExtended } from '../interfaces/apm-extended.interface';

@Injectable({
  providedIn: 'root'
})
export class ApmExtendedService extends CrudService<ApmExtended, number>{
  
  protected static BASE_API: string = `${environment.api.url}${environment.api.prefix}/apm/v1/extended`;  
  
  constructor(
    protected _http: HttpClient, 
    protected utilsService: UtilsService) 
    {
      super(_http, ApmExtendedService.BASE_API, utilsService);
    }

}
