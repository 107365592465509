<layout-default>
    <div header>
        <div toolbar>
            <h4 *ngIf="mode === 'CREATE'">
                <i-feather name="database"></i-feather> {{'Create region' | translate}}
            </h4>
            <h4 *ngIf="mode === 'UPDATE'">
                <i-feather name="database"></i-feather> {{'Edit region' | translate}}: {{region.name}}
            </h4>
        </div>

        <hr />
    </div>
    
    <div content>
        <div *ngIf="!loader" class="content bg-white">

            <div class="card-content col-8 offset-2">

                <form [formGroup]="form" autocomplete="off">

                    <div id="partner-edit-form-dc" class="form-group row">
                        <label class="col-2" for="code">{{'Partner DC' | translate}}:&nbsp;<span *ngIf="form.get('partnerDcId') | isRequiredControlPipe">*</span></label>
                        <div class="col-10">
                            <select class="custom-select" formControlName="partnerDcId">
                                <option *ngFor="let dc of dcs" [value]="dc.id">{{dc.name}}</option>
                            </select>
                            <div *ngIf="form.get('partnerDcId').hasError('required') && form.get('partnerDcId').touched" class="error-color">
                                {{ "The field is required" | translate }}!
                            </div>
                            <div *ngIf="!dcs.length" class="error-color">
                                {{ "To create region you have to have defined distribution center"  | translate }}!
                            </div>
                        </div>
                    </div>

                    <div id="region-edit-form-{{item}}" *ngFor="let item of regularFormItems()" class="form-group row">

                        <label [for]="item" class="col-2">
                            <span>{{item.toUpperCase() | translate}}:&nbsp;</span>
                            <span *ngIf="form.get(item) | isRequiredControlPipe">*</span>&nbsp;
                            <i-feather *ngIf="tooltipLabel(item)" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(item)"
                                [style.width.px]="13" name="info"></i-feather>
                        </label>

                        <div class="col-10">
                            <input [formControlName]="item" class="form-control" />
                            <div *ngIf="form.get(item).hasError('required') && form.get(item).touched" class="error-color">
                                {{ "The field is required" | translate }}!
                            </div>
                            <div *ngIf="form.get(item).hasError('maxlength') && form.get(item).touched" class="error-color">
                                {{ "The field value is too long" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div id="region-edit-form-password" class="form-group row">
                        <label class="col-2" for="code">{{'Default RLM password' | translate}}:&nbsp;<span *ngIf="form.get('defaultApmPassword') | isRequiredControlPipe">*</span></label>
                        <div class="col-10">

                            <div class="input-group mb-2">
                                <div class="input-group-prepend" (touchstart)="showPassword = true"  (mousedown)="showPassword = true" (touchend)="showPassword = false"  (mouseup)="showPassword = false">
                                    <div class="input-group-text">
                                        <i *ngIf="showPassword" class="far fa-eye"></i>
                                        <i *ngIf="!showPassword" class="far fa-eye-slash"></i>
                                </div>
                                </div>
                                <input [type]="(showPassword) ? 'text' : 'password'" formControlName="defaultApmPassword" class="form-control form-password-control" autocomplete="new-password"/>
                            </div>

                            <div *ngIf="form.get('defaultApmPassword').hasError('required') && form.get('defaultApmPassword').touched" class="error-color">
                                {{ "The field is required" | translate }}!
                            </div>

                            <div *ngIf="form.get('defaultApmPassword')?.hasError('minlength') && form.get('defaultApmPassword')?.touched"
                                class="error-color">
                                {{ "The password should contain at least 8 characters" | translate }}!
                            </div>

                            <div *ngIf="form.get('defaultApmPassword').hasError('maxlength') && form.get(item).touched" class="error-color">
                                {{ "The field value is too long" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div id="region-edit-form-password-show-btn" *ngIf="showPassword" class="form-group row">
                        <label class="col-2" for="code">{{'DEFAULTAPMUSERNAME' | translate}}:&nbsp;<span *ngIf="form.get('defaultApmUsername') | isRequiredControlPipe">*</span></label>
                        <div class="col-10">
                            <input formControlName="defaultApmUsername" class="form-control" />
                        </div>
                    </div>

                    <div id="region-edit-form-address" formGroupName="address">
                        <label class="d-block col-10 offset-2 tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-b-20">{{'Address' | translate}}</label>

                        <div *ngFor="let item of addressKeys" class="form-group row">
                            <label class="col-2" for="code">{{item.toUpperCase() | translate}}:&nbsp;<span *ngIf="form.get('address').get(item) | isRequiredControlPipe">*</span></label>
                            <div class="col-10">
                                <input *ngIf="item === 'zip'" [formControlName]="item" [dropSpecialCharacters]="false" mask="00-000" class="form-control" />
                                <input *ngIf="item !== 'zip'" [formControlName]="item" class="form-control" />
                                <div *ngIf="form.get('address').get(item)?.hasError('required') && form.get('address').get(item)?.touched" class="error-color">
                                    {{ "The field is required" | translate }}!
                                </div>
                                <div *ngIf="item === 'zip' && form.get('address').get('zip').hasError('pattern') && form.get('address').get('zip').touched" class="error-color">
                                    {{ "The zip code you entered is invalid" | translate }}!
                                </div>
                                <div *ngIf="form.get('address').get(item)?.hasError('maxlength') && form.get('address').get(item)?.touched" class="error-color">
                                    {{ "The field value is too long" | translate }}!
                                </div>
                            </div>
                        </div>

                    </div>

                    <div id="region-edit-form-locationAdditional" formGroupName="locationAdditional">
                        <label class="d-block col-10 offset-2 tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-b-20">{{'Address information' | translate}}</label>

                        <ng-container *ngFor="let item of additionalKeys">
                            <div *ngIf="form.get('locationAdditional').get(item)" class="form-group row">
                                <ng-container *ngIf="item != 'lift' && item != 'floor'">
                                    <label class="col-2" [for]="item">{{item.toUpperCase() | translate}}:&nbsp;<span *ngIf="form.get('locationAdditional').get(item) | isRequiredControlPipe">*</span></label>
                                    <div class="col-10">
                                        
                                        <input *ngIf="item === 'contactPhoneNO'" prefix="+48 " mask="000-000-000" [formControlName]="item" class="form-control" />
                                        <input *ngIf="item !== 'contactPhoneNO'" [formControlName]="item" class="form-control" />

                                        <div *ngIf="form.get('locationAdditional').get(item)?.hasError('required') && form.get('locationAdditional').get(item)?.touched" class="error-color">
                                            {{ "The field is required" | translate }}!
                                        </div>
                                        <div *ngIf="form.get('locationAdditional').get(item).hasError('pattern') && form.get('locationAdditional').get(item).touched" class="error-color">
                                            {{ "Above email address is incorrect" | translate }}!
                                        </div>
                                        <div *ngIf="item === 'contactPhoneNO' && form.get('locationAdditional').get('contactPhoneNO').hasError('Mask error') && form.get('locationAdditional').get('contactPhoneNO').touched" class="error-color">
                                            {{ "Above phone number you entered is invalid" | translate }}!
                                        </div>
                                        <div *ngIf="form.get('locationAdditional').get(item)?.hasError('maxlength') && form.get('locationAdditional').get(item)?.touched" class="error-color">
                                            {{ "The field value is too long" | translate }}!
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                        <div class="form-group row">
                            <label class="col-2" for="socialNumberType">{{'Lift' | translate}}:&nbsp;<span *ngIf="form.get('locationAdditional').get('lift') | isRequiredControlPipe">*</span></label>
                            <div class="col-10">
                                <select class="custom-select" formControlName="lift">
                                    <option [value]="true">{{'Yes' | translate}}</option>
                                    <option [value]="false">{{'No' | translate}}</option>
                                </select>

                                <div *ngIf="form.get('locationAdditional').get('lift')?.hasError('required') && form.get('locationAdditional').get('lift')?.touched" class="error-color">
                                        {{ "Lift field is required" | translate }}!
                                </div>
                            </div>
                        </div>   
                        <div class="form-group row">
                            <label class="col-2" for="floor">{{'Floor'.toUpperCase() | translate}}:&nbsp;<span *ngIf="form.get('locationAdditional').get('floor') | isRequiredControlPipe">*</span></label>
                            <div class="col-10">
                                <input formControlName="floor" type="number" class="form-control" />
                                <div *ngIf="form.get('locationAdditional').get('floor')?.hasError('required') && form.get('locationAdditional').get('floor')?.touched" class="error-color">
                                    {{ "The field is required" | translate }}!
                                </div>
                            </div>
                        </div>

                    </div>

                    <div *ngIf="formErrorsMessages && formErrorsMessages.length" class="form-group row">
                        <div class="col-10 offset-2">
                            <p class="error-color">{{ 'Something has wrong! Please correct form' | translate }}:</p>
                            <ul>
                                <li *ngFor="let error of formErrorsMessages" class="error-color">
                                    <span *ngIf="error.value">{{ 'Field' | translate }}</span>
                                    <strong>{{ error.field | translate }}</strong>
                                    <span>{{ error.value | translate }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div id="region-edit-form-btn-save" class="form-group row justify-content-end">
                        <div class="col-2">
                            <button (click)="onSubmit()" [disabled]="!form.valid || submitted"
                                class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01 w-100">

                                <span *ngIf="!submitted">{{ "Save" | translate }}</span>

                                <ng-container *ngIf="submitted">
                                    <div class="load-spinner text-center">
                                        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                    </div>
                                </ng-container>

                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</layout-default>

<!-- {{'DESCRIPTION' | translate }} -->
<!-- {{'OPENINGHOURS' | translate }} -->
<!-- {{'partnerid' | translate }} -->
<!-- {{'DEFAULTAPMUSERNAME' | translate}} -->
<!-- {{'Default RLM password' | translate}} -->

<!-- {{'tooltips.region.labels.code' | translate -->
